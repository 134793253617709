import React from "react";
import { useDispatch } from "react-redux";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { FileForm } from "../../Components/FileForm";
import { Button } from "../../Components/Buttons";
import { Input } from "../../Components/Inputs";
/** Ag-grid Import */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";

function BM10060(props) {
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const gridRef = React.useRef();
	const columnDefs = [
		{
			field: "popComment",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_col_description"
					)[0].lanName
					: "", // 설명
			minWidth: 250,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: true,
		},
		{
			field: "baseFileData",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_col_link"
					)[0].lanName
					: "", // 링크
			minWidth: 350,
			cellRenderer: (e) => {
				return e.data.addRow !== "Y" ? (
					e.data.baseFileData !== null ? (
						<a
							style={{
								color:
									e.data.useYn === "Y"
										? "#FFFFFF"
										: "#5f5f5f",
							}}
							onClick={() => {
								props.setInputs({
									...props.inputs,
									rcnt: e.data.rcnt,
									popLink: e.data.baseFileData,
								});
								props.FileDownload(e.data.rcnt);
							}}
						>
							{e.data.baseFileData}
						</a>
					) : (
						<Button
							name={props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"bm10060_button_upload_image"
								)[0].lanName
								: ""	// 이미지 업로드
							}
							onClick={() => {
								props.setInputs({
									...props.inputs,
									rcnt: e.data.rcnt,
								});
								setCheckingToggle(true);
							}}
						/>
					)
				) : null;
			},
			editable: false,
		},
		{
			field: "useYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_col_use_yn"
					)[0].lanName
					: "", // 사용 유무
			minWidth: 120,
			cellEditor: "agSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) => item.name),
			},
			editable: true,
			sort: "desc",
		},
		{
			field: "popSdate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_col_show_start"
					)[0].lanName
					: "", // 표출시작일자
			minWidth: 150,
			editable: true,
		},
		{
			field: "popEdate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_col_show_end"
					)[0].lanName
					: "", // 표출종료일자
			minWidth: 150,
			editable: true,
		},
		{
			field: "createDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_col_create_date"
					)[0].lanName
					: "", // 등록일
			minWidth: 150,
			editable: false,
		},
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_col_update_date"
					)[0].lanName
					: "", // 수정일
			minWidth: 150,
			editable: false,
		},
	];
	const [checkingToggle, setCheckingToggle] = React.useState(false);
	const [confirmToggle, setConfirmToggle] = React.useState({
		open: false,
		title: "",
		message: "",
		type: "",
	});

	return (
		<>
			<Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid xs={12} md={12}>
					<Card sx={{ display: "flex", justifyContent: "flex-end" }}>
						<AuthGroupButton
							pageName="BM10060"
							target="G01"
							addFunc={() => {
								props.CheckingLogic(gridRef);
							}}
							saveFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10060_pop_save_title"
										)[0].lanName
										: ""),	// 저장
									message: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10060_pop_save_message"
										)[0].lanName
										: ""),	// 저장하시겠습니까?
									type: "create",
								});
							}}
							deleteFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10060_pop_delete_title"
										)[0].lanName
										: ""),	// 삭제
									message: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10060_pop_delete_message"
										)[0].lanName
										: ""),	// 삭제하시겠습니까?
									type: "delete",
								});
							}}
						/>
					</Card>
				</Grid>
				<Grid xs={12} md={12}>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animationRows={true}
								columnDefs={columnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: true,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="multiple"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() =>
									GridFunctions.onSelectionChanged(
										gridRef,
										props.setSelectedRowData
									)
								}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen()); // 열림 방지
									GridFunctions.onCellValueChanged(
										e,
										gridRef,
										props.setChangedRowData
									);
								}}
								getRowStyle={(e) => {
									if (e.data.useYn === "미사용" && e.data.newYn !== "Y") {
										return { color: "#5f5f5f" };
									} else {
										return GridFunctions.getRowStyle(e);
									}
								}}
							/>
						</div>
					</Card>
				</Grid>
			</Grid>
			<Dialog
				title={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_pop_upload_title"
					)[0].lanName
					: ""	// 이미지 업로드
				}
				open={checkingToggle}
				onClose={() => {
					props.setInputs({
						...props.inputs,
						rcnt: "",
						fileSize: "",
						formFile: "",
						popLink: "",
					});
					setCheckingToggle(false);
				}}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_pop_upload"
					)[0].lanName
					: ""	// 업로드
				}
				useSaveAfterClose={false}
				onSave={() => {
					if (
						props.inputs.fileSize !== "" ||
						props.inputs.popLink !== ""
					) {
						props.FileUpload();
					}
					props.setInputs({
						...props.inputs,
						rcnt: "",
						fileSize: "",
						formFile: "",
						popLink: "",
					});
					setCheckingToggle(false);
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
			>
				<FileForm
					datas={[
						{
							type: "file",
							name: "", //파일
							value: props.inputs.popLink,
							onChange: (e) => {
								var file = e.target.files[0];
								if (e.target.files.length === 0) {
									return;
								} else {
									let reader = new FileReader();
									reader.readAsDataURL(file);
									reader.onload = (f) => {
										if (reader) {
											props.setInputs({
												...props.inputs,
												fileSize: file,
												formFile: reader.result,
												popLink: file.name,
											});
										}
									};
								}
							},
							buttonName: (props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"bm10060_button_upload_file"
								)[0].lanName
								: ""),	// 파일 업로드
						},
						{
							name: (props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"bm10060_pop_upload_file_name"
								)[0].lanName
								: ""),	//파일명
							value:
								props.inputs.popLink === ""
									? ""
									: props.inputs.popLink,
							disabled: true,
						},
					]}
				/>
			</Dialog>
			<Dialog
				fullWidth
				title={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_pop_show_image"
					)[0].lanName
					: ""	// 이미지 보기
				}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
				open={props.checkingToggle1}
				onClose={() => {
					props.setInputs({
						...props.inputs,
						rcnt: "",
						fileSize: "",
						formFile: "",
						popLink: "",
					});
					props.setCheckingToggle1(false);
				}}
				bodySx={{ display: "flex", flexDirection: "column" }}
				useSaveButton={false}
			>
				<Input
					name={props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId ===
								"bm10060_pop_upload_file_name"
						)[0].lanName
						: ""	// 파일명
					}
					type="filled"
					value={
						props.inputs.popLink === "" ? "" : props.inputs.popLink
					}
					disabled
				/>
				<FileForm
					datas={[
						{
							type: "image",
							id: "imageFile",
							src:
								props.inputs.formFile === ""
									? null
									: props.inputs.formFile,
						},
					]}
				/>
			</Dialog>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_pop_confirm"
					)[0].lanName
					: ""	// 확인
				}
				onSave={() => {
					if (confirmToggle.type === "create") {
						props.CreateLogic();
					} else if (confirmToggle.type === "delete") {
						props.DeleteLogic();
					}
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10060_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
			>
				{confirmToggle.message}
			</Dialog>
		</>
	);
}

export default BM10060;
