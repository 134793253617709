import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	CardActionArea,
	IconButton,
	Divider,
	Typography,
} from "@mui/material";
import { Button, GroupButton } from "../Buttons";
/** Icons Import */
import {
	FiberManualRecord as CircleIcon,
	Settings as SettingIcon,
} from "@mui/icons-material";
/** Images Import */
import UpArrowIcon from "../../Assets/Images/Design/drawable-xhdpi/up_btn.png";
import DownArrowIcon from "../../Assets/Images/Design/drawable-xhdpi/down_btn.png";

const CardContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
}));

function SwitchingLists({
	items = [], // HomeLists's item list
	// GetItem = () => {},
	setListData,
}) {
	// 순서변경 함수
	const SwitchingList = (_arr = [], _currentData, _type = "up") => {
		let currentIdx = _arr.findIndex((f) => f.id === _currentData.id);
		if (_type === "up" && currentIdx === 0) {
			return;
		} else if (_type === "down" && currentIdx === _arr.length - 1) {
			return;
		}
		let temp = _arr.splice(currentIdx, 1);

		switch (_type) {
			case "down":
				temp.map((item, index) => {
					item.order = currentIdx + 2;
				});
				_arr.splice(currentIdx + 1, 0, temp[0]);
				_arr.map((item, index) => {
					if (index === currentIdx) {
						item.order = index + 1;
					}
				});
				Commons.SelectionDocuments(
					"controllerSelectionList",
					currentIdx + 1,
					_arr
				);
				break;
			default:
				temp.map((item, index) => {
					item.order = currentIdx;
				});
				_arr.splice(currentIdx - 1, 0, temp[0]);
				_arr.map((item, index) => {
					if (index === currentIdx) {
						item.order = index + 1;
					}
				});
				Commons.SelectionDocuments(
					"controllerSelectionList",
					currentIdx - 1,
					_arr
				);
				break;
		}
		setListData([..._arr]);
	};

	const ReturnData = items.map((item, index) => {
		return (
			<Card
				key={index}
				sx={{ my: 1.5, color: "#141820" }}
				className={"controllerSelectionList" + index}
			>
				<CardHeader
					title={item.name}
					titleTypographyProps={{
						variant: "h6",
						fontWeight: "bold",
						marginLeft: "20px",
						color: "#FFFFFF",
					}}
					action={
						<GroupButton
							sx={{ my: 1, color: "#dff5fc" }}
							items={[
								{
									name: (
										<img
											style={{
												width: "28px",
												height: "28px",
											}}
											src={UpArrowIcon}
										/>
									),
									onClick: () => {
										SwitchingList(items, item);
										Commons.SelectionDocuments(
											"controllerSelectionList",
											index - 1,
											items
										);
									},
								},
								{
									name: (
										<img
											style={{
												width: "28px",
												height: "28px",
											}}
											src={DownArrowIcon}
										/>
									),
									onClick: () => {
										SwitchingList(items, item, "down");
										Commons.SelectionDocuments(
											"controllerSelectionList",
											index + 1,
											items
										);
									},
								},
							]}
							color="conotecButton"
						/>
					}
				/>
			</Card>
		);
	});

	return ReturnData;
}

export default SwitchingLists;
