import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components */
import DM10010View from "../../Views/DashboardManagements/DM10010";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";

function DM10010(props) {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const location = useLocation(); // 화면간 데이터를 전달받음.
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
		logout: false,
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
		type: 0,	// 홈 화면으로 이동하는 플래그. 0: 이동 안 함, 1 : 강제이동
	});
	const intervalTime = Commons.Storages("get", "settings").reloadTime;
	const [determinateLabel, setDeterminateLabel] = React.useState(
		Commons.Storages("get", "settings").reloadTime / 1000
	); // 재조회 시간
	const [codeList, setCodeList] = React.useState([]); // 기초코드 리스트
	const [amountYn, setAmountYn] = React.useState([]); // 결재 유무
	const [allController, setAllController] = React.useState([]); // 사용자의 모든 컨트롤러 데이터
	const [controllerData, setControllerData] = React.useState([]); // 조회된 컨트롤러의 기본 정보
	const [controllerDetail, setControllerDetail] = React.useState([]); // 조회된 컨트롤러의 정리된 정보
	const [settingAllData, setSettingAllData] = React.useState([]); // 조회된 컨트롤러의 세팅 전체 정보 (RegisterMap + RegisterVal)
	const [settingMobileData, setSettingMobileData] = React.useState([]); // 조회된 컨트롤러의 세팅 Mobile(가제제상, 시스템 등) 정보
	const [settingBitData, setSettingBitData] = React.useState([]); // 조회된 컨트롤러의 세팅 Bit(콤프, 제상, 저온방지 등) 정보
	const [registerChLog, setRegisterChLog] = React.useState({
		categories: [],
		data: [],
	}); // 레지스터 변경 이력 조회
	const [tempChLog, setTempChLog] = React.useState([]); // Chart Data
	const [bookingList, setBookingList] = React.useState([]); // 예약된 데이터 리스트
	const [bookingData, setBookingData] = React.useState({
		mapAdd: "",
		aftValue: "",
		rotation: "0",
		appDate:
			new Date().getMinutes() > 30
				? new Date(
					new Date().setHours(new Date().getHours() + 1, 0, 0, 0)
				)
				: new Date(
					new Date().setHours(new Date().getHours(), 30, 0, 0)
				),
		endDate: new Date(new Date().setHours(23, 59, 59, 0)),
	}); // 예약 설정 데이터
	const [mapAddItem, setMapAddItem] = React.useState([]); // 예약등록 팝업의 레지스터 맵 주소 아이템
	const [registerValue, setRegisterValue] = React.useState({
		// 예약등록 팝업의 값을 수정하기 위한 데이터
		open: false,
		title: "",
		message: "",
		inputType: 1,
		inputs: [],
		min: 0,
		max: 0,
		min1: 0,
		max1: 0,
		beforeValue: "",
		mapAdd: "",
		value: "",
		stepType: "",
		mapUnit: "",
		value1: "",
		value2: "",
	});
	const [writeValue, setWriteValue] = React.useState(""); // 장치 이름 변경 변수
	const [copySettings, setCopySettings] = React.useState({
		open: false,
		value: "",
		items: [],
	}); // 선택한 장비의 설정값을 현재 장비에 복사
	const [copyMasters, setCopyMasters] = React.useState({
		open: false,
		value: "",
		items: [],
		checkId: "",
		checkPw: "",
		checked: false,
	}); // 마스터에 장비의 설정값을 복사
	const [rowData, setRowData] = React.useState([]); // 마스터 복사 예시용 그리드 데이터
	const [registerList, setRegisterList] = React.useState([]); // 전체 레지스터 맵 주소 데이터
	const [registerVal, setRegisterVal] = React.useState([]); // 전체 레지스터 값 데이터
	const [lanList, setLanList] = React.useState([]); // language list
	const [userLanCode, setUserLanCode] = React.useState("kr"); // 사용자 설정 언어코드. 기본 한국어

	const [registerMap, setRegisterMap] = React.useState([]); // 예약시 보조 최대최소값 가져올 레지스터 맵 주소 데이터
	const reloadCount = React.useRef(0); // 일정 시간 후 새로고침을 하기 위한 변수

	React.useEffect(() => {
		Init();
		// GetBooking();

		const LabelProgress = setInterval(() => {
			setDeterminateLabel((prevProgress) =>
				prevProgress <= 0 ? intervalTime / 1000 : prevProgress - 1
			);
			reloadCount.current = reloadCount.current + 1;
		}, 1000);

		return () => {
			clearInterval(LabelProgress);
		};
	}, []);

	React.useEffect(() => {
		if (reloadCount.current >= 900) {
			window.location.reload(true);
		} else if (determinateLabel === 0) {
			Init(false);
			setDeterminateLabel(
				Commons.Storages("get", "settings").reloadTime / 1000
			);
		}
	}, [determinateLabel]);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
		// 언어팩
		IndexedDb.GetAllDb("LanguageList").then((result) => {
			setLanList(result);
		});
		// 사용자 언어 코드
		IndexedDb.GetLoginInfo().then((result) => {
			setUserLanCode(result.lanCode);
		});
	}, []);

	// 컨트롤러 상태 재조회
	const ReloadPage = () => {
		Init(false);
		// 재조회 버튼 클릭 시 시간 초기화
		setDeterminateLabel(
			Commons.Storages("get", "settings").reloadTime / 1000
		);
	};

	// 1. 사용자의 모든 컨트롤러 데이터를 조회
	//		=> 현재 컨트롤러 데이터만 추출 후 가공
	// 2. 현재 컨트롤러 데이터를 기준으로 현재 레지스터 값 조회
	const Init = async (type = true) => {
		if (type) {
			setProgress(true);
		}

		let userInfo = null;
		let codeList = [];
		let errorMsgCodes = [];
		let mesureUnitList = [];
		let mesureSubUnitList = [];
		let temp = [];
		let tempDetail = [];
		let bookingItem = [];
		let lans = [];
		let lanCode = "";

		try {
			// 사용자 정보 조회
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
				setAmountYn(result.amountYn);
			});

			// 코드 리스트 조회
			await IndexedDb.GetCodeAll().then((result) => {
				codeList = result;
				errorMsgCodes = result.filter(
					(item, index) => item.groupCd === "SENSSOR_ERROR"
				);
				mesureUnitList = result.filter(
					(item, index) => item.groupCd === "MESURE_TYPE"
				);
			});

			// 사용자 언어코드 확인
			await IndexedDb.GetLoginInfo().then((result) => {
				lanCode = result.lanCode;
			});

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			location.state !== null && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.Home,
				{
					userId: userInfo.userId,
					type: Commons.Storages("get", "settings").server,
				}
			)
				.then((result) => {
					let data = result.data;

					if (
						data.message ===
						"Index was outside the bounds of the array."
					) {
						throw new Error(
							lans.length > 0
								? lans.filter(
									(f) =>
										f.lanId ===
										"snack_no_conntected_devices"
								)[0].lanName
								: ""
							// 연결된 장치가 없습니다.
						);
					} else if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						setControllerData([]);
						throw new Error(
							lans.length > 0
								? lans.filter(
									(f) =>
										f.lanId === "snack_unavailable_data"
								)[0].lanName
								: "" // 데이터가 없습니다.
						);
					} else if (data === false) {
						throw new Error(data);
					} else {
						// 사용자의 전체 데이터 조회 후 대시보드에 맞게 데이터 재가공
						if (data.machNames.length > 0) {
							setAllController(data.machNames);
							codeList.forEach((item, index) => {
								mesureUnitList.forEach((item1, index1) => {
									if (item.groupCd === item1.code) {
										mesureSubUnitList.push({ ...item });
									}
								});
							});

							// 각 장치의 여러 데이터를 하나로 묶는 작업
							data.machNames.map((item, index) => {
								let pushData = {
									...item,
									amountYn: userInfo.amountYn,
								};

								if (data.settingValues) {
									data.settingValues.forEach(
										(item1, index1) => {
											if (item1.itemNo === item.itemNo) {
												pushData = {
													...pushData,
													setting_temp: item1.temp,
													setting_damp: item1.damp,
													setting_illum: item1.illum,
													setting_co2: item1.co2,
												};
											}
										}
									);
								}
								if (data.currentValues) {
									data.currentValues.forEach(
										(item1, index1) => {
											if (item1.itemNo === item.itemNo) {
												pushData = {
													...pushData,
													current_temp: item1.temp,
													current_damp: item1.damp,
													current_illum: item1.illum,
													current_co2: item1.co2,
												};
											}
										}
									);
								}
								if (data.operations) {
									data.operations.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												operation: item1.operation,
											};
										}
									});
								}
								if (data.tempUnits) {
									data.tempUnits.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												tempUnit: item1.tempUnit
													? item1.tempUnit
													: "0",
											};
										}
									});
								}
								if (data.alarms) {
									data.alarms.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												errorYn: "Y",
												// alarmMsg:
												// 	errorMsgCodes.filter(
												// 		(f) =>
												// 			f.code ===
												// 			item1.alarmMsg
												// 	).length > 0
												// 		? errorMsgCodes.filter(
												// 			(f) =>
												// 				f.code ===
												// 				item1.alarmMsg
												// 		)[0].val1
												// 		: "",
												alarmMsg:
													item1.alarmMsg === "미접속"
														? "N-S"
														: item1.alarmMsg,
											};
										}
									});
								}
								temp.push(pushData);
							});

							temp.filter(
								(f) => f.itemNo === location.state.itemNo
							).map((item, index) => {
								if (
									item.itemType === "1" &&
									item.tmpYn === "Y"
								) {
									tempDetail.push({
										type: "temp",
										maxVal: item.tmpMaxVal,
										minVal: item.tmpMinVal,
										current: item.current_temp,
										setting: item.setting_temp,
										unit:
											mesureSubUnitList.filter(
												(f) =>
													f.groupCd === "TMP" &&
													f.code == item.tempUnit
											).length > 0
												? mesureSubUnitList.filter(
													(f) =>
														f.groupCd ===
														"TMP" &&
														f.code ==
														item.tempUnit
												)[0].val1
												: "",
										errorYn:
											item.errorYn === "Y" ? "Y" : "N",
										alarmMsg: item.alarmMsg,
									});
								} else if (
									item.itemType === "2" &&
									item.hmdYn === "Y"
								) {
									tempDetail.push({
										type: "damp",
										maxVal: item.hmdMaxVal,
										minVal: item.hmdMinVal,
										current: item.current_damp,
										setting: item.setting_damp,
										unit:
											mesureSubUnitList.filter(
												(f) =>
													f.groupCd === "DAMP" &&
													f.code == "0"
											).length > 0
												? mesureSubUnitList.filter(
													(f) =>
														f.groupCd ===
														"DAMP" &&
														f.code == "0"
												)[0].val1
												: "",
										errorYn:
											item.errorYn === "Y" ? "Y" : "N",
										alarmMsg: item.alarmMsg,
									});
								} else if (
									item.itemType === "3" &&
									(item.tmpYn === "Y" || item.hmdYn === "Y")
								) {
									tempDetail = [
										{
											type: "temp",
											maxVal: item.tmpMaxVal,
											minVal: item.tmpMinVal,
											current: item.current_temp,
											setting: item.setting_temp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "TMP" &&
														f.code == item.tempUnit
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"TMP" &&
															f.code ==
															item.tempUnit
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "damp",
											maxVal: item.hmdMaxVal,
											minVal: item.hmdMinVal,
											current: item.current_damp,
											setting: item.setting_damp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "DAMP" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"DAMP" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
									];
								} else if (
									item.itemType === "4" &&
									(item.tmpYn === "Y" ||
										item.hmdYn === "Y" ||
										item.co2Yn === "Y")
								) {
									tempDetail = [
										{
											type: "temp",
											maxVal: item.tmpMaxVal,
											minVal: item.tmpMinVal,
											current: item.current_temp,
											setting: item.setting_temp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "TMP" &&
														f.code == item.tempUnit
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"TMP" &&
															f.code ==
															item.tempUnit
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "damp",
											maxVal: item.hmdMaxVal,
											minVal: item.hmdMinVal,
											current: item.current_damp,
											setting: item.setting_damp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "DAMP" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"DAMP" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "co2",
											maxVal: item.co2MaxVal,
											minVal: item.co2MinVal,
											current: item.current_co2,
											setting: item.setting_co2,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "CO2" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"CO2" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
									];
								} else if (
									item.itemType === "5" &&
									(item.tmpYn === "Y" ||
										item.hmdYn === "Y" ||
										item.illYn === "Y" ||
										item.co2Yn === "Y")
								) {
									tempDetail = [
										{
											type: "temp",
											maxVal: item.tmpMaxVal,
											minVal: item.tmpMinVal,
											current: item.current_temp,
											setting: item.setting_temp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "TMP" &&
														f.code == item.tempUnit
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"TMP" &&
															f.code ==
															item.tempUnit
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "damp",
											maxVal: item.hmdMaxVal,
											minVal: item.hmdMinVal,
											current: item.current_damp,
											setting: item.setting_damp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "DAMP" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"DAMP" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "inllum",
											maxVal: item.illMaxVal,
											minVal: item.illMinVal,
											current: item.current_illum,
											setting: item.setting_illum,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "ILLUM" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"ILLUM" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "co2",
											maxVal: item.co2MaxVal,
											minVal: item.co2MinVal,
											current: item.current_co2,
											setting: item.setting_co2,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "CO2" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"CO2" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
									];
								} else if (item.itemType === "6") {
									// console.log("이상신호기");
								} else if (item.itemType === "7") {
									// console.log("중계기");
								}

								// tempDetail = [
								// 	{
								// 		type: "temp",
								// 		maxVal: item.tmpMaxVal,
								// 		minVal: item.tmpMinVal,
								// 		// current: item.current_temp,
								// 		current: "-20.2",
								// 		setting: item.setting_temp,
								// 		unit: mesureSubUnitList.filter(
								// 			(f) =>
								// 				f.groupCd === "TMP" &&
								// 				f.code == item.tempUnit
								// 		)[0].val1,
								// 		errorYn:
								// 			item.errorYn === "Y" ? "Y" : "N",
								// 		alarmMsg: item.alarmMsg,
								// 	},
								// 	{
								// 		type: "damp",
								// 		maxVal: item.hmdMaxVal,
								// 		minVal: item.hmdMinVal,
								// 		// current: item.current_damp,
								// 		current: "64.3",
								// 		setting: item.setting_damp,
								// 		unit: mesureSubUnitList.filter(
								// 			(f) =>
								// 				f.groupCd === "DAMP" &&
								// 				f.code == "0"
								// 		)[0].val1,
								// 		errorYn:
								// 			item.errorYn === "Y" ? "Y" : "N",
								// 		alarmMsg: item.alarmMsg,
								// 	},
								// 	{
								// 		type: "inllum",
								// 		maxVal: item.illMaxVal,
								// 		minVal: item.illMinVal,
								// 		// current: item.current_illum,
								// 		current: "35670",
								// 		setting: item.setting_illum,
								// 		unit: mesureSubUnitList.filter(
								// 			(f) =>
								// 				f.groupCd === "ILLUM" &&
								// 				f.code == "0"
								// 		)[0].val1,
								// 		errorYn:
								// 			item.errorYn === "Y" ? "Y" : "N",
								// 		alarmMsg: item.alarmMsg,
								// 	},
								// 	{
								// 		type: "co2",
								// 		maxVal: item.co2MaxVal,
								// 		minVal: item.co2MinVal,
								// 		// current: item.current_co2,
								// 		current: "2400",
								// 		setting: item.setting_co2,
								// 		unit: mesureSubUnitList.filter(
								// 			(f) =>
								// 				f.groupCd === "CO2" &&
								// 				f.code == "0"
								// 		)[0].val1,
								// 		errorYn:
								// 			item.errorYn === "Y" ? "Y" : "N",
								// 		alarmMsg: item.alarmMsg,
								// 	},
								// ];
							});
						}
						// console.log(
						// 	"Init base data: ",
						// 	temp.filter(
						// 		(f) => f.itemNo == location.state.itemNo
						// 	)
						// );
						setControllerData(
							temp.filter(
								(f) => f.itemNo == location.state.itemNo
							)
						);
						// console.log("tempDetail : ", tempDetail);
						setControllerDetail(tempDetail);

						// 대시보드에서 통신 두절 발생시 홈 화면으로 이동
						if (tempDetail[0].errorYn === 'Y' && (tempDetail[0].alarmMsg === "미접속" || tempDetail[0].alarmMsg === "N-S")) {
							setSnackDialog({
								...snackDialog,
								open: true,
								// title: lans.length > 0
								// 	? lans.filter(
								// 		(f) =>
								// 			f.lanId === "snack_unavailable_data"
								// 	)[0].lanName
								// 	: "", // 데이터가 없습니다.
								title: "Error",
								message: lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_con_lost_redirect_home"
									)[0].lanName
									: "", // 통신 연결이 끊어져 홈 화면으로 이동합니다.
								type: 1,
							});
						}
					}
				})
				.then(async () => {
					// 현재 컨트롤러의 레지스터 맵 조회 => 섭씨/화씨에 따른 최대/최소값 데이터 조회
					let registerMaps = [];

					// 현재 아이템이 맞는 레지스터 값 조회
					let registerValues = [];

					// 레지스터 맵 조회
					await Commons.Axios(
						"get",
						Global.base.useUrl +
						Global.urls.BaseManagement.BM10070.GetRegisterMaps,
						{
							itemNo: location.state.itemNo,
						}
					).then((result) => {
						let data = result.data;
						// console.log("레지스터 맵 조회 data : ", data);

						if (data.type === "Error") {
							throw new Error(
								(lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_view_failed"
									)[0].lanName
									: "") +
								(lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_refresh"
									)[0].lanName
									: "") // 조회 실패! 새로고침하세요.
							);
						} else if (data === "데이터가 없습니다.") {
							setRegisterList([]);
							throw new Error(
								lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_unavailable_data"
									)[0].lanName
									: ""
							);
						} else {
							registerMaps = [...data];
							setRegisterMap(data);
						}
					});

					// 레지스터 값 조회
					await Commons.Axios(
						"get",
						Global.base.useUrl + Global.urls.Home.GetRegisterVal,
						{
							itemNo: location.state.itemNo,
						}
					).then((result) => {
						let data = result.data;
						// console.log("레지스터 값 조회 data : ", data);

						if (data.type === "Error") {
							throw new Error(
								(lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_view_failed"
									)[0].lanName
									: "") +
								(lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_refresh"
									)[0].lanName
									: "") // 조회 실패! 새로고침하세요.
							);
						} else if (data === "데이터가 없습니다.") {
							throw new Error(
								lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_unavailable_data"
									)[0].lanName
									: ""
							);
						} else {
							registerValues = [...data];
						}
					});

					// 컨트롤러에 현재 설정되어 있는 값 조회
					await Commons.Axios(
						"get",
						Global.base.useUrl + Global.urls.Home.GetSetting,
						{
							userId: userInfo.userId,
							itemNo: location.state.itemNo,
							type: Commons.Storages("get", "settings").server,
							lan: lanCode === "en" ? 2 : 1,
						}
					).then((result) => {
						let data = result.data;
						let temp = null;
						// console.log("컨트롤러 설정값 data: ", data);

						if (
							data.message ===
							"Index was outside the bounds of the array."
						) {
							throw new Error(
								lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_no_conntected_devices"
									)[0].lanName
									: ""
							);
						} else if (data.type === "Error") {
							throw new Error(data.message);
						} else if (data === "데이터가 없습니다.") {
							setSettingBitData([]);
							throw new Error(
								lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_unavailable_data"
									)[0].lanName
									: ""
							);
						} else if (data === false) {
							throw new Error(data);
						} else {
							if (data.baseTypes.length > 0) {
								// 예약등록의 현재 값을 보는데 사용
								setSettingAllData(data.baseTypes);

								// 장치설정의 설정값 리스트 + 버튼
								temp = data.baseTypes.filter(
									(item, index) =>
										item.moDpYn === "Y" &&
										item.mapType !== "2"
								);

								// 장치설정의 설정값 리스트 + 버튼
								temp.map((item, index) => {
									item.inputs = item.inputs.map(
										(item1, index1) => {
											return {
												name: item1,
												onClick: () => {
													// 변경값 전송 함수
													ChangeSettings(
														item.mapAdd,
														registerValues.filter(
															(f) =>
																f.valDesc ===
																item1
														)[0].val
													);
													// 변경 알림
													// setSnacks({
													// 	...snacks,
													// 	open: true,
													// 	type: "success",
													// 	message:
													// 		item.mapNm +
													// 		" " +
													// 		item1,
													// });
													setSnackDialog({
														open: true,
														title:
															// lanList.length > 0
															// 	? lanList.filter(
															// 		(f) =>
															// 			f.lanId ===
															// 			"snack_success_title"
															// 	)[0].lanName
															// 	: "",	// 성공
															// lans.length > 0
															// 	? lans.filter(
															// 		(f) =>
															// 			f.lanId ===
															// 			"snack_success_title"
															// 	)[0].lanName
															// 	: "",	// 성공
															item.mapNm,
														message: (
															<div>
																<p>
																	{item.mapNm +
																		"(" +
																		item1 +
																		") " +
																		(lans.length > 0
																			? lans.filter(
																				(f) =>
																					f.lanId ===
																					"snack_submitted"
																			)[0].lanName
																			: ""	// 을 전달하였습니다.
																		)
																	}
																</p>
																<p>
																	{item.mapNm +
																		" " +
																		(lans.length > 0
																			? lans.filter(
																				(f) =>
																					f.lanId ===
																					"snack_required_check"
																			)[0].lanName
																			: ""	// 을 확인하세요.
																		)
																	}
																</p>
																<br />
																<p>
																	{lans.length > 0
																		? lans.filter(
																			(f) =>
																				f.lanId ===
																				"snack_redirect_home"
																		)[0].lanName
																		: ""	// 홈 화면으로 이동합니다.
																	}
																</p>
															</div>
														),
														type: 1
													});

												},
											};
										}
									);
								});

								// 예약등록 장치 설정명 콤보 아이템 제작
								data.baseTypes.forEach((item, index) => {
									// if (item.moDpYn !== "Y") {	// 40000 번지이면 예약 가능함.
									if (
										(registerMaps.filter(
											(f) => f.MapAdd === item.mapAdd
										)[0].MapUnit === "분" &&
											registerMaps.filter(
												(f) =>
													f.MapAdd ===
													data.baseTypes[
														index + 1
													].mapAdd
											)[0].MapUnit === "초") ||
										(registerMaps.filter(
											(f) => f.MapAdd === item.mapAdd
										)[0].MapUnit === "Min" &&
											registerMaps.filter(
												(f) =>
													f.MapAdd ===
													data.baseTypes[
														index + 1
													].mapAdd
											)[0].MapUnit === "Sec")
									) {
										// 분+초 다 있는 시간
										bookingItem.push({
											value: item.mapAdd,
											name: item.mapNm.split("(")[0],
										});
									} else if (
										registerMaps.filter(
											(f) => f.MapAdd === item.mapAdd
										)[0].MapUnit !== "초" &&
										registerMaps.filter(
											(f) => f.MapAdd === item.mapAdd
										)[0].MapUnit !== "Sec"
									) {
										// 그 외 나머지
										bookingItem.push({
											value: item.mapAdd,
											name: item.mapNm,
										});
									}
									// }
								});

								setMapAddItem(bookingItem);
							}

							// 장치설정값
							setSettingMobileData(
								data.baseTypes.filter(
									(item, index) =>
										item.moDpYn === "Y" &&
										item.mapType !== "2"
								)
							);
							// 콤프, 제상 등 장치 상태값
							setSettingBitData(data.bitStatus);
							// 컨트롤러 설정 변경 로그 조회
							GetRegisterChLog(location.state.itemNo);
							// 온도변화 차트 데이터 조회
							GetTempChLog(
								location.state.itemNo,
								location.state.machNm,
								location.state.itemType
							);
						}
					});
				});
		} catch (e) {
			// console.log("e : ", e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};

	// 컨트롤러 설정 변경 로그 조회
	const GetRegisterChLog = async (_itemNo) => {
		setProgress(true);
		let userInfo = null;
		let today = new Date();

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10030.GetBetweenWithDash,
				{
					years: today.getFullYear(),
					userId: userInfo.userId,
					fromDate: Commons.DateFormating(today, 6),
					toDate: Commons.DateFormating(today, 6),
					itemNo: _itemNo,
				}
			).then((result) => {
				let data = result.data;
				let categories = []; // x축
				let series = []; // 데이터

				if (data === "데이터가 없습니다.") {
					return false;
				} else if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data.length > 0) {
					data.sort((a, b) => a.MapAdd - b.MapAdd).forEach(
						(item, index) => {
							if (index === 0) {
								categories.push(item.MapNm);
							} else if (item.MapAdd !== data[index - 1].MapAdd) {
								categories.push(item.MapNm);
							}
						}
					);

					categories.forEach((item, index) => {
						series.push(
							data.filter((f) => f.MapNm === item).length
						);
					});
					setRegisterChLog({
						...registerChLog,
						categories: categories,
						data: series,
					});
				} else {
					throw new Error(data);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
		} finally {
			setProgress(false);
		}
	};

	// 온도변화 차트 데이터 조회
	const GetTempChLog = async (itemNo, name, itemType) => {
		setProgress(true);
		let userInfo = null;
		let tmpArr = [];
		let hmdArr = [];
		let illArr = [];
		let co2Arr = [];
		let resultArr = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10010.GetDeviceGraphData,
				{
					years: new Date().getFullYear(),
					userId: userInfo.userId,
					itemNo: itemNo,
				}
			).then((result) => {
				let data = result.data;
				// let data = null;
				// console.log("chart data : ", data);
				// let data = [
				// 	{ CreateDate: '20240502065000', ItemNo: 5, CurTmp: 69, CurHdm: 0, CurIll: 0, CurCo2: 0 },
				// 	{ CreateDate: '20240502064000', ItemNo: 5, CurTmp: 68, CurHdm: 0, CurIll: 0, CurCo2: 0 },
				// ];

				if (data === null) {
					let date = new Date();
					setTempChLog([{
						categories: [date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0")],
						data: [{
							name: name,
							data: [null]
						}],
						type: "tmp",
					}]);
				} else if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (data) {
					// 중복을 제거한 CreateDate 목록 생성
					const dateList = Array.from(
						new Set(
							data.map((item) => item.CreateDate.slice(8, 12))
						)
					).sort((a, b) => a.localeCompare(b));

					const dateResult = dateList.map((item) => {
						return item.slice(0, 2) + ":" + item.slice(2, 4);
					});

					// 차트 데이터 조합1
					dateList.map((item, index) => {
						const matchingItems = data.filter(
							(f) => f.CreateDate.slice(8, 12) === item
						);

						if (itemType === "1") {
							tmpArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurTmp === 999 || matchingItems[0].CurTmp === 9999 || matchingItems[0].CurTmp === 998
										? null
										: matchingItems[0].CurTmp
									: null
							);
						} else if (itemType === "2") {
							hmdArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurHdm === 999 || matchingItems[0].CurHdm === 9999 || matchingItems[0].CurHdm === 998
										? null
										: matchingItems[0].CurHdm
									: null
							);
						} else if (itemType === "3") {
							tmpArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurTmp === 999 || matchingItems[0].CurTmp === 9999 || matchingItems[0].CurTmp === 998
										? null
										: matchingItems[0].CurTmp
									: null
							);
							hmdArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurHdm === 999 || matchingItems[0].CurHdm === 9999 || matchingItems[0].CurHdm === 998
										? null
										: matchingItems[0].CurHdm
									: null
							);
						} else if (itemType === "4") {
							tmpArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurTmp === 999 || matchingItems[0].CurTmp === 9999 || matchingItems[0].CurTmp === 998
										? null
										: matchingItems[0].CurTmp
									: null
							);
							hmdArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurHdm === 999 || matchingItems[0].CurHdm === 9999 || matchingItems[0].CurHdm === 998
										? null
										: matchingItems[0].CurHdm
									: null
							);
							co2Arr.push(
								matchingItems.length > 0
									? matchingItems[0].CurCo2 === 999 || matchingItems[0].CurCo2 === 9999 || matchingItems[0].CurCo2 === 998
										? null
										: matchingItems[0].CurCo2
									: null
							);
						} else if (itemType === "5") {
							tmpArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurTmp === 999 || matchingItems[0].CurTmp === 9999 || matchingItems[0].CurTmp === 998
										? null
										: matchingItems[0].CurTmp
									: null
							);
							hmdArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurHdm === 999 || matchingItems[0].CurHdm === 9999 || matchingItems[0].CurHdm === 998
										? null
										: matchingItems[0].CurHdm
									: null
							);
							illArr.push(
								matchingItems.length > 0
									? matchingItems[0].CurIll === 999 || matchingItems[0].CurIll === 9999 || matchingItems[0].CurIll === 998
										? null
										: matchingItems[0].CurIll
									: null
							);
							co2Arr.push(
								matchingItems.length > 0
									? matchingItems[0].CurCo2 === 999 || matchingItems[0].CurCo2 === 9999 || matchingItems[0].CurCo2 === 998
										? null
										: matchingItems[0].CurCo2
									: null
							);
						}
					});

					// 차트 데이터 조합2
					if (itemType === "1") {
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: tmpArr,
								},
							],
							type: "tmp",
						});
					} else if (itemType === "2") {
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: hmdArr,
								},
							],
							type: "hmd",
						});
					} else if (itemType === "3") {
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: tmpArr,
								},
							],
							type: "tmp",
						});
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: hmdArr,
								},
							],
							type: "hmd",
						});
					} else if (itemType === "4") {
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: tmpArr,
								},
							],
							type: "tmp",
						});
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: hmdArr,
								},
							],
							type: "hmd",
						});
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: co2Arr,
								},
							],
							type: "co2",
						});
					} else if (itemType === "5") {
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: tmpArr,
								},
							],
							type: "tmp",
						});
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: hmdArr,
								},
							],
							type: "hmd",
						});
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: illArr,
								},
							],
							type: "ill",
						});
						resultArr.push({
							categories: dateResult,
							data: [
								{
									name: name,
									data: co2Arr,
								},
							],
							type: "co2",
						});
					}

					setTempChLog(resultArr);
				} else {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "" // 조회 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
		} finally {
			setProgress(false);
		}
	};

	// 설정값 변경
	const ChangeSettings = async (_mapAdd, _value) => {
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then(
				(result) => (userInfo = result)
			);
			await Commons.Axios(
				"post",
				Global.base.useUrl +
				Global.urls.SettingManagement.SM10010.Change,
				{
					itemNo: location.state.itemNo,
					mapAdd: _mapAdd,
					value: _value + "",
					userId: userInfo.userId,
					type: Commons.Storages("get", "settings").server,
				}
			).then((result) => {
				let data = result.data;
				// console.log("ChangeSettings result: ", data);
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};

	//#region 예약 관련 함수 사용 X
	// 예약 데이터 조회
	const GetBooking = async () => {
		setProgress(true);
		let userInfo = null;
		let lans = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			if (location.state === null) {
				setSnackDialog({
					...snackDialog,
					open: true,
					title: lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
					message: (
						<div>
							<p>
								{
									lans.length > 0
										? lans.filter(
											(f) =>
												f.lanId ===
												"sm10000_gen_not_controller"
										)[0].lanName
										: ""	// 컨트롤러가 선택되지 않았습니다.
								}
							</p>
							<p>
								{
									lans.length > 0
										? lans.filter(
											(f) =>
												f.lanId ===
												"snack_redirect_home"
										)[0].lanName
										: ""	// 홈 화면으로 이동합니다.
								}
							</p>
						</div>
					),
					type: 1,
				});
			}

			location.state !== null && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.GetBooking,
				{
					userId: userInfo.userId,
					itemNo: location.state.itemNo,
				}
			).then((result) => {
				let data = result.data;
				// console.log("data : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setBookingList([
						{
							data: "No data",
						},
					]);
				} else {
					setBookingList(data);
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					...snackDialog,
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
					type: 0,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""}
								{/* 로그인 정보가 만료되었습니다. */}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_login_again"
									)[0].lanName
									: ""}
								{/* 다시 로그인 해 주세요. */}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 예약시 선택된 레지스터 주소에 따른 변경값 입력
	const RegisterValue = async (_mapAdd) => {
		let tempRegisterVal = {};

		let set = settingAllData.filter((item) => item.mapAdd === _mapAdd)[0]; // 현재 레지스터의 값
		let temp = registerMap.filter((f) => f.MapAdd === _mapAdd)[0]; // 현재 레지스터의 설정값
		let temp1 = registerMap.filter(
			(f) => f.MapAdd === Number(_mapAdd) + 1 + ""
		)[0]; // 다음 레지스터 설정값

		// console.log("set : ", set);
		// console.log("temp : ", temp);
		// console.log("temp1 : ", temp1);

		if (set.inputType === "1") {
			// 숫자타입 설정값
			if (
				(temp.MapUnit === "분" && temp1.MapUnit === "초") ||
				(temp.MapUnit === "Min" && temp1.MapUnit === "Sec")
			) {
				// 분+초 시간 설정

				// let temp = {
				// 	title: set[0].mapNm,
				// 	message: set[0].mapComment,
				// 	inputType: "1",
				// 	inputs: [],
				// 	min: Number(set[0].mapMin),
				// 	max: Number(set[0].mapMax),
				// 	max1: registerMap.filter((f) => f.MapAdd === set[0].mapAdd)[0].MapMax1,
				// 	min1: registerMap.filter((f) => f.MapAdd === set[0].mapAdd)[0].MapMin1,
				// 	beforeValue: set[0].value,
				// 	mapAdd: set[0].mapAdd,
				// 	// mapUnit: set[0].mapUnit,
				// 	mapUnit: registerMap.filter((f) => f.MapAdd === set[0].mapAdd)[0].MapUnit,
				// 	value: set[0].value,
				// 	stepType: "",
				// 	value1: set[0].mapUnit === "분" ? set[0].value : "",
				// 	value2: set[0].mapUnit === "초" ? set[0].value : "",
				// };
				tempRegisterVal = {
					title:
						userLanCode === "en"
							? temp.MapNmShEng.split("(")[0]
							: temp.MapNm.split("(")[0],
					message:
						userLanCode === "en"
							? temp.MapCommentEng
							: temp.MapComment,
					inputType: "1",
					inputs: [],
					min: Number(temp.MapMin),
					max: Number(temp.MapMax),
					max1: null,
					min1: null,
					beforeValue: set.value,
					mapAdd: temp.MapAdd,
					// mapUnit: set[0].mapUnit,
					mapUnit: "분초",
					// value:
					// 	settingAllData
					// 		.filter((item) => item.mapAdd === _mapAdd)[0]
					// 		.value.toString()
					// 		.padStart(2, "0") +
					// 	":" +
					// 	settingAllData
					// 		.filter(
					// 			(item) =>
					// 				item.mapAdd === Number(_mapAdd) + 1 + ""
					// 		)[0]
					// 		.value.toString()
					// 		.padStart(2, "0"),
					stepType: "",
					value1: settingAllData.filter(
						(item) => item.mapAdd === _mapAdd
					)[0].value,
					value2: settingAllData.filter(
						(item) => item.mapAdd === Number(_mapAdd) + 1 + ""
					)[0].value,
				};
			} else if (
				(temp.MapUnit === "분" && temp1.MapUnit !== "초") ||
				(temp.MapUnit === "Min" && temp1.MapUnit !== "Sec")
			) {
				// 분 입력 설정
				tempRegisterVal = {
					title: userLanCode === "en" ? temp.MapNmShEng : temp.MapNm,
					message:
						userLanCode === "en"
							? temp.MapCommentEng
							: temp.MapComment,
					inputType: "1",
					inputs: [],
					min: Number(temp.MapMin),
					max: Number(temp.MapMax),
					min1: Number(temp.MapMin),
					max1: Number(temp.MapMax),
					beforeValue: set.value,
					mapAdd: temp.MapAdd,
					mapUnit: "분",
					// value: set.value,
					stepType: "",
					value1: "",
					value2: "",
				};
			} else {
				// 일반 숫자 입력
				tempRegisterVal = {
					title: userLanCode === "en" ? temp.MapNmShEng : temp.MapNm,
					message:
						userLanCode === "en"
							? temp.MapCommentEng
							: temp.MapComment,
					inputType: "1",
					inputs: [],
					min: Number(temp.MapMin),
					max: Number(temp.MapMax),
					min1: Number(temp.MapMin1),
					max1: Number(temp.MapMax1),
					beforeValue: set.value,
					mapAdd: temp.MapAdd,
					mapUnit: "",
					// value: set.value,
					stepType: "",
					value1: "",
					value2: "",
				};
			}
		} else {
			// 코드타입 설정값
			let tempInputs = [];
			if (set.moDpYn === "Y") {
				set.inputs.forEach((item, index) => {
					tempInputs.push({
						value: index,
						name: item.name,
					});
				});
			} else {
				set.inputs.forEach((item, index) => {
					tempInputs.push({
						value: index,
						name: item,
					});
				});
			}

			tempRegisterVal = {
				title: userLanCode === "en" ? temp.MapNmShEng : temp.MapNm,
				message:
					userLanCode === "en" ? temp.MapCommentEng : temp.MapComment,
				inputType: "2",
				inputs: tempInputs,
				min: Number(set.mapMin),
				max: Number(set.mapMax),
				max1: null,
				min1: null,
				beforeValue: tempInputs.filter(
					(_item) => _item.name === set.value
				)[0].value,
				mapAdd: set.mapAdd,
				// mapUnit: set[0].mapUnit,
				mapUnit: temp.MapUnit,
				// value: tempInputs.filter((_item) => _item.name === set.value)[0]
				// 	.value,
				stepType: "",
				value1: "",
				value2: "",
			};
		}
		// console.log("tempRegisterVal : ", tempRegisterVal);

		// 예약등록팝업 값
		return setRegisterValue({
			...tempRegisterVal,
			open: true,
		});
	};

	// 예약 저장 전 확인
	const CheckBooking = async () => {
		// 예약값의 최대최소 판단
		if (registerValue.inputType === "1") {
			// 숫자 입력
			if (registerValue.value === "timepicker") {
				// 분+초의 시간 입력
				await CreateBooking(
					bookingData.mapAdd,
					bookingData.aftValue[0]
				);
				await CreateBooking(
					(bookingData.mapAdd * 1 + 1).toString(),
					bookingData.aftValue[1]
				);
			} else if (
				controllerData[0].tempUnit === "1" &&
				registerValue.value !== "timepicker"
			) {
				// 일반 숫자 입력
				// 화씨 => Max1, Min1 사용
				if (registerValue.max1 < Number(registerValue.value)) {
					// console.log("화씨 최대값 초과");
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "", // 설정 범위를 벗어난 값입니다.
					// });
					setSnackDialog({
						...snackDialog,
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "", // 설정 범위를 벗어난 값입니다.
						type: 0,
					});
				} else if (registerValue.min1 > Number(registerValue.value)) {
					// console.log("화씨 최소값 미만");
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "", // 설정 범위를 벗어난 값입니다.
					// });
					setSnackDialog({
						...snackDialog,
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "", // 설정 범위를 벗어난 값입니다.
						type: 0,
					});
				} else {
					// console.log('화씨 정상범위');
					await CreateBooking(
						bookingData.mapAdd,
						bookingData.aftValue
					);
				}
			} else {
				// 섭씨 => Max, Min 사용
				if (registerValue.max < Number(registerValue.value)) {
					// console.log("섭씨 최대값 초과");
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "", // 설정 범위를 벗어난 값입니다.
					// });
					setSnackDialog({
						...snackDialog,
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "", // 설정 범위를 벗어난 값입니다.
						type: 0,
					});
				} else if (registerValue.min > Number(registerValue.value)) {
					// console.log("섭씨 최소값 미만");
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "", // 설정 범위를 벗어난 값입니다.
					// });
					setSnackDialog({
						...snackDialog,
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "", // 설정 범위를 벗어난 값입니다.
						type: 0,
					});
				} else {
					// console.log('섭씨 정상범위');
					await CreateBooking(
						bookingData.mapAdd,
						bookingData.aftValue
					);
				}
			}
		} else {
			// 코드 입력
			await CreateBooking(bookingData.mapAdd, bookingData.aftValue);
		}
	};

	// 예약 저장
	const CreateBooking = async (mapAdd, aftValue) => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Home.CreateBooking,
				{
					appDate: Commons.DateFormating(bookingData.appDate, 5),
					endDate: Commons.DateFormating(bookingData.endDate, 5),
					rotation: bookingData.rotation,
					itemNo: location.state.itemNo,
					mapAdd: mapAdd,
					aftValue: aftValue * 1,
					userId: userInfo.userId,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (
					data === "종료일자는 시작일자보다 느릴 수 없습니다!"
				) {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_cannot_slower_end_date"
							)[0].lanName
							: ""
					);
				} else if (
					data === "시작일자는 현재일자보다 느릴 수 없습니다!"
				) {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_cannot_slower_current_date"
							)[0].lanName
							: ""
					);
				} else if (data === "Ok") {
					setRegisterValue({
						...registerValue,
						open: false,
						title: "",
						message: "",
						inputType: 1,
						inputs: [],
						min: 0,
						max: 0,
						min1: 0,
						max1: 0,
						beforeValue: "",
						value: "",
					});
					setSnacks({
						...snacks,
						open: true,
						type: "success",
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_saved"
								)[0].lanName
								: "", // 저장되었습니다.
					});
					// GetBooking();
				} else {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_save_failed"
							)[0].lanName
							: "" // 저장 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};

	// 예약 삭제
	const DeleteBooking = async (dkDate, rcnt) => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Home.DeleteBooking,
				{
					dkDate: dkDate,
					rcnt: rcnt,
				}
			).then((result) => {
				let data = result.data;
				// console.log("DeleteBooking: ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (data === "Ok") {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_deleted"
					// 			)[0].lanName
					// 			: "", // 삭제되었습니다.
					// });
					setSnackDialog({
						...snackDialog,
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_deleted"
								)[0].lanName
								: "", // 삭제되었습니다.
						type: 0,
					});
					// GetBooking();
				} else {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_delete_failed"
							)[0].lanName
							: "" // 삭제 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};
	//#endregion

	// 장치 이름 변경
	const ChangeItemName = async () => {
		setProgress(true);
		let userInfo = null;

		try {
			if (!writeValue) {
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_enter_new_nm"
						)[0].lanName
						: "" // 변경할 장치명을 입력하세요.
				);
			}

			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.ChangeMachName,
				{
					itemNo: location.state.itemNo,
					userId: userInfo.userId,
					machNm: writeValue,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data !== "Ok") {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_save_failed"
							)[0].lanName
							: "" // 저장 실패!
					);
				} else {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_saved"
					// 			)[0].lanName
					// 			: "", // 저장되었습니다.
					// });
					setSnackDialog({
						...snackDialog,
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_saved"
								)[0].lanName
								: "", // 저장되었습니다.
						type: 0,
					});

					let temp = [...controllerData];
					temp[0].name = writeValue;
					setControllerData(temp);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};

	// 장치 복사
	const CopySetting = () => {
		let tempItem = [];
		let tempArr = allController.filter(
			(item) =>
				controllerData[0].itemIdx === item.itemIdx &&
				item.itemNo !== location.state.itemNo &&
				item.itemType !== "6" && // 이상신호기 제외
				item.itemType !== "7" // 중계기 제외
		);

		tempArr.forEach((item) => {
			tempItem.push({
				value: item.itemNo,
				name: item.machNm,
			});
		});

		setCopySettings({
			...copySettings,
			open: true,
			items: tempItem,
		});
	};

	// 장치 복사 저장
	const SaveCopySetting = async () => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Home.CopySettings,
				{
					originItemNo: location.state.itemNo,
					targetItemNo: copySettings.value,
					userId: userInfo.userId,
					type: Commons.Storages("get", "settings").server,
				}
			).then((result) => {
				let data = result.data;
				// console.log("copy setting data: ", data);

				if (data.response.indexOf("미접속") > -1) {
					throw new Error(data.response);
				} else if (data === false) {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (data.response === "Y") {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_copy_finished"
					// 			)[0].lanName
					// 			: "", // 복사가 왼료되었습니다.
					// });
					setSnackDialog({
						...snackDialog,
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_copy_finished"
								)[0].lanName
								: "", // 복사가 왼료되었습니다.
						type: 0,
					});
				} else {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_save_failed"
							)[0].lanName
							: "" // 저장 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};

	// 마스터 복사용 Base Mst 데이터 조회
	const CopyMasterMst = async () => {
		setProgress(true);
		let tempArr = [];
		let tempItemArr = [];

		if (!copyMasters.checkId || !copyMasters.checkPw) {
			setProgress(false);
			return false;
		}

		try {
			// console.log("location.state : ", location.state);

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Home.GetCopyMasters,
				{
					userId: copyMasters.checkId,
					userPw: copyMasters.checkPw,
				}
			).then((result) => {
				let data = result.data;
				// console.log("Copy master select: ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: "" // 데이터가 없습니다
					);
				} else if (data) {
					// 동일한 ItemIdx를 가진 장치만 필터링
					tempArr = data.filter(
						(item) => item.itemIdx === location.state.itemIdx
					);

					tempArr.forEach((item) => {
						tempItemArr.push({
							value: item.rmCd,
							name: item.rmNm,
						});
					});
					GetRegisterMaps();
					setCopyMasters({
						...copyMasters,
						checked: true,
						items: tempItemArr,
					});
				} else {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "" // 조회 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};

	// 레지스터 맵 주소 조회
	const GetRegisterMaps = async () => {
		setProgress(true);

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10070.GetRegisterMaps,
				{
					itemNo: location.state.itemNo,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRegisterList([]);
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else {
					setRegisterList(data);
					GetRegisterVals(data[0].ItemIdx);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
			return "";
		} finally {
			setProgress(false);
		}
	};

	// 레지스터 값 조회
	const GetRegisterVals = async (itemIdx) => {
		setProgress(true);

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10070.GetRegisterVals,
				{
					itemIdx: itemIdx,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRegisterVal([]);
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else {
					setRegisterVal(data);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
			return false;
		} finally {
			setProgress(false);
		}
	};

	//#region 마스터복사 사용 X
	// 마스터 복사용 Base sub 데이터 조회
	const CopyMasterSub = async (rmCd) => {
		setProgress(true);
		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10070.GetSubForRmCd,
				{
					rmCd: rmCd,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					setRowData([]);
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (data) {
					setRowData(data);
				} else {
					setRowData([]);
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "" // 조회 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};

	// 마스터 복사 저장
	const SaveCopyMaster = async () => {
		setProgress(true);
		let userInfo = null;
		let tempArr = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});
			if (rowData.length <= 0) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message:
				// 		lanList.length > 0
				// 			? lanList.filter(
				// 				(f) => f.lanId === "snack_unavailable_code"
				// 			)[0].lanName
				// 			: "", // 사용할 수 없는 코드입니다.
				// });
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_unavailable_code"
						)[0].lanName
						: "", // 사용할 수 없는 코드입니다.
				);
			} else {
				rowData.forEach((_item) => {
					tempArr.push({
						mapAdd: _item.mapAdd,
						value:
							registerList.filter(
								(item) => item.MapAdd === _item.mapAdd
							)[0].InputType === "1"
								? _item.val
								: registerVal.filter(
									(item) =>
										item.MapAdd === _item.mapAdd &&
										item.ValDesc === _item.val
								)[0].Val,
					});
				});

				await Commons.Axios(
					"post",
					Global.base.useUrl + Global.urls.Home.CopyMasters,
					{
						itemNo: location.state.itemNo,
						mapModels: tempArr,
						userId: userInfo.userId,
						type: Commons.Storages("get", "settings").server,
					}
				).then((result) => {
					let data = result.data;
					// console.log("data : ", data);

					if (data.response.indexOf("미접속") > -1) {
						throw new Error(data.response);
					} else if (data === false) {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId === "snack_unavailable_data"
								)[0].lanName
								: ""
						);
					} else if (data.response === "Y") {
						// setSnacks({
						// 	...snacks,
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_copy_finished"
						// 			)[0].lanName
						// 			: "", // 복사가 왼료되었습니다.
						// });
						setSnackDialog({
							...snackDialog,
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_copy_finished"
									)[0].lanName
									: "", // 복사가 왼료되었습니다.
							type: 0,
						});
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_save_failed"
								)[0].lanName
								: "" // 저장 실패!
						);
					}
				});
			}
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				...snackDialog,
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
				type: 0,
			});
		} finally {
			setProgress(false);
		}
	};
	//#endregion

	return (
		<div className="wrap">
			<DM10010View
				// 언어팩
				lanList={lanList}
				userLanCode={userLanCode}
				// 기초코드 리스트
				codeList={codeList}
				determinateLabel={determinateLabel}
				ReloadPage={ReloadPage}
				amountYn={amountYn}
				controllerData={controllerData}
				controllerDetail={controllerDetail}
				// 알림 팝업
				setSnacks={setSnacks}
				setSnackDialog={setSnackDialog}
				/** 설비 설정 관련 */
				settingMobileData={settingMobileData}
				settingBitData={settingBitData}
				registerChLog={registerChLog}
				tempChLog={tempChLog}
				/** 예약 관련 */
				// bookingList={bookingList}
				// bookingData={bookingData}
				// setBookingData={setBookingData}
				// mapAddItem={mapAddItem}
				// registerValue={registerValue}
				// setRegisterValue={setRegisterValue}
				// RegisterValue={RegisterValue}
				// CheckBooking={CheckBooking}
				// CreateBooking={CreateBooking}
				// DeleteBooking={DeleteBooking}
				/** 장치 이름 변경 */
				writeValue={writeValue}
				setWriteValue={setWriteValue}
				ChangeItemName={ChangeItemName}
				/** 장치 복사 */
				copySettings={copySettings}
				setCopySettings={setCopySettings}
				CopySetting={CopySetting}
				SaveCopySetting={SaveCopySetting}
			/** 마스터 복사 */
			// copyMasters={copyMasters}
			// setCopyMasters={setCopyMasters}
			// rowData={rowData}
			// setRowData={setRowData}
			// CopyMasterMst={CopyMasterMst}
			// CopyMasterSub={CopyMasterSub}
			// SaveCopyMaster={SaveCopyMaster}
			// settingAllData={settingAllData}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					dialog.logout && Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "dashboard_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() => {
					console.log("snackDialog : ", snackDialog);
					if (snackDialog.type === 0) {
						setSnackDialog({
							...snackDialog,
							open: false,
							title: "",
							message: "",
							type: 0,
						});
					} else if (snackDialog.type === 1) {
						navigate("/Home");
					}
				}}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default DM10010;
