import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
/** Styles Import */
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components Import */
import BM10060View from "../../Views/BaseManagements/BM10060";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
/** Images Import */
/** Icons Import */

/** 광고 관리 */
function BM10060(props) {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [codeList, setCodeList] = React.useState([]);
	const [checkingToggle1, setCheckingToggle1] = React.useState(false); // 이미지 미리보기
	const [rowData, setRowData] = React.useState([]); // 그리드에 보여주는 데이터
	const [selectedRowData, setSelectedRowData] = React.useState([]); // 선택된 행을 저장함
	const [changedRowData, setChangedRowData] = React.useState([]); // 수정된 행을 저장함
	const [inputs, setInputs] = React.useState({
		rcnt: "",
		fileSize: "",
		formFile: "", // 존나 긴거
		popLink: "", // 파일이름.png
	});
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
	}, []);

	// 데이터 조회
	const Init = async () => {
		setProgress(true);
		let tempArr = [];
		let lans = [];
		let search = true;

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "BM10060").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10060.GetCode
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else if (data.length > 0) {
					data.forEach((item, index) => {
						tempArr.push({
							...item,
							useYn: item.useYn === "Y" ? "사용" : "미사용",
							popSdate: Commons.StringtoDate(item.popSdate),
							popEdate: Commons.StringtoDate(item.popEdate),
							createDate: Commons.DateFormating(
								Commons.StringtoDate(item.createDate, 3)
							),
							updateDate: Commons.DateFormating(
								Commons.StringtoDate(item.updateDate, 3)
							),
						});
					});
					setRowData(tempArr);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 그리드 한 줄 추가
	const CheckingLogic = async (_gridref) => {
		let userInfo = null;
		await IndexedDb.GetLoginInfo().then((result) => {
			userInfo = result;
		});
		let tempArr = [];

		rowData.forEach((item) => tempArr.push(item.rcnt));

		let currentData = [
			{
				rcnt: rowData.length === 0 ? 0 : Math.max(...tempArr) + 1,
				popComment: "",
				popSdate: new Date(),
				popEdate: new Date(),
				useYn: "사용",
				addRow: "Y",
				createId: userInfo.userId,
				createDate: new Date(),
				updateId: userInfo.userId,
				updateDate: new Date(),
				newYn: "Y",
			},
			...rowData,
		];
		_gridref.current.api.setRowData(currentData);
		setRowData(currentData);
		setChangedRowData([
			...changedRowData,
			{
				rcnt: rowData.length === 0 ? 0 : Math.max(...tempArr) + 1,
				popComment: "",
				popSdate: new Date(),
				popEdate: new Date(),
				useYn: "사용",
				addRow: "Y",
				createId: userInfo.userId,
				createDate: new Date(),
				updateId: userInfo.userId,
				updateDate: new Date(),
				newYn: "Y",
			},
		]);

		dispatch(DrawerActions.setNotOpen()); // Drawer 열림 방지
	};

	// 그리드 데이터 저장
	const CreateLogic = async () => {
		setProgress(true);
		let temp = [];
		let tempChagedArr = [];

		try {
			if (changedRowData.length !== 0) {
				temp = changedRowData.reverse().reduce((prev, now) => {
					if (!prev.some((obj) => obj.rcnt === now.rcnt)) {
						prev.push(now);
					}
					return prev;
				}, []);
				// console.log("temp : ", temp);
				temp.forEach((item) => {
					// console.log("item.rcnt : ", item.rcnt);
					tempChagedArr.push({
						rcnt: item.rcnt,
						popComment: item.popComment,
						popLink: item.popLink,
						baseFileData: item.baseFileData,
						useYn: item.useYn === "사용" ? "Y" : "N",
						popSdate: Commons.DateToString(item.popSdate),
						popEdate: Commons.DateToString(item.popEdate),
						createId: item.createId,
						createDate: Commons.DateToString(item.createDate, 2),
						updateId: item.updateId,
						updateDate: Commons.DateToString(item.updateDate, 2),
					});
				});
				// console.log("tempChagedArr : ", tempChagedArr);
				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10060.Create,
					tempChagedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_saved"
						// 			)[0].lanName
						// 			: ""),	// 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_saved"
									)[0].lanName
									: ""),	// 저장 되었습니다!
						});
						setSelectedRowData([]);
						setChangedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // Drawer 열림 가능
						Init();
					} else {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_save_failed"
							)[0].lanName
							: ""),	// 저장 실패!
						);
					}
				});
			} else {
				// 변경된 행이 없으면 에러
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 그리드 데이터 삭제
	const DeleteLogic = async () => {
		setProgress(true);
		let changedArr = [];
		let tempNewYn = selectedRowData.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData.length === 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete"
					)[0].lanName
					: ""),	// 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				selectedRowData.forEach((item) => {
					changedArr.push({
						...item,
						popSdate: Commons.DateToString(item.popSdate),
						popEdate: Commons.DateToString(item.popEdate),
						createDate: Commons.DateToString(item.createDate, 2),
						updateDate: Commons.DateToString(item.updateDate, 2),
					});
				});

				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10060.Delete,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_unused"
						// 		)[0].lanName
						// 		: ""),	// 미사용 처리 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_unused"
									)[0].lanName
									: ""),	// 미사용 처리 되었습니다!
						});
						setSelectedRowData([]);
						setChangedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init();
					} else {
						throw new Error(
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_unused_failed"
								)[0].lanName
								: ""),	// 미사용 처리 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 광고 파일 업로드
	const FileUpload = async () => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			const formData = new FormData();
			formData.append("rcnt", inputs.rcnt);
			formData.append("FormFile", inputs.fileSize);
			formData.append("popLink", inputs.popLink);
			formData.append("userId", userInfo.userId);

			await Commons.Axios(
				"file",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10060.FileUpload,
				null,
				formData
			).then((result) => {
				let data = result.data;

				if (data === false) {
					throw new Error("이미지 업로드 실패!");
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else if (data) {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		(lanList.length > 0
					// 		? lanList.filter(
					// 			(f) =>
					// 				f.lanId ===
					// 				"snack_image_upload"
					// 		)[0].lanName
					// 		: "")	// 이미지 파일이 업로드 되었습니다.
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_image_upload"
								)[0].lanName
								: "")	// 이미지 파일이 업로드 되었습니다.
					});
					Init();
				} else {
					throw new Error((lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_image_upload_failed"
						)[0].lanName
						: "")	// 이미지 업로드 실패!
					);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 광고 파일 다운로드
	const FileDownload = async (_rcnt) => {
		setProgress(true);

		try {
			await Commons.Axios(
				"post",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10060.FileDownload,
				{
					rcnt: _rcnt,
				}
			).then((result) => {
				let data = result.data;

				if (data === false) {
					throw new Error((lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_image_loading_failed"
						)[0].lanName
						: "")	// 이미지 로딩 실패!
					);
				} else if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data) {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		(lanList.length > 0
					// 		? lanList.filter(
					// 			(f) =>
					// 				f.lanId ===
					// 				"snack_image_loading"
					// 		)[0].lanName
					// 		: "")	// 이미지 로딩 성공.
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_image_loading"
								)[0].lanName
								: "")	// 이미지 로딩 성공.
					});
					setInputs({
						...inputs,
						rcnt: _rcnt,
						formFile: "data:image/png;base64," + data, // 존나 긴거
						popLink: rowData.filter(
							(item) => item.rcnt === _rcnt
						)[0].baseFileData, // 파일이름.png
					});
					setCheckingToggle1(true);
				} else {
					throw new Error((lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_image_loading_failed"
						)[0].lanName
						: "")	// 이미지 로딩 실패!
					);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 이미지 파일 로컬 다운로드
	const saveAs = async (blob, fileName) => {
		//base to blod
		const byteCharacters = atob(blob);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob2 = new Blob([byteArray]);
		var link = document.createElement("a");
		link.href = URL.createObjectURL(blob2);
		link.download = fileName;
		link.style.display = "none";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			<BM10060View
				// 언어팩
				lanList={lanList}
				// 코드 리스트
				codeList={codeList}
				// 그리드 데이터
				rowData={rowData}
				setRowData={setRowData}
				selectedRowData={selectedRowData}
				setSelectedRowData={setSelectedRowData}
				changedRowData={changedRowData}
				setChangedRowData={setChangedRowData}
				inputs={inputs}
				setInputs={setInputs}
				checkingToggle1={checkingToggle1}
				setCheckingToggle1={setCheckingToggle1}
				setSnacks={setSnacks}
				// 함수
				CheckingLogic={CheckingLogic}
				CreateLogic={CreateLogic}
				DeleteLogic={DeleteLogic}
				FileUpload={FileUpload}
				FileDownload={FileDownload}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "bm10060_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</>
	);
}

export default BM10060;
