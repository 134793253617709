import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components */
import {
	CardHeader,
	CardContent,
	IconButton,
	Typography,
} from "@mui/material";
import { Unstable_Grid2 as Grid, Card, Box, Fab } from "@mui/material";
import { DashboardHeader } from "../../Components/Conotec";
import { green } from "@mui/material/colors";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
import { Combo } from "../../Components/Combos";
import Chart from "react-apexcharts";
/** Icons Import */
import {
	RestartAlt as ReloadIcon,
	Check as CheckIcon,
} from "@mui/icons-material";

function DM10030(props) {
	const [success, setSuccess] = React.useState(false);
	const [writeDialog, setWriteDialog] = React.useState(false);

	React.useEffect(() => {
		if (props.determinateValue === 100) {
			setSuccess(true);
		} else {
			setSuccess(false);
		}
	}, [props.determinateValue]);

	const buttonSx = {
		...(success && {
			bgcolor: green[500],
			"&:hover": {
				bgcolor: green[700],
			},
		}),
	};

	const dashboardChart = React.useMemo(() => (
		<Chart
			type="line"
			height={
				Commons.IsMobile()
					? Commons.Dimentions().height * 0.35
					: Commons.Dimentions().height * 0.55
			}
			options={{
				theme: {
					mode: "dark",
				},
				chart: {
					id: "basic-line",
					toolbar: {
						show: false,
						tools: {
							download: false,
							pan: false,
						},
					},
					zoom: {
						enabled: false,
					},
					// zoom: {
					// 	enabled: true,
					// 	type: 'x',
					// 	zoomedArea: {
					// 		fill: {
					// 			color: '#90CAF9',
					// 			opacity: 0.4
					// 		},
					// 		stroke: {
					// 			color: '#0D47A1',
					// 			opacity: 0.4,
					// 			width: 1
					// 		}
					// 	}
					// }
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					categories: props.chartData.categories,
					tickAmount: 10,
				},
				yaxis: props.chartData.yaxis,
				dataLabels: {
					enabled: false,
				},
			}}
			series={props.chartData.data}
		/>
	), [props.chartData.data]);

	return (
		<>
			<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid xs={4} md={12}>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<IconButton onClick={() => props.ReloadPage()}>
							<Typography sx={{ mr: 1 }}>
								{
									props.determinateLabel +
									(props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId === "home_gen_time"
										)[0].lanName
										: "") // 초후
								}
							</Typography>
							<ReloadIcon fontSize="small" />
						</IconButton>
					</div>
				</Grid>
				<Grid xs={4} sm={8} md={12}>
					<Card>
						<DashboardHeader
							items={props.controllerData}
							controllerDetail={props.controllerDetail}
							setDialog={setWriteDialog}
						/>
					</Card>
					{/* <Card
						sx={{
							display: "flex",
							justifyContent: "space-between",
							height: 80
						}}
					>
						<Combo
							name={"품목 타입 선택"}
							value={props.itemTypeValue}
							items={props.itemTypeItems.length === 0 ? [] : props.itemTypeItems}
							onChange={(e) => {
								props.setItemTypeValue(e);

								switch (e) {
									case "1": {
										props.setChartData({
											...props.chartData,
											data: [
												{
													name: props.controllerData[0].machNm,
													data: props.curTmp,
												},
											]
										});
										break;
									}
									case "2": {
										props.setChartData({
											...props.chartData,
											data: [
												{
													name: props.controllerData[0].machNm,
													data: props.curHdm,
												},
											]
										});
										break;
									}
									case "3": {
										props.setChartData({
											...props.chartData,
											data: [
												{
													name: props.controllerData[0].machNm,
													data: props.curIll,
												},
											]
										});
										break;
									}
									case "4": {
										props.setChartData({
											...props.chartData,
											data: [
												{
													name: props.controllerData[0].machNm,
													data: props.curCo2,
												},
											]
										});
										break;
									}
								}
							}}
							fullWidth={true}
							sx={{
								marginTop: 1,
							}}
						/>
						</Card>*/}
					<Card>
						{/* <Chart
							type="line"
							height={
								Commons.IsMobile()
									? Commons.Dimentions().height * 0.35
									: Commons.Dimentions().height * 0.55
							}
							options={{
								theme: {
									mode: "dark",
								},
								chart: {
									id: "basic-line",
									toolbar: {
										show: false,
										tools: {
											download: false,
											pan: false,
										},
									},
									zoom: {
										enabled: false,
									},
								},
								stroke: {
									curve: "smooth",
								},
								xaxis: {
									categories: props.chartData.categories,
									tickAmount: 10,
								},
								yaxis: props.chartData.yaxis,
								dataLabels: {
									enabled: false,
								},
							}}
							series={props.chartData.data}
						/> */}
						{dashboardChart}
					</Card>
				</Grid>
			</Grid>
			<Dialog
				open={writeDialog}
				title={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"dashboard_pop_ch_title"
					)[0].lanName
					: ""	// 장치 이름 변경
				}
				onClose={() => {
					setWriteDialog(false);
					props.setWriteValue("");
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"dashboard_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"dashboard_pop_save"
					)[0].lanName
					: ""	// 저장
				}
				onSave={() => props.ChangeItemName()}
				children={
					<Input
						value={props.writeValue}
						onChange={(e) => props.setWriteValue(e)}
					/>
				}
			/>
		</>
	);
}

export default DM10030;
