import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	List as MuiList,
	ListItem as MuiListItem,
	ListItemButton as MuiListItemButton,
	ListItemText as MuiListItemText,
	ListItemAvatar as MuiListItemAvatar,
	Divider,
	Avatar,
	Typography,
} from "@mui/material";
import { Button } from "../Buttons";
/** Icons Import */
import {
	DeviceThermostat as TemperatureIcon,
	Opacity as HumidityIcon,
	Co2 as Co2Icon,
	BrightnessMedium as LiluminationIcon,
} from "@mui/icons-material";

const List = styled((props) => <MuiList {...props} />)(({ theme }) => ({
	color: "#ffffff",
	backgroundColor: "#003c8b",
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: "5px",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
	padding: Commons.IsMobile() ? 0 : "8px 0px",
}));

const ListHeaders = styled((props) => <MuiListItemText {...props} />)(
	({ theme }) => ({})
);

const ListHeaderText = styled((props) => (
	<Typography variant="h6" {...props} />
))(({ theme }) => ({}));

const ListBodys = styled((props) => <MuiListItemButton {...props} />)(
	({ theme }) => ({
		display: "block",
		margin: 0,
		padding: 0,
	})
);

const ListBodyItem = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
}));

const ItemTextDivider = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	marginTop: "8px",
	marginBottom: "8px",
}));

function ControllerWideList({
	items = [], // ControllerHomeList's item list
}) {
	const RetrunData = items.map((item, index) => {
		let getTwoItems = [];
		let tempArr = [];

		const SettingOptions = item.options.forEach((item1, index1) => {
			if ((index1 + 1) % 2 === 0 || index1 + 1 === item.options.length) {
				tempArr.push(item1);
				getTwoItems.push(tempArr);
				tempArr = [];
			} else {
				tempArr.push(item1);
			}
		});

		return (
			<Grid key={index} xs={12} md={4}>
				{SettingOptions}
				<Card>
					<List>
						<MuiListItem>
							<ListHeaders>
								<ListHeaderText>{item.name}</ListHeaderText>
							</ListHeaders>
							<Button
								name="설정"
								onClick={() => item.ButtonClick(item)}
							/>
						</MuiListItem>
						<Divider
							sx={{ borderBottom: 1 }}
							variant="middle"
							light
						/>
						<MuiListItem>
							<ListBodys onClick={() => item.ListClick(item)}>
								{getTwoItems.map((option, idx) => {
									return (
										<ListBodyItem key={idx}>
											{option.map((option1, idx1) => {
												const ReturnData = () => {
													switch (option1.type) {
														case "temp":
															return (
																<MuiListItemText
																	key={idx1}
																>
																	<ItemTextDivider>
																		<TemperatureIcon
																			sx={{
																				fontSize:
																					"55px",
																				mx: 1,
																			}}
																			color={
																				option1.status ==
																					"on"
																					? "success"
																					: "disabled"
																			}
																		/>
																		<Typography variant="h4">
																			{
																				option1.value
																			}
																			&ordm;C
																		</Typography>
																	</ItemTextDivider>
																</MuiListItemText>
															);
														case "Humi":
															return (
																<MuiListItemText
																	key={idx1}
																>
																	<ItemTextDivider>
																		<HumidityIcon
																			sx={{
																				fontSize:
																					"40px",
																				mx: 1,
																			}}
																			color={
																				option1.status ==
																					"on"
																					? "success"
																					: "disabled"
																			}
																		/>
																		<Typography variant="h6">
																			{
																				option1.value
																			}
																			%
																		</Typography>
																	</ItemTextDivider>
																</MuiListItemText>
															);
														case "Lilu":
															return (
																<MuiListItemText
																	key={idx1}
																>
																	<ItemTextDivider>
																		<LiluminationIcon
																			sx={{
																				fontSize:
																					"40px",
																				mx: 1,
																			}}
																			color={
																				option1.status ==
																					"on"
																					? "success"
																					: "disabled"
																			}
																		/>
																		<Typography variant="h6">
																			{
																				option1.value
																			}
																			%
																		</Typography>
																	</ItemTextDivider>
																</MuiListItemText>
															);
														case "Co2":
															return (
																<MuiListItemText
																	key={idx1}
																>
																	<ItemTextDivider>
																		<Co2Icon
																			sx={{
																				fontSize:
																					"40px",
																				mx: 1,
																			}}
																			color={
																				option1.status ==
																					"on"
																					? "success"
																					: "disabled"
																			}
																		/>
																		<Typography variant="h6">
																			{
																				option1.value
																			}
																			%
																		</Typography>
																	</ItemTextDivider>
																</MuiListItemText>
															);
													}
												};

												return ReturnData();
											})}
										</ListBodyItem>
									);
								})}
							</ListBodys>
						</MuiListItem>
					</List>
				</Card>
			</Grid>
		);
	});

	return RetrunData;
}

export default ControllerWideList;
