import React, { forwardRef } from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card, Tabs, Tab } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { DatePicker } from "../../Components/DatePicker";
import { Combo, AutoCombo } from "../../Components/Combos";
import { Snackbar } from "../../Components/Snackbar";
import { TextField } from "@mui/material";
import Chart from "react-apexcharts";
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";
/** Icons */
import minus from "../../../node_modules/apexcharts/src/assets/ico-minus-square.svg";
import plus from "../../../node_modules/apexcharts/src/assets/ico-plus-square.svg";

function LM10010(props) {
	const gridRef = React.useRef();
	const inputRef1 = React.useRef();
	const inputRef2 = React.useRef();
	const [tabToggle, setTabToggle] = React.useState(0);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});

	const chart = React.useMemo(() => (
		<Chart
			type="line"
			height={
				Commons.IsMobile()
					? Commons.Dimentions().height * 0.58
					: Commons.Dimentions().height * 0.6
			}
			options={{
				theme: {
					mode: "dark",
				},
				chart: {
					id: "basic-line",
					type: "line",
					toolbar: {
						show: true,
						tools: {
							download: false,
							selection: true,
							zoomin: Commons.IsMobile()
								? false
								: '<img class="zoomin" src="' +
								plus +
								'" style="margin-right:60px; margin-left:-60px;" width="30" height="30" />',
							zoomout: Commons.IsMobile()
								? false
								: '<img class="zoomout" src="' +
								minus +
								'" style="margin-right:-0px; margin-left:-60px;" width="30" height="30" />',
							// zoomin: '<img class="zoomin" src="' +
							// 	plus +
							// 	'" style="margin-right:60px; margin-left:-60px;" width="30" height="30" />',
							// zoomout: '<img class="zoomout" src="' +
							// 	minus +
							// 	'" style="margin-right:-0px; margin-left:-60px;" width="30" height="30" />',
							pan: true,
							reset: true,
						},
					},
					animations: {
						enabled: false,
					},
					// zoom: {
					// 	enabled: true,
					// 	type: "x",
					// 	autoScaleYaxis: true,
					// },
				},
				stroke: {
					curve: "smooth",
				},
				legend: {
					fontSize: "20px",
					markers: {
						width: 15,
					},
					itemMargin: {
						horizontal: 15,
					},
				},
				markers: {
					size: 0,
				},
				xaxis: {
					categories: props.chartData.categories,
					tickPlacement: "on",
					tickAmount: 10,
					tooltip: {
						enabled: false,
					},
				},
			}}
			series={props.chartData.data}
		/>
	), [props.chartData]);

	// console.log("minus : ", minus);
	// console.log("plus : ", plus);

	const TabChanged = (event, newValue) => {
		if (newValue === 1) {
			if (props.comboValue.length === 0) {
				setSnacks({
					open: true,
					type: "error",
					message:
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId === "snack_setting_controller"
							)[0].lanName
							: "", // 컨트롤러를 선택해 주세요.
				});
				props.setChartData({
					categories: [],
					data: [],
				});
			}
		}
		setTabToggle(newValue);
	};

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<TextField
			style={{
				width: Commons.IsMobile() ? "120px" : "150px",
			}}
			size="small"
			variant="outlined"
			value={value}
			onClick={onClick}
			ref={ref}
			// inputMode="none"
			inputProps={{
				inputMode: "none",
			}}
		/>
	));

	return (
		<>
			<Tabs value={tabToggle} onChange={TabChanged}>
				<Tab
					value={0}
					label={
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId === "lm10010_tab_tab_look_grid"
							)[0].lanName
							: ""
					} // 그리드 보기
				/>
				<Tab
					value={1}
					label={
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId === "lm10010_tab_tab_look_chart"
							)[0].lanName
							: ""
					} // 차트 보기
				/>
			</Tabs>
			{tabToggle === 0 && (
				<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={4} sm={8} md={12}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: Commons.IsMobile()
										? "column"
										: "row",
									alignItems: "center",
									// height: "100px",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										marginTop: "10px",
									}}
								>
									<DatePicker
										className={
											Commons.IsMobile()
												? "datepicker-custom-input2"
												: "datepicker-custom-input"
										}
										style={{
											marginRight: "5px",
											marginLeft: "5px",
										}}
										selected={props.fromDate}
										minDate={
											new Date(
												new Date().setFullYear(
													new Date().getFullYear() - 1
												)
											)
										}
										maxDate={new Date()}
										onChange={(e) => {
											props.setFromDate(e);
										}}
										customInput={<CustomInput />}
									/>
									~
									<DatePicker
										className={
											Commons.IsMobile()
												? "datepicker-custom-input2"
												: "datepicker-custom-input"
										}
										style={{
											marginRight: "5px",
											marginLeft: "5px",
										}}
										selected={props.toDate}
										minDate={props.fromDate}
										maxDate={new Date()}
										onChange={(e) => {
											props.setToDate(e);
										}}
										customInput={<CustomInput />}
									/>
								</div>
								<AutoCombo
									options={props.comboItems}
									multiple={true}
									disableCloseOnSelect={true}
									label={
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"lm10010_gen_select_controller"
											)[0].lanName
											: ""
									} // 컨트롤러 선택
									defaultValue={props.comboValue}
									onChange={(e, v) => {
										props.setComboValue(v);
									}}
									style={{
										marginLeft: Commons.IsMobile()
											? 10
											: 10,
										marginTop: 5,
										marginBottom: 5,
										width: Commons.IsMobile() ? 250 : 500,
										height: Commons.IsMobile() ? 50 : 50,
									}}
									sx={{
										overflow: Commons.IsMobile()
											? "scroll"
											: "none",
									}}
								/>
							</div>
							<AuthGroupButton
								pageName="LM10010"
								target="G01"
								selectFunc={() => {
									if (props.comboValue.length === 0) {
										setSnacks({
											open: true,
											type: "error",
											message:
												props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"snack_setting_controller"
													)[0].lanName
													: "", // 컨트롤러를 선택해 주세요.
										});
										props.setRowData([]);
										props.setChartData({
											categories: [],
											data: [],
										});
									} else {
										if (props.fromDate > props.toDate) {
											props.setToDate(props.fromDate);
											props.setSnacks({
												open: true,
												type: "error",
												message:
													props.lanList.length > 0
														? props.lanList.filter(
															(f) =>
																f.lanId ===
																"snack_check_period"
														)[0].lanName
														: "", // 조회 기간을 다시 확인하세요.
											});
										} else {
											if (
												props.fromDate.getFullYear() !==
												props.toDate.getFullYear()
											) {
												props.GetBetweenTwoYear();
											} else {
												props.BetweenDate();
											}
										}
									}
								}}
							/>
						</Card>
					</Grid>
					<Grid xs={12} md={12}>
						<Card>
							<div
								className="ag-theme-alpine-dark"
								style={{
									width: "100%",
									height:
										Commons.Dimentions().height < 800
											? Commons.Dimentions().height * 0.58
											: Commons.Dimentions().height * 0.7,
								}}
							>
								<AgGridReact
									ref={gridRef}
									animateRows={true}
									columnDefs={props.columnData}
									defaultColDef={{
										editable: false,
										resizable: true,
										sortable: true,
										filter: true,
										floatingFilter: true,
									}}
									rowData={props.rowData}
									rowSelection="multiple"
									singleClickEdit={
										Commons.IsMobile() ? true : false
									}
									getRowStyle={(e) =>
										GridFunctions.getRowStyle(e)
									}
								/>
							</div>
						</Card>
					</Grid>
				</Grid>
			)}
			{tabToggle === 1 && (
				<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={4} sm={8} md={12}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: Commons.IsMobile()
										? "column"
										: "row",
									alignItems: "center",
								}}
							>
								<Combo
									name={
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"lm10010_gen_select_type"
											)[0].lanName
											: ""
										// 품목 타입 선택
									}
									value={props.itemTypeValue}
									items={
										props.itemTypeItems.length === 0
											? []
											: props.itemTypeItems
									}
									onChange={(e) => {
										props.setItemTypeValue(e);
										switch (e) {
											case "1": {
												props.setChartData({
													...props.chartData,
													data: props.curTmp,
												});
												break;
											}
											case "2": {
												props.setChartData({
													...props.chartData,
													data: props.curHdm,
												});
												break;
											}
											case "3": {
												props.setChartData({
													...props.chartData,
													data: props.curIll,
												});
												break;
											}
											case "4": {
												props.setChartData({
													...props.chartData,
													data: props.curCo2,
												});
												break;
											}
										}
									}}
									size="small"
									fullWidth={true}
									sx={{
										marginLeft: 2,
										marginTop: 2,
										width: Commons.IsMobile() ? 250 : 500,
										height: Commons.IsMobile() ? 50 : 50,
									}}
								/>
							</div>
						</Card>
						<Card
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<Chart
								type="line"
								height={
									Commons.IsMobile()
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.6
								}
								options={{
									theme: {
										mode: "dark",
									},
									chart: {
										id: "basic-line",
										type: "line",
										toolbar: {
											show: true,
											tools: {
												download: false,
												selection: true,
												zoomin: Commons.IsMobile()
													? false
													: '<img class="zoomin" src="' +
													plus +
													'" style="margin-right:60px; margin-left:-60px;" width="30" height="30" />',
												zoomout: Commons.IsMobile()
													? false
													: '<img class="zoomout" src="' +
													minus +
													'" style="margin-right:-0px; margin-left:-60px;" width="30" height="30" />',
												// zoomin: '<img class="zoomin" src="' +
												// 	plus +
												// 	'" style="margin-right:60px; margin-left:-60px;" width="30" height="30" />',
												// zoomout: '<img class="zoomout" src="' +
												// 	minus +
												// 	'" style="margin-right:-0px; margin-left:-60px;" width="30" height="30" />',
												pan: true,
												reset: true,
											},
										},
										animations: {
											enabled: false,
										},
										// zoom: {
										// 	enabled: true,
										// 	type: "x",
										// 	autoScaleYaxis: true,
										// },
									},
									stroke: {
										curve: "smooth",
									},
									legend: {
										fontSize: "20px",
										markers: {
											width: 15,
										},
										itemMargin: {
											horizontal: 15,
										},
									},
									markers: {
										size: 0,
									},
									xaxis: {
										categories: props.chartData.categories,
										tickPlacement: "on",
										tickAmount: 11,
										tooltip: {
											enabled: false,
										},
									},
								}}
								series={props.chartData.data}
							/>
						</Card>
					</Grid>
				</Grid>
			)}
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
		</>
	);
}

export default LM10010;
