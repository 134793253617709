import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	List as MuiList,
	ListItem as MuiListItem,
	ListItemButton as MuiListItemButton,
	ListItemText as MuiListItemText,
	ListItemAvatar as MuiListItemAvatar,
	Divider,
	Avatar,
	Typography,
} from "@mui/material";
import { Button } from "../Buttons";
/** Icons Import */
import {
	DeviceThermostat as TemperatureIcon,
	Opacity as HumidityIcon,
	Co2 as Co2Icon,
	BrightnessMedium as LiluminationIcon,
} from "@mui/icons-material";

const List = styled((props) => <MuiList {...props} />)(({ theme }) => ({
	color: "#ffffff",
	backgroundColor: "#003c8b",
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: "5px",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
	padding: Commons.IsMobile() ? 0 : "8px 0px",
}));

const ListBodys = styled((props) => <div {...props} />)(({ theme }) => ({
	width: "100%",
	display: "block",
	margin: 0,
	padding: 0,
}));

const ListBodyItem = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-evenly",
	alignItems: "center",
}));

const ItemTextDivider = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	marginTop: "8px",
	marginBottom: "8px",
}));

function DashboardStatusList({
	items = [], // DashboardStatusList's item list
}) {
	let getTreeItems = [];
	let tempArr = [];

	items.forEach((item1, index1) => {
		if ((index1 + 1) % 2 === 0 || index1 + 1 === items.length) {
			tempArr.push(item1);
			getTreeItems.push(tempArr);
			tempArr = [];
		} else {
			tempArr.push(item1);
		}
	});

	const ReturnData = getTreeItems.map((item, index) => {
		return (
			<MuiListItem key={index} sx={{ py: 0, px: 1 }}>
				<ListBodys>
					<ListBodyItem>
						{item.map((item1, index1) => {
							const ReturnStatus = () => {
								switch (item1.type) {
									case "comp":
										return (
											<MuiListItemText
												key={index1}
												style={{ width: "150px" }}
											>
												<ItemTextDivider>
													<TemperatureIcon
														sx={{
															fontSize: "40px",
															mx: 1,
														}}
														color={
															item1.status ==
															"normal"
																? "success"
																: "disabled"
														}
													/>
													<Typography variant="h6">
														콤프 상태
													</Typography>
												</ItemTextDivider>
											</MuiListItemText>
										);
									case "defrosting":
										return (
											<MuiListItemText
												key={index1}
												style={{ width: "150px" }}
											>
												<ItemTextDivider>
													<TemperatureIcon
														sx={{
															fontSize: "40px",
															mx: 1,
														}}
														color={
															item1.status ==
															"normal"
																? "success"
																: "disabled"
														}
													/>
													<Typography variant="h6">
														제상 상태
													</Typography>
												</ItemTextDivider>
											</MuiListItemText>
										);
									case "fan":
										return (
											<MuiListItemText
												key={index1}
												style={{ width: "150px" }}
											>
												<ItemTextDivider>
													<TemperatureIcon
														sx={{
															fontSize: "40px",
															mx: 1,
														}}
														color={
															item1.status ==
															"normal"
																? "success"
																: "disabled"
														}
													/>
													<Typography variant="h6">
														팬 상태
													</Typography>
												</ItemTextDivider>
											</MuiListItemText>
										);
									case "abnormal-signal":
										return (
											<MuiListItemText
												key={index1}
												style={{ width: "150px" }}
											>
												<ItemTextDivider>
													<TemperatureIcon
														sx={{
															fontSize: "40px",
															mx: 1,
														}}
														color={
															item1.status ==
															"normal"
																? "success"
																: "disabled"
														}
													/>
													<Typography variant="h6">
														이상 신호
													</Typography>
												</ItemTextDivider>
											</MuiListItemText>
										);
									case "high-temp-alarm":
										return (
											<MuiListItemText
												key={index1}
												style={{ width: "150px" }}
											>
												<ItemTextDivider>
													<TemperatureIcon
														sx={{
															fontSize: "40px",
															mx: 1,
														}}
														color={
															item1.status ==
															"normal"
																? "success"
																: "disabled"
														}
													/>
													<Typography variant="h6">
														고온 경보
													</Typography>
												</ItemTextDivider>
											</MuiListItemText>
										);
									case "low-temp-alarm":
										return (
											<MuiListItemText
												key={index1}
												style={{ width: "150px" }}
											>
												<ItemTextDivider>
													<TemperatureIcon
														sx={{
															fontSize: "40px",
															mx: 1,
														}}
														color={
															item1.status ==
															"normal"
																? "success"
																: "disabled"
														}
													/>
													<Typography variant="h6">
														저온 경보
													</Typography>
												</ItemTextDivider>
											</MuiListItemText>
										);
									case "low-temp-prevent":
										return (
											<MuiListItemText
												key={index1}
												style={{ width: "150px" }}
											>
												<ItemTextDivider>
													<TemperatureIcon
														sx={{
															fontSize: "40px",
															mx: 1,
														}}
														color={
															item1.status ==
															"normal"
																? "success"
																: "disabled"
														}
													/>
													<Typography variant="h6">
														저온 방지
													</Typography>
												</ItemTextDivider>
											</MuiListItemText>
										);
									case "alarm":
										return (
											<MuiListItemText
												key={index1}
												style={{ width: "150px" }}
											>
												<ItemTextDivider>
													<TemperatureIcon
														sx={{
															fontSize: "40px",
															mx: 1,
														}}
														color={
															item1.status ==
															"normal"
																? "success"
																: "disabled"
														}
													/>
													<Typography variant="h6">
														경보
													</Typography>
												</ItemTextDivider>
											</MuiListItemText>
										);
								}
							};

							return ReturnStatus();
						})}
					</ListBodyItem>
				</ListBodys>
			</MuiListItem>
		);
	});

	return (
		<Grid xs={12} md={4}>
			<Card>
				<List>{ReturnData}</List>
			</Card>
		</Grid>
	);
}

export default DashboardStatusList;
