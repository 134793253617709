import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
/** Components Import */
import RequireAuth from "./RequireAuth";
import Login from "../Factories/Account/Login";
import Drawer from "./Drawer";
import Home from "../Factories/Home/Home"; // 메인 화면
import ChangePw from "../Factories/Account/ChangePw"; // 패스워드 변경
import Approval from "../Factories/Home/Approval"; // 유료 결제
import Settings from "../Factories/Home/Settings"; // 설정
import SystemSetting from "../Factories/Home/SystemSetting"; // 시스템 설정
import Swapping from "../Factories/Home/Swapping"; // 순서변경
import KakaoAuth from "../Factories/Account/Kakao/KakaoAuth";

/** BaseManagements */
import BM10010 from "../Factories/BaseManagements/BM10010"; // 사용자관리
import BM10020 from "../Factories/BaseManagements/BM10020"; // 컨트롤러등록
import BM10030 from "../Factories/BaseManagements/BM10030"; // 권한관리
import BM10040 from "../Factories/BaseManagements/BM10040"; // 기초코드관리
import BM10050 from "../Factories/BaseManagements/BM10050"; // 맥주소관리
import BM10060 from "../Factories/BaseManagements/BM10060"; // 광고 관리
import BM10070 from "../Factories/BaseManagements/BM10070"; // 마스터 관리
import BM10080 from "../Factories/BaseManagements/BM10080"; // 언어 관리

/** ControllerManagements */
import CM10000 from "../Factories/ControllerManagements/CM10000"; // 컨트롤러관리

/** LogManagements */
import LM10010 from "../Factories/LogManagements/LM10010"; // HACCP 로그 조회
import LM10020 from "../Factories/LogManagements/LM10020"; // 사용자 로그 조회
import LM10030 from "../Factories/LogManagements/LM10030"; // 컨트롤러 로그 조회
import LM10040 from "../Factories/LogManagements/LM10040"; // 예약 로그 조회
import LM10050 from "../Factories/LogManagements/LM10050"; // 컨트롤러 조회
import LM10060 from "../Factories/LogManagements/LM10060"; // 푸쉬 로그 조회

/** DashboardManagements */
import DM10010 from "../Factories/DashboardManagements/DM10010"; // 대시보드 선택
import DM10030 from "../Factories/DashboardManagements/DM10030"; // 온도변화 대시보드

/** SettingManagements */
import SM10010 from "../Factories/SettingManagements/SM10010"; // 설정선택

export default function Routers() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						<RequireAuth>
							<Login />
						</RequireAuth>
					}
				/>
				<Route
					path="/auth"
					element={
						<RequireAuth>
							<KakaoAuth />
						</RequireAuth>
					}
				/>
				<Route
					element={
						<RequireAuth>
							<Drawer />
						</RequireAuth>
					}
				>
					<Route path="/Home" element={<Home />} />
					<Route path="/Swapping" element={<Swapping />} />
					<Route path="/Settings" element={<Settings />} />
					<Route path="/SystemSetting" element={<SystemSetting />} />
					<Route path="/ChangePw" element={<ChangePw />} />
					<Route path="/Approval" element={<Approval />} />
					<Route path="BaseManagement">
						<Route
							path="/BaseManagement/BM10010"
							element={<BM10010 />}
						/>
						<Route
							path="/BaseManagement/BM10020"
							element={<BM10020 />}
						/>
						<Route
							path="/BaseManagement/BM10030"
							element={<BM10030 />}
						/>
						<Route
							path="/BaseManagement/BM10040"
							element={<BM10040 />}
						/>
						<Route
							path="/BaseManagement/BM10050"
							element={<BM10050 />}
						/>
						<Route
							path="/BaseManagement/BM10060"
							element={<BM10060 />}
						/>
						<Route
							path="/BaseManagement/BM10070"
							element={<BM10070 />}
						/>
						<Route
							path="/BaseManagement/BM10080"
							element={<BM10080 />}
						/>
					</Route>
					<Route path="ControllerManagement">
						<Route
							path="/ControllerManagement/CM10000"
							element={<CM10000 />}
						/>
					</Route>
					<Route path="DashBoardManagement">
						<Route
							path="/DashBoardManagement/DM10000"
							element={<DM10010 />}
						/>
						<Route
							path="/DashBoardManagement/DM10030"
							element={<DM10030 />}
						/>
					</Route>
					<Route path="SettingManagement">
						<Route
							path="/SettingManagement/SM10000"
							element={<SM10010 />}
						/>
					</Route>
					<Route path="LogManagement">
						<Route
							path="/LogManagement/LM10010"
							element={<LM10010 />}
						/>
						<Route
							path="/LogManagement/LM10020"
							element={<LM10020 />}
						/>
						<Route
							path="/LogManagement/LM10030"
							element={<LM10030 />}
						/>
						<Route
							path="/LogManagement/LM10040"
							element={<LM10040 />}
						/>
						<Route
							path="/LogManagement/LM10050"
							element={<LM10050 />}
						/>
						<Route
							path="/LogManagement/LM10060"
							element={<LM10060 />}
						/>
					</Route>
				</Route>
				<Route
					path="*"
					element={
						<RequireAuth>
							<Login />
						</RequireAuth>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}
