import React, { forwardRef } from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card, Tabs, Tab } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { DatePicker } from "../../Components/DatePicker";
import { TextField } from "@mui/material";
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";

function LM10060(props) {
	const [tabToggle, setTabToggle] = React.useState(0);
	const gridRef = React.useRef();
	const gridRef1 = React.useRef();
	const columnDefs = [
		{
			field: "num",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_number"
					)[0].lanName
					: "", // 연번
			minWidth: 80,
		},
		{
			field: "TrdDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_register_date"
					)[0].lanName
					: "", // 등록일
			minWidth: 140,
		},
		{
			field: "UserId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_user_id"
					)[0].lanName
					: "", // 사용자
			minWidth: 140,
		},
		{
			field: "SndDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_trans_date"
					)[0].lanName
					: "", // 전송일시
			minWidth: 180,
		},
		{
			field: "SndType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_trans_type"
					)[0].lanName
					: "", // 전송타입
			minWidth: 180,
		},
		{
			field: "PsTitle",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_push_title"
					)[0].lanName
					: "", // 푸쉬 제목
			minWidth: 200,
		},
		{
			field: "ItemNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_item_nm"
					)[0].lanName
					: "", // 푸쉬 메세지
			minWidth: 200,
		},
		{
			field: "PsComment",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_push_message"
					)[0].lanName
					: "", // 푸쉬 메세지
			minWidth: 300,
		},
	];
	const columnDefs2 = [
		{
			field: "num",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_number"
					)[0].lanName
					: "", // 연번
			minWidth: 80,
		},
		{
			field: "TrdDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_register_date"
					)[0].lanName
					: "", // 등록일
			minWidth: 140,
		},
		{
			field: "UserId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_user_id"
					)[0].lanName
					: "", // 사용자
			minWidth: 140,
		},
		// {
		// 	field: "CreateDate",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "lm10060_col_trans_request_date"
		// 			)[0].lanName
		// 			: "", // 전송 요청 일시
		// 	minWidth: 180,
		// },
		{
			field: "SndDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_trans_date"
					)[0].lanName
					: "", // 전송일시
			minWidth: 180,
		},
		{
			field: "SndType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_trans_type"
					)[0].lanName
					: "", // 전송타입
			minWidth: 180,
		},
		{
			field: "ErrMsg",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_err_msg"
					)[0].lanName
					: "", // 에러 메시지
			minWidth: 180,
		},
		{
			field: "PsTitle",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_push_title"
					)[0].lanName
					: "", // 푸쉬 제목
			minWidth: 200,
		},
		{
			field: "ItemNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_item_nm"
					)[0].lanName
					: "", // 푸쉬 메세지
			minWidth: 200,
		},
		{
			field: "PsComment",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10060_col_push_message"
					)[0].lanName
					: "", // 푸쉬 메세지
			minWidth: 300,
		},
	];

	const TabChanged = (event, newValue) => {
		setTabToggle(newValue);
	};

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<TextField
			size="small"
			variant="outlined"
			value={value}
			onClick={onClick}
			autoFocus={false}
			ref={ref}
			inputProps={{
				inputMode: "none",
			}}
		/>
	));

	return (
		<>
			<Tabs value={tabToggle} onChange={TabChanged}>
				<Tab
					value={0}
					label={
						props.lanList.length > 0
							? props.lanList.filter(
								(f) => f.lanId === "lm10060_tab_trans_log"
							)[0].lanName
							: ""
					} // 전송 로그
				/>
				<Tab
					value={1}
					label={
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId === "lm10060_tab_trans_err_log"
							)[0].lanName
							: ""
					} // 전송 에러 로그
				/>
				<Tab
					value={2}
					label={
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId === "lm10060_tab_kakao_log"
							)[0].lanName
							: ""
					} // 카카오 메시지 로그
				/>
			</Tabs>
			{tabToggle === 0 && (
				<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={12} md={12}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginTop: "10px",
								}}
							>
								<DatePicker
									className={
										Commons.IsMobile()
											? "datepicker-custom-input2"
											: "datepicker-custom-input"
									}
									style={{
										marginRight: "5px",
										marginLeft: "5px",
									}}
									selected={props.fromDate}
									maxDate={new Date()}
									onChange={(e) => {
										props.setFromDate(e);
									}}
									customInput={<CustomInput />}
								/>
								~
								<DatePicker
									className={
										Commons.IsMobile()
											? "datepicker-custom-input2"
											: "datepicker-custom-input"
									}
									style={{
										marginRight: "5px",
										marginLeft: "5px",
									}}
									selected={props.toDate}
									minDate={props.fromDate}
									maxDate={new Date()}
									onChange={(e) => {
										props.setToDate(e);
									}}
									customInput={<CustomInput />}
								/>
							</div>
							<AuthGroupButton
								pageName="LM10060"
								target="G01"
								selectFunc={() => {
									if (props.fromDate > props.toDate) {
										props.setToDate(props.fromDate);
										props.setSnacks({
											open: true,
											type: "error",
											message:
												props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"snack_check_period"
													)[0].lanName
													: "", // 조회 기간을 다시 확인하세요.
										});
									} else {
										props.BetweenDate();
									}
								}}
							/>
						</Card>
					</Grid>
					<Grid xs={12} md={12}>
						<Card>
							<div
								className="ag-theme-alpine-dark"
								style={{
									width: "100%",
									height:
										Commons.Dimentions().height < 800
											? Commons.Dimentions().height * 0.58
											: Commons.Dimentions().height *
											0.74,
								}}
							>
								<AgGridReact
									ref={gridRef}
									animateRows={true}
									columnDefs={columnDefs}
									defaultColDef={{
										editable: false,
										resizable: true,
										sortable: true,
										filter: true,
										floatingFilter: true,
									}}
									rowData={props.rowData}
									rowSelection="single"
									singleClickEdit={
										Commons.IsMobile() ? true : false
									}
									getRowStyle={(e) =>
										GridFunctions.getRowStyle(e)
									}
								/>
							</div>
						</Card>
					</Grid>
				</Grid>
			)}
			{tabToggle === 1 && (
				<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={12} md={12}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginTop: "10px",
								}}
							>
								<DatePicker
									className={
										Commons.IsMobile()
											? "datepicker-custom-input2"
											: "datepicker-custom-input"
									}
									style={{
										marginRight: "5px",
										marginLeft: "5px",
									}}
									selected={props.fromDate}
									maxDate={new Date()}
									onChange={(e) => {
										props.setFromDate(e);
									}}
									customInput={<CustomInput />}
								/>
								~
								<DatePicker
									className={
										Commons.IsMobile()
											? "datepicker-custom-input2"
											: "datepicker-custom-input"
									}
									style={{
										marginRight: "5px",
										marginLeft: "5px",
									}}
									selected={props.toDate}
									minDate={props.fromDate}
									maxDate={new Date()}
									onChange={(e) => {
										props.setToDate(e);
									}}
									customInput={<CustomInput />}
								/>
							</div>
							<AuthGroupButton
								pageName="LM10060"
								target="G02"
								selectFunc={() => {
									if (props.fromDate > props.toDate) {
										props.setToDate(props.fromDate);
										props.setSnacks({
											open: true,
											type: "error",
											message:
												props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"snack_check_period"
													)[0].lanName
													: "", // 조회 기간을 다시 확인하세요.
										});
									} else {
										props.BetweenDate();
									}
								}}
							/>
						</Card>
					</Grid>
					<Grid xs={12} md={12}>
						<Card>
							<div
								className="ag-theme-alpine-dark"
								style={{
									width: "100%",
									height:
										Commons.Dimentions().height < 800
											? Commons.Dimentions().height * 0.58
											: Commons.Dimentions().height *
											0.74,
								}}
							>
								<AgGridReact
									ref={gridRef}
									animateRows={true}
									columnDefs={columnDefs}
									defaultColDef={{
										editable: false,
										resizable: true,
										sortable: true,
										filter: true,
										floatingFilter: true,
									}}
									rowData={props.rowData1}
									rowSelection="single"
									singleClickEdit={
										Commons.IsMobile() ? true : false
									}
									getRowStyle={(e) =>
										GridFunctions.getRowStyle(e)
									}
								/>
							</div>
						</Card>
					</Grid>
				</Grid>
			)}
			{tabToggle === 2 && (
				<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={12} md={12}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginTop: "10px",
								}}
							>
								<DatePicker
									className={
										Commons.IsMobile()
											? "datepicker-custom-input2"
											: "datepicker-custom-input"
									}
									style={{
										marginRight: "5px",
										marginLeft: "5px",
									}}
									selected={props.fromDate}
									maxDate={new Date()}
									onChange={(e) => {
										props.setFromDate(e);
									}}
									customInput={<CustomInput />}
								/>
								~
								<DatePicker
									className={
										Commons.IsMobile()
											? "datepicker-custom-input2"
											: "datepicker-custom-input"
									}
									style={{
										marginRight: "5px",
										marginLeft: "5px",
									}}
									selected={props.toDate}
									minDate={props.fromDate}
									maxDate={new Date()}
									onChange={(e) => {
										props.setToDate(e);
									}}
									customInput={<CustomInput />}
								/>
							</div>
							<AuthGroupButton
								pageName="LM10060"
								target="G02"
								selectFunc={() => {
									if (props.fromDate > props.toDate) {
										props.setToDate(props.fromDate);
										props.setSnacks({
											open: true,
											type: "error",
											message:
												props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"snack_check_period"
													)[0].lanName
													: "", // 조회 기간을 다시 확인하세요.
										});
									} else {
										props.BetweenDate();
									}
								}}
							/>
						</Card>
					</Grid>
					<Grid xs={12} md={12}>
						<Card>
							<div
								className="ag-theme-alpine-dark"
								style={{
									width: "100%",
									height:
										Commons.Dimentions().height < 800
											? Commons.Dimentions().height * 0.58
											: Commons.Dimentions().height *
											0.74,
								}}
							>
								<AgGridReact
									ref={gridRef}
									animateRows={true}
									columnDefs={columnDefs2}
									defaultColDef={{
										editable: false,
										resizable: true,
										sortable: true,
										filter: true,
										floatingFilter: true,
									}}
									rowData={props.rowData2}
									rowSelection="single"
									singleClickEdit={
										Commons.IsMobile() ? true : false
									}
									getRowStyle={(e) =>
										GridFunctions.getRowStyle(e)
									}
								/>
							</div>
						</Card>
					</Grid>
				</Grid>
			)}
		</>
	);
}

export default LM10060;
