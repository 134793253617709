import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Styles Import */
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components Import */
import CM10000View from "../../Views/ControllerManagements/CM10000";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
/** Images Import */
/** Icons Import */

/** 컨트롤러 관리 */

function CM10000(props) {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [codeList, setCodeList] = React.useState([]);
	// 사용자 리스트
	const [rowData, setRowData] = React.useState([]); // 좌측 그리드에 보여주는 데이터
	const [selectedRowData, setSelectedRowData] = React.useState([]); // 선택된 행을 저장함
	const [selectedItemNo, setSelectedItemNo] = React.useState("");
	// 등록된 컨트롤러 리스트
	const [counting, setCounting] = React.useState(-1); // 화면에서 사용할 임시 제품관리번호
	const [rowData1, setRowData1] = React.useState([]); // 우측 그리드에 보여주는 데이터
	const [selectedRowData1, setSelectedRowData1] = React.useState([]); // 선택된 행을 저장함
	const [changedRowData1, setChangedRowData1] = React.useState([]); // 수정된 행을 저장함
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [numberInUse, setNumberInUse] = React.useState(0); // 현재 사용 중인 장비의 개수
	// 컨트롤러 리스트
	const [rowData2, setRowData2] = React.useState([]); // 컨트롤러 데이터
	const [selectedRowData2, setSelectedRowData2] = React.useState([]); // 선택된 행을 저장함
	const [controllerToggle, setControllerToggle] = React.useState(false);
	const [rtList, setRtList] = React.useState([]); // 연결 중계기관리 번호 리스트
	// 맥 주소 리스트
	const [rowData3, setRowData3] = React.useState([]); // 맥 주소 원본 데이터
	const [showRowData3, setShowRowData3] = React.useState([]); // 화면에 보여줄 맥 주소 데이터
	const [selectedRowData3, setSelectedRowData3] = React.useState([]); // 선택된 행을 저장함
	const [macToggle, setMacToggle] = React.useState(false);
	// 주소 설정
	const [addressInput, setAddressInput] = React.useState({
		userPost: "",
		userAddress1: "",
	});
	const [postToggle, setPostToggle] = React.useState(false);
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
		GetMacAdd();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
	}, []);

	// 사용자 리스트
	const Init = async () => {
		setProgress(true);
		let tempInit = [];
		let lans = [];
		let search = true;

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "CM10000").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.ControllerManagement.CM10000.GetCode
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (data.length > 0) {
					data.forEach((item, index) => {
						tempInit.push({
							...item,
							useYn: item.useYn === "Y" ? "사용 중" : "미사용",
							paidYn:
								item.useEdate >=
									Commons.DateFormating(new Date(), 6)
									? "유료 회원"
									: "무료 회원",
							useSdate: Commons.DateFormating(
								Commons.StringtoDate(item.useSdate),
								2
							),
							useEdate: Commons.DateFormating(
								Commons.StringtoDate(item.useEdate),
								2
							),
						});
					});
					setRowData(tempInit);
				} else {
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "" // 조회 실패!
					);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""}
								{/* 로그인 정보가 만료되었습니다. */}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_login_again"
									)[0].lanName
									: ""}
								{/* 다시 로그인 해 주세요. */}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 맥 주소 원본 리스트
	const GetMacAdd = async () => {
		setProgress(true);
		let tempArr = [];
		let lans = [];

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.ControllerManagement.CM10000.GetMacAdd
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					setRowData3([]);
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData3([]);
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (data) {
					data.forEach((item) => {
						tempArr.push({
							...item,
							selected: false,
						});
					});
					setRowData3(tempArr);
				} else {
					setRowData3([]);
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "" // 조회 실패!
					);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""}
								{/* 로그인 정보가 만료되었습니다. */}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_login_again"
									)[0].lanName
									: ""}
								{/* 다시 로그인 해 주세요. */}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 우측 그리드 (사용자별 컨트롤러 리스트) 조회
	const GetDtlList = async (_selectedData) => {
		setProgress(true);
		let tempArr = [];
		let tempRtArr = [];

		try {
			GetMacAdd(); // 새로운 사용자를 선택할 때 마다, 사용할 수 있는 맥 주소 리스트 재조회

			if (_selectedData.length === 0) {
				setRowData1([]);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.ControllerManagement.CM10000.GetItem,
					{
						userId: _selectedData[0].userId,
					}
				).then((result) => {
					let data = result.data;
					// console.log("data : ", data);

					if (data.type === "Error") {
						setRowData1([]);
						setNumberInUse(0);
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						setRowData1([]);
						setNumberInUse(0);
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId === "snack_unavailable_data"
								)[0].lanName
								: ""
						);
					} else if (data) {
						data.forEach((_item, index) => {
							tempArr.push({
								..._item,
								no: index + 1,
								tmpSaveTime: _item.tmpSaveTime
									? codeList.filter(
										(f) =>
											f.groupCd ===
											"MACH_TMP_REV_TIME" &&
											f.code + "" ===
											_item.tmpSaveTime + ""
									)[0].name
									: "10분",
								useYn: _item.useYn === "Y" ? "사용" : "미사용",
								screenYn:
									_item.screenYn === "Y" ? "사용" : "미사용",
								tmpArYn:
									_item.tmpArYn === "Y" ? "사용" : "미사용",
								illArYn:
									_item.illArYn === "Y" ? "사용" : "미사용",
								co2ArYn:
									_item.co2ArYn === "Y" ? "사용" : "미사용",
								defHgArYn:
									_item.defHgArYn === "Y" ? "사용" : "미사용",
								hmdArYn:
									_item.hmdArYn === "Y" ? "사용" : "미사용",
								itemOrder: _item.itemOrder
									? _item.itemOrder
									: index + 1,
								itemGrp: _item.itemGrp ? _item.itemGrp : "기본",
								updateDate: Commons.DateFormating(
									Commons.StringtoDate(_item.updateDate, 3)
								),
								joinRtNo:
									_item.joinRtNo !== null
										? data.filter(
											(item) =>
												item.itemNo ===
												_item.joinRtNo
										)[0].machNm
										: null,
								conForm:
									_item.conForm ?
										codeList.filter((f) =>
											f.groupCd === "CON_FORM" &&
											f.code + "" === _item.conForm
										)[0].name
										: "",
							});
							if (_item.itemType === "7" && _item.useYn === "Y") {
								tempRtArr.push({
									name: _item.machNm,
									value: _item.itemNo,
								});
							}
						});

						setNumberInUse(
							data.filter((item) => item.useYn === "Y") ===
								undefined
								? 0
								: data.filter((item) => item.useYn === "Y")
									.length
						);
						setRowData1(tempArr);
						setRtList(tempRtArr);
					} else {
						setRowData1([]);
						setNumberInUse(0);
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_view_failed"
								)[0].lanName
								: "" // 조회 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			setRowData1([]);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setChangedRowData1([]);
			setSelectedRowData1([]);
			setProgress(false);
		}
	};

	// 우측 그리드 row 생성
	const CheckingLogic1 = async (_gridref, _userId) => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			setSelectedItemNo(counting);
			setCounting(counting - 1);
			let currentData = [
				{
					userId: _userId,
					itemNo: counting,
					itemCd: null,
					macAdd: null,
					machId: "1",
					itemOrder: rowData1.length + 1,
					itemGrp: "기본",
					useYn: "사용",
					tmpArYn: "미사용",
					illArYn: "미사용",
					co2ArYn: "미사용",
					defHgArYn: "미사용",
					hmdArYn: "미사용",
					updateDate: Commons.DateFormating(new Date(), 5),
					updateId: userInfo.userId,
					machNm: "품목명",
					fwVer: "",
					tmpSpec: 0,
					joinRtNo: null, // 연결 중계기 관리번호
					// 장치 주소
					itemLan: 0,
					itemLong: 0,
					postNo: "",
					address1: "",
					address2: "",
					newYn: "Y",
				},
				...rowData1,
			];
			_gridref.current.api.setRowData(currentData);
			setRowData1(currentData);
			setChangedRowData1([
				...changedRowData1,
				{
					userId: _userId,
					itemNo: counting,
					itemCd: null,
					macAdd: null,
					machId: "1",
					itemOrder: rowData1.length + 1,
					itemGrp: "기본",
					useYn: "사용",
					tmpArYn: "미사용",
					illArYn: "미사용",
					co2ArYn: "미사용",
					defHgArYn: "미사용",
					hmdArYn: "미사용",
					updateDate: Commons.DateFormating(new Date(), 5),
					updateId: userInfo.userId,
					machNm: "품목명",
					fwVer: "",
					tmpSpec: 0,
					joinRtNo: null, // 연결 중계기 관리번호
					// 장치 주소
					itemLan: 0,
					itemLong: 0,
					postNo: "",
					address1: "",
					address2: "",
					newYn: "Y",
				},
			]);
			GetDtlList2();
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			dispatch(DrawerActions.setNotOpen()); // Drawer 열림 방지
			setProgress(false);
		}
	};

	// 장비설정용 컨트롤러 리스트 조회
	const GetDtlList2 = async () => {
		setProgress(true);
		let temp = [];

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.ControllerManagement.CM10000.GetItemList
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					setRowData2([]);
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData2([]);
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (data) {
					// setRowData2(data);
					data.forEach((item) => {
						temp.push({
							...item,
							conForm:
								item.conForm === "" || item.conForm === null
									? ""
									: codeList.filter(
										(_item) =>
											_item.groupCd === "CON_FORM" &&
											_item.code === item.conForm
									)[0].name,
							conType:
								item.conType === "" || item.conType === null
									? ""
									: codeList.filter(
										(_item) =>
											_item.groupCd === "CON_TYPE" &&
											_item.code === item.conType
									)[0].name,
						});
					});

					setRowData2(temp);
					return setControllerToggle(true);
				} else {
					setRowData2([]);
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "" // 조회 실패!
					);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 선택한 컨트롤러를 row에 삽입
	const ControllerInsert = async () => {
		setProgress(true);
		let temp = [];

		try {
			if (selectedRowData2.length === 0) {
				setControllerToggle(false);
			} else {
				rowData1.map((item) => {
					if (item.itemNo === selectedItemNo) {
						temp.push({
							...item,
							itemIdx: selectedRowData2[0].itemIdx,
							itemCd: selectedRowData2[0].itemCd,
							itemNm: selectedRowData2[0].itemNm,
							itemType: selectedRowData2[0].itemType,
						});
						changedRowData1.push({
							...item,
							itemIdx: selectedRowData2[0].itemIdx,
							itemCd: selectedRowData2[0].itemCd,
							itemNm: selectedRowData2[0].itemNm,
							itemType: selectedRowData2[0].itemType,
						});
					} else {
						temp.push(item);
					}
				});
				setRowData1(temp);

				GetDtlList3();
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setSelectedRowData2([]);
			setProgress(false);
		}
	};

	// 보여줄 맥 주소 리스트 선별
	const GetDtlList3 = async () => {
		setProgress(true);
		let tempArr = [];

		try {
			rowData3.forEach((item) => {
				if (!item.selected) {
					tempArr.push(item);
				}
			});

			setShowRowData3(tempArr);
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setMacToggle(true);
			setProgress(false);
		}
	};

	// 선택한 맥 주소를 row에 삽입
	const MacAddInsert = async () => {
		setProgress(true);
		let tempRowData1 = [];
		let tempMacAdd = null;
		let tempRowData3 = [];

		try {
			if (selectedRowData3.length === 0) {
				setMacToggle(false);
			} else {
				rowData1.map((item, index) => {
					// 선택된 row 확인
					if (item.itemNo === selectedItemNo) {
						// 기존에 선택된 맥 주소가 있는지 확인
						if (item.macAdd !== null) {
							tempMacAdd = item.macAdd;

							tempRowData1.push({
								...item,
								macAdd: selectedRowData3[0].macAdd,
							});
							changedRowData1.push({
								...item,
								macAdd: selectedRowData3[0].macAdd,
							});
						} else {
							tempRowData1.push({
								...item,
								macAdd: selectedRowData3[0].macAdd,
							});
							changedRowData1.push({
								...item,
								macAdd: selectedRowData3[0].macAdd,
							});
						}
					} else {
						tempRowData1.push(item);
					}
				});

				// 선택된 맥 주소를 true로 변경	=> 기존에 선택되었던 맥 주소는 false로 변경
				rowData3.forEach((item, index) => {
					if (item.macAdd === tempMacAdd) {
						tempRowData3.push({
							...item,
							selected: false,
						});
					} else if (item.macAdd === selectedRowData3[0].macAdd) {
						tempRowData3.push({
							...item,
							selected: true,
						});
					} else {
						tempRowData3.push(item);
					}
				});

				setRowData1(tempRowData1);
				setRowData3(tempRowData3);
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setSelectedRowData3([]);
			setProgress(false);
		}
	};

	// 선택한 장치 주소 삽입
	const AddressInsert = async (_data) => {
		setProgress(true);
		let temp = [];

		try {
			rowData1.map((item, index) => {
				if (item.itemNo === selectedItemNo) {
					temp.push({
						...item,
						itemLan: _data.x,
						itemLong: _data.y,
						postNo: _data.road_address.zone_no,
						address1: _data.address_name,
					});
					changedRowData1.push({
						...item,
						itemLan: _data.x,
						itemLong: _data.y,
						postNo: _data.road_address.zone_no,
						address1: _data.address_name,
					});
				} else {
					temp.push(item);
				}
			});

			setRowData1(temp);
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 그리드 변경사항 저장
	const CreateLogic1 = async () => {
		setProgress(true);
		let tempControllerCheck = [];
		let tempMacCheck = [];
		let tempNameCheck = [];
		let tempChangedArr = [];
		let tempChangedArr1 = [];
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			tempControllerCheck = rowData1.filter(
				(item) => item.itemCd !== null
			); // 장비 있는지 확인
			tempMacCheck = rowData1.filter((item) => item.macAdd !== null); // 맥 주소 있는지 확인

			if (tempControllerCheck.length !== rowData1.length) {
				// 장비 없으면 에러
				// setSnacks({
				// 	open: true,
				// 	type: "error",
				// 	message:
				// 		lanList.length > 0
				// 			? lanList.filter(
				// 				(f) => f.lanId === "snack_select_controller"
				// 			)[0].lanName
				// 			: "", // 장비를 설정해주세요!
				// });
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_select_controller"
						)[0].lanName
						: "", // 장비를 설정해주세요!
				);
			}
			// else if (tempMacCheck.length !== rowData1.length) {
			// 	// 맥 주소 없으면 에러
			// 	setSnacks({
			// 		open: true,
			// 		type: "error",
			// 		message: "Mac 주소를 설정해주세요!",
			// 	});
			// }
			else if (changedRowData1.length === 0) {
				// 수정된 행이 없으면 에러
				// setSnacks({
				// 	open: true,
				// 	type: "error",
				// 	message: "수정된 행이 없습니다.",
				// });
			} else if (
				tempControllerCheck.length == rowData1.length
				// && tempMacCheck.length === rowData1.length
			) {
				// 정상 케이스
				// 선택된 행의 중복 제거
				tempChangedArr = changedRowData1
					.reverse()
					.reduce((prev, now) => {
						if (
							!prev.some(
								(obj) => obj.itemNo === now.itemNo
								// && obj.macAdd === now.macAdd
							)
						) {
							prev.push(now);
						}
						return prev;
					}, []);

				tempChangedArr.forEach((item) => {
					delete item.newYn;
				});
				// console.log("tempChangedArr : ", tempChangedArr);
				// 변경된 행의 데이터를 저장 형식에 맞게 수정
				tempChangedArr.forEach((_item) => {
					if (
						_item.itemCd !== null
						// && _item.macAdd !== null
					) {
						if (_item.itemNo < 0) {
							// 신규 생성
							tempChangedArr1.push({
								..._item,
								useYn: _item.useYn === "사용" ? "Y" : "N",
								screenYn: _item.screenYn === "사용" ? "Y" : "N",
								tmpArYn: _item.tmpArYn === "사용" ? "Y" : "N",
								illArYn: _item.illArYn === "사용" ? "Y" : "N",
								co2ArYn: _item.co2ArYn === "사용" ? "Y" : "N",
								defHgArYn:
									_item.defHgArYn === "사용" ? "Y" : "N",
								hmdArYn: _item.hmdArYn === "사용" ? "Y" : "N",
								machNm:
									_item.machNm === "" || _item.machNm === null
										? "품목명"
										: _item.machNm,
								macAdd:
									_item.macAdd === "" || _item.macAdd === null
										? ""
										: _item.macAdd,
								tmpSaveTime:
									_item.tmpSaveTime === undefined ||
										_item.tmpSaveTime === ""
										? 10
										: codeList.filter(
											(item) =>
												item.groupCd ===
												"MACH_TMP_REV_TIME" &&
												item.name ===
												_item.tmpSaveTime
										)[0].code * 1,
								itemNo: 0,
								tmpMinVal: _item.tmpMinVal === null || _item.tmpMinVal === undefined ? 0 : _item.tmpMinVal,
								tmpMaxVal: _item.tmpMaxVal === null || _item.tmpMaxVal === undefined ? 0 : _item.tmpMaxVal,
								hmdMinVal: _item.hmdMinVal === null || _item.hmdMinVal === undefined ? 0 : _item.hmdMinVal,
								hmdMaxVal: _item.hmdMaxVal === null || _item.hmdMaxVal === undefined ? 0 : _item.hmdMaxVal,
								illMinVal: _item.illMinVal === null || _item.illMinVal === undefined ? 0 : _item.illMinVal,
								illMaxVal: _item.illMaxVal === null || _item.illMaxVal === undefined ? 0 : _item.illMaxVal,
								co2MinVal: _item.co2MinVal === null || _item.co2MinVal === undefined ? 0 : _item.co2MinVal,
								co2MaxVal: _item.co2MaxVal === null || _item.co2MaxVal === undefined ? 0 : _item.co2MaxVal,
								tmpCheckTime: _item.tmpCheckTime === null || _item.tmpCheckTime === undefined ? 0 : _item.tmpCheckTime,
								checkTotalTime: _item.checkTotalTime === null || _item.checkTotalTime === undefined ? 0 : _item.checkTotalTime,
								retryPushAlarmYn: _item.retryPushAlarmYn === null || _item.retryPushAlarmYn === undefined ?
									"N" :
									_item.retryPushAlarmYn === "Y" ? "Y" : "N",
								joinRtNo:
									_item.joinRtNo !== null &&
										_item.joinRtNo !== undefined
										? rtList.filter(
											(item) =>
												item.name === _item.joinRtNo
										)[0].value
										: null,
								updateId: userInfo.userId
							});
						} else {
							// 기존 항목 변경
							tempChangedArr1.push({
								..._item,
								useYn: _item.useYn === "사용" ? "Y" : "N",
								screenYn: _item.screenYn === "사용" ? "Y" : "N",
								tmpArYn: _item.tmpArYn === "사용" ? "Y" : "N",
								illArYn: _item.illArYn === "사용" ? "Y" : "N",
								co2ArYn: _item.co2ArYn === "사용" ? "Y" : "N",
								defHgArYn:
									_item.defHgArYn === "사용" ? "Y" : "N",
								hmdArYn: _item.hmdArYn === "사용" ? "Y" : "N",
								machNm:
									_item.machNm === "" || _item.machNm === null
										? "품목명"
										: _item.machNm,
								macAdd:
									_item.macAdd === "" || _item.macAdd === null
										? ""
										: _item.macAdd,
								tmpSaveTime:
									_item.tmpSaveTime === undefined ||
										_item.tmpSaveTime === ""
										? 0
										: codeList.filter(
											(item) =>
												item.groupCd ===
												"MACH_TMP_REV_TIME" &&
												item.name ===
												_item.tmpSaveTime
										)[0].code * 1,
								tmpMinVal: _item.tmpMinVal === null || _item.tmpMinVal === undefined ? 0 : _item.tmpMinVal,
								tmpMaxVal: _item.tmpMaxVal === null || _item.tmpMaxVal === undefined ? 0 : _item.tmpMaxVal,
								hmdMinVal: _item.hmdMinVal === null || _item.hmdMinVal === undefined ? 0 : _item.hmdMinVal,
								hmdMaxVal: _item.hmdMaxVal === null || _item.hmdMaxVal === undefined ? 0 : _item.hmdMaxVal,
								illMinVal: _item.illMinVal === null || _item.illMinVal === undefined ? 0 : _item.illMinVal,
								illMaxVal: _item.illMaxVal === null || _item.illMaxVal === undefined ? 0 : _item.illMaxVal,
								co2MinVal: _item.co2MinVal === null || _item.co2MinVal === undefined ? 0 : _item.co2MinVal,
								co2MaxVal: _item.co2MaxVal === null || _item.co2MaxVal === undefined ? 0 : _item.co2MaxVal,
								tmpCheckTime: _item.tmpCheckTime === null || _item.tmpCheckTime === undefined ? 0 : _item.tmpCheckTime,
								checkTotalTime: _item.checkTotalTime === null || _item.checkTotalTime === undefined ? 0 : _item.checkTotalTime,
								retryPushAlarmYn: _item.retryPushAlarmYn === null || _item.retryPushAlarmYn === undefined ?
									"N" :
									_item.retryPushAlarmYn === "Y" ? "Y" : "N",
								joinRtNo:
									_item.joinRtNo !== null &&
										_item.joinRtNo !== undefined
										? rtList.filter(
											(item) =>
												item.name === _item.joinRtNo
										)[0].value
										: null,
								updateId: userInfo.userId
							});
						}
					}
				});
				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.ControllerManagement.CM10000.Create,
					tempChangedArr1
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_saved"
						// 			)[0].lanName
						// 			: "", // 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_saved"
									)[0].lanName
									: "", // 저장 되었습니다!
						});
						setSelectedRowData1([]);
						setChangedRowData1([]);
						setShowRowData3([]);
						dispatch(DrawerActions.setUseOpen()); // Drawer 열림 허용
						GetDtlList(selectedRowData);
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_save_failed"
								)[0].lanName
								: "" // 저장 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 그리드 데이터 삭제
	const DeleteLogin1 = async () => {
		setProgress(true);
		let tempArr = [];
		let tempNewYn = selectedRowData1.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인
		let itemType7 = selectedRowData1.filter(
			(f) => (f.useYn === "Y" || f.useYn === "사용")
				&& f.itemType === "7"
		); // 사용 중인 중계기를 삭제하려 하는 경우
		let checkUsing7 = false;	// 삭제하려는 중계기를 사용하고 있는 rowData 조회. 사용하고 있는 컨트롤러가 있으면 true, 없으면 false.
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			if (selectedRowData1.length === 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_cannot_unstored"
						)[0].lanName
						: "" // 저장되지 않은 데이터는 미사용 처리를 할 수 없습니다.
				);
			} else {
				if (itemType7.length > 0) {
					// 삭제할 row 중에 사용 중인 중계기가 있음
					itemType7.forEach((item) => {
						rowData1.filter(
							(f) => (f.useYn === "Y" || f.useYn === "사용") && f.joinRtNo === item.machNm
						).length > 0 ?
							checkUsing7 = true :
							checkUsing7 = false;
					});

					if (checkUsing7) {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_unused_failed"
								)[0].lanName
								: "" // 미사용 처리 실패!
						);
					} else {
						selectedRowData1.forEach((_item) => {
							tempArr.push({
								..._item,
								useYn: _item.useYn === "사용" ? "Y" : "N",
								screenYn:
									_item.screenYn === "사용" ? "Y" : "N",
								tmpArYn:
									_item.tmpArYn === "사용" ? "Y" : "N",
								illArYn:
									_item.illArYn === "사용" ? "Y" : "N",
								co2ArYn:
									_item.co2ArYn === "사용" ? "Y" : "N",
								defHgArYn:
									_item.defHgArYn === "사용" ? "Y" : "N",
								hmdArYn: _item.hmdArYn === "사용" ? "Y" : "N",
								tmpSaveTime:
									_item.tmpSaveTime === undefined ||
										_item.tmpSaveTime === ""
										? 0
										: codeList.filter(
											(item) =>
												item.groupCd ===
												"MACH_TMP_REV_TIME" &&
												item.name === _item.tmpSaveTime
										)[0].code * 1,
								joinRtNo: _item.joinRtNo !== null ?
									rowData1.filter((f) =>
										f.machNm === _item.joinRtNo
										&& f.itemType === "7"
									)[0].itemNo
									: null,
								updateId: userInfo.userId,
							});
						});
					}
				} else {
					// 삭제할 row 중에 사용 중인 중계기가 없음

					// 정상 데이터 삭제
					selectedRowData1.forEach((_item) => {
						tempArr.push({
							..._item,
							useYn: _item.useYn === "사용" ? "Y" : "N",
							screenYn:
								_item.screenYn === "사용" ? "Y" : "N",
							tmpArYn:
								_item.tmpArYn === "사용" ? "Y" : "N",
							illArYn:
								_item.illArYn === "사용" ? "Y" : "N",
							co2ArYn:
								_item.co2ArYn === "사용" ? "Y" : "N",
							defHgArYn:
								_item.defHgArYn === "사용" ? "Y" : "N",
							hmdArYn: _item.hmdArYn === "사용" ? "Y" : "N",
							tmpSaveTime:
								_item.tmpSaveTime === undefined ||
									_item.tmpSaveTime === ""
									? 0
									: codeList.filter(
										(item) =>
											item.groupCd ===
											"MACH_TMP_REV_TIME" &&
											item.name === _item.tmpSaveTime
									)[0].code * 1,
							joinRtNo: _item.joinRtNo !== null ?
								rowData1.filter((f) =>
									f.machNm === _item.joinRtNo
									&& f.itemType === "7"
								)[0].itemNo
								: null,
							updateId: userInfo.userId,
						});
					});
				}

				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.ControllerManagement.CM10000.Delete,
					tempArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_saved"
						// 			)[0].lanName
						// 			: "", // 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_saved"
									)[0].lanName
									: "", // 저장 되었습니다!
						});
						setSelectedRowData1([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						GetDtlList(selectedRowData);
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_unused_failed"
								)[0].lanName
								: "" // 미사용 처리 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<>
			<CM10000View
				codeList={codeList}
				lanList={lanList}
				// 사용자 리스트
				rowData={rowData}
				setRowData={setRowData}
				selectedRowData={selectedRowData}
				setSelectedRowData={setSelectedRowData}
				selectedItemNo={selectedItemNo}
				setSelectedItemNo={setSelectedItemNo}
				// 사용자 컨트롤러 리스트
				rowData1={rowData1}
				setRowData1={setRowData1}
				selectedRowData1={selectedRowData1}
				setSelectedRowData1={setSelectedRowData1}
				changedRowData1={changedRowData1}
				setChangedRowData1={setChangedRowData1}
				numberInUse={numberInUse}
				// 컨트롤러 리스트
				rowData2={rowData2}
				setRowData2={setRowData2}
				selectedRowData2={selectedRowData2}
				setSelectedRowData2={setSelectedRowData2}
				rtList={rtList}
				// 맥 주소 리스트
				rowData3={rowData3}
				setRowData3={setRowData3}
				selectedRowData3={selectedRowData3}
				setSelectedRowData3={setSelectedRowData3}
				showRowData3={showRowData3}
				setShowRowData3={setShowRowData3}
				// 장치 주소
				addressInput={addressInput}
				setAddressInput={setAddressInput}
				// 컨트롤러 / 맥주소 / 주소 토글
				controllerToggle={controllerToggle}
				setControllerToggle={setControllerToggle}
				macToggle={macToggle}
				setMacToggle={setMacToggle}
				postToggle={postToggle}
				setPostToggle={setPostToggle}
				// 함수
				GetDtlList={GetDtlList}
				GetDtlList2={GetDtlList2}
				ControllerInsert={ControllerInsert}
				GetDtlList3={GetDtlList3}
				MacAddInsert={MacAddInsert}
				AddressInsert={AddressInsert}
				CheckingLogic1={CheckingLogic1}
				CreateLogic1={CreateLogic1}
				DeleteLogin1={DeleteLogin1}
				// Dialog
				snackDialog={snackDialog}
				setSnackDialog={setSnackDialog}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</>
	);
}

export default CM10000;
