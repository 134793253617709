import React from "react";
import { useNavigate } from "react-router-dom";
/** Service Import */
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardHeader,
	CardContent as MuiCardContent,
	IconButton,
	Divider,
	Typography,
} from "@mui/material";
/** Icons Import */
import { EditCalendar as WriteIcon } from "@mui/icons-material";
/** Images Import */
import SettingIcon from "../../Assets/Images/Design/drawable-hdpi/main_setting_btn.png";
import CircleBack from "../../Assets/Images/Design/drawable-hdpi/circle_back.png";

const CardContents = styled((props) => (
	<MuiCardContent style={{ paddingBottom: "8px" }} {...props} />
))(({ theme }) => ({
	padding: "0px",
	margin: "0px",
	marginTop: "5px",
}));
const CardContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-around",
	alignItems: "center",
}));
const CardContentItem = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
}));
const CardContentItemHeadTitle = styled((props) => <div {...props} />)(
	({ theme }) => ({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "152px",
		height: "152px",
		color: "#dff4eb",
		backgroundImage: `url(${CircleBack})`,
		backgroundSize: "cover",
	})
);

function DashboardHeader({
	items = [], // Controller wide list's item list
	controllerDetail = [],
	setDialog,
}) {
	const navigate = useNavigate();

	const ReturnData = items.map((item, index) => {
		const ReturnItem = () => {
			let storeTemp = [];
			let dividerTemp = [];

			controllerDetail.forEach((tempItem, tempIndex) => {
				if (controllerDetail.length % 2 === 0) {
					if (
						(tempIndex + 1) % 2 === 0 ||
						tempIndex + 1 === controllerDetail.length
					) {
						storeTemp.push(tempItem);
						dividerTemp.push(storeTemp);
						storeTemp = [];
					} else {
						storeTemp.push(tempItem);
					}
				} else {
					if (tempIndex === 0) {
						storeTemp.push(tempItem);
						storeTemp.push(tempItem);
						dividerTemp.push(storeTemp);
						storeTemp = [];
					} else if ((tempIndex + 1) % 2 === 0) {
						storeTemp.push(tempItem);
					} else {
						storeTemp.push(tempItem);
						dividerTemp.push(storeTemp);
						storeTemp = [];
					}
				}
			});
			// console.log("dividerTemp : ", dividerTemp);
			return dividerTemp.map((divItem, divIndex) => {
				return (
					<CardContents key={divIndex}>
						<CardContentItems>
							{divItem.map((lastItem, lastIndex) => {
								return (
									<CardContentItem key={lastIndex}>
										<CardContentItemHeadTitle>
											<Typography variant="subtitle1">
												{controllerDetail % 2 !== 0 &&
													divIndex === 0 &&
													lastIndex === 1
													? "sv"
													: "pv"}
											</Typography>
											<Typography
												sx={{
													color:
														lastItem.errorYn === "Y"
															? "red"
															: "#388DFC",
												}}
												variant="h4"
												fontWeight="bold"
												fontFamily="digital-num"
											>
												{lastItem.errorYn === "Y"
													? (lastItem.alarmMsg === "미접속" ? "N-S" : lastItem.alarmMsg)
													: (controllerDetail % 2 !==
														0 &&
														divIndex === 0 &&
														lastIndex === 1
														? lastItem.setting
														: lastItem.current)}
												{/* {controllerDetail % 2 !== 0 &&
												divIndex === 0 &&
												lastIndex === 1
													? lastItem.setting
													: lastItem.current} */}
											</Typography>
											<Typography variant="subtitle1">
												{lastItem.unit}
											</Typography>
										</CardContentItemHeadTitle>
									</CardContentItem>
								);
							})}
						</CardContentItems>
					</CardContents>
				);
			});
		};

		return (
			<Grid key={index} xs={4} sm={8} md={3}>
				<Card sx={{ height: "100%" }}>
					<CardHeader
						sx={{ py: 1 }}
						title={
							<div
								style={{
									display: "flex",
									alignItems: "center",
									color: "#388DFC",
								}}
							>
								<Typography variant="h6" fontWeight="bold">
									{item.machNm}
								</Typography>
								{/* <IconButton onClick={() => setDialog(true)}>
									<WriteIcon
										sx={{ color: "#388DFC" }}
										fontSize="small"
									/>
								</IconButton> */}
							</div>
						}
						action={
							item.amountYn === "Y" && (
								<IconButton
									onClick={
										() =>
											navigate(
												"/SettingManagement/SM10000",
												{
													state: { ...item },
												}
											)
										// item.ButtonClick(item)
									}
								>
									<img
										style={{
											width: "28px",
											height: "28px",
										}}
										src={SettingIcon}
									/>
								</IconButton>
							)
						}
					/>
					<Divider variant="middle" />
					{ReturnItem()}
				</Card>
			</Grid>
		);
	});

	return ReturnData;
}

export default DashboardHeader;
