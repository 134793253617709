import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardContent as MuiCardContent,
	Divider,
	Typography,
} from "@mui/material";
/** Icons Import */
/** Images Import */
// import CompOffIcon from "../../Assets/Images/Design/drawable-hdpi/ic01_off.png";
// import CompOnIcon from "../../Assets/Images/Design/drawable-hdpi/ic01_on.png";
// import DefrostOffIcon from "../../Assets/Images/Design/drawable-hdpi/ic02_off.png";
// import DefrostOnIcon from "../../Assets/Images/Design/drawable-hdpi/ic02_on.png";
// import FanOffIcon from "../../Assets/Images/Design/drawable-hdpi/ic03_off.png";
// import FanOnIcon from "../../Assets/Images/Design/drawable-hdpi/ic03_on.png";
// import LowDefenseOffIcon from "../../Assets/Images/Design/drawable-hdpi/ic04_off.png";
// import LowDefenseOnIcon from "../../Assets/Images/Design/drawable-hdpi/ic04_on.png";

import CompOffIcon from "../../Assets/Images/Design/drawable-hdpi/CompOFF.png";
import CompOnIcon from "../../Assets/Images/Design/drawable-hdpi/CompON.png";
import DefrostOffIcon from "../../Assets/Images/Design/drawable-hdpi/DefrostOFF.png";
import DefrostOnIcon from "../../Assets/Images/Design/drawable-hdpi/DefrostON.png";
import FanOffIcon from "../../Assets/Images/Design/drawable-hdpi/FanOFF.png";
import FanOnIcon from "../../Assets/Images/Design/drawable-hdpi/FanON.png";
import LowTempOffIcon from "../../Assets/Images/Design/drawable-hdpi/LowTempOFF.png";
import LowTempOnIcon from "../../Assets/Images/Design/drawable-hdpi/LowTempON.png";
import SystemStatusOffIcon from "../../Assets/Images/Design/drawable-hdpi/SystemStatusOFF.png";
import SystemStatusOnIcon from "../../Assets/Images/Design/drawable-hdpi/SystemStatusON.png";

import CO2OutPutOnIcon from "../../Assets/Images/Design/drawable-hdpi/CO2OutPutON.png";
import CO2OutPutOffIcon from "../../Assets/Images/Design/drawable-hdpi/CO2OutPutOFF.png";
import HumidifyOnIcon from "../../Assets/Images/Design/drawable-hdpi/HumidifyON.png";
import HumidifyOFFIcon from "../../Assets/Images/Design/drawable-hdpi/HumidifyOFF.png";
import HeaterOnIcon from "../../Assets/Images/Design/drawable-hdpi/HeaterON.png";
import HeaterOFFIcon from "../../Assets/Images/Design/drawable-hdpi/HeaterOFF.png";
import OpenOnIcon from "../../Assets/Images/Design/drawable-hdpi/OpenON.png";
import OpenOFFIcon from "../../Assets/Images/Design/drawable-hdpi/OpenOFF.png";
import CloseOnIcon from "../../Assets/Images/Design/drawable-hdpi/CloseON.png";
import CloseOFFIcon from "../../Assets/Images/Design/drawable-hdpi/CloseOFF.png";
import AlartOnIcon from "../../Assets/Images/Design/drawable-hdpi/AlartON.png";
import AlartOFFIcon from "../../Assets/Images/Design/drawable-hdpi/AlartOFF.png";

const CardContents = styled((props) => <MuiCardContent {...props} />)(
	({ theme }) => ({
		padding: "0px",
		margin: "0px",
	})
);
const CardContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-around",
	alignItems: "center",
}));
const CardContentItems_wide = styled((props) => <div {...props} />)(
	({ theme }) => ({
		display: "flex",
		flexDirection: "column",
	})
);
const CardContentItem = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	// height: "90px",
	// height: "120px",
}));
const CardContentItem_wide = styled((props) => <div {...props} />)(
	({ theme }) => ({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		marginBottom: "16px",
	})
);
const CardContentItemHeadTitle = styled((props) => <Typography {...props} />)(
	({ theme }) => ({
		width: "100%",
		textAlign: "center",
		padding: "0px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	})
);

function DashboardList({
	items = [], // Controller wide list's item list
}) {
	let storeTemp = [];
	let dividerTemp = [];

	if (!Commons.IsMobile()) {
		items.forEach((item1, index1) => {
			if ((index1 + 1) % 2 === 0 || index1 + 1 === items.length) {
				storeTemp.push(item1);
				dividerTemp.push(storeTemp);
				storeTemp = [];
			} else {
				storeTemp.push(item1);
			}
		});
	}

	const ReturnIcons = (type, status) => {
		switch (type) {
			case "1":
				if (status === "ON" || status === "운전" || status === "Operation") {
					return (
						<div className="gradient-box in">
							<img
								style={{ width: "50px", height: "50px" }}
								src={CompOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<div>
							<img
								style={{ width: "50px", height: "50px" }}
								src={CompOffIcon}
							/>
						</div>
					);
				}
			case "2":
				if (status === "ON" || status === "운전" || status === "Operation") {
					return (
						<div className="gradient-box in">
							<img
								style={{ width: "50px", height: "50px" }}
								src={DefrostOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<div>
							<img
								style={{ width: "50px", height: "50px" }}
								src={DefrostOffIcon}
							/>
						</div>
					);
				}
			case "3":
				if (status === "ON" || status === "운전" || status === "Operation") {
					return (
						<div className="gradient-box in">
							<img
								style={{ width: "50px", height: "50px" }}
								src={FanOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<div>
							<img
								style={{ width: "50px", height: "50px" }}
								src={FanOffIcon}
							/>
						</div>
					);
				}
			case "4":
				if (status === "ON" || status === "운전" || status === "Operation") {
					return (
						<div className="gradient-box in">
							<img
								style={{ width: "50px", height: "50px" }}
								src={LowTempOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<div>
							<img
								style={{ width: "50px", height: "50px" }}
								src={LowTempOffIcon}
							/>
						</div>
					);
				}
			case "5":
				if (status === "ON" || status === "운전" || status === "Operation") {
					return (
						<div className="gradient-box in">
							<img
								style={{ width: "50px", height: "50px" }}
								src={SystemStatusOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<div>
							<img
								style={{ width: "50px", height: "50px" }}
								src={SystemStatusOffIcon}
							/>
						</div>
					);
				}
			default:
				if (status === "ON" || status === "운전" || status === "Operation") {
					return (
						<div className="gradient-box in">
							<img
								style={{ width: "50px", height: "50px" }}
								src={CompOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<div>
							<img
								style={{ width: "50px", height: "50px" }}
								src={CompOffIcon}
							/>
						</div>
					);
				}
		}
	};

	const ReturnColor = (status) => {
		switch (status) {
			case "ON":
			case "운전":
			case "Operation":
				return "#388DFC";
			default:
				return "#ABB2C4";
		}
	};

	const ReturnData = items.map((item, index) => {
		return (
			<CardContentItem key={index}>
				{ReturnIcons(item.imgCode, item.bitValue)}
				<CardContentItemHeadTitle
					sx={{ color: ReturnColor(item.bitValue) }}
				>
					{item.statusNm}
				</CardContentItemHeadTitle>
			</CardContentItem>
		);
	});

	const ReturnData_wide = dividerTemp.map((item, index) => {
		return (
			<CardContents key={index} style={{ padding: "12px 0px 4px 0px" }}>
				<CardContentItems_wide>
					{item.map((item1, index1) => {
						return (
							<CardContentItem_wide key={index1}>
								{ReturnIcons(item1.imgCode, item1.bitValue)}
								<CardContentItemHeadTitle
									sx={{
										color: ReturnColor(item1.bitValue),
									}}
								>
									{item1.statusNm}
								</CardContentItemHeadTitle>
							</CardContentItem_wide>
						);
					})}
				</CardContentItems_wide>
			</CardContents>
		);
	});

	return (
		<Grid xs={4} sm={8} md={Commons.IsMobile() ? 12 : 3}>
			{Commons.IsMobile() ? (
				<Card>
					<CardContents style={{ padding: "12px 0px 4px 0px" }}>
						<CardContentItems>{ReturnData}</CardContentItems>
					</CardContents>
				</Card>
			) : (
				<Card
					sx={{
						height: "100%",
						display: "flex",
						justifyContent: "space-around",
						alignItems: "flex-start",
						padding: "16px",
					}}
				>
					{ReturnData_wide}
				</Card>
			)}
		</Grid>
	);
}

export default DashboardList;
