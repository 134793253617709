import React from "react";
import { useDispatch } from "react-redux";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card, Breadcrumbs } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
import { Combo } from "../../Components/Combos";
/** Ag-grid Import */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";
/** Icons Import */
import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";

/** 사용자 등록 */

function BM10010(props) {
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const gridRef = React.useRef();
	const columnDefs = [
		{
			field: "userId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_user_id"
					)[0].lanName
					: "", // 사용자 아이디
			minWidth: 250,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "userNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_user_nm"
					)[0].lanName
					: "", // 사용자 이름
			minWidth: 170,
			editable: true,
		},
		{
			field: "authGroupCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_auth"
					)[0].lanName
					: "", // 계정 권한
			minWidth: 180,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				// values: props.authGroupData.map((item) => item.AuthGroupNm),
				values: props.authGroupData.map((item) =>
					props.userLanCode === "en"
						? item.AuthGroupNameEng
						: item.AuthGroupNm
				),
			},
			editable: true,
		},
		{
			field: "useYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_use_yn"
					)[0].lanName
					: "", // 사용 유무
			minWidth: 140,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
			sort: "desc",
		},
		// {
		// 	field: "kakaoYn",
		// 	headerName: (props.lanList.length > 0
		// 		? props.lanList.filter(
		// 			(f) =>
		// 				f.lanId ===
		// 				"bm10010_col_use_yn"
		// 		)[0].lanName
		// 		: ""),	// 사용 유무
		// 	minWidth: 140,
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: props.codeList
		// 			.filter((item) => item.groupCd === "USE_YN")
		// 			.map((item) => props.userLanCode === "en" ? item.ref3 : item.name),
		// 	},
		// 	editable: false,
		// },
		{
			field: "lanCode",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_lan_code"
					)[0].lanName
					: "", // 사용 언어
			minWidth: 140,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "LAN_CODE")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "useSdate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_use_s_date"
					)[0].lanName
					: "", // 시작 일자
			minWidth: 150,
			cellEditor: "agDateCellEditor",
			cellEditorParams: {
				min: "2000-01-01",
				max: "9999-12-31",
			},
			valueFormatter: (params) => {
				var param =
					params.value === null
						? new Date().getFullYear() +
						"-" +
						(new Date().getMonth() + 1) +
						"-" +
						new Date().getDate()
						: params.value.getFullYear() +
						"-" +
						(params.value.getMonth() + 1) +
						"-" +
						params.value.getDate();
				return param;
			},
			editable: true,
		},
		{
			field: "useEdate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_use_e_date"
					)[0].lanName
					: "", // 종료일자
			minWidth: 150,
			cellEditor: "agDateCellEditor",
			cellEditorParams: {
				min: "2000-01-01",
				max: "9999-12-31",
			},
			valueFormatter: (params) => {
				var param =
					params.value === null
						? new Date().getFullYear() +
						"-" +
						(new Date().getMonth() + 1) +
						"-" +
						new Date().getDate()
						: params.value.getFullYear() +
						"-" +
						(params.value.getMonth() + 1) +
						"-" +
						params.value.getDate();
				return param;
			},
			editable: true,
		},
		{
			field: "countryCode",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_country_code"
					)[0].lanName
					: "", // 국가 전화번호
			minWidth: 170,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "COUNTRY_CODE")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "tel",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_tel"
					)[0].lanName
					: "", // 전화번호
			minWidth: 170,
			cellDataType: "text",
			// valueFormatter: (params) => {
			// 	if (params.value !== null && !pattern.test(params.value)) {
			// 		var formatted = params.value
			// 			.toString()
			// 			.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
			// 		return formatted;
			// 	} else {
			// 		return "";
			// 	}
			// },
			editable: true,
		},
		{
			field: "useAmt",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_amt"
					)[0].lanName
					: "", // 금액
			minWidth: 150,
			cellDataType: "number",
			valueFormatter: (params) => {
				if (params.value !== null) {
					var formatted = params.value
						.toString()
						.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
					return formatted;
				} else {
					return 0;
				}
			},
			editable: true,
		},
		// {
		// 	field: "privacyYn",
		// 	headerName: (props.lanList.length > 0
		// 		? props.lanList.filter(
		// 			(f) =>
		// 				f.lanId ===
		// 				"bm10010_col_consent_yn"
		// 		)[0].lanName
		// 		: ""),	// 동의여부
		// 	minWidth: 150,
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: props.codeList
		// 			.filter((item) => item.groupCd === "CONSENT_YN")
		// 			.map((item) => props.userLanCode === "en" ? item.ref3 : item.name),
		// 	},
		// 	editable: true,
		// },
		{
			field: "createId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_create_id"
					)[0].lanName
					: "", // 계정 등록자
			minWidth: 200,
			editable: false,
		},
		{
			field: "createDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_create_date"
					)[0].lanName
					: "", // 계정 등록일
			minWidth: 180,
			editable: false,
		},
		{
			field: "updateId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_update_id"
					)[0].lanName
					: "", // 수정자
			minWidth: 200,
			editable: false,
		},
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10010_col_update_date"
					)[0].lanName
					: "", // 수정일
			minWidth: 180,
			editable: false,
		},
	];
	const domain = [
		{
			name: "네이버",
			value: "naver.com",
		},
		{
			name: "구글",
			value: "gmail.com",
		},
		{
			name: "카카오",
			value: "kakao.com",
		},
		{
			name: "직접입력",
			value: " ",
		},
	];
	const [domainNm, setDomainNm] = React.useState("naver.com");
	const [domainInput, setDomainInput] = React.useState("");
	const [checkingToggle, setCheckingToggle] = React.useState(false);
	const [confirmToggle, setConfirmToggle] = React.useState({
		open: false,
		title: "",
		message: "",
		type: "",
	});

	return (
		<>
			<Breadcrumbs
				sx={{ m: 0, mb: 1, p: 0 }}
				separator={<NavigateNextIcon />}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						color: "inherit",
					}}
				>
					{props.lanList.length > 0
						? props.lanList.filter((f) => f.lanId === "bm10000")[0]
							.lanName
						: ""}
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						color: "inherit",
					}}
				>
					{props.lanList.length > 0
						? props.lanList.filter((f) => f.lanId === "bm10010")[0]
							.lanName
						: ""}
				</div>
			</Breadcrumbs>
			<Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid xs={12} md={12}>
					<Card sx={{ display: "flex", justifyContent: "flex-end" }}>
						<AuthGroupButton
							pageName="BM10010"
							target="G01"
							addFunc={() => {
								setCheckingToggle(true);
							}}
							saveFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10010_pop_save_title"
											)[0].lanName
											: "", // 저장
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10010_pop_save_message"
											)[0].lanName
											: "", // 저장하시겠습니까?
									type: "create",
								});
							}}
							deleteFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10010_pop_delete_title"
											)[0].lanName
											: "", // 삭제
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10010_pop_delete_message"
											)[0].lanName
											: "", // 삭제하시겠습니까?
									type: "delete",
								});
							}}
						/>
					</Card>
				</Grid>
				<Grid xs={12} md={12}>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animateRows={true}
								columnDefs={columnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: true,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="multiple"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() =>
									GridFunctions.onSelectionChanged(
										gridRef,
										props.setSelectedRowData
									)
								}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen());
									GridFunctions.onCellValueChanged(
										e,
										gridRef,
										props.setChangedRowData
									);
								}}
								getRowStyle={(e) => {
									if (
										(e.data.useYn === "미사용" ||
											e.data.useYn === "Not used") &&
										e.data.newYn !== "Y"
									) {
										return { color: "#5f5f5f" };
									} else {
										return GridFunctions.getRowStyle(e);
									}
								}}
							/>
						</div>
					</Card>
				</Grid>
			</Grid>
			<Dialog
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10010_pop_key_check"
						)[0].lanName
						: "" // 키체크
				}
				open={checkingToggle}
				onClose={() => {
					props.setCheckings({
						...props.checkings,
						userId: "",
					});
					setCheckingToggle(false);
				}}
				bodySx={{ display: "flex", flexDirection: "column" }}
				fullWidth
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10010_pop_check"
						)[0].lanName
						: "" // 체크
				}
				useSaveAfterClose={false}
				onSave={() => {
					let eMail =
						/^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

					let id = (
						props.checkings.userId +
						"@" +
						domainInput +
						domainNm
					).trim();

					if (eMail.test(id)) {
						props.CheckingLogic(gridRef, id);
						setDomainNm("naver.com");
						setDomainInput("");
						setCheckingToggle(false);
					} else {
						props.setCheckings({
							...props.checkings,
							userId: "",
						});
						setDomainNm("");
						props.setSnacks({
							open: true,
							type: "error",
							message:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"snack_enter_email_form"
									)[0].lanName
									: "", // 이메일 형식으로 입력하세요.
						});
					}
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10010_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={4} md={4.5}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<Input
								sx={{ my: 1 }}
								type="outlined"
								value={props.checkings.userId}
								onChange={(e) => {
									props.setCheckings({
										...props.checkings,
										userId: e,
									});
								}}
								name={
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10010_col_user_id"
										)[0].lanName
										: "" // 사용자 아이디
								}
							/>
							<div style={{ margin: "5px 10px" }}>@</div>
						</div>
					</Grid>
					<Grid xs={4} md={3.5}>
						<Input
							sx={{ my: 1 }}
							type="outlined"
							value={domainNm === " " ? domainInput : domainNm}
							onChange={(e) => {
								setDomainInput(e);
							}}
							name={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId === "bm10010_pop_domain"
									)[0].lanName
									: "" // 도메인
							}
							disabled={domainNm === " " ? false : true}
						/>
					</Grid>
					<Grid xs={4} md={4}>
						<Combo
							sx={{ my: 1, width: "130px" }}
							name={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId === "bm10010_pop_domain"
									)[0].lanName
									: "" // 도메인
							}
							value={domainNm}
							items={domain}
							onChange={(e) => {
								setDomainNm(e);
								setDomainInput("");
							}}
						/>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10010_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onSave={() => {
					if (confirmToggle.type === "create") {
						props.CreateLogic();
					} else if (confirmToggle.type === "delete") {
						props.DeleteLogic();
					}
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10010_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				{confirmToggle.message}
			</Dialog>
		</>
	);
}

export default BM10010;
