import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components */
import {
	Unstable_Grid2 as Grid,
	Card,
	Box,
	TextField,
	Stack,
	Button as MuiButton,
} from "@mui/material";
import { Dialog } from "../../Components/Dialog";
import { Combo } from "../../Components/Combos";
import { Button } from "../../Components/Buttons";
import { Posts } from "../../Components/PostCode";
/** Icons Import */
import {
	Add as CreateIcon,
	Save as UpdateIcon,
	Delete as DeleteIcon,
} from "@mui/icons-material";

function AddController(props) {

	const [postToggle, setPostToggle] = React.useState(false);
	const [screenYn, setScreenYn] = React.useState(
		[
			{
				name: "사용",
				value: "Y"
			},
			{
				name: "미사용",
				value: "N"
			},
		]
	);

	React.useEffect(() => {
		// console.log("porps.usreLan : ", props.userLan);
		if (props.codeList.length > 0) {
			let temp = [];
			if (props.userLan === "en") {
				props.codeList
					.filter((f) => f.groupCd === "USE_YN")
					.forEach((f) => temp.push({
						name: f.ref3,
						value: f.code
					}));
			} else {
				props.codeList
					.filter((f) => f.groupCd === "USE_YN")
					.forEach((f) => temp.push({
						name: f.name,
						value: f.code
					}));
			}
			// console.log("temp : ", temp);
			setScreenYn(temp);
		}
	}, []);

	return (
		<Grid
			xs={12}
			lg={6}
			sx={{
				backgroundColor: "background.paper",
				display: "flex",
				flexDirection: "column",
				position: "relative",
			}}
		>
			<Box
				sx={{
					flexDirection: "column",
					display: "flex",
					flex: "1 1 auto",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "background.paper",
				}}
			>
				<Box
					sx={{
						width: "100%",
						minWidth: Commons.Dimentions().width / 5,
						maxWidth: 600,
						marginTop: 1,
						px: 3,
						py: "10px",
					}}
				>
					<div>
						<form noValidate>
							<Stack spacing={3}>
								{props.addController.disabledNoShow && <Combo
									fullWidth
									name={
										props.lanList.length > 0
											? props.lanList.filter(
												(f) => f.lanId === "cm10000_pop_non_display"
											)[0].lanName
											: "" // 화면 미표시 장치
									}
									items={props.noShowList}
									value={props.addController.itemNo}
									onChange={(e) => {
										props.setAddController({
											...props.addController,
											itemNo: e,
										});
										props.SelectNoShowItem(e);
									}}
								/>}
								<Combo
									fullWidth
									name={
										props.lanList.length > 0
											? props.lanList.filter(
												(f) => f.lanId === "cm10000_pop_model_nm"
											)[0].lanName
											: "" // 모델명
									}
									items={props.controllerList}
									value={props.addController.itemIdx}
									onChange={(e) => {
										// props.setAddController({
										// 	...props.addController,
										// 	itemIdx: e,
										// 	itemNm: props.controllerList.filter((f) => f.value === e)[0].name,
										// });

										if (
											props.controllerList.filter((f) => f.value === e)[0].name === "DSFOX-XLink" ||
											props.controllerList.filter((f) => f.value === e)[0].name === "DSFOX-SLE30" ||
											props.controllerList.filter((f) => f.value === e)[0].name === "DSFOX-SLW30"
										) {
											props.setAddController({
												...props.addController,
												itemIdx: e,
												itemNm: props.controllerList.filter((f) => f.value === e)[0].name,
												joinRtNo: "",
											});
										} else {
											props.setAddController({
												...props.addController,
												itemIdx: e,
												itemNm: props.controllerList.filter((f) => f.value === e)[0].name,
											});
										}
									}}
									disabled={props.addController.disabled}
								/>
								{!props.addController.disabledNoShow && <TextField
									fullWidth
									label={
										props.lanList.length > 0 ?
											props.lanList.filter(
												(f) => f.lanId === "cm10000_pop_item_nm"
											)[0].lanName
											: ""
									}	// 장비명
									value={props.addController.machNm}
									onChange={(e) => {
										props.setAddController({
											...props.addController,
											machNm: e.target.value,
										});
									}}
									autoComplete="off"
								/>}
								<Combo
									fullWidth
									name={
										props.lanList.length > 0 ?
											props.lanList.filter(
												(f) => f.lanId === "cm10000_pop_join_rt_no"
											)[0].lanName
											: ""
									}	// 연결 중계기
									items={props.joinRtNoList}
									value={props.addController.joinRtNo}
									onChange={(e) => {
										props.setAddController({
											...props.addController,
											joinRtNo: e,
											useMac: false,	// 연결 중계기 설정하면 mac 사용 X
										});
									}}
									disabled={
										(
											props.joinRtNoList.length !== 0 &&
												!props.addController.disabled ? false : true
										) ||
										(
											props.addController.itemNm === "DSFOX-XLink" ||
											props.addController.itemNm === "DSFOX-SLE30" ||
											props.addController.itemNm === "DSFOX-SLW30"
										)
									}
								/>
								<div style={{ display: "flex" }}>
									<TextField
										fullWidth
										label={
											props.lanList.length > 0 ?
												props.lanList.filter(
													(f) => f.lanId === "cm10000_pop_mac_add"
												)[0].lanName
												: ""
										}	// 맥 주소
										name=""
										value={props.addController.macAdd}
										onChange={(e) => {
											// console.log("e.target.value :", e.target.value);
											if (e.target.value.length === 12) {
												let temp = e.target.value.replace(/\B(?=(\w{2})+(?!\w))/g, ':');
												// console.log("temp : ", temp);
												props.setAddController({
													...props.addController,
													macAdd: temp,
												});
											} else {
												props.setAddController({
													...props.addController,
													macAdd: e.target.value,
												});
											}
										}}
										disabled={
											props.addController.disabled ||			// 수정하는 경우
											props.addController.joinRtNo !== "" ||	// 연결 중계기를 설정하는 경우
											props.addController.useMac				// 맥 주소 사용 가능 여부 체크한 경우
										}
										autoComplete="off"
									/>
									<Button
										name={
											props.lanList.length > 0 ?
												props.lanList.filter(
													(f) => f.lanId === "home_pop_confirm"
												)[0].lanName
												: ""
										}	// 확인
										onClick={() => props.MacSearch()}
										disabled={props.addController.useMac}
									/>
								</div>
								<TextField
									fullWidth
									label={
										props.lanList.length > 0 ?
											props.lanList.filter(
												(f) => f.lanId === "cm10000_pop_mach_id"
											)[0].lanName
											: ""
									}	// 국번
									name="after Password Check"
									value={props.addController.machId}
									onChange={(e) => {
										props.setAddController({
											...props.addController,
											machId: e.target.value,
										});
									}}
									disabled={props.addController.joinRtNo === "" || props.addController.useMachId}
									autoComplete="off"
								/>
								<Combo
									fullWidth
									name={
										props.lanList.length > 0 ?
											props.lanList.filter(
												(f) => f.lanId === "cm10000_pop_screen_yn"
											)[0].lanName
											: ""
									}	// 화면 표시 유무
									items={screenYn}
									value={props.addController.screenYn}
									onChange={(e) => {
										props.setAddController({
											...props.addController,
											screenYn: e,
										});
									}}
								/>
								<div style={{ display: "flex" }}>
									<TextField
										fullWidth
										label={
											props.lanList.length > 0 ?
												props.lanList.filter(
													(f) => f.lanId === "cm10000_pop_address"
												)[0].lanName
												: ""
										}	// 장치 주소
										name="post"
										value={props.addController.address1}
										disabled
									/>
									<Button
										name={
											props.lanList.length > 0 ?
												props.lanList.filter(
													(f) => f.lanId === "cm10000_button_address"
												)[0].lanName
												: ""
										}	// 찾기
										onClick={() => setPostToggle(true)}
									/>
								</div>
							</Stack>
							{/* <Button
								fullWidth
								size="large"
								sx={{ mt: 3, mb: 3 }}
								variant="contained"
								color="secondary"
								onClick={() => {
									if (props.inputs.beforePassword === "") {
										props.setMessage({
											type: "error",
											toggle: true,
											message:
												(props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"settings_pop_checked_current_password"
													)[0].lanName
													: ""), // 현재 비밀번호를 입력하세요.
										});
									} else if (
										props.inputs.afterPassword === ""
									) {
										props.setMessage({
											type: "error",
											toggle: true,
											message:
												(props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"settings_pop_checked_change_password"
													)[0].lanName
													: ""),
											// 변경할 비밀번호를 입력하세요.
										});
									} else if (
										props.inputs.afterPasswordCheck === ""
									) {
										props.setMessage({
											type: "error",
											toggle: true,
											message:
												(props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"settings_pop_checked_confirm_password"
													)[0].lanName
													: ""),
											// 비밀번호를 한 번 더 입력하세요.
										});
									} else if (
										props.inputs.afterPassword !==
										props.inputs.afterPasswordCheck
									) {
										props.setMessage({
											type: "error",
											toggle: true,
											message:
												(props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"settings_pop_checked_not_match_password"
													)[0].lanName
													: ""),
											// 비밀번호가 일치하지 않습니다.
										});
										props.setInputs({
											...props.inputs,
											afterPassword: "",
											afterPasswordCheck: "",
										});
									} else {
										props.FindUserAccount();
									}
								}}
							>
								{props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"settings_pop_change_password"
									)[0].lanName
									: ""}
							</Button> */}
						</form>
					</div>
				</Box>
			</Box>
			<Posts
				title={
					props.lanList.length > 0 ?
						props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_search_address"
						)[0].lanName
						: ""
				}	// 주소 검색
				open={postToggle}
				closeButtonName={
					props.lanList.length > 0 ?
						props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: ""
				}	// 닫기
				onClose={() => setPostToggle(false)}
				fullScreen={Commons.IsMobile() ? true : false}
				ReceivePost={(data) => {
					props.setAddController({
						...props.addController,
						address1: data.roadAddress,
					});
				}}
			/>
		</Grid>
	);
}

export default AddController;
