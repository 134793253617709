module.exports = {
	base: {
		useUrl: process.env.REACT_APP_USE_URL,
		indexedDb: {
			name: "Local_Conotec",
		},
		serverVersion: "",
		clientVersion: "",
	},
	urls: {
		Common: {
			GetCode: "COMMON/GetCode",
			GetRegisters: "COMMON/GetRegisters",
		},
		Account: {
			login: "api/Account/GetToken", // 로그인
			PushEMail: "api/Account/PushEMail", // 메일 전송
			GetUserInfo: "api/Account/GetUserInfo", // 사용자 정보 재조회
			Logout: "api/Account/Logout",
			CheckUserId: "api/Account/CheckUserId",
			RegisterUser: "api/Account/RegisterUser",
		},
		Social: {
			Kakao: {
				kakao: "kakao",
				GetLoginCallback: "Kakao/GetLoginCallback",
				GetUserProfile: "Kakao/GetUserProfile",
				CheckKakaoUser: "api/Account/CheckKakaoUser", // Kakao user인지 확인
				KakaoLogin: "api/Account/GetKakaoToken", // 로그인

				// 비즈톡 카카오 메시지
				GetToken: "Kakao/GetToken",	// 사용자 토큰 발급
				SendAlimTalkTest: "Kakao/SendAlimTalkTest",	// 테스트 알림 보내기
				SendAlimTalk: "Kakao/SendAlimTalk",	// 알림 보내기
				// GetResult: "Kakao/GetResult",	// 테스트 결과값 전체 받기
				// GetResultAll: "Kakao/GetResultAll",	// 테스트 결과값 전체 받기
				// GetResultPolling: "Kakao/GetResultPolling",	//polling 테스트
			},
		},
		Approval: {
			Approval: "Approval",
			GetCrypto: "Approval/GetCrypto",
			CallApproval: "Approval/CallApproval",
			CallMobileApproval: "Approval/CallMobileApproval",
			MobileStopApproval: "Approval/MobileStopApproval",
			RetryApproval: "Approval/RetryApproval",
			UpdateUseEDate: "Approval/UpdateUseEDate",
		},
		Home: {
			Home: "Home",
			ChangePassword: "Home/ChangePassword",
			TestSockets: "Home/TestSockets",
			GetLanguage: "Home/GetLanguage",	// 언어팩 갱신을 위한 조회
			GetCode: "Home/GetCode",			// 기초코드관리 갱신을 위한 조회
			GetSetting: "Home/GetSettings", // 컨트롤러의 데이터를 조회 Get4101Data(userId, itemNo, type, lan(1: kr, 2: en))
			ChangeOrder: "Home/ChangeOrder", // 컨트롤러의 순서 변경
			GetBooking: "Home/GetBooking", // 예약 데이터 조회
			CreateBooking: "Home/CreateBooking", // 예약 데이터 저장
			DeleteBooking: "Home/DeleteBooking", // 예약 데이터 삭제
			ChangeMachName: "Home/ChangeMachName",
			CopySettings: "Home/CopySettings",
			GetCopyMasters: "Home/GetCopyMasters",
			CopyMasters: "Home/CopyMasters",
			GetRegisterVal: "Home/GetRegisterVal", // TB_REGISTER_VAL
			CheckUserAuth: "Home/CheckUserAuth"	// 사용자 사용유무/결제유무 확인
		},
		Settings: {
			Settings: "Settings",
			GetUserInfo: "Settings/GetUserInfo",
			Create: "Settings/Create",
			ChangeName: "Settings/ChangeName",
			ChangeLanguage: "Settings/ChangeLanguage",
			GetLanguageList: "Settings/GetLanguageList",
		},
		BaseManagement: {
			BM10010: {
				GetCode: "BM10010",
				GetAuthGroup: "BM10010/GetAuthGroup",
				CheckCode: "BM10010/CheckCode",
				Create: "BM10010/Create",
				Delete: "BM10010/Delete",
				GetMemberHisInfo: "BM10010/GetMemberHisInfo",
				UpdateUserPrivacy: "BM10010/UpdateUserPrivacy",
			},
			BM10020: {
				GetCode: "BM10020",
				CheckCode: "BM10020/CheckCode",
				Create: "BM10020/CreateItem",
				Delete: "BM10020/DeleteItem",
				GetRegister: "BM10020/GetRegister",
				CheckRegister: "BM10020/CheckRegister",
				CreateRegister: "BM10020/CreateRegister",
				DeleteRegister: "BM10020/DeleteRegister",
				GetRegisterVal: "BM10020/GetRegisterVal",
				CheckRegisterVal: "BM10020/CheckRegisterVal",
				CreateRegisterVal: "BM10020/CreateRegisterVal",
				DeleteRegisterVal: "BM10020/DeleteRegisterVal",
				MapUpload: "BM10020/MapUpload",
				ValUpload: "BM10020/ValUpload",
			},
			BM10030: {
				GetGroup: "BM10030/GetGroup",
				GetAuthGroup: "BM10030/GetAuthGroup",
				GetMenu: "BM10030/GetMenu",
				CheckAuthGroupCd: "BM10030/CheckAuthGroupCd",
				CreateGroup: "BM10030/CreateGroup",
				CreateAuthGroup: "BM10030/CreateAuthGroup",
				DeleteGroup: "BM10030/DeleteGroup",
				DeleteAuthGroup: "BM10030/DeleteAuthGroup",
				GetMenuGroup: "BM10030/GetMenuGroup",
				GetMenuList: "BM10030/GetMenuList",
				CreateMenuGroup: "BM10030/CreateMenuGroup",
				DeleteMenuGroup: "BM10030/DeleteMenuGroup",
			},
			BM10040: {
				GetCode: "BM10040",
				CheckCode: "BM10040/CheckCode",
				Create: "BM10040/Create",
				Delete: "BM10040/Delete",
			},
			BM10050: {
				GetCode: "BM10050",
				CheckCode: "BM10050/CheckCode",
				Create: "BM10050/Create",
				Delete: "BM10050/Delete",
				ExcelUpload: "BM10050/ExcelUpload",
			},
			BM10060: {
				GetCode: "BM10060",
				Create: "BM10060/Create",
				Delete: "BM10060/Delete",
				FileUpload: "BM10060/FtpUpload",
				FileDownload: "BM10060/FtpDownload",
				AdDownload: "BM10060/AdDownload",
			},
			BM10070: {
				GetMst: "BM10070",
				GetItemMng: "BM10070/GetItemMng",
				CheckCodeMst: "BM10070/CheckCode",
				CreateMst: "BM10070/Create",
				DeleteMst: "BM10070/Delete",
				GetSub: "BM10070/GetSub",
				GetSubForRmCd: "BM10070/GetSubForRmCd",
				GetRegisterMaps: "BM10070/GetRegisterMaps",
				GetRegisterVals: "BM10070/GetRegisterVals",
				CheckCodeSub: "BM10070/CheckCodeSub",
				CreateSub: "BM10070/CreateSub",
				DeleteSub: "BM10070/DeleteSub",
			},
			BM10080: {
				GetCode: "BM10080",
				CheckCode: "BM10080/CheckCode",
				Create: "BM10080/Create",
				Delete: "BM10080/Delete",
				ExcelUpload: "BM10080/ExcelUpload",
			},
		},
		ControllerManagement: {
			CM10000: {
				GetCode: "CM10000",
				GetItem: "CM10000/GetItem",
				CheckCode: "CM10000/CheckCode",
				GetItemList: "CM10000/GetItemList",
				GetMacAdd: "CM10000/GetMacAdd",
				Create: "CM10000/Create",
				Delete: "CM10000/Delete",
				MacSearch: "CM10000/MacSearch",
				SingleCreate: "CM10000/SingleCreate",

			},
		},
		DashboardManagement: {
			DM10010: {
				GetCode: "DM10010",
				CheckCode: "DM10010/CheckCode",
				Create: "DM10010/Create",
				Delete: "DM10010/Delete",
			},
			DM10020: {
				GetCode: "DM10020",
				CheckCode: "DM10020/CheckCode",
				Create: "DM10020/Create",
				Delete: "DM10020/Delete",
			},
			DM10030: {
				GetCode: "DM10030",
				CheckCode: "DM10030/CheckCode",
				Create: "DM10030/Create",
				Delete: "DM10030/Delete",
			},
		},
		SettingManagement: {
			SM10010: {
				Change: "Setting/ChangeValue", // 컨트롤러 설정값 변경
				ChangeAlarm: "Setting/ChangeAlarm",
				GetInOutAlarm: "Setting/GetInOutAlarm",
				ChangeInOutAlarm: "Setting/ChangeInOutAlarm",
				GetItem: "Setting/GetItem",
			},
			SM10020: {
				GetCode: "SM10020",
				CheckCode: "SM10020/CheckCode",
				Create: "SM10020/Create",
				Delete: "SM10020/Delete",
			},
			SM10030: {
				GetCode: "SM10030",
				CheckCode: "SM10030/CheckCode",
				Create: "SM10030/Create",
				Delete: "SM10030/Delete",
			},
		},
		LogManagement: {
			LM10010: {
				GetCode: "LM10010",
				GetBetween: "LM10010/GetBetween", // 두 일자 사이의 그리드 데이터 조회
				GetBetweenTwoYear: "LM10010/GetBetweenTwoYear", // 년도가 넘어가는 두 일자 사이의 그리드 데이터 조회
				GetDeviceGraphData: "LM10010/GetDeviceGraphData", // 대시보드 온도변화 차트에서 사용하는 하루치 차트 데이터 조회
			},
			LM10020: {
				GetCode: "LM10020",
				GetBetween: "LM10020/GetBetween",
			},
			LM10030: {
				GetCode: "LM10030",
				GetBetween: "LM10030/GetBetween",
				GetBetweenTwoYear: "LM10030/GetBetweenTwoYear",
				GetBetweenWithDash: "LM10030/GetBetweenWithDash",
			},
			LM10040: {
				GetCode: "LM10040",
				GetBetween: "LM10040/GetBetween",
				GetBetweenRegister: "LM10040/GetBetweenRegister",
			},
			LM10050: {
				Get: "LM10050",
				GetBetween: "LM10050/GetBetween",
			},
			LM10060: {
				Get: "LM10060",
			},
		},
		GroupManagement: {
			GM10000: {
				GetCode: "GM10000",
				Create: "GM10000/Create",
			},
		},
	},
};
