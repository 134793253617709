import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	List as MuiList,
	ListItem as MuiListItem,
	ListItemButton as MuiListItemButton,
	ListItemText as MuiListItemText,
	ListItemAvatar as MuiListItemAvatar,
	Avatar,
	Typography,
} from "@mui/material";
import { Button } from "../Buttons";
/** Icons Import */

const List = styled((props) => <MuiList {...props} />)(({ theme }) => ({
	// color: "#ffffff",
	// backgroundColor: "#003c8b",
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: "5px",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
	padding: Commons.IsMobile() ? 0 : "8px 0px",
}));

const ListItem = styled((props) => <MuiListItem {...props} />)(({ theme }) => ({
	padding: "0px 8px",
}));

const ListSubTitle = styled((props) => (
	<Typography
		variant={Commons.IsMobile() ? "caption" : "subtitle1"}
		{...props}
	/>
))(({ theme }) => ({
	color: "#a7a7a7",
	fontSize: "12px",
}));

const ListTitle = styled((props) => (
	<Typography variant={Commons.IsMobile() ? "body2" : "body1"} {...props} />
))(({ theme }) => ({
	// color: "#ffffff",
	fontSize: "18px",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}));

function ControllerSelectionList({
	items = [], // Controller selection list's items
	/**items example
				const [listData, setListData] = React.useState([
					{
						id: "1",
						name: "1",
						ListClick: (_item) => {
							console.log(_item);
						},
						ButtonClick: (_item) => {
							console.log(_item);
						},
						options: [
							{ type: "temp", value: "30", status: "on" },
							{ type: "Humi", value: "60", status: "on" },
							{ type: "Lilu", value: "20", status: "on" },
							{ type: "Co2", value: "40", status: "on" },
						],
						order: 1,
					},
					{
						id: "2",
						name: "2",
						ListClick: (_item) => {
							console.log(_item);
						},
						ButtonClick: (_item) => {
							console.log(_item);
						},
						options: [
							{ type: "temp", value: "0", status: "off" },
							{ type: "Lilu", value: "0", status: "off" },
						],
						order: 2,
					},
					{
						id: "3",
						name: "3",
						ListClick: (_item) => {
							console.log(_item);
						},
						ButtonClick: (_item) => {
							console.log(_item);
						},
						options: [
							{ type: "temp", value: "0", status: "off" },
							{ type: "Lilu", value: "0", status: "off" },
						],
						order: 3,
					},
				]);
				 */
	showButton = false,
	buttonName = "설정",
	GetItem = (_item) => {
		console.log(_item);
	}, // ControllerList get item indexes.
}) {
	return (
		<>
			{items.map((item, index) => {
				return (
					<Grid key={index} xs={12} md={4}>
						<Card sx={{ my: 0.5, mx: 1 }}>
							<List className={"controllerSelectionList" + index}>
								<ListItem>
									<MuiListItemButton
										sx={{ p: 1 }}
										onClick={() => {
											GetItem(item);
											Commons.SelectionDocuments(
												"controllerSelectionList",
												index,
												items
											);
										}}
									>
										<MuiListItemText>
											<ListSubTitle>장치명</ListSubTitle>
											<ListTitle>{item.name}</ListTitle>
										</MuiListItemText>
									</MuiListItemButton>
									{showButton ? (
										<Button
											sx={{ p: 1 }}
											color="conotecSub"
											name={buttonName}
											size="medium"
											onClick={() =>
												item.onClick(item.id)
											}
										/>
									) : null}
								</ListItem>
							</List>
						</Card>
					</Grid>
				);
			})}
		</>
	);
}

export default ControllerSelectionList;
