import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardContent as MuiCardContent,
	CardActionArea,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Switch as MuiSwitch,
	Typography,
	Divider,
} from "@mui/material";
import { Input } from "../../Components/Inputs";
import { Button } from "../../Components/Buttons";
/** Icons Import */
import { EditCalendar as WriteIcon } from "@mui/icons-material";
/** Images Import */

const Switch = styled((props) => (
	<MuiSwitch
		focusVisibleClassName=".Mui-focusVisible"
		disableRipple
		{...props}
	/>
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor:
					theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

function AlarmSettingList({
	items = [],
	userLanCode = "kr",
	lanList = [],
	alarmInputs,
	bitsList = [],
	bitsSwitchList = [],
	ChangeBitsSwitch = (e, index) => { },
	ChangeSwitch = (_type, _value) => { },
	ChangeInput = (_type, _type1, _value) => { },
	onSave = () => { },
}) {
	const ReturnBitsList = bitsList.map((item, index) => {
		return (
			<ListItem key={index}>
				<ListItemText
					primary={
						(userLanCode === "en" ? item.MapNmShEng : item.MapNm) +
						(lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "sm10000_gen_alert"
							)[0].lanName
							: "") // 알림 유무
					}
				/>
				<Switch
					checked={bitsSwitchList[index]}
					onChange={(e) => ChangeBitsSwitch(e.target.checked, index)}
				/>
			</ListItem>
		);
	});

	const ReturnAlarmList = items.map((item, index) => {
		// ~ 알림 체크박스
		const ReturnSwitch = () => {
			switch (item.type) {
				case "temp":
					return (
						<Switch
							checked={alarmInputs.tmpArYn}
							onChange={(e) => {
								ChangeSwitch(item.type, e.target.checked);
							}}
						/>
					);
				case "def":
					return (
						<Switch
							checked={alarmInputs.def_Ridch}
							onChange={(e) => {
								ChangeSwitch(item.type, e.target.checked);
							}}
						/>
					);
				case "damp":
					return (
						<Switch
							checked={alarmInputs.hmdArYn}
							onChange={(e) => {
								ChangeSwitch(item.type, e.target.checked);
							}}
						/>
					);
				case "ill":
					return (
						<Switch
							checked={alarmInputs.illArYn}
							onChange={(e) => {
								ChangeSwitch(item.type, e.target.checked);
							}}
						/>
					);
				case "co2":
					return (
						<Switch
							checked={alarmInputs.co2ArYn}
							onChange={(e) => {
								ChangeSwitch(item.type, e.target.checked);
							}}
						/>
					);
			}
		};

		// ~ 알림 최대죄소값 input 박스
		const ReturnInput = (_type) => {
			switch (item.type) {
				case "temp":
					if (_type === "min") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								// inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.tmpMinVal ||
										alarmInputs.tmpMinVal == 0
										? alarmInputs.tmpMinVal
										: ""
								}
								onChange={(e) => {
									// console.log("e : ", e);

									const pattern0 = /[^-0-9\.]/g; // 숫자, ., -만 허용
									const pattern1 = /\.-/g; // .- 방지
									const pattern2 = /-\./g; // -. 방지
									const pattern3 = /^\.\d+/g; // .숫자 방지
									const pattern4 = /\d+-/g; // 숫자- 방지
									const pattern5 = /\.[0-9]*\./g; // .숫자. 방지
									const pattern6 = /--/g; // -- 방지
									const pattern7 = /\.\./g; // ..의 경우 -로 변경

									if (pattern7.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern7, "-"));
									} else if (pattern0.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern0, ""));
									} else if (pattern1.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern1, ""));
									} else if (pattern2.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern2, ""));
									} else if (pattern3.test(e)) {
										ChangeInput(item.type, _type, e.replace(".", ""));
									} else if (pattern4.test(e)) {
										ChangeInput(item.type, _type, e.slice(0, -1));
									} else if (pattern5.test(e)) {
										ChangeInput(item.type, _type, e.slice(0, -1));
									} else if (pattern6.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern6, ""));
									} else {
										ChangeInput(item.type, _type, e);
									}

									// ChangeInput(item.type, _type, e);
								}}
								disabled={!alarmInputs.tmpArYn ? true : false}
							/>
						);
					} else if (_type === "max") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								// inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.tmpMaxVal ||
										alarmInputs.tmpMaxVal == 0
										? alarmInputs.tmpMaxVal
										: ""
								}
								onChange={(e) => {
									// console.log("e : ", e);

									const pattern0 = /[^-0-9\.]/g; // 숫자, ., -만 허용
									const pattern1 = /\.-/g; // .- 방지
									const pattern2 = /-\./g; // -. 방지
									const pattern3 = /^\.\d+/g; // .숫자 방지
									const pattern4 = /\d+-/g; // 숫자- 방지
									const pattern5 = /\.[0-9]*\./g; // .숫자. 방지
									const pattern6 = /--/g; // -- 방지
									const pattern7 = /\.\./g; // ..의 경우 -로 변경

									if (pattern7.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern7, "-"));
									} else if (pattern0.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern0, ""));
									} else if (pattern1.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern1, ""));
									} else if (pattern2.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern2, ""));
									} else if (pattern3.test(e)) {
										ChangeInput(item.type, _type, e.replace(".", ""));
									} else if (pattern4.test(e)) {
										ChangeInput(item.type, _type, e.slice(0, -1));
									} else if (pattern5.test(e)) {
										ChangeInput(item.type, _type, e.slice(0, -1));
									} else if (pattern6.test(e)) {
										ChangeInput(item.type, _type, e.replace(pattern6, ""));
									} else {
										ChangeInput(item.type, _type, e);
									}

									// ChangeInput(item.type, _type, e);
								}}
								disabled={!alarmInputs.tmpArYn ? true : false}
							/>
						);
					} else if (_type === "checkTime") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								// inputMode="number"
								inputMode="numeric"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.tmpCheckTime ||
										alarmInputs.tmpCheckTime === 0
										? alarmInputs.tmpCheckTime
										: ""
								}
								onChange={(e) => {
									if (Number(e) < 0 || isNaN(e)) {
										// ChangeInput(item.type, _type, 0);
									} else if (e.indexOf(".") !== -1) {
										ChangeInput(item.type, _type, 0);
									} else if (e.indexOf("-") !== -1) {
										ChangeInput(item.type, _type, 0);
									} else {
										ChangeInput(item.type, _type, Number(e));
									}
								}}
							// disabled={!alarmInputs.tmpArYn ? true : false}
							/>
						);
					}
				case "damp":
					if (_type === "min") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.hmdMinVal ||
										alarmInputs.hmdMinVal == 0
										? alarmInputs.hmdMinVal
										: ""
								}
								onChange={(e) => {
									ChangeInput(item.type, _type, e);
								}}
								disabled={!alarmInputs.hmdArYn ? true : false}
							/>
						);
					} else if (_type === "max") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.hmdMaxVal ||
										alarmInputs.hmdMaxVal == 0
										? alarmInputs.hmdMaxVal
										: ""
								}
								onChange={(e) => {
									ChangeInput(item.type, _type, e);
								}}
								disabled={!alarmInputs.hmdArYn ? true : false}
							/>
						);
					}
				case "ill":
					if (_type === "min") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.illMinVal ||
										alarmInputs.illMinVal == 0
										? alarmInputs.illMinVal
										: ""
								}
								onChange={(e) => {
									ChangeInput(item.type, _type, e);
								}}
								disabled={!alarmInputs.illArYn ? true : false}
							/>
						);
					} else if (_type === "max") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.illMaxVal ||
										alarmInputs.illMaxVal == 0
										? alarmInputs.illMaxVal
										: ""
								}
								onChange={(e) => {
									ChangeInput(item.type, _type, e);
								}}
								disabled={!alarmInputs.illArYn ? true : false}
							/>
						);
					}
				case "co2":
					if (_type === "min") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.co2MinVal ||
										alarmInputs.co2MinVal == 0
										? alarmInputs.co2MinVal
										: ""
								}
								onChange={(e) => {
									ChangeInput(item.type, _type, e);
								}}
								disabled={!alarmInputs.co2ArYn ? true : false}
							/>
						);
					} else if (_type === "max") {
						return (
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.co2MaxVal ||
										alarmInputs.co2MaxVal == 0
										? alarmInputs.co2MaxVal
										: ""
								}
								onChange={(e) => {
									ChangeInput(item.type, _type, e);
								}}
								disabled={!alarmInputs.co2ArYn ? true : false}
							/>
						);
					}
			}
		};

		return (
			<Grid key={index} xs={4} sm={4} md={4}>
				<List sx={{ height: "100%" }}>
					<ListItem>
						<ListItemText
							primary={
								`${userLanCode === "kr"
									? item.name
									: item.engName
								} ` +
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId === "sm10000_gen_alarm"
									)[0].lanName
									: "") // 알림
							}
						/>
						{ReturnSwitch()}
					</ListItem>
					{item.type === "temp" ? (
						<ListItem>
							<ListItemText
								primary={
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"sm10000_gen_during_defrosting"
										)[0].lanName
										: "" // 제상시 고온 알림
								}
							/>
							<Switch
								checked={alarmInputs.defHgArYn}
								onChange={(e) => {
									ChangeSwitch("def", e.target.checked);
								}}
							// disabled={!alarmInputs.tmpArYn ? true : false}
							/>
						</ListItem>
					) : null}
					<ListItem>
						<ListItemText
							primary={
								`${userLanCode === "kr"
									? item.name
									: item.engName
								} ` +
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"sm10000_gen_alarm_max_value"
									)[0].lanName
									: "") // 알림 최댓값
							}
						/>
						{ReturnInput("max")}
					</ListItem>
					<ListItem>
						<ListItemText
							primary={
								`${userLanCode === "kr"
									? item.name
									: item.engName
								} ` +
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"sm10000_gen_alarm_min_value"
									)[0].lanName
									: "") // 알림 최솟값
							}
						/>
						{ReturnInput("min")}
					</ListItem>
					{item.type === "temp" ? (
						<ListItem>
							<ListItemText
								primary={
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"sm10000_gen_tmp_not_changed"
										)[0].lanName
										: "" // 온도변화 없을시(분)
								}
								secondary={
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"sm10000_gen_tmp_not_changed_sub"
										)[0].lanName
										: "" // 0: 알림없음
								}
							/>
							{ReturnInput("checkTime")}
						</ListItem>
					) : null}
				</List>
				<Divider variant="middle" />
			</Grid>
		);
	});

	return (
		<>
			{/* <Grid xs={4} sm={8} md={12}>
				<List>
					<ListItem>
						<ListItemText
							primary="전체 알림"
							secondary="PUSH 알림"
						/>
						<Switch
							checked={wrapAlarms}
							onChange={(e) => setWrapAlarms(e.target.checked)}
						/>
					</ListItem>
				</List>
				<Divider variant="middle" />
			</Grid> */}
			{/* <Grid xs={4} sm={4} md={4}>
				<List>
					<ListItem>
						<ListItemText primary="제상 시 고온 알림 유무" />
						<Switch checked={true} />
					</ListItem>
				</List>
				<Divider variant="middle" />
			</Grid> */}
			<Grid xs={4} sm={4} md={4}>
				<List sx={{ height: "100%" }}>
					{alarmInputs.runTimeCheckYn === "Y" ? (
						<ListItem>
							<ListItemText
								primary={
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"sm10000_gen_time_out"
										)[0].lanName
										: "" // 가동시간 초과(분)
								}
							/>
							<Input
								sx={{ maxWidth: "50%" }}
								type="outlined"
								inputMode="number"
								inputMode1="numeric"
								size="small"
								value={
									alarmInputs.checkTotalTime ||
										alarmInputs.checkTotalTime == 0
										? alarmInputs.checkTotalTime
										: ""
								}
								onChange={(e) => {
									ChangeInput(
										"checkTotalTime",
										"checkTotalTime",
										e
									);
								}}
								disabled={!alarmInputs.co2ArYn ? true : false}
							/>
						</ListItem>
					) : null}
					<ListItem>
						<ListItemText
							primary={
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"sm10000_gen_repeat_alarm"
									)[0].lanName
									: "" // 반복알림(10분)
							}
						/>
						<Switch
							checked={alarmInputs.retryYn}
							onChange={(e) => {
								ChangeSwitch("retry", e.target.checked);
							}}
						/>
					</ListItem>
				</List>
				<Divider variant="middle" />
			</Grid>
			<Grid xs={4} sm={4} md={4}>
				<List sx={{ height: "100%" }}>{ReturnBitsList}</List>
				<Divider variant="middle" />
			</Grid>
			{ReturnAlarmList}
			<div style={{ width: "100%", margin: "8px" }}>
				<Button
					fullWidth
					name={
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "sm10000_button_save"
							)[0].lanName
							: "" // 저장
					}
					onClick={onSave}
				/>
			</div>
		</>
	);
}

export default AlarmSettingList;
