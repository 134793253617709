import React from "react";
import { Helmet } from "react-helmet";
import { useScript } from "./hooks";
/** Services Import */
import Commons from "./Services/Common/Common";
import IndexedDb from "./Services/IndexedDb/IndexedDb";
/** Components Import */
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Routers from "./Routers/Router";
/** ag-Grid */
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(process.env.REACT_APP_AgGrid_License);

const theme = createTheme({
	palette: {
		mode: "dark",
		background: {
			default: "#1E2430",
			paper: "#1E2430",
		},
		custom_white: {
			main: "#FFFFFF",
		},
		secondary: {
			main: "#388DFC",
		},
		conotecMain: {
			main: "#003c8b",
		},
		conotecSub: {
			main: "#0080b9",
		},
		conotecButton: {
			main: "#388dfc",
		},
		conotecBgcolor: {
			main: "#102c51",
		},
		conotecWhite: {
			main: "#fff",
		},
	},
});

function App() {
	const meta = document.createElement("meta");
	meta.name = "viewport";
	meta.content = "width=device-width, initial-scale=1";
	document.getElementsByTagName("head")[0].appendChild(meta);

	// IndexedDb 버전 관리
	IndexedDb.DropIndexDB().then(async (result) => {
		if (result === "delete") {
			await IndexedDb.CreateIndexedDB();
		} else if (result === true) {
			// await IndexedDb.CreateIndexedDB();
		}
	});
	// IndexedDb.CreateIndexedDB();

	// 로컬 스토리지 버전 관리
	Commons.Storages("set", "version", {
		version: "v2024.09.02-01 aws",
	});

	// 로컬 스토리지 권한 관리
	if (!Commons.Storages("get", "authorization")) {
		Commons.Storages("set", "authorization", {
			authorization: false,
			authDate: Commons.DateFormating(new Date()),
		});
	}

	// 로컬 스토리지 결재팝업 시간 관리
	if (
		!Commons.Storages("get", "approvalAlerted") ||
		!Commons.Storages("get", "approvalAlerted").open ||
		!Commons.Storages("get", "approvalAlerted").updateDate
	) {
		Commons.Storages("set", "approvalAlerted", {
			open: true,
			updateDate: Commons.DateFormating(new Date()),
		});
	}

	// 로컬 스토리지 광고팝업 시간 관리
	if (
		!Commons.Storages("get", "advertisements") ||
		new Date(
			Commons.Storages("get", "advertisements").updateDate
		).getFullYear() < new Date().getFullYear() ||
		new Date(
			Commons.Storages("get", "advertisements").updateDate
		).getMonth() < new Date().getMonth() ||
		new Date(
			Commons.Storages("get", "advertisements").updateDate
		).getDate() < new Date().getDate()
	) {
		Commons.Storages("set", "advertisements", {
			open: true,
			updateDate: Commons.DateFormating(new Date()),
		});
	}

	// 로컬 스토리지 Settings 관리
	if (
		!Commons.Storages("get", "settings") ||
		!Commons.Storages("get", "settings").reloadTime ||
		!Commons.Storages("get", "settings").server ||
		!Commons.Storages("get", "settings").language
	) {
		Commons.Storages("set", "settings", {
			reloadTime: 60000,
			server: "0",
			language: "ko",
		});
	}

	return (
		<ThemeProvider theme={theme}>
			<div>
				<Routers />
			</div>
		</ThemeProvider>
	);
}

export default App;
