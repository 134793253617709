
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

/* 
참조 사이트
https://www.ag-grid.com/react-data-grid/cell-editors/
*/

export default forwardRef((props, ref) => {
	const createInitialState = () => {
		let startValue;

		const eventKey = props.eventKey;

		if (eventKey === KEY_BACKSPACE) {
			// 백스페이스 또는 삭제를 누른 경우 셀을 지웁니다
			startValue = '';
		} else if (eventKey && eventKey.length === 1) {
			// 만약 문자가 눌려졌다면, 우리는 문자부터 시작합니다
			startValue = eventKey;
		} else {
			// 그렇지 않으면 현재 값으로 시작합니다
			startValue = props.value;
		}

		return {
			value: startValue,
		};
	};

	const initialState = createInitialState();
	const [value, setValue] = useState(initialState.value);
	const refInput = useRef(null);

	// focus on the input
	useEffect(() => {
		// get ref from React component
		window.setTimeout(() => {
			const eInput = refInput.current;
			eInput.focus();
		});
	}, []);

	/* Utility Methods */
	const isCharacter = props.eventKey && props.eventKey.length === 1;
	const cancelBeforeStart =
		isCharacter && '1234567890'.indexOf(props.eventKey) < 0;

	// .(소수점) 판단. 온도일 때, 1번만 입력 가능
	const isDotKey = (event) => {
		const key = event.key;
		// console.log("event.target.value : ", event.target.value);
		// console.log("test : ", event.target.value.split(".").length);
		// console.log("prosp : ", props.data);
		return props.data.mapUnit === '℃/℉' && event.target.value.split(".").length === 1 && key === '.';
	};

	// -(마이너스)판단. 온도일 때, 1번만 입력 가능
	const isMinusKey = (event) => {
		const key = event.key;
		return props.data.mapUnit === '℃/℉' && event.target.value.split("-").length === 1 && key === "-";
	};

	// 좌우 화살표 판단
	const isLeftOrRight = (event) => {
		return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
	};

	// 숫자 판단
	const isCharNumeric = (charStr) => {
		return !!/\d/.test(charStr);
	};

	// 숫자 판단
	const isNumericKey = (event) => {
		const charStr = event.key;
		return isCharNumeric(charStr);
	};

	// 팩스페이스 판단
	const isBackspace = (event) => {
		return event.key === KEY_BACKSPACE;
	};

	// 엔터 혹은 탭 판단
	const finishedEditingPressed = (event) => {
		const key = event.key;
		return key === KEY_ENTER || key === KEY_TAB;
	};

	const onKeyDown = (event) => {
		if (isDotKey(event) || isMinusKey(event)) {
			return event.key;
		}

		if (isLeftOrRight(event) || isBackspace(event)) {
			event.stopPropagation();
			return;
		}

		if (!finishedEditingPressed(event) && !isNumericKey(event)) {
			if (event.preventDefault) event.preventDefault();
		}
	};

	/* Component Editor Lifecycle methods */
	useImperativeHandle(ref, () => {
		return {
			// 편집 완료 시 그리드로 보낼 최종 값
			getValue() {
				return value;
			},

			// 편집을 시작하기 전에 한 번 호출되어 편집자에게 다음 작업을 수행할 수 있는 기회를 제공합니다
			// 편집이 시작되기도 전에 편집을 취소합니다.
			isCancelBeforeStart() {
				return cancelBeforeStart;
			},

			// 편집이 완료되면 한 번 호출됩니다(예: Enter 키를 누른 경우).
			// true를 반환하면 편집 결과가 무시됩니다.
			isCancelAfterEnd() {
				// 숫자가 1,000,000보다 크면 거부됩니다
				// 그다지 실용적이지는 않지만 방법을 보여줍니다.
				return value > 1000000;
			},
		};
	});

	return (
		<input
			ref={refInput}
			className={'simple-input-editor'}
			value={value}
			style={{
				height: "100%",
				color: "#ffffff"
			}}
			onChange={(event) => {
				const pattern0 = /[^0-9\.-]/g;		// 숫자, ., -만 허용
				const pattern1 = /^\.-/g;			// .- 방지
				const pattern2 = /^-\./g;			// -. 방지
				const pattern3 = /^\.\d+/g;			// .숫자 방지
				const pattern4 = /\d+-/g;			// 숫자- 방지

				if (pattern0.test(event.target.value)) {
					setValue(event.target.value.replace(pattern0, ""));
				} else if (pattern1.test(event.target.value)) {
					setValue(event.target.value.replace(pattern1, ""));
				} else if (pattern2.test(event.target.value)) {
					setValue(event.target.value.replace(pattern2, ""));
				} else if (pattern3.test(event.target.value)) {
					setValue(event.target.value.replace(".", ""));
				} else if (pattern4.test(event.target.value)) {
					setValue(event.target.value.replace("-", ""));
				} else {
					setValue(event.target.value);
				}
			}}
			onKeyDown={(event) => onKeyDown(event)}
		/>
	);
});