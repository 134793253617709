import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components Import */
import LM10050View from "../../Views/LogManagements/LM10050";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";

function LM10050() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [fromDate, setFromDate] = React.useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [toDate, setToDate] = React.useState(
		new Date(new Date().setHours(23, 59, 59, 0))
	);
	const [rowData, setRowData] = React.useState([]);
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		BetweenDate();
	}, []);

	// 날자 조회
	const BetweenDate = async () => {
		setProgress(true);
		let tempArr = [];
		let userInfo = null;
		let lans = [];
		let search = true;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					setLanList(result);
					result.forEach((item) => lans.push(item));
				}
			});


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "LM10050").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10050.GetBetween,
				{
					userId: userInfo.userId,
					fromDate: fromDate,
					toDate: toDate,
				}
			).then((result) => {
				let data = result.data;
				// // console.log("data : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					// throw new Error(
					// 	lans.length > 0
					// 		? lans.filter(
					// 			(f) => f.lanId === "snack_unavailable_data"
					// 		)[0].lanName
					// 		: ""
					// );
				} else {
					// setRowData(data);
					data.forEach((item) => {
						tempArr.push({
							...item,
							Date: item.Date.replace("T", " "),
						});
					});
					setRowData(tempArr);
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""}
								{/* 로그인 정보가 만료되었습니다. */}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_login_again"
									)[0].lanName
									: ""}
								{/* 다시 로그인 해 주세요. */}
							</p>
						</>
					),
				});
			}
			return "";
		} finally {
			setProgress(false);
		}
	};

	return (
		<div>
			<LM10050View
				rowData={rowData}
				fromDate={fromDate}
				setFromDate={setFromDate}
				toDate={toDate}
				setToDate={setToDate}
				setSnacks={setSnacks}
				lanList={lanList}
				// function
				BetweenDate={BetweenDate}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default LM10050;
