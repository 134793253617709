import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components Import */
import BM10080View from "../../Views/BaseManagements/BM10080";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
/** Icons Import */

/** 언어 관리 */
function BM10080() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [codeList, setCodeList] = React.useState([]);
	// 그리드
	const [rowData, setRowData] = React.useState([]);
	const [selectedRowData, setSelectedRowData] = React.useState([]);
	const [changedRowData, setChangedRowData] = React.useState([]);
	const [addData, setAddData] = React.useState({
		lanId: "",
		lanCode: "",
	});
	// 콤보박스
	const [lanCodeList, setLanCodeList] = React.useState([]); // row 추가시 사용하는 언어타입 콤보박스
	const [lanTypeList, setLanTypeList] = React.useState([]); // row 수정시 사용하는 타입 콤보박스
	// 엑셀 업로드
	const [inputs, setInputs] = React.useState({
		files: "",
		fileName: "",
	});
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
	}, []);

	// 데이터 조회
	const Init = async () => {
		setProgress(true);
		let tempCodeList = [];
		let tempLanCode = [];
		let tempLanType = [];
		let tempArr = [];
		let lans = [];
		let search = true;

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			await IndexedDb.GetCodeAll().then((result) => {
				tempCodeList = result;
			});

			// 언어 타입 콜보박스
			tempCodeList
				.filter((item) => item.groupCd === "LAN_CODE")
				.sort((a, b) => a.sortSeq - b.sortSeq)
				.forEach((item) => {
					tempLanCode.push({
						value: item.code,
						name: item.name,
					});
				});
			setLanCodeList(tempLanCode);

			// 타입 콤보박스
			tempCodeList
				.filter((item) => item.groupCd === "LAN_TYPE")
				.sort((a, b) => a.sortSeq - b.sortSeq)
				.forEach((item) => {
					tempLanType.push({
						value: item.code,
						name: item.name,
					});
				});
			setLanTypeList(tempLanType);


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "BM10080").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10080.GetCode
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					// setRowData(data);
					data.forEach((item) => {
						tempArr.push({
							...item,
							lanCode: tempLanCode.filter(
								(_item) => _item.value === item.lanCode
							)[0].name,
							lanType: tempLanType.filter(
								(_item) => _item.value === item.lanType
							)[0].name,
						});
					});
					setRowData(tempArr);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 그리드 중복 데이터 검사
	const CheckCodeMst = async (_gridref) => {
		setProgress(true);
		let currentData = null;

		try {
			if (addData.lanId === "" || addData.lanId === null) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message:
				// 		(lanList.length > 0
				// 		? lanList.filter(
				// 			(f) =>
				// 				f.lanId ===
				// 				"snack_enter_representative_nm"
				// 		)[0].lanName
				// 		: "")	// 대표명칭을 입력해 주세요",
				// });
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_enter_representative_nm"
						)[0].lanName
						: "")	// 대표명칭을 입력해 주세요",
				);
			} else if (addData.lanCode === "" || addData.lanCode === null) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message:
				// 		(lanList.length > 0
				// 			? lanList.filter(
				// 				(f) =>
				// 					f.lanId ===
				// 					"snack_select_lan_code"
				// 			)[0].lanName
				// 			: "")	// 언어타입을 선택해 주세요",
				// });
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_select_lan_code"
						)[0].lanName
						: "")	// 언어타입을 선택해 주세요",
				);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10080.CheckCode,
					{
						lanId: addData.lanId,
						lanCode: addData.lanCode,
					}
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						throw new Error(
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_unavailable_data"
								)[0].lanName
								: "")	// 데이터가 없습니다.
						);
					} else if (!data) {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_registered_code"
							)[0].lanName
							: "")	// 이미 등록된 코드입니다.
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_available_code"
						// 			)[0].lanName
						// 			: "")	// 사용 가능한 코드입니다.
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_available_code"
									)[0].lanName
									: "")	// 사용 가능한 코드입니다.
						});
						currentData = [
							{
								lanId: addData.lanId,
								lanCode: lanCodeList.filter(
									(item) => item.value === addData.lanCode
								)[0].name,
								newYn: "Y",
							},
							...rowData,
						];

						_gridref.current.api.setRowData(currentData);
						setRowData(currentData);
						// changedRowData 추가
						setChangedRowData([
							...changedRowData,
							{
								lanId: addData.lanId,
								lanCode: lanCodeList.filter(
									(item) => item.value === addData.lanCode
								)[0].name,
								newYn: "Y",
							},
						]);
						dispatch(DrawerActions.setNotOpen()); // 열림 방지
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			// 그리드 추가 버튼 초기화
			setAddData({
				...addData,
				lanId: "",
				lanCode: "",
			});
			setProgress(false);
		}
	};

	// 그리드 데이터 저장
	const CreateLogic = async () => {
		setProgress(true);
		let tempArr = [];
		let tempResult = [];

		try {
			if (changedRowData.length === 0) {
				// 변경된 행이 없으면 에러
			} else {
				// 변경된 데이터의 중복 제거
				tempArr = [...changedRowData].reverse().reduce((prev, now) => {
					if (
						!prev.some(
							(obj) =>
								obj.lanId === now.lanId &&
								obj.lanCode === now.lanCode
						)
					) {
						prev.push(now);
					}
					return prev;
				}, []);

				if (
					tempArr.filter((item) => item.lanType === undefined)
						.length > 0
				) {
					// 타입이 없으면 에러
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		(lanList.length > 0
					// 			? lanList.filter(
					// 				(f) =>
					// 					f.lanId ===
					// 					"snack_select_lan_type"
					// 			)[0].lanName
					// 			: "")	// 타입을 반드시 설정해 주세요.",
					// });
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_select_lan_type"
							)[0].lanName
							: "")	// 타입을 반드시 설정해 주세요.",
					);
				} else {
					tempArr.forEach((item) => {
						tempResult.push({
							lanId: item.lanId,
							lanCode: lanCodeList.filter(
								(_item) => _item.name === item.lanCode
							)[0].value,
							lanName: item.lanName,
							lanType: lanTypeList.filter(
								(_item) => _item.name === item.lanType
							)[0].value,
						});
					});

					await Commons.Axios(
						"post",
						Global.base.useUrl +
						Global.urls.BaseManagement.BM10080.Create,
						tempResult
					).then((result) => {
						let data = result.data;

						if (data.type === "Error") {
							throw new Error(data.message);
						} else if (data !== "Ok") {
							throw new Error((lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_save_failed"
								)[0].lanName
								: ""),	// 저장 실패!
							);
						} else {
							// setSnacks({
							// 	open: true,
							// 	type: "success",
							// 	message:
							// 		(lanList.length > 0
							// 		? lanList.filter(
							// 			(f) =>
							// 				f.lanId ===
							// 				"snack_saved"
							// 		)[0].lanName
							// 		: ""),	// 저장 되었습니다!
							// });
							setSnackDialog({
								open: true,
								title:
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"snack_success_title"
										)[0].lanName
										: "",	// 성공
								message:
									(lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"snack_saved"
										)[0].lanName
										: ""),	// 저장 되었습니다!
							});
							setChangedRowData([]);
							dispatch(DrawerActions.setUseOpen()); // 열림 허용
							Init();
						}
					});
				}
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 그리드 데이터 삭제
	const DeleteLogic = async () => {
		setProgress(true);
		let tempArr = [];
		let tempNewYn = selectedRowData.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete"
					)[0].lanName
					: ""),	// 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				selectedRowData.forEach((item) => {
					tempArr.push({
						lanId: item.lanId,
						lanCode: lanCodeList.filter(
							(_item) => _item.name === item.lanCode
						)[0].value,
						lanName: item.lanName,
						lanType: lanTypeList.filter(
							(_item) => _item.name === item.lanType
						)[0].value,
					});
				});

				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10080.Delete,
					tempArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_delete_failed"
							)[0].lanName
							: ""),	// 삭제 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_deleted"
						// 			)[0].lanName
						// 			: ""),	// 삭제 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_deleted"
									)[0].lanName
									: ""),	// 삭제 되었습니다!
						});
						setChangedRowData([]);
						setSelectedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init();
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 엑셀 파일 업로드
	const ExcelUpload = async () => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			const formData = new FormData();
			formData.append("FormFile", inputs.files);
			formData.append("userId", userInfo.userId);

			await Commons.Axios(
				"file",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10080.ExcelUpload,
				null,
				formData
			).then((result) => {
				let data = result.data;
				// console.log("data : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "파일이 잘못 되었습니다.") {
					throw new Error((lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_file_invalid"
						)[0].lanName
						: ""),	// 파일이 잘 못 되었습니다.
					);
				} else if (data.toString().substring(0, 2) === "업로드") {
					throw new Error(data);
				} else if (data.toString().indexOf("업로드 실패") > -1) {
					throw new Error(data);
				} else {
					// setSnacks({
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		(lanList.length > 0
					// 			? lanList.filter(
					// 				(f) =>
					// 					f.lanId ===
					// 					"snack_uploaded"
					// 			)[0].lanName
					// 			: "")	// 업로드 되었습니다!
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_uploaded"
								)[0].lanName
								: "")	// 업로드 되었습니다!
					});
					setInputs({
						files: "",
						fileName: "",
					});
					Init();
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<>
			<BM10080View
				// 언어팩
				lanList={lanList}
				codeList={codeList}
				setSnacks={setSnacks}
				// 그리드
				rowData={rowData}
				setRowData={setRowData}
				selectedRowData={selectedRowData}
				setSelectedRowData={setSelectedRowData}
				changedRowData={changedRowData}
				setChangedRowData={setChangedRowData}
				// 그리드 중복값
				addData={addData}
				setAddData={setAddData}
				// 콤보박스
				lanCodeList={lanCodeList}
				lanTypeList={lanTypeList}
				// 함수
				CheckCodeMst={CheckCodeMst}
				CreateLogic={CreateLogic}
				DeleteLogic={DeleteLogic}
				// 파일 업로드
				inputs={inputs}
				setInputs={setInputs}
				ExcelUpload={ExcelUpload}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "bm10080_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</>
	);
}

export default BM10080;
