import React from "react";
import { ko } from "date-fns/esm/locale";
/** CSS Import */
import "react-datepicker/dist/react-datepicker.css";
/** Components Import */
import DatePicker from "react-datepicker";

function DatePickerView({
	selected, // select Time
	onChange = (e) => { }, // Change Date
	dateFormat = "yyyy-MM-dd", // DateFormat
	showIcon = false, // show Icon on front
	OpenCalendar = () => { }, // Action when open calendar
	CloseCalendar = () => { }, // Action when close calendar
	clear = false, // clear button on right
	placehold = "test", // placehold text
	autoClose = true, // auto close when select date
	portalId,
	popperClassName,
	style,
	inputStyle,
	disabled,
	className,
	startDate,
	endDate,
	minDate,
	maxDate,
	excludeDates = [],
	excludeDateIntervals = [],
	defaultValue = "", // set default value
	readOnly = false, // read only
	timeFormat,
	timeIntervals,
	timeCaption,
	showTimeSelect,
	filterTime = () => { return true; },
	withPortal = false,
	customInput,
	customTimeInput,
	showMonthYearPicker = false,
	showWeekPicker = false,
	showWeekNumbers = false,
	onFocus = (e) => { e.target.readOnly = true; e.target.blur(); },
}) {
	const DateChange = (e) => {
		onChange(e);
	};
	const CalendarOpen = () => {
		OpenCalendar();
	};
	const CalendarClose = () => {
		CloseCalendar();
	};
	const Focus = (e) => {
		onFocus(e);
	};
	const HandleDateChangeRaw = (e) => {
		e.preventDefault();
	};
	return (
		<div style={style}>
			<DatePicker
				style={inputStyle}
				portalId={portalId}
				popperClassName={popperClassName}
				className={className}
				locale={ko}
				placeholderText={placehold}
				showIcon={showIcon}
				selected={selected}
				onChange={DateChange}
				dateFormat={dateFormat}
				disabled={disabled}
				startDate={startDate}
				endDate={endDate}
				minDate={minDate}
				maxDate={maxDate}
				excludeDates={excludeDates}
				excludeDateIntervals={excludeDateIntervals}
				defaultValue={defaultValue}
				readOnly={readOnly}
				showTimeSelect={showTimeSelect}
				timeFormat={timeFormat}
				timeIntervals={timeIntervals}
				timeCaption={timeCaption}
				filterTime={filterTime}
				onCalendarOpen={CalendarOpen}
				onCalendarClose={CalendarClose}
				shouldCloseOnSelect={autoClose}
				isClearable={clear}
				withPortal={withPortal}
				customInput={customInput}
				customTimeInput={customTimeInput}
				showMonthYearPicker={showMonthYearPicker}
				showWeekPicker={showWeekPicker}
				showWeekNumbers={showWeekNumbers}
				disabledKeyboardNavigation
				onKeyDown={(e) => {
					e.preventDefault();
					return false;
				}}
				autoComplete="off"
				onFocus={Focus}
				inputProps={{ readOnly: true }}
				onChangeRaw={HandleDateChangeRaw}
			/>
		</div>
	);
}

DatePickerView.defaultProps = {
	style: {
		width: "200px",
		margin: "0px 5px",
	},
	showIcon: false,
	dateFormat: "yyyy-MM-dd",
	disabled: false,
};

export default DatePickerView;
