import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Box,
	Button,
	Stack,
	Typography,
	TextField,
	Unstable_Grid2 as Grid,
	IconButton,
	Avatar,
} from "@mui/material";
import { GroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Combo } from "../../Components/Combos";
import { SocialKakao } from "../../Factories/Account/SocialAuth";
import Register from "./Register";
/** Icons Import */
import {
	AccountCircle,
	Visibility,
	VisibilityOff,
	InstallMobile,
} from "@mui/icons-material";
/** Images Import */
import LogoIcon from "../../Assets/Images/Design/drawable-xxxhdpi/intro_conotec_logo.png";
import MailIcon from "../../Assets/Images/Design/drawable-xxxhdpi/popup_mail_ic.png";
import InfoIcon from "../../Assets/Images/Design/drawable-xxxhdpi/popup_info_ic.png";

const FindItem = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

function Login(props) {
	const [serverSettingToggle, setServerSettingToggle] = React.useState(false);
	const [serverSettingValue, setServerSettingValue] = React.useState(
		Commons.Storages("get", "settings").server
	);

	const ChangeId = (e) => {
		props.setInputs({
			...props.inputs,
			userId: e.target.value,
		});
	};

	const ChangePw = (e) => {
		props.setInputs({
			...props.inputs,
			userPw: e.target.value,
		});
	};

	// User ID Click Event Func
	const SelectedIds = () => {
		const inputIds = document.getElementById("LoginId");
		inputIds.select();
		inputIds.focus();
	};

	// User Password Click Event Func
	const SelectedPws = () => {
		const inputPws = document.getElementById("LoginPw");
		inputPws.select();
		inputPws.focus();
	};

	// pwVisible Value Change Func
	const ChangePasswordVisible = () => {
		props.setInputs({
			...props.inputs,
			passwordVisible: !props.inputs.passwordVisible,
		});
	};

	return (
		<>
			<Box
				component="main"
				style={{ height: "100vh" }}
				sx={{
					display: "flex",
					flex: "1 1 auto",
				}}
			>
				<Grid container sx={{ flex: "1 1 auto" }}>
					{!props.registers.register ? (
						<Grid
							xs={12}
							lg={6}
							sx={{
								backgroundColor: "background.paper",
								display: "flex",
								flexDirection: "column",
								position: "relative",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flex: "1 1 auto",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "background.paper",
								}}
							>
								<Box
									sx={{
										width: "100%",
										maxWidth: 550,
										px: 3,
										py: "100px",
									}}
								>
									<Stack spacing={1} sx={{ mb: 5 }}>
										<Typography
											variant="h6"
											color="text.primary"
											textAlign="center"
										>
											Conotec
										</Typography>
										<Typography
											color="text.primary"
											variant="subtitle1"
											textAlign="center"
										>
											Becomes a Global Enterprise.
										</Typography>
									</Stack>
									<form noValidate>
										<Stack spacing={2}>
											<TextField
												id="LoginId"
												fullWidth
												label="ID"
												name="ID"
												type="text"
												value={props.inputs.userId}
												onChange={ChangeId}
												onClick={SelectedIds}
												InputProps={{
													startAdornment: (
														<IconButton
															position="start"
															disabled={false}
														>
															<AccountCircle />
														</IconButton>
													),
												}}
												inputProps={{
													inputMode: "email",
												}}
											/>
											<TextField
												id="LoginPw"
												fullWidth
												label="Password"
												name="Password"
												value={props.inputs.userPw}
												onChange={ChangePw}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														props.LoginLogic();
													}
												}}
												type={
													!props.inputs
														.passwordVisible
														? "password"
														: ""
												}
												onClick={SelectedPws}
												InputProps={{
													startAdornment: (
														<IconButton
															position="start"
															onClick={
																ChangePasswordVisible
															}
														>
															{!props.inputs
																.passwordVisible ? (
																<Visibility />
															) : (
																<VisibilityOff />
															)}
														</IconButton>
													),
												}}
											/>
										</Stack>
										<Stack
											spacing={2}
											sx={{ alignItems: "center", mt: 4 }}
										>
											<Button
												// fullWidth
												size="large"
												sx={{ minWidth: 300 }}
												variant="contained"
												color="secondary"
												onClick={() => {
													props.LoginLogic("direct");
												}}
											>
												Login
											</Button>
											<SocialKakao />
											<GroupButton
												type="text"
												color="custom_white"
												fullWidth={true}
												items={
													// props.deferredPrompt &&
													Commons.IsMobile()
														? [
															{
																name: "Finding the password",
																onClick:
																	() => {
																		props.setInputs(
																			{
																				...props.inputs,
																				findPwToggle: true,
																			}
																		);
																	},
															},
															{
																name: "Register",
																onClick: () => {
																	props.setRegisters({
																		...props.registers,
																		register: true
																	});
																},
															},
															{
																name: "Add to home screen",
																onClick:
																	() => {
																		props.HandleInstall();
																	},
															},
														]
														: [
															{
																name: "Finding the password",
																onClick:
																	() => {
																		props.setInputs(
																			{
																				...props.inputs,
																				findPwToggle: true,
																			}
																		);
																	},
															},
															{
																name: "Register",
																onClick:
																	() => {
																		props.setRegisters(
																			{
																				...props.registers,
																				register: true,
																			}
																		);
																	},
															},
														]
												}
											/>
											<div style={{ color: "#ffffff" }}>
												Google Chrome browser is
												recommended.
											</div>
										</Stack>
									</form>
								</Box>
							</Box>
						</Grid>
					) : (
						<Register
							registers={props.registers}
							setRegisters={props.setRegisters}
							snack={props.snack}
							setSnacks={props.setSnacks}
							CheckUserId={props.CheckUserId}
							RegisterLogic={props.RegisterLogic}
						/>
					)}
					<Dialog
						open={props.inputs.findPwToggle}
						onClose={() => {
							props.setInputs({
								...props.inputs,
								findPwToggle: false,
								findEmail: "",
							});
						}}
						title=""
						children={
							<Box>
								<Stack spacing={2}>
									<FindItem>
										<Avatar
											sx={{
												width: "50px",
												height: "50px",
											}}
											src={MailIcon}
										/>
									</FindItem>
									<FindItem>
										<div style={{ textAlign: "center" }}>
											<Typography
												variant="h6"
												fontWeight="bold"
											>
												임시 비밀번호 전송을 위해
											</Typography>
											<Typography
												variant="h6"
												fontWeight="bold"
											>
												이메일을 입력해 주세요.
											</Typography>
										</div>
									</FindItem>
									<FindItem>
										<TextField
											sx={{ width: "250px" }}
											label=""
											value={props.inputs.findEmail}
											onChange={(e) =>
												props.setInputs({
													...props.inputs,
													findEmail: e.target.value,
												})
											}
											inputProps={{ inputMode: "email" }}
											placeholder="ex) conotec@conotec.co.kr"
										// inputMode="email"
										/>
									</FindItem>
								</Stack>
							</Box>
						}
						saveButtonName="전송"
						onSave={() => props.FindPassword()}
					/>
					<Dialog
						open={props.inputs.findConfirmToggle}
						onClose={() => {
							props.setInputs({
								...props.inputs,
								findConfirmToggle: false,
								findPwToggle: false,
								findEmail: "",
							});
						}}
						title=""
						children={
							<Box>
								<Stack spacing={2}>
									<FindItem>
										<Avatar
											sx={{
												width: "50px",
												height: "50px",
											}}
											src={InfoIcon}
										/>
									</FindItem>
									<FindItem>
										<div style={{ textAlign: "center" }}>
											<Typography
												variant="h6"
												fontWeight="bold"
											>
												입력하신 이메일을 통해
											</Typography>
											<Typography
												variant="h6"
												fontWeight="bold"
											>
												임시 비밀번호가 발급되었습니다.
											</Typography>
											<Typography
												variant="h6"
												fontWeight="bold"
											>
												이메일을 확인해 주세요.
											</Typography>
										</div>
									</FindItem>
								</Stack>
							</Box>
						}
						closeButtonName="확인"
						useSaveButton={false}
					/>
					<Dialog
						open={serverSettingToggle}
						onClose={() => {
							setServerSettingToggle(false);
						}}
						title="서버 설정"
						children={
							<div>
								<Combo
									fullWidth
									name=""
									items={[
										{ name: "코노텍", value: "0" },
										{ name: "하이텍", value: "1" },
									]}
									value={serverSettingValue}
									onChange={(e) => setServerSettingValue(e)}
								/>
							</div>
						}
						closeButtonName="닫기"
						saveButtonName="저장"
						onSave={() => {
							Commons.Storages("set", "settings", {
								...Commons.Storages("get", "settings"),
								server: serverSettingValue,
							});
						}}
					/>
					{!Commons.IsMobile() && (
						<Grid
							className="login-secondary"
							xs={12}
							lg={6}
							sx={{
								alignItems: "center",
								background:
									"radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)",
								color: "white",
								display: "flex",
								justifyContent: "center",
								"& img": {
									maxWidth: "100%",
								},
							}}
						>
							<Box sx={{ p: 3 }}>
								<img src={LogoIcon} />
								{/* <Typography
									align="center"
									color="inherit"
									sx={{
										fontSize: "24px",
										lineHeight: "32px",
										mb: 1,
									}}
									variant="h1"
								>
									Welcome to{" "}
									<Box
										component="a"
										sx={{ color: "#15B79E" }}
										target="_blank"
									>
										Conotec
									</Box>
								</Typography>
								<Typography
									align="center"
									sx={{ mb: 3 }}
									variant="subtitle1"
								>
									KONOTEC Becomes a Global Enterprise.
								</Typography> */}
								{/* <iframe
									className="login-iframe"
									src="https://www.youtube.com/embed/eX4ccnYEbFY?autoplay=1&mute=1&amp;playlist=eX4ccnYEbFY&loop=1&controls=0"
									title="YouTube video player"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
								/> */}
							</Box>
						</Grid>
					)}
				</Grid>
			</Box>
		</>
	);
}

export default Login;
