import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardContent as MuiCardContent,
	CardActionArea,
	Typography,
} from "@mui/material";
/** Icons Import */
import { EditCalendar as WriteIcon } from "@mui/icons-material";
/** Images Import */

const CardContents = styled((props) => <MuiCardContent {...props} />)(
	({ theme }) => ({
		padding: "0px",
		margin: "0px",
	})
);
const CardContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-around",
	alignItems: "center",
}));
const CardContentItemDivs = styled((props) => <div {...props} />)(
	({ theme }) => ({
		display: "flex",
		justifyContent: "space-between",
		// alignItems: "center",
		width: "100%",
	})
);
const CardContentItemDivAction = styled((props) => (
	<CardActionArea {...props} />
))(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const CardContentItemHeader = styled((props) => (
	<Typography variant="caption" {...props} />
))(({ theme }) => ({
	width: "100%",
	textAlign: "center",
	fontWeight: "bold",
	padding: "2px 8px",
	borderWidth: "1px",
	borderBottomWidth: "0px",
	borderColor: "#4A5263",
	borderStyle: "solid",
	color: "#FFFFFF",
	backgroundColor: "#000000",
}));
const CardContentItemData = styled((props) => (
	<Typography variant="body1" {...props} />
))(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	width: "100%",
	height: "60px",
	textAlign: "center",
	// overflow: "hidden",
	// textOverflow: "ellipsis",
	// whiteSpace: "nowrap",
	fontWeight: "500",
	padding: "12px 8px",
	borderWidth: 1,
	borderRightWidth: 0,
	borderColor: "#4A5263",
	borderStyle: "solid",
	color: "#FFFFFF",
}));
const CardContentItemData1 = styled((props) => <Typography {...props} />)(
	({ theme }) => ({
		display: "flex",
		alignItems: "center",
		height: "60px",
		padding: "10.5px 8px",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderColor: "#4A5263",
		borderStyle: "solid",
	})
);

function SettingList({
	items = [], // SettingList's item list
	lanList = [], // language list
	onClick = (e) => {},
}) {
	const ClickItem = (e) => {
		// console.log("e : ", e);
		return onClick(e);
		// if (e.inputType === "1") {
		// 	// 숫자타입 설정값
		// 	let temp = {
		// 		title: e.mapNm,
		// 		message: e.mapComment,
		// 		inputType: e.inputType,
		// 		inputs: [],
		// 		min: Number(e.mapMin),
		// 		max: Number(e.mapMax),
		// 		beforeValue: e.value,
		// 		mapAdd: e.mapAdd,
		// 		value: e.value,
		// 		value1: e.value1 ? e.value1 : e.value,
		// 		value2: e.value2 ? e.value2 : e.value,
		// 		mapUnit: e.mapUnit,
		// 	};
		// 	return onClick(temp);
		// } else {
		// 	// 코드타입 설정값
		// 	let tempInputs = [];
		// 	e.inputs.forEach((item, index) => {
		// 		tempInputs.push({
		// 			value: index,
		// 			name: item,
		// 		});
		// 	});

		// 	let temp = {
		// 		title: e.mapNm,
		// 		// message: e.mapComment + " " + e.mapUnit,
		// 		message: e.mapComment,
		// 		inputType: e.inputType,
		// 		inputs: tempInputs,
		// 		min: Number(e.mapMin),
		// 		max: Number(e.mapMax),
		// 		beforeValue: tempInputs.filter(
		// 			(_item) => _item.name === e.value
		// 		)[0].value,
		// 		mapAdd: e.mapAdd,
		// 		value: tempInputs.filter((_item) => _item.name === e.value)[0]
		// 			.value,
		// 		mapUnit: e.mapUnit,
		// 	};
		// 	return onClick(temp);
		// }
	};

	return (
		<Grid xs={12} md={12}>
			<Card>
				<CardContents style={{ paddingBottom: "5px" }}>
					<CardContentItems>
						<CardContentItemDivs
							style={{ justifyContent: "flex-start" }}
						>
							<CardContentItemHeader>
								{
									lanList.length > 0
										? lanList.filter(
												(f) =>
													f.lanId ===
													"sm10000_gen_settings_name"
										  )[0].lanName
										: "" // 설정명
								}
							</CardContentItemHeader>
							<CardContentItemDivs style={{ width: "40%" }}>
								<CardContentItemHeader>
									{
										lanList.length > 0
											? lanList.filter(
													(f) =>
														f.lanId ===
														"sm10000_gen_settings_value"
											  )[0].lanName
											: "" // 설정 값
									}
								</CardContentItemHeader>
							</CardContentItemDivs>
						</CardContentItemDivs>
					</CardContentItems>
					{items.map((item, index) => {
						return (
							<CardContentItems key={index}>
								<CardContentItemDivs
									style={{ justifyContent: "flex-start" }}
								>
									<CardContentItemData
										sx={{ textAlign: "left" }}
									>
										{item.mapNm}
									</CardContentItemData>
									<CardContentItemDivAction
										style={{ width: "40%" }}
										onClick={() => {
											item.mapType !== "2" &&
												ClickItem(item);
										}}
									>
										<CardContentItemData
											sx={{
												color:
													item.mapType !== "2"
														? ""
														: "grey",
											}}
										>
											{item.showValue ? (
												item.showValue
											) : (
												<>&#00;</>
											)}
										</CardContentItemData>
										{item.mapType !== "2" ? (
											<CardContentItemData1>
												<WriteIcon fontSize="small" />
											</CardContentItemData1>
										) : null}
									</CardContentItemDivAction>
								</CardContentItemDivs>
							</CardContentItems>
						);
					})}
				</CardContents>
			</Card>
		</Grid>
	);
}

export default SettingList;
