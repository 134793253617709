import { createSlice } from "@reduxjs/toolkit";

let initialState = {
	open: false,
	appBarTitle: "FOX IOT",
	changedYn: "Y",
};

const DrawerSlice = createSlice({
	name: "Drawer",
	initialState,
	reducers: {
		OpenDrawer(state) {
			// Drawer 열림
			state.open = true;
		},
		CloseDrawer(state) {
			// Drawer 닫힘
			state.open = false;
		},
		setAppBarTitle(state, action) {
			// App bar 제목 설정
			state.appBarTitle = action.payload;
		},
		setUseOpen(state) {
			// Drawer 열림 가능
			state.changedYn = "Y";
		},
		setNotOpen(state) {
			// Drawer 열림 불가능(알림창)
			state.changedYn = "N";
		},
	},
});

export default DrawerSlice.reducer;
export const DrawerActions = DrawerSlice.actions;
