import React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
/** Services Import */
import Commons from "../Services/Common/Common";

function RequireAuth({ children }) {
	let auth = Commons.Storages("get", "authorization");
	let location = useLocation();

	return auth.authorization ? (
		location.pathname === "/" ? (
			<Navigate replace to="/Home" />
		) : (
			children
		)
	) : location.pathname === "/" ? (
		children
	) : location.pathname === "/auth" ? (
		children
	) : (
		<Navigate replace to="/" />
	);
}

export default RequireAuth;
