import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	Accordion as MuiAccordion,
	AccordionSummary as MuiAccordionSummary,
	AccordionDetails as MuiAccordionDetails,
	AccordionActions as MuiAccordionActions,
	Avatar,
	Typography,
	Checkbox,
} from "@mui/material";
import { Button } from "../../Components/Buttons";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import {
	RadioButtonUnchecked,
	RadioButtonChecked
} from '@mui/icons-material';

/** Icons Import */
import {
	ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
	ExpandMore,
} from "@mui/icons-material";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	color: "#ffffff",
	// backgroundColor: "#05105D",
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: "5px",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={
			<ArrowForwardIosSharpIcon
				sx={{ color: "#ffffff", fontSize: "0.9rem" }}
			/>
		}
		{...props}
	/>
))(({ theme }) => ({
	color: "#ffffff",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const SummaryContainer = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(255, 255, 255, .125)",
}));

const TitleNames = styled((props) => <div {...props} />)(({ theme }) => ({
	width: Commons.IsMobile() ? "200px" : "450px",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	margin: "0px 10px",
}));

// 한 단락
const DetailWrap = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	margin: "5px 0px"
}));

// 한 단락(DetailWrap) 내에서의 한 칸
const DetailInfo = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
}));

// 일반 줄
const InfoBody = styled((props) => (
	<Typography variant={Commons.IsMobile() ? "body2" : "body1"} {...props} />
))(({ theme }) => ({
	color: "#ffffff",
	marginBottom: "10px",
	overflow: "hidden",
	textOverflow: "ellipsis",
	// whiteSpace: "nowrap",
	whiteSpace: "pre-line",
}));

// 회색빛 일반 줄
const InfoSubtitle = styled((props) => (
	<Typography
		variant={Commons.IsMobile() ? "caption" : "subtitle1"}
		{...props}
	/>
))(({ theme }) => ({
	color: "#8D8D8D",
}));

// 한 칸 들여쓰기
const TitleName = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	margin: "0px 10px",
}));

// 두 칸 들여쓰기
const TitleName1 = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	margin: "0px 20px",
}));

function ControllerAccordion({
	items = [], // ControllerAccrodion's data list. not used
	lanList = [],	// language list
	term = {},	//
	setTerm = () => { },
	showCheckbox = true,
}) {

	// 약관 html 파일 가져오기
	const iframePrivacyPart = () => {
		return {
			__html:
				'<iframe src="/Privacy.html" width="100%" height="350px"></iframe>'
		};
	};

	const [accExpanded, setAccExpanded] = React.useState(null);

	const ChangeExpanded = (panel) => (event, newExpanded) => {
		setAccExpanded(newExpanded ? panel : false);
	};

	return (
		<>
			<Grid xs={12} md={4}>
				<Card>
					<Accordion
						expanded={accExpanded === "panel1"}
						onChange={ChangeExpanded("panel1")}
					>
						<AccordionSummary>
							<SummaryContainer>
								{/* <InfoSubtitle>
											개인정보 수집·이용 내역
										</InfoSubtitle> */}
								<InfoBody>
									{
										lanList.length > 0
											? lanList.filter(
												(f) => f.lanId === "home_pop_detail"
											)[0].lanName
											: "" // 개인정보 수집·이용 내역
									}
								</InfoBody>
							</SummaryContainer>
						</AccordionSummary>
						<AccordionDetails>
							{/* <div dangerouslySetInnerHTML={iframePrivacyPart()} /> */}
							<AccordionDetails
								sx={{
									display: "flex",
									flexDirection: "column",
									maxHeight: "30vh",
									overflowY: "scroll"
								}}
							>
								{/** 서문 */}
								<DetailWrap>
									<DetailInfo>
										<InfoBody>
											주식회사 코노텍(이하 '코노텍'이라 함)는 FoxIoT 서비스(이하 ‘서비스’) 이용자의 개인정보를 다음과 같이 취급하고 있습니다.
										</InfoBody>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제1조 개인정보의 처리 목적
										</InfoSubtitle>
										<TitleName>
											1. 사용자의 이름, 전자메일 주소, 전화번호, 주소를 수집하여 회원 관리, IoT 장치의 정보 제공 등의 목적으로 처리합니다.
										</TitleName>
										<TitleName>
											2. 서비스 이용 또는 업무처리 과정에서 생성된 정보 수집
										</TitleName>
										<TitleName>
											3. 코노텍은 수집한 정보를 다음과 같은 목적으로 이용합니다.
										</TitleName>
										<TitleName1>
											① 이용자가 서비스를 원활히 이용할 수 있도록 돕기 위해
										</TitleName1>
										<TitleName1>
											② 본인확인과 부정 이용 방지를 위해
										</TitleName1>
										<TitleName1>
											③ 서비스 이용에 관한 통계 데이터를 작성하기 위해
										</TitleName1>
										<TitleName1>
											④ 캠페인 및 이벤트 등의 추첨 및 선물 발송을 위해
										</TitleName1>
										<TitleName1>
											⑤ 중요한 공지사항 등을 필요에 따라 공지하기 위해
										</TitleName1>
										<TitleName1>
											⑥ 광고성 정보의 전달을 위해
										</TitleName1>
										<TitleName1>
											⑦ 상품구매, 유료 서비스 이용 시의 요금 결제 및 청구를 위해
										</TitleName1>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제2조 개인정보의 처리 및 보유 기간
										</InfoSubtitle>
										<TitleName>
											1. 사용자의 개인정보는 회원 탈퇴 시까지 보유하며, 탈퇴 시 즉시 파기합니다.
										</TitleName>
										<TitleName>
											2. 운영상 수집정보
										</TitleName>
										<TitleName1>
											① 서비스 이용 또는 업무처리 과정에서 생성되어 수집될 수 있는 정보
										</TitleName1>
										<TitleName1>
											② 서비스 이용기록, 접속로그, 접속IP정보, 접속IP정보 결제기록, 이용정지 기록
										</TitleName1>
										<TitleName>
											3. 서비스 이용 또는 업무처리 과정에서 생성되거나 생성정보 수집 툴을 통해 정보 수집 등
										</TitleName>
										<TitleName>
											4. 코노텍은 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 이용자의 개인정보를 파기합니다.
										</TitleName>
										<TitleName1>
											단, 회사 내부방침이나 관계법령의 규정에 의하여 보존할 필요가 있는 경우에는 일정기간 동안 개인정보를 보관할 수 있습니다.
										</TitleName1>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제3조 개인정보 자동 수집 장치의 설치 / 운영 및 거부에 관한 사항
										</InfoSubtitle>
										<TitleName>
											1. 쿠키의 사용 목적
										</TitleName>
										<TitleName1>
											① 이용자들이 사용한 서비스와 웹 사이트들에 대한 방문 및 이용형태, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.
										</TitleName1>
										<TitleName>
											2. 쿠키의 설치/운영 및 거부
										</TitleName>
										<TitleName1>
											① 이용자는 쿠키 설정에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저의 옵션을 설정함으로써, 쿠키가 저장될 때마다 확인하거나 모든 쿠키의 허용 또는 모든 쿠키의 저장을 거부할 수도 있습니다.
										</TitleName1>
										<TitleName1>
											다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
										</TitleName1>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제3조 개인정보의 제3자 제공
										</InfoSubtitle>
										<TitleName>
											1. 사용자의 동의 없이 개인정보를 제3자에게 제공하지 않습니다. 다만, 아래의 경우 예외로 합니다.
										</TitleName>
										<TitleName1>
											① 이용자들이 사전에 동의한 경우
										</TitleName1>
										<TitleName1>
											② 법령의 규정에 의한 경우
										</TitleName1>
										<TitleName>
											2. 쿠키의 설치/운영 및 거부
										</TitleName>
										<TitleName>
											2. 개인정보를 제공받는 자의 이용목적
										</TitleName>
										<TitleName1>
											① 대금결제 및 판매자와 구매자의 원활한 거래 진행, 본인의사의 확인, 고객상담 및 불만처리/부정이용 방지 등의 고객관리, 상품/서비스 구매에 따른 혜택 제공 등
										</TitleName1>
										<TitleName>
											3. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간
										</TitleName>
										<TitleName1>
											① 개인정보 이용목적 달성 시까지 보존합니다. 단, 관계 법령의 규정에 의하여 일정 기간 보존이 필요한 경우에는 해당 기간만큼 보관 후 삭제합니다.
										</TitleName1>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제4조 개인정보처리의 위탁
										</InfoSubtitle>
										<TitleName>
											1. 개인정보의 처리를 위탁하지 않습니다.
										</TitleName>
										<TitleName>
											2. 코노텍은 서비스 제공을 위해 아래와 같이 개인정보를 위탁하고 있으며, 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
										</TitleName>
										<TitleName1>
											① 수탁업체 및 위탁업무 내용
										</TitleName1>
										<TitleName1>
											② 결제 대행사 : ㈜ 스마트로
										</TitleName1>
										<TitleName>
											3. 개인정보의 보유 및 이용기간 : 회원 탈퇴 시 또는 위탁계약 종료 시
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제5조 정보주체의 권리, 의무 및 그 행사 방법
										</InfoSubtitle>
										<TitleName>
											1. 사용자는 개인정보의 열람, 정정, 삭제를 요청할 수 있으며, 개인정보 보호 책임자에게 서면, 전화 또는 전자우편으로 연락하시면 됩니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제6조 처리하는 개인정보의 항목
										</InfoSubtitle>
										<TitleName>
											1. 이름, 전자 메일 주소, 전화번호, 주소
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제7조 개인정보의 파기 절차 및 방법
										</InfoSubtitle>
										<TitleName>
											1. 사용자의 개인정보는 회원 탈퇴 시까지 보유하며, 탈퇴 시 즉시 파기합니다.
										</TitleName>
										<TitleName>
											2. 이용자가 서비스 이용 등을 위해 입력한 정보는 목적이 달성된 후, 내부 방침 및 기타 관련 법령에 의한 정보보호 사유(보유 및 이용기간 참조)에 따라 일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제8조 개인정보 보호책임자
										</InfoSubtitle>
										<TitleName>
											1. 개인정보 보호 책임자의 이름, 연락처 등은 본 사이트의 개인정보 처리 방침에서 확인할 수 있습니다.
										</TitleName>
										<TitleName>
											2. 본 개인정보취급방침에 관해 질문/불만/상담 내용이 있는 경우 개인정보관리 담당부서(conotec@conotec.co.kr)로 신고하실 수 있습니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제9조 정책 변경에 따른 공지의무
										</InfoSubtitle>
										<TitleName>
											1. 개인정보 처리 방침은 변경될 수 있으며, 변경된 내용은 본 사이트의 공지사항을 통해 공지됩니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제10조 적용범위
										</InfoSubtitle>
										<TitleName>
											본 취급방침에 대한 책임 및 권한은 코노텍에 있으며, 개인정보취급방침은 서비스를 이용하는  모든 이용자에게 적용됩니다. 또한 링크 페이지 등 다른 사업자에 의한 개인정보 수집은 본 개인정보취급방침의 적용 범위가 아닙니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								{/* <DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											{"　　　　　"}
										</InfoSubtitle>
										<InfoBody>
											* 귀하는 위와 같이 개인정보를 수집·이용하는데 동의를 거부할 권리가 있습니다.
										</InfoBody>
										<InfoBody>
											* 필수 수집항목에 대한 동의를 거절하는 경우 서비스 이용이 제한될 수 있습니다.
										</InfoBody>
									</DetailInfo>
								</DetailWrap> */}
							</AccordionDetails>
							{showCheckbox && <DetailWrap>
								<InfoBody>
									<Checkbox
										value={term.userTerm}
										onChange={() => {
											setTerm({
												...term,
												userTerm: term.userTerm ? false : true,
											});
										}}
									/>
									{
										lanList.length > 0
											? lanList.filter(
												(f) => f.lanId === "home_pop_agree_term"
											)[0].lanName
											: "" // 위 회원가입 약관에 동의합니다.
									}
								</InfoBody>
							</DetailWrap>}
						</AccordionDetails>
					</Accordion>
					<div style={{ marginTop: "15px", marginBottom: "15px" }}></div>
					<Accordion
						expanded={accExpanded === "panel2"}
						onChange={ChangeExpanded("panel2")}
					>
						<AccordionSummary>
							<SummaryContainer>
								{/* <InfoSubtitle>
											개인정보 수집·이용 내역
										</InfoSubtitle> */}
								<InfoBody>
									{
										lanList.length > 0
											? lanList.filter(
												(f) => f.lanId === "home_pop_agree_site"
											)[0].lanName
											: "" // 사이트 이용 동의
									}
								</InfoBody>
							</SummaryContainer>
						</AccordionSummary>
						<AccordionDetails>
							<AccordionDetails
								sx={{
									display: "flex",
									flexDirection: "column",
									maxHeight: "30vh",
									overflowY: "scroll"
								}}
							>
								{/** 서문 */}
								<DetailWrap>
									<DetailInfo>
										<InfoBody>
											FoxIoT 서비스(이하 “서비스”)는 주식회사 코노텍에서 제공합니다. 본 약관은 사용자가 FoxIoT 서비스를 사용하는 데 적용됩니다.
										</InfoBody>
										<InfoBody>
											주식회사 코노톡(이하 “회사”)은 정보 통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 다하고 있습니다.
										</InfoBody>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 1 조( 목적 )
										</InfoSubtitle>
										<TitleName>
											이 약관은 “회사”가 운영하는 “서비스”를 이용함에 있어 코노텍과 회원 및 비회원의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 2 조( 정의 )
										</InfoSubtitle>
										<TitleName>
											1. "회원"이라 함은 "회사"에 개인정보를 제공하여 회원 등록을 한 자로서, "회사"의 정보를 지속적으로 제공받으며, "회사"가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
										</TitleName>
										<TitleName>
											2. “유료 회원”이라 함은 일반 회원에서 유료 회원 가입을 통하여 유료 요금제 결제를 한 회원을 말합니다.
										</TitleName>
										<TitleName>
											2. “비회원”이라 함은 회원에 가입하지 않고 "회사"가 제공하는 서비스를 이용하는 자를 말합니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 3 조 (약관 등의 명시와 설명 및 개정)
										</InfoSubtitle>
										<TitleName>
											1. "회사"는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호·모사전송번호·전자우편 주소, 사업자등록번호, 통신판매업 신고번호, 개인정보 보호 책임자 등을 회원 및 비회원이 쉽게 알 수 있도록 서비스의 초기 화면에 게시합니다. 다만, 약관의 내용은 회원 및 비회원이 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.
										</TitleName>
										<TitleName>
											2. "회사"는 서비스를 이용하고자 하는 회원 및 비회원에게 약관에 정하여져 있는 내용 중 청약철회 배송 책임 환불 조건 등과 같은 중요한 내용을 별도의 연결 화면 또는 팝업 화면 등을 제공하여 확인할 수 있도록 합니다.
										</TitleName>
										<TitleName>
											3 "회사"는 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제에 관한 법률, 전자거래 기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 방문판매 등에 관한 법률, 소비자 보호법 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
										</TitleName>
										<TitleName>
											4 "회사"가 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 서비스의 초기 화면에 그 적용 일자 7일 이전부터 적용 일자 전일까지 공지합니다. 다만, 회원에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "회사"는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
										</TitleName>
										<TitleName>
											5. 본 이용 약관의 일부 조항(들)이 무효, 불법 또는 이행 불가능한 것으로 판명되는 경우라 하더라도, 나머지 다른 조항들의 유효성, 법적 타당성 및 이행 가능성은 여전히 그대로 유효한 것으로 간주됩니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 5 조( FoxIoT 서비스 란 )
										</InfoSubtitle>
										<TitleName>
											① 서비스는 누구나 회원 가입이 가능 합니다.
										</TitleName>
										<TitleName>
											② AWS 클라우드 대용량 저장소 사용, 서비스는 코노텍의 IoT 장치를 구매하고 유료 회원 요금제 결제를 하신 고객에 대해 IoT 장치에서 수집되는 정보를 기록하는 서비스를 제공합니다. 기록된 데이터는 다양한 형태로 다시 사용자에게 제공됩니다.
										</TitleName>
										<TitleName>
											③ IoT 장치 또는 IoT 장치에 연결된 코노텍 제품의 상태를 파악하여 사용자에게 한눈에 확인 가능하도록 보여주는 기능을 제공합니다.
										</TitleName>
										<TitleName>
											④ 구글 푸시, 카카오톡 알림 또는 문자메시지 서비스를 통해 IoT 장치 상태, 수집되는 데이터의 값을 모니터링하면서, 문제가 있는 경우에는 즉각적으로 사용자에게 알려주는 기능을 제공합니다. 이런 기능은 사용자가 직접 선택 할 수 있습니다.
										</TitleName>
										<TitleName>
											⑤ 서비스 회원 중 정상적이지 않은 방법으로 데이터를 요청하거나, 서비스 접근을 하거나, 서비스 내용를 조작해서는 안 됩니다. 위와 같은 사항이 발견되면 “회사”는 회원의 서비스 사용을 종료시키거나 제한할 수 있습니다.
										</TitleName>
										<TitleName>
											⑥ 서비스는 사용자가 서비스에 접속하여 IoT 장치를 관리하는 모든 행위에 대해 기록을 남기고 있습니다.
										</TitleName>
										<TitleName>
											⑦ 서비스는 데이터를 수집하고 구글 푸시, 카카오톡 또는 문자메시지 알림 서비스를 제공합니다. 특정 한곳에 문제가 발생하면 데이터 수집이 끊기는 현상 혹은 사용자에게 통보하는 기능에 일시적 장애가 발생합니다. 이런 장애에 대해서 서비스는 전혀 배상에 대한 책임은 없습니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 6 조( 서비스의 제공 및 변경 )
										</InfoSubtitle>
										<TitleName>
											1. "회사"는 다음과 같은 업무를 수행합니다.
										</TitleName>
										<TitleName1>
											① 재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결
										</TitleName1>
										<TitleName1>
											② 구매 계약이 체결된 재화 또는 용역의 배송
										</TitleName1>
										<TitleName1>
											③ 기타 "회사"가 정하는 업무
										</TitleName1>
										<TitleName>
											2. "회사"는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공 일자를 명시하여 회원에게 통지합니다.
										</TitleName>
										<TitleName>
											3. "회사"가 제공하기로 회원과 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 회원에게 통지 가능한 주소로 즉시 통지합니다.
										</TitleName>
										<TitleName>
											4. 보증 및 책임의 제한
											서비스는 보증 없이 제공됩니다. 특히, 서비스의 중단 없는 제공이 이루어지거나 오류 무결성을 보증하지 않습니다. 회원은 모든 특별 손해, 2차 손해 등 발생한 모든 직접 손해에 대하여 서비스에 손해배상을 청구할 권리를 포기합니다. 본 조항은 회원의 거주 국가의 법률에 따라 인정되는 최저 보증 조건이나 소비자 보호권리를 제한하지 않습니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 6 조( 서비스의 중단 )
										</InfoSubtitle>
										<TitleName>
											1. "회사"는 컴퓨터 등 정보통신 설비의 보수 점검 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
										</TitleName>
										<TitleName>
											2. "회사"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 회원 또는 제3자가 입은 손해에 대하여 배상합니다. 단 "회사"가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
										</TitleName>
										<TitleName>
											3. 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "회사"는 회원에게 통지하고 당초 "회사"에서 제시한 조건에 따라 소비자에게 보상합니다. 다만 "회사"가 보상 기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 "회사"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 7 조( 회원의 가입 )
										</InfoSubtitle>
										<TitleName>
											1. 회원은 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.
										</TitleName>
										<TitleName>
											2. 회원가입계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.
										</TitleName>
										<TitleName>
											3. 서비스 가입 시 생성한 비밀번호는 암호화 되어 저장됩니다. 비밀번호에 대한 분실 시 발생되는 문제는 회원 스스로 책임을 져야 합니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제8조(회원 탈퇴 및 자격 상실 등)
										</InfoSubtitle>
										<TitleName>
											1. 회원은 언제든지 회원 탈퇴를 할 수 있으며, 탈퇴를 희망하는 경우 회원 탈퇴 신청서를 제출하면 됩니다.
										</TitleName>
										<TitleName>
											2. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
										</TitleName>
										<TitleName1>
											① 가입 신청 시에 허위 내용을 등록한 경우
										</TitleName1>
										<TitleName1>
											② 타인의 명예를 훼손하거나 손해를 가하는 행위를 한 경우
										</TitleName1>
										<TitleName1>
											③ 개인정보를 포함한 정보를 무단으로 유출한 경우
										</TitleName1>
										<TitleName1>
											④ 서비스 이용 약관을 위반한 경우
										</TitleName1>
										<TitleName>
											3. 회사는 회원자격을 상실한 회원에 대하여는 회원 재가입을 승낙하지 않을 수 있습니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 9 조( 회원에 대한 통지 )
										</InfoSubtitle>
										<TitleName>
											1. 회사는 회원에 대한 통지를 하는 경우, 회원이 회사에 제출한 전자우편 주소로 할 수 있습니다.
										</TitleName>
										<TitleName>
											2. 회사는 불특정 다수 회원에 대한 통지의 경우 1주일 이상의 기간 동안 서비스 팝업으로 게시함으로써 개별 통지에 갈음할 수 있습니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 10 조( 구매 신청 )
										</InfoSubtitle>
										<TitleName>
											1. 유료 회원 가입을 원하는 회원은 유료 요금제 결제를 합니다.
										</TitleName>
										<TitleName>
											2. 구매 신청 시 신청자의 성명, 주소, 전화번호, 전자우편 주소 등을 정확하게 기재하여야 합니다.
										</TitleName>
										<TitleName>
											3. 수시로 서비스 요금제 및 가격을 변경할 수 있으나, 이러한 서비스 요금제 및 요금의 변경은 해당 내용을 회원에게 이메일로 통지한 날로부터 15일이 경과한 후 효력이 발생합니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 11 조 ( 계약의 성립 )
										</InfoSubtitle>
										<TitleName>
											1. “회사”는 “회원”이 청약한 내용에 대하여 승낙할 경우, 계약이 성립됩니다.
										</TitleName>
										<TitleName>
											2. “회사”의 승낙은 “회원”에게 도달한 시점에 효력이 발생합니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 12 조 ( 지급방법 )
										</InfoSubtitle>
										<TitleName>
											“회사”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.
										</TitleName>
										<TitleName>
											1. 계좌이체
										</TitleName>
										<TitleName>
											2. 신용카드 결제
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 14 조 ( 청약철회 등 )
										</InfoSubtitle>
										<TitleName>
											1. “회원”은 “회사”에 재화 또는 용역의 구매에 대한 청약철회를 할 수 있습니다. 다만, 청약철회는 제품 수령일로부터 7일 이내에 가능합니다.
										</TitleName>
										<TitleName>
											2. “회사”는 “회원”이 청약철회를 한 경우, 이용자의 요구에 따라 즉시 환불 처리합니다.
										</TitleName>
										<TitleName>
											3. 해지는 유료 회원은 언제라도 해지할 수 있습니다. 이 경우 저장된 데이터를 열람할 수 없는 등의 제약이 따르며, 해지에 환불 또는 공제는 제공되지 않습니다.
										</TitleName>
										<TitleName>
											4. 유료 회원 이용기간이 만료되면 회원은 만료일부터 유료 서비스 이용이 불가능 합니다. 이후 180일 이내에 유료 회원 요금을 결제하여야 합니다. 이 기간 내에 결제가 이루어지지 않으면 모든 데이터가 삭제됩니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 15 조 ( 소비자의 불만 처리 )
										</InfoSubtitle>
										<TitleName>
											1. 회사는 소비자의 불만 사항이 접수되는 경우, 신속하고 공정하게 처리합니다.
										</TitleName>
										<TitleName>
											2. 회사는 소비자의 불만 사항을 처리하기 위하여 소비자 불만 처리 위원회를 운영합니다.
										</TitleName>
										<TitleName>
											3. 서비스 및 서비스에 연결된 장치에 대한 지원은 코노텍 또는 제품을 구입한 판매점에서 지원을 받을 수가 있습니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								<DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											제 16 조 ( 거래의 증명 )
										</InfoSubtitle>
										<TitleName>
											1. 회사는 거래의 증명을 위하여 필요한 경우 회원에게 거래명세서를 발급할 수 있습니다.
										</TitleName>
									</DetailInfo>
								</DetailWrap>
								{/**------------------------------------------------------------------------------------------------------------ */}
								{/* <DetailWrap>
									<DetailInfo>
										<InfoSubtitle>
											{"　　　　　"}
										</InfoSubtitle>
										<InfoBody>
											* 귀하는 위와 같이 개인정보를 수집·이용하는데 동의를 거부할 권리가 있습니다.
										</InfoBody>
										<InfoBody>
											* 필수 수집항목에 대한 동의를 거절하는 경우 서비스 이용이 제한될 수 있습니다.
										</InfoBody>
									</DetailInfo>
								</DetailWrap> */}
							</AccordionDetails>
							{showCheckbox && <DetailWrap>
								<InfoBody>
									<Checkbox
										value={term.siteTerm}
										onChange={() => {
											setTerm({
												...term,
												siteTerm: term.siteTerm ? false : true,
											});
										}}
									/>
									{
										lanList.length > 0
											? lanList.filter(
												(f) => f.lanId === "home_pop_agree_term"
											)[0].lanName
											: "" // 위 회원가입 약관에 동의합니다.
									}
								</InfoBody>
							</DetailWrap>}
						</AccordionDetails>
					</Accordion>
				</Card>
			</Grid>
			{/** not used */}
			{/* <Grid xs={12} md={4}>
				<Card>
					<Accordion
						expanded={accExpanded === "panel2"}
						onChange={ChangeExpanded("panel2")}
					>
						<AccordionSummary>
							<SummaryContainer>
								<Checkbox
									icon={<RadioButtonUnchecked fontSize="large" />}
									checkedIcon={<RadioButtonChecked fontSize="large" />}
								/>
								<TitleNames>
									<TitleName>
										<InfoSubtitle>
											장치명
										</InfoSubtitle>
										<InfoBody>
											장치명
										</InfoBody>
									</TitleName>
								</TitleNames>
							</SummaryContainer>
						</AccordionSummary>
						<AccordionDetails>
							<DetailWrap>
								<DetailInfo>
									<InfoSubtitle>
										고유 번호
									</InfoSubtitle>
									<InfoBody>
										고유번호
									</InfoBody>
								</DetailInfo>
							</DetailWrap>
						</AccordionDetails>
					</Accordion>
				</Card>
			</Grid>
			<Grid xs={12} md={4}>
				<Card>
					<Accordion
						expanded={accExpanded === "panel3"}
						onChange={ChangeExpanded("panel3")}
					>
						<AccordionSummary>
							<SummaryContainer>
								<Checkbox
									icon={<RadioButtonUnchecked fontSize="large" />}
									checkedIcon={<RadioButtonChecked fontSize="large" />}
								/>
								<TitleNames>
									<TitleName>
										<InfoSubtitle>
											장치명
										</InfoSubtitle>
										<InfoBody>
											장치명
										</InfoBody>
									</TitleName>
								</TitleNames>
							</SummaryContainer>
						</AccordionSummary>
						<AccordionDetails>
							<DetailWrap>
								<DetailInfo>
									<InfoSubtitle>
										고유 번호
									</InfoSubtitle>
									<InfoBody>
										고유번호
									</InfoBody>
								</DetailInfo>
							</DetailWrap>
						</AccordionDetails>
					</Accordion>
				</Card>
			</Grid> */}
		</>
	);
}

export default ControllerAccordion;



/** items example
const accordionData = [
	{
		color: "#000000",
		deviceName: "비닐하우스1-입구",
		deviceIndex: "A8:A2:43:9E:A8:7B",
		onClick: (_item) => {
			console.log(_item);
		},
		position: "부산광역시 연제구 월드컵대로 118, 7층",
		deviceManager: "박준영",
		infos: [
			{
				subscript: "온도",
				data: "30",
			},
			{
				subscript: "습도",
				data: "72",
			},
		],
	},
	{
		color: "#ffffff",
		deviceName: "비닐하우스1-중앙",
		deviceIndex: "E8:33:43:9F:D8:7C",
		onClick: (_item) => {
			console.log(_item);
		},
		position: "부산광역시 연제구 월드컵대로 118, 7층",
		deviceManager: "박준영",
		infos: [
			{
				subscript: "온도",
				data: "30",
			},
		],
	},
	{
		deviceName: "비닐하우스1-끝",
		deviceIndex: "F6:33:05:9D:A8:21",
		onClick: (_item) => {
			console.log(_item);
		},
		position: "부산광역시 연제구 월드컵대로 118, 7층",
		deviceManager: "박준영",
		infos: [
			{
				subscript: "온도",
				data: "30",
			},
			{
				subscript: "제상온도",
				data: "29",
			},
		],
	},
];
*/