import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card, Tabs, Tab } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
import { FileForm } from "../../Components/FileForm";
/** Ag-grid Import */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";

/** MAC 주소관리 화면 */

function BM10050(props) {
	const gridRef = React.useRef();
	const columnDefs = [
		{
			field: "macAdd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10050_col_mac_address"
					)[0].lanName
					: "", // 맥 주소
			headerCheckboxSelection: true,
			checkboxSelection: true,
		},
		{
			field: "useYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10050_col_use_yn"
					)[0].lanName + " (" + props.count.useCount + " / " + props.count.totlaCount + ")"
					: "", // 사용 유무
			sort: "desc",
		},
		{
			field: "createDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10050_col_create_date"
					)[0].lanName
					: "", // 계정 등록일
		},
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10050_col_update_date"
					)[0].lanName
					: "", // 수정일
		},
	];
	const [checkingToggle, setCheckingToggle] = React.useState(false);
	const [checkingToggle1, setCheckingToggle1] = React.useState(false);
	const [confirmToggle, setConfirmToggle] = React.useState({
		open: false,
		title: "",
		message: "",
		type: "",
	});

	const TabChanged = (event, newValue) => {
		props.setTabToggle(newValue);
	};

	return (
		<>
			{/* <Tabs value={props.tabToggle} onChange={TabChanged}>
				<Tab
					value={0}
					label={
						props.lanList.length > 0
							? props.lanList.filter(
									(f) => f.lanId === "bm10050_tab_mac_managed"
							  )[0].lanName
							: "" // Mac 주소 관리
					}
				/>
				<Tab
					value={1}
					label={
						props.lanList.length > 0
							? props.lanList.filter(
									(f) => f.lanId === "bm10050_tab_mac_current"
							  )[0].lanName
							: "" // Mac 현황
					}
				/>
			</Tabs> */}
			<Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
				{/* {props.tabToggle === 0 && (
					<> */}
				<Grid xs={12} md={12}>
					<Card
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<AuthGroupButton
							pageName="BM10050"
							target="G01"
							addFunc={() => {
								setCheckingToggle(true);
							}}
							saveFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10050_pop_save_title"
											)[0].lanName
											: "", // 저장
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10050_pop_save_message"
											)[0].lanName
											: "", // 저장하시겠습니까?
									type: "create",
								});
							}}
							deleteFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10050_pop_delete_title"
											)[0].lanName
											: "", // 삭제
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10050_pop_delete_message"
											)[0].lanName
											: "", // 삭제하시겠습니까?
									type: "delete",
								});
							}}
							excelFunc={() => {
								setCheckingToggle1(true);
							}}
						/>
					</Card>
				</Grid>
				<Grid xs={12} md={12}>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animationRows={true}
								columnDefs={columnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 250,
									editable: false,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="multiple"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() =>
									GridFunctions.onSelectionChanged(
										gridRef,
										props.setSelectedRowData
									)
								}
								onCellValueChanged={(e) => {
									GridFunctions.onCellValueChanged(
										e,
										gridRef,
										props.setChangedRowData
									);
								}}
								getRowStyle={(e) => {
									if (
										e.data.useYn === "미사용" &&
										e.data.newYn !== "Y"
									) {
										return { color: "#5f5f5f" };
									} else {
										return GridFunctions.getRowStyle(e);
									}
								}}
							/>
						</div>
					</Card>
				</Grid>
				{/* </>
				)} */}
			</Grid>
			<Dialog
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10050_pop_key_check"
						)[0].lanName
						: "" // 키 체크
				}
				open={checkingToggle}
				onClose={() => {
					props.setCheckings({
						...props.checkings,
						macAdd: "",
					});
					setCheckingToggle(false);
				}}
				bodySx={{ display: "flex", flexDirection: "column" }}
				fullWidth
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10050_pop_check"
						)[0].lanName
						: "" // 체크
				}
				useSaveAfterClose={false}
				onSave={() => {
					props.CheckingLogic(gridRef);
					setCheckingToggle(false);
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10050_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				<Input
					sx={{ my: 1 }}
					type="outlined"
					value={props.checkings.macAdd}
					onChange={(e) => {
						if (e.length === 12) {
							let temp = e.replace(/\B(?=(\w{2})+(?!\w))/g, ':');
							// console.log("temp : ", temp);

							props.setCheckings({
								...props.checkings,
								macAdd: temp,
							});
						} else {
							props.setCheckings({
								...props.checkings,
								macAdd: e,
							});
						}
					}}
					name={
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId === "bm10050_pop_place_holder"
							)[0].lanName
							: "" // Mac 주소
					}
				/>
			</Dialog>
			<Dialog
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10050_pop_excel_upload"
						)[0].lanName
						: "" // 엑셀 업로드
				}
				open={checkingToggle1}
				onClose={() => {
					setCheckingToggle1(false);
					props.setInputs({
						files: "",
						fileName: "",
					});
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10050_pop_upload"
						)[0].lanName
						: "" // 업로드
				}
				onSave={() => {
					props.ExcelUpload();
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10050_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				<FileForm
					datas={[
						{
							type: "excel",
							name: "", //파일
							value: props.inputs.fileName,
							onChange: (e) => {
								var file = e.target.files[0];
								if (e.target.files.length === 0) {
									return;
								} else {
									let reader = new FileReader();
									reader.readAsDataURL(file);
									reader.onload = (f) => {
										if (reader) {
											props.setInputs({
												...props.inputs,
												files: file,
												fileName: file.name,
											});
										}
									};
								}
							},
							buttonName:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10050_pop_file_upload"
									)[0].lanName
									: "", // 파일 업로드
						},
						{
							name:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10050_pop_file_nm"
									)[0].lanName
									: "", // 파일명
							value: props.inputs.fileName,
							disabled: true,
						},
					]}
				/>
			</Dialog>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10050_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onSave={() => {
					if (confirmToggle.type === "create") {
						props.CreateLogic();
					} else if (confirmToggle.type === "delete") {
						props.DeleteLogic();
					}
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10050_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				{confirmToggle.message}
			</Dialog>
		</>
	);
}

export default BM10050;
