import React from "react";
/** Components Import */
import { FormControlLabel, Checkbox } from "@mui/material";

function BasicCheckbox({
	sx = {}, // mui inline-style object
	color = "secondary", // "first", "secondary", "success", "info", "warning", "error"
	size = "medium", // "small", "medium"
	value = false, // state variant
	onChange = (e) => {}, // checkbox click event function
	name = "Checkbox",
	required = false, // true, false
	disabled = false, // true, false
}) {
	const CheckboxClicked = (e) => {
		onChange(e.target.checked);
	};

	return (
		<FormControlLabel
			sx={sx}
			control={
				<Checkbox
					color={color}
					size={size}
					checked={value}
					onChange={CheckboxClicked}
				/>
			}
			label={name}
			required={required}
			disabled={disabled}
		/>
	);
}

export default BasicCheckbox;
