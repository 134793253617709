import React from "react";
/** Components Import */
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import { Button } from "@mui/material";

/** 
 * 스낵바 대체용 다이얼로그
 * 오직 알림 내용과 닫기만 가능하도록 수정
 */
function SnackDialog({
	open = false, // dialog open value.           "true" or "false"
	useTitle = false,	// 타이틀 공간 사용 여부
	title = "", // dialog Title
	children, // dialog children, sub-content in Dialog
	disableEscapeKeyDown = false, // disable onClose callback.    "true" or "false"
	fullScreen = false, // using fullscreen dialog.     "true" or "false"
	fullWidth = false, // using fullWidth dialog.      "true" or "false"
	maxWidth = "sm", // determine maxium dialog width.       "xs" or "sm" or "md" or "lg" or "xl" or false or string
	style = {}, // mui style
	bodyStyle = {},	// bodyStyle
	sx = {}, // mui inline-style object
	onClose = () => { }, // dialog Close ACtion
	scroll = "paper", // dialog scrool
	closeButtonName = "닫기", // close button name
	titleSx = {}, // mui inline-style object for title
	bodySx = {}, // mui inline-style object for body
	dividers = false, // draw divide line in title and body.     "true" or "false"
	actionSx = {}, // mui inline-style object for action
	disableSpacing = false, // delete margin between actions.   "true" or "false"
}) {
	const CloseButton = () => {
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={CloseButton}
			style={style}
			sx={sx}
			scroll={scroll}
			disableEscapeKeyDown={disableEscapeKeyDown}
			fullScreen={fullScreen}
			fullWidth={fullWidth}
			maxWidth={maxWidth}
		>
			<div
				style={bodyStyle}
			>
				<DialogTitle sx={titleSx}>{title}</DialogTitle>
				<DialogContent sx={bodySx} dividers={dividers}>
					{children}
				</DialogContent>
				<DialogActions sx={actionSx} disableSpacing={disableSpacing}>
					<Button onClick={CloseButton}>{closeButtonName}</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
}

export default SnackDialog;
