import React from "react";
/** Components Import */
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

function GroupCheckbox({
	sx = {}, // mui inline-style object
	row = true, // true, false
	items = [], // checkbox element object array ex) [{ name: "Button1", value={}, onChange: (e) => {} }, {...} ]
}) {
	return (
		<FormGroup sx={sx} row={row}>
			{items.map((item, index) => {
				return (
					<FormControlLabel
						key={index}
						sx={item.sx}
						control={
							<Checkbox
								color={item.color ? item.color : "secondary"}
								size={item.size}
								checked={item.value}
								onChange={item.onChange}
							/>
						}
						label={item.name}
						required={item.required}
						disabled={item.disabled}
					/>
				);
			})}
		</FormGroup>
	);
}

export default GroupCheckbox;
