import React from "react";
/** Compornts Import */
import { TextField } from "@mui/material";

function BasicInput({
	type = "standard", // "filled", "outlined", "standard"
	sx = {}, // mui inline-style object
	color = "secondary", // "first", "secondary", "success", "info", "warning", "error"
	size = "medium", // "small", "medium"
	name = "", // input label
	value = "", // input value
	onChange = (e) => {}, // input value changed event function
	placeholder = "", // input placeholder
	helperText = "", // input helperText
	inputMode = "text", // select input type like 'text', 'number', 'hidden', 'tel', 'time' else
	inputMode1,
	pattern = "",
	startIcon = null, // input start icon component
	endIcon = null, // input end icon component
	autoFocus = false, // true, false
	fullWidth = false, // true, false
	multiline = false, // true, false
	required = false, // true, false
	disabled = false, // true, false
	InputProps = {},
	inputProps = {}, // when input mode is 'number', then you can use 'step', 'min', 'max', etc...
}) {
	const InputChanged = (e) => {
		onChange(e.target.value);
	};

	return (
		<TextField
			variant={type}
			sx={sx}
			color={color}
			size={size}
			label={name}
			value={value}
			onChange={InputChanged}
			placeholder={placeholder}
			helperText={helperText}
			type={inputMode}
			inputMode={inputMode1}
			pattern={pattern}
			InputProps={{
				...InputProps,
				startAdornment: startIcon,
				endAdornment: endIcon,
			}}
			inputProps={{
				...inputProps,
			}}
			autoFocus={autoFocus}
			fullWidth={fullWidth}
			multiline={multiline}
			required={required}
			disabled={disabled}
		/>
	);
}

export default BasicInput;
