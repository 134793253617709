import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardContent as MuiCardContent,
	Typography,
} from "@mui/material";
/** Icons Import */
/** Images Import */
import CompOffIcon from "../../Assets/Images/Design/drawable-hdpi/ic01_off.png";
import CompOnIcon from "../../Assets/Images/Design/drawable-hdpi/ic01_on.png";
import DefrostOffIcon from "../../Assets/Images/Design/drawable-hdpi/ic02_off.png";
import DefrostOnIcon from "../../Assets/Images/Design/drawable-hdpi/ic02_on.png";
import FanOffIcon from "../../Assets/Images/Design/drawable-hdpi/ic03_off.png";
import FanOnIcon from "../../Assets/Images/Design/drawable-hdpi/ic03_on.png";
import LowDefenseOffIcon from "../../Assets/Images/Design/drawable-hdpi/ic04_off.png";
import LowDefenseOnIcon from "../../Assets/Images/Design/drawable-hdpi/ic04_on.png";

const CardContents = styled((props) => <MuiCardContent {...props} />)(
	({ theme }) => ({
		margin: "0px",
		padding: "0px",
	})
);
const CardContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
}));
const CardContentItem = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	// height: "90px",
}));
const CardContentItemHeadTitle = styled((props) => <Typography {...props} />)(
	({ theme }) => ({
		width: "100%",
		textAlign: "center",
		padding: "0px",
	})
);

function DashboardList_web({
	items = [], // Controller wide list's item list
}) {
	let tempArr = [];
	let midArr = [];
	let getItems = [];

	items.forEach((item, index) => {
		if (item.comp) {
			tempArr.push({
				name: "comp",
				nameHan: "콤프",
				value: item.comp,
			});
		}
		if (item.defrost) {
			tempArr.push({
				name: "defrost",
				nameHan: "제상",
				value: item.defrost,
			});
		}
		if (item.fan) {
			tempArr.push({
				name: "fan",
				nameHan: "팬",
				value: item.fan,
			});
		}
		if (item.lowDefense) {
			tempArr.push({
				name: "lowDefense",
				nameHan: "저온방지",
				value: item.lowDefense,
			});
		}

		if (tempArr.length > 0) {
			tempArr.forEach((item1, index1) => {
				if ((index1 + 1) % 2 === 0 || index1 + 1 === tempArr.length) {
					midArr.push(item1);
					getItems.push(midArr);
					midArr = [];
				} else {
					midArr.push(item1);
				}
			});
		}
	});

	const ReturnColor = (item) => {
		switch (item) {
			case "0":
				return "#388DFC";
			case "ERROR":
				return "#f44336";
			default:
				return "#ABB2C4";
		}
	};

	const ReturnIcons = (type, status) => {
		switch (type) {
			case "comp":
				if (status === "0") {
					return (
						<div
							className={
								Commons.IsMobile()
									? "gradient-box in"
									: "gradient-box-web in"
							}
						>
							<img
								style={{ width: "80px", height: "80px" }}
								src={CompOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<img
							style={{ width: "80px", height: "80px" }}
							src={CompOffIcon}
						/>
					);
				}
			case "defrost":
				if (status === "0") {
					return (
						<div
							className={
								Commons.IsMobile()
									? "gradient-box in"
									: "gradient-box-web in"
							}
						>
							<img
								style={{ width: "80px", height: "80px" }}
								src={DefrostOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<img
							style={{ width: "80px", height: "80px" }}
							src={DefrostOffIcon}
						/>
					);
				}
			case "fan":
				if (status === "0") {
					return (
						<div
							className={
								Commons.IsMobile()
									? "gradient-box in"
									: "gradient-box-web in"
							}
						>
							<img
								style={{ width: "80px", height: "80px" }}
								src={FanOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<img
							style={{ width: "80px", height: "80px" }}
							src={FanOffIcon}
						/>
					);
				}
			case "lowDefense":
				if (status === "0") {
					return (
						<div
							className={
								Commons.IsMobile()
									? "gradient-box in"
									: "gradient-box-web in"
							}
						>
							<img
								style={{ width: "80px", height: "80px" }}
								src={LowDefenseOnIcon}
							/>
						</div>
					);
				} else {
					return (
						<img
							style={{ width: "80px", height: "80px" }}
							src={LowDefenseOffIcon}
						/>
					);
				}
		}
	};

	return (
		<Grid xs={4} sm={8} md={3}>
			{items.length !== 0 ? (
				<Card
					sx={{
						height: "100%",
						display: "flex",
						justifyContent: "space-around",
						alignItems: "center",
					}}
				>
					{getItems.map((item, index) => {
						return (
							<CardContents
								key={index}
								style={{ padding: "8px 0px" }}
							>
								<CardContentItems>
									{item.length > 0
										? item.map((item1, index1) => {
												return (
													<CardContentItem
														key={index1}
													>
														{ReturnIcons(
															item1.name,
															item1.value
														)}
														<CardContentItemHeadTitle
															sx={{
																color: ReturnColor(
																	item1.value
																),
															}}
														>
															{item1.nameHan}
														</CardContentItemHeadTitle>
													</CardContentItem>
												);
										  })
										: null}
								</CardContentItems>
							</CardContents>
						);
					})}
				</Card>
			) : null}
		</Grid>
	);
}

export default DashboardList_web;
