import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components */
import {
	Typography,
	Checkbox,
} from "@mui/material";
import HomeView from "./../../Views/Home/Home";
import AddController from "./../../Views/Home/AddController";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
import { Slick } from "../../Components/Slick";
import { Accordion } from "../../Components/Conotec";
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
import {
	ArrowDropDown,
} from "@mui/icons-material";

// import { Unstable_Grid2 as Grid, Card, Tabs, Tab } from "@mui/material";
// import {
// 	Accordion,
// 	AccordionSummary,
// 	Typography,
// 	AccordionDetails,
// } from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const InfoSubtitle = styled((props) => (
	<Typography
		variant={Commons.IsMobile() ? "caption" : "subtitle1"}
		{...props}
	/>
))(({ theme }) => ({
	color: "#8D8D8D",
}));

const InfoBody = styled((props) => (
	<Typography variant={Commons.IsMobile() ? "body2" : "body1"} {...props} />
))(({ theme }) => ({
	color: "#ffffff",
	marginBottom: "10px",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}));

function Home() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});

	const [machNamesList, setMachNames] = React.useState([]);

	const [listData, setListData] = React.useState([]);
	const [newAd, setNewAd] = React.useState(false); // 광고창 팝업
	const [datePopup, setDatePopup] = React.useState(false); // 유효기간 알림 팝업
	const [privacyPopup, setPrivacyPopup] = React.useState(false); // 개인정보동의 팝업
	const [term, setTerm] = React.useState({
		userTerm: false,	// 개인정보 처리방침 동의
		siteTerm: false,	// 사이트 이용 약관 동의
	});
	const [privacyClose, setPrivacyClose] = React.useState(false); // 개인정보동의 팝업 닫기시 확인
	const [adList, setAdList] = React.useState([]);
	const intervalTime = Commons.Storages("get", "settings").reloadTime;
	const [determinateLabel, setDeterminateLabel] = React.useState(
		Commons.Storages("get", "settings").reloadTime / 1000
	);
	const [mesureUnits, setMesureUnits] = React.useState([]);
	const userLan = Commons.Storages("get", "userInfo").lanCode;
	const [lanList, setLanList] = React.useState([]); // language list
	const [codeList, setCodeList] = React.useState([]); // language list
	const reloadCount = React.useRef(0); // 일정 시간 후 새로고침을 하기 위한 변수

	const [rowData3, setRowData3] = React.useState([]); // 맥 주소 원본 데이터
	const [showRowData3, setShowRowData3] = React.useState([]); // 화면에 보여줄 맥 주소 데이터

	const [snackDialog, setSnackDialog] = React.useState({
		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});

	const [confirmToggle, setConfirmToggle] = React.useState({
		// 확인 창
		open: false,
		title: "",
		message: "",
		type: "",
	});
	const [noDevice, setNoDevice] = React.useState(false); // 장치 없을 시 플래그

	// AddController에 보낼 변수들
	const [controllerList, setControllerList] = React.useState([]); // ItemMst 변수 (장치들 리스트)
	const [joinRtNoList, setJoinRtNoList] = React.useState([]); // joinRtNo 변수 (중계기 리스트)
	const [noShowList, setNoShowList] = React.useState([]);	// 화면표시유무 N인 장치들 리스트
	const [addController, setAddController] = React.useState({
		open: false,
		disabled: false, // 맥 주소, 장치 설정, 연결 중계기 수정, 다이얼로그 삭제 가능 여부
		disabledNoShow: false,	// 화면표시 미사용 콤보박스 사용 X
		itemIdx: "", // 장치 설정
		itemNm: "",	// 선택한 장치의 이름. 중계기에 붙으면 안되는 장치 구분용.
		itemNo: "", // 장치 번호
		machNm: "", // 장치명
		macAdd: "", // 맥 주소
		useMac: false, // 맥 주소 사용 가능 여부 (false면 사용 불가능. true면 사용 가능)
		machId: "", // 국번
		useMachId: false,	// 국번 수정 가능 여부 (false면 수정 불가능, true면 가능)
		joinRtNo: "", // 연결 중계기 번호
		screenYn: "Y", // 화면 표시 유무
		address1: "", // 주소
	});

	// 개인정보, 사이트 이용 동의 아코디언 조절
	const [accExpanded, setAccExpanded] = React.useState(null);

	const ChangeExpanded = (panel) => (event, newExpanded) => {
		setAccExpanded(newExpanded ? panel : false);
	};

	React.useEffect(() => {
		Init();
		GetDtlList2();

		const LabelProgress = setInterval(() => {
			setDeterminateLabel((prevProgress) =>
				prevProgress <= 0 ? intervalTime / 1000 : prevProgress - 1
			);
			reloadCount.current = reloadCount.current + 1;
		}, 1000);

		return () => {
			clearInterval(LabelProgress);
		};
	}, []);

	React.useEffect(() => {
		if (reloadCount.current >= 900) {
			window.location.reload(true);
		} else if (determinateLabel === 0) {
			Init(false);
			setDeterminateLabel(
				Commons.Storages("get", "settings").reloadTime / 1000
			);
		}
	}, [determinateLabel]);

	// React.useMemo(() => {
	// 	IndexedDb.GetCodeAll().then((result) => {
	// 		setCodeList(result);
	// 	});
	// }, []);

	// 컨트롤러 데이터 조회
	const Init = async (_type = true) => {
		if (_type) {
			setProgress(true);
		}
		let userInfo = null;
		let codeList = [];
		let errorMsgCodes = [];
		let mesureUnitList = [];
		let mesureSubUnitList = [];
		let temp = [];
		let lans = [];
		let tempRtArr = []; // 장치 추가시 연결 중계기 리스트
		let tempNoShowArr = [];

		let userPrivacy = await Commons.Storages("get", "userInfo").privacyYn;
		// let limitDate = await Commons.Storages("get", "userInfo").useEndDate;

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then(async (result) => {
				if (result.length > 0) {
					// 언어팩 길이 비교 후 업데이트
					await Commons.Axios(
						"get",
						Global.base.useUrl + Global.urls.Home.GetLanguage,
						{
							lanCd: Commons.Storages("get", "userInfo").lanCode,
						}
					).then(async (result1) => {
						let data = result1.data;

						if (data.type !== "Error") {
							if (data.length != result.length) {
								await IndexedDb.ClearDb("LanguageList");
								await IndexedDb.AddDb("LanguageList", data);
							}
						}
					});

					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			// 기초코드관리
			await IndexedDb.GetAllDb("CodeList").then(async (result) => {
				if (result.length > 0) {
					// 기초코드관리 길이 비교 후 업데이트
					await Commons.Axios(
						"get",
						Global.base.useUrl + Global.urls.Home.GetCode
					).then(async (result1) => {
						let data = result1.data;

						if (data.type !== "Error") {
							if (data.length != result.length) {
								await IndexedDb.ClearDb("CodeList");
								await IndexedDb.AddDb("CodeList", data);
							}
						}
					});
					setCodeList(result);
				}
			});

			if (userPrivacy === "N") {
				setPrivacyPopup(true);
			}

			// 날짜가 변경된 경우 다시 광고창 열기
			if (
				new Date(
					Commons.Storages("get", "advertisements").updateDate
				).getFullYear() < new Date().getFullYear() ||
				new Date(
					Commons.Storages("get", "advertisements").updateDate
				).getMonth() < new Date().getMonth() ||
				new Date(
					Commons.Storages("get", "advertisements").updateDate
				).getDate() < new Date().getDate()
			) {
				await Commons.Storages("set", "advertisements", {
					...Commons.Storages("get", "advertisements"),
					open: true,
					updateDate: Commons.DateFormating(new Date()),
				});
				setNewAd(true);
			}

			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await IndexedDb.GetCodeAll().then((result) => {
				codeList = result;
				errorMsgCodes = result.filter(
					(item, index) => item.groupCd === "SENSSOR_ERROR"
				);
				mesureUnitList = result.filter(
					(item, index) => item.groupCd === "MESURE_TYPE"
				);
			});

			// 사용자 계정 사용유무 및 결제정보 확인
			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Home.CheckUserAuth,
				{
					userId: userInfo.userId,
				}
			).then(async (result) => {
				let authData = result.data;

				if (authData.useYn === "N") {
					// 사용자가 미사용 처리됨 => 로그아웃
					setDialog({
						...dialog,
						open: true,
						title: "Alert",
						children: (
							<>
								<p>
									{lans.length > 0
										? lans.filter(
											(f) =>
												f.lanId ===
												"snack_information_expired"
										)[0].lanName
										: ""	// 로그인 정보가 만료되었습니다.
									}
								</p>
								<p>
									{lans.length > 0
										? lans.filter(
											(f) =>
												f.lanId ===
												"snack_login_again"
										)[0].lanName
										: ""	// 다시 로그인 해 주세요.
									}
								</p>
							</>
						),
					});
				} else {
					// 사용자 종료일 및 결제유무 업데이트
					Commons.Storages("set", "userInfo", {
						...Commons.Storages("get", "userInfo"),
						amountYn: authData.amount,
						useEndDate: authData.edate.slice(0, 4) + "-" + authData.edate.slice(4, 6) + "-" + authData.edate.slice(6, 8)
					});

					await IndexedDb.UpdateDb(
						"LoginInfo",
						userInfo.token,
						{
							...userInfo,
							useEdate: authData.edate,
							amountYn: authData.amount,
						}
					);

					if (authData.amount === "Y") {
						// 결제되어 있는데 유효기간 7일 이하일 경우 팝업 알림창 열기
						if (
							Commons.DateDiff(
								new Date(
									authData.edate.slice(0, 4) + "-" +
									authData.edate.slice(4, 6) + "-" +
									authData.edate.slice(6, 8)
								),
								new Date(),
								"D"
							) <= 7
						) {
							// console.log(
							// 	"남은 유효기간: ",
							// 	Commons.DateDiff(new Date(limitDate), new Date(), "D")
							// );
							// if (
							// 	new Date(
							// 		Commons.Storages("get", "approvalAlerted").updateDate
							// 	).getFullYear() < new Date().getFullYear() ||
							// 	new Date(
							// 		Commons.Storages("get", "approvalAlerted").updateDate
							// 	).getMonth() < new Date().getMonth() ||
							// 	new Date(
							// 		Commons.Storages("get", "approvalAlerted").updateDate
							// 	).getDate() < new Date().getDate()
							// ) {
							// 	// Commons.Storages("set", "approvalAlerted", {
							// 	// 	...Commons.Storages("get", "approvalAlerted"),
							// 	// 	open: true,
							// 	// 	updateDate: Commons.DateFormating(new Date()),
							// 	// });
							// 	_type && setDatePopup(true);
							// }
							_type && Commons.Storages("get", "approvalAlerted").open && setDatePopup(true);
						}
					}

					// 컨트롤러 데이터 조회
					await Commons.Axios(
						"get",
						Global.base.useUrl + Global.urls.Home.Home,
						{
							userId: userInfo.userId,
							type: Commons.Storages("get", "settings").server,
						}
					).then((result) => {
						let data = result.data;
						// console.log("Init Home:", data);

						if (
							data.message !== undefined &&
							data.message ===
							"Index was outside the bounds of the array."
						) {
							// setSnacks({
							// 	...snacks,
							// 	open: true,
							// 	type: "error",
							// 	message:
							// 		lans.length > 0
							// 			? lans.filter(
							// 				(f) =>
							// 					f.lanId ===
							// 					"snack_no_conntected_devices"
							// 			)[0].lanName
							// 			: "",	// 연결된 장치가 없습니다.
							// });
							throw new Error(
								lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_no_conntected_devices"
									)[0].lanName
									: "" // 연결된 장치가 없습니다.
							);
						} else if (data.type !== undefined && data.type === "Error") {
							throw new Error(data.message);
						} else if (data === "데이터가 없습니다.") {
							setListData([]);
							// setSnacks({
							// 	...snacks,
							// 	open: true,
							// 	type: "error",
							// 	message:
							// 		lans.length > 0
							// 			? lans.filter(
							// 				(f) =>
							// 					f.lanId === "snack_unavailable_data"
							// 			)[0].lanName
							// 			: "",
							// });
							throw new Error(
								lans.length > 0
									? lans.filter(
										(f) => f.lanId === "snack_unavailable_data"
									)[0].lanName
									: "" // 데이터가 없습니다.
							);
						} else if (data === "등록된 장치가 없습니다.") {
							// setSnacks({
							// 	...snacks,
							// 	open: true,
							// 	type: "error",
							// 	// 등록된 장치가 없습니다.
							// 	message:
							// 		lans.length > 0
							// 			? lans.filter(
							// 				(f) =>
							// 					f.lanId ===
							// 					"snack_no_device_registered"
							// 			)[0].lanName
							// 			: "",
							// });
							setNoDevice(true);
						} else if (data === false) {
							throw new Error(data);
						} else {
							if (data.machNames.length > 0) {
								setMachNames(data.machNames);

								codeList.forEach((item, index) => {
									mesureUnitList.forEach((item1, index1) => {
										if (item.groupCd === item1.code) {
											mesureSubUnitList.push({ ...item });
										}
									});
								});
								// 홈 화면에 뿌려줄 컨트롤러 데이터 제작
								data.machNames
									// .filter(
									// 	(f) =>
									// 		// f.itemType !== "7" &&
									// 		f.screenYn === "Y"
									// )
									.map((item, index) => {
										let pushData = {
											...item,
											// amountYn: userInfo.amountYn,
											amountYn: authData.amount,
										};

										if (data.settingValues) {
											data.settingValues.forEach(
												(item1, index1) => {
													if (item1.itemNo === item.itemNo) {
														pushData = {
															...pushData,
															setting_temp: item1.temp,
															setting_damp: item1.damp,
															setting_illum: item1.illum,
															setting_co2: item1.co2,
														};
													}
												}
											);
										}
										if (data.currentValues) {
											data.currentValues.forEach(
												(item1, index1) => {
													if (item1.itemNo === item.itemNo) {
														pushData = {
															...pushData,
															current_temp: item1.temp,
															current_damp: item1.damp,
															current_illum: item1.illum,
															current_co2: item1.co2,
														};
													}
												}
											);
										}
										if (data.operations) {
											data.operations.forEach((item1, index1) => {
												if (item1.itemNo === item.itemNo) {
													pushData = {
														...pushData,
														operation: item1.operation,
													};
												}
											});
										}
										if (data.tempUnits) {
											data.tempUnits.forEach((item1, index1) => {
												if (item1.itemNo === item.itemNo) {
													pushData = {
														...pushData,
														tempUnit: item1.tempUnit
															? item1.tempUnit
															: "0",
													};
												}
											});
										}
										if (data.alarms) {
											data.alarms.forEach((item1, index1) => {
												if (item1.itemNo === item.itemNo) {
													pushData = {
														...pushData,
														errorYn: "Y",
														alarmMsg: item1.alarmMsg,
													};
												}
											});
										}
										if (data.strengths) {
											data.strengths.forEach((item1, index1) => {
												if (item1.itemNo === item.itemNo) {
													pushData = {
														...pushData,
														strength: item1.strength
													};
												}
											});
										}
										temp.push(pushData);
										// console.log("pushData : ", pushData);

									});

								// 장치 추가시 사용할 중계기 리스트
								data.machNames
									.filter(
										(f) => f.itemType === "7" && f.useYn === "Y"
									)
									.forEach((f) => {
										tempRtArr.push({
											name: f.machNm,
											value: f.itemNo,
										});
									});

								// 화면표시유무 N 처리된 장치 리스트
								data.machNames
									.filter(
										(f) => f.screenYn === "N"
									)
									.forEach((f) => {
										tempNoShowArr.push({
											name: f.machNm,
											value: f.itemNo,
										});
									});
							}

							// console.log(
							// 	"Init result data: ",
							// 	temp.sort((a, b) => a.itemOrder - b.itemOrder)
							// );

							// console.log("listDAta : ", temp);

							// console.log("data : ", data);
							// console.log("temp : ", temp);
							// console.log("tempNoShowArr : ", tempNoShowArr);

							setListData(temp.sort((a, b) => a.itemOrder - b.itemOrder));
							setMesureUnits(mesureSubUnitList);
							setJoinRtNoList(tempRtArr);
							setNoShowList(tempNoShowArr);
							// return GetSettingData(tempListData);
							setNoDevice(false);
						}
						_type && AdDownload(lans);
					});
				}
			});

		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter((f) => f.lanId === "snack_error_title")[0]
							.lanName
						: "", // 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 유저 약관 동의 저장
	const UpdateUserPrivacy = async (privacy) => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10010.UpdateUserPrivacy,
				{
					userId: userInfo.userId,
					privacyYn: privacy,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "Ok") {
					// setSnacks({
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_saved"
					// 			)[0].lanName
					// 			: "", // 저장 되었습니다!
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_success_title"
								)[0].lanName
								: "", // 성공
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_saved"
								)[0].lanName
								: "", // 저장 되었습니다!
					});
				} else {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_user_registration_failed"
							)[0].lanName
							: "" // 사용자 등록 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_error_title"
						)[0].lanName
						: "", // 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 광고 파일 다운로드
	const AdDownload = async (lans = lanList) => {
		setProgress(true);
		let tempArr = [];

		try {
			await Commons.Axios(
				"post",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10060.AdDownload
			).then((result) => {
				let data = result.data;

				if (data === false) {
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId === "home_pop_image_load_title"
							)[0].lanName
							: "" // 이미지 로딩 실패!
					);
				} else if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data.length === 0) {
					// console.log("조회된 광고 이미지가 없습니다.");
					return false;
				} else if (data) {
					data.forEach((item) => {
						tempArr.push("data:image/png;base64," + item);
					});
					setAdList(tempArr);
					setNewAd(Commons.Storages("get", "advertisements").open);
				} else {
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId === "home_pop_image_load_title"
							)[0].lanName
							: "" // 이미지 로딩 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_error_title"
						)[0].lanName
						: "", // 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 광고창 팝업 닫기.
	const NewAdd = async (value) => {
		Commons.Storages("set", "advertisements", {
			open: value,
			updateDate: Commons.DateFormating(new Date()),
		});
		setNewAd(false);
	};

	// 개인정보동의 팝업 닫기.
	const ClosePrivacy = async (value) => {
		if (value) {
			// 개인정보 동의
			await Commons.Storages("set", "userInfo", {
				...Commons.Storages("get", "userInfo"),
				privacyYn: "Y",
			});
			await UpdateUserPrivacy("Y"); // 개인정보동의 저장
			setPrivacyPopup(false);
		} else {
			// 개인정보 동의 X => 로그인 화면으로
			setPrivacyClose(true);
		}
	};

	// 유효기간 팝업 닫기.
	const LimitDate = async (value) => {
		// await Commons.Storages("set", "advertisements", {
		// 	...Commons.Storages("get", "advertisements"),
		// 	limitDate: value,
		// 	updateDate: Commons.DateFormating(new Date()),
		// });
		if (value === "close") {
			Commons.Storages("set", "approvalAlerted", {
				...Commons.Storages("get", "approvalAlerted"),
				open: false,
				updateDate: Commons.DateFormating(new Date()),
			});
		}
		setDatePopup(false);
	};

	// 컨트롤러 상태 재조회
	const ReloadPage = () => {
		Init();
		// 재조회 버튼 클릭 시 시간 초기화
		setDeterminateLabel(
			Commons.Storages("get", "settings").reloadTime / 1000
		);
	};

	// 순서변경 화면 이동
	const MovePage = (_page) => {
		if (_page === "Swapping" && listData.length < 1) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		lanList.length > 0
			// 			? lanList.filter(
			// 				(f) => f.lanId === "snack_no_device_registered"
			// 			)[0].lanName
			// 			: "", // 등록된 장치가 없습니다.,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_error_title"
						)[0].lanName
						: "", // 실패!
				message:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_no_device_registered"
						)[0].lanName
						: "", // 등록된 장치가 없습니다.,
			});
			return false;
		}
		navigate("/" + _page);
	};

	// 장비설정용 컨트롤러 리스트 조회
	const GetDtlList2 = async (_type = false) => {
		setProgress(true);
		let temp = [];
		let code = [];

		try {
			// 기초코드 조회
			IndexedDb.GetCodeAll().then((result) => {
				if (result.length > 0) {
					result.forEach((item) => code.push(item));
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.ControllerManagement.CM10000.GetItemList
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					setControllerList([]);
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setControllerList([]);
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: ""
					);
				} else if (data) {
					// data.forEach((item) => {
					// 	temp.push({
					// 		...item,
					// 		conForm:
					// 			item.conForm === "" || item.conForm === null
					// 				? ""
					// 				: code.filter(
					// 					(_item) =>
					// 						_item.groupCd === "CON_FORM" &&
					// 						_item.code === item.conForm
					// 				)[0].name,
					// 		conType:
					// 			item.conType === "" || item.conType === null
					// 				? ""
					// 				: code.filter(
					// 					(_item) =>
					// 						_item.groupCd === "CON_TYPE" &&
					// 						_item.code === item.conType
					// 				)[0].name,
					// 	});
					// });

					// 장치 선택 콤보에 사용하는 아이템 리스트
					data.forEach((f) => {
						temp.push({
							value: f.itemIdx,
							name: f.itemNm,
						});
					});

					// setRowData2(temp);
					setControllerList(temp);
					_type &&
						setAddController({
							...addController,
							open: true,
						});
				} else {
					setControllerList([]);
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "" // 조회 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_error_title"
						)[0].lanName
						: "", // 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 사용중인 맥 주소 조회
	const MacSearch = async () => {
		setProgress(true);
		let regex = /^([0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$/;

		try {
			if (addController.macAdd === "" || addController.macAdd === null) {
				// 맥 주소 없는데 확인하는 경우 에러
			} else if (!regex.test(addController.macAdd)) {
				// 맥 주소가 형식에 맞지 않으면 에러
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.ControllerManagement.CM10000.MacSearch,
					{
						Mac: addController.macAdd,
					}
				).then((result) => {
					let data = result.data;
					// console.log("data : ", data);

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "N") {
						setAddController({
							...addController,
							macAdd: "",
						});
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId === "snack_unavailable_mac"
								)[0].lanName
								: "" // 사용할 수 없는 맥 주소입니다.
						);
					} else if (data === "Y") {
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "", // 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_available_mac_address"
									)[0].lanName
									: "", // 사용 가능한 맥 주소입니다
						});
						setAddController({
							...addController,
							useMac: true,
						});
					}
				});
			}
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_error_title"
						)[0].lanName
						: "", // 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 장치 추가 저장
	const CreateLogic1 = async () => {
		setProgress(true);
		let regex = /^([0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$/;
		let userInfo = null;
		let tempArr = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});
			// console.log("addController : ", addController);
			if (
				addController.itemIdx === "" ||
				addController.itemIdx === null
			) {
				// 장치 설정 없음 => 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_select_controller"
						)[0].lanName
						: "" // 장비를 설정해주세요!
				);
			} else if (
				addController.machNm === "" ||
				addController.machNm === null
			) {
				// 장치 이름 없음 => 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_enter_item_nm"
						)[0].lanName
						: "" // 아이템 이름을 입력세요!
				);
			} else if (
				addController.joinRtNo === "" &&
				(addController.macAdd === "" || addController.macAdd === null)
			) {
				// 중계기 없는데 맥 주소 없음 => 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_enter_mac_address"
						)[0].lanName
						: "" // 맥주소를 입력하세요!
				);
			} else if (
				addController.joinRtNo === "" &&
				!regex.test(addController.macAdd)
			) {
				// 중계기 없는데 맥 주소 형식 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_check_mac_address"
						)[0].lanName
						: "" // 맥주소를 다시 확인하세요.
				);
			} else if (addController.joinRtNo === "" && !addController.useMac) {
				// 중계기 없는데 맥 주소 확인 안 함 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_check_mac_address"
						)[0].lanName
						: "" // 맥주소를 다시 확인하세요.
				);
			} else if (
				addController.joinRtNo !== "" &&
				(addController.machId === "" || addController.machId === null)
			) {
				// 중계기 있는데 국번 없음 => 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_select_mach_id"
						)[0].lanName
						: "" // 국번을 입력하세요!
				);
			} else {
				// 이상 없음
				if (
					addController.itemNo === "" ||
					addController.itemNo === null
				) {
					// 신규 생성
					tempArr.push({
						userId: userInfo.userId,
						itemIdx: addController.itemIdx,
						itemNo: 0,
						machNm: addController.machNm,
						macAdd: addController.macAdd,
						machId: addController.machId,
						address1: addController.address1,
						screenYn: addController.screenYn,
						joinRtNo:
							addController.joinRtNo !== "" &&
								addController.joinRtNo !== null
								? addController.joinRtNo
								: 0,
						updateId: userInfo.userId,
					});
				} else {
					// 기존 항목 변경
					// console.log("listData : ", listData.filter((f) => f.itemNo === addController.itemNo)[0]);
					// console.log("addController : ", addController);
					let _item = listData.filter(
						(f) => f.itemNo === addController.itemNo
					)[0];

					tempArr.push({
						userId: userInfo.userId,
						itemIdx: addController.itemIdx,
						itemNo: addController.itemNo,
						machNm: addController.machNm,
						macAdd: "",
						machId: addController.machId,
						address1: addController.address1,
						screenYn: addController.screenYn,
						joinRtNo: addController.joinRtNo !== "" &&
							addController.joinRtNo !== null ?
							addController.joinRtNo :
							0,
						updateId: userInfo.userId,
					});
				}

				// console.log("tempArr : ", tempArr);

				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.ControllerManagement.CM10000.SingleCreate,
					tempArr[0]
				).then((result) => {
					let data = result.data;
					// console.log("data : ", data);

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "이미 사용중인 맥 주소입니다.") {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId === "snack_cannot_use_mac"
								)[0].lanName
								: "" // 이미 사용중인 맥 주소입니다
						);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_saved"
						// 			)[0].lanName
						// 			: "", // 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_saved"
									)[0].lanName
									: "", // 저장 되었습니다!
						});
						Init();
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_save_failed"
								)[0].lanName
								: "" // 저장 실패!
						);
					}
				});
			}
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_error_title"
						)[0].lanName
						: "", // 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 장치 삭제
	const DeleteLogin1 = async () => {
		setProgress(true);
		let tempArr = [];
		let userInfo = null;
		let joinRtCheck = listData.filter((f) => f.joinRtNo + "" === addController.itemNo).length;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			if (
				addController.itemIdx === "" ||
				addController.itemIdx === null
			) {
				// 장치 설정(모델명)이 없는 컨트롤러 => 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_delete_failed"
						)[0].lanName
						: "" // 삭제 실패!
				);
			} else if (
				addController.itemNo === "" ||
				addController.itemNo === null
			) {
				// 장치번호가 없는 컨트롤러 => 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_delete_failed"
						)[0].lanName
						: "" // 삭제 실패!
				);
			} else if (
				joinRtCheck > 0
			) {
				// 하위 컨트롤러가 있는 컨트롤러 => 에러
				// 중계기든 아니든, 하위에 컨트롤러가 있으면 무조건 에러.
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_delete_failed"
						)[0].lanName
						: "" // 삭제 실패!
				);
			} else {
				// 정상 데이터 삭제
				tempArr.push({
					useYn: "N",
					userId: userInfo.userId,
					itemIdx: addController.itemIdx,
					itemNo: addController.itemNo,
					screenYn: addController.screenYn,
					updateId: userInfo.userId,
				});

				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.ControllerManagement.CM10000.Delete,
					tempArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_saved"
						// 			)[0].lanName
						// 			: "", // 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "", // 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_saved"
									)[0].lanName
									: "", // 저장 되었습니다!
						});
						Init();
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_unused_failed"
								)[0].lanName
								: "" // 미사용 처리 실패!
						);
					}
				});
			}
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_error_title"
						)[0].lanName
						: "", // 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 화면 미표시 장치 선택시 데이터 조회
	const SelectNoShowItem = async (_itemNo) => {
		let item = machNamesList.filter((f) => f.itemNo === _itemNo)[0];

		setAddController(
			{
				...addController,
				disabled: true, // 맥 주소, 장치 설정, 연결 중계기 수정, 다이얼로그 삭제 가능 여부
				itemIdx: item.itemIdx, // 장치 설정
				// itemNm: controllerList.filter((f) => f.value === item.itemIdx)[0].name,
				itemNo: _itemNo,
				machNm: item.machNm, // 장치명
				macAdd: item.macAdd, // 맥 주소
				machId: item.machId, // 국번
				useMachId: true,	// 국번 미사용
				joinRtNo: item.joinRtNo === null ? "" : item.joinRtNo, // 연결 중계기 번호
				screenYn: item.screenYn, // 화면 표시 유무
				address1: item.address1, // 주소open: false,
			}
		);
	};

	return (
		<div className="wrap">
			<HomeView
				// 언어팩
				lanList={lanList}
				listData={listData}
				setListData={setListData}
				mesureUnits={mesureUnits}
				noShowList={noShowList}
				Init={Init}
				determinateLabel={determinateLabel}
				ReloadPage={ReloadPage}
				MovePage={MovePage}
				addController={addController}
				setAddController={setAddController}
				GetDtlList2={GetDtlList2}
				noDevice={noDevice}
				snackDialog={snackDialog}
				setSnackDialog={setSnackDialog}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			{/* 개인정보동의 팝업 */}
			<Dialog
				fullWidth
				open={privacyPopup}
				// open={true}
				maxWidth={"md"}
				actionSx={{
					display: "flex",
					justifyContent: "space-between",
				}}
				title={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_pop_consent"
						)[0].lanName
						: "" // 코노텍 IoT 서비스 이용을 위한 개인정보 수집·이용 동의서
				}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_button_agree"
						)[0].lanName
						: "" // 동의
				}
				onClose={() => {
					ClosePrivacy(false);
				}}
				onSave={() => {
					if (!term.userTerm) {
						setConfirmToggle({
							// 확인 창
							open: true,
							title: (
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "home_pop_disagree"
									)[0].lanName
									: "" // 약관 미동의
							),
							message: (
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "home_pop_disagree_personal_info"
									)[0].lanName
									: "" // 개인정보 수집 및 이용에 관한 약관에 동의하지 않았습니다.
							),
							type: "error",
						});
					} else if (!term.siteTerm) {
						setConfirmToggle({
							// 확인 창
							open: true,
							title: (
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "home_pop_disagree"
									)[0].lanName
									: "" // 약관 미동의
							),
							message: (
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "home_pop_disagree_using_site"
									)[0].lanName
									: "" // 사이트 이용에 관한 약관에 동의하지 않았습니다.
							),
							type: "error",
						});
					} else {
						ClosePrivacy(true);
					}
				}}
				useSaveButton={true}
				useSaveAfterClose={false}
				children={
					<Accordion
						lanList={lanList}
						term={term}
						setTerm={setTerm}
					/>
				}
			/>
			{/* 개인정보동의 없이 닫기 팝업 */}
			<Dialog
				fullWidth
				open={privacyClose}
				title={""}
				children={
					<div>
						<div>
							{
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "home_pop_cannot_use_site"
									)[0].lanName
									: "" // 개인정보 동의 항목에 동의하지 않으면 이용하실 수 없습니다.
							}
						</div>
						<div>
							{
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "home_pop_return_login"
									)[0].lanName
									: "" // 로그인 화면으로 돌아갑니다
							}
						</div>
					</div>
				}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_gen_cancel"
						)[0].lanName
						: "" // 취소
				}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onClose={() => {
					setPrivacyClose(false);
				}}
				onSave={async () => {
					await Commons.Storages("set", "authorization", {
						authorization: false,
						authDate: "2024-04-21 19:49:06",
					});

					setTimeout(() => window.location.reload(), 300);
				}}
				useSaveButton={true}
				actionSx={{
					display: "flex",
					justifyContent: "space-between",
				}}
			/>
			{/* 유효기간 알림 팝업 */}
			<Dialog
				open={datePopup}
				title={""}
				children={
					<>
						<div>
							{
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "home_pop_expire_validity"
									)[0].lanName
									: "" // 결제 유효기간이 얼마 남지 않았습니다.
							}
						</div>
						<div>
							{
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "home_pop_make_renewal"
									)[0].lanName
									: "" // 계속해서 서비스를 이용하실려면 연장 결제해주세요.
							}
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
							}}
						>
							{/** 결제 완성되면 주석 해제 */}
							{/* <Button
								sx={{ mt: 2 }}
								type="outlined"
								name="결제하기"
								onClick={() =>
									navigate("/Approval")
								}
							/> */}
						</div>
					</>
				}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_pop_close_today"
						)[0].lanName
						: "" // 오늘 하루 보지 않기
				}
				onClose={() => {
					LimitDate("open");
				}}
				onSave={() => {
					LimitDate("close");
				}}
				useSaveButton={true}
				useSaveAfterClose={false}
				actionSx={{
					display: "flex",
					justifyContent: "space-between",
				}}
			/>
			{/* 광고 팝업 */}
			<Dialog
				fullWidth
				open={newAd}
				title={""}
				children={
					<div>
						<Slick items={adList} />
					</div>
				}
				maxWidth={"lg"}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "home_pop_close_today"
						)[0].lanName
						: "" // 오늘 하루 보지 않기
				}
				onClose={() => {
					NewAdd(true);
				}}
				onSave={() => {
					NewAdd(false);
				}}
				useSaveButton={true}
				useSaveAfterClose={false}
				actionSx={{
					display: "flex",
					justifyContent: "space-between",
				}}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName="확인"
			/>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onSave={() => {
					if (confirmToggle.type === "save1") {
						CreateLogic1();
					} else if (confirmToggle.type === "delete1") {
						DeleteLogin1();
					}
				}}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				{confirmToggle.message}
			</Dialog>
			{/* 사용자 장치(컨트롤러) 추가 */}
			<Dialog
				// fullWidth
				title={
					lanList.length > 0
						?
						addController.disabled ?
							lanList.filter(
								(f) => f.lanId === "cm10000_pop_modify_title"
							)[0].lanName
							: lanList.filter(
								(f) => f.lanId === "cm10000_pop_add_title"
							)[0].lanName
						: ""

				}
				open={addController.open}
				actionSx={{
					justifyContent: "space-around",
				}}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() => {
					setAddController({
						open: false,
						disabled: false, // 맥 주소, 장치 설정, 연결 중계기 수정 가능 여부
						disabledNoShow: false,	// 화면표시 미사용 콤보박스 사용 X
						itemIdx: "", // 장치 설정
						itemNo: "", // 장치 번호
						machNm: "", // 장치명
						macAdd: "", // 맥 주소
						useMac: false, // 맥 주소 사용 가능 여부
						machId: "", // 국번
						useMachId: false,
						joinRtNo: "", // 연결 중계기 번호
						screenYn: "Y", // 화면 표시 유무
						address1: "", // 주소
					});
				}}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_save"
						)[0].lanName
						: "" // 저장
				}
				onSave={() => {
					CreateLogic1();
				}}
				// useSaveAfterClose={false}
				useSecondButton={addController.disabled}
				secondButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_delete_title"
						)[0].lanName
						: "" // 삭제
				}
				onSecond={() => {
					// console.log("addController save button: ", addController);
					DeleteLogin1();
				}}
				useSaveAfterClose2={true}
			>
				<AddController
					userLan={userLan}
					lanList={lanList}
					codeList={codeList} // 기초코드팩
					noShowList={noShowList}	// 화면표시 미사용 장치 리스트
					controllerList={controllerList} // 장치 설정(모델명) 콤보 리스트
					joinRtNoList={joinRtNoList} // 연결 중계기 콤보 리스트
					addController={addController} // 추가할 장치의 데이터
					MacSearch={MacSearch} // 맥 주소 사용 가능 여부 조회
					setAddController={setAddController} // 추가할 장치의 데이터 저장
					SelectNoShowItem={SelectNoShowItem}	// 화면표시 미사용 장치 조회
				/>
			</Dialog>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: "",
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default Home;
