import React from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Box,
	Card,
	CardHeader,
	CardContent,
	Typography as MuiTypography,
	Divider,
} from "@mui/material";
import { Combo } from "../../Components/Combos";
import { Input } from "../../Components/Inputs";
import { Button } from "../../Components/Buttons";
import { Checkbox } from "../../Components/Checkboxs";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Icons Import */
import { FiberManualRecord as CircleIcon } from "@mui/icons-material";
/** Images Import */
import ApprovalIcon from "../../Assets/Images/Design/drawable-hdpi/payment_ic.png";

const smartropay = window.smartropay;

const Wraps = styled((props) => <Box {...props} />)(({ theme }) => ({
	padding: "8px 0px",
}));
const Headers = styled((props) => <Box {...props} />)(({ theme }) => ({
	marginBottom: "8px",
}));
const Contents = styled((props) => <form {...props} />)(({ theme }) => ({
	margin: "8px 0px",
	"&:last-child": {
		margin: "24px 0px 0px 0px",
		padding: "0px",
	},
}));
const Content = styled((props) => <Box {...props} />)(({ theme }) => ({
	width: "100%",
	paddingBottom: "8px",
}));
const InputTitles = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: "8px 0px",
}));
const Typography = styled((props) => (
	<MuiTypography variant="subtitle2" {...props} />
))(({ theme }) => ({
	color: "#6E7B9B",
}));
const AlignItemsCenters = styled((props) => <div {...props} />)(
	({ theme }) => ({
		display: "flex",
		alignItems: "center",
	})
);

function Approval(props) {
	const tranMgrFormRef = React.useRef(null);

	// 결제 불러오기
	const CallApproval = async () => {
		try {
			// SmartroPAY 초기화
			await smartropay.init({
				mode: "STG", // MODE 값을 적절히 채워 넣으세요. STG : 데모 테스트, REAL : 실제 결제
			});

			// if (Commons.IsMobile()) {
			// 	// SmartroPAY 결제 요청
			// 	await smartropay.payment({
			// 		FormId: tranMgrFormRef.current.id,
			// 	});
			// } else {
			// SmartroPAY 결제 요청
			await smartropay.payment({
				FormId: tranMgrFormRef.current.id,
				Callback: function (res) {
					// console.log("CallApproval: ", res);
					const { Tid, TrAuthKey } = res;
					props.CallApproval(Tid, TrAuthKey);
				},
			});
			// }
		} catch (error) {
			console.error("SmartroPAY 초기화 및 결제 요청 중 에러:", error);
		}
	};

	return (
		<>
			<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid xs={12} md={12}>
					<Wraps>
						<Headers>
							<AlignItemsCenters>
								<Typography
									sx={{
										fontWeight: "bold",
										color: "#FFFFFF",
									}}
									variant="subtitle1"
								>
									{props.inputs.userNm} &nbsp;
								</Typography>
								<Typography sx={{ color: "#FFFFFF" }}>
									{props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"approval_gen_welcome_message"
										)[0].lanName
										: ""}
									{/* 회원님 환영합니다! */}
								</Typography>
							</AlignItemsCenters>
							<Typography sx={{ color: "#FFFFFF" }}>
								{props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"approval_gen_select_message"
									)[0].lanName
									: ""}
								{/* 유료 회원가입을 위해 아래 항목들을 선택해주세요. */}
							</Typography>
						</Headers>
						<Contents>
							<Content>
								<InputTitles>
									<CircleIcon
										sx={{
											mx: 1,
											color: "#6E7B9B",
											fontSize: "10px",
										}}
									/>
									<Typography>
										{props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"approval_gen_payment_method"
											)[0].lanName
											: ""}
										{/* 결제수단 */}
									</Typography>
								</InputTitles>
								<Combo
									name=""
									fullWidth={true}
									items={props.payments}
									value={props.inputs.payment}
									onChange={(e) => {
										props.setInputs({
											...props.inputs,
											payment: e,
											Paymethod: props.payments.filter(
												(f) => f.value === e
											)[0].val1,
										});
									}}
								/>
							</Content>
							<Content>
								<InputTitles>
									<CircleIcon
										sx={{
											mx: 1,
											color: "#6E7B9B",
											fontSize: "10px",
										}}
									/>
									<Typography>
										{props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"approval_gen_subscription_period"
											)[0].lanName
											: ""}
										{/* 가입기간 */}
									</Typography>
								</InputTitles>
								<Combo
									name=""
									fullWidth={true}
									items={props.approvalPeriod}
									value={props.inputs.periodValue}
									onChange={(e) => props.ChangeAmount(e)}
								/>
							</Content>
							<Content>
								<InputTitles>
									<CircleIcon
										sx={{
											mx: 1,
											color: "#6E7B9B",
											fontSize: "10px",
										}}
									/>
									<Typography>
										{props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10010_col_amt"
											)[0].lanName
											: ""}
										{/* 금액 */}
									</Typography>
								</InputTitles>
								<Input
									type="outlined"
									name=""
									value={
										props.inputs.Amt.toString().replace(
											/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
											","
										) +
										(props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"approval_gen_money_unit"
											)[0].lanName
											: "") //원
									}
									disabled={true}
									fullWidth={true}
									inputProps={{
										style: { textAlign: "right" },
									}}
								/>
							</Content>
						</Contents>
						<Contents>
							<Card sx={{ mt: 3 }}>
								<CardHeader
									sx={{
										textAlign: "center",
										fontWeight: "bold",
										bgcolor: "#141820",
									}}
									title={
										<MuiTypography variant="subtitle1">
											{props.lanList.length > 0
												? props.lanList.filter(
													(f) =>
														f.lanId ===
														"approval_gen_refund_information"
												)[0].lanName
												: ""}
											{/* 유료회원 가입 / 환불안내 */}
										</MuiTypography>
									}
								/>
								<Divider />
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										maxHeight: "20vh",
										overflowY: "scroll"
									}}
								>
									<CardContent>
										본 약관은 FoxIoT 서비스(이하 "서비스"라 한다) 이용과 관련하여 본인은 동 서비스의 이용 고객(이하 ‘고객’)으로서 아래 사항에 대하여 충분한 설명을 듣고 이해하였으며 동의 합니다.
										<div>　　　　　</div>
										<ul style={{ padding: "0px 8px" }}>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 1 조 (목적)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														본 약관은 (주)코노텍(이하 "회사"라 한다)가 제공하는 서비스 내에서 유료서비스의 이용과 관련하여 "회사"와 "고객"의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
													</li>
												</ul>
											</li>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 2 조 (약관의 적용)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														본 약관은 고객이 서비스 내에서 유료로 제공되는 서비스를 이용하고자 하는 경우, 본 약관에 별도로 동의한 "고객"에게 적용됩니다.
													</li>
												</ul>
											</li>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 3 조 (정의)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														① 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
														<ul style={{ padding: "0px 8px" }}>
															<li>
																1. 고객이라 함은 본 약관에 따라 회사와 이용계약을 체결하여 회사 또는 판매자가 제공하는 유료서비스를 이용하는 자를 말합니다.
															</li>
															<li>
																2. 서비스라 함은 회사 또는 판매자가 유료로 제공하는 각종 서비스, 온라인 디지털콘텐츠 및 이를 결제 이용하는 데에 수반되는 제반 관련 서비스를 말합니다.
															</li>
															<li>
																3. 무료체험기간이라 함은 회사가 자신의 정책에 의거하여 시혜적으로 부여하는 서비스 무상 이용 기간을 말합니다.
															</li>
														</ul>
													</li>
													<li>
														② 본 약관에 사용되는 용어 중 본 조에서 정하지 아니한 부분은 관계 법령 및 일반 관례에 따릅니다.
													</li>
												</ul>
											</li>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 4 조 (이용계약의 성립 등)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														① 고객은 회사가 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. 회사는 계약 체결 전에 각 호의 사항에 관하여 "회원"이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.
														<ul style={{ padding: "0px 8px" }}>
															<li>
																1. 유료서비스의 확인 및 선택
															</li>
															<li>
																2. 결제방법의 선택 및 결제정보의 입력
															</li>
															<li>
																3. 유료서비스의 이용신청에 관한 확인 또는 회사의 확인에 대한 동의
															</li>
														</ul>
													</li>
													<li>
														② 회사는 고객의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.
														<ul style={{ padding: "0px 8px" }}>
															<li>
																1. 실명이 아니거나 타인의 명의를 이용한 경우
															</li>
															<li>
																2. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
															</li>
															<li>
																3. 미성년자가 청소년보호법에 의해서 이용이 금지되는 유료서비스를 이용하고자 하는 경우
															</li>
															<li>
																4. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우
															</li>
															<li>
																5. 무료체험 기간 또는 서비스이용 유예기간 등 회사에서 제공하는 혜택을 남용하기 위한 재가입이라고 판단되는 경우
															</li>
															<li>
																6. 기타 "회원"의 귀책사유로 승인이 불가능하다고 판단되는 경우
															</li>
														</ul>
													</li>
													<li>
														③ 이용계약의 성립시기는 가입완료 또는 구매완료를 신청절차 상에서 표시한 시점으로 합니다.
													</li>
													<li>
														④ 고객이 유료서비스를 이용하기 위해서는 본 약관에 동의한 후 해당 유료서비스의 이용조건에 따라 이용요금을 지급하여야 합니다.
													</li>
												</ul>
											</li>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 5 조 (환불 대상)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														1. 고객은 본 약관에 따라 유료서비스를 이용하기 위해 유료 결제를 한 날로부터 7일 이내에 회사에 대해 서비스의 이용에 관한 청약을 철회할 수 있습니다. 다만, 회사가 회원에게 사전에 합의한 내용과 다르게 서비스를 제공하거나 서비스를 제공하지 아니하는 경우, 회원은 유료 결제를 한 날로부터 3개월 이내 또는 해당 사실을 알거나 알 수 있었던 날로부터 30일 이내에 회사에 대해 서비스의 이용에 관한 청약을 철회할 수 있습니다.
													</li>
													<li>
														2. 서비스에 대한 환불기준은 다음 각 호와 같습니다.
														<ul style={{ padding: "0px 8px" }}>
															<li>
																① 서비스 시작일로부터 7일 이내 전액 환불 가능합니다.
															</li>
															<li>
																② 서비스 시작일로부터 7일 이후에는 잔여 일 수에 따라 부분 환불 가능하며, 정확한 금액은 고객센터에서 문의하실 수 있습니다.
															</li>
															<li>
																③ 이벤트성 서비스, 비정규 서비스, 특별기획 서비스 등에서는 별도의 취소·변경 및 환불규정이 적용될 수 있습니다.
															</li>
															<li>
																④ 환불은 구매한 서비의 기본 시간을 기준으로 적용이 되며, 추가혜택 기간, 이벤트 기간, 일시정지 기간은 환불에서 제외됩니다.
															</li>
														</ul>
													</li>
													<li>
														3. 무형 상품은 결제 완료 즉시 상품이 제공되므로 배송 및 결제 취소 절차가 생략됩니다.
													</li>
													<li>
														4. 이용자가 관계법령 및 본 약관의 규정을 위반하여 "회사"로부터 강제탈퇴 처리가 되는 경우에는 본 조의 환불규정이 적용되지 않습니다.
													</li>
												</ul>
											</li>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 6 조 (환불 불가 사유)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														1. 서비스 이용 기간이 만료된 경우
													</li>
													<li>
														2. 고객이 스스로 탈퇴한 경우
													</li>
													<li>
														3. 천재지변, 국가비상사태 등 불가항력적인 사유로 인해 서비스 제공이 불가능한 경우
													</li>
													<li>
														4. 고객은 다음 각 호의 어느 하나에 해당하는 경우에는 청약철회를 할 수 없습니다.
														<ul style={{ padding: "0px 8px" }}>
															<li>
																① 이용자에게 책임 있는 사유로 콘텐츠가 멸실 또는 훼손된 경우
															</li>
															<li>
																② 디지털콘텐츠 제공이 개시된 경우(다만, 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 청약철회 등을 할 수 있습니다)
															</li>
														</ul>
													</li>
												</ul>
											</li>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 8 조 (이용요금의 환불 및 제한)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														1. 회사가 이용요금을 환불하는 경우 기본적으로 아래 각 호에 따릅니다. 단, 회사는 개별 유료서비스에 대해 별도의 상세 환불정책을 운영할 수 있으며, 이 경우 아래 각 호 및 본 약관의 다른 내용에도 불구하고 상세 환불정책에서 정한 바를 따릅니다. 별도의 상세 환불정책이 적용되는 경우 회사는 해당 유료서비스 구매 과정에서 주의사항으로 기재하는 등 적절한 방식을 통해 안내합니다.
													</li>
													<li>
														2. 해당 유료서비스가 결제 후 1회의 이용만으로 서비스의 이용이나 구매가 완료되는 서비스인 경우 해당 서비스를 이용(제공이 개시 된 것을 포함)한 후에는 환불 불가능.
													</li>
													<li>
														3. 회사는 고객에 대하여 계약해제, 해지의 의사표시를 한 날로부터 3영업일 이내에 대금의 결제와 동일한 방법으로 유료서비스 이용요금을 환급합니다(단, 수납확인이 필요한 결제수단의 경우에는 수납확인일로부터 3영업일 이내에 환급). 다만, 동일한 방법으로 환불이 불가한 경우에는 계좌 이체 등의 방법으로 환불 조치할 수 있으며 회사는 이를 고객에게 고지합니다.
													</li>
													<li>
														4. 회사는 이용요금을 환불함에 있어서 고객이 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 수납확인이 필요한 결제수단의 경우 및 고객이 유료서비스 이용으로부터 얻은 이익에 해당하는 금액을 공제하는 경우에는 그러하지 아니할 수 있습니다.
													</li>
												</ul>
											</li>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 9 조 (책임제한)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														1. 유료 요금제 결제 후 사용 시 서비스에서 생기는 문제는 이 서비스를 제공하는 업체에 있지 않습니다.
													</li>
													<li>
														2. 유료서비스 기간이 만료된 이후 초기화가 진행되오니 소실된 데이터의 복구는 하실 수 없으므로 만료 전 미리 연장요청 또는 백업 부탁드립니다.
													</li>
												</ul>
											</li>
											<li style={{ listStyleType: "disc", padding: "7px 0px" }}>
												제 10 조 (기타)
												<ul style={{ padding: "0px 8px" }}>
													<li>
														1. 고객은 서비스 이용요금 결제일로부터 3일 이후에는 결제수단을 변경할 수 없습니다.
													</li>
													<li>
														2. 고객의 사유로 서비스를 중도해지 하게 되는 경우, 회사는 고객이 선납한 이용요금을 환불하지 않으며 고객은 이에 동의 합니다.
													</li>
													<li>
														3. 회사가 고객에게 무상으로 제공하는 부가서비스의 경우, 고객에게 어떠한 권리도 발생되지 않으며, 회사는 고객에게 이와 관련한 별도의 보상 및 기타 책임을 지지 않습니다.
													</li>
													<li>
														4. 고객의 개인 사유로 인한 서비스 이용 장애 및 어려움 등에 대해 회사에서는 별도의 보상 및 기타 책임을 지지 않습니다.
													</li>
													<li>
														5. 회사는 고객이 서비스 약관 또는 본 약관에 명시된 고객의 의무를 위반하였을 경우, 또는 그러한 위법·부당한 행위가 있는 것으로 의심될 만한 상당한 이유가 있는 경우 고객과의 유료서비스 이용계약을 해제, 해지하거나 또는 기간을 정하여 유료서비스의 이용을 제한할 수 있습니다. 계약의 해제, 해지는 회사가 자신이 정한 통지 방법에 따라 고객에게 그 의사를 표시한 때에 효력이 발생합니다.
													</li>
												</ul>
											</li>
											<li style={{ padding: "10px 0px" }}>
												- 본 환불 규정은 2024년 8월 1일부터 적용됩니다.
											</li>
										</ul>
									</CardContent>
								</div>
							</Card>
							<Checkbox
								value={props.inputs.checkboxValue}
								onChange={(e) => {
									// props.setprops.inputs({
									// 	...props.inputs,
									// 	checkboxValue: e,
									// });
									props.ChangeCheckbox(e);
								}}
								name={
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"approval_gen_consent_regulatory"
										)[0].lanName
										: ""
								} // 가입 및 환불 규정에 동의합니다.
							/>
						</Contents>
					</Wraps>
				</Grid>
			</Grid>
			{/* <div
				style={{
					width: "100%",
					position: "fixed",
					left: "0px",
					bottom: "0px",
				}}
			> */}
			<Button
				sx={{ mb: 2 }}
				name={
					<AlignItemsCenters>
						<img
							style={{
								width: "20px",
								height: "20px",
								marginRight: "8px",
							}}
							src={ApprovalIcon}
						/>
						<Typography sx={{ color: "#FFFFFF" }} variant="h6">
							{props.lanList.length > 0
								? props.lanList.filter(
									(f) => f.lanId === "approval_gen_paying"
								)[0].lanName
								: ""}
							{/* 결제하기 */}
						</Typography>
					</AlignItemsCenters>
				}
				onClick={() => {
					// console.log("click: ", props.inputs);
					if (props.ApprovalValidation()) {
						CallApproval();
					} else {
						return false;
					}
				}}
				fullWidth={true}
			/>
			{/* </div> */}

			{/* --- 결제 FORM --- */}
			<>
				<form
					id="tranMgr"
					name="tranMgr"
					method="post"
					ref={tranMgrFormRef}
					style={{ display: "none" }}
				>
					<input
						type="text"
						name="PayMethod"
						value={props.inputs.Paymethod}
						readOnly
					/>
					<input
						type="text"
						name="GoodsCnt"
						value={props.inputs.GoodsCnt}
						readOnly
					/>
					<input
						type="text"
						name="GoodsName"
						value={props.inputs.GoodsName}
						readOnly
					/>
					<input
						type="text"
						name="Amt"
						value={props.inputs.Amt}
						readOnly
					/>
					<input
						type="text"
						name="Moid"
						value={props.inputs.MoId}
						readOnly
					/>
					<input
						type="text"
						name="Mid"
						value={props.inputs.MId}
						readOnly
					/>
					<input
						type="text"
						name="ReturnUrl"
						value={props.inputs.ReturnUrl}
						readOnly
					/>
					<input
						type="text"
						name="StopUrl"
						value={props.inputs.StopUrl}
						readOnly
					/>
					<input
						type="text"
						name="BuyerName"
						value={props.inputs.BuyerName}
						readOnly
					/>
					<input
						type="text"
						name="BuyerTel"
						value={props.inputs.BuyerTel}
						readOnly
					/>
					<input
						type="text"
						name="BuyerEmail"
						value={props.inputs.BuyerEmail}
						readOnly
					/>
					<input
						type="text"
						name="VbankExpDate"
						value={props.inputs.VbankExpDate}
						readOnly
					/>
					<input
						type="text"
						name="EncryptData"
						value={props.inputs.EncryptData}
						readOnly
					/>
					<input
						type="text"
						name="GoodsCl"
						value={props.inputs.GoodsCl}
						readOnly
					/>
					<input
						type="text"
						name="EdiDate"
						value={props.inputs.EdiDate}
						readOnly
					/>
					<input
						type="text"
						name="GoodsCl"
						value={props.inputs.GoodsCl}
						readOnly
					/>

					{/* MID 기본 세팅시 부가세 직접계산으로 설정됩니다. */}
					<input
						type="text"
						name="TaxAmt"
						value={props.inputs.TaxAmt}
						readOnly
					/>
					<input
						type="text"
						name="TaxFreeAmt"
						value={props.inputs.TaxFreeAmt}
						readOnly
					/>
					<input
						type="text"
						name="VatAmt"
						value={props.inputs.VatAmt}
						readOnly
					/>
				</form>

				{/* PC 연동의 경우에만 아래 승인폼이 필요합니다. (Mobile은 제외) */}
				{/* {Commons.IsMobile() ? null : (
					<form id="approvalForm" name="approvalForm" method="post">
						<input type="hidden" id="Tid" name="Tid" readOnly />
						<input
							type="hidden"
							id="TrAuthKey"
							name="TrAuthKey"
							readOnly
						/>
					</form>
				)} */}
				<form id="approvalForm" name="approvalForm" method="post">
					<input type="hidden" id="Tid" name="Tid" readOnly />
					<input
						type="hidden"
						id="TrAuthKey"
						name="TrAuthKey"
						readOnly
					/>
				</form>
			</>
			{/* ----------------- */}
		</>
	);
}

export default Approval;
