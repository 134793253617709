import React from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Box,
	Button,
	IconButton,
	Link,
	Stack,
	Typography,
	TextField,
	Unstable_Grid2 as Grid,
	Checkbox,
} from "@mui/material";
import { Dialog } from "../../Components/Dialog";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { Alert } from "../../Components/Alert";
import { Posts } from "../../Components/PostCode";
// import { Accordion } from "../../Components/Conotec";
/** Icons Import */
import {
	ArrowBack,
	ArrowDropDown,
} from "@mui/icons-material";


const InfoSubtitle = styled((props) => (
	<Typography
		variant={Commons.IsMobile() ? "caption" : "subtitle1"}
		{...props}
	/>
))(({ theme }) => ({
	color: "#8D8D8D",
}));

const InfoBody = styled((props) => (
	<Typography variant={Commons.IsMobile() ? "body2" : "body1"} {...props} />
))(({ theme }) => ({
	color: "#ffffff",
	marginBottom: "10px",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}));


function Register(props) {
	const [message, setMessage] = React.useState({
		toggle: false,
		type: "info",
		message: "",
	});
	const [password, setPassword] = React.useState({
		pw: "",
		pwConfirm: "",
	});
	const [tel, setTel] = React.useState("");
	const messageRef = React.useRef();
	const [postToggle, setPostToggle] = React.useState(false);

	const [accordion, setAccordion] = React.useState({
		title: "teset",
		content: "test"
	});
	const [confirmToggle, setConfirmToggle] = React.useState({
		// 확인 창
		open: false,
		title: "",
		message: "",
		type: "",
	});

	const [privacyPopup, setPrivacyPopup] = React.useState(false); // 개인정보동의 팝업
	// 개인정보, 사이트 이용 동의 아코디언 조절
	const [accExpanded, setAccExpanded] = React.useState(null);

	const ChangeExpanded = (panel) => (event, newExpanded) => {
		setAccExpanded(newExpanded ? panel : false);
	};

	// 패스워드 체크
	React.useEffect(() => {
		// const HandlePwConfirm = () => {
		// 	console.log("test : ", props.registers);
		// 	if (password.pw !== password.pwConfirm) {
		// 		setMessage({
		// 			...message,
		// 			toggle: true,
		// 			type: "error",
		// 			message: "비밀번호가 다릅니다.",
		// 		});
		// 		props.setRegisters({
		// 			...props.registers,
		// 			userPw: "",
		// 			useUserPw: false,
		// 		});
		// 	} else if (password.pw !== "" && password.pwConfirm !== "" && password.pw === password.pwConfirm) {
		// 		setMessage({
		// 			...message,
		// 			toggle: false,
		// 			type: "info",
		// 			message: "",
		// 		});
		// 		props.setRegisters({
		// 			...props.registers,
		// 			userPw: password.pw,
		// 			useUserPw: true,
		// 		});
		// 	}
		// 	// props.setRegisters({
		// 	// 	...props.registers,
		// 	// });
		// };
		// window.addEventListener("change", HandlePwConfirm);
		// return () => window.removeEventListener("change", HandlePwConfirm);

		if (password.pw !== password.pwConfirm) {
			setMessage({
				...message,
				toggle: true,
				type: "error",
				message: (
					<div>
						<p>
							비밀번호가 다릅니다.
						</p>
						<p>
							The password is different.
						</p>
					</div>
				),
			});
			props.setRegisters({
				...props.registers,
				userPw: "",
				useUserPw: false,
			});
		} else if (password.pw !== "" && password.pwConfirm !== "" && password.pw === password.pwConfirm) {
			setMessage({
				...message,
				toggle: false,
				type: "info",
				message: "",
			});
			props.setRegisters({
				...props.registers,
				userPw: password.pw,
				useUserPw: true,
			});
		}
	}, [password.pwConfirm]);

	// 입력한 전화번호를 전화번호 형식으로 변경
	React.useEffect(() => {
		let test = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
		// console.log("tel : ", tel);
		// console.log("tel : ", test.test(tel));
		if (test.test(tel)) {
			if (tel.length === 11) {
				props.setRegisters({
					...props.registers,
					userTel: tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
				});
			} else if (tel.length === 13) {
				props.setRegisters({
					...props.registers,
					userTel: tel,
				});
			}
		} else if (tel === "") {
			props.setRegisters({
				...props.registers,
				userTel: tel,
			});
		}
	}, [tel]);

	return (
		<Grid
			xs={12}
			lg={6}
			sx={{
				backgroundColor: "background.paper",
				display: "flex",
				flexDirection: "column",
				position: "relative",
			}}
		>
			<Box
				component="header"
				sx={{
					left: 0,
					p: 3,
					position: "fixed",
					top: 0,
					width: "100%",
				}}
			>
				<Box
					href="/"
					sx={{
						display: "inline-flex",
						height: 32,
						width: 32,
					}}
				>
					<IconButton
						size="large"
						color="secondary"
						onClick={() => {
							// 뒤로가기시 레지스터 초기화
							props.setRegisters({
								register: false,
								userId: "",
								useUserId: false,
								userNm: "",
								userPw: "",
								useUserPw: false,
								userTel: "",
								userPost: "",
								userAddress1: "",
								userAddress2: "",
							});
						}}
					>
						<ArrowBack />
					</IconButton>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					flex: "1 1 auto",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "background.paper",
				}}
			>
				<Box
					sx={{
						width: "100%",
						maxWidth: 550,
						px: 3,
						// py: "100px",
					}}
				>
					<div>
						<Stack spacing={1} sx={{ mb: 3 }}>
							<Typography variant="h4">Register</Typography>
							<Typography color="text.secondary" variant="body2">
								Be &nbsp;
								<Link variant="subtitle2">Conotec&apos;s</Link>
								&nbsp; family with simple input.
							</Typography>
						</Stack>
						<form noValidate>
							<Stack spacing={3}>
								<div style={{ display: "flex" }}>
									<TextField
										sx={{
											minWidth: Commons.IsMobile()
												? ""
												: "470px",
										}}
										label="* 이메일/E-Mail (필수)"
										name="eMail"
										value={props.registers.userId}
										onChange={(e) => {
											props.setRegisters({
												...props.registers,
												userId: e.target.value,
											});
										}}
									/>
									<Button onClick={() => {
										let eMail =
											/^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

										if (eMail.test(props.registers.userId)) {
											props.CheckUserId();
										} else if (props.registers.userId === "") {
											props.setSnacks({
												...props.snack,
												open: true,
												type: "error",
												message: (
													<div>
														<p>
															이메일 형식을 입력해 주세요.
														</p>
														<p>
															Please enter your email format.
														</p>
													</div>
												),
											});
										} else {
											props.setSnacks({
												...props.snack,
												open: true,
												type: "error",
												message: (
													<div>
														<p>
															이메일 형식으로 입력해 주세요.
														</p>
														<p>
															Please enter it in email format.
														</p>
													</div>
												),
											});
										}
									}}
									>
										중복체크
									</Button>
								</div>
								<TextField
									fullWidth
									label="* 이름/Name (필수)"
									name="Name"
									type="text"
									value={props.registers.userNm}
									onChange={(e) => {
										props.setRegisters({
											...props.registers,
											userNm: e.target.value,
										});
									}}
								/>
								<TextField
									id="LoginPw"
									fullWidth
									label="* 비밀번호/Password (필수)"
									name="Password"
									type="password"
									value={password.pw}
									onChange={(e) => {
										setPassword({
											...password,
											pw: e.target.value,
										});
									}}
								/>
								<TextField
									ref={messageRef}
									fullWidth
									label="* 비밀번호 확인/Check Password (필수)"
									name="Password"
									type="password"
									value={password.pwConfirm}
									onChange={(e) => {
										setPassword({
											...password,
											pwConfirm: e.target.value,
										});
									}}
								/>
								<TextField
									fullWidth
									label="* 전화번호/Tel (필수)"
									name="Tel"
									type="text"
									value={tel}
									// value={props.registers.userTel}
									onChange={(e) => {
										setTel(e.target.value);
										// props.setRegisters({
										// 	...props.registers,
										// 	userTel: e.target.value,
										// });
									}}
								/>
								<div style={{ display: "flex" }}>
									<TextField
										sx={{
											minWidth: Commons.IsMobile()
												? ""
												: "470px",
										}}
										label="우편번호/Address (선택)"
										name="post"
										value={props.registers.userPost}
										disabled
									/>
									<Button onClick={() => setPostToggle(true)}>
										주소검색
									</Button>
								</div>
								<TextField
									fullWidth
									label="주소/Address"
									name="address1"
									value={props.registers.userAddress1}
									disabled
								/>
								<TextField
									fullWidth
									label="상세 주소/Detail Address"
									name="address2"
									value={props.registers.userAddress2}
									onChange={(e) => {
										props.setRegisters({
											...props.registers,
											userAddress2: e.target.value,
										});
									}}
								/>
								{/* <TextField
									fullWidth
									label="사용 언어 (기본 한국어)"
									name="lanCode"
									value={props.registers.lanCode}
									onChange={(e) => {
										props.setRegisters({
											...props.registers,
											lanCode: e.target.value,
										});
									}}
								/> */}
								{/* <Button onClick={() => setPrivacyPopup(true)}>
									약관 동의
								</Button> */}
							</Stack>
							{message.toggle && (
								<Alert
									sx={{ mt: 3 }}
									noteType={message.type}
									name={message.message}
									onClose={() => {
										setMessage({
											...message,
											toggle: false,
										});
									}}
								/>
							)}
							<Button
								fullWidth
								size="large"
								sx={{ mt: 3, mb: 3 }}
								variant="contained"
								color="secondary"
								onClick={() => {
									let telReg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
									// console.log("props.registers : ", props.registers);
									if (props.registers.userId === "") {
										props.setSnacks({
											...props.snack,
											open: true,
											type: "error",
											message: (
												<div>
													<p>
														아이디를 입력해 주세요
													</p>
													<p>
														Please enter your ID
													</p>
												</div>
											),
										});
									} else if (!props.registers.useUserId) {
										props.setSnacks({
											...props.snack,
											open: true,
											type: "error",
											message: (
												<div>
													<p>
														아이디 중복 체크를 진행해 주세요.
													</p>
													<p>
														Please proceed with the duplicate ID check.
													</p>
												</div>
											),
										});
									} else if (props.registers.userNm === "") {
										props.setSnacks({
											...props.snack,
											open: true,
											type: "error",
											message: (
												<div>
													<p>
														성명을 입력해 주세요.
													</p>
													<p>
														Please enter your name.
													</p>
												</div>
											),
										});
									} else if (props.registers.userTel === "") {
										props.setSnacks({
											...props.snack,
											open: true,
											type: "error",
											message: (
												<div>
													<p>
														올바른 전화번호 형식으로 입력해 주세요.
													</p>
													<p>
														Please enter a valid phone number format.
													</p>
												</div>
											),
										});
									} else if (!telReg.test(props.registers.userTel)) {
										props.setSnacks({
											...props.snack,
											open: true,
											type: "error",
											message: (
												<div>
													<p>
														올바른 전화번호를 입력해 주세요.
													</p>
													<p>
														Please enter a valid phone number.
													</p>
												</div>
											),
										});
									} else if (props.registers.userPw === "") {
										props.setSnacks({
											...props.snack,
											open: true,
											type: "error",
											message: (
												<div>
													<p>
														비밀번호를 입력해 주세요.
													</p>
													<p>
														Please enter your password.
													</p>
												</div>
											),
										});
									} else if (!props.registers.useUserPw) {
										props.setSnacks({
											...props.snack,
											open: true,
											type: "error",
											message: (
												<div>
													<p>
														비밀번호를 다시 확인해 주세요
													</p>
													<p>
														Please check your password again
													</p>
												</div>
											),
										});
									} else {
										props.RegisterLogic();
										setTel("");
										// setPrivacyPopup(true);
									}
								}}
							>
								회원가입
							</Button>
						</form>
					</div>
				</Box>
			</Box>
			<Posts
				open={postToggle}
				onClose={() => setPostToggle(false)}
				fullScreen={Commons.IsMobile() ? true : false}
				ReceivePost={(data) => {
					props.setRegisters({
						...props.registers,
						userPost: data.zonecode,
						userAddress1: data.roadAddress,
					});
				}}
			/>
			{/* <Dialog
				fullWidth
				open={privacyPopup}
				maxWidth={"md"}
				title={
					"코노텍 IoT 서비스 이용을 위한 개인정보 수집·이용 동의서"
				}
				children={
					<div>
						<Accordion
							expanded={accExpanded === "panel1"}
							onChange={ChangeExpanded("panel1")}
						>
							<AccordionSummary
								expandIcon={<ArrowDropDown />}
							>
								<InfoBody>
									개인정보 수집·이용 내역
								</InfoBody>
							</AccordionSummary>
							<AccordionDetails
								sx={{
									display: "flex",
									flexDirection: "column",
									maxHeight: "30vh",
									overflowY: "scroll"
								}}
							>
								<div>
									<InfoSubtitle>
										회원가입 약관1
									</InfoSubtitle>
									<InfoBody>
										회원가입 약관2
									</InfoBody>
									<InfoBody>
										회원가입 약관3
									</InfoBody>
									<InfoBody>
										회원가입 약관4
									</InfoBody>
									<InfoBody>
										회원가입 약관5
									</InfoBody>
									<InfoBody>
										회원가입 약관6
									</InfoBody>
									<InfoBody>
										회원가입 약관6
									</InfoBody>
									<InfoBody>
										회원가입 약관7
									</InfoBody>
									<InfoBody>
										회원가입 약관8
									</InfoBody>
									<InfoBody>
										회원가입 약관9
									</InfoBody>
									<InfoBody>
										회원가입 약관10
									</InfoBody>
									<InfoBody>
										회원가입 약관11
									</InfoBody>
									<InfoBody>
										회원가입 약관12
									</InfoBody>
									<InfoBody>
										회원가입 약관13
									</InfoBody>
									<InfoBody>
										회원가입 약관14
									</InfoBody>
									<InfoBody>
										<Checkbox
											value={props.registers.userTerm}
											onChange={() => {
												props.setRegisters({
													...props.registers,
													userTerm: props.registers.userTerm ? false : true,
												});
											}}
										/>
										위 회원가입 약관에 동의합니다.
									</InfoBody>
								</div>
							</AccordionDetails>
						</Accordion>
						<div style={{ marginTop: "15px", marginBottom: "15px" }}></div>
						<Accordion
							expanded={accExpanded === "panel2"}
							onChange={ChangeExpanded("panel2")}
						>
							<AccordionSummary
								expandIcon={<ArrowDropDown />}
							>
								<InfoBody>
									사이트 이용 동의
								</InfoBody>
							</AccordionSummary>
							<AccordionDetails
								sx={{
									display: "flex",
									flexDirection: "column",
									maxHeight: "30vh",
									overflowY: "scroll"
								}}
							>
								<div>
									<InfoSubtitle>
										사이트 이용 약관1
									</InfoSubtitle>
									<InfoBody>
										사이트 이용 약관2
									</InfoBody>
									<InfoBody>
										사이트 이용 약관3
									</InfoBody>
									<InfoBody>
										사이트 이용 약관4
									</InfoBody>
									<InfoBody>
										사이트 이용 약관5
									</InfoBody>
									<InfoBody>
										사이트 이용 약관6
									</InfoBody>
									<InfoBody>
										사이트 이용 약관6
									</InfoBody>
									<InfoBody>
										사이트 이용 약관7
									</InfoBody>
									<InfoBody>
										사이트 이용 약관8
									</InfoBody>
									<InfoBody>
										사이트 이용 약관9
									</InfoBody>
									<InfoBody>
										사이트 이용 약관10
									</InfoBody>
									<InfoBody>
										사이트 이용 약관11
									</InfoBody>
									<InfoBody>
										사이트 이용 약관12
									</InfoBody>
									<InfoBody>
										사이트 이용 약관13
									</InfoBody>
									<InfoBody>
										사이트 이용 약관14
									</InfoBody>
									<InfoBody>
										<Checkbox
											value={props.registers.siteTerm}
											onChange={() => {
												props.setRegisters({
													...props.registers,
													siteTerm: props.registers.siteTerm ? false : true,
												});
											}}
										/>
										위 사이트 이용 약관에 동의합니다.
									</InfoBody>
								</div>
							</AccordionDetails>
						</Accordion>
					</div>
				}
				closeButtonName="닫기"
				saveButtonName="동의"
				onClose={() => {
					setPrivacyPopup(false);
				}}
				onSave={() => {
					console.log("props.registers : ", props.registers);
					if (!props.registers.userTerm) {
						setConfirmToggle({
							// 확인 창
							open: true,
							title: "약관 미동의",
							message: "개인정보 수집 및 이용에 관한 약관에 동의하지 않았습니다.",
							type: "error",
						});
					} else if (!props.registers.siteTerm) {
						setConfirmToggle({
							// 확인 창
							open: true,
							title: "약관 미동의",
							message: "사이트 이용에 관한 약관에 동의하지 않았습니다.",
							type: "error",
						});
					} else {
						setPrivacyPopup(false);
					}
				}}
				useSaveButton={true}
				useSaveAfterClose={false}
				actionSx={{
					display: "flex",
					justifyContent: "space-between",
				}}
			/>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				saveButtonName={"확인"}
				onSave={() => {
					console.log("test");
				}}
				closeButtonName={"닫기"}
			>
				{confirmToggle.message}
			</Dialog> */}
		</Grid>
	);
}

export default Register;