import React from "react";
/** Components Import */
import { FormControl, InputLabel, Select, MenuList, MenuItem } from "@mui/material";

function BasicCombo({
	type = "outlined", 		// "filled", "outlined", "standard"
	sx = {}, 				// mui inline-style object
	color = "secondary", 	// "first", "secondary", "success", "info", "warning", "error"
	size = "medium", 		// "small", "medium"
	fullWidth = false, 		// true, false
	required = false, 		// true, false
	disabled = false, 		// true, false
	name = "Combo", 		// combo name
	value = "", 			// combo value
	onOpen = (e) => { }, 	// combo open event function
	onChange = (e) => { }, 	// combo changed event function
	onClose = (e) => { }, 	// combo close event function
	multiple = false, 		// true, false
	autoWidth = false, 		// true, false
	inputProps = {}, 		// mui input object
	items = [],				// [{value, name}]
}) {
	const ComboOpened = (e) => {
		onOpen(e);
	};

	const ComboChanged = (e) => {
		onChange(e.target.value);
	};

	const ComboClosed = (e) => {
		onClose(e);
	};

	return (
		<FormControl
			sx={sx}
			color={color}
			size={size}
			fullWidth={fullWidth}
			required={required}
			disabled={disabled}
		>
			<InputLabel>{name}</InputLabel>
			<Select
				variant={type}
				label={name}
				value={value}
				onOpen={ComboOpened}
				onChange={ComboChanged}
				onClose={ComboClosed}
				multiple={multiple}
				autoWidth={autoWidth}
				inputProps={inputProps}
			>
				{items.map((item, index) => {
					return (
						<MenuItem key={index} value={item.value}>
							{item.name}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

export default BasicCombo;
