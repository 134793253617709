import React from "react";
import DaumPostcode from "react-daum-postcode";
/** Components Import */
import { Dialog } from "../Dialog";

function DaumPosts({
	open = false,
	onClose = () => {},
	title = "주소검색",
	maxWidth = "lg",
	fullScreen = false,
	closeButtonName = "닫기",
	ReceivePost = (data) => {},
}) {
	const CloseDialog = () => {
		onClose();
	};

	const HandleComplete = (data) => {
		ReceivePost(data);
	};

	return (
		<Dialog
			open={open}
			onClose={CloseDialog}
			title={title}
			maxWidth={maxWidth}
			fullScreen={fullScreen}
			closeButtonName={closeButtonName}
			useSaveButton={false}
		>
			<DaumPostcode onComplete={HandleComplete} onClose={CloseDialog} />
		</Dialog>
	);
}

export default DaumPosts;
