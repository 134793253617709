import React from "react";
/** Components Import */
import {
	ToggleButtonGroup,
	ToggleButton as MuiToggleButton,
} from "@mui/material";

function ToggleButton({
	sx = {}, // mui inline-style object
	color = "secondary", // "first", "secondary", "success", "info", "warning", "error"
	size = "medium", // "small", "medium", "large"
	value = "", // toggle button value
	orientation = "horizontal", // "horizontal", "vertical"
	onChange = (e) => {}, // toggle button changed event function
	exclusive = true, // true, false
	fullWidth = false, // true, false
	disabled = false, // true, false
	items = [], // toggle button element object array ex) [{ name: "Button1", value={}, onChange: (e) => {} }, {...} ]
}) {
	const ToggleButtonChanged = (e, newValue) => {
		onChange(e, newValue);
	};

	return (
		<ToggleButtonGroup
			sx={sx}
			color={color}
			size={size}
			orientation={orientation}
			value={value}
			onChange={ToggleButtonChanged}
			exclusive={exclusive}
			fullWidth={fullWidth}
			disabled={disabled}
		>
			{items.map((item, index) => {
				return (
					<MuiToggleButton
						key={index}
						sx={item.sx}
						color={item.color}
						value={item.value}
						onClick={item.onClick ? item.onClick : (e) => {}}
						onChange={item.onChange ? item.onChange : (e) => {}}
						selected={item.selected}
						disabled={item.disabled}
					>
						{item.icon}
					</MuiToggleButton>
				);
			})}
		</ToggleButtonGroup>
	);
}

export default ToggleButton;
