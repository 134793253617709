import React from "react";
/** Components Import */
// import { Button } from "@mui/material";
import { Button } from '@mui/material';
import KakaoImage from "../../Assets/Images/kakao_login_medium_wide.png";

function SocialKakao() {
	const rest_api_key = process.env.REACT_APP_KAKAO_REST_API_KEY;
	const redirect_uri = process.env.REACT_APP_KAKAO_REDIRECT_URI;
	const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

	const KakaoLogic = () => {
		// console.log("kakaoURL : ", kakaoURL);
		window.location.href = kakaoURL;
	};

	return (
		<>
			<Button
				// fullWidth
				size="large"
				sx={{ minWidth: 300 }}
				// variant="contained"
				// color="#FEE500"
				onClick={() => KakaoLogic()}
			>
				{/* 카카오 로그인 */}
				<img src={KakaoImage} />
			</Button>
		</>
	);
}

export { SocialKakao };