import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components */
import SwappingView from "./../../Views/Home/Swapping";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { Slick } from "../../Components/Slick";
import { SnackDialog } from "../../Components/Dialog";

function Swapping(props) {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [listData, setListData] = React.useState([]);
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
	}, []);

	// 컨트롤러 데이터 조회
	const Init = async (_type = false) => {
		if (_type) {
			setProgress(true);
		}

		let userInfo = null;
		let tempListData = [];
		let lans = [];

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});
			// 컨트롤러 데이터 조회
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.Home,
				{
					userId: userInfo.userId,
					type: Commons.Storages("get", "settings").server,
				}
			).then((result) => {
				let data = result.data;
				// console.log("data : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setListData([]);
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_check_data"
							)[0].lanName
							: "" // 데이터가 없습니다.
					);
				} else if (data === "등록된 장치가 없습니다.") {
					setListData([]);
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_no_device_registered"
							)[0].lanName
							: "" // 등록된 장치가 없습니다.
					);
				} else if (data === false) {
					throw new Error(data);
				} else {
					data.machNames
						.filter((f) =>
							f.itemType !== "6" &&
							// f.itemType !== "7" &&
							f.screenYn !== "N"
						)
						.forEach((item, index) => {
							tempListData.push({
								id: item.itemNo,
								name: item.machNm,
								order: item.itemOrder ? Number(item.itemOrder) : index,
								// order: index + 1 + "",
							});
						});
					// tempListData.sort((a, b) => a.order.localeCompare(b.order));
					tempListData.sort((a, b) => a.order - b.order);
					// console.log("list: ", tempListData);
				}
			});
			setListData(tempListData);
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	type:
			// 		lans.length > 0
			// 			? lans.filter(
			// 				(f) => f.lanId === "swapping_pop_error_title"
			// 			)[0].lanName
			// 			: "", // error
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 컨트롤러 순서 변경
	const ChangeOrder = async () => {
		setProgress(true);
		let userInfo = null;
		let changeArr = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});
			listData.forEach((item, index) => {
				changeArr.push({
					itemNo: item.id,
					itemOrder: item.order + "",
					userId: userInfo.userId,
				});
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Home.ChangeOrder,
				changeArr
			).then((result) => {
				let data = result.data;

				if (data === "Error") {
					setSnacks({
						...snacks,
						open: true,
						type: "error",
					});
				} else if (data === "데이터를 확인하세요.") {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: "" // 데이터를 확인하세요.
					);
				} else {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_saved"
					// 			)[0].lanName
					// 			: "", // 저장 되었습니다!
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_saved"
								)[0].lanName
								: "", // 저장 되었습니다!
					});
					// Init();
					navigate("/Home");
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
			}
		} finally {
			setProgress(false);
		}
	};

	const MovePage = (_page) => {
		navigate("/" + _page);
	};

	return (
		<div className="wrap">
			<SwappingView
				// 언어팩
				lanList={lanList}
				listData={listData}
				setListData={setListData}
				Init={Init}
				ChangeOrder={ChangeOrder}
				MovePage={MovePage}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default Swapping;
