import React from "react";
/** Components Import */
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
	return (
		<MuiAlert
			sx={props.sx}
			elevation={6}
			ref={ref}
			variant="filled"
			{...props}
		/>
	);
});

function BasicSnackbar({
	sx = {}, // mui inline-style object
	type = "info", // "success", "info", "warning", "error"
	position = { vertical: "top", horizontal: "center" }, // horizontal: 'center' | 'left' | 'right', vertical: 'bottom' | 'top'
	open = false, // snackbar's open
	onClose = () => {}, // snackbar's close event function
	duration = 3000, //snackbar's duration
	message = "Snackbar message.", // snackbar's message
}) {
	const CloseSnackbar = () => {
		onClose();
	};

	return (
		<Snackbar
			open={open}
			onClose={CloseSnackbar}
			autoHideDuration={duration}
			anchorOrigin={position}
		>
			<Alert sx={sx} severity={type}>
				{message}
			</Alert>
		</Snackbar>
	);
}

export default BasicSnackbar;
