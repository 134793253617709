import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
import dayjs from "dayjs";
/** Components */
import { Unstable_Grid2 as Grid, Tabs, Tab } from "@mui/material";
import {
	SettingItem,
	SettingList,
	AlarmSettingList,
} from "../../Components/Conotec";
import { Dialog } from "../../Components/Dialog";
import { Combo } from "../../Components/Combos";
import { Input } from "../../Components/Inputs";
import { Alert } from "../../Components/Alert";
import { Checkbox } from "../../Components/Checkboxs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
/** Icons Import */
/** Images Import */

/** 설정선택 홈 */

function SM10010(props) {
	const [tabValue, setTabValue] = React.useState(0);
	const [changeToggle, setChangeToggle] = React.useState({
		open: false,
		mapNm: "",
		message: "",
		inputType: "",
		inputs: [],
		min: 0,
		max: 0,
		min1: 0,
		max1: 0,
		beforeValue: "",
		mapAdd: "",
		mapUnit: "",
		showValue: "",
		value: "",
		value1: "",
		value2: "",
	}); // 각 레지스터의 값 구분을 위한 데이터
	const [message, setMessage] = React.useState({
		toggle: false,
		message: "",
		type: "info",
	}); // 예외 처리 경고용 데이터
	const [checked, setChecked] = React.useState(false); // 60분 체크박스 선택 토글

	// 탭 선택 토글 함수
	const ChangeTabs = (event, newValue) => {
		setTabValue(newValue);
	};

	// 현재 온도 단위가 C일 때의 패턴
	const CheckPatternC = (e) => {
		const pattern0 = /[^-0-9\.]/g; // 숫자, ., -만 허용
		const pattern1 = /^\.-/g; // .- 방지
		const pattern2 = /^-\./g; // -. 방지
		const pattern3 = /^\.\d+/g; // .숫자 방지
		const pattern4 = /\d+-/g; // 숫자- 방지
		const pattern5 = /\.[0-9]*\./g; // .숫자. 방지
		const pattern6 = /--/g; // -- 방지
		const pattern7 = /\.\./g; // ..의 경우 -로 변경

		// console.log("e : ", e);

		if (pattern7.test(e)) {
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern7, "-"),
			});
		} else if (pattern0.test(e)) {
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern0, ""),
			});
		} else if (pattern1.test(e)) {
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern1, ""),
			});
		} else if (pattern2.test(e)) {
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern2, ""),
			});
		} else if (pattern3.test(e)) {
			setChangeToggle({
				...changeToggle,
				value: e.replace(".", ""),
			});
		} else if (pattern4.test(e)) {
			setChangeToggle({
				...changeToggle,
				value: e.slice(0, -2),
			});
		} else if (pattern5.test(e)) {
			setChangeToggle({
				...changeToggle,
				value: e.slice(0, -1),
			});
		} else if (pattern6.test(e)) {
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern6, ""),
			});
		} else {
			setChangeToggle({
				...changeToggle,
				value: e,
			});
		}
	};

	// 현재 온도 단위가 F 일 때의 패턴
	const CheckPatternF = (e) => {
		const pattern0 = /[^-0-9\.]/g; // 숫자, -만 허용
		const pattern1 = /\d+-/g; // 숫자- 방지
		const pattern2 = /--/g; // -- 방지
		const pattern3 = /\.\./g; // ..의 경우 -로 변경

		// .. 이외의 모든 . 사용 방지
		const pattern4 = /^\.-/g; // .- 방지
		const pattern5 = /^-\./g; // -. 방지
		const pattern6 = /\.[0-9]+/g; // .숫자 방지
		const pattern7 = /[0-9]+\.[0-9]*/g; // 숫자.숫자 방지

		if (pattern3.test(e)) {
			// console.log("e.replace(pattern3, ) : ", e.replace(pattern3, "-"));
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern3, "-"),
			});
		} else if (pattern0.test(e)) {
			// console.log("0");
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern0, ""),
			});
		} else if (pattern1.test(e)) {
			// console.log("1");
			setChangeToggle({
				...changeToggle,
				value: e.slice(0, -2),
			});
		} else if (pattern2.test(e)) {
			// console.log("2");
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern2, ""),
			});
		} else if (pattern4.test(e)) {
			// console.log("4");
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern4, ""),
			});
		} else if (pattern5.test(e)) {
			// console.log("5");
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern5, ""),
			});
		} else if (pattern6.test(e)) {
			// console.log("6");
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern6, ""),
			});
		} else if (pattern7.test(e)) {
			// console.log("7");
			setChangeToggle({
				...changeToggle,
				value: e.replace(pattern7, ""),
			});
		} else {
			setChangeToggle({
				...changeToggle,
				value: e,
			});
		}
	};

	// 설정값 변경 탭에서 값을 변경할 때 보여줄 컴포넌트
	const ReturnComponents = (_item) => {
		switch (_item.mapUnit) {
			case "분초":
				let defaultValue = dayjs(
					Commons.DateFormating(new Date(), 2) +
					"T00:" +
					_item.value1.toString().padStart(2, "0") +
					":" +
					_item.value2.toString().padStart(2, "0")
				);

				return (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<MobileTimePicker
								label={
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"sm10000_pop_min_sec"
										)[0].lanName
										: "" // "분:초"
								}
								openTo="minutes"
								views={["minutes", "seconds"]}
								format="mm:ss"
								defaultValue={defaultValue}
								onChange={(e) => {
									setChangeToggle({
										..._item,
										value: "timepicker",
										value1: e.minute(),
										value2: e.second(),
									});
								}}
								disabled={checked ? true : false}
							/>
						</LocalizationProvider>
						<Checkbox
							size="large"
							name={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"sm10000_pop_60minutes"
									)[0].lanName
									: "" // 60분
							}
							value={checked}
							onChange={(e) => {
								setChecked(e);
								if (e) {
									setChangeToggle({
										..._item,
										value: "timepicker",
										value1: "60",
										value2: "0",
									});
								}
							}}
						/>
					</div>
				);
			case "분":
				return (
					<Input
						fullWidth
						type="outlined"
						placeholder={_item.beforeValue}
						value={_item.value}
						inputMode="numeric"
						inputMode1="numeric"
						// inputProps={{ inputMode: "numeric" }}
						onChange={(e) => {
							if (Number(e) < 0 || isNaN(e)) {
								// setChangeToggle({
								// 	...changeToggle,
								// 	value: 0,
								// });
							} else if (e.indexOf(".") !== -1) {
								setChangeToggle({
									...changeToggle,
									value: 0,
								});
							} else if (e.indexOf("-") !== -1) {
								setChangeToggle({
									...changeToggle,
									value: 0,
								});
							} else {
								setChangeToggle({
									...changeToggle,
									value: Number(e),
								});
							}
						}}
						helperText={
							_item.message
						}
					/>
				);
			default:
				return (
					<Input
						fullWidth
						type="outlined"
						placeholder={_item.beforeValue}
						value={_item.value}
						inputMode="text"
						// inputProps={{ inputMode: "numeric" }}
						onChange={(e) => {
							// tempUnit이 0이면 섭씨(C), 1이면 화씨(F)
							// console.log("props.controllerData[0].tempUnit : ", props.controllerData[0].tempUnit);
							if (props.controllerData[0].tempUnit === "0")
								CheckPatternC(e);
							else CheckPatternF(e);
						}}
						helperText={
							// _item.mapUnit !== "℃/℉"
							// 	? props.lanList.length > 0
							// 		? props.lanList.filter(
							// 			(f) =>
							// 				f.lanId === "sm10000_pop_hint"
							// 		)[0].lanName
							// 		: "" // (*마침표 2번 입력시 음수 표시됨)
							// 	: ""
							_item.message
						}
					/>
				);
		}
	};

	// 알림 설정에서 보여줄 알림값 변경 (알림 On/Off)
	const ChangeSwitch = (_type, _value) => {
		switch (_type) {
			case "temp":
				return props.setAlarmInputs({
					...props.alarmInputs,
					tmpArYn: _value,
				});
			case "def":
				return props.setAlarmInputs({
					...props.alarmInputs,
					defHgArYn: _value,
				});
			case "damp":
				return props.setAlarmInputs({
					...props.alarmInputs,
					hmdArYn: _value,
				});
			case "ill":
				return props.setAlarmInputs({
					...props.alarmInputs,
					illArYn: _value,
				});
			case "co2":
				return props.setAlarmInputs({
					...props.alarmInputs,
					co2ArYn: _value,
				});
			case "retry":
				return props.setAlarmInputs({
					...props.alarmInputs,
					retryYn: _value,
				});
		}
	};

	// 알림 설정에서 보여줄 알림값 변경 (최대/최소)
	const ChangeInput = (_type, _type1, _value) => {
		switch (_type) {
			case "temp":
				if (_type1 === "min") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						tmpMinVal: _value,
					});
				} else if (_type1 === "max") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						tmpMaxVal: _value,
					});
				} else if (_type1 === "checkTime") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						tmpCheckTime: _value,
					});
				}
			case "damp":
				if (_type1 === "min") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						hmdMinVal: _value,
					});
				} else if (_type1 === "max") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						hmdMaxVal: _value,
					});
				}
			case "ill":
				if (_type1 === "min") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						illMinVal: _value,
					});
				} else if (_type1 === "max") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						illMaxVal: _value,
					});
				}
			case "co2":
				if (_type1 === "min") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						co2MinVal: _value,
					});
				} else if (_type1 === "max") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						co2MaxVal: _value,
					});
				}
			case "checkTotalTime":
				if (_type1 === "checkTotalTime") {
					return props.setAlarmInputs({
						...props.alarmInputs,
						checkTotalTime: _value,
					});
				}
		}
	};

	const ChangeBitsSwitch = (vlaue, index) => {
		let copy = [...props.bitsSwitchList];
		copy[index] = vlaue;
		props.setBitsSwitchList(copy);
	};

	return (
		<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
			<SettingItem
				items={props.controllerData}
				onChange={(e) => {
					props.setControllerData([
						{
							...props.controllerData[0],
							status: e ? "on" : "off",
						},
					]);
				}}
			/>
			<Grid xs={4} md={12}>
				<Tabs
					value={tabValue}
					onChange={ChangeTabs}
					variant="fullWidth"
					aria-label="basic tabs example"
				>
					<Tab
						label={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"sm10000_tab_change_settings"
								)[0].lanName
								: "" // 설정값 변경
						}
						value={0}
					/>
					<Tab
						label={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"sm10000_tab_alarm_settings"
								)[0].lanName
								: "" // 알림 설정
						}
						value={1}
					/>
				</Tabs>
			</Grid>
			{tabValue === 0 ? (
				<SettingList
					items={props.settingList}
					lanList={props.lanList}
					onClick={(_item) => {
						setChangeToggle({
							open: true,
							..._item,
						});
					}}
				/>
			) : (
				<AlarmSettingList
					items={props.alarmList}
					userLanCode={props.userLanCode}
					lanList={props.lanList}
					alarmInputs={props.alarmInputs}
					setAlarmInputs={props.setAlarmInputs}
					bitsList={props.bitsList}
					bitsSwitchList={props.bitsSwitchList}
					setBitsSwitchList={props.setBitsSwitchList}
					ChangeBitsSwitch={ChangeBitsSwitch}
					ChangeSwitch={ChangeSwitch}
					ChangeInput={ChangeInput}
					onSave={props.ChangeAlarm}
				/>
			)}

			{/* 설정 값 변경 팝업 */}
			<Dialog
				fullWidth
				open={changeToggle.open}
				title={changeToggle.mapNm}
				onClose={() => {
					setChangeToggle({
						...changeToggle,
						open: false,
						mapNm: "",
						message: "",
						inputType: "",
						inputs: [],
						min: 0,
						max: 0,
						min1: 0,
						max1: 0,
						beforeValue: "",
						mapAdd: "",
						mapUnit: "",
						showValue: "",
						value: "",
						value1: "",
						value2: "",
					});
					setMessage({
						toggle: false,
						message: "",
						type: "error",
					});
					setChecked(false);
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10080_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onSave={() => {
					if (changeToggle.value === "") {
						// 입력값이 없는 경우
						setMessage({
							toggle: true,
							message:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId === "snack_enter_value"
									)[0].lanName
									: "", // 값을 입력하세요.
							type: "error",
						});
					} else if (changeToggle.value === "timepicker") {
						// 입력 값이 분 + 초 인경우
						// console.log(changeToggle.value1, changeToggle.value2);
						props.SaveValue(
							changeToggle.mapAdd,
							changeToggle.value1
						);
						props.SaveValue(
							(changeToggle.mapAdd * 1 + 1).toString(),
							changeToggle.value2
						);
						setChangeToggle({
							...changeToggle,
							open: false,
							mapNm: "",
							message: "",
							inputType: "",
							inputs: [],
							min: 0,
							max: 0,
							min1: 0,
							max1: 0,
							beforeValue: "",
							mapAdd: "",
							mapUnit: "",
							showValue: "",
							value: "",
							value1: "",
							value2: "",
						});
						setMessage({
							toggle: false,
							message: "",
							type: "error",
						});
						setChecked(false);
					} else {
						props.CheckTempUnit(
							changeToggle.mapAdd,
							changeToggle.value
						);
						setChangeToggle({
							...changeToggle,
							open: false,
							mapNm: "",
							message: "",
							inputType: "",
							inputs: [],
							min: 0,
							max: 0,
							min1: 0,
							max1: 0,
							beforeValue: "",
							mapAdd: "",
							mapUnit: "",
							showValue: "",
							value: "",
							value1: "",
							value2: "",
						});
						setMessage({
							toggle: false,
							message: "",
							type: "error",
						});
						setChecked(false);
					}
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "sm10000_button_save"
						)[0].lanName
						: "" // 확인
				}
				useSaveAfterClose={false}
			>
				<div
					style={{
						marginBottom: "30px",
						marginLeft: "5px",
						fontSize: "23px",
					}}
				>
					{changeToggle.message}
				</div>
				{changeToggle.inputType === "1" ? (
					<div>{ReturnComponents(changeToggle)}</div>
				) : (
					<div>
						<Combo
							fullWidth
							type="outlined"
							name={changeToggle.mapNm}
							value={changeToggle.value}
							items={changeToggle.inputs}
							onChange={(e) => {
								setChangeToggle({
									...changeToggle,
									value: e,
								});
							}}
						/>
					</div>
				)}
				{message.toggle && (
					<Alert
						sx={{ mt: 3, fontSize: 18 }}
						noteType={message.type}
						name={message.message}
						onClose={() => {
							setMessage({
								...message,
								toggle: false,
							});
						}}
					/>
				)}
			</Dialog>
		</Grid>
	);
}

export default SM10010;
