import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components Import */
import LM10010View from "../../Views/LogManagements/LM10010";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
import { format } from "date-fns";

function LM10010() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [codeList, setCodeList] = React.useState([]); // 기초코드 리스트
	const [machNames, setMachNames] = React.useState([]); // Get4100Data.machNames
	const [rowData, setRowData] = React.useState([]);
	const [columnData, setColumnData] = React.useState([]);
	const [fromDate, setFromDate] = React.useState(new Date());
	const [toDate, setToDate] = React.useState(new Date());
	const [chartData, setChartData] = React.useState({
		categories: [],
		data: [],
		groups: [],
	});
	const [dateList, setDateList] = React.useState([]);	// 그리드 보기의 날짜 리스트 저장
	const [curTmp, setCurTmp] = React.useState([]);
	const [curHdm, setCurHdm] = React.useState([]);
	const [curIll, setCurIll] = React.useState([]);
	const [curCo2, setCurCo2] = React.useState([]);
	const [comboValue, setComboValue] = React.useState([]); // 그리드 보기의 AutoCombo에서 선택된 값
	const [comboItems, setComboItems] = React.useState([]); // 그리드 보기의 AutoCombo에서 보여주는 목록

	const [itemTypeValue, setItemTypeValue] = React.useState(""); // 차트 보기의 Combo에서 선택된 값
	const [itemTypeItems, setItemTypeItems] = React.useState([]); // 차트 보기의 Combo에서 보여주는 목록

	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		GetController();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
	}, []);

	// 컨트롤러 데이터 조회
	const GetController = async () => {
		setProgress(true);
		let userInfo = null;
		let tempListData = [];
		let lans = [];
		let search = true;

		try {
			// 컨트롤러 데이터 조회
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					setLanList(result);
					result.forEach((item) => lans.push(item));
				}
			});


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "LM10010").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.Home,
				{
					userId: userInfo.userId,
					type: Commons.Storages("get", "settings").server,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: "")
					);
				} else if (data === "등록된 장치가 없습니다.") {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId === "snack_no_device_registered"
							)[0].lanName
							: "")
					);
				} else if (data === false) {
					throw new Error(data);
				} else {
					setMachNames(data.machNames);

					data.machNames.forEach((item) => {
						if (item.itemType !== "7" && item.itemType !== "6") {
							tempListData.push({
								value: item.itemNo,
								name: item.machNm,
							});
						}
					});

					return setComboItems(tempListData);
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""}
								{/* 로그인 정보가 만료되었습니다. */}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_login_again"
									)[0].lanName
									: ""}
								{/* 다시 로그인 해 주세요. */}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 현재 온도값에 대한 리턴값 정의 (그리드)
	const returnValue = (value) => {
		switch (value) {
			case 999: return "N/S";		// 미접속
			case 998: return "";		// 사용하지 않는 항목
			case -999.9: return "S/E";	// 센서 에러
			default: return value;		// 일반 온도
		}
	};

	// 일자별 조회
	const BetweenDate = async () => {
		setProgress(true);
		let userInfo = null;
		let itemArr = "(";
		let temp = [];	// data.tbTrdTmpYyyys 중복 제거
		let tempArr = [];
		let tempDateList = [];	// 날짜 목록 담아두는 임시 변수
		let tempChartArr = [];
		let tmpSeriesArr = [];
		let hdmSeriesArr = [];
		let illSeriesArr = [];
		let co2SeriesArr = [];
		let numStr = '{"field": "num", "headerName": "연번", "minWidth": 80}';
		let createDateStr =
			'{"field": "CreateDate", "headerName": "생성일자", "minWidth": 180}';
		let columnDefs = [];
		let pivot = [];
		let tempItemTypeList = new Set();
		let tempList = [];
		let tempListItem = "1";

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			// db 조회용 컨트롤러 목록 제작
			comboValue.forEach((item, index) => {
				itemArr += item.value;
				if (index !== comboValue.length - 1) {
					itemArr += ",";
				} else {
					itemArr += ")";
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10010.GetBetween,
				{
					years: toDate.getFullYear(),
					userId: userInfo.userId,
					fromDate:
						Commons.DateToString(fromDate, 3).slice(0, 8) +
						"000000",
					toDate:
						Commons.DateToString(toDate, 3).slice(0, 8) + "235959",
					itemNo: itemArr,
				}
			).then((result) => {
				let data = result.data;
				// console.log("Datga : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: "")
					);
				} else if (data) {
					// 그리드용 데이터 제작

					tempDateList = data.chartModels.xaxis.categories;

					// 가끔 발생하는 로그 중복 제거
					temp = data.tbTrdTmpYyyys.reduce((prev, now) => {
						if (!prev.some((obj) => obj.CreateDate === now.CreateDate && obj.ItemNo === now.ItemNo)) {
							prev.push(now);
						}
						return prev;
					}, []);

					// data.tbTrdTmpYyyys.reverse().forEach((item, index) => {	// 로그 중복 제거 전의 로직
					temp.reverse().forEach((item, index) => {
						// if (
						// 	item.CurTmp < 999 && item.CurTmp > -999 &&
						// 	item.CurHdm < 999 && item.CurHdm > -999 &&
						// 	item.CurIll < 999 && item.CurIll > -999 &&
						// 	item.CurCo2 < 999 && item.CurCo2 > -999
						// ) {
						tempArr.push({
							num: index + 1,
							itemNo: item.ItemNo + "",
							itemNm: comboItems.filter(
								(_item) => _item.value === item.ItemNo + ""
							)[0].name,
							itemType: machNames.filter(
								(_item) => _item.itemNo === item.ItemNo + ""
							)[0].itemType,
							curTmp: returnValue(item.CurTmp),
							curHdm: returnValue(item.CurHdm),
							curIll: returnValue(item.CurIll),
							curCo2: returnValue(item.CurCo2),
							createDate:
								item.CreateDate.slice(0, 4) +
								"-" +
								item.CreateDate.slice(4, 6) +
								"-" +
								item.CreateDate.slice(6, 8) +
								" " +
								item.CreateDate.slice(8, 10) +
								":" +
								item.CreateDate.slice(10, 12),
							checkDate: item.CreateDate.slice(2, 12),
						});
						// }
					});

					tempArr.sort(
						(a, b) =>
							new Date(a.createDate) - new Date(b.createDate) ||
							a.itemNo - b.itemNo
					);

					// 차트용 카테고리 제작
					data.chartModels.xaxis.categories
						.sort()
						// .reduce((prev, now) => prev.includes(now) ? prev : [...prev, now], [])
						.forEach((item) => {
							tempChartArr.push(
								format(
									new Date(
										"20" +
										item.slice(0, 2) +
										"-" +
										item.slice(2, 4) +
										"-" +
										item.slice(4, 6) +
										" " +
										item.slice(6, 8) +
										":" +
										item.slice(8, 10)
									),
									"yy-MM-dd HH:mm"
								)
							);
						});

					// 차트용 데이터 제작
					data.chartModels.series.forEach((item) => {
						let itemInfo = machNames.filter(
							(_item) => _item.itemNo === item.name + ""
						)[0];

						switch (itemInfo.itemType) {
							case "1": {
								tmpSeriesArr.push({
									name: itemInfo.machNm,
									data: item.tmpData,
								});
								break;
							}
							case "2": {
								hdmSeriesArr.push({
									name: itemInfo.machNm,
									data: item.hdmData,
								});
								break;
							}
							case "3": {
								tmpSeriesArr.push({
									name: itemInfo.machNm,
									data: item.tmpData,
								});
								hdmSeriesArr.push({
									name: itemInfo.machNm,
									data: item.hdmData,
								});
								break;
							}
							case "4": {
								tmpSeriesArr.push({
									name: itemInfo.machNm,
									data: item.tmpData,
								});
								hdmSeriesArr.push({
									name: itemInfo.machNm,
									data: item.hdmData,
								});
								illSeriesArr.push({
									name: itemInfo.machNm,
									data: item.illData,
								});
								break;
							}
							case "5": {
								tmpSeriesArr.push({
									name: itemInfo.machNm,
									data: item.tmpData,
								});
								hdmSeriesArr.push({
									name: itemInfo.machNm,
									data: item.hdmData,
								});
								illSeriesArr.push({
									name: itemInfo.machNm,
									data: item.illData,
								});
								co2SeriesArr.push({
									name: itemInfo.machNm,
									data: item.co2Data,
								});
								break;
							}
						}
					});

					setCurTmp(tmpSeriesArr);
					setCurHdm(hdmSeriesArr);
					setCurIll(illSeriesArr);
					setCurCo2(co2SeriesArr);

					// 차트 보기의 combo items 제작
					comboValue.forEach((item) => {
						tempItemTypeList.add(
							Number(
								machNames.filter(
									(_item) => _item.itemNo === item.value
								)[0].itemType
							)
						);
					});

					switch (Math.max(...tempItemTypeList)) {
						case 1: {
							tempList.push({
								value: "1",
								name: "온도",
							});
							break;
						}
						case 2: {
							if (tempItemTypeList.has(1)) {
								tempList.push(
									{
										value: "1",
										name: "온도",
									},
									{
										value: "2",
										name: "습도",
									}
								);
							} else {
								tempList.push({
									value: "2",
									name: "습도",
								});
								tempListItem = "2";
							}
							break;
						}
						case 3: {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								}
							);
							break;
						}
						case 4: {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								},
								{
									value: "3",
									name: "CO2",
								}
							);
							break;
						}
						case 5: {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								},
								{
									value: "3",
									name: "CO2",
								},
								{
									value: "4",
									name: "조도",
								}
							);
							break;
						}
					}

					setItemTypeValue(tempListItem);
					setItemTypeItems(tempList);

					// 그리드 컬럼 헤더 제작
					columnDefs.push(JSON.parse(numStr));
					columnDefs.push(JSON.parse(createDateStr));
					for (let i = 0; i < comboValue.length; i++) {
						let createStr =
							'{"headerName": "' +
							comboValue[i].name +
							'", "children": [';
						switch (
						machNames.filter(
							(item) => item.machNm === comboValue[i].name
						)[0].itemType
						) {
							case "1": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curTmp", "headerName":"온도"}';
								break;
							}
							case "2": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curHdm", "headerName":"습도"}';
								break;
							}
							case "3": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curTmp", "headerName":"온도"}, {"field": "' +
									comboValue[i].value +
									'-curHdm", "headerName":"습도"}';
								break;
							}
							case "4": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curTmp", "headerName":"온도"}, {"field": "' +
									comboValue[i].value +
									'-curHdm", "headerName":"습도"}, {"field": "' +
									comboValue[i].value +
									'-curIll", "headerName":"조도"}';
								break;
							}
							case "5": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curTmp", "headerName":"온도"}, {"field": "' +
									comboValue[i].value +
									'-curHdm", "headerName":"습도"}, {"field": "' +
									comboValue[i].value +
									'-curIll", "headerName":"조도"}, {"field": "' +
									comboValue[i].value +
									'-curCo2", "headerName":"CO2"}';
								break;
							}
						}
						createStr += "]}";
						columnDefs.push(JSON.parse(createStr));
					}

					let count = 0;
					// 그리드 데이터 row 제작
					for (let i = 0; i < tempDateList.length; i++) {
						let createStr =
							'{"num": "' +
							(i * 1 + 1) +
							'", "CreateDate": "' + "20" +
							tempDateList[i].slice(0, 2) +
							"-" +
							tempDateList[i].slice(2, 4) +
							"-" +
							tempDateList[i].slice(4, 6) +
							" " +
							tempDateList[i].slice(6, 8) +
							":" +
							tempDateList[i].slice(8, 10) +
							'", ';

						for (let j = 0; j < comboValue.length; j++) {
							if (tempArr[count].itemNm === comboValue[j].name && tempArr[count].checkDate === tempDateList[i]) {
								createStr +=
									'"' +
									tempArr[count].itemNo +
									'-curTmp": "' +
									tempArr[count].curTmp +
									'", "' +
									tempArr[count].itemNo +
									'-curHdm": "' +
									tempArr[count].curHdm +
									'", "' +
									tempArr[count].itemNo +
									'-curIll": "' +
									tempArr[count].curIll +
									'", "' +
									tempArr[count].itemNo +
									'-curCo2": "' +
									tempArr[count].curCo2 +
									'", ';
								if (count !== tempArr.length - 1) {
									count += 1;
								}
							}

							if (j === comboValue.length - 1) {
								let tempCreateStr = createStr.slice(0, -2);
								tempCreateStr += "}";
								createStr = "";
								pivot.push(JSON.parse(tempCreateStr));
							}
						}
					}

					// 기존 로직
					// for (let i = 0; i < tempArr.length; i++) {
					// 	let createStr =
					// 		'{"num": "' +
					// 		(i * 1 + 1) +
					// 		'", "CreateDate": "' +
					// 		tempArr[i].createDate +
					// 		'", ';

					// 	for (let j = 0; j < comboValue.length; j++) {
					// 		if (tempArr[i].itemNm === comboValue[j].name && tempArr[i].checkDate === data.chartModels.xaxis.categories[count]) {
					// 			createStr +=
					// 				'"' +
					// 				tempArr[i].itemNo +
					// 				'-curTmp": "' +
					// 				tempArr[i].curTmp +
					// 				'", "' +
					// 				tempArr[i].itemNo +
					// 				'-curHdm": "' +
					// 				tempArr[i].curHdm +
					// 				'", "' +
					// 				tempArr[i].itemNo +
					// 				'-curIll": "' +
					// 				tempArr[i].curIll +
					// 				'", "' +
					// 				tempArr[i].itemNo +
					// 				'-curCo2": "' +
					// 				tempArr[i].curCo2 +
					// 				'", ';
					// 			if (i !== tempArr.length - 1) {
					// 				i += 1;
					// 			}
					// 		}

					// 		if (j === comboValue.length - 1) {
					// 			let tempCreateStr = createStr.slice(0, -2);
					// 			tempCreateStr += "}";
					// 			createStr = "";
					// 			pivot.push(JSON.parse(tempCreateStr));

					// 			count++;
					// 		}
					// 	}

					// 	if (i !== tempArr.length - 1) {
					// 		i -= 1;
					// 	} else {
					// 		// 로그가 2개인 경우, 2번째 로그는 로직상 잘리는 것을 막기 위해 추가됨.
					// 		// 로그가 1개인 경우, 동일한 로그를 다시 삽입하는 것을 막기 위해 추가됨.
					// 		if (comboValue.length === 1) {
					// 			let createStr =
					// 				'{"num": "' +
					// 				(i * 1 + 1) +
					// 				'", "CreateDate": "' +
					// 				tempArr[i].createDate +
					// 				'", '
					// 				+
					// 				'"' +
					// 				tempArr[i].itemNo +
					// 				'-curTmp": "' +
					// 				tempArr[i].curTmp +
					// 				'", "' +
					// 				tempArr[i].itemNo +
					// 				'-curHdm": "' +
					// 				tempArr[i].curHdm +
					// 				'", "' +
					// 				tempArr[i].itemNo +
					// 				'-curIll": "' +
					// 				tempArr[i].curIll +
					// 				'", "' +
					// 				tempArr[i].itemNo +
					// 				'-curCo2": "' +
					// 				tempArr[i].curCo2 +
					// 				'", ';

					// 			let tempCreateStr = createStr.slice(0, -2);
					// 			tempCreateStr += "}";
					// 			createStr = "";
					// 			pivot.push(JSON.parse(tempCreateStr));
					// 		} 
					// 	}
					// }

					let tempPivot = [];
					pivot.reverse().forEach((item, index) => {
						tempPivot.push({
							...item,
							num: index + 1,
						});
					});

					setRowData(tempPivot);
					setColumnData(columnDefs);
					if (tempListItem === "2") {
						setChartData({
							categories: tempChartArr,
							data: hdmSeriesArr,
							groups: data.chartModels.groups,
						});
					} else {
						setChartData({
							categories: tempChartArr,
							data: tmpSeriesArr,
							groups: data.chartModels.groups,
						});
					}
				} else {
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "") // 조회 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 2년치 일자별 조회
	const GetBetweenTwoYear = async () => {
		setProgress(true);
		let userInfo = null;
		let itemArr = "(";
		let temp = [];	// data.tbTrdTmpYyyys 중복 제거
		let tempArr = [];
		let tempChartArr = [];
		let tmpSeriesArr = [];
		let hdmSeriesArr = [];
		let illSeriesArr = [];
		let co2SeriesArr = [];
		let numStr = '{"field": "num", "headerName": "연번", "minWidth": 80}';
		let createDateStr =
			'{"field": "CreateDate", "headerName": "생성일자", "minWidth": 180}';
		let columnDefs = [];
		let pivot = [];
		let tempItemTypeList = new Set();
		let tempList = [];
		let tempListItem = "1";

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			comboValue.forEach((item, index) => {
				itemArr += item.value;
				if (index !== comboValue.length - 1) {
					itemArr += ",";
				} else {
					itemArr += ")";
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10010.GetBetweenTwoYear,
				{
					fromYears: fromDate.getFullYear(),
					toYears: toDate.getFullYear(),
					userId: userInfo.userId,
					fromDate:
						Commons.DateToString(fromDate, 3).slice(0, 8) +
						"000000",
					toDate:
						Commons.DateToString(toDate, 3).slice(0, 8) + "235959",
					itemNo: itemArr,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: "")
					);
				} else if (data) {
					// 그리드용 데이터 제작

					// 가끔 발생하는 로그 중복 제거
					temp = data.tbTrdTmpYyyys.reduce((prev, now) => {
						if (!prev.some((obj) => obj.CreateDate === now.CreateDate && obj.ItemNo === now.ItemNo)) {
							prev.push(now);
						}
						return prev;
					}, []);

					// data.tbTrdTmpYyyys.reverse().forEach((item, index) => {	// 로그 중복 제거 전의 로직
					temp.reverse().forEach((item, index) => {
						// if (
						// 	item.CurTmp < 999 && item.CurTmp > -999 &&
						// 	item.CurHdm < 999 && item.CurHdm > -999 &&
						// 	item.CurIll < 999 && item.CurIll > -999 &&
						// 	item.CurCo2 < 999 && item.CurCo2 > -999
						// ) {
						tempArr.push({
							num: index + 1,
							itemNo: item.ItemNo + "",
							itemNm: comboItems.filter(
								(_item) => _item.value === item.ItemNo + ""
							)[0].name,
							itemType: machNames.filter(
								(_item) => _item.itemNo === item.ItemNo + ""
							)[0].itemType,
							curTmp: returnValue(item.CurTmp),
							curHdm: returnValue(item.CurHdm),
							curIll: returnValue(item.CurIll),
							curCo2: returnValue(item.CurCo2),
							createDate:
								item.CreateDate.slice(0, 4) +
								"-" +
								item.CreateDate.slice(4, 6) +
								"-" +
								item.CreateDate.slice(6, 8) +
								" " +
								item.CreateDate.slice(8, 10) +
								":" +
								item.CreateDate.slice(10, 12),
						});
						// }
					});

					tempArr.sort(
						(a, b) =>
							new Date(a.createDate) - new Date(b.createDate) ||
							a.itemNo - b.itemNo
					);

					// 차트용 카테고리 제작
					data.chartModels.xaxis.categories
						.sort()
						// .reduce((prev, now) => prev.includes(now) ? prev : [...prev, now], [])
						.forEach((item) => {
							tempChartArr.push(
								format(
									new Date(
										"20" +
										item.slice(0, 2) +
										"-" +
										item.slice(2, 4) +
										"-" +
										item.slice(4, 6) +
										" " +
										item.slice(6, 8) +
										":" +
										item.slice(8, 10)
									),
									"yy-MM-dd HH:mm"
								)
							);
						});

					// 차트용 데이터 제작
					data.chartModels.series.forEach((item) => {
						let itemInfo = machNames.filter(
							(_item) => _item.itemNo === item.name + ""
						)[0];
						switch (itemInfo.itemType) {
							case "1": {
								tmpSeriesArr.push({
									name: itemInfo.machNm,
									data: item.tmpData,
								});
								break;
							}
							case "2": {
								hdmSeriesArr.push({
									name: itemInfo.machNm,
									data: item.hdmData,
								});
								break;
							}
							case "3": {
								tmpSeriesArr.push({
									name: itemInfo.machNm,
									data: item.tmpData,
								});
								hdmSeriesArr.push({
									name: itemInfo.machNm,
									data: item.hdmData,
								});
								break;
							}
							case "4": {
								tmpSeriesArr.push({
									name: itemInfo.machNm,
									data: item.tmpData,
								});
								hdmSeriesArr.push({
									name: itemInfo.machNm,
									data: item.hdmData,
								});
								illSeriesArr.push({
									name: itemInfo.machNm,
									data: item.illData,
								});
								break;
							}
							case "5": {
								tmpSeriesArr.push({
									name: itemInfo.machNm,
									data: item.tmpData,
								});
								hdmSeriesArr.push({
									name: itemInfo.machNm,
									data: item.hdmData,
								});
								illSeriesArr.push({
									name: itemInfo.machNm,
									data: item.illData,
								});
								co2SeriesArr.push({
									name: itemInfo.machNm,
									data: item.co2Data,
								});
								break;
							}
						}
					});

					setCurTmp(tmpSeriesArr);
					setCurHdm(hdmSeriesArr);
					setCurIll(illSeriesArr);
					setCurCo2(co2SeriesArr);

					// 차트 보기의 combo items 제작
					comboValue.forEach((item) => {
						tempItemTypeList.add(
							Number(
								machNames.filter(
									(_item) => _item.itemNo === item.value
								)[0].itemType
							)
						);
					});

					switch (Math.max(...tempItemTypeList)) {
						case 1: {
							tempList.push({
								value: "1",
								name: "온도",
							});
							break;
						}
						case 2: {
							if (tempItemTypeList.has(1)) {
								tempList.push(
									{
										value: "1",
										name: "온도",
									},
									{
										value: "2",
										name: "습도",
									}
								);
							} else {
								tempList.push({
									value: "2",
									name: "습도",
								});
								tempListItem = "2";
							}
							break;
						}
						case 3: {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								}
							);
							break;
						}
						case 4: {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								},
								{
									value: "3",
									name: "CO2",
								}
							);
							break;
						}
						case 5: {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								},
								{
									value: "3",
									name: "CO2",
								},
								{
									value: "4",
									name: "조도",
								}
							);
							break;
						}
					}

					setItemTypeValue(tempListItem);
					setItemTypeItems(tempList);

					// 그리드 컬럼 헤더 제작
					columnDefs.push(JSON.parse(numStr));
					columnDefs.push(JSON.parse(createDateStr));
					for (let i = 0; i < comboValue.length; i++) {
						let createStr =
							'{"headerName": "' +
							comboValue[i].name +
							'", "children": [';
						switch (
						machNames.filter(
							(item) => item.machNm === comboValue[i].name
						)[0].itemType
						) {
							case "1": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curTmp", "headerName":"온도"}';
								break;
							}
							case "2": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curHdm", "headerName":"습도"}';
								break;
							}
							case "3": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curTmp", "headerName":"온도"}, {"field": "' +
									comboValue[i].value +
									'-curHdm", "headerName":"습도"}';
								break;
							}
							case "4": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curTmp", "headerName":"온도"}, {"field": "' +
									comboValue[i].value +
									'-curHdm", "headerName":"습도"}, {"field": "' +
									comboValue[i].value +
									'-curIll", "headerName":"조도"}';
								break;
							}
							case "5": {
								createStr +=
									'{"field": "' +
									comboValue[i].value +
									'-curTmp", "headerName":"온도"}, {"field": "' +
									comboValue[i].value +
									'-curHdm", "headerName":"습도"}, {"field": "' +
									comboValue[i].value +
									'-curIll", "headerName":"조도"}, {"field": "' +
									comboValue[i].value +
									'-curCo2", "headerName":"CO2"}';
								break;
							}
						}
						createStr += "]}";
						columnDefs.push(JSON.parse(createStr));
					}

					// 그리드 데이터 row 제작
					for (let i = 0; i < tempArr.length; i++) {
						let createStr =
							'{"num": "' +
							(i * 1 + 1) +
							'", "CreateDate": "' +
							tempArr[i].createDate +
							'", ';

						for (let j = 0; j < comboValue.length; j++) {
							if (tempArr[i].itemNm === comboValue[j].name) {
								createStr +=
									'"' +
									tempArr[i].itemNo +
									'-curTmp": "' +
									tempArr[i].curTmp +
									'", "' +
									tempArr[i].itemNo +
									'-curHdm": "' +
									tempArr[i].curHdm +
									'", "' +
									tempArr[i].itemNo +
									'-curIll": "' +
									tempArr[i].curIll +
									'", "' +
									tempArr[i].itemNo +
									'-curCo2": "' +
									tempArr[i].curCo2 +
									'", ';

								if (i !== tempArr.length - 1) {
									i += 1;
								}
							}
							if (j === comboValue.length - 1) {
								let tempCreateStr = createStr.slice(0, -2);
								tempCreateStr += "}";
								createStr = "";
								pivot.push(JSON.parse(tempCreateStr));
							}
						}

						if (i !== tempArr.length - 1) {
							i -= 1;
						} else {
							if (comboValue.length === 1) {
								let createStr =
									'{"num": "' +
									(i * 1 + 1) +
									'", "CreateDate": "' +
									tempArr[i].createDate +
									'", '
									+
									'"' +
									tempArr[i].itemNo +
									'-curTmp": "' +
									tempArr[i].curTmp +
									'", "' +
									tempArr[i].itemNo +
									'-curHdm": "' +
									tempArr[i].curHdm +
									'", "' +
									tempArr[i].itemNo +
									'-curIll": "' +
									tempArr[i].curIll +
									'", "' +
									tempArr[i].itemNo +
									'-curCo2": "' +
									tempArr[i].curCo2 +
									'", ';

								let tempCreateStr = createStr.slice(0, -2);
								tempCreateStr += "}";
								createStr = "";
								pivot.push(JSON.parse(tempCreateStr));
							}
						}
					}

					let tempPivot = [];
					pivot.reverse().forEach((item, index) => {
						tempPivot.push({
							...item,
							num: index + 1,
						});
					});

					setRowData(tempPivot);
					setColumnData(columnDefs);
					setChartData({
						categories: tempChartArr,
						data: tmpSeriesArr,
						groups: data.chartModels.groups,
					});
				} else {
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "") // 조회 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<div>
			<LM10010View
				setSnacks={setSnacks}
				lanList={lanList}
				// 그리드 데이터
				rowData={rowData}
				setRowData={setRowData}
				columnData={columnData}
				fromDate={fromDate}
				setFromDate={setFromDate}
				toDate={toDate}
				setToDate={setToDate}
				// 차트 데이터
				chartData={chartData}
				setChartData={setChartData}
				curTmp={curTmp}
				curHdm={curHdm}
				curIll={curIll}
				curCo2={curCo2}
				// AutoCombo
				comboValue={comboValue}
				setComboValue={setComboValue}
				comboItems={comboItems}
				setComboItems={setComboItems}
				// Combo
				itemTypeValue={itemTypeValue}
				setItemTypeValue={setItemTypeValue}
				itemTypeItems={itemTypeItems}
				setItemTypeItems={setItemTypeItems}
				// function
				BetweenDate={BetweenDate}
				GetBetweenTwoYear={GetBetweenTwoYear}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default LM10010;
