import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components Import */
import BM10070View from "../../Views/BaseManagements/BM10070";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
/** Icons Import */

/** 마스터 관리 */
function BM10070() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [codeList, setCodeList] = React.useState([]);
	// 좌측 그리드
	const [rowData, setRowData] = React.useState([]);
	const [selectedRowData, setSelectedRowData] = React.useState([]);
	const [changedRowData, setChangedRowData] = React.useState([]);
	const [itemMngList, setItemMngList] = React.useState([]);
	const [addData, setAddData] = React.useState({
		userId: "",
		userCombo: [],
		itemNo: "",
		itemCombo: [],
		rmCd: "",
	});
	// 우측 그리드
	const [rowData1, setRowData1] = React.useState([]);
	const [selectedRowData1, setSelectedRowData1] = React.useState([]);
	const [changedRowData1, setChangedRowData1] = React.useState([]);
	const [registerList, setRegisterList] = React.useState([]); // 레지스터 맵 리스트
	const [registerVal, setRegisterVal] = React.useState([]); // 레지스터 값 리스트
	// 팝업 그리드
	const [rowData2, setRowData2] = React.useState([]);
	const [selectedRowData2, setSelectedRowData2] = React.useState([]); // 팝업창 그리드 선택된 아이템 목록
	const [inputs1, setInputs1] = React.useState({
		mapAdd: "",
	});
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
	}, []);

	// 좌측 데이터 조회
	const Init = async () => {
		setProgress(true);
		let temp = [];
		let lans = [];
		let userInfo = null;
		let search = true;

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "BM10070").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10070.GetMst,
				{
					userId: userInfo.userId
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					// throw new Error(
					// 	(lans.length > 0
					// 		? lans.filter(
					// 			(f) =>
					// 				f.lanId ===
					// 				"snack_unavailable_data"
					// 		)[0].lanName
					// 		: "")	// 데이터가 없습니다.
					// );
				} else {
					for (let i = 0; i < data.length; i++) {
						temp.push({
							...data[i],
							createDate: Commons.DateFormating(
								Commons.StringtoDate(data[i].createDate, 3)
							),
							updateDate: Commons.DateFormating(
								Commons.StringtoDate(data[i].updateDate, 3)
							),
						});
					}
					setRowData(temp);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			GetItemMng(lans);
			setProgress(false);
		}
	};

	// 좌측 데이터 사용자 조회
	const GetItemMng = async (lans = lanList) => {
		setProgress(true);
		let userArr = [];

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10070.GetItemMng
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					setItemMngList(data);

					data.forEach((item) => {
						userArr.push({
							value: item.userId,
							name: item.userId,
						});
					});

					userArr = userArr.reduce((prev, now) => {
						if (!prev.some((obj) => obj.value === now.value)) {
							prev.push(now);
						}
						return prev;
					}, []);

					setAddData({
						...addData,
						userCombo: userArr,
					});
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 사용자 아이디에 따른 장치 선택
	const CheckUserId = async (_userId) => {
		let itemArr = [];

		itemMngList.forEach((item) => {
			if (item.userId === _userId) {
				itemArr.push({
					value: item.itemNo,
					name:
						item.machNm === ""
							? item.itemNo
							: item.machNm + " (" + item.itemNo + ")",
				});
			}
		});

		setAddData({
			...addData,
			userId: _userId,
			itemNo: "",
			itemCombo: itemArr,
		});
	};

	// 좌측 그리드 중복 데이터 검사
	const CheckCodeMst = async (_gridref) => {
		setProgress(true);
		let currentData = null;

		try {
			if (addData.itemNo === "" || addData.itemNo === null) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message:
				// 		(lanList.length > 0
				// 		? lanList.filter(
				// 			(f) =>
				// 				f.lanId ===
				// 				"snack_select_item"
				// 		)[0].lanName
				// 		: "")	// 아이템을 선택해 주세요
				// });
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_select_item"
						)[0].lanName
						: "")	// 아이템을 선택해 주세요
				);
			} else if (addData.rmCd === "" || addData.rmCd === null) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message:
				// 		(lanList.length > 0
				// 			? lanList.filter(
				// 				(f) =>
				// 					f.lanId ===
				// 					"snack_select_master_code"
				// 			)[0].lanName
				// 			: "")	// 마스터 코드를 입력해 주세요
				// });
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_select_master_code"
						)[0].lanName
						: "")	// 마스터 코드를 입력해 주세요
				);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10070.CheckCodeMst,
					{
						itemNo: addData.itemNo,
						rmCd: addData.rmCd,
					}
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						throw new Error(
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_unavailable_data"
								)[0].lanName
								: "")	// 데이터가 없습니다.
						);
					} else if (!data) {
						throw new Error(
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_registered_code"
								)[0].lanName
								: "")	// 이미 등록된 코드입니다.
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_available_code"
						// 		)[0].lanName
						// 		: "")	// 사용 가능한 코드입니다.",
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_available_code"
									)[0].lanName
									: "")	// 사용 가능한 코드입니다.",
						});
						currentData = [
							{
								itemNo: addData.itemNo,
								rmCd: addData.rmCd,
								machNm: addData.itemCombo.filter(
									(item) => item.value === addData.itemNo
								)[0].name,
								newYn: "Y",
							},
							...rowData,
						];
						_gridref.current.api.setRowData(currentData);
						setRowData(currentData);
						// changedRowData 추가
						setChangedRowData([
							...changedRowData,
							{
								itemNo: addData.itemNo,
								rmCd: addData.rmCd,
								machNm: addData.itemCombo.filter(
									(item) => item.value === addData.itemNo
								)[0].name,
								newYn: "Y",
							},
						]);
						dispatch(DrawerActions.setNotOpen()); // 열림 방지
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			// 좌측 그리드 추가 버튼 초기화
			setAddData({
				...addData,
				userId: "",
				itemNo: "",
				rmCd: "",
			});
			setProgress(false);
		}
	};

	// 좌측 그리드 데이터 저장
	const CreateMst = async () => {
		setProgress(true);
		let userInfo = null;
		let tempArr = [];
		let resultData = [];

		try {
			tempArr = [...changedRowData].reverse().reduce((prev, now) => {
				if (
					!prev.some(
						(obj) =>
							obj.itemNo === now.itemNo &&
							obj.rmCd === now.rmCd
					)
				) {
					prev.push(now);
				}
				return prev;
			}, []);

			// 
			let tempNameCheck = tempArr.filter((item) => item.rmNm === "" || item.rmNm === null || item.rmNm === undefined);

			if (changedRowData.length === 0) {
				// 변경된 행이 없으면 에러
			} else if (tempNameCheck.length !== 0) {
				// 마스터복사 이름(rmNm) 없으면 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_enter_mastercode_name"
					)[0].lanName
					: ""),	// 저장 실패!
				);
			} else {
				await IndexedDb.GetLoginInfo().then((result) => {
					userInfo = result;
				});

				tempArr.forEach((item) => {
					resultData.push({
						...item,
						createDate: Commons.DateToString(new Date(), 2),
						createId: userInfo.userId,
						updateDate: Commons.DateToString(new Date(), 2),
						updateId: userInfo.userId,
					});
				});

				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10070.CreateMst,
					resultData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_save_failed"
							)[0].lanName
							: ""),	// 저장 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_saved"
						// 			)[0].lanName
						// 			: ""),	// 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_saved"
									)[0].lanName
									: ""),	// 저장 되었습니다!
						});
						setChangedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init();
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	//좌측 그리드 데이터 삭제
	const DeleteMst = async () => {
		setProgress(true);
		let tempNewYn = selectedRowData.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete"
					)[0].lanName
					: ""),	// 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10070.DeleteMst,
					selectedRowData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_delete_failed"
							)[0].lanName
							: ""),	// 삭제 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_deleted"
						// 		)[0].lanName
						// 		: ""),	// 삭제 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_deleted"
									)[0].lanName
									: ""),	// 삭제 되었습니다!
						});
						setRowData1([]);
						setChangedRowData([]);
						setSelectedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init();
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 그리드용 기본 레지스터 조회
	const GetRegisterMaps = async (itemNo, rmCd) => {
		setProgress(true);
		let tempData = [];

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10070.GetRegisterMaps,
				{
					itemNo: itemNo,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRegisterList([]);
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					// console.log("data : ", data);
					tempData = data.filter((item) => item.MapType !== "2" && item.CopyType !== 'N');
					setRegisterList(tempData);
					GetRegisterVals(data[0].ItemIdx, itemNo, rmCd, data);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
			return "";
		} finally {
			setRowData1([]);
			setProgress(false);
		}
	};

	// 우측 그리드용 기본 레지스터 값 조회
	const GetRegisterVals = async (itemIdx, itemNo, rmCd, map) => {
		setProgress(true);

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10070.GetRegisterVals,
				{
					itemIdx: itemIdx,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRegisterVal([]);
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					setRegisterVal(data);
					GetSub(itemNo, rmCd, map, data);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
			return false;
		} finally {
			setProgress(false);
		}
	};

	// 우측 그리드 조회
	const GetSub = async (
		itemNo,
		rmCd,
		map = registerList,
		val = registerVal
	) => {
		setProgress(true);
		let tempArr = [];

		try {
			setRowData1([]);
			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10070.GetSub,
				{
					itemNo: itemNo,
					rmCd: rmCd,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData1([]);
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					data.forEach((_item) => {
						let value = [];
						val.filter(
							(item) => item.MapAdd === _item.mapAdd
						).forEach((_item1) => {
							value.push(_item1.ValDesc);
						});

						tempArr.push({
							..._item,
							type:
								map.filter(
									(item) => item.MapAdd === _item.mapAdd
								)[0].InputType === "1"
									? "number"
									: "select",
							values: value,
							mapNm: _item.mapNm,
							max: map.filter(
								(item) => item.MapAdd === _item.mapAdd
							)[0].MapMax,
							min: map.filter(
								(item) => item.MapAdd === _item.mapAdd
							)[0].MapMin,
							mapUnit: map.filter(
								(item) => item.MapAdd === _item.mapAdd
							)[0].MapUnit,
							updateDate: Commons.DateFormating(
								Commons.StringtoDate(_item.updateDate, 3)
							),
						});
					});

					setRowData1(tempArr);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
			setRowData1([]);
		} finally {
			setChangedRowData1([]);
			setSelectedRowData1([]);
			setProgress(false);
		}
	};

	// 우측 팝업 그리드 아이템 제작
	const MakeGridData = async () => {
		let result = registerList.filter((item) => {
			return !rowData1.some((obj) => obj.mapAdd === item.MapAdd);
		});

		setRowData2(result);
	};

	// 우측 그리드 row 추가
	const CheckCodeSub = async (_gridref) => {
		let currentData = [];
		let tempChangedRowData1 = changedRowData1;

		selectedRowData2.forEach((item) => {
			// combo box용 value 제작
			let value = [];

			registerVal
				.filter((_item) => _item.MapAdd === item.MapAdd)
				.forEach((_item1) => {
					value.push(_item1.ValDesc);
				});

			currentData.push({
				rmCd: selectedRowData[0].rmCd,
				mapAdd: item.MapAdd,
				mapNm: item.MapNm,
				mapNmSh: item.MapNmSh,
				val: "",
				type: item.InputType === "1" ? "number" : "select",
				values: value,
				max: item.MapMax,
				min: item.MapMin,
				mapUnit: item.MapUnit,
				newYn: "Y",
			});
			tempChangedRowData1.push({
				rmCd: selectedRowData[0].rmCd,
				mapAdd: item.MapAdd,
				mapNm:
					item.MapUnit === "분" || item.MapUnit === "초"
						? item.MapNm
						: item.MapNm,
				mapNmSh: item.MapNmSh,
				val: "",
				type: item.InputType === "1" ? "number" : "select",
				values: value,
				max: item.MapMax,
				min: item.MapMin,
				mapUnit: item.MapUnit,
				newYn: "Y",
			});

			value = [];
		});
		currentData.push(...rowData1);
		_gridref.current.api.setRowData(currentData);
		setRowData1(currentData);
		setChangedRowData1(tempChangedRowData1);
		setAddData({
			...addData,
			userId: "",
			itemNo: "",
			rmCd: "",
		});

		dispatch(DrawerActions.setNotOpen()); // 열림 방지
	};

	// 우측 그리드 데이터 저장 전 체크
	const CheckGridData = async () => {
		let tempArr = [];
		let tempCheckArr = [];
		let tempItemName = "";

		tempArr = [...changedRowData1].reverse().reduce((prev, now) => {
			if (
				!prev.some(
					(obj) => obj.rmCd === now.rmCd && obj.mapAdd === now.mapAdd
				)
			) {
				prev.push(now);
			}
			return prev;
		}, []);

		tempArr.forEach((item) => {
			if (item.val === null || item.val === "") {
				tempCheckArr.push(item.mapNm);
				tempItemName += item.mapNm + "(" + item.mapAdd + "), ";
			} else if (
				(item.mapUnit === "분" || item.mapUnit === "초") &&
				(Number(item.val) > item.max || Number(item.val) < item.min)
			) {
				tempCheckArr.push(item.mapNm);
				tempItemName += item.mapNm + "(" + item.mapAdd + "), ";
			}
		});

		if (tempCheckArr.length !== 0) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		(lanList.length > 0
			// 			? lanList.filter(
			// 				(f) =>
			// 					f.lanId ===
			// 					"snack_check_register_val_1"
			// 			)[0].lanName
			// 			: "")	// '
			// 		+ tempItemName +
			// 		(lanList.length > 0
			// 			? lanList.filter(
			// 				(f) =>
			// 					f.lanId ===
			// 					"snack_check_register_val_2"
			// 			)[0].lanName
			// 			: "")	// 의 레지스터 값을 다시 확인해 주세요."
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message:
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_check_register_val_1"
						)[0].lanName
						: "")	// '
					+ tempItemName +
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_check_register_val_2"
						)[0].lanName
						: "")	// 의 레지스터 값을 다시 확인해 주세요."
			});
		} else {
			CreateSub(tempArr);
		}
	};

	// 우측 그리드 데이터 저장
	const CreateSub = async (reduceChanged) => {
		setProgress(true);
		let resultData = [];

		try {
			if (reduceChanged.length !== 0) {
				reduceChanged.forEach((_item) => {
					resultData.push({
						rmCd: _item.rmCd,
						mapAdd: _item.mapAdd,
						val: _item.val,
						updateDate: Commons.DateToString(new Date(), 2),
					});
				});

				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10070.CreateSub,
					resultData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_save_failed"
							)[0].lanName
							: ""),	// 저장 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_saved"
						// 		)[0].lanName
						// 		: ""),	// 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_saved"
									)[0].lanName
									: ""),	// 저장 되었습니다!
						});
						setChangedRowData1([]);
						setAddData({
							...addData,
							userId: "",
							itemNo: "",
							rmCd: "",
						});
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						GetSub(
							selectedRowData[0].itemNo,
							selectedRowData[0].rmCd
						);
					}
				});
			} else {
				// 변경된 행이 없으면 에러
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 그리드 데이터 삭제
	const DeleteSub = async () => {
		setProgress(true);
		let resultData = [];
		let tempNewYn = selectedRowData1.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData1.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete"
					)[0].lanName
					: ""),	// 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				selectedRowData1.forEach((item, index) => {
					resultData.push({
						rmCd: item.rmCd,
						mapAdd: item.mapAdd,
						val: item.val + "",
						updateDate: Commons.DateToString(new Date(), 2),
					});
				});

				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10070.DeleteSub,
					resultData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_delete_failed"
							)[0].lanName
							: ""),	// 삭제 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_deleted"
						// 		)[0].lanName
						// 		: ""),	// 삭제 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_deleted"
									)[0].lanName
									: ""),	// 삭제 되었습니다!
						});
						// setSelectedRowData([]);
						setChangedRowData([]);
						setSelectedRowData1([]);
						setChangedRowData1([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						GetSub(
							selectedRowData[0].itemNo,
							selectedRowData[0].rmCd
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<>
			<BM10070View
				// 언어팩
				lanList={lanList}
				codeList={codeList}
				setSnacks={setSnacks}
				// 좌측 그리드
				rowData={rowData}
				setRowData={setRowData}
				selectedRowData={selectedRowData}
				setSelectedRowData={setSelectedRowData}
				changedRowData={changedRowData}
				setChangedRowData={setChangedRowData}
				// 좌측 그리드 중복값
				addData={addData}
				setAddData={setAddData}
				// 우측 그리드
				rowData1={rowData1}
				setRowData1={setRowData1}
				selectedRowData1={selectedRowData1}
				setSelectedRowData1={setSelectedRowData1}
				changedRowData1={changedRowData1}
				setChangedRowData1={setChangedRowData1}
				registerList={registerList}
				registerVal={registerVal}
				// 우측 그리드 중복값
				inputs1={inputs1}
				setInputs1={setInputs1}
				// 팝업 그리드
				rowData2={rowData2}
				setSelectedRowData2={setSelectedRowData2}
				// 함수
				CheckUserId={CheckUserId}
				CheckCodeMst={CheckCodeMst}
				CreateMst={CreateMst}
				DeleteMst={DeleteMst}
				GetRegisterMaps={GetRegisterMaps}
				GetRegisterVals={GetRegisterVals}
				GetSub={GetSub}
				MakeGridData={MakeGridData}
				CheckCodeSub={CheckCodeSub}
				CheckGridData={CheckGridData}
				DeleteSub={DeleteSub}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "bm10070_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</>
	);
}

export default BM10070;
