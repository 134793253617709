import React from "react";
import { useNavigate } from "react-router-dom";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Typography,
	Stack,
} from "@mui/material";
import {
	DashboardHeader,
	DashboardList,
	DashboardSchedule,
	ScheduleContent,
	DashboardSetting,
	DashboardList_web,
	DashboardChart,
} from "../../Components/Conotec";
import Chart from "react-apexcharts";
import { Dialog } from "../../Components/Dialog";
import { GroupButton, Button } from "../../Components/Buttons";
import { Input } from "../../Components/Inputs";
import { Combo } from "../../Components/Combos";
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
/** Icons Import */
import {
	Refresh as ReloadIcon,
	ContentPaste as Con_Log_Icon,
} from "@mui/icons-material";
/** Images Import */
import GraphIcon from "../../Assets/Images/Design/drawable-hdpi/tem_btn01.png";
import GraphIcon1 from "../../Assets/Images/Design/drawable-hdpi/tem_btn02.png";
import CopyIcon from "../../Assets/Images/Design/drawable-hdpi/copy_btn02.png";
import MasterIcon from "../../Assets/Images/Design/drawable-hdpi/master_btn03.png";

function DM10010(props) {
	const navigate = useNavigate();
	const [bookingToggle, setBookingToggle] = React.useState(false);
	const groupButtonWeb = [
		{
			name: (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "#fff",
					}}
				>
					<img
						style={{
							width: "20px",
							height: "20px",
						}}
						src={CopyIcon}
					/>
					<Typography
						variant={Commons.IsMobile() ? "subtitle2" : "h6"}
					>
						{
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"dashboard_button_device_copy"
								)[0].lanName
								: "" // 장치 복사
						}
					</Typography>
				</div>
			),
			onClick: (e) => {
				props.CopySetting();
			},
		},
		// {
		// 	sx: { px: Commons.IsMobile() ? 0 : 2 },
		// 	name: (
		// 		<div
		// 			style={{
		// 				display: "flex",
		// 				flexDirection: "column",
		// 				alignItems: "center",
		// 				color: "#fff",
		// 			}}
		// 		>
		// 			<img
		// 				style={{
		// 					width: "20px",
		// 					height: "20px",
		// 				}}
		// 				src={MasterIcon}
		// 			/>
		// 			<Typography
		// 				variant={Commons.IsMobile() ? "subtitle2" : "h6"}
		// 			>
		// 				{
		// 					props.lanList.length > 0
		// 						? props.lanList.filter(
		// 							(f) =>
		// 								f.lanId ===
		// 								"dashboard_button_master_copy"
		// 						)[0].lanName
		// 						: "" // 마스터 복사
		// 				}
		// 			</Typography>
		// 		</div>
		// 	),
		// 	onClick: (e) => {
		// 		// props.CopyMasterMst();
		// 		props.setCopyMasters({
		// 			...props.copyMasters,
		// 			open: true,
		// 		});
		// 	},
		// },
	];
	const groupButtonMobile = [
		{
			name: (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "#fff",
					}}
				>
					<img
						style={{
							width: "20px",
							height: "20px",
						}}
						src={GraphIcon}
					/>
					<Typography
						variant={Commons.IsMobile() ? "subtitle2" : "h6"}
					>
						{
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"dashboard_gen_tmp_change_log"
								)[0].lanName
								: "" // 온도변화
						}
					</Typography>
				</div>
			),
			onClick: (e) => {
				if (
					props.controllerData[0] !== undefined &&
					props.controllerDetail.length !== 0
				) {
					navigate("/DashboardManagement/DM10030", {
						state: [
							props.controllerData[0],
							props.controllerDetail,
						],
					});
				}
			},
		},
		{
			name: (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "#fff",
					}}
				>
					<img
						style={{
							width: "20px",
							height: "20px",
						}}
						src={CopyIcon}
					/>
					<Typography
						variant={Commons.IsMobile() ? "subtitle2" : "h6"}
					>
						{
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"dashboard_button_device_copy"
								)[0].lanName
								: "" // 장치 복사
						}
					</Typography>
				</div>
			),
			onClick: (e) => {
				props.CopySetting();
			},
		},
		// {
		// 	sx: { px: Commons.IsMobile() ? 0 : 2 },
		// 	name: (
		// 		<div
		// 			style={{
		// 				display: "flex",
		// 				flexDirection: "column",
		// 				alignItems: "center",
		// 				color: "#fff",
		// 			}}
		// 		>
		// 			<img
		// 				style={{
		// 					width: "20px",
		// 					height: "20px",
		// 				}}
		// 				src={MasterIcon}
		// 			/>
		// 			<Typography
		// 				variant={Commons.IsMobile() ? "subtitle2" : "h6"}
		// 			>
		// 				{
		// 					props.lanList.length > 0
		// 						? props.lanList.filter(
		// 							(f) =>
		// 								f.lanId ===
		// 								"dashboard_button_master_copy"
		// 						)[0].lanName
		// 						: "" // 마스터 복사
		// 				}
		// 			</Typography>
		// 		</div>
		// 	),
		// 	onClick: (e) => {
		// 		// props.CopyMasterMst();
		// 	},
		// },
	];
	const [writeDialog, setWriteDialog] = React.useState(false);
	const gridRef = React.useRef();
	const columnDefs = [
		{
			field: "mapAdd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_register_address"
					)[0].lanName
					: "", // "레지스터 주소",
			minWidth: 150,
		},
		{
			field: "mapNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_register_nm"
					)[0].lanName
					: "", // "레지스터 명",
			minWidth: 230,
		},
		{
			field: "val",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_register_value"
					)[0].lanName
					: "", // "레지스터 값",
			minWidth: 150,
		},
	];

	const dashboardChart = React.useMemo(() => (
		<DashboardChart
			items={props.tempChLog}
			lanList={props.lanList}
		/>
	), [props.tempChLog]);

	const settingLogChart = React.useMemo(() => (
		<Chart
			type="bar"
			height="200px"
			series={[
				{
					name:
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId ===
									"dashboard_gen_chart_count"
							)[0].lanName
							: "", // 횟수
					data: props.registerChLog.data,
				},
			]} // 데이터
			options={{
				theme: {
					mode: "dark",
				},
				chart: {
					id: "basic-line",
					toolbar: {
						show: true,
						tools: {
							download: false,
							pan: false,
						},
					},
					animations: {
						enabled: false,
					},
					background: "#292f3b",
					zoom: {
						enabled: false,
					},
				},
				xaxis: {
					categories:
						props.registerChLog.categories, // 밑에
					tickAmount: 10,
				},
				dataLabels: {
					enabled: false,
				},
			}}
		/>
	), [props.registerChLog]);

	return (
		<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
			<Grid xs={4} md={12}>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<IconButton onClick={() => props.ReloadPage()}>
						<Typography sx={{ mr: 1 }}>
							{
								props.determinateLabel +
								(props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId === "home_gen_time"
									)[0].lanName
									: "") // 초후
							}
						</Typography>
						<ReloadIcon fontSize="small" />
					</IconButton>
				</div>
			</Grid>
			<DashboardHeader
				items={props.controllerData}
				controllerDetail={props.controllerDetail}
				setDialog={setWriteDialog}
			/>
			<DashboardList items={props.settingBitData} />
			{Commons.IsMobile() ? null : (
				<Grid xs={4} sm={8} md={6}>
					<Card sx={{ height: "100%" }}>
						<CardHeader
							sx={{ py: 1, bgcolor: "#141820" }}
							avatar={
								<Con_Log_Icon
									sx={{ color: "#6e7b9b" }}
									fontSize="small"
								/>
							}
							title={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_change_settings_log"
									)[0].lanName
									: "" // 설정 변경
							}
							titleTypographyProps={{
								variant: "subtitle2",
								fontWeight: "bold",
							}}
						/>
						<CardContent>
							{/* <Chart
								type="bar"
								height="200px"
								series={[
									{
										name:
											props.lanList.length > 0
												? props.lanList.filter(
													(f) =>
														f.lanId ===
														"dashboard_gen_chart_count"
												)[0].lanName
												: "", // 횟수
										data: props.registerChLog.data,
									},
								]} // 데이터
								options={{
									theme: {
										mode: "dark",
									},
									chart: {
										id: "basic-line",
										toolbar: {
											show: true,
											tools: {
												download: false,
												pan: false,
											},
										},
										animations: {
											enabled: false,
										},
										background: "#292f3b",
										zoom: {
											enabled: false,
										},
									},
									xaxis: {
										categories:
											props.registerChLog.categories, // 밑에
										tickAmount: 10,
									},
									dataLabels: {
										enabled: false,
									},
								}}
							/> */}
							{settingLogChart}
						</CardContent>
					</Card>
				</Grid>
			)}
			{Commons.IsMobile() ? null : (
				// <DashboardChart
				// 	items={props.tempChLog}
				// 	lanList={props.lanList}
				// />
				dashboardChart
			)}
			{props.amountYn === "Y" ? (
				<DashboardSetting
					items={props.settingMobileData}
					lanList={props.lanList}
				/>
			) : null}
			{/** 스케쥴 목록 주석처리 */}
			{/* <DashboardSchedule
				items={props.bookingList}
				itemsVal={props.settingAllData}
				lanList={props.lanList}
				lanCode={props.userLanCode}
				controllerData={props.controllerData[0]}
				onClick={() => setBookingToggle(true)}
				onDelete={(bkDate, rcnt) => props.DeleteBooking(bkDate, rcnt)}
			/> */}
			<div
				style={{
					width: "100%",
					marginTop: "16px",
				}}
			>
				{props.amountYn === "Y" && (
					<GroupButton
						sx={{ mb: Commons.IsMobile() ? 2 : 0 }}
						color="conotecButton"
						items={
							Commons.IsMobile()
								? groupButtonMobile
								: groupButtonWeb
						}
						fullWidth={true}
					/>
				)}
			</div>
			<Dialog
				fullWidth
				open={bookingToggle}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId === "dashboard_pop_schedule_title"
						)[0].lanName
						: "" // 예약 등록
				}
				onClose={() => {
					props.setRegisterValue({
						...props.registerValue,
						open: false,
						title: "",
						message: "",
						inputType: 1,
						inputs: [],
						min: 0,
						max: 0,
						min1: 0,
						max1: 0,
						beforeValue: "",
						value: "",
						stepType: "",
						value1: "",
						value2: "",
					});
					props.setBookingData({
						mapAdd: "",
						aftValue: "",
						rotation: "0",
						appDate:
							new Date().getMinutes() > 30
								? new Date(
									new Date().setHours(
										new Date().getHours() + 1,
										0,
										0,
										0
									)
								)
								: new Date(
									new Date().setHours(
										new Date().getHours(),
										30,
										0,
										0
									)
								),
						endDate: new Date(new Date().setHours(23, 59, 59, 0)),
					});
					setBookingToggle(false);
				}}
				onSave={() => {
					if (props.bookingData.aftValue === "") {
						// console.log("변경값 없음");
						props.setSnacks({
							open: true,
							type: "error",
							message:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId === "snack_enter_change"
									)[0].lanName
									: "", // 변경 내용을 입력해 주세요.
						});
					} else if (props.bookingData.appDate <= new Date()) {
						// console.log("예약 일자가 현재 시간보다 작음");
						props.setSnacks({
							open: true,
							type: "error",
							message:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"snack_cannot_less_current_time"
									)[0].lanName
									: "", // 예약 일자가 현재 시간보다 작습니다.
						});
					} else {
						props.CheckBooking();
					}
					setBookingToggle(false);
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_save"
						)[0].lanName
						: ""
				} // 저장
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_close"
						)[0].lanName
						: ""
				} // 닫기
			>
				<ScheduleContent
					bookingData={props.bookingData}
					setBookingData={props.setBookingData}
					mapAddItem={props.mapAddItem}
					RegisterValue={(e) => props.RegisterValue(e)}
					registerValue={props.registerValue}
					setRegisterValue={props.setRegisterValue}
					nowUnit={props.controllerData[0]}
					lanList={props.lanList}
				/>
			</Dialog>
			<Dialog
				open={writeDialog}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_ch_title"
						)[0].lanName
						: "" // 장치 이름 변경
				}
				onClose={() => {
					setWriteDialog(false);
					props.setWriteValue("");
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_save"
						)[0].lanName
						: "" // 저장
				}
				onSave={() => props.ChangeItemName()}
				children={
					<Input
						value={props.writeValue}
						onChange={(e) => props.setWriteValue(e)}
					/>
				}
			/>
			<Dialog
				fullWidth
				open={props.copySettings.open}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId === "dashboard_button_device_copy"
						)[0].lanName
						: "" // 장치 복사
				}
				onClose={() => {
					props.setCopySettings({
						...props.setCopySettings,
						open: false,
						value: "",
					});
				}}
				onSave={() => {
					if (props.copySettings.value !== "") {
						props.setCopySettings({
							...props.setCopySettings,
							open: false,
							value: "",
						});
						props.SaveCopySetting();
					} else {
					}
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_copy"
						)[0].lanName
						: "" // 복사
				}
			>
				<div>
					<Combo
						fullWidth
						sx={{
							margin: 1,
						}}
						name={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"dashboard_pop_copy_device"
								)[0].lanName
								: "" // 복사본을 가져올 장치를 선택하세요.
						}
						value={props.copySettings.value}
						items={props.copySettings.items}
						onChange={(e) => {
							props.setCopySettings({
								...props.copySettings,
								value: e,
							});
						}}
					/>
				</div>
			</Dialog>
			{/* <Dialog
				fullWidth
				open={props.copyMasters.open}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId === "dashboard_button_master_copy"
						)[0].lanName
						: "" // 마스터 복사
				}
				onClose={() => {
					props.setCopyMasters({
						...props.copyMasters,
						open: false,
						value: "",
						items: [],
						rowData: [],
						checkId: "",
						checkPw: "",
						checked: false,
					});
					props.setRowData([]);
				}}
				onSave={() => {
					if (props.copyMasters.value !== "") {
						props.SaveCopyMaster();
					} else {
					}
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "dashboard_pop_copy"
						)[0].lanName
						: "" // 복사
				}
			>
				<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
						<Input
							type="outlined"
							name="ID"
							value={props.copyMasters.checkId}
							onChange={(e) => {
								props.setCopyMasters({
									...props.copyMasters,
									checkId: e,
								});
							}}
						/>
						<Input
							type="outlined"
							name="Password"
							inputMode="password"
							value={props.copyMasters.checkPw}
							onChange={(e) => {
								props.setCopyMasters({
									...props.copyMasters,
									checkPw: e,
								});
							}}
						/>
						<Button
							name="Check"
							onClick={() => {
								props.setCopyMasters({
									...props.copyMasters,
									value: "",
									items: [],
								});
								props.setRowData([]);
								props.CopyMasterMst();
							}}
						/>
					</Stack>
					<Combo
						fullWidth
						sx={{ my: 2 }}
						name={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"dashboard_button_master_copy"
								)[0].lanName
								: "" // 마스터 코드 선택
						}
						value={props.copyMasters.value}
						items={props.copyMasters.items}
						onChange={(e) => {
							props.setCopyMasters({
								...props.copyMasters,
								value: e,
							});
							props.CopyMasterSub(e);
						}}
						disabled={props.copyMasters.checked ? false : true}
					/>
					<Card
						style={{
							width: "100%",
							justifyContent: "center",
						}}
					>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height: "500px",
								justifyContent: "center",
							}}
						>
							<AgGridReact
								ref={gridRef}
								animationRows={true}
								columnDefs={columnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: false,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="single"
								singleClickEdit={false}
							/>
						</div>
					</Card>
				</Grid>
			</Dialog> */}
		</Grid>
	);
}

export default DM10010;
