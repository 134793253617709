import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	Accordion as MuiAccordion,
	AccordionSummary as MuiAccordionSummary,
	AccordionDetails as MuiAccordionDetails,
	AccordionActions as MuiAccordionActions,
	Avatar,
	Typography,
	ListItemText as MuiListItemText,
} from "@mui/material";
import { Button } from "../Buttons";
/** Icons Import */
import {
	ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
	ExpandMore,
} from "@mui/icons-material";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	color: "#ffffff",
	backgroundColor: "#05105A",
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: "5px",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={
			<ArrowForwardIosSharpIcon
				sx={{ color: "#ffffff", fontSize: "0.9rem" }}
			/>
		}
		{...props}
	/>
))(({ theme }) => ({
	color: "#ffffff",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const SummaryContainer = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(255, 255, 255, .125)",
}));

const TitleNames = styled((props) => <div {...props} />)(({ theme }) => ({
	width: Commons.IsMobile() ? "200px" : "450px",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	margin: "0px 10px",
}));

const TitleName = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	margin: "0px 10px",
}));

const InfoSubtitle = styled((props) => (
	<Typography
		variant={Commons.IsMobile() ? "caption" : "subtitle1"}
		{...props}
	/>
))(({ theme }) => ({
	color: "#ffffff",
}));

const InfoBody = styled((props) => (
	<Typography variant={Commons.IsMobile() ? "body2" : "body1"} {...props} />
))(({ theme }) => ({
	color: "#ffffff",
	marginBottom: "10px",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
}));

const DetailWrap = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
}));

const DetailInfo = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
}));

const ListHeaders = styled((props) => <MuiListItemText {...props} />)(
	({ theme }) => ({})
);

const ListHeaderText = styled((props) => (
	<Typography variant="h6" {...props} />
))(({ theme }) => ({}));

/** items example
const accordionData = [
];
*/

function GroupAccordion({
	groupList = [],
	itemList = [],
	onClick = (_item) => {
		// console.log(_item);
	}
}) {
	const [accExpanded, setAccExpanded] = React.useState(null);

	const ChangeExpanded = (panel) => (event, newExpanded) => {
		setAccExpanded(newExpanded ? panel : false);
	};

	return (
		<>
			{groupList.map((item, index) => {
				// console.log("item : ", item);
				return (
					<Grid key={index} xs={4} md={12}>
						<Card>
							<Accordion
								expanded={accExpanded === "panel" + (index + 1)}
								onChange={ChangeExpanded("panel" + (index + 1))}
								key={index}
							>
								<AccordionSummary>
									<SummaryContainer>
										<TitleNames>
											<TitleName>
												<InfoSubtitle>
													그룹명
												</InfoSubtitle>
												<InfoBody>
													{item.itemGrp} 그룹
												</InfoBody>
											</TitleName>
										</TitleNames>
									</SummaryContainer>
								</AccordionSummary>
								<AccordionDetails>
									<Card
										sx={{
											display: "flex",
											justifyContent: "right",
											marginBottom: "15px",
											backgroundColor: "#05105A",
										}}
									>
										<Button
											name="수정"
											size="large"
											color="info"
											onClick={(e) => {
												// console.log("e : ", e);
												onClick(item);
											}}
										/>
									</Card>
									<Grid container spacing={3} column={{ xs: 4, sm: 8, md: 12 }} key={index} >
										{itemList
											.map((_item, index1) => {
												// console.log("_item : ", _item);
												if (_item.itemGrp === item.itemGrp) {
													return (
														<Grid key={index1} xs={12} md={4} sx={{ border: "solid 1px green", margin: "5px" }}>
															<DetailWrap>
																<DetailInfo>
																	{/* <InfoSubtitle>
																고유 번호
															</InfoSubtitle>
															<InfoBody>
																{item.itemGrp}
															</InfoBody> */}
																	<InfoSubtitle>
																		장치 이름
																	</InfoSubtitle>
																	<ListHeaders>
																		<ListHeaderText>{_item.machNm}</ListHeaderText>
																	</ListHeaders>
																</DetailInfo>
																<DetailInfo>
																	<InfoSubtitle>
																		장치 타입
																	</InfoSubtitle>
																	<ListHeaders>
																		<ListHeaderText>{_item.itemType}</ListHeaderText>
																	</ListHeaders>
																</DetailInfo>
															</DetailWrap>
														</Grid>
													);
												}
											})
										}
									</Grid>
								</AccordionDetails>
							</Accordion>
						</Card>
					</Grid >
				);
			})}
		</>
	);
}

export default GroupAccordion;
