import { urlB64ToUint8Array } from "./urlB64ToUint8Array.js";

// const serverUrl = `http://${window.location.hostname}:3000`;
const serverUrl = `https://v932ze2144.execute-api.ap-northeast-2.amazonaws.com`;

export const getPermission = () => {
	// Notification.requestPermission(function (status) {
	//   alert('Notification permission status:' + status);
	// });
	Notification.requestPermission().then((permission) => {
		// alert("Notification permission status:" + permission);
		// console.log("getPermission: ", permission);
	});
};

export const getSubscription = async () => {
	const { pubkey } = await fetch(`${serverUrl}/pubkey`).then((res) =>
		res.json()
	);
	alert("fetched public key:" + pubkey);
	// console.log("getSubscription1: ", pubkey);
	const subscription = await navigator.serviceWorker
		.getRegistration()
		.then((registration) => {
			return registration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlB64ToUint8Array(pubkey),
			});
		});
	alert("created subscription:" + JSON.stringify(subscription));
	// console.log("getSubscription2: ", subscription);
	// in production we would send it directly to our server and not store it on the window
	window.mySubscription = subscription;
};

export const sendToServer = async () => {
	if (!window.mySubscription) {
		alert("No subscription yet created");
		return;
	}
	const subscription = JSON.stringify(
		window.mySubscription.toJSON(),
		null,
		2
	);
	// console.log("sendToServer: ", window.mySubscription.toJSON());
	fetch(`${serverUrl}/subscription`, {
		method: "POST",
		body: subscription,
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => alert("successfully send subscription to server"))
		.catch((err) => alert("error while sending to server" + err));
};

export const sendMessageViaServer = async () => {
	const message = "hihihi"; //document.getElementById('message').value;
	const body = JSON.stringify(
		{ message, pushSubscription: window.mySubscription.toJSON() },
		null,
		2
	);
	// console.log("sendMessageViaServer: ", window.mySubscription.toJSON());

	fetch(`${serverUrl}/send`, {
		method: "POST",
		body,
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) =>
			alert("successfully send message via server to push service")
		)
		.catch((err) => alert("error while sending to server" + err));
};

export const displayNotificationDirectly = async () => {
	if (Notification.permission !== "granted") {
		alert("notification permission missing");
		return;
	}

	const options = {
		body: "This notification is send without a server, directly by the browser",
		icon: "images/checkmark.png",
		vibrate: [100, 50, 100],
		data: {
			dateOfArrival: Date.now(),
			primaryKey: 1,
		},
		actions: [
			{
				action: "explore",
				title: "Explore this new world",
				icon: "images/checkmark.png",
			},
			{
				action: "close",
				title: "Close notification",
				icon: "images/xmark.png",
			},
		],
	};

	const registration = await navigator.serviceWorker.getRegistration();
	registration.showNotification("Notification without Push API", options);
};
