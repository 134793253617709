import React from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import {
	Box,
	Button,
	IconButton,
	Stack,
	TextField,
	Unstable_Grid2 as Grid,
} from "@mui/material";
/** Icons Import */
import { ArrowBack } from "@mui/icons-material";

function ChangePw(props) {
	return (
		<Grid
			xs={12}
			lg={6}
			sx={{
				backgroundColor: "background.paper",
				display: "flex",
				flexDirection: "column",
				position: "relative",
			}}
		>
			<Box
				sx={{
					flexDirection: "column",
					display: "flex",
					flex: "1 1 auto",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "background.paper",
				}}
			>
				<Box
					sx={{
						width: "100%",
						minWidth: Commons.Dimentions().width / 5,
						maxWidth: 600,
						px: 3,
						py: "10px",
					}}
				>
					<div>
						<form noValidate>
							<Stack spacing={3}>
								<TextField
									fullWidth
									label={
										props.lanList.length > 0
											? props.lanList.filter(
													(f) =>
														f.lanId ===
														"settings_pop_current_password"
											  )[0].lanName
											: ""
									} // 현재 비밀번호
									name="now Password"
									value={props.inputs.beforePassword}
									onChange={(e) => {
										props.setInputs({
											...props.inputs,
											beforePassword: e.target.value,
										});
									}}
									autoComplete="off"
									type="password"
								/>
								<TextField
									fullWidth
									label={
										props.lanList.length > 0
											? props.lanList.filter(
													(f) =>
														f.lanId ===
														"settings_pop_new_password"
											  )[0].lanName
											: ""
									} // 변경할 비밀번호
									name="after Password"
									value={props.inputs.afterPassword}
									onChange={(e) => {
										props.setInputs({
											...props.inputs,
											afterPassword: e.target.value,
										});
									}}
									autoComplete="off"
									type="password"
								/>
								<TextField
									fullWidth
									label={
										props.lanList.length > 0
											? props.lanList.filter(
													(f) =>
														f.lanId ===
														"settings_pop_check_password"
											  )[0].lanName
											: ""
									} // 비밀번호 확인
									name="after Password Check"
									value={props.inputs.afterPasswordCheck}
									onChange={(e) => {
										props.setInputs({
											...props.inputs,
											afterPasswordCheck: e.target.value,
										});
									}}
									autoComplete="off"
									type="password"
								/>
							</Stack>
							<Button
								fullWidth
								size="large"
								sx={{ mt: 3, mb: 3 }}
								variant="contained"
								color="secondary"
								onClick={() => {
									if (props.inputs.beforePassword === "") {
										props.setMessage({
											type: "error",
											toggle: true,
											message:
												props.lanList.length > 0
													? props.lanList.filter(
															(f) =>
																f.lanId ===
																"settings_pop_checked_current_password"
													  )[0].lanName
													: "", // 현재 비밀번호를 입력하세요.
										});
									} else if (
										props.inputs.afterPassword === ""
									) {
										props.setMessage({
											type: "error",
											toggle: true,
											message:
												props.lanList.length > 0
													? props.lanList.filter(
															(f) =>
																f.lanId ===
																"settings_pop_checked_change_password"
													  )[0].lanName
													: "",
											// 변경할 비밀번호를 입력하세요.
										});
									} else if (
										props.inputs.afterPasswordCheck === ""
									) {
										props.setMessage({
											type: "error",
											toggle: true,
											message:
												props.lanList.length > 0
													? props.lanList.filter(
															(f) =>
																f.lanId ===
																"settings_pop_checked_confirm_password"
													  )[0].lanName
													: "",
											// 비밀번호를 한 번 더 입력하세요.
										});
									} else if (
										props.inputs.afterPassword !==
										props.inputs.afterPasswordCheck
									) {
										props.setMessage({
											type: "error",
											toggle: true,
											message:
												props.lanList.length > 0
													? props.lanList.filter(
															(f) =>
																f.lanId ===
																"settings_pop_checked_not_match_password"
													  )[0].lanName
													: "",
											// 비밀번호가 일치하지 않습니다.
										});
										props.setInputs({
											...props.inputs,
											afterPassword: "",
											afterPasswordCheck: "",
										});
									} else {
										props.FindUserAccount();
									}
								}}
							>
								{props.lanList.length > 0
									? props.lanList.filter(
											(f) =>
												f.lanId ===
												"settings_pop_change_password"
									  )[0].lanName
									: ""}
								{/* 비밀번호 변경 */}
							</Button>
						</form>
					</div>
				</Box>
			</Box>
		</Grid>
	);
}

export default ChangePw;
