import React from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Box } from "@mui/material";
import { SwitchingLists } from "../../Components/Conotec";
import { GroupButton } from "../../Components/Buttons";
import { Snackbar } from "../../Components/Snackbar";
/** Icons Import */
import { SwapVert as SwapIcon } from "@mui/icons-material";
/** Images Import */
import UpArrowIcon from "../../Assets/Images/Design/drawable-xhdpi/up_btn.png";
import DownArrowIcon from "../../Assets/Images/Design/drawable-xhdpi/down_btn.png";

function Swapping(props) {
	const [selected, setSelected] = React.useState(""); //컨트롤러 선택
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});

	// 순서변경 함수
	// const SwitchingList = (_arr = [], _currentData, _type = "up") => {
	// 	let currentIdx = _arr.findIndex((f) => f.id === _currentData.id);
	// 	if (_type === "up" && currentIdx === 0) {
	// 		return;
	// 	} else if (_type === "down" && currentIdx === _arr.length - 1) {
	// 		return;
	// 	}
	// 	let temp = _arr.splice(currentIdx, 1);

	// 	switch (_type) {
	// 		case "down":
	// 			temp.map((item, index) => {
	// 				item.order = currentIdx + 2;
	// 			});
	// 			_arr.splice(currentIdx + 1, 0, temp[0]);
	// 			_arr.map((item, index) => {
	// 				if (index === currentIdx) {
	// 					item.order = index + 1;
	// 				}
	// 			});
	// 			Commons.SelectionDocuments(
	// 				"controllerSelectionList",
	// 				currentIdx + 1,
	// 				_arr
	// 			);
	// 			break;
	// 		default:
	// 			temp.map((item, index) => {
	// 				item.order = currentIdx;
	// 			});
	// 			_arr.splice(currentIdx - 1, 0, temp[0]);
	// 			_arr.map((item, index) => {
	// 				if (index === currentIdx) {
	// 					item.order = index + 1;
	// 				}
	// 			});
	// 			Commons.SelectionDocuments(
	// 				"controllerSelectionList",
	// 				currentIdx - 1,
	// 				_arr
	// 			);
	// 			break;
	// 	}
	// 	props.setListData([..._arr]);
	// };

	return (
		<>
			<Box
				sx={{
					height: "92%",
					px: 1,
					overflowY: "scroll",
					bgcolor: "#4A5263",
				}}
			>
				<SwitchingLists
					items={props.listData}
					GetItem={(e) => setSelected(e)}
					setListData={props.setListData}
				/>
			</Box>
			<div
				style={{
					// position: "fixed",
					// left: "0px",
					// bottom: "8px",
					width: "100%",
				}}
			>
				{/* <GroupButton
					sx={{ my: 1, color: "#dff5fc" }}
					items={[
						{
							name: (
								<img
									style={{ width: "28px", height: "28px" }}
									src={UpArrowIcon}
								/>
							),
							onClick: (e) => {
								if (selected === "") {
									setSnacks({
										...snacks,
										open: true,
										type: "error",
										message:
											props.lanList.length > 0
												? props.lanList.filter(
														(f) =>
															f.lanId ===
															"snack_select_move_controller"
												  )[0].lanName
												: "", // 이동할 컨트롤러를 선택하세요
									});
								} else {
									SwitchingList(props.listData, selected);
								}
							},
						},
						{
							name: (
								<img
									style={{ width: "28px", height: "28px" }}
									src={DownArrowIcon}
								/>
							),
							onClick: (e) => {
								if (selected === "") {
									setSnacks({
										...snacks,
										open: true,
										type: "error",
										message:
											props.lanList.length > 0
												? props.lanList.filter(
														(f) =>
															f.lanId ===
															"snack_select_move_controller"
												  )[0].lanName
												: "", // 이동할 컨트롤러를 선택하세요
									});
								} else {
									SwitchingList(
										props.listData,
										selected,
										"down"
									);
								}
							},
						},
					]}
					color="conotecButton"
					fullWidth={true}
				/> */}
				<GroupButton
					sx={{ my: 1, color: "#dff5fc" }}
					items={[
						{
							name:
								props.lanList.length > 0
									? props.lanList.filter(
											(f) =>
												f.lanId ===
												"swapping_button_save"
									  )[0].lanName
									: "", // 저장
							onClick: (e) => {
								props.ChangeOrder();
							},
						},
						{
							name:
								props.lanList.length > 0
									? props.lanList.filter(
											(f) =>
												f.lanId ===
												"swapping_button_close"
									  )[0].lanName
									: "", // 닫기
							onClick: (e) => {
								props.MovePage("Home");
							},
						},
					]}
					color="conotecButton"
					fullWidth={true}
				/>
			</div>
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
		</>
	);
}

export default Swapping;
