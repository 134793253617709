import React, { forwardRef } from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
import dayjs from "dayjs";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import { Box, IconButton, Typography } from "@mui/material";
import { DatePicker } from "../../Components/DatePicker";
import { Combo } from "../../Components/Combos";
import { Input } from "../../Components/Inputs";
import { GroupRadio } from "../../Components/Radios";
import { Checkbox } from "../../Components/Checkboxs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { TextField } from "@mui/material";
import {
	getDate,
	getDay,
	getWeek,
	getMonth,
	subDays,
	addDays,
	subWeeks,
	addWeeks,
	subMonths,
	addMonths,
} from "date-fns";

const ContentWrap = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
}));

const ContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	margin: "8px 0px",
}));

const ContentItemTitle = styled((props) => <div {...props} />)(({ theme }) => ({
	width: "30%",
}));

function ScheduleContent({
	bookingData = {}, // 예약 데이터
	setBookingData = () => { }, // 예약 데이터 수정
	mapAddItem = [], // 장치 설정 명
	RegisterValue = (e) => { }, // 예약시 선택된 레지스터 주소에 따른 변경값 입력
	registerValue = {}, // 예약등록 팝업의 값
	setRegisterValue = () => { }, // 예약등록 팝업의 값 입력
	setMessage = () => { },
	nowUnit = {}, // 현재 장치의 데이터
	lanList = [],
}) {
	const [checked, setChecked] = React.useState(false);
	const radioItems = [
		{
			name:
				lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId === "dashboard_pop_schedule_repeat_none"
					)[0].lanName
					: "", // 반복 없음
			value: "0",
		},
		{
			name:
				lanList.length > 0
					? lanList.filter(
						(f) => f.lanId === "dashboard_pop_schedule_everyday"
					)[0].lanName
					: "", // 매일
			value: "1",
		},
		{
			name:
				lanList.length > 0
					? lanList.filter(
						(f) => f.lanId === "dashboard_pop_schedule_week"
					)[0].lanName
					: "", // 매주
			value: "2",
		},
		{
			name:
				lanList.length > 0
					? lanList.filter(
						(f) => f.lanId === "dashboard_pop_schedule_month"
					)[0].lanName
					: "", // 매달
			value: "3",
		},
	];

	// 현재 온도 단위가 C(nowUnit.tempUnit === 0) 일 때의 패턴
	const CheckPatternC = (e) => {
		const pattern0 = /[^-0-9\.]/g; // 숫자, ., -만 허용
		const pattern1 = /^\.-/g; // .- 방지
		const pattern2 = /^-\./g; // -. 방지
		const pattern3 = /^\.\d+/g; // .숫자 방지
		const pattern4 = /\d+-/g; // 숫자- 방지
		const pattern5 = /\.[0-9]*\./g; // .숫자. 방지
		const pattern6 = /--/g; // -- 방지
		const pattern7 = /\.\./g; // ..시 -로 변경

		if (pattern7.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.replace(pattern7, "-"),
			});
			setBookingData({
				...bookingData,
				aftValue: e.replace(pattern7, "-"),
			});
		} else if (pattern0.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.replace(pattern0, ""),
			});
			setBookingData({
				...bookingData,
				aftValue: e.replace(pattern0, ""),
			});
		} else if (pattern1.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.replace(pattern1, ""),
			});
			setBookingData({
				...bookingData,
				aftValue: e.replace(pattern1, ""),
			});
		} else if (pattern2.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.replace(pattern2, ""),
			});
			setBookingData({
				...bookingData,
				aftValue: e.replace(pattern2, ""),
			});
		} else if (pattern3.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.replace(".", ""),
			});
			setBookingData({
				...bookingData,
				aftValue: e.replace(".", ""),
			});
		} else if (pattern4.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.slice(0, -2),
			});
			setBookingData({
				...bookingData,
				aftValue: e.slice(0, -2),
			});
		} else if (pattern5.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.slice(0, -1),
			});
			setBookingData({
				...bookingData,
				aftValue: e.slice(0, -1),
			});
		} else if (pattern6.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.replace(pattern6, ""),
			});
			setBookingData({
				...bookingData,
				aftValue: e.replace(pattern6, ""),
			});
		} else {
			setRegisterValue({
				...registerValue,
				value: e,
			});
			setBookingData({
				...bookingData,
				aftValue: e,
			});
		}
	};

	// 현재 온도 단위가 F(nowUnit.tempUnit === 1) 일 때의 패턴
	const CheckPatternF = (e) => {
		const pattern0 = /[^-0-9]/g; // 숫자, -만 허용
		const pattern1 = /\d+-/g; // 숫자- 방지
		const pattern2 = /--/g; // -- 방지

		if (pattern0.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.replace(pattern0, ""),
			});
			setBookingData({
				...bookingData,
				aftValue: e.replace(pattern0, ""),
			});
		} else if (pattern1.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.slice(0, -1),
			});
			setBookingData({
				...bookingData,
				aftValue: e.slice(0, -1),
			});
		} else if (pattern2.test(e)) {
			setRegisterValue({
				...registerValue,
				value: e.replace(pattern2, ""),
			});
			setBookingData({
				...bookingData,
				aftValue: e.replace(pattern2, ""),
			});
		} else {
			setRegisterValue({
				...registerValue,
				value: e,
			});
			setBookingData({
				...bookingData,
				aftValue: e,
			});
		}
	};

	const ReturnComponents = (_item) => {
		switch (_item.mapUnit) {
			case "분초":
			case "MinSec":
				// let defaultValue = dayjs(
				// 	Commons.DateFormating(new Date(), 2) +
				// 	"T00:" +
				// 	_item.value1.toString().padStart(2, "0") +
				// 	":" +
				// 	_item.value2.toString().padStart(2, "0")
				// );
				return (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<MobileTimePicker
								sx={{ width: "70%" }}
								label={
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"sm10000_pop_min_sec"
										)[0].lanName
										: "" // "분:초"
								}
								openTo="minutes"
								views={["minutes", "seconds"]}
								format="mm:ss"
								// defaultValue={defaultValue}
								onChange={(e) => {
									setRegisterValue({
										..._item,
										value: "timepicker",
										value1: e.minute(),
										value2: e.second(),
									});
									setBookingData({
										...bookingData,
										aftValue: [e.minute().toString(), e.second().toString()],
									});
								}}
								disabled={checked ? true : false}
							/>
						</LocalizationProvider>
						<Checkbox
							size="large"
							name={
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"sm10000_pop_60minutes"
									)[0].lanName
									: "" // 60분
							}
							value={checked}
							onChange={(e) => {
								setChecked(e);
								if (e) {
									setRegisterValue({
										..._item,
										value: "timepicker",
										value1: "60",
										value2: "0",
									});
									setBookingData({
										...bookingData,
										aftValue: ["60", "0"],
									});
								}
							}}
						/>
						{/* {_item.mapUnit === "분" && (
							<Checkbox
								sx={{ width: "70%", marginLeft: "30px" }}
								size="large"
								name="60분"
								value={checked}
								onChange={(e) => {
									setChecked(e);
									if (e) {
										setRegisterValue({
											..._item,
											value: "timepicker",
											value1: "60",
											value2: "0",
										});
										setBookingData({
											...bookingData,
											aftValue: "60",
										});
									}
								}}
							/>
						)} */}
					</div>
				);
			default:
				return (
					<Input
						sx={{ width: "70%" }}
						type="outlined"
						placeholder={_item.beforeValue}
						value={_item.value}
						inputMode="text"
						helperText={_item.message}
						inputProps={{
							// min: _item.min,
							// max: _item.max,
							// step: _item.stepType === "float" ? 0.1 : 1,
							inputMode: "numeric",
						}}
						onChange={(e) => {
							if (nowUnit.tempUnit === "0") CheckPatternC(e);
							else CheckPatternF(e);
						}}
					/>
				);
		}
	};

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<TextField
			size="small"
			variant="outlined"
			value={value}
			onClick={onClick}
			autoFocus={false}
			ref={ref}
			inputProps={{
				inputMode: "none",
			}}
		/>
	));

	return (
		<ContentWrap>
			<ContentItems>
				<ContentItemTitle>
					{lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"dashboard_pop_schedule_repeat_settings"
						)[0].lanName
						: ""}
					{/* 반복 설정 */}
				</ContentItemTitle>
				<GroupRadio
					value={bookingData.rotation}
					items={radioItems}
					onChange={(e) =>
						setBookingData({
							...bookingData,
							rotation: e,
						})
					}
				/>
			</ContentItems>
			<ContentItems>
				<ContentItemTitle>
					{lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"dashboard_gen_schedule_list_date"
						)[0].lanName
						: ""}
					{/* 예약 일자 */}
				</ContentItemTitle>
				<DatePicker
					className={"datepicker-custom-input4"}
					selected={bookingData.appDate}
					minDate={new Date()}
					filterTime={(time) => {
						let currentTime = new Date();
						let selectedTime = new Date(time);

						return currentTime.getTime() < selectedTime.getTime();
					}}
					onChange={(e) => {
						// 반복 없을 시 종료일자는 시작일자와 같음
						if (bookingData.rotation !== "0") {
							setBookingData({
								...bookingData,
								appDate: e,
							});
						} else {
							setBookingData({
								...bookingData,
								appDate: e,
								endDate: e,
							});
						}
					}}
					withPortal={true}
					showTimeSelect={true}
					// showWeekPicker
					dateFormat="yyyy-MM-dd HH:mm"
					customInput={<CustomInput />}
				/>
			</ContentItems>
			{bookingData.rotation !== "0" && (
				<ContentItems>
					<ContentItemTitle>종료 일자</ContentItemTitle>
					<DatePicker
						className={"datepicker-custom-input4"}
						selected={bookingData.endDate}
						minDate={bookingData.appDate}
						onChange={(e) => {
							setBookingData({
								...bookingData,
								endDate: e,
							});
						}}
						showMonthYearPicker={
							bookingData.rotation === "3" ? true : false
						}
						withPortal={true}
						dateFormat="yyyy-MM-dd"
						customInput={<CustomInput />}
					/>
				</ContentItems>
			)}
			<ContentItems>
				<ContentItemTitle>
					{lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"dashboard_pop_schedule_device_settings"
						)[0].lanName
						: ""}
					{/* 장치 설정명 */}
				</ContentItemTitle>
				<Combo
					sx={{ width: "70%" }}
					name=""
					value={bookingData.mapAdd}
					items={mapAddItem}
					onChange={(e) => {
						setBookingData({
							...bookingData,
							mapAdd: e,
						});
						RegisterValue(e);
					}}
				/>
			</ContentItems>
			<ContentItems>
				<ContentItemTitle>
					{lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"dashboard_gen_schedule_list_ch_value"
						)[0].lanName
						: ""}
					{/* 변경값 */}
				</ContentItemTitle>
				{registerValue.inputType === "1" ? (
					ReturnComponents(registerValue)
				) : (
					<Combo
						sx={{
							width: "70%",
						}}
						type="outlined"
						name=""
						value={registerValue.value}
						items={registerValue.inputs}
						onChange={(e) => {
							setRegisterValue({
								...registerValue,
								value: e,
							});
							setBookingData({
								...bookingData,
								aftValue: e,
							});
						}}
					/>
				)}
			</ContentItems>
		</ContentWrap>
	);
}

export default ScheduleContent;
