import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Styles Import */
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components Import */
import BM10020View from "../../Views/BaseManagements/BM10020";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
/** Images Import */
/** Icons Import */

/** 컨트롤러 등록 */
function BM10020(props) {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [codeList, setCodeList] = React.useState([]);
	const [rowData, setRowData] = React.useState([]); // 좌측 그리드에 보여주는 데이터
	const [selectedRowData, setSelectedRowData] = React.useState([]); // 선택된 행을 저장함
	const [changedRowData, setChangedRowData] = React.useState([]); // 수정된 행을 저장함
	const [checkings, setCheckings] = React.useState({
		// 데이터의 중복을 체크하는 변수(해당 테이블의 키)
		itemCd: "",
	});
	const [rowData1, setRowData1] = React.useState([]); // 우측 상단 그리드에 보여주는 데이터
	const [selectedRowData1, setSelectedRowData1] = React.useState([]); // 선택된 행을 저장함  		=> 삭제 로직 없음 === 사용 X
	const [changedRowData1, setChangedRowData1] = React.useState([]); // 수정된 행을 저장함
	const [checkings1, setCheckings1] = React.useState({
		// 데이터의 중복을 체크하는 변수(해당 테이블의 키)
		itemIdx: "",
		mapAdd: "",
	});
	const [rowData2, setRowData2] = React.useState([]); // 우측 하단 그리드에 보여주는 데이터
	const [selectedRowData2, setSelectedRowData2] = React.useState([]); // 선택된 행을 저장함  		=> 삭제 로직 없음 === 사용 X
	const [changedRowData2, setChangedRowData2] = React.useState([]); // 수정된 행을 저장함
	const [checkings2, setCheckings2] = React.useState({
		// 데이터의 중복을 체크하는 변수(해당 테이블의 키)
		itemIdx: "",
		mapAdd: "",
		val: "",
	});
	const [inputs, setInputs] = React.useState({
		// 파일 업로드 변수
		mapFiles: "",
		mapFileName: "",
		valFiles: "",
		valFileName: "",
	});
	const [lanList, setLanList] = React.useState([]); // language list
	const [userLanCode, SetUserLanCode] = React.useState("kr"); // 사용자 설정 언어코드. 기본 한국어

	React.useEffect(() => {
		Init();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
		IndexedDb.GetLoginInfo().then((result) => {
			SetUserLanCode(result.lanCode);
		});
	}, []);

	//좌측 그리드 데이터 조회
	const Init = async () => {
		setProgress(true);
		let tempCode = [];
		let tempArr = [];
		let lans = [];
		let lanCode = "";
		let search = true;

		try {
			await IndexedDb.GetCodeAll().then((result) => {
				tempCode = result;
			});

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			// 사용자 언어코드 확인
			await IndexedDb.GetLoginInfo().then((result) => {
				lanCode = result.lanCode;
			});


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "BM10020").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10020.GetCode
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data.length > 0) {
					// console.log("data: ", data);
					data.forEach((item) => {
						tempArr.push({
							...item,
							itemQcInspYn:
								lanCode === "kr"
									? item.itemQcInspYn === "Y"
										? "사용"
										: "미사용"
									: item.itemQcInspYn === "Y"
										? "Used"
										: "Not used",
							conForm:
								item.conForm !== null && item.conForm !== ""
									? lanCode === "en"
										? tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"CON_FORM" &&
												_item.code === item.conForm
										)[0].ref3
										: tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"CON_FORM" &&
												_item.code === item.conForm
										)[0].name
									: "",

							conType:
								item.conType !== null && item.conType !== ""
									? lanCode === "en"
										? tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"CON_TYPE" &&
												_item.code === item.conType
										)[0].ref3
										: tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"CON_TYPE" &&
												_item.code === item.conType
										)[0].name
									: "",
							itemType:
								item.itemType !== null && item.itemType !== ""
									? lanCode === "en"
										? tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"ITEM_TYPE" &&
												_item.code === item.itemType
										)[0].ref3
										: tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"ITEM_TYPE" &&
												_item.code === item.itemType
										)[0].name
									: "",
							lCatego:
								item.lCatego !== null && item.lCatego !== ""
									? lanCode === "en"
										? tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"L_CATEGO" &&
												_item.code === item.lCatego
										)[0].ref3
										: tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"L_CATEGO" &&
												_item.code === item.lCatego
										)[0].name
									: "",
							mCatego:
								item.mCatego !== null && item.mCatego !== ""
									? lanCode === "en"
										? tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"M_CATEGO" &&
												_item.code === item.mCatego
										)[0].ref3
										: tempCode.filter(
											(_item) =>
												_item.groupCd ===
												"M_CATEGO" &&
												_item.code === item.mCatego
										)[0].name
									: "",
							itemVatYn:
								lanCode === "kr"
									? item.itemVatYn === "Y"
										? "사용"
										: "미사용"
									: item.itemVatYn === "Y"
										? "Used"
										: "Not used",
							tmpYn:
								lanCode === "kr"
									? item.tmpYn === "Y"
										? "사용"
										: "미사용"
									: item.tmpYn === "Y"
										? "Used"
										: "Not used",
							tmpPointYn:
								lanCode === "kr"
									? item.tmpPointYn === "Y"
										? "사용"
										: "미사용"
									: item.tmpPointYn === "Y"
										? "Used"
										: "Not used",
							hmdYn:
								lanCode === "kr"
									? item.hmdYn === "Y"
										? "사용"
										: "미사용"
									: item.hmdYn === "Y"
										? "Used"
										: "Not used",
							hmdPointYn:
								lanCode === "kr"
									? item.hmdPointYn === "Y"
										? "사용"
										: "미사용"
									: item.hmdPointYn === "Y"
										? "Used"
										: "Not used",
							illYn:
								lanCode === "kr"
									? item.illYn === "Y"
										? "사용"
										: "미사용"
									: item.illYn === "Y"
										? "Used"
										: "Not used",
							co2Yn:
								lanCode === "kr"
									? item.co2Yn === "Y"
										? "사용"
										: "미사용"
									: item.co2Yn === "Y"
										? "Used"
										: "Not used",
							runTimeCheckYn:
								lanCode === "kr"
									? item.runTimeCheckYn === "Y"
										? "사용"
										: "미사용"
									: item.runTimeCheckYn === "Y"
										? "Used"
										: "Not used",
							updateDate: Commons.DateFormating(
								Commons.StringtoDate(item.updateDate, 3)
							),
						});
					});
					setRowData(tempArr);
				} else {
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "" // 조회 실패!"
					);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{
									lans.length > 0
										? lans.filter(
											(f) =>
												f.lanId ===
												"snack_information_expired"
										)[0].lanName
										: "" // 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{
									lans.length > 0
										? lans.filter(
											(f) =>
												f.lanId ===
												"snack_login_again"
										)[0].lanName
										: "" // 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 우측 상단 그리드 데이터 조회
	const GetDtlList = async (_selectedData) => {
		setProgress(true);
		let tempDtlList = [];

		try {
			if (_selectedData.length === 0) {
				setRowData1([]);
				setRowData2([]);
				setChangedRowData1([]);
				setChangedRowData2([]);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.GetRegister,
					{
						itemIdx: _selectedData[0].itemIdx,
					}
				).then((result) => {
					let data = result.data;
					// console.log("data : ", data);

					if (data.type === "Error") {
						setRowData1([]);
						setRowData2([]);
						setChangedRowData1([]);
						setChangedRowData2([]);
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						setRowData1([]);
						setRowData2([]);
						setChangedRowData1([]);
						setChangedRowData2([]);
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId === "snack_unavailable_data"
								)[0].lanName
								: "" // 데이터가 없습니다.
						);
					} else if (data) {
						data.forEach((_item) => {
							tempDtlList.push({
								..._item,
								mapType:
									_item.mapType !== null &&
										_item.mapType !== ""
										? userLanCode === "en"
											? codeList.filter(
												(item) =>
													item.groupCd ===
													"MAP_TYPE" &&
													item.code ===
													_item.mapType
											)[0].ref3
											: codeList.filter(
												(item) =>
													item.groupCd ===
													"MAP_TYPE" &&
													item.code ===
													_item.mapType
											)[0].name
										: "",
								inputType:
									_item.inputType !== null &&
										_item.inputType !== ""
										? userLanCode === "en"
											? codeList.filter(
												(item) =>
													item.groupCd ===
													"INPUT_TYPE" &&
													item.code ===
													_item.inputType
											)[0].ref3
											: codeList.filter(
												(item) =>
													item.groupCd ===
													"INPUT_TYPE" &&
													item.code ===
													_item.inputType
											)[0].name
										: "",
								copyType:
									userLanCode === "en"
										? _item.copyType === "Y"
											? "Used"
											: "Not used"
										: _item.copyType === "Y"
											? "사용"
											: "미사용",
								arDataType:
									_item.arDataType !== null &&
										_item.arDataType !== ""
										? userLanCode === "kr"
											? codeList.filter(
												(item) =>
													item.groupCd ===
													"AR_DATA_TYPE" &&
													item.code ===
													_item.arDataType
											)[0].name
											: codeList.filter(
												(item) =>
													item.groupCd ===
													"AR_DATA_TYPE" &&
													item.code ===
													_item.arDataType
											)[0].ref3
										: "",
								mapAddImgCd:
									_item.mapAddImgCd !== null &&
										_item.mapAddImgCd !== ""
										? userLanCode === "kr"
											? codeList.filter(
												(item) =>
													item.groupCd ===
													"MAP_IMAGE_CODE" &&
													item.code ===
													_item.mapAddImgCd
											)[0].name
											: codeList.filter(
												(item) =>
													item.groupCd ===
													"MAP_IMAGE_CODE" &&
													item.code ===
													_item.mapAddImgCd
											)[0].ref3
										: "",
								moDpYn:
									userLanCode === "en"
										? _item.moDpYn === "Y"
											? "Used"
											: "Not used"
										: _item.moDpYn === "Y"
											? "사용"
											: "미사용",
								useYn:
									userLanCode === "en"
										? _item.useYn === "Y"
											? "Used"
											: "Not used"
										: _item.useYn === "Y"
											? "사용"
											: "미사용",
								updateDate: Commons.DateFormating(
									Commons.StringtoDate(_item.updateDate, 3)
								),
							});
						});

						setRowData1(tempDtlList);
						setChangedRowData1([]);
						setChangedRowData2([]);
					} else {
						setRowData1([]);
						setRowData2([]);
						setChangedRowData1([]);
						setChangedRowData2([]);
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_register_map_failed"
								)[0].lanName
								: "" // 레지스터 맵 조회 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 하단 그리드 데이터 조회
	const GetValList = async (_selectedData) => {
		setProgress(true);
		let tempDtlList = [];
		try {
			// console.log("_selectedData : ", _selectedData);
			if (_selectedData.length === 0) {
				setRowData2([]);
				setChangedRowData1([]);
				setChangedRowData2([]);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.GetRegisterVal,
					{
						itemIdx: _selectedData[0].itemIdx,
						mapAdd: _selectedData[0].mapAdd,
					}
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						setRowData2([]);
						setChangedRowData2([]);
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						setRowData2([]);
						// throw new Error(
						// 	(lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_unavailable_data"
						// 		)[0].lanName
						// 		: "")	// 데이터가 없습니다.
						// );
					} else if (data) {
						data.forEach((_item) => {
							tempDtlList.push({
								..._item,
								updateDate: Commons.DateFormating(
									Commons.StringtoDate(_item.updateDate, 3)
								),
							});
						});

						setRowData2(tempDtlList);
						setChangedRowData2([]);
					} else {
						setRowData2([]);
						setChangedRowData2([]);
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_register_value_failed"
								)[0].lanName
								: "" // 레지스터 값 조회 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 좌측 그리드 중복 데이터 검사
	const CheckingLogic = async (_gridref) => {
		setProgress(true);
		try {
			if (!checkings.itemCd) {
				// 1. 아이템 코드가 없음
				setSnacks({
					...snacks,
					open: true,
					type: "error",
					message:
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_enter_item_code"
							)[0].lanName
							: "", // 아이템 코드를 입력하세요.
				});
				return false;
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.CheckCode,
					{
						itemCd: checkings.itemCd,
					}
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId === "snack_unavailable_data"
								)[0].lanName
								: "" // 데이터가 없습니다.
						);
					} else if (data === true) {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_available_code"
						// 			)[0].lanName
						// 			: "", // 사용 가능한 코드입니다
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_available_code"
									)[0].lanName
									: "", // 사용 가능한 코드입니다
						});
						let currentData = [
							{
								itemCd: checkings.itemCd,
								newYn: "Y",
							},
							...rowData,
						];
						_gridref.current.api.setRowData(currentData);
						setRowData(currentData);
						setChangedRowData([
							...changedRowData,
							{
								itemCd: checkings.itemCd,
								newYn: "Y",
							},
						]);
						dispatch(DrawerActions.setNotOpen()); // 열림 방지
						return true;
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_add_device_failed"
								)[0].lanName
								: "" // 장치 품목 추가 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 상단 그리드 중복 데이터 검사
	const CheckingLogic1 = async (_gridref) => {
		setProgress(true);
		try {
			if (!checkings1.mapAdd) {
				// 1. 대분류코드가 없음
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message:
				// 		lanList.length > 0
				// 			? lanList.filter(
				// 				(f) => f.lanId === "snack_enter_address"
				// 			)[0].lanName
				// 			: "", // 주소를 입력하세요.
				// });
				// return false;
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_enter_address"
						)[0].lanName
						: "", // 주소를 입력하세요.
				);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.CheckRegister,
					{
						itemIdx: checkings1.itemIdx,
						mapAdd: checkings1.mapAdd,
					}
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId === "snack_unavailable_data"
								)[0].lanName
								: "" // 데이터가 없습니다.
						);
					} else if (data === true) {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_available_code"
						// 			)[0].lanName
						// 			: "", // 사용 가능한 코드입니다
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_available_code"
									)[0].lanName
									: "", // 사용 가능한 코드입니다
						});
						let currentData = [
							{
								itemIdx: checkings1.itemIdx,
								mapAdd: checkings1.mapAdd,
								newYn: "Y",
							},
							...rowData1,
						];
						_gridref.current.api.setRowData(currentData);
						setRowData1(currentData);
						setChangedRowData1([
							...changedRowData1,
							{
								itemIdx: checkings1.itemIdx,
								mapAdd: checkings1.mapAdd,
								newYn: "Y",
							},
						]);
						dispatch(DrawerActions.setNotOpen()); // 열림 방지
						return true;
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_check_failed"
								)[0].lanName
								: "" // 확인 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 하단 그리드 중복 데이터 검사
	const CheckingLogic2 = async (_gridref) => {
		setProgress(true);
		try {
			// console.log("CheckingLogic2 : ", checkings2);
			if (!checkings2.val) {
				// 2. Register 값이 없음
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message:
				// 		lanList.length > 0
				// 			? lanList.filter(
				// 				(f) => f.lanId === "snack_enter_value"
				// 			)[0].lanName
				// 			: "", // 값을 입력하세요.
				// });
				// return false;
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_enter_value"
						)[0].lanName
						: "", // 값을 입력하세요.
				);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.CheckRegisterVal,
					{
						itemIdx: checkings2.itemIdx,
						mapAdd: checkings2.mapAdd,
						val: checkings2.val,
					}
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId === "snack_unavailable_data"
								)[0].lanName
								: "" // 데이터가 없습니다.
						);
					} else if (data === true) {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_available_code"
						// 			)[0].lanName
						// 			: "", // 사용 가능한 코드입니다
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_available_code"
									)[0].lanName
									: "", // 사용 가능한 코드입니다
						});
						let currentData = [
							{
								itemIdx: checkings2.itemIdx,
								mapAdd: checkings2.mapAdd,
								val: checkings2.val,
								newYn: "Y",
							},
							...rowData2,
						];
						_gridref.current.api.setRowData(currentData);
						setRowData2(currentData);
						setChangedRowData2([
							...changedRowData2,
							{
								itemIdx: checkings2.itemIdx,
								mapAdd: checkings2.mapAdd,
								val: checkings2.val,
								newYn: "Y",
							},
						]);
						dispatch(DrawerActions.setNotOpen()); // 열림 방지
						return true;
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_check_failed"
								)[0].lanName
								: "" // 확인 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 좌측 그리드 데이터 저장
	const CreateLogic = async () => {
		setProgress(true);
		let userInfo = null;
		let tempChangeArr = [];
		let changedArr = [];

		try {
			if (changedRowData.length <= 0) {
				// 수정된 행 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else {
				await IndexedDb.GetLoginInfo().then((result) => {
					userInfo = result;
				});

				tempChangeArr = changedRowData.reverse().reduce((prev, now) => {
					if (!prev.some((obj) => obj.itemCd === now.itemCd)) {
						prev.push(now);
					}
					return prev;
				}, []);

				tempChangeArr.forEach((item) => {
					changedArr.push({
						...item,
						itemQcInspYn:
							item.itemQcInspYn === "사용" ||
								item.itemQcInspYn === "Used"
								? "Y"
								: "N",
						conForm:
							item.conForm !== null &&
								item.conForm !== "" &&
								item.conForm !== undefined
								? codeList.filter(
									(_item) =>
										_item.groupCd === "CON_FORM" &&
										(_item.name === item.conForm ||
											_item.ref3 === item.conForm)
								)[0].code
								: "",
						conType:
							item.conType !== null &&
								item.conType !== "" &&
								item.conType !== undefined
								? codeList.filter(
									(_item) =>
										_item.groupCd === "CON_TYPE" &&
										(_item.name === item.conType ||
											_item.ref3 === item.conType)
								)[0].code
								: "",
						itemType:
							item.itemType !== null &&
								item.itemType !== "" &&
								item.itemType !== undefined
								? codeList.filter(
									(_item) =>
										_item.groupCd === "ITEM_TYPE" &&
										(_item.name === item.itemType ||
											_item.ref3 === item.itemType)
								)[0].code
								: "",
						lCatego:
							item.lCatego !== null &&
								item.lCatego !== "" &&
								item.lCatego !== undefined
								? codeList.filter(
									(_item) =>
										_item.groupCd === "L_CATEGO" &&
										(_item.name === item.lCatego ||
											_item.ref3 === item.lCatego)
								)[0].code
								: "",
						mCatego:
							item.mCatego !== null &&
								item.mCatego !== "" &&
								item.mCatego !== undefined
								? codeList.filter(
									(_item) =>
										_item.groupCd === "M_CATEGO" &&
										(_item.name === item.mCatego ||
											_item.ref3 === item.mCatego)
								)[0].code
								: "",
						itemVatYn:
							item.itemVatYn === "사용" ||
								item.itemVatYn === "Used"
								? "Y"
								: "N",
						tmpYn:
							item.tmpYn === "사용" || item.tmpYn === "Used"
								? "Y"
								: "N",
						tmpPointYn:
							item.tmpPointYn === "사용" || item.tmpPointYn === "Used"
								? "Y"
								: "N",
						hmdYn:
							item.hmdYn === "사용" || item.hmdYn === "Used"
								? "Y"
								: "N",
						hmdPointYn:
							item.hmdPointYn === "사용" || item.hmdPointYn === "Used"
								? "Y"
								: "N",
						illYn:
							item.illYn === "사용" || item.illYn === "Used"
								? "Y"
								: "N",
						co2Yn:
							item.co2Yn === "사용" || item.co2Yn === "Used"
								? "Y"
								: "N",
						runTimeCheckYn:
							item.runTimeCheckYn === "사용" ||
								item.runTimeCheckYn === "Used"
								? "Y"
								: "N",
						createId: userInfo.userId,
						createDate: Commons.DateFormating(new Date(), 5),
						updateId: userInfo.userId,
						updateDate: Commons.DateFormating(new Date(), 5),
					});
				});

				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.Create,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						setRowData1([]);
						setRowData2([]);
						setChangedRowData1([]);
						setChangedRowData2([]);
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_saved"
						// 			)[0].lanName
						// 			: "", // 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_saved"
									)[0].lanName
									: "", // 저장 되었습니다!
						});
						setRowData1([]);
						setRowData2([]);
						setChangedRowData([]);
						setChangedRowData1([]);
						setChangedRowData2([]);
						setSelectedRowData([]);
						setSelectedRowData1([]);
						setSelectedRowData2([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init();
					} else {
						setRowData1([]);
						setRowData2([]);
						setChangedRowData1([]);
						setChangedRowData2([]);
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_save_failed"
								)[0].lanName
								: "" // 저장 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 상단 그리드 데이터 저장
	const CreateLogic1 = async () => {
		setProgress(true);
		let tempArr = [];
		let changedArr = [];

		try {
			if (changedRowData1.length <= 0) {
				// 수정된 행 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else {
				tempArr = changedRowData1.reverse().reduce((prev, now) => {
					if (!prev.some((obj) => obj.mapAdd === now.mapAdd)) {
						prev.push(now);
					}
					return prev;
				}, []);

				tempArr.forEach((_item) => {
					changedArr.push({
						..._item,
						mapType:
							_item.mapType !== undefined &&
								_item.mapType !== null &&
								_item.mapType !== ""
								? codeList.filter(
									(item) =>
										item.groupCd === "MAP_TYPE" &&
										(item.name === _item.mapType ||
											item.ref3 === _item.mapType)
								)[0].code
								: "",
						inputType:
							_item.inputType !== undefined &&
								_item.inputType !== null &&
								_item.inputType !== ""
								? codeList.filter(
									(item) =>
										item.groupCd === "INPUT_TYPE" &&
										(item.name === _item.inputType ||
											item.ref3 === _item.inputType)
								)[0].code
								: "",
						copyType:
							_item.copyType === "사용" ||
								_item.copyType === "Used"
								? "Y"
								: "N",
						arDataType:
							_item.arDataType !== undefined &&
								_item.arDataType !== null &&
								_item.arDataType !== ""
								? codeList.filter(
									(item) =>
										item.groupCd === "AR_DATA_TYPE" &&
										(item.name === _item.arDataType ||
											item.ref3 === _item.arDataType)
								)[0].code
								: "",
						mapAddImgCd:
							_item.mapAddImgCd !== undefined &&
								_item.mapAddImgCd !== null &&
								_item.mapAddImgCd !== ""
								? codeList.filter(
									(item) =>
										item.groupCd === "MAP_IMAGE_CODE" &&
										(item.name === _item.mapAddImgCd ||
											item.ref3 === _item.mapAddImgCd)
								)[0].code
								: "",
						moDpYn:
							_item.moDpYn === "사용" || _item.moDpYn === "Used"
								? "Y"
								: "N",
						useYn: "Y",
						updateDate: Commons.DateFormating(new Date(), 5),
					});
				});

				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.CreateRegister,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_saved"
						// 			)[0].lanName
						// 			: "", // 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_saved"
									)[0].lanName
									: "", // 저장 되었습니다!
						});
						setChangedRowData1([]);
						setChangedRowData2([]);
						setSelectedRowData1([]);
						setSelectedRowData2([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						GetDtlList(selectedRowData);
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_save_failed"
								)[0].lanName
								: "" // 저장 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 하단 그리드 데이터 저장
	const CreateLogic2 = async () => {
		setProgress(true);
		let tempArr = [];
		let changedArr = [];

		try {
			if (changedRowData2.length <= 0) {
				// 수정된 행 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else {
				tempArr = changedRowData2.reverse().reduce((prev, now) => {
					if (!prev.some((obj) => obj.val === now.val)) {
						prev.push(now);
					}
					return prev;
				}, []);

				tempArr.forEach((f) => {
					changedArr.push({
						...f,
						useYn: "Y",
					});
				});

				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.CreateRegisterVal,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_saved"
						// 			)[0].lanName
						// 			: "", // 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_saved"
									)[0].lanName
									: "", // 저장 되었습니다!
						});
						setChangedRowData2([]);
						setSelectedRowData2([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						GetValList(selectedRowData1);
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_save_value_failed"
								)[0].lanName
								: "" // 값 저장 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 좌측 그리드 데이터 삭제
	const DeleteLogic = async () => {
		setProgress(true);
		let userInfo = null;
		let changedArr = [];
		let tempNewYn = selectedRowData.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_cannot_delete"
						)[0].lanName
						: "" // 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				await IndexedDb.GetLoginInfo().then((result) => {
					userInfo = result;
				});

				selectedRowData.forEach((_item) => {
					changedArr.push({
						..._item,
						createId: userInfo.userId,
						createDate: Commons.DateFormating(new Date(), 5),
						updateId: userInfo.userId,
						updateDate: Commons.DateFormating(new Date(), 5),
					});
				});

				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.Delete,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_deleted"
						// 			)[0].lanName
						// 			: "", // 삭제 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_deleted"
									)[0].lanName
									: "", // 삭제 되었습니다!
						});
						setRowData1([]);
						setRowData2([]);
						setSelectedRowData([]);
						setChangedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init();
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_delete_failed"
								)[0].lanName
								: "" // 삭제 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 상단 그리드 데이터 삭제
	const DeleteLogic1 = async () => {
		setProgress(true);
		let changedArr = [];
		let tempNewYn = selectedRowData1.filter(
			(item) => item.newYn !== undefined && item.newYn == "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData1.length === 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_cannot_delete"
						)[0].lanName
						: "" // 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				selectedRowData1.forEach((_item, index) => {
					changedArr.push({
						..._item,
						mapType:
							_item.mapType !== undefined &&
								_item.mapType !== null &&
								_item.mapType !== ""
								? codeList.filter(
									(item) =>
										item.groupCd === "MAP_TYPE" &&
										(item.name === _item.mapType ||
											item.ref3 === _item.mapType)
								)[0].code
								: "",
						inputType:
							_item.inputType !== undefined &&
								_item.inputType !== null &&
								_item.inputType !== ""
								? codeList.filter(
									(item) =>
										item.groupCd === "INPUT_TYPE" &&
										(item.name === _item.inputType ||
											item.ref3 === _item.inputType)
								)[0].code
								: "",
						copyType:
							_item.copyType === "사용" ||
								_item.copyType === "Used"
								? "Y"
								: "N",
						arDataType:
							_item.arDataType !== undefined &&
								_item.arDataType !== null &&
								_item.arDataType !== ""
								? codeList.filter(
									(item) =>
										item.groupCd === "AR_DATA_TYPE" &&
										(item.name === _item.arDataType ||
											item.ref3 === _item.arDataType)
								)[0].code
								: "",
						mapAddImgCd:
							_item.mapAddImgCd !== undefined &&
								_item.mapAddImgCd !== null &&
								_item.mapAddImgCd !== ""
								? codeList.filter(
									(item) =>
										item.groupCd === "MAP_IMAGE_CODE" &&
										(item.name === _item.mapAddImgCd ||
											item.ref3 === _item.mapAddImgCd)
								)[0].code
								: "",
						moDpYn:
							_item.moDpYn === "사용" || _item.moDpYn === "Used"
								? "Y"
								: "N",
						useYn:
							_item.useYn === "사용" || _item.useYn === "Used"
								? "Y"
								: "N",
						updateDate: Commons.DateFormating(new Date(), 5),
					});
				});

				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.DeleteRegister,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_deleted"
						// 			)[0].lanName
						// 			: "", // 삭제되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_deleted"
									)[0].lanName
									: "", // 삭제되었습니다!
						});
						setRowData2([]);
						setSelectedRowData1([]);
						setChangedRowData1([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						GetDtlList(selectedRowData1);
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_delete_failed"
								)[0].lanName
								: "" // 삭제 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 하단 그리드 데이터 삭제
	const DeleteLogic2 = async () => {
		setProgress(true);
		let tempNewYn = selectedRowData2.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData2.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				// console.log("tempNewYn : ", tempNewYn);
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_cannot_delete"
						)[0].lanName
						: "" // 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10020.DeleteRegisterVal,
					selectedRowData2
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		lanList.length > 0
						// 			? lanList.filter(
						// 				(f) => f.lanId === "snack_deleted"
						// 			)[0].lanName
						// 			: "", // 삭제 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_deleted"
									)[0].lanName
									: "", // 삭제 되었습니다!
						});
						setSelectedRowData2([]);
						setChangedRowData2([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						GetValList(selectedRowData2);
					} else {
						throw new Error(
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_delete_failed"
								)[0].lanName
								: "" // 삭제 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 상단(레지스터 맵) 엑셀 파일 업로드
	const MapUpload = async () => {
		setProgress(true);
		try {
			const formData = new FormData();
			formData.append("FormFile", inputs.mapFiles);
			formData.append("ItemIdx", selectedRowData[0].itemIdx);

			await Commons.Axios(
				"file",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10020.MapUpload,
				null,
				formData
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "파일이 잘 못 되었습니다.") {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_file_invalid"
							)[0].lanName
							: "" // 파일이 잘 못 되었습니다.
					);
				} else if (data.toString().indexOf("업로드") > -1) {
					throw new Error(data);
				} else {
					// setSnacks({
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_uploaded"
					// 			)[0].lanName
					// 			: "", // 업로드 되었습니다!
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_uploaded"
								)[0].lanName
								: "", // 업로드 되었습니다!
					});
					GetDtlList(selectedRowData);
					setInputs({
						...inputs,
						mapFiles: "",
						mapFileName: "",
					});
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 우측 하단(레지스터 값) 엑셀 파일 업로드
	const ValUpload = async () => {
		setProgress(true);
		try {
			const formData = new FormData();
			formData.append("FormFile", inputs.valFiles);
			formData.append("ItemIdx", selectedRowData[0].itemIdx);

			await Commons.Axios(
				"file",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10020.ValUpload,
				null,
				formData
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "파일이 잘 못 되었습니다.") {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_file_invalid"
							)[0].lanName
							: "" // 파일이 잘 못 되었습니다.
					);
				} else if (data.toString().indexOf("업로드") > -1) {
					// 업로드 실패 에러
					throw new Error(data);
				} else {
					// setSnacks({
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_uploaded"
					// 			)[0].lanName
					// 			: "", // 업로드 되었습니다!
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_uploaded"
								)[0].lanName
								: "", // 업로드 되었습니다!
					});
					setInputs({
						...inputs,
						valFiles: "",
						valFileName: "",
					});
					GetValList(selectedRowData1);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<>
			<BM10020View
				// 언어팩
				lanList={lanList}
				userLanCode={userLanCode}
				// 파일 업로드 state
				inputs={inputs}
				setInputs={setInputs}
				// 코드 리스트
				codeList={codeList}
				// 죄측 그리드(controller Data)
				rowData={rowData}
				setRowData={setRowData}
				selectedRowData={selectedRowData}
				setSelectedRowData={setSelectedRowData}
				changedRowData={changedRowData}
				setChangedRowData={setChangedRowData}
				checkings={checkings}
				setCheckings={setCheckings}
				// 우측 상단 그리드(Register Map)
				rowData1={rowData1}
				setRowData1={setRowData1}
				selectedRowData1={selectedRowData1}
				setSelectedRowData1={setSelectedRowData1}
				changedRowData1={changedRowData1}
				setChangedRowData1={setChangedRowData1}
				checkings1={checkings1}
				setCheckings1={setCheckings1}
				// 우측 하단 그리드(Register Val)
				rowData2={rowData2}
				setRowData2={setRowData2}
				selectedRowData2={selectedRowData2}
				setSelectedRowData2={setSelectedRowData2}
				changedRowData2={changedRowData2}
				setChangedRowData2={setChangedRowData2}
				checkings2={checkings2}
				setCheckings2={setCheckings2}
				// 함수
				GetDtlList={GetDtlList}
				GetValList={GetValList}
				CheckingLogic={CheckingLogic}
				CheckingLogic1={CheckingLogic1}
				CheckingLogic2={CheckingLogic2}
				CreateLogic={CreateLogic}
				CreateLogic1={CreateLogic1}
				CreateLogic2={CreateLogic2}
				DeleteLogic={DeleteLogic}
				DeleteLogic1={DeleteLogic1}
				DeleteLogic2={DeleteLogic2}
				MapUpload={MapUpload}
				ValUpload={ValUpload}
				// dialog
				snackDialog={snackDialog}
				setSnackDialog={setSnackDialog}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "bm10020_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</>
	);
}

export default BM10020;
