import React from "react";
/** Styles Import */
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Components Import */
import ChangePwView from "./../../Views/Account/ChangePw";
import { Loading } from "../../Components/Progresses";
import { Snackbar } from "../../Components/Snackbar";
import { Alert } from "../../Components/Alert";
/** Images Import */
/** Icons Import */

function ChangePw(props) {
	const [inputs, setInputs] = React.useState({
		beforePassword: "",
		afterPassword: "",
		afterPasswordCheck: "",
		savePassword: false,
	});
	const [progress, setProgress] = React.useState(false);
	const [message, setMessage] = React.useState({
		toggle: false,
		message: "",
		type: "info",
	});
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
	}, []);

	const Init = async () => {
		setProgress(true);

		try {
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					setLanList(result);
				}
			});
		} catch (e) {
			// console.log("e : ", e);
			props.setSnacks({
				...props.snacks,
				type: "error",
				open: true,
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	const FindUserAccount = async () => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});
			if (userInfo.userId === "guest") {
				setProgress(false);
				props.setDialog({
					...props.dialog,
					open: false,
					title: "",
					children: "",
				});
				setInputs({
					...inputs,
					beforePassword: "",
					afterPassword: "",
					afterPasswordCheck: "",
				});
				return false;
			}
			// console.log(
			// 	"params : ",
			// 	userInfo.userId,
			// 	inputs.beforePassword,
			// 	inputs.afterPasswordCheck
			// );
			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.ChangePassword,
				{
					userId: userInfo.userId,
					currentPw: inputs.beforePassword,
					newPw: inputs.afterPasswordCheck,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "현재 비밀번호가 틀렸습니다.") {
					props.setSnacks({
						...props.snacks,
						type: "error",
						open: true,
						message:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_current_pw_incorrect"
								)[0].lanName
								: "", // 현재 비밀번호가 틀렸습니다.
					});
				} else {
					props.setSnacks({
						...props.snacks,
						type: "success",
						open: true,
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_pw_changed"
								)[0].lanName
								: "", // 비밀번호가 변경되었습니다.
					});
					props.setDialog({
						...props.dialog,
						open: false,
						title: "",
						children: "",
					});
					return setInputs({
						...inputs,
						beforePassword: "",
						afterPassword: "",
						afterPasswordCheck: "",
					});
				}
			});
		} catch (e) {
			console.log("e : ", e);
			props.setSnacks({
				...props.snacks,
				type: "error",
				open: true,
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<>
			<ChangePwView
				inputs={inputs}
				setInputs={setInputs}
				setMessage={setMessage}
				FindUserAccount={FindUserAccount}
				lanList={lanList}
			/>
			<Loading value={progress} />
			{/* <Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/> */}
			{message.toggle && (
				<Alert
					sx={{ mt: 3, fontSize: 18 }}
					noteType={message.type}
					name={message.message}
					onClose={() => {
						setMessage({
							...message,
							toggle: false,
						});
					}}
				/>
			)}
		</>
	);
}

export default ChangePw;
