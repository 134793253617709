import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
/** Reducers Import */
import DrawerReducer from "../Reducers/DrawerReducer";
import BreadCrumbsReducer from "../Reducers/BreadCrumbsReducer";

const reducers = combineReducers({
	drawers: DrawerReducer,
	menus: BreadCrumbsReducer,
});

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["menus"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}),
});
