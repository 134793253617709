import React from "react";
/** Service Import */
/** Styles Import */
/** Components Import */
import { Unstable_Grid2 as Grid, Card, CardHeader } from "@mui/material";
/** Icons Import */
/** Images Import */

function SettingItem({ items = [] }) {
	const ReturnData = items.map((item, index) => {
		return (
			<Grid key={index} xs={12} md={12}>
				<Card>
					<CardHeader
						title={item.machNm}
						titleTypographyProps={{
							variant: "h6",
							fontWeight: "bold",
							marginLeft: "8px",
							color: "#388DFC",
						}}
					/>
				</Card>
			</Grid>
		);
	});

	return ReturnData;
}

export default SettingItem;
