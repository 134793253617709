import React from "react";
import { useNavigate } from "react-router-dom";
/** Services Import */
import Global from "../../../Services/Common/Global";
import Commons from "../../../Services/Common/Common";
import IndexedDb from "../../../Services/IndexedDb/IndexedDb";
import * as util from "../../../util.js";
/** Firebase Import */
import { initializeApp } from "firebase/app";
import {
	getToken,
	getMessaging,
	onMessage,
	isSupported,
} from "firebase/messaging";
/** Components Import */
import { Loading } from "../../../Components/Progresses";
import { Snackbar } from "../../../Components/Snackbar";
import { ConstructionOutlined } from "@mui/icons-material";

function KakaoAuth() {
	const navigate = useNavigate();
	const [snacks, setSnacks] = React.useState({
		type: "info",
		open: false,
		message: "",
	});
	const params = new URL(document.location.toString()).searchParams;
	const code = params.get("code");
	const grant_type = `${process.env.REACT_APP_KAKAO_Grant_type}`;
	const client_id = `${process.env.REACT_APP_KAKAO_REST_API_KEY}`;
	const redirect_uri = `${process.env.REACT_APP_KAKAO_REDIRECT_URI}`;
	const client_secret = `${process.env.REACT_APP_KAKAO_Client_Secret_Key}`;

	let firebaseSupported = false;
	let firebaseMessaging = null;
	let firebaseToken = "0";
	const firebaseConfig = {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	};
	const firebase = initializeApp(firebaseConfig);
	isSupported().then((result) => {
		firebaseSupported = result;
		if (result) {
			firebaseMessaging = getMessaging(firebase);

			util.getPermission();
			onMessage(firebaseMessaging, (payload) => {
				// console.log("Message received. ", payload);
				// console.log("title: ", payload.notification.title);
				// console.log("body: ", payload.notification.body);
				setSnacks({
					...snacks,
					type: "info",
					open: true,
					message:
						payload.notification.title +
						`${(<br />)} Message: ` +
						payload.notification.body,
				});
			});
		}
	});

	React.useEffect(() => {
		const GetKakaoToken = async () => {
			window.localStorage.removeItem("userInfo");
			await IndexedDb.ClearDb("MenuList").then((result) => { if (!result) { navigate("/"); return window.location.reload(); } });
			await IndexedDb.ClearDb("AuthList").then((result) => { if (!result) { navigate("/"); return window.location.reload(); } });
			await IndexedDb.ClearDb("LoginInfo").then((result) => { if (!result) { navigate("/"); return window.location.reload(); } });
			await IndexedDb.ClearDb("LanguageList").then((result) => { if (!result) { navigate("/"); return window.location.reload(); } });

			// 1. 카카오 토큰 가져오기
			// console.log("GetKakaoToken");
			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Social.Kakao.GetLoginCallback,
				{
					code: code,
					client_id: client_id,
					redirect_uri: redirect_uri,
					client_secret: client_secret,
					grant_type: grant_type,
					token: "",
				}
			)
				.then(async (result) => {
					let data = result.data;

					if (data.access_token) {
						Commons.Storages("set", "kakaoToken", {
							access_token: data.access_token,
							refresh_token: data.refresh_token,
						});
					} else {
						return () => {
							setSnacks({
								...snacks,
								open: true,
								type: "error",
								message: "카카오 연동에 실패했습니다.",
							});
							navigate("/");
						};
					}
				})
				.then(async () => {
					// 2. 카카오톡 프로필 조회 => 사용자 정보를 가져옴
					await Commons.Axios(
						"post",
						Global.base.useUrl +
						Global.urls.Social.Kakao.GetUserProfile,
						{
							code: code,
							client_id: client_id,
							redirect_uri: redirect_uri,
							client_secret: client_secret,
							grant_type: grant_type,
							token: Commons.Storages("get", "kakaoToken")
								.access_token,
						}
					).then(async (result) => {
						let data = result.data;
						// console.log("Data : ", data);

						if (data[0].kakao_account.name) {
							// 3. DB에서 카카오톡 유저정보 조회 (유저정보 없으면 DB에 저장)

							// data[1]의 shipping_addresses_needs_agreement가 true 이면, 배송지 조회에 추가 동의가 필요하다는 의미이다
							// => 로그인하는 사용자가 주소지 정보 제공에 동의하지 않았음
							await Commons.Axios(
								"post",
								Global.base.useUrl + Global.urls.Social.Kakao.CheckKakaoUser,
								{
									userId: data[0].kakao_account.email,
									userNm: data[0].kakao_account.name,
									userUuid: data[0].id,
									userTel: !data[0].kakao_account.has_phone_number
										? ""
										: !data[0].kakao_account.phone_number.includes("+82 ")
											? data[0].kakao_account.phone_number
											: data[0].kakao_account.phone_number.replace("+82 ", "0"),
									address1: data[1].shipping_addresses_needs_agreement ?
										"" : data[1].shipping_addresses[0].base_address,
									address2: data[1].shipping_addresses_needs_agreement ?
										"" : data[1].shipping_addresses[0].detail_address,
									post: data[1].shipping_addresses_needs_agreement ?
										"" : data[1].shipping_addresses[0].zone_number,
									accessToken: Commons.Storages("get", "kakaoToken")
										.access_token,
									refreshToken: Commons.Storages("get", "kakaoToken")
										.refresh_token,
								}
							).then(async (result) => {
								let data = result.data;
								// console.log("Check kakao user: ", data);
								let menus = [
									{
										lcategoCd: "Home",
										mcategoCd: "Home",
										mcategoNm: "Home",
										mobileYn: "Y",
										open: false,
										screenType: "L",
										userId: "System",
										sort: 0,
									},
								];
								let auths = [];
								let lans = [];

								if (data.type === "Error") {
									setSnacks({
										...snacks,
										open: true,
										type: "error",
										message: data.message,
									});
									setTimeout(() => navigate("/"), 3000);
								} else if (data.userId) {

									if (firebaseSupported) {
										if (
											Notification.permission !== "granted" ||
											!Commons.IsMobile()
										) {
											firebaseToken = "0"; // denied alarm
										} else {
											firebaseToken = await getToken(firebaseMessaging, {
												vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
											});
										}
									} else {
										firebaseToken = "0";
									}
									// console.log("firebaseToken : ", firebaseToken);

									// 4. 조회된 정보 바탕으로 로그인 => 로그인 완료 후 프론트 IndexedDB에 저장
									await Commons.Axios(
										"post",
										Global.base.useUrl + Global.urls.Social.Kakao.KakaoLogin,
										{
											userId: data.userId,
											tokenKey: firebaseToken,
											type: Commons.IsMobile() ? "mobile" : "web",
										}
									).then(async (result) => {
										let resultData = result.data;

										// console.log("kakao login logic:", resultData);

										if (resultData.type === "Error") {
											// console.log("error");
											setTimeout(() => navigate("/"), 300);
										} else if (
											resultData.userInfo.userId ===
											data.userId
										) {
											let useEndDate =
												resultData.userInfo.useEdate.substring(0, 4) +
												"-" +
												resultData.userInfo.useEdate.substring(4, 6) +
												"-" +
												resultData.userInfo.useEdate.substring(6);

											for (let i = 0; i < resultData.menuList.length; i++) {
												menus.push(resultData.menuList[i]);
											}
											for (let i = 0; i < resultData.authList.length; i++) {
												auths.push(resultData.authList[i]);
											}
											for (let i = 0; i < resultData.languageList.length; i++) {
												lans.push(resultData.languageList[i]);
											}

											await IndexedDb.AddDb("LoginInfo", [
												{
													token: resultData.userInfo.token,
													userId: resultData.userInfo.userId,
													userNm: resultData.userInfo.userNm,
													eMail: resultData.userInfo.eMail,
													lanCode: resultData.languageList[0].lanCode,
													postNo: resultData.userInfo.postNo,
													address1: resultData.userInfo.address1,
													address2: resultData.userInfo.address2,
													validaty: resultData.userInfo.validaty,
													refreshToken: resultData.userInfo.refreshToken,
													id: resultData.userInfo.id,
													guidId: resultData.userInfo.guidId,
													expiredTime: resultData.userInfo.expiredTime,
													connTime: Commons.DateFormating(new Date(), 1),
													plantList: resultData.plantList,
													amountYn: resultData.userInfo.amountYn,
													useSdate: resultData.userInfo.useSdate,
													useEdate: resultData.userInfo.useEdate,
													kakaoAccessToken: Commons.Storages("get", "kakaoToken")
														.access_token,
													kakaoRefreshToken: Commons.Storages("get", "kakaoToken")
														.refresh_token,
													serverVersion: resultData.userInfo.serverVersion,
													clientVersion: Commons.Storages("get", "version")
														.version,
												},
											]);
											Commons.Storages("set", "userInfo", {
												userId: resultData.userInfo.userId,
												userNm: resultData.userInfo.userNm,
												useEndDate: useEndDate,
												amountYn: resultData.userInfo.amountYn,
												lanCode: resultData.languageList[0].lanCode,
											});
											Commons.Storages("set", "authorization", {
												authorization: true,
												authDate: Commons.DateFormating(new Date()),
											});
											// console.log(
											// 	"server version: ",
											// 	resultData.userInfo.serverVersion
											// );
											// console.log(
											// 	"client version: ",
											// 	Commons.Storages("get", "version").version
											// );
											menus.push({
												lcategoCd: "Settings",
												mcategoCd: "Settings",
												mcategoNm: "설정",
												mobileYn: "Y",
												open: false,
												screenType: "L",
												userId: "System",
												sort: 9999,
											});
											await IndexedDb.AddDb("MenuList", menus);
											await IndexedDb.AddDb("AuthList", auths);
											await IndexedDb.AddDb("LanguageList", lans);
											navigate("/Home");
										}
									});
								}
							});
						} else {
							return () => {
								setSnacks({
									...snacks,
									open: true,
									type: "error",
									message: "카카오 연동에 실패했습니다.",
								});
								navigate("/");
							};
						}
					});
				});
		};

		GetKakaoToken();
	}, []);

	return (
		<>
			<Loading value={true}>
				<div>로그인 중입니다...</div>
			</Loading>
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
		</>
	);
}

export default KakaoAuth;
