const GridFunctions = {
	// 그리드의 행이 선택되었을 때 해당 변경 함수에 선택된 행의 데이터를 저장함
	onSelectionChanged: (_ref, _saveFunc) => {
		const selected = _ref.current.api.getSelectedRows();
		_saveFunc(selected);
	},

	// 그리드의 각 셀이 변경되었을 때 호출되어 기존에 기존에 있던 데이터를 새로 변경된 데이터로 변경시킴
	onCellValueChanged: (_row, _ref, _saveFunc) => {
		let changeRow = _ref.current.api.getDisplayedRowAtIndex(_row.rowIndex);

		if (_row.oldValue !== _row.newValue) {
			_saveFunc((current) => [
				...current,
				{
					...changeRow.data,
					newYn: "Y",
				},
			]);
			_ref.current.api.flashCells({
				rowNodes: [changeRow],
			});
			changeRow.setData({ ...changeRow.data, newYn: "Y" });
		}
	},

	// 데이터에 추가된 내용이 있을 경우 (상단 함수의 newYn 변수) 배경색을 변경함.
	getRowStyle: (_row) => {
		if (_row.data !== undefined && _row.data.newYn === "Y") {
			return { background: "#128191" };
		}
	},
};

export default GridFunctions;
