import React from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components Import */
import { Stack, IconButton, Button } from "@mui/material";
import { Snackbar } from "../Snackbar";
import { Dialog } from "../Dialog";
/** Icons Import */
import {
	Search as ReadIcon,
	Add as CreateIcon,
	Save as UpdateIcon,
	Delete as DeleteIcon,
	FileCopy as ExcelIcon,
	Refresh as RefreshIcon,
} from "@mui/icons-material";

function AuthGroupButton({
	pageName,
	target,
	refreshNone = "",
	refreshFunc = () => {
		return false;
	},
	addFunc = () => {
		return false;
	},
	selectFunc = () => {
		return false;
	},
	saveFunc = () => {
		return false;
	},
	deleteFunc = () => {
		return false;
	},
	excelFunc = () => {
		return false;
	},
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [authData, setAuthData] = React.useState([]);
	const [snacks, setSnacks] = React.useState(true);
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
	}, []);

	const Init = async () => {
		let lans = [];

		try {
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				setAuthData(
					result.filter(
						(f) =>
							f.mcategoCd === pageName &&
							f.detailCd.toString().substr(8) === target
					)[0]
				);
			});

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});
		} catch (e) {
			console.log(e);
		}
	};

	return (
		authData !== undefined ? <Stack sx={{ m: 0.5 }} direction="row">
			{authData.readYn === "Y" ? (
				<Button
					sx={{ m: 0.3, p: 0.5 }}
					color="secondary"
					variant="contained"
					onClick={() => selectFunc()}
				>
					<ReadIcon
						fontSize={Commons.IsMobile() ? "small" : "medium"}
					/>
				</Button>
			) : null}
			{authData.createYn === "Y" ? (
				<Button
					sx={{ m: 0.3, p: 0.5 }}
					color="secondary"
					variant="contained"
					onClick={() => addFunc()}
				>
					<CreateIcon
						fontSize={Commons.IsMobile() ? "small" : "medium"}
					/>
				</Button>
			) : null}
			{authData.updateYn === "Y" ? (
				<Button
					sx={{ m: 0.3, p: 0.5 }}
					color="secondary"
					variant="contained"
					onClick={() => saveFunc()}
				>
					<UpdateIcon
						fontSize={Commons.IsMobile() ? "small" : "medium"}
					/>
				</Button>
			) : null}
			{authData.deleteYn === "Y" ? (
				<Button
					sx={{ m: 0.3, p: 0.5 }}
					color="secondary"
					variant="contained"
					onClick={() => deleteFunc()}
				>
					<DeleteIcon
						fontSize={Commons.IsMobile() ? "small" : "medium"}
					/>
				</Button>
			) : null}
			{authData.excelYn === "Y" ? (
				<Button
					sx={{ m: 0.3, p: 0.5 }}
					color="secondary"
					variant="contained"
					onClick={() => excelFunc()}
				>
					<ExcelIcon
						fontSize={Commons.IsMobile() ? "small" : "medium"}
					/>
				</Button>
			) : null}
			{/* <Button
				sx={{ display: refreshNone, m: 0.3, p: 0.5 }}
				color="secondary"
				variant="contained"
			>
				<RefreshIcon
					fontSize={Commons.IsMobile() ? "small" : "medium"}
				/>
			</Button> */}
		</Stack>
			:
			// <Snackbar
			// 	type={'error'}
			// 	open={snacks}
			// 	onClose={() => {
			// 		setSnacks(false);
			// 	}}
			// 	message={"그룹 버튼을 불러올 수 없습니다. 관리자에게 문의하세요."}
			// />
			<Dialog
				open={true}
				useSaveButton={false}
				onClose={() => {
					// 로그아웃
					// Commons.Axios("logout");
					// dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					// dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/Home");
				}}
				title=
				{
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId === "snack_error_title"
						)[0].lanName
						: "에러" // 에러
				}
				children={(
					<>
						<p>
							{
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId === "snack_unable_group_button"
									)[0].lanName
									: "그룹 버튼을 불러올 수 없습니다." // 그룹 버튼을 불러올 수 없습니다.
							}
						</p>
						<p>
							{
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId === "snack_redirect_home"
									)[0].lanName
									: "홈 화면으로 이동합니다." // 홈 화면으로 이동합니다.
							}
						</p>
					</>
				)}
			/>
	);
}

export default AuthGroupButton;
