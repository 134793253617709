import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components Import */
import LM10030View from "../../Views/LogManagements/LM10030";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";

function LM10030() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [rowData, setRowData] = React.useState([]);
	const [fromDate, setFromDate] = React.useState(new Date());
	const [toDate, setToDate] = React.useState(new Date());
	const [registerList, setRegisterList] = React.useState([]);
	const [codeList, setCodeList] = React.useState([]);
	const [lanList, setLanList] = React.useState([]); // language list
	const [userLanCode, SetUserLanCode] = React.useState("kr");	// 사용자 설정 언어코드. 기본 한국어

	React.useEffect(() => {
		GetRegisters();
	}, []);

	// 레지스터 조회
	const GetRegisters = async () => {
		setProgress(true);
		let userInfo = null;
		let codes = [];
		let lans = [];
		let lanCode = "";
		let search = true;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					setLanList(result);
					result.forEach((item) => lans.push(item));
				}
			});

			// 사용자 언어코드 확인
			await IndexedDb.GetLoginInfo().then((result) => {
				SetUserLanCode(result.lanCode);
				lanCode = result.lanCode;
				// // console.log("result : ", result.lanCode);
			});


			await IndexedDb.GetCodeAll().then((result) => {
				if (result.length > 0) {
					setCodeList(result);
					result.forEach((item, index) => {
						codes.push(item);
					});
				}
			});


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "LM10030").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Common.GetRegisters,
				{
					userId: userInfo.userId,
				}
			).then(async (result) => {
				let data = result.data;
				// console.log("Data : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRegisterList([]);
					// throw new Error(
					// 	(lans.length > 0
					// 		? lans.filter(
					// 			(f) => f.lanId === "snack_unavailable_data"
					// 		)[0].lanName
					// 		: "")
					// );
				} else {
					setRegisterList(data);
					// if (fromDate.getFullYear() !== toDate.getFullYear()) {
					// 	await BetweenDateTwoYear(data, codes);
					// } else {
					// 	await BetweenDate(data, codes, lans, lanCode);
					// }
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""}
								{/* 로그인 정보가 만료되었습니다. */}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_login_again"
									)[0].lanName
									: ""}
								{/* 다시 로그인 해 주세요. */}
							</p>
						</>
					),
				});
			}
			return "";
		} finally {
			setProgress(false);
		}
	};

	const BetweenDate = async (register = registerList, codes = codeList, lans = lanList, lanCode = userLanCode) => {
		setProgress(true);
		let userInfo = null;
		let tempData = [];
		let tempArr = [];
		let tempRegister = [];
		let tempRowData = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10030.GetBetween,
				{
					years: toDate.getFullYear(),
					userId: userInfo.userId,
					fromDate: Commons.DateFormating(fromDate, 6),
					toDate: Commons.DateFormating(toDate, 6),
				}
			).then((result) => {
				let data = result.data;
				// // console.log("data test : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					// throw new Error(
					// 	(lans.length > 0
					// 		? lans.filter(
					// 			(f) => f.lanId === "snack_unavailable_data"
					// 		)[0].lanName
					// 		: "")
					// );
				} else if (data) {
					tempArr = data.reduce((prev, now) => {
						if (!prev.some((obj) => obj.ItemNo === now.ItemNo)) {
							prev.push(now);
						}
						return prev;
					}, []);

					register.forEach((item) => {
						tempArr.forEach((_item) => {
							if (item.itemNo === _item.ItemNo) {
								tempRegister.push(item);
							}
						});
					});

					data.forEach((_item, index) => {
						let temp1 = tempRegister.filter(
							(item) =>
								item.itemNo === _item.ItemNo &&
								item.mapAdd === _item.MapAdd &&
								item.val === _item.BefValue + ""
						);
						let temp2 = tempRegister.filter(
							(item) =>
								item.itemNo === _item.ItemNo &&
								item.mapAdd === _item.MapAdd &&
								item.val === _item.AftValue + ""
						);

						tempRowData.push({
							..._item,
							num: index + 1,
							ShowMapNm: lanCode === "en" ? _item.MapNmShEng : _item.MapNmSh,
							BefValue:
								temp1.length === 0
									? _item.BefValue + ""
									: temp1[0].valDesc,
							AftValue:
								temp2.length === 0
									? _item.AftValue + ""
									: temp2[0].valDesc,
							ChType: codes.filter(
								(f) =>
									f.groupCd === "CH_TYPE" &&
									f.code === _item.ChType
							)[0].name,
							TrdDate:
								_item.TrdDate.slice(0, 4) +
								"-" +
								_item.TrdDate.slice(4, 6) +
								"-" +
								_item.TrdDate.slice(6, 8) +
								" ",
							CreateDate:
								_item.CreateDate.slice(0, 4) +
								"-" +
								_item.CreateDate.slice(4, 6) +
								"-" +
								_item.CreateDate.slice(6, 8) +
								" " +
								_item.CreateDate.slice(8, 10) +
								":" +
								_item.CreateDate.slice(10, 12) +
								":" +
								_item.CreateDate.slice(12, 14),
						});
					});
					setRowData(tempRowData);
				} else {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "")
					); // 조회 실패!
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	const BetweenDateTwoYear = async (
		register = registerList,
		codes = codeList
	) => {
		setProgress(true);
		let userInfo = null;
		let tempArr = [];
		let tempRegister = [];
		let tempRowData = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10030.GetBetweenTwoYear,
				{
					fromYears: fromDate.getFullYear(),
					toYears: toDate.getFullYear(),
					userId: userInfo.userId,
					fromDate: Commons.DateFormating(fromDate, 6),
					toDate: Commons.DateFormating(toDate, 6),
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					// throw new Error(
					// 	(lanList.length > 0
					// 		? lanList.filter(
					// 			(f) => f.lanId === "snack_unavailable_data"
					// 		)[0].lanName
					// 		: "")
					// );
				} else if (data) {
					tempArr = data.reduce((prev, now) => {
						if (!prev.some((obj) => obj.ItemNo === now.ItemNo)) {
							prev.push(now);
						}
						return prev;
					}, []);

					register.forEach((item) => {
						tempArr.forEach((_item) => {
							if (item.itemNo === _item.ItemNo) {
								tempRegister.push(item);
							}
						});
					});

					data.forEach((_item, index) => {
						let temp1 = tempRegister.filter(
							(item) =>
								item.itemNo === _item.ItemNo &&
								item.mapAdd === _item.MapAdd &&
								item.val === _item.BefValue + ""
						);
						let temp2 = tempRegister.filter(
							(item) =>
								item.itemNo === _item.ItemNo &&
								item.mapAdd === _item.MapAdd &&
								item.val === _item.AftValue + ""
						);

						tempRowData.push({
							..._item,
							num: index + 1,
							ShowMapNm: userLanCode === "en" ? _item.MapNmShEng : _item.MapNmSh,
							BefValue:
								temp1.length === 0
									? _item.BefValue + ""
									: temp1[0].valDesc,
							AftValue:
								temp2.length === 0
									? _item.AftValue + ""
									: temp2[0].valDesc,
							ChType: codes.filter(
								(f) =>
									f.groupCd === "CH_TYPE" &&
									f.code === _item.ChType
							)[0].name,
							TrdDate:
								_item.TrdDate.slice(0, 4) +
								"-" +
								_item.TrdDate.slice(4, 6) +
								"-" +
								_item.TrdDate.slice(6, 8) +
								" ",
							CreateDate:
								_item.CreateDate.slice(0, 4) +
								"-" +
								_item.CreateDate.slice(4, 6) +
								"-" +
								_item.CreateDate.slice(6, 8) +
								" " +
								_item.CreateDate.slice(8, 10) +
								":" +
								_item.CreateDate.slice(10, 12) +
								":" +
								_item.CreateDate.slice(12, 14),
						});
					});
					setRowData(tempRowData);
				} else {
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "") // 조회 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<div>
			<LM10030View
				setSnacks={setSnacks}
				rowData={rowData}
				setRowData={setRowData}
				fromDate={fromDate}
				setFromDate={setFromDate}
				toDate={toDate}
				setToDate={setToDate}
				codeList={codeList}
				lanList={lanList}
				// function
				BetweenDate={BetweenDate}
				BetweenDateTwoYear={BetweenDateTwoYear}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default LM10030;
