import React from "react";
import DaumPostcode from "react-daum-postcode";
import axios from 'axios';
/** Components Import */
import { Dialog } from "../Dialog";

function DaumMaps({
	open = false,
	onClose = () => { },
	title = "주소검색",
	maxWidth = "lg",
	fullScreen = false,
	closeButtonName = "닫기",
	ReceivePost = (data) => { },
}) {
	const handleClose = () => {
		onClose();
	};
	const handleComplete = async (data) => {
		const searchTxt = data.address;
		const config = {
			headers: {
				Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`
			}
		}; //인증키 정보
		const url = 'https://dapi.kakao.com/v2/local/search/address.json?query=' + searchTxt; // url 및 키워드 담아 보냄

		await axios
			.get(url, config)
			.then(function (result) { // API호출
				if (result.data !== undefined || result.data !== null) {
					if (result.data.documents[0].x && result.data.documents[0].y) {
						ReceivePost(result.data.documents[0]);
					}
				}
			});
	};
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			title={title}
			maxWidth={maxWidth}
			fullScreen={fullScreen}
			closeButtonName={closeButtonName}
			useSaveButton={false}
		>
			<DaumPostcode
				onComplete={handleComplete}
				onClose={handleClose}
				submitMode={true}
			/>
		</Dialog>
	);
};


export default DaumMaps;