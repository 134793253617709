import React from "react";
/** Componsnts Import */
import { ButtonGroup, Button } from "@mui/material";

function GroupButton({
	type = "contained", // "contained", "outlined", "text"
	orientation = "horizontal", // "horizontal", vertical
	style = {}, // react inline-style object
	sx = {}, // mui inline-style object
	color = "secondary", // "first", "secondary", "success", "info", "warning", "error"
	size = "medium", // "small", "medium", "large"
	fullWidth = false, // true, false
	disabled = false, // true, false
	items = [], // button element object array ex) [{ name: "Button1", onClick: (e) => {} }, {...} ]
}) {
	return (
		<ButtonGroup
			variant={type}
			orientation={orientation}
			style={style}
			sx={sx}
			color={color}
			size={size}
			fullWidth={fullWidth}
			disabled={disabled}
		>
			{items.map((item, index) => {
				return (
					<Button
						key={index}
						style={item.style}
						sx={item.sx}
						color={item.color}
						size={item.size}
						fullWidth={item.fullWidth}
						onClick={item.onClick}
						startIcon={item.startIcon}
						endIcon={item.endIcon}
						disabled={item.disabled}
					>
						{item.name}
					</Button>
				);
			})}
		</ButtonGroup>
	);
}

export default GroupButton;
