import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components */
import SystemSettingView from "../../Views/Home/SystemSetting";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { SnackDialog } from "../../Components/Dialog";

function SystemSetting() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [inputs, setInputs] = React.useState({
		userId: Commons.Storages("get", "userInfo").userId,
		userName: Commons.Storages("get", "userInfo").userNm,
		authDate: Commons.Storages("get", "userInfo").useEndDate,
		amountYn: Commons.Storages("get", "userInfo").amountYn,
		reloadTime: (Commons.Storages("get", "settings").reloadTime * 1) / 1000,
		logTime: "",
		lanCode: Commons.Storages("get", "userInfo").lanCode,
	});
	const [comboList, setComboList] = React.useState([]); // reload time
	const [comboList1, setComboList1] = React.useState([]); // log save tiem
	const [comboList2, setComboList2] = React.useState([]); // language code list
	const [lanList, setLanList] = React.useState([]); // language list
	const [userLanCode, setUserLanCode] = React.useState([]); // 사용자 언어 코드

	React.useEffect(() => {
		Init();
	}, []);

	const Init = async () => {
		setProgress(true);
		let userInfo = null;
		let combos = [];
		let combos1 = [];
		let combos2 = [];
		let lans = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
				setUserLanCode(result.lanCode);
			});
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					setLanList(result);
					result.forEach((item) => lans.push(item));
				}
			});

			await IndexedDb.GetCodeAll().then((result) => {
				if (result.length > 0) {
					if (userInfo.lanCode === "kr") {
						result.forEach((element) => {
							if (element.groupCd === "TMP_RELOAD_TIME") {
								// 재조회 시간
								combos.push({
									value: element.code,
									name: element.name,
									val1: element.val1,
									sort: element.sortSeq,
								});
							} else if (element.groupCd === "MACH_TMP_REV_TIME") {
								// 로그 저장 시간
								combos1.push({
									value: element.code,
									name: element.name,
								});
							} else if (element.groupCd === "LAN_CODE") {
								// 언어 코드
								combos2.push({
									value: element.code,
									name: element.name,
								});
							}
						});
					} else {
						result.forEach((element) => {
							if (element.groupCd === "TMP_RELOAD_TIME") {
								// 재조회 시간
								combos.push({
									value: element.code,
									name: element.ref3,
									val1: element.val1,
									sort: element.sortSeq,
								});
							} else if (element.groupCd === "MACH_TMP_REV_TIME") {
								// 로그 저장 시간
								combos1.push({
									value: element.code,
									name: element.ref3,
								});
							} else if (element.groupCd === "LAN_CODE") {
								// 언어 코드
								combos2.push({
									value: element.code,
									name: element.ref3,
								});
							}
						});
					}
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Settings.Settings,
				{
					userId: userInfo.userId,
				}
			).then((result) => {
				let data = result.data;
				let reloadTime = combos.filter(
					(item) =>
						item.val1 ==
						Commons.Storages("get", "settings").reloadTime
				)[0]
					? combos.filter(
						(item) =>
							item.val1 ==
							Commons.Storages("get", "settings").reloadTime
					)[0].value
					: "60";

				if (data.type === "Error") {
					// throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: "" // 데이터가 없습니다.
					);
				} else {
				}

				setInputs({
					...inputs,
					reloadTime: reloadTime,
					logTime: data.TmpSaveTime === undefined ? 0 : data.TmpSaveTime,
				});
			});

			setComboList(combos.sort((a, b) => a.sort - b.sort));
			setComboList1(combos1);
			setComboList2(combos2);
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 재조회 시간 변경
	const SaveReloadTime = async (_reloadTime) => {
		setProgress(true);

		try {
			let selectItem = comboList.filter(
				(item) => item.value == _reloadTime
			)[0];
			// console.log(selectItem);

			Commons.Storages("set", "settings", {
				...Commons.Storages("get", "settings"),
				reloadTime: selectItem.val1,
			});
			Init();
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 로그 시간 저장
	const SaveLogTime = async (_tmpSaveTime) => {
		setProgress(true);
		let userInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Settings.Create,
				{
					userId: userInfo.userId,
					tmpSaveTime: _tmpSaveTime,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else {
					Init();
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 언어 변경
	const ChangeLanguage = async (_lanCode = inputs.lanCode) => {
		setProgress(true);
		let userInfo = null;
		let lans = [];

		try {
			await IndexedDb.GetLoginInfo().then(
				(result) => (userInfo = result)
			);

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Settings.ChangeLanguage,
				{
					userId: userInfo.userId,
					lanCode: _lanCode,
				}
			)
				.then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					}
				})
				.then(async () => {
					await IndexedDb.ClearDb("LanguageList");
					await Commons.Axios(
						"get",
						Global.base.useUrl +
						Global.urls.Settings.GetLanguageList,
						{
							userId: userInfo.userId,
							lanCode: _lanCode,
						}
					).then(async (result) => {
						let data1 = result.data;

						if (data1.type === "Error") {
							throw new Error(data1.message);
						} else {
							for (let i = 0; i < data1.length; i++) {
								lans.push({
									lanId: data1[i].LanId,
									lanCode: data1[i].LanCode,
									lanName: data1[i].LanName,
									lanType: data1[i].LanType,
								});
							}
						}

						Commons.Storages("set", "userInfo", {
							...Commons.Storages("get", "userInfo"),
							lanCode: _lanCode,
						});
						await IndexedDb.UpdateDb("LoginInfo", userInfo.token, {
							...userInfo,
							lanCode: _lanCode,
						});
						await IndexedDb.AddDb("LanguageList", lans);
					});
				})
				.then(() => {
					// setTimeout(() => navigate("/Settings"), 300);
					setTimeout(() => window.location.reload(true), 100);
				});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	const MovePage = (_path = "Home") => {
		navigate("/" + _path);
	};

	return (
		<div className="wrap">
			<SystemSettingView
				inputs={inputs}
				setInputs={setInputs}
				comboList={comboList}
				comboList1={comboList1}
				comboList2={comboList2}
				lanList={lanList}
				// 함수
				MovePage={MovePage}
				SaveReloadTime={SaveReloadTime}
				SaveLogTime={SaveLogTime}
				ChangeLanguage={ChangeLanguage}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default SystemSetting;
