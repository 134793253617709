import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components Import */
import BM10040View from "../../Views/BaseManagements/BM10040";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";

/** 기초코드관리 */
function BM10040() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [rowData, setRowData] = React.useState([]);
	const [selectedRowData, setSelectedRowData] = React.useState([]);
	const [changedRowData, setChangedRowData] = React.useState([]);
	const [checkings, setCheckings] = React.useState({
		groupCode: "",
		code: "",
	});
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
	}, []);

	// 데이터 조회
	const Init = async () => {
		setProgress(true);
		let temp = [];
		let lans = [];
		let search = true;

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "BM10040").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10040.GetCode
			).then((result) => {
				let data = result.data;
				// console.log("IU", data);

				if (data.type === "Error") {
					setRowData([]);
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else if (data.length > 0) {
					for (let i = 0; i < data.length; i++) {
						temp.push({
							...data[i],
							createDate: Commons.DateFormating(
								Commons.StringtoDate(data[i].createDate)
							),
							updateDate: Commons.DateFormating(
								Commons.StringtoDate(data[i].updateDate)
							),
						});
					}
					setRowData(temp);
				} else {
					setRowData([]);
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_view_failed"
							)[0].lanName
							: "")	// 조회 실패!
					);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 그리드 데이터 중복 검사
	const CheckingLogic = async (_gridRef) => {
		setProgress(true);

		try {
			if (!checkings.groupCode) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: (lanList.length > 0
				// 		? lanList.filter(
				// 			(f) =>
				// 				f.lanId ===
				// 				"snack_enter_group_code"
				// 		)[0].lanName
				// 		: "")	// 그룹코드를 입력하세요.
				// });
				// return false;
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_enter_group_code"
						)[0].lanName
						: "")	// 그룹코드를 입력하세요.
				);
			} else if (!checkings.code) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: (lanList.length > 0
				// 		? lanList.filter(
				// 			(f) =>
				// 				f.lanId ===
				// 				"snack_enter_code"
				// 		)[0].lanName
				// 		: "")	// 코드를 입력하세요.
				// });
				// return false;
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_enter_code"
						)[0].lanName
						: "")	// 코드를 입력하세요.
				);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10040.CheckCode,
					{
						groupCd: checkings.groupCode,
						code: checkings.code,
					}
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						throw new Error(
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_unavailable_data"
								)[0].lanName
								: "")	// 데이터가 없습니다.
						);
					} else if (data) {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_available_code"
						// 		)[0].lanName
						// 		: "")	//사용 가능한 코드입니다.
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_available_code"
								)[0].lanName
								: "")	//사용 가능한 코드입니다.
						});
						let currentData = [
							{
								groupCd: checkings.groupCode,
								code: checkings.code,
								newYn: "Y",
							},
							...rowData,
						];
						_gridRef.current.api.setRowData(currentData);
						setRowData(currentData);
						setChangedRowData([
							...changedRowData,
							{
								groupCd: checkings.groupCode,
								code: checkings.code,
								newYn: "Y",
							},
						]);
						dispatch(DrawerActions.setNotOpen()); // Drawer 열림 방지
						return true;
					} else {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_code"
							)[0].lanName
							: "")	//사용할 수 없는 코드입니다.
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 그리드 데이터 저장
	const CreateLogic = async () => {
		setProgress(true);
		let userInfo = null;
		let changedArr = [];

		try {
			if (changedRowData.length !== 0) {
				await IndexedDb.GetLoginInfo().then((result) => {
					userInfo = result;
				});
				changedRowData.forEach((item, index) => {
					changedArr.push({
						groupCd: item.groupCd,
						code: item.code,
						name: item.name,
						sortSeq: item.sortSeq === undefined ? 0 : item.sortSeq,
						val1: item.val1,
						val2: item.val2,
						val3: item.val3,
						ref1: item.ref1,
						ref2: item.ref2,
						ref3: item.ref3,	// 영문 코드명
						createId: userInfo.userId,
						createDate: Commons.DateFormating(new Date(), 5),
						updateId: userInfo.userId,
						updateDate: Commons.DateFormating(new Date(), 5),
					});
				});

				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10040.Create,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_saved"
						// 		)[0].lanName
						// 		: ""),	// 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_saved"
								)[0].lanName
								: ""),	// 저장 되었습니다!
						});
						setChangedRowData([]);
						setSelectedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // Drawer 열림 가능
						Init();
					} else {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_save_failed"
							)[0].lanName
							: ""),	// 저장 실패!
						);
					}
				});
			} else {
				// 변경된 행이 없으면 에러
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 그리드 데이터 삭제
	const DeleteLogic = async () => {
		setProgress(true);
		let tempNewYn = selectedRowData.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete"
					)[0].lanName
					: ""),	// 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10040.Delete,
					selectedRowData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_deleted"
						// 		)[0].lanName
						// 		: ""),	// 삭제 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_deleted"
								)[0].lanName
								: ""),	// 삭제 되었습니다!
						});
						setSelectedRowData([]);
						setChangedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // Drawer 열림 가능
						Init();
					} else {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_delete_failed"
							)[0].lanName
							: ""),	// 삭제 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<div>
			<BM10040View
				// 언어팩
				lanList={lanList}
				// 그리드 데이터
				rowData={rowData}
				setRowData={setRowData}
				selectedRowData={selectedRowData}
				setSelectedRowData={setSelectedRowData}
				changedRowData={changedRowData}
				setChangedRowData={setChangedRowData}
				checkings={checkings}
				// 함수
				setCheckings={setCheckings}
				CheckingLogic={CheckingLogic}
				CreateLogic={CreateLogic}
				DeleteLogic={DeleteLogic}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "bm10040_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default BM10040;
