import React, { forwardRef } from "react";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card, Tabs, Tab } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { DatePicker } from "../../Components/DatePicker";
import { TextField } from "@mui/material";
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";

function LM10040(props) {
	const gridRef = React.useRef();
	const columnDefs = [
		{
			field: "num",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10040_col_number"
					)[0].lanName
					: "", // 연번
			minWidth: 80,
		},
		// {
		// 	field: "Rcnt",
		// 	headerName: "순번",
		// 	minWidth: 80,
		// },
		{
			field: "MachNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10040_col_machine_name"
					)[0].lanName
					: "", // 장치명
			minWidth: 140,
		},
		{
			field: "ShowMapNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10040_col_map_name"
					)[0].lanName
					: "", // 예약 항목
			minWidth: 140,
		},
		{
			field: "AftValue",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10040_col_after_value"
					)[0].lanName
					: "", // 변경값
			minWidth: 140,
		},
		{
			field: "BkType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10040_col_reservation_type"
					)[0].lanName
					: "", // 예약상태
			minWidth: 120,
		},
		{
			field: "BkDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10040_col_create_date"
					)[0].lanName
					: "", // 등록일자
			minWidth: 180,
		},
		{
			field: "AppDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10040_col_update_date"
					)[0].lanName
					: "", // 예약일자
			minWidth: 180,
		},
		{
			field: "CreateId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "lm10040_col_update_id"
					)[0].lanName
					: "", // 등록자
			minWidth: 150,
		},
	];

	const CustomInput = forwardRef(({ value, onClick }, ref) => (
		<TextField
			size="small"
			variant="outlined"
			value={value}
			onClick={onClick}
			autoFocus={false}
			ref={ref}
			inputProps={{
				inputMode: "none",
			}}
		/>
	));

	return (
		<>
			<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid xs={12} md={12}>
					<Card
						sx={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<DatePicker
								className={
									Commons.IsMobile()
										? "datepicker-custom-input2"
										: "datepicker-custom-input"
								}
								style={{
									marginRight: "5px",
									marginLeft: "5px",
								}}
								selected={props.fromDate}
								maxDate={new Date()}
								onChange={(e) => {
									props.setFromDate(e);
								}}
								customInput={<CustomInput />}
							/>
							~
							<DatePicker
								className={
									Commons.IsMobile()
										? "datepicker-custom-input2"
										: "datepicker-custom-input"
								}
								style={{
									marginRight: "5px",
									marginLeft: "5px",
								}}
								selected={props.toDate}
								minDate={props.fromDate}
								maxDate={new Date()}
								onChange={(e) => {
									props.setToDate(e);
								}}
								customInput={<CustomInput />}
							/>
						</div>
						<AuthGroupButton
							pageName="LM10040"
							target="G01"
							selectFunc={() => {
								if (props.fromDate > props.toDate) {
									props.setToDate(props.fromDate);
									props.setSnacks({
										open: true,
										type: "error",
										message:
											props.lanList.length > 0
												? props.lanList.filter(
													(f) =>
														f.lanId ===
														"snack_check_period"
												)[0].lanName
												: "", // 조회 기간을 다시 확인하세요.
									});
								} else {
									props.BetweenDate();
								}
							}}
						/>
					</Card>
				</Grid>
				<Grid xs={12} md={12}>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animateRows={true}
								columnDefs={columnDefs}
								defaultColDef={{
									editable: false,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="single"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								getRowStyle={(e) =>
									GridFunctions.getRowStyle(e)
								}
							/>
						</div>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}

export default LM10040;
