import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components Import */
import BM10030View from "../../Views/BaseManagements/BM10030";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
/** Icons Import */

/** 권환 관리 */
function BM10030() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	// 탭 변경
	const [tabToggle, setTabToggle] = React.useState(0);
	// 좌측 그리드
	const [rowData, setRowData] = React.useState([]);
	const [selectedRowData, setSelectedRowData] = React.useState([]);
	const [changedRowData, setChangedRowData] = React.useState([]);
	// 권한관리 우측 그리드
	const [rowData1, setRowData1] = React.useState([]);
	const [selectedRowData1, setSelectedRowData1] = React.useState([]);
	const [changedRowData1, setChangedRowData1] = React.useState([]);
	// 메뉴관리 우측 그리드
	const [rowData2, setRowData2] = React.useState([]);
	const [selectedRowData2, setSelectedRowData2] = React.useState([]);
	const [changedRowData2, setChangedRowData2] = React.useState([]);
	// 권한관리 팝업 그리드
	const [menuRowData, setMenuRowData] = React.useState([]);
	const [selectedMenuRowData, setSelectedMenuRowData] = React.useState([]);
	const [inputs, setInputs] = React.useState({
		keyCheckedValue: "",
	});
	// 메뉴관리 팝업 그리드
	const [menuRowData1, setMenuRowData1] = React.useState([]);
	const [selectedMenuRowData1, setSelectedMenuRowData1] = React.useState([]);
	const [inputs1, setInputs1] = React.useState({
		keyCheckedValue: "",
	});
	const [codeList, setCodeList] = React.useState([]);
	const [lanList, setLanList] = React.useState([]); // language list
	const [userLanCode, SetUserLanCode] = React.useState("kr");	// 사용자 설정 언어코드. 기본 한국어

	React.useEffect(() => {
		Init();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
		IndexedDb.GetLoginInfo().then((result) => {
			SetUserLanCode(result.lanCode);
		});
	}, []);

	// 좌측 데이터 조회
	const Init = async () => {
		setProgress(true);
		let lans = [];
		let search = true;

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "BM10030").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10030.GetGroup
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					setRowData(data);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 좌측 그리드 중복 데이터 검사
	const CheckAuthGroupCd = async (_gridref) => {
		setProgress(true);
		let currentData = null;

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10030.CheckAuthGroupCd,
				{
					authGroupCd: inputs.keyCheckedValue,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else if (!data) {
					throw new Error((lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_registered_code"
						)[0].lanName
						: ""),	// 이미 등록된 코드입니다.
					);
				} else {
					// setSnacks({
					// 	open: true,
					// 	type: "success",
					// 	message: (lanList.length > 0
					// 		? lanList.filter(
					// 			(f) =>
					// 				f.lanId ===
					// 				"snack_available_id"
					// 		)[0].lanName
					// 		: ""),	// 사용 가능한 아이디입니다.
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message: (lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_available_id"
							)[0].lanName
							: ""),	// 사용 가능한 아이디입니다.
					});
					currentData = [
						{
							authGroupCd: inputs.keyCheckedValue,
							newYn: "Y",
						},
						...rowData,
					];
					_gridref.current.api.setRowData(currentData);
					setRowData(currentData);
					setChangedRowData([
						...changedRowData,
						{
							authGroupCd: inputs.keyCheckedValue,
							newYn: "Y",
						},
					]);
					dispatch(DrawerActions.setNotOpen()); // 열림 방지
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 좌측 그리드 데이터 저장
	const CreateGroup = async () => {
		setProgress(true);
		let userInfo = null;
		let resultData = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			changedRowData.forEach((item, index) => {
				resultData.push({
					...item,
					createId: userInfo.userId,
					updateId: userInfo.userId,
				});
			});

			resultData.length > 0 &&
				(await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10030.CreateGroup,
					resultData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_save_failed"
							)[0].lanName
							: ""),	// 저장 실패!
						);
					} else {
						setSnacks({
							open: true,
							type: "success",
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_saved"
								)[0].lanName
								: "")	// 저장 되었습니다!
						});
						setChangedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init();
					}
				}));
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 좌측 그리드 데이터 삭제
	const DeleteGroup = async () => {
		setProgress(true);
		let tempNewYn = selectedRowData.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			if (selectedRowData.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete"
					)[0].lanName
					: ""),	// 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else if (selectedRowData[0].useCount > 0) {
				// 사용중인 권한 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete_auth"
					)[0].lanName
					: ""),	// 사용중인 권한은 삭제할 수 없습니다
				);
			} else {
				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10030.DeleteGroup,
					selectedRowData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_delete_failed"
							)[0].lanName
							: ""),	// 삭제 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_deleted"
						// 		)[0].lanName
						// 		: ""),	// 삭제 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_deleted"
								)[0].lanName
								: ""),	// 삭제 되었습니다!
						});
						setRowData1([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init();
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 권한관리 우측 그리드 조회
	const GetAuthGroup = async (_authGroupCd) => {
		setProgress(true);
		let tempRowData1 = [];
		let lanCode = "";

		try {
			// 사용자 언어코드 확인
			await IndexedDb.GetLoginInfo().then((result) => {
				lanCode = result.lanCode;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10030.GetAuthGroup,
				{
					authGroupCd: _authGroupCd,
				}
			).then((result) => {
				let data = result.data;
				// console.log("Data : ", data);
				// console.log("lanlist : ", lanList);

				if (data.type === "Error") {
					setRowData1([]);
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData1([]);
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					data.forEach((item, index) => {
						tempRowData1.push({
							...item,
							// mcategoName: lanList.filter((f) =>
							// 	f.lanCode === lanCode &&
							// 	f.lanId.toUpperCase() === item.mcategoCd
							// )[0].lanName,
							createYn:
								item.createYn === "X" || item.createYn === ""
									? "X"
									: lanCode === "en" ?
										(item.createYn === "Y"
											? "Used"
											: "Not Used") :
										(item.createYn === "Y"
											? "사용"
											: "미사용"),
							readYn:
								item.readYn === "X" || item.readYn === ""
									? "X"
									: lanCode === "en" ?
										(item.readYn === "Y"
											? "Used"
											: "Not Used") :
										(item.readYn === "Y"
											? "사용"
											: "미사용"),
							updateYn:
								item.updateYn === "X" || item.updateYn === ""
									? "X"
									: lanCode === "en" ?
										(item.updateYn === "Y"
											? "Used"
											: "Not Used") :
										(item.updateYn === "Y"
											? "사용"
											: "미사용"),
							deleteYn:
								item.deleteYn === "X" || item.deleteYn === ""
									? "X"
									: lanCode === "en" ?
										(item.deleteYn === "Y"
											? "Used"
											: "Not Used") :
										(item.deleteYn === "Y"
											? "사용"
											: "미사용"),
							printYn:
								item.printYn === "X" || item.printYn === ""
									? "X"
									: lanCode === "en" ?
										(item.printYn === "Y"
											? "Used"
											: "Not Used") :
										(item.printYn === "Y"
											? "사용"
											: "미사용"),
							excelYn:
								item.excelYn === "X" || item.excelYn === ""
									? "X"
									: lanCode === "en" ?
										(item.excelYn === "Y"
											? "Used"
											: "Not Used") :
										(item.excelYn === "Y"
											? "사용"
											: "미사용"),
							pdfYn:
								item.pdfYn === "X" || item.pdfYn === ""
									? "X"
									: lanCode === "en" ?
										(item.pdfYn === "Y"
											? "Used"
											: "Not Used") :
										(item.pdfYn === "Y"
											? "사용"
											: "미사용"),
						});
					});
					setRowData1(tempRowData1);
					setChangedRowData1([]);
					setSelectedRowData1([]);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 권한관리 팝업 그리드 조회
	const GetMenu = async () => {
		setProgress(true);

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10030.GetMenu
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					for (let i = 0; i < data.length; i++) {
						for (let j = 0; j < rowData1.length; j++) {
							if (
								data[i].lcategoCd === rowData1[j].lcategoCd &&
								data[i].mcategoCd === rowData1[j].mcategoCd &&
								data[i].scategoCd === rowData1[j].scategoCd &&
								data[i].detailCd === rowData1[j].detailCd
							) {
								data.splice(i, 1);
							}
						}
					}
					setMenuRowData(data);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 권한관리 우측 그리드 데이터 저장
	const CreateAuthGroup = async () => {
		setProgress(true);
		let userInfo = null;
		let resultData = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			changedRowData1.forEach((item, index) => {
				resultData.push({
					...item,
					createYn:
						item.createYn === "사용" || item.createYn === "Y"
							? "Y"
							: item.createYn === "미사용" ||
								item.createYn === "N"
								? "N"
								: "X",
					readYn:
						item.readYn === "사용" || item.readYn === "Y"
							? "Y"
							: item.readYn === "미사용" || item.readYn === "N"
								? "N"
								: "X",
					updateYn:
						item.updateYn === "사용" || item.updateYn === "Y"
							? "Y"
							: item.updateYn === "미사용" ||
								item.updateYn === "N"
								? "N"
								: "X",
					deleteYn:
						item.deleteYn === "사용" || item.deleteYn === "Y"
							? "Y"
							: item.deleteYn === "미사용" ||
								item.deleteYn === "N"
								? "N"
								: "X",
					printYn:
						item.printYn === "사용" || item.printYn === "Y"
							? "Y"
							: item.printYn === "미사용" || item.printYn === "N"
								? "N"
								: "X",
					excelYn:
						item.excelYn === "사용" || item.excelYn === "Y"
							? "Y"
							: item.excelYn === "미사용" || item.excelYn === "N"
								? "N"
								: "X",
					pdfYn:
						item.pdfYn === "사용" || item.pdfYn === "Y"
							? "Y"
							: item.pdfYn === "미사용" || item.pdfYn === "N"
								? "N"
								: "X",
					createId: userInfo.userId,
					updateId: userInfo.userId,
				});
			});

			resultData.length > 0 &&
				(await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10030.CreateAuthGroup,
					resultData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_save_failed"
							)[0].lanName
							: ""),	// 저장 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_saved"
						// 		)[0].lanName
						// 		: ""),	// 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								lanList.length > 0
									? lanList.filter(
										(f) => f.lanId === "snack_saved"
									)[0].lanName
									: "", // 저장 되었습니다!
						});
						setChangedRowData1([]);
						GetAuthGroup(selectedRowData[0].authGroupCd);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
					}
				}));
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 권한관리 우측 그리드 데이터 삭제
	const DeleteAuthGroup = async () => {
		setProgress(true);
		let tempNewYn = selectedRowData1.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		);

		try {
			if (selectedRowData1.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete"
					)[0].lanName
					: ""),	// 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10030.DeleteAuthGroup,
					selectedRowData1
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_delete_failed"
							)[0].lanName
							: ""),	// 삭제 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: "삭제되었습니다.",
						// });
						setRowData1([]);
						setSelectedRowData1([]);
						setChangedRowData1([]);
						// GetAuthGroup(selectedRowData[0].authGroupCd);
						GetMenuGroup(selectedRowData[0].authGroupCd);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용

						setTabToggle(0);
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_permission_group_deleted"
						// 			)[0].lanName
						// 			: "")	// 권한 그룹이 삭제되었습니다."
						// 		+ " " +
						// 		(lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_delete_menu_together"
						// 			)[0].lanName
						// 			: "")	// 해당 그룹에 대한 메뉴를 같이 삭제해 주세요.
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_permission_group_deleted"
									)[0].lanName
									: "")	// 권한 그룹이 삭제되었습니다."
								+ " " +
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_delete_menu_together"
									)[0].lanName
									: "")	// 해당 그룹에 대한 메뉴를 같이 삭제해 주세요.
						});
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 메뉴관리 우측 그리드 조회
	const GetMenuGroup = async (_authGroupCd) => {
		setProgress(true);
		let temp = [];

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10030.GetMenuGroup,
				{
					authGroupCd: _authGroupCd,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					setRowData2([]);
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData2([]);
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					for (let i = 0; i < data.length; i++) {
						temp.push({
							authGroupCd: data[i].authGroupCd,
							createDate: data[i].createDate,
							createId: data[i].createId,
							lcategoCd: data[i].lcategoCd,
							mcategoCd: data[i].mcategoCd,
							mcategoName: data[i].mcategoName,
							scategoCd: data[i].scategoCd,
							screenType:
								data[i].screenType === "L"
									? "대메뉴"
									: "중메뉴",
							updateDate: data[i].updateDate,
							updateId: data[i].updateId,
						});
					}
					setRowData2(temp);
					setChangedRowData2([]);
					setSelectedRowData2([]);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 메뉴관리 팝업 그리드 조회
	const GetMenuList = async () => {
		setProgress(true);
		let temp = [];

		try {
			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10030.GetMenuList
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					for (let i = 0; i < data.length; i++) {
						temp.push({
							...data[i],
							screenType:
								data[i].screenType === "L"
									? "대메뉴"
									: "중메뉴",
						});
					}
					for (let i = 0; i < temp.length; i++) {
						for (let j = 0; j < rowData2.length; j++) {
							if (
								temp[i].lcategoCd === rowData2[j].lcategoCd &&
								temp[i].mcategoCd === rowData2[j].mcategoCd &&
								temp[i].scategoCd === rowData2[j].scategoCd
							) {
								temp.splice(i, 1);
							}
						}
					}

					setMenuRowData1(temp);
				}
			});
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 메뉴관리 우측 그리드 데이터 저장
	const CreateMenuGroup = async () => {
		setProgress(true);
		let userInfo = null;
		let resultData = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			changedRowData2.forEach((item) => {
				delete item.mobileYn;
				resultData.push({
					...item,
					screenType: item.screenType === "대메뉴" ? "L" : "M",
					createId: userInfo.userId,
					updateId: userInfo.userId,
				});
			});

			resultData.length > 0 &&
				(await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10030.CreateMenuGroup,
					resultData
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_save_failed"
							)[0].lanName
							: ""),	// 저장 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: "저장되었습니다.",
						// });
						setRowData2([]);
						setChangedRowData2([]);
						setSelectedRowData2([]);
						// GetMenuGroup(selectedRowData[0].authGroupCd);
						// GetAuthGroup(selectedRowData[0].authGroupCd);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용

						setTabToggle(1);
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message:
						// 		(lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_menu_group_saved"
						// 			)[0].lanName
						// 			: "")	// 메뉴 그룹이 저장되었습니다." 
						// 		+ " " +
						// 		(lanList.length > 0
						// 			? lanList.filter(
						// 				(f) =>
						// 					f.lanId ===
						// 					"snack_save_permission_together"
						// 			)[0].lanName
						// 			: "")	// 해당 그룹에 대한 권한을 같이 등록해 주세요."
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message:
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_menu_group_saved"
									)[0].lanName
									: "")	// 메뉴 그룹이 저장되었습니다." 
								+ " " +
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_save_permission_together"
									)[0].lanName
									: "")	// 해당 그룹에 대한 권한을 같이 등록해 주세요."
						});
					}
				}));
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 메뉴관리 우측 그리드 데이터 삭제
	const DeleteMenuGroup = async () => {
		setProgress(true);
		let tempNewYn = selectedRowData2.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		);

		try {
			if (selectedRowData2.length <= 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_delete"
					)[0].lanName
					: ""),	// 저장되지 않은 데이터는 삭제할 수 없습니다.
				);
			} else {
				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10030.DeleteMenuGroup,
					selectedRowData2
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data !== "Ok") {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_delete_failed"
							)[0].lanName
							: ""),	// 삭제 실패!
						);
					} else {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_deleted"
						// 		)[0].lanName
						// 		: ""),	// 삭제 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_deleted"
								)[0].lanName
								: ""),	// 삭제 되었습니다!
						});
						setRowData2([]);
						setSelectedRowData2([]);
						setChangedRowData2([]);
						GetMenuGroup(selectedRowData[0].authGroupCd);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<>
			<BM10030View
				// 언어팩
				lanList={lanList}
				userLanCode={userLanCode}
				// 코드 리스트
				codeList={codeList}
				// 탭 변경
				tabToggle={tabToggle}
				setTabToggle={setTabToggle}
				// 좌측 그리드
				rowData={rowData}
				setRowData={setRowData}
				selectedRowData={selectedRowData}
				setSelectedRowData={setSelectedRowData}
				changedRowData={changedRowData}
				setChangedRowData={setChangedRowData}
				// 권한관리 우측 그리드
				rowData1={rowData1}
				setRowData1={setRowData1}
				selectedRowData1={selectedRowData1}
				setSelectedRowData1={setSelectedRowData1}
				changedRowData1={changedRowData1}
				setChangedRowData1={setChangedRowData1}
				// 권한관리 팝업 그리드
				menuRowData={menuRowData}
				setMenuRowData={setMenuRowData}
				selectedMenuRowData={selectedMenuRowData}
				setSelectedMenuRowData={setSelectedMenuRowData}
				// 메뉴관리 우측 그리드
				rowData2={rowData2}
				setRowData2={setRowData2}
				selectedRowData2={selectedRowData2}
				setSelectedRowData2={setSelectedRowData2}
				changedRowData2={changedRowData2}
				setChangedRowData2={setChangedRowData2}
				// 메뉴관리 팝업 그리드
				menuRowData1={menuRowData1}
				setMenuRowData1={setMenuRowData1}
				selectedMenuRowData1={selectedMenuRowData1}
				setSelectedMenuRowData1={setSelectedMenuRowData1}
				// 좌측 그리드 중복값
				inputs={inputs}
				setInputs={setInputs}
				// 함수
				Init={Init}
				CheckAuthGroupCd={CheckAuthGroupCd}
				CreateGroup={CreateGroup}
				DeleteGroup={DeleteGroup}
				GetAuthGroup={GetAuthGroup}
				GetMenu={GetMenu}
				CreateAuthGroup={CreateAuthGroup}
				DeleteAuthGroup={DeleteAuthGroup}
				GetMenuGroup={GetMenuGroup}
				GetMenuList={GetMenuList}
				CreateMenuGroup={CreateMenuGroup}
				DeleteMenuGroup={DeleteMenuGroup}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "bm10030_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</>
	);
}

export default BM10030;
