import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Typography,
	Box,
	CircularProgress,
	IconButton,
	Breadcrumbs,
} from "@mui/material";
import { HomeLists } from "../../Components/Conotec";
/** Icons Import */
import {
	MoveUp as SwapIcon,
	Refresh as ReloadIcon,
	AddCircle as AddIcon,
	NavigateNext as NavigateNextIcon,
	Visibility,
} from "@mui/icons-material";

function Home(props) {
	const [success, setSuccess] = React.useState(false);

	React.useEffect(() => {
		if (props.determinateValue === 100) {
			setSuccess(true);
		} else {
			setSuccess(false);
		}
	}, [props.determinateValue]);

	return (
		<>
			<Breadcrumbs
				sx={{ m: 0, mb: 1, p: 0, pt: 1 }}
				separator={<NavigateNextIcon />}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						color: "inherit",
					}}
				>
					Home
				</div>
				{/* <div
					style={{
						display: "flex",
						alignItems: "center",
						color: "inherit",
					}}
				>
					Home
				</div> */}
			</Breadcrumbs>
			<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid xs={4} md={12}>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						{props.listData.length < 29 && <IconButton
							sx={{ mr: 1.5 }}
							onClick={() => {
								// console.log("props.listData.length  : ", props.listData.length);
								if (props.listData.length >= 29) {
									props.setSnackDialog({
										...props.snackDialog,
										open: true,
										title:
											props.lanList.length > 0
												? props.lanList.filter((f) => f.lanId === "snack_error_title")[0]
													.lanName
												: "", // 에러!
										message:
											props.lanList.length > 0
												? props.lanList.filter((f) => f.lanId === "snack_no_more_controller")[0]
													.lanName
												: "", // 더 이상 컨트롤러를 추가할 수 없습니다.
									});
								} else {
									props.GetDtlList2(true);
								}
							}}
						>
							<AddIcon sx={{ mr: 1 }} />
							<Typography>
								{
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"home_gen_add_device"
										)[0].lanName
										: "" // 장치추가
								}
							</Typography>
						</IconButton>}
						{!Commons.IsMobile() && props.noShowList.length !== 0 && <IconButton
							sx={{ mr: 1.5 }}
							onClick={() =>
								props.setAddController({
									...props.addController,
									open: true,
									disabled: true,
									disabledNoShow: true,
									useMac: true,
									useMachId: false,
									screenYn: "",
								})
							}
						>
							<Visibility sx={{ mr: 1 }} />
							<Typography>
								{
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"home_gen_display"
										)[0].lanName
										: "" // 화면 표시
								}
							</Typography>
						</IconButton>}
						<IconButton
							sx={{ mr: 1.5 }}
							onClick={() => props.MovePage("Swapping")}
						>
							<SwapIcon sx={{ mr: 1 }} />
							<Typography>
								{
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"home_gen_sort_change"
										)[0].lanName
										: "" // 순서변경
								}
							</Typography>
						</IconButton>
						<IconButton onClick={() => props.ReloadPage()}>
							<Typography sx={{ mr: 1 }}>
								{
									props.determinateLabel +
									(props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"home_gen_time"
										)[0].lanName
										: "") // 초후
								}
							</Typography>
							<ReloadIcon fontSize="small" />
						</IconButton>
					</div>
					{Commons.IsMobile() && <div
						style={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						{props.noShowList.length !== 0 && <IconButton
							sx={{ mr: 1.5 }}
							onClick={() =>
								props.setAddController({
									...props.addController,
									open: true,
									disabled: true,
									disabledNoShow: true,
									useMac: true,
									useMachId: true,
									screenYn: "",
								})
							}
						>
							<Visibility sx={{ mr: 1 }} />
							<Typography>
								{
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"home_gen_display"
										)[0].lanName
										: "" // 화면 표시
								}
							</Typography>
						</IconButton>}
					</div>}
				</Grid>
				{props.noDevice ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							height: "60vh",
							fontSize: "18px",
							fontWeight: "700",
						}}
					>
						{props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId === "snack_no_device_registered"
							)[0].lanName
							: ""}
					</div>
				) : (
					<HomeLists
						items={props.listData}
						mesureUnits={props.mesureUnits}
						setAddController={props.setAddController}
					/>
				)}
			</Grid>
		</>
	);
}

export default Home;
