import { createSlice } from "@reduxjs/toolkit";

let initialState = {
	largeTitle: "Home",
	mediumTitle: "Home",
};

const BreadCrumbsSlice = createSlice({
	name: "BreadCrumbs",
	initialState,
	reducers: {
		setLargeTitle(state, action) {
			state.largeTitle = action.payload;
		},
		setMediumTitle(state, action) {
			state.mediumTitle = action.payload;
		},
	},
});

export const BreadCrumbsActions = BreadCrumbsSlice.actions;
export default BreadCrumbsSlice.reducer;
