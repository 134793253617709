import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components */
import {
	Unstable_Grid2 as Grid,
	Card as MuiCard,
	CardHeader as MuiCardHeader,
	CardContent as MuiCardContent,
} from "@mui/material";
import Chart from "react-apexcharts";
/** Ag-grid Import */
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
/** Icons Import */
/** Images Import */
import GraphIcon1 from "../../Assets/Images/Design/drawable-hdpi/tem_btn02.png";

const Card = styled((props) => <MuiCard {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));
const CardContent = styled((props) => <MuiCardContent {...props} />)(
	({ theme }) => ({
		width: "100%",
	})
);

function DashboardChart({ items = [], lanList = [] }) {
	let storeTemp = [];
	let dividerTemp = [];

	items.forEach((item1, index1) => {
		if ((index1 + 1) % 2 === 0 || index1 + 1 === items.length) {
			storeTemp.push(item1);
			dividerTemp.push(storeTemp);
			storeTemp = [];
		} else {
			storeTemp.push(item1);
		}
	});

	const ReturnData = dividerTemp.map((item, index) => {
		return (
			<Card key={index}>
				{item.map((item1, index1) => {
					const ReturnTitle = () => {
						switch (item1.type) {
							case "tmp":
								return lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_chart_tmp"
									)[0].lanName
									: ""; // 온도
							case "hmd":
								return lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_chart_hmd"
									)[0].lanName
									: ""; // 습도
							case "ill":
								return lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_chart_ill"
									)[0].lanName
									: ""; // 조도
							case "co2":
								return lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_chart_co2"
									)[0].lanName
									: ""; // CO2
						}
					};

					return (
						<CardContent key={index1}>
							<MuiCardHeader
								sx={{ py: 1, bgcolor: "#141820" }}
								avatar={
									<img
										style={{
											width: "20px",
											height: "20px",
										}}
										src={GraphIcon1}
									/>
								}
								title={`${ReturnTitle()} ${lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_chart_changed"
									)[0].lanName
									: ""
									}`}
								titleTypographyProps={{
									variant: "subtitle2",
									fontWeight: "bold",
								}}
							/>
							<Chart
								type="line"
								height={Commons.Dimentions().height * 0.4}
								options={{
									theme: {
										mode: "dark",
									},
									chart: {
										id: "basic-line",
										toolbar: {
											show: false,
											tools: {
												download: false,
												pan: false,
											},
										},
										background: "#292f3b",
										animations: {
											enabled: false,
										},
										zoom: {
											enabled: false
										}
									},
									stroke: {
										curve: "smooth",
									},
									xaxis: {
										categories: item1.categories,
										tickAmount: 10,
										tooltip: {
											enabled: false,
										},
									},
									dataLabels: {
										enabled: false,
									},
								}}
								series={item1.data}
							/>
						</CardContent>
					);
				})}
			</Card>
		);
	});

	return (
		<Grid xs={4} sm={8} md={12}>
			{ReturnData}
		</Grid>
	);
}

export default DashboardChart;
