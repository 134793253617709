import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
/** Styles Import */
import "../Styles/DrawerStyle.css";
/** Services Import */
import Commons from "./../Services/Common/Common";
import IndexedDb from "../Services/IndexedDb/IndexedDb";
import Global from "../Services/Common/Global";
/** Reducers Import */
import { DrawerActions } from "../Reducers/DrawerReducer";
import { BreadCrumbsActions } from "../Reducers/BreadCrumbsReducer";
/** Components Import */
import ChangePw from "../Factories/Account/ChangePw";
import { Dialog } from "../Components/Dialog";
import { Alert } from "../Components/Alert";
/** MUI Import */
import { styled, useTheme, emphasize } from "@mui/material/styles";
import {
	Box,
	Button as MuiButton,
	Drawer as MuiDrawer,
	CssBaseline,
	AppBar as MuiAppBar,
	Toolbar,
	List,
	Typography,
	Divider,
	IconButton,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Collapse,
	Stack,
	Avatar,
	Menu,
	MenuItem,
	Container,
	Breadcrumbs,
	Chip,
	TextField,
} from "@mui/material";
import {
	Menu as MenuIcon,
	ChevronLeft as ChevronLeftIcon,
	ChevronRight as ChevronRightIcon,
	Home as HomeIcon,
	ExpandLess,
	ExpandMore,
	Logout,
	AppSettingsAlt as BasicIcon,
	SettingsRemote as ControllerIcon,
	Dashboard as DashboardIcon,
	Beenhere as ReservationIcon,
	Pageview as InquiryIcon,
	SwipeRightAlt as MediumMenuIcon,
	NavigateNext as NavigateNextIcon,
	Settings as SettingIcon,
	History as HistoryIcon,
	PersonAddAlt1 as PersonAddIcon,
} from "@mui/icons-material";

const drawerWidth = 280;

const Center = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: Commons.IsMobile() ? theme.spacing(0) : theme.spacing(3),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: 0,
		...(open && {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: `${drawerWidth - 167}px`,
		}),
		backgroundColor: "#1E2430",
		height: "93vh",
	})
);

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-end",
}));

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
	// const backgroundColor =
	// 	theme.palette.mode === "light"
	// 		? theme.palette.grey[400]
	// 		: theme.palette.grey[800];
	const backgroundColor = "#fff";
	return {
		backgroundColor,
		height: theme.spacing(4),
		// color: theme.palette.text.primary,
		color: "inherit",
		fontSize: 16,
		fontWeight: theme.typography.fontWeightRegular,
		"&:hover, &:focus": {
			backgroundColor: emphasize(backgroundColor, 0.06),
		},
		"&:active": {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(backgroundColor, 0.12),
		},
	};
});

export default function Drawer() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const theme = useTheme(); // 테마 사용을 위한 저장
	const drawerStates = useSelector((state) => state.drawers); // Drawer Redux 호출
	const BreadCrumbsStates = useSelector((state) => state.menus);
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const drawerRef = React.useRef();
	const [selected, setSelected] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const menuToggle = Boolean(anchorEl);
	const [largeMenuArray, setLargeMenuArray] = React.useState([]); // Drawer large Menu Array
	const [mediumMenuArray, setMediumMenuArray] = React.useState([]); // Drawer Medium Menu Array
	const userInfo = Commons.Storages("get", "userInfo");
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [message, setMessage] = React.useState({
		toggle: false,
		message: "",
		type: "info",
	});
	const [checkModifyToggle, setCheckModifyToggle] = React.useState(false);
	const [checkModifyToggle1, setCheckModifyToggle1] = React.useState(false);
	const [settingsToggle, setSettingsToggle] = React.useState(false);
	const [logoutToggle, setLogoutToggle] = React.useState(false);
	const [intervalTime, setIntervalTime] = React.useState(
		Commons.Storages("get", "settings").reloadTime / 1000
	);
	const [lanList, setLanList] = React.useState([]);

	React.useEffect(() => {
		GetMenuList();
	}, []);

	// 인덱스 DB에서 모든 메뉴 리스트를 가져와서 모바일 여부에 따라 필터링
	const GetMenuList = async () => {
		await IndexedDb.GetAllDb("MenuList").then((result) => {
			if (result.length > 0) {
				if (Commons.IsMobile()) {
					setLargeMenuArray(
						result
							.sort((a, b) => a.sort - b.sort)
							.filter(
								(f) =>
									f.screenType === "L" &&
									f.mobileYn === "Y" &&
									f.mcategoCd !== "GM10000" &&
									f.mcategoCd !== "DM10000" &&
									f.mcategoCd !== "SM10000"
							)
					);
					setMediumMenuArray(
						result
							.sort((a, b) => a.sort - b.sort)
							.filter(
								(f) =>
									f.screenType === "M" && f.mobileYn === "Y"
							)
					);
				} else {
					setLargeMenuArray(
						result
							.sort((a, b) => a.sort - b.sort)
							.filter(
								(f) =>
									f.screenType === "L" &&
									f.mcategoCd !== "GM10000" &&
									f.mcategoCd !== "DM10000" &&
									f.mcategoCd !== "SM10000"
							)
					);
					setMediumMenuArray(
						result
							.sort((a, b) => a.sort - b.sort)
							.filter((f) => f.screenType === "M")
					);
				}
			}
		});
		await GetLanguageList();
		await GetCodeList();
	};

	// 기초코드를 들고와서 인덱스 DB에 저장
	const GetCodeList = async () => {
		let code = [];
		try {
			await IndexedDb.ClearDb("CodeList");
			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Common.GetCode,
				{
					groupCd: "string",
				}
			).then(async (result) => {
				if (result.data.type === "Error") {
					throw new Error(result.data.message);
				} else if (result.data) {
					for (let i = 0; i < result.data.length; i++) {
						code.push({
							groupCd: result.data[i].GroupCd,
							code: result.data[i].Code,
							name: result.data[i].Name,
							sortSeq: result.data[i].SortSeq,
							val1: result.data[i].Val1,
							val2: result.data[i].Val2,
							val3: result.data[i].Val3,
							ref1: result.data[i].Ref1,
							ref2: result.data[i].Ref2,
							ref3: result.data[i].Ref3,
							createId: result.data[i].CreateId,
							createDate: result.data[i].CreateDate,
							updateId: result.data[i].UpdateId,
							updateDate: result.data[i].UpdateDate,
						});
					}
					await IndexedDb.AddDb("CodeList", code);
				} else {
					throw new Error(" 실패!");
				}
			});
		} catch (e) {
			console.log(e);
		} finally {
		}
	};

	const GetLanguageList = async () => {
		await IndexedDb.GetAllDb("LanguageList").then((result) => {
			if (result.length > 0) {
				setLanList(result);
			}
		});
	};

	// Drawer Menu Icon Setting Func
	const ReturnIcons = (_id) => {
		switch (_id) {
			case "Home":
				return <HomeIcon />;
			case "Settings":
				return <SettingIcon />;
			case "BaseManagement":
				return <BasicIcon />;
			case "ControllerManagement":
				return <ControllerIcon />;
			case "DashboardManagement":
				return <DashboardIcon />;
			case "ReservationManagement":
				return <ReservationIcon />;
			case "InquiryManagement":
				return <InquiryIcon />;
			case "LogManagement":
				return <HistoryIcon />;
			case "SettingManagement":
				return <SettingIcon />;
			default:
				return <MediumMenuIcon />;
		}
	};

	// 하위 메뉴 클릭 이벤트
	const MediumMenuClick = (_id) => {
		const targetArray = [...largeMenuArray];
		let target;
		if (_id == "Home") {
			target = targetArray.find((f) => f.mcategoCd != _id);
			dispatch(BreadCrumbsActions.setLargeTitle("Home"));
			dispatch(BreadCrumbsActions.setMediumTitle("Home"));
		} else {
			target = targetArray.find((f) => f.mcategoCd === _id);
		}
		target.open = !target.open;
		setLargeMenuArray(targetArray);
	};

	// Menu 뿌리는 로직 함수
	const CreateMenus = () => {
		const result = largeMenuArray.map((item, index) => {
			const mediumArr = mediumMenuArray.filter(
				(f) => f.lcategoCd === item.lcategoCd
			);

			const BasicClick = async (
				_lcategoCd = "Home",
				_mcategoCd = "Home",
				_mcategoNm = "CONOTEC",
				_screenType
			) => {
				const targetArray = [...largeMenuArray];
				let targetList = [];

				if (_screenType === "L") {
					targetList = targetArray;
				} else {
					targetList = targetArray.filter(
						(f) => f.lcategoCd !== _lcategoCd
					);
				}

				targetList.forEach((fItem) => {
					fItem.open = false;
				});
				setLargeMenuArray(targetArray);

				if (item.lcategoCd == "Home") {
					setSelected(null);
					navigate("/Home");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
				} else if (item.lcategoCd === "Settings") {
					setSelected(null);
					navigate("/Settings");
					dispatch(BreadCrumbsActions.setLargeTitle("Settings"));
					dispatch(BreadCrumbsActions.setMediumTitle("Settings"));
				} else if (item.lcategoCd === "test") {
					setSelected(null);
					navigate("/Settings");
					dispatch(BreadCrumbsActions.setLargeTitle("test"));
					dispatch(BreadCrumbsActions.setMediumTitle("test"));
				} else {
					setSelected(_mcategoCd);
					navigate(_lcategoCd + "/" + _mcategoCd);
					dispatch(
						BreadCrumbsActions.setLargeTitle(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										Commons.ChangeString(
											largeMenuArray.filter(
												(f) =>
													f.lcategoCd ===
													_lcategoCd
											)[0].mcategoCd,
											"lower"
										)
								)[0].lanName
								: ""
						)
					);
					dispatch(
						BreadCrumbsActions.setMediumTitle(
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										Commons.ChangeString(
											_mcategoCd,
											"lower"
										)
								)[0].lanName
								: ""
						)
					);
				}

				dispatch(DrawerActions.CloseDrawer());
			};

			return (
				<div key={index}>
					{mediumArr.length > 0 ? (
						<>
							<ListItemButton
								style={{ padding: "5px 10px" }}
								onClick={() => MediumMenuClick(item.mcategoCd)}
							>
								<ListItemIcon>
									{ReturnIcons(item.lcategoCd)}
								</ListItemIcon>
								<ListItemText
									// primary={item.mcategoNm}
									primary={
										lanList.length > 0
											? lanList.filter(
												(f) =>
													f.lanId ===
													Commons.ChangeString(
														item.mcategoCd,
														"lower"
													)
											)[0].lanName
											: ""
									}
								/>
								{item.open ? <ExpandMore /> : <ExpandLess />}
							</ListItemButton>
							<Collapse
								in={item.open}
								timeout="auto"
								unmountOnExit
							>
								{mediumArr.map((item2, index) => {
									return (
										<ListItemButton
											key={index}
											style={{
												padding: "5px 0px 5px 20px",
											}}
											selected={
												selected === item2.mcategoCd
											}
											onClick={() =>
												BasicClick(
													item2.lcategoCd,
													item2.mcategoCd,
													item2.mcategoNm,
													item2.screenType
												)
											}
										>
											<ListItemIcon>
												{ReturnIcons(
													// item2.lcategoCd,
													"MediumMenu"
												)}
											</ListItemIcon>
											<ListItemText
												// primary={item2.mcategoNm}
												primary={
													lanList.length > 0
														? lanList.filter(
															(f) =>
																f.lanId ===
																Commons.ChangeString(
																	item2.mcategoCd,
																	"lower"
																)
														)[0].lanName
														: ""
												}
											/>
										</ListItemButton>
									);
								})}
							</Collapse>
						</>
					) : (
						<ListItemButton
							style={{ padding: "5px 10px" }}
							onClick={() =>
								BasicClick(
									item.lcategoCd,
									item.mcategoCd,
									item.mcategoNm,
									item.screenType
								)
							}
						>
							<ListItemIcon>
								{ReturnIcons(item.lcategoCd)}
							</ListItemIcon>
							<ListItemText
								// primary={item.mcategoNm}
								primary={
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												Commons.ChangeString(
													item.mcategoCd,
													"lower"
												)
										)[0].lanName
										: ""
								}
							/>
						</ListItemButton>
					)}
				</div>
			);
		});
		return result;
	};

	// 메뉴 열기 / 닫기
	const MenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const MenuClose = () => {
		setAnchorEl(null);
	};

	const LogoutLogic = async () => {
		let userInfoId = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfoId = result.userId;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Account.Logout,
				{
					userId: userInfoId,
					type: Commons.IsMobile() ? "mobile" : "web",
				}
			).then((result) => {
				let data = result.data;

				if (data === "Ok") {
					// MenuClose();
					setAnchorEl(null);
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					dispatch(DrawerActions.CloseDrawer());
					navigate("/");
					return window.location.reload();
				}
			});
		} catch (e) {
			console.log("Logout logic error: ", e);
		}
	};

	return (
		<div className="Drawer">
			<div className="empty_box" />
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBar
					position="fixed"
					open={drawerStates.open}
					sx={{ backgroundColor: "#141820" }}
				>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={() => {
								if (drawerStates.changedYn === "Y") {
									dispatch(DrawerActions.OpenDrawer());
									drawerRef.current.focus();
								} else {
									setCheckModifyToggle(true);
								}
							}}
							edge="start"
							sx={{
								mr: 2,
								...(drawerStates.open && { display: "none" }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<MuiButton
							onClick={() => {
								if (drawerStates.changedYn === "Y") {
									MediumMenuClick("Home");
									setSelected(null);
									navigate("/Home");
									dispatch(
										BreadCrumbsActions.setLargeTitle("Home")
									);
									dispatch(
										BreadCrumbsActions.setMediumTitle(
											"Home"
										)
									);
									dispatch(DrawerActions.CloseDrawer());
								} else {
									setCheckModifyToggle1(true);
								}
							}}
						>
							<Typography
								variant="h6"
								noWrap
								component="div"
								style={{
									color: "#e5e5e5",
									fontWeight: "bold",
									fontSize: "1.125em",
								}}
							>
								{drawerStates.appBarTitle}
							</Typography>
						</MuiButton>
						<>
							<div
								className="Appbar"
								style={{ display: "flex", marginLeft: "auto" }}
							>
								<Stack
									direction="row"
									spacing={2}
									sx={{
										display: "flex",
										alignItems: "center",
										marginTop: 0.5,
									}}
								>
									{userInfo ? userInfo.userNm : ""}
									<IconButton
										aria-controls={
											menuToggle
												? "account-menu"
												: undefined
										}
										aria-haspopup="true"
										aria-expanded={
											menuToggle ? "true" : undefined
										}
										style={{ marginLeft: "5px" }}
										onClick={MenuClick}
									>
										<Avatar
											sx={{ width: 32, height: 32 }}
										/>
									</IconButton>
								</Stack>
							</div>
						</>
					</Toolbar>
				</AppBar>
				<MuiDrawer
					ref={drawerRef}
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						"& .MuiDrawer-paper": {
							width: drawerWidth,
							boxSizing: "border-box",
						},
					}}
					variant="persistent"
					anchor="left"
					open={drawerStates.open}
				>
					<DrawerHeader>
						<IconButton
							onClick={() => {
								dispatch(DrawerActions.CloseDrawer());
							}}
						>
							{theme.direction === "ltr" ? (
								<ChevronLeftIcon />
							) : (
								<ChevronRightIcon />
							)}
						</IconButton>
					</DrawerHeader>
					<Divider />
					<List>{CreateMenus()}</List>
				</MuiDrawer>
			</Box>
			<Center
				open={drawerStates.open}
				onFocus={() => dispatch(DrawerActions.CloseDrawer())}
			>
				<Box component="main" sx={{ flexGrow: 1, bgcolor: "#1E2430" }}>
					{/* <Container maxWidth="xl"> */}
					<Container maxWidth={drawerStates.open ? "xl" : false}>
						{/* <Breadcrumbs
							sx={{ m: 0, mb: 1, p: 0, pt: 1 }}
							separator={<NavigateNextIcon />}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									color: "inherit",
								}}
							>
								{ReturnIcons(drawerStates.largeTitle)}
								{BreadCrumbsStates.largeTitle}
							</div>
							{BreadCrumbsStates.mediumTitle !== "Home" && (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										color: "inherit",
									}}
								>
									{BreadCrumbsStates.mediumTitle}
								</div>
							)}
						</Breadcrumbs> */}

						{/** react-router에서 중첩라우팅 시작점 지정을 위한 테그 */}
						<Outlet />
					</Container>
				</Box>
			</Center>
			<Menu
				id="account-menu"
				anchorEl={anchorEl}
				open={menuToggle}
				onClose={MenuClose}
				onClick={MenuClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<MenuItem onClick={() => navigate("/Settings")}>
					<ListItemIcon>
						<SettingIcon fontSize="small" />
					</ListItemIcon>
					{/* 설정 */}
					{lanList.length > 0
						? lanList.filter((f) => f.lanId === "settings")[0]
							.lanName
						: ""}
				</MenuItem>
				<MenuItem
					onClick={() => {
						setLogoutToggle(true);
						// LogoutLogic();

						// MenuClose();
						// Commons.Axios("logout");
						// dispatch(BreadCrumbsActions.setLargeTitle("Home"));
						// dispatch(BreadCrumbsActions.setMediumTitle("Home"));
						// dispatch(DrawerActions.CloseDrawer());
						// navigate("/");
						// return window.location.reload();
					}}
				>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					{/* 로그아웃 */}
					{lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "settings_gen_logout"
						)[0].lanName
						: ""}
				</MenuItem>
			</Menu>
			{/** 비밀번호 변경 => Not Used */}
			<Dialog
				open={dialog.open}
				title={"비밀번호 변경"}
				children={<ChangePw dialog={dialog} setDialog={setDialog} />}
				onClose={() => {
					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				closeButtonName=""
				saveButtonName=""
			/>
			{/** 수정 중 화면 이동시 확인 */}
			<Dialog
				open={checkModifyToggle}
				onClose={() => setCheckModifyToggle(false)}
				title={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_warning_title"
						)[0].lanName
						: "" // 경고
				}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onSave={() => {
					dispatch(DrawerActions.setUseOpen());
					dispatch(DrawerActions.OpenDrawer());
					drawerRef.current.focus();
				}}
			>
				<div>
					{
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"drawer_pop_warning_message_1"
							)[0].lanName
							: "" // 저장되지 않은 목록이 있습니다.
					}
				</div>
				<div>
					{
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"drawer_pop_warning_message_2"
							)[0].lanName
							: "" // 이동 시 모두 지워집니다.
					}
				</div>
				<div>
					{
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"drawer_pop_warning_message_3"
							)[0].lanName
							: "" // 그래도 이동하시겠습니까?
					}
				</div>
			</Dialog>
			<Dialog
				open={checkModifyToggle1}
				onClose={() => setCheckModifyToggle1(false)}
				title={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_warning_title"
						)[0].lanName
						: "" // 경고
				}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onSave={() => {
					dispatch(DrawerActions.setUseOpen());
					MediumMenuClick("Home");
					setSelected(null);
					navigate("/Home");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					dispatch(DrawerActions.CloseDrawer());
				}}
			>
				<div>
					{
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"drawer_pop_warning_message_1"
							)[0].lanName
							: "" // 저장되지 않은 목록이 있습니다.
					}
				</div>
				<div>
					{
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"drawer_pop_warning_message_2"
							)[0].lanName
							: "" // 이동 시 모두 지워집니다.
					}
				</div>
				<div>
					{
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"drawer_pop_warning_message_3"
							)[0].lanName
							: "" // 그래도 이동하시겠습니까?
					}
				</div>
			</Dialog>
			{/** 컨트롤러 재조회 시간 변경 => Not Used */}
			<Dialog
				open={settingsToggle}
				onClose={() => setSettingsToggle(false)}
				title={
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"settings_gen_change_settings"
						)[0].lanName
						: "" // 설정 변경
				}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"home_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onSave={async () => {
					if (intervalTime >= 3) {
						await Commons.Storages("set", "settings", {
							...Commons.Storages("get", "settings"),
							reloadTime: intervalTime * 1000,
						});
						setSettingsToggle(false);
						setMessage({
							toggle: false,
							message: "",
							type: "success",
						});
						return window.location.reload();
					} else {
						setMessage({
							toggle: true,
							message: "3초 이상 입력해주세요",
							type: "error",
						});
						setIntervalTime(3);
						return false;
					}
				}}
				useSaveAfterClose={false}
			>
				<div
					style={{
						alignContent: "center",
					}}
				>
					컨트롤러 재조회 시간 설정 (초)
				</div>
				<div>
					<TextField
						id="intervalTimeField"
						sx={{ my: 2 }}
						label={
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_least_second"
								)[0].lanName
								: "최소 3초 이상" // 최소 3초 이상
						}
						value={intervalTime}
						onChange={(e) => {
							setIntervalTime(e.target.value);
							setMessage({
								toggle: false,
								message: "",
								type: "success",
							});
						}}
						onClick={(e) => {
							const intervalTimeInput =
								document.getElementById("intervalTimeField");
							intervalTimeInput.select();
							intervalTimeInput.focus();
						}}
						fullWidth
						variant="outlined"
						inputMode="number"
					/>
				</div>
				{message.toggle && (
					<Alert
						sx={{ mt: 1, fontSize: 15 }}
						noteType={message.type}
						name={message.message}
						onClose={() => {
							setMessage({
								...message,
								toggle: false,
							});
						}}
					/>
				)}
			</Dialog>
			<Dialog
				open={logoutToggle}
				onClose={() => setLogoutToggle(false)}
				title={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_warning_title"
						)[0].lanName
						: "경고" // 경고
				}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_confirm"
						)[0].lanName
						: "확인" // 확인
				}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "drawer_pop_close"
						)[0].lanName
						: "닫기" // 닫기
				}
				onSave={() => {
					LogoutLogic();
				}}
			>
				<div>
					{
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_no_more_push"
							)[0].lanName
							: "더 이상 푸시 메시지가 전송되지 않습니다." // 더 이상 푸시 메시지가 전송되지 않습니다.
					}
				</div>
				<div>
					{
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_wanna_logout"
							)[0].lanName
							: "로그아웃 하시겠습니까?" // 로그아웃 하시겠습니까?
					}
				</div>
			</Dialog>
		</div>
	);
}
