import React from "react";
import { useDispatch } from "react-redux";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card, Stack } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Button } from "../../Components/Buttons";
import { Snackbar } from "../../Components/Snackbar";
import { Maps } from "../../Components/PostCode";
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";
/** Splitter */
import { Splitter, SplitterPanel } from "primereact/splitter";

/** 컨트롤러 관리 화면 */

function CM10000(props) {
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const gridRef = React.useRef();
	const gridRef1 = React.useRef();
	const gridRef2 = React.useRef();
	const gridRef3 = React.useRef();
	const columnDefs = [
		{
			field: "userId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_user_id"
					)[0].lanName
					: "", // 사용자 아이디
			minWidth: 250,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "userNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_user_name"
					)[0].lanName
					: "", // 사용자
			minWidth: 150,
			editable: false,
		},
		// {
		// 	field: "useYn",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 					(f) => f.lanId === "cm10000_col_use_yn"
		// 			  )[0].lanName
		// 			: "", // 사용유무
		// 	minWidth: 120,
		// 	editable: false,
		// },
		{
			field: "paidYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_amount_yn"
					)[0].lanName
					: "", // 유료가입 여부
			minWidth: 140,
			editable: false,
		},
		{
			field: "useSdate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_start_date"
					)[0].lanName
					: "", // 시작일자
			minWidth: 120,
			editable: false,
		},
		{
			field: "useEdate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_end_date"
					)[0].lanName
					: "", // 종료일자
			minWidth: 120,
			editable: false,
		},
	];
	const columnDefs1 = [
		{
			field: "no",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_number"
					)[0].lanName
					: "", // 연번
			minWidth: 130,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "itemNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_item_Setting"
					)[0].lanName
					: "", // 장비 설정
			cellRenderer: (e) => {
				return e.data.itemNm ? (
					<a
						onClick={() => {
							if (e.data.itemNo < 0) {
								props.setSelectedItemNo(e.data.itemNo);
								props.GetDtlList2();
							}
						}}
						style={{
							color:
								e.data.useYn === "미사용"
									? "#5f5f5f"
									: "#FFFFFF",
						}}
					>
						{e.data.itemNm}
					</a>
				) : (
					<Button
						name={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"cm10000_button_machine_setting"
								)[0].lanName
								: "" // 장비 설정
						}
						onClick={() => {
							props.setSelectedItemNo(e.data.itemNo);
							props.GetDtlList2();
						}}
					/>
				);
			},
			minWidth: 180,
			// headerCheckboxSelection: true,
			// checkboxSelection: true,
			editable: false,
		},
		{
			field: "itemNo",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_item_no"
					)[0].lanName
					: "", // 제품관리번호
			cellRenderer: (e) => {
				return e.data.itemNo < 0 ? "" : e.data.itemNo;
			},
			minWidth: 180,
			editable: false,
		},
		// {
		// 	field: "itemGrp",
		// 	headerName: "장비그룹명",
		// 	minWidth: 160,
		// 	editable: true,
		// },
		{
			field: "machNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_machine_name"
					)[0].lanName
					: "", // 장비명
			minWidth: 160,
			editable: true,
		},
		{
			field: "macAdd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_mac_address"
					)[0].lanName
					: "", // Mac 주소
			cellRenderer: (e) => {
				return e.data.macAdd ? (
					<a
						style={{
							color:
								e.data.useYn === "사용" ? "#FFFFFF" : "#5f5f5f",
						}}
						onClick={() => {
							props.setSelectedItemNo(e.data.itemNo);
							props.GetDtlList3();
						}}
					>
						{e.data.macAdd}
					</a>
				) : (
					<Button
						name={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"cm10000_button_mac_address_setting"
								)[0].lanName
								: "" // MAC 주소 설정
						}
						onClick={() => {
							if (e.data.itemNm !== undefined) {
								props.setSelectedItemNo(e.data.itemNo);
								props.GetDtlList3();
							} else {
								setSnacks({
									open: true,
									type: "error",
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_select_controller"
											)[0].lanName
											: "", // 장비를 먼저 선택해 주세요.
								});
							}
						}}
						style={{
							backgroundColor:
								e.data.useYn === "미사용" ? "#181d1f" : "",
							color: e.data.useYn === "미사용" ? "#5f5f5f" : "",
						}}
					/>
				);
			},
			minWidth: 200,
			editable: false,
			cellStyle: { display: "flex", justifyContent: "left" },
		},
		{
			field: "machId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_machine_id"
					)[0].lanName
					: "", // 국번
			minWidth: 160,
			editable: true,
		},
		{
			field: "fwVer",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_firmware_version"
					)[0].lanName
					: "", // 펌웨어 버전
			minWidth: 160,
			editable: true,
		},
		// {
		// 	field: "useYn",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 					(f) => f.lanId === "cm10000_col_use_yn"
		// 			  )[0].lanName
		// 			: "", // 사용유무
		// 	minWidth: 180,
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: ["사용", "미사용"],
		// 	},
		// 	// headerComponentParams: (params) => {
		// 	// 	return { displayName: '사용유무 (' + props.numberInUse + "/" + props.rowData1.length + ")" };
		// 	// },
		// 	editable: true,
		// },
		{
			field: "screenYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_screen_yn"
					)[0].lanName
					: "", // 화면표시유무
			minWidth: 180,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: ["사용", "미사용"],
			},
			// headerComponentParams: (params) => {
			// 	return { displayName: '사용유무 (' + props.numberInUse + "/" + props.rowData1.length + ")" };
			// },
			editable: true,
		},
		{
			field: "Address",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_address_enter"
					)[0].lanName
					: "", // 주소 입력
			minWidth: 170,
			cellRenderer: (e) => {
				return (
					<Button
						name={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"cm10000_button_machine_address_setting"
								)[0].lanName
								: "" // 장치 주소 설정
						}
						onClick={() => {
							props.setSelectedItemNo(e.data.itemNo);
							props.setPostToggle(true);
						}}
						style={{
							backgroundColor:
								e.data.useYn === "미사용" ? "#181d1f" : "",
							color: e.data.useYn === "미사용" ? "#5f5f5f" : "",
						}}
					/>
				);
			},
			editable: false,
			cellStyle: { display: "flex", justifyContent: "left" },
		},
		{
			field: "itemLan",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_latitude"
					)[0].lanName
					: "", // 위도
			minWidth: 160,
			editable: false,
		},
		{
			field: "itemLong",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_longitude"
					)[0].lanName
					: "", // 경도
			minWidth: 160,
			editable: false,
		},
		{
			field: "postNo",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_poster_code"
					)[0].lanName
					: "", // 우편번호
			minWidth: 120,
			editable: false,
		},
		{
			field: "address1",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_address"
					)[0].lanName
					: "", // 주소
			minWidth: 260,
			editable: false,
		},
		{
			field: "address2",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_end_date"
					)[0].lanName
					: "", // 상세주소
			minWidth: 260,
			editable: true,
		},
		{
			field: "itemOrder",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_address_detail"
					)[0].lanName
					: "", // 정렬순서
			minWidth: 120,
			editable: false,
		},
		{
			field: "tmpSpec",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_tmp_upper_limit"
					)[0].lanName
					: "", // 온도 상한값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "tmpArYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_tmp_alart_yn"
					)[0].lanName
					: "", // 온도알림유무
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: ["사용", "미사용"],
			},
			editable: true,
		},
		{
			field: "tmpMinVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_tmp_minimum"
					)[0].lanName
					: "", // 온도 최소값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "tmpMaxVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_tmp_maximum"
					)[0].lanName
					: "", // 온도 최대값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "hmdArYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_hmd_alart_yn"
					)[0].lanName
					: "", // 습도알림유무
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: ["사용", "미사용"],
			},
			editable: true,
		},
		{
			field: "hmdMinVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_hmd_minimum"
					)[0].lanName
					: "", // 습도 최소값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "hmdMaxVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_hmd_maximum"
					)[0].lanName
					: "", // 습도 최대값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "illArYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_ill_alart_yn"
					)[0].lanName
					: "", // 조도 알림유무
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: ["사용", "미사용"],
			},
			editable: true,
		},
		{
			field: "illMinVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_ill_minimum"
					)[0].lanName
					: "", // 조도 최소값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "illMaxVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_ill_maximum"
					)[0].lanName
					: "", // 조도 최대값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "co2ArYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_co2_alart_yn"
					)[0].lanName
					: "", // CO2 알림유무
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: ["사용", "미사용"],
			},
			editable: true,
		},
		{
			field: "co2MinVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_co2_minimum"
					)[0].lanName
					: "", // CO2 최소값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "co2MaxVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_co2_maximum"
					)[0].lanName
					: "", // CO2 최대값
			minWidth: 160,
			cellDataType: "number",
			editable: true,
		},
		{
			field: "defHgArYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_defrosting_alart_yn"
					)[0].lanName
					: "", // 제상시 고온 알림유무
			minWidth: 180,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: ["사용", "미사용"],
			},
			editable: true,
		},
		{
			field: "joinRtNo",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId === "cm10000_col_conntection_repeater"
					)[0].lanName
					: "", // 연결 중계기
			minWidth: 180,
			// cellEditor: "agRichSelectCellEditor",
			// cellEditorParams: {
			// 	values: props.rtList.map((item) => item.name),
			// },
			cellEditorSelector: (e) => {
				return {
					component: "agRichSelectCellEditor",
					params: {
						values:
							e.data.itemType === "7" ||
								e.data.itemIdx === 2 ||	// DSFOX-SLE30
								e.data.itemIdx === 3 ||	// DSFOX-SLW30
								e.data.itemIdx === 10		// DSFOX-XLink
								? []
								: props.rtList.map((item) => item.name),
					},
				};
			},
			editable: true,
		},
		// {
		// 	field: "tmpSaveTime",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "cm10000_col_tmp_save_time"
		// 			)[0].lanName
		// 			: "", // 장비별 온도 저장 시간(분)
		// 	minWidth: 220,
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: props.codeList
		// 			.filter((item) => item.groupCd === "MACH_TMP_REV_TIME")
		// 			.map((item) => item.name),
		// 	},
		// 	editable: true,
		// },
		// {
		// 	field: "conForm",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "cm10000_col_connection_form"
		// 			)[0].lanName
		// 			: "", // 전문형식
		// 	minWidth: 160,
		// 	editable: false,
		// },
		{
			field: "updateId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_update_id"
					)[0].lanName
					: "", // 수정자
			minWidth: 160,
			editable: false,
		},
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_update_date"
					)[0].lanName
					: "", // 수정일시
			minWidth: 180,
			editable: false,
		},
	];
	const columnController = [
		{
			field: "itemCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_item_code"
					)[0].lanName
					: "", // 품목코드
			minWidth: 160,
		},
		{
			field: "itemNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_item_name"
					)[0].lanName
					: "", // 품목명
			minWidth: 180,
		},
		{
			field: "conForm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_connection_form"
					)[0].lanName
					: "", // 전문형식
			minWidth: 120,
		},
		{
			field: "conType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_connection_type"
					)[0].lanName
					: "", // 통신타입
			minWidth: 120,
		},
		{
			field: "itemQty",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_item_qty"
					)[0].lanName
					: "", // 수량
			minWidth: 120,
		},
		{
			field: "itemStdAmt",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId === "cm10000_col_item_standard_amount"
					)[0].lanName
					: "", // 기준단가
			minWidth: 120,
		},
		{
			field: "itemSvsAmt",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_item_service_amount"
					)[0].lanName
					: "", // 서비스단가
			minWidth: 120,
		},
		{
			field: "itemVatYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_item_vat_yn"
					)[0].lanName
					: "", // 부가세포함여부
			minWidth: 150,
		},
		{
			field: "remarks",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_remark"
					)[0].lanName
					: "", // 비고
			minWidth: 120,
		},
	];
	const columnMacAdd = [
		{
			field: "macAdd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "cm10000_col_mac_address"
					)[0].lanName
					: "", // Mac 주소
			minWidth: 250,
			editable: false,
		},
	];
	const [confirmToggle, setConfirmToggle] = React.useState({
		open: false,
		title: "",
		message: "",
		type: "",
	});
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});

	return (
		<>
			<Splitter>
				<SplitterPanel size={25}>
					<Card
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							m: 0.3,
							p: 0.5,
						}}
					>
						<Stack sx={{ m: 0.5 }} direction="row">
							<Button
								style={{
									backgroundColor: "#292f3b",
								}}
								name="　"
								disabled
							/>
						</Stack>
					</Card>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{ width: "100%", height: "715px" }}
						>
							<AgGridReact
								ref={gridRef}
								animationRows={true}
								columnDefs={columnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: true,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="single"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() => {
									GridFunctions.onSelectionChanged(
										gridRef,
										(_selected) => {
											props.setSelectedRowData(_selected);
											props.GetDtlList(_selected);
										}
									);
								}}
								onCellValueChanged={(e) => { }}
								getRowStyle={(e) =>
									GridFunctions.getRowStyle(e)
								}
							/>
						</div>
					</Card>
				</SplitterPanel>
				<SplitterPanel size={75}>
					<Card
						sx={{
							display: "flex",
							justifyContent: "space-between",
							m: 0.3,
							p: 0.5,
						}}
					>
						<Stack sx={{ m: 0.5 }} direction="row"></Stack>
						<AuthGroupButton
							pageName="CM10000"
							target="G02"
							addFunc={async () => {
								// console.log("props.rowData1 : ", props.rowData1.length);
								if (props.selectedRowData.length === 0) {
									setSnacks({
										open: true,
										type: "error",
										message:
											props.lanList.length > 0
												? props.lanList.filter(
													(f) =>
														f.lanId ===
														"snack_check_user"
												)[0].lanName
												: "", // 유저를 선택해 주세요.
									});
									return false;
								} else if (props.rowData1.length >= 29) {
									props.setSnackDialog({
										...props.snackDialog,
										open: true,
										title: props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_error_title"
											)[0].lanName
											: "에러!", // 에러!
										message: props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_no_more_controller"
											)[0].lanName
											: "더 이상 컨트롤러를 추가할 수 없습니다.", // 더 이상 컨트롤러를 추가할 수 없습니다.
									});
								} else {
									return props.CheckingLogic1(
										gridRef1,
										props.selectedRowData[0].userId
									);
								}
							}}
							saveFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"button_save"
											)[0].lanName
											: "", // 저장
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_do_save"
											)[0].lanName
											: "", // 저장하시겠습니까?
									type: "save1",
								});
							}}
							refreshNone="none"
							deleteFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"button_delete"
											)[0].lanName
											: "", // 삭제
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_do_delete"
											)[0].lanName
											: "", // 삭제하시겠습니까?
									type: "delete1",
								});
							}}
						/>
					</Card>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{ width: "100%", height: "715px" }}
						>
							<AgGridReact
								ref={gridRef1}
								animationRows={true}
								columnDefs={columnDefs1} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: true,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData1}
								rowSelection="multiple"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() =>
									GridFunctions.onSelectionChanged(
										gridRef1,
										props.setSelectedRowData1
									)
								}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen()); // 열림 방지
									GridFunctions.onCellValueChanged(
										e,
										gridRef1,
										props.setChangedRowData1
									);
								}}
								getRowStyle={(e) => {
									if (
										e.data.screenYn === "미사용" &&
										e.data.newYn !== "Y"
									) {
										return { color: "#5f5f5f" };
									} else {
										return GridFunctions.getRowStyle(e);
									}
								}}
							/>
						</div>
					</Card>
				</SplitterPanel>
			</Splitter>
			<Dialog
				fullWidth
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_add_title"
						)[0].lanName
						: "" // 컨트롤러 추가
				}
				open={props.controllerToggle}
				onClose={() => {
					props.setControllerToggle(false);
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onSave={() => {
					if (props.selectedRowData.length === 0) {
						props.setControllerToggle(false);
					} else {
						props.ControllerInsert();
					}
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={12} md={12}>
						<Card>
							<div
								className="ag-theme-alpine-dark"
								style={{ width: "100%", height: "600px" }}
							>
								<AgGridReact
									ref={gridRef2}
									animationRows={true}
									columnDefs={columnController} // 컬럼 헤더
									defaultColDef={{
										flex: 1,
										minWidth: 100,
										editable: false,
										resizable: true,
										sortable: true,
										filter: true,
										floatingFilter: true,
									}}
									rowData={props.rowData2}
									rowSelection="single"
									singleClickEdit={
										Commons.IsMobile() ? true : false
									}
									onSelectionChanged={() => {
										GridFunctions.onSelectionChanged(
											gridRef2,
											(_selected) => {
												props.setSelectedRowData2(
													_selected
												);
											}
										);
									}}
									onCellValueChanged={(e) => {
										GridFunctions.onCellValueChanged(
											e,
											gridRef2,
											props.setChangedRowData2
										);
									}}
									getRowStyle={(e) =>
										GridFunctions.getRowStyle(e)
									}
									onRowDoubleClicked={() => {
										props.ControllerInsert();
										props.setControllerToggle(false);
									}}
								/>
							</div>
						</Card>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				fullWidth={true}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_mac_title"
						)[0].lanName
						: "" // MAC 주소 설정
				}
				open={props.macToggle}
				onClose={() => {
					props.setMacToggle(false);
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onSave={() => {
					return props.MacAddInsert();
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				<Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={12} md={12}>
						<Card>
							<div
								className="ag-theme-alpine-dark"
								style={{ width: "100%", height: "600px" }}
							>
								<AgGridReact
									ref={gridRef3}
									animationRows={true}
									columnDefs={columnMacAdd} // 컬럼 헤더
									defaultColDef={{
										flex: 1,
										minWidth: 100,
										editable: true,
										resizable: true,
										sortable: true,
										filter: true,
										floatingFilter: true,
									}}
									rowData={props.showRowData3}
									rowSelection="single"
									singleClickEdit={
										Commons.IsMobile() ? true : false
									}
									onSelectionChanged={() => {
										GridFunctions.onSelectionChanged(
											gridRef3,
											(_selected) => {
												props.setSelectedRowData3(
													_selected
												);
											}
										);
									}}
									getRowStyle={(e) =>
										GridFunctions.getRowStyle(e)
									}
									onRowDoubleClicked={() => {
										props.MacAddInsert();
										props.setMacToggle(false);
									}}
								/>
							</div>
						</Card>
					</Grid>
				</Grid>
			</Dialog>
			<Maps
				open={props.postToggle}
				onClose={() => props.setPostToggle(false)}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_search_address"
						)[0].lanName
						: "" // 주소검색
				}
				fullScreen={Commons.IsMobile() ? true : false}
				ReceivePost={(data) => {
					props.AddressInsert(data);
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			/>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onSave={() => {
					if (confirmToggle.type === "save1") {
						props.CreateLogic1();
					} else if (confirmToggle.type === "delete1") {
						props.DeleteLogin1();
					}
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				{confirmToggle.message}
			</Dialog>
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
		</>
	);
}

export default CM10000;
