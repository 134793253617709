import React from "react";
import { useNavigate } from "react-router-dom";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
import * as util from "../../util.js";
/** Firebase Import */
import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import {
	getToken,
	getMessaging,
	onMessage,
	isSupported,
} from "firebase/messaging";
/** Components */
import LoginView from "./../../Views/Account/Login";
import { Loading } from "../../Components/Progresses";
import { Snackbar } from "../../Components/Snackbar";

function Login(props) {
	const navigate = useNavigate();
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		type: "info",
		open: false,
		message: "",
	});
	const [inputs, setInputs] = React.useState({
		userId: "",
		userPw: "",
		passwordVisible: false,
		findPwToggle: false,
		findEmail: "",
		findConfirmToggle: false,
	});
	const [registers, setRegisters] = React.useState({
		register: false,
		userId: "",
		useUserId: false, // 아이디 사용 가능 여부. false: 사용 불가, true: 사용 가능
		userNm: "",
		userPw: "",
		useUserPw: false, // 비밀번호 사용 가능 여부. false: 사용 불가, true: 사용 가능
		userTel: "",
		userTerm: false,	// 회원가입시 사용자 개인정보 동의
		siteTerm: false,	// 회원가입시 사이트 이용약관 동의
		userPost: "",
		userAddress1: "",
		userAddress2: "",
	});
	const [deferredPrompt, setDeferredPrompt] = React.useState(null); // 앱 설치 prompt를 담는 변수
	let firebaseSupported = false;
	let firebaseMessaging = null;
	let firebaseToken = "0";
	const firebaseConfig = {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	};
	const firebase = initializeApp(firebaseConfig);
	isSupported().then((result) => {
		firebaseSupported = result;
		if (result) {
			firebaseMessaging = getMessaging(firebase);

			util.getPermission();
			onMessage(firebaseMessaging, (payload) => {
				// console.log("Message received. ", payload);
				// console.log("title: ", payload.notification.title);
				// console.log("body: ", payload.notification.body);
				setSnacks({
					...snacks,
					type: "info",
					open: true,
					message:
						payload.notification.title +
						`${(<br />)} Message: ` +
						payload.notification.body,
				});
			});
		}
	});

	React.useEffect(() => {
		window.addEventListener(
			"beforeinstallprompt",
			HandleBeforeInstallPrompt
		);
		return () => {
			window.removeEventListener(
				"beforeinstallprompt",
				HandleBeforeInstallPrompt
			);
		};
	}, []);

	const HandleBeforeInstallPrompt = (e) => {
		e.preventDefault();
		setDeferredPrompt(e);
	};

	// 앱 설치 확인
	const HandleInstall = () => {
		// console.log("deferredPrompt : ", deferredPrompt);
		if (deferredPrompt) {
			deferredPrompt.prompt();
			deferredPrompt.userChoice.then((result) => {
				if (result.outcome === "accepted") {
					// console.log("앱 설치 동의");
				} else {
					// console.log("앱 설치 미동의");
				}

				setDeferredPrompt(null);
			});
		} else {

		}
	};

	// 로그인 체크
	const LoginLogic = async (type = "direct") => {
		setProgress(true);

		try {
			if (inputs.userId === ""
				|| inputs.userId === null
				|| inputs.userPw === ""
				|| inputs.userPw === null
			) {
				throw new Error();
			} else {
				window.localStorage.removeItem("userInfo");
				await IndexedDb.ClearDb("MenuList");
				await IndexedDb.ClearDb("AuthList");
				await IndexedDb.ClearDb("LoginInfo");
				await IndexedDb.ClearDb("LanguageList");

				switch (type) {
					case "kakao":
					default:
						await GetTokenLogic();
				}
			}
		} catch (e) {
			setSnacks({
				...snacks,
				type: "error",
				open: true,
				message: "아이디 혹은 비밀번호를 확인하세요.",
			});
		} finally {
			setProgress(false);
		}
	};

	// 로그인 진행
	const GetTokenLogic = async () => {
		setProgress(true);
		let menus = [
			{
				lcategoCd: "Home",
				mcategoCd: "Home",
				mcategoNm: "Home",
				mobileYn: "Y",
				open: false,
				screenType: "L",
				userId: "System",
				sort: 0,
			},
		];
		let auths = [];
		let lans = [];

		try {
			if (firebaseSupported) {
				if (
					Notification.permission !== "granted" ||
					!Commons.IsMobile()
				) {
					firebaseToken = "0"; // denied alarm
				} else {
					firebaseToken = await getToken(firebaseMessaging, {
						vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
					});
				}
			} else {
				firebaseToken = "0";
			}

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Account.login,
				{
					userId: inputs.userId,
					password: inputs.userPw,
					TokenKey:
						firebaseToken === null ||
							firebaseToken === "" ||
							firebaseToken.length < 20
							? "0"
							: firebaseToken,
					type: Commons.IsMobile() ? "mobile" : "web",
				}
			).then(async (result) => {
				let data = result.data;
				// console.log("Login logic:", data);

				if (data.type === "Error") {
					setInputs({
						...inputs,
						userId: "",
						userPw: "",
					});
					throw new Error(data.message);
				} else if (!data) {
					setInputs({
						...inputs,
						userId: "",
						userPw: "",
					});
					setSnacks({
						...snacks,
						type: "error",
						open: true,
						message: "아이디 혹은 비밀번호를 확인하세요.",
					});
				} else if (
					data.userInfo.userId.toUpperCase() ===
					inputs.userId.toUpperCase()
				) {
					let useEndDate =
						data.userInfo.useEdate.substring(0, 4) +
						"-" +
						data.userInfo.useEdate.substring(4, 6) +
						"-" +
						data.userInfo.useEdate.substring(6);
					for (let i = 0; i < data.menuList.length; i++) {
						menus.push(data.menuList[i]);
					}
					for (let i = 0; i < data.authList.length; i++) {
						auths.push(data.authList[i]);
					}
					for (let i = 0; i < data.languageList.length; i++) {
						lans.push(data.languageList[i]);
					}

					await IndexedDb.AddDb("LoginInfo", [
						{
							token: data.userInfo.token,
							userId: data.userInfo.userId,
							userNm: data.userInfo.userNm,
							eMail: data.userInfo.eMail,
							lanCode: data.languageList[0].lanCode,
							postNo: data.userInfo.postNo,
							address1: data.userInfo.address1,
							address2: data.userInfo.address2,
							validaty: data.userInfo.validaty,
							refreshToken: data.userInfo.refreshToken,
							id: data.userInfo.id,
							guidId: data.userInfo.guidId,
							expiredTime: data.userInfo.expiredTime,
							connTime: Commons.DateFormating(new Date(), 1),
							plantList: data.plantList,
							amountYn: data.userInfo.amountYn,
							useSdate: data.userInfo.useSdate,
							useEdate: data.userInfo.useEdate,
							kakaoAccessToken: null,
							kakaoRefreshToken: null,
							serverVersion: data.userInfo.serverVersion,
							clientVersion: Commons.Storages("get", "version")
								.version,
						},
					]);
					Commons.Storages("set", "userInfo", {
						userId: data.userInfo.userId,
						userNm: data.userInfo.userNm,
						privacyYn: data.userInfo.privacyYn,
						useEndDate: useEndDate,
						amountYn: data.userInfo.amountYn,
						lanCode: data.languageList[0].lanCode,
					});
					Commons.Storages("set", "authorization", {
						authorization: true,
						authDate: Commons.DateFormating(new Date()),
					});
					// console.log(
					// 	"server version: ",
					// 	data.userInfo.serverVersion
					// );
					// console.log(
					// 	"client version: ",
					// 	Commons.Storages("get", "version").version
					// );
					menus.push(
						// {
						// 	lcategoCd: "test",
						// 	mcategoCd: "test",
						// 	mcategoNm: "test 설정",
						// 	mobileYn: "Y",
						// 	open: false,
						// 	screenType: "L",
						// 	userId: "System",
						// 	sort: 9998,
						// },
						{
							lcategoCd: "Settings",
							mcategoCd: "Settings",
							mcategoNm: "설정",
							mobileYn: "Y",
							open: false,
							screenType: "L",
							userId: "System",
							sort: 9999,
						}
					);
					await IndexedDb.AddDb("MenuList", menus);
					await IndexedDb.AddDb("AuthList", auths);
					await IndexedDb.AddDb("LanguageList", lans);
					navigate("/Home");
				} else if (
					data.userInfo.userId.toUpperCase() ===
					inputs.userId.toUpperCase()
				) {
					setInputs({
						...inputs,
						userId: "",
						userPw: "",
					});
					setSnacks({
						...snacks,
						type: "error",
						open: true,
						message:
							"등록된 아이디와 입력된 아이디가 일치하지 않습니다.",
					});
				}
			});
		} catch (e) {
			console.log(e);
			setSnacks({
				...snacks,
				type: "error",
				open: true,
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 비밀번호 찾기
	const FindPassword = async () => {
		setProgress(true);

		try {
			if (!inputs.findEmail) {
				throw new Error("이메일을 입력하세요.");
			}

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Account.PushEMail,
				{
					eMail: inputs.findEmail,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "가입된 사용자가 아닙니다.") {
					throw new Error(data);
				} else if (data !== "Ok") {
					throw new Error("메일 전송에 실패했습니다.");
				} else {
					setInputs({
						...inputs,
						findConfirmToggle: true,
						findPwToggle: false,
						findEmail: "",
					});
					return true;
				}
			});
		} catch (e) {
			console.log(e);
			setSnacks({
				...snacks,
				open: true,
				type: "error",
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 아이디 중복 체크
	const CheckUserId = async () => {
		setProgress(true);

		try {
			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Account.CheckUserId,
				{
					userId: registers.userId,
				}
			).then((result) => {
				let data = result.data;

				if (data) {
					setSnacks({
						...snacks,
						open: true,
						type: "success",
						message: "사용 가능한 아이디입니다.",
					});
					setRegisters({
						...registers,
						useUserId: true, // 아이디 사용 가능 여부. false: 사용 불가, true: 사용 가능
					});
				} else {
					setSnacks({
						...snacks,
						open: true,
						type: "error",
						message: "사용 불가능한 아이디입니다.",
					});
				}
			});
		} catch (e) {
			console.log(e);
			setSnacks({
				...snacks,
				open: true,
				type: "error",
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 회원가입
	const RegisterLogic = async () => {
		setProgress(true);

		try {
			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Account.RegisterUser,
				{
					userId: registers.userId,
					password: registers.userPw,
					userNm: registers.userNm,
					postNo: registers.userPost,
					address1: registers.userAddress1,
					address2: registers.userAddress2,
					tel: registers.userTel,
				}
			).then((result) => {
				let data = result.data;
				// console.log("RegisterLogic data: ", data);

				if (data.type === "Error") {
					setInputs({
						...inputs,
						userId: "",
						userPw: "",
					});
					throw new Error(data.message);
				} else if (data.userId === registers.userId) {
					setSnacks({
						...snacks,
						type: "success",
						open: true,
						message: "회원가입이 완료되었습니다!",
					});
					// 등록 데이터 초기화
					setTimeout(() => {
						setRegisters({
							register: false,
							userId: "",
							useUserId: false,
							userNm: "",
							userPw: "",
							useUserPw: false,
							userTel: "",
							userPost: "",
							userAddress1: "",
							userAddress2: "",
						});
					}, 500);
				}
			});
		} catch (e) {
		} finally {
			setProgress(false);
		}
	};

	return (
		<div className="Login">
			<LoginView
				inputs={inputs}
				setInputs={setInputs}
				registers={registers}
				setRegisters={setRegisters}
				setSnacks={setSnacks}
				// 로그인 관련 함수
				LoginLogic={LoginLogic}
				CheckUserId={CheckUserId}
				RegisterLogic={RegisterLogic}
				FindPassword={FindPassword}
				//
				deferredPrompt={deferredPrompt}
				HandleInstall={HandleInstall}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
		</div>
	);
}

export default Login;
