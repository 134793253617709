import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardHeader,
	CardContent as MuiCardContent,
	CardActionArea,
	Typography,
	Switch,
} from "@mui/material";
import { GroupButton } from "../../Components/Buttons";
/** Icons Import */
/** Images Import */
import SettingIcon from "../../Assets/Images/Design/drawable-hdpi/setting_ic.png";

const IOSSwitch = styled((props) => (
	<Switch
		focusVisibleClassName=".Mui-focusVisible"
		disableRipple
		{...props}
	/>
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor:
					theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const CardContents = styled((props) => <MuiCardContent {...props} />)(
	({ theme }) => ({
		padding: "0px",
		margin: "0px",
	})
);
const CardContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-around",
	alignItems: "center",
}));
const CardContentItemDivs = styled((props) => <div {...props} />)(
	({ theme }) => ({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
	})
);
const CardContentItemDivAction = styled((props) => (
	<CardActionArea {...props} />
))(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));
const CardContentItemHeader = styled((props) => (
	<Typography variant="caption" {...props} />
))(({ theme }) => ({
	width: "100%",
	textAlign: "center",
	fontWeight: "bold",
	padding: "2px 8px",
	borderWidth: "1px",
	borderBottomWidth: "0px",
	borderColor: "#4A5263",
	borderStyle: "solid",
	color: "#FFFFFF",
	backgroundColor: "#000000",
}));
const CardContentItemData = styled((props) => (
	<Typography variant="body1" {...props} />
))(({ theme }) => ({
	width: "100%",
	textAlign: "center",
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	fontWeight: "500",
	padding: "12px 8px",
	borderWidth: 1,
	borderColor: "#4A5263",
	borderStyle: "solid",
	color: "#FFFFFF",
}));
const CardContentItemButtons = styled((props) => <div {...props} />)(
	({ theme }) => ({
		width: "100%",
		borderWidth: 1,
		borderColor: "#4A5263",
		borderStyle: "solid",
		color: "#FFFFFF",
	})
);

function DashboardSetting({
	items = [], // SettingList's item list
	lanList = [],
}) {
	return (
		<Grid xs={12} md={12}>
			<Card sx={{ height: "100%" }}>
				<CardHeader
					sx={{ bgcolor: "#141820" }}
					avatar={
						<img
							style={{ width: "20px", height: "20px" }}
							src={SettingIcon}
						/>
					}
					title={
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"dashboard_gen_device_settings"
							)[0].lanName
							: "" // 설비 설정
					}
					titleTypographyProps={{ variant: "body2" }}
				/>
				<CardContents style={{ paddingBottom: "5px" }}>
					<CardContentItems>
						<CardContentItemDivs>
							<CardContentItemHeader>
								{lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_device_settings_nm"
									)[0].lanName
									: ""}
								{/* 설정명 */}
							</CardContentItemHeader>
							<CardContentItemDivs>
								<CardContentItemHeader>
									{lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"dashboard_gen_device_settings_value"
										)[0].lanName
										: ""}
									{/* 설정 값 */}
								</CardContentItemHeader>
							</CardContentItemDivs>
						</CardContentItemDivs>
					</CardContentItems>
					{items.map((item, index) => {
						return (
							<CardContentItems key={index}>
								<CardContentItemDivs>
									<CardContentItemData
										sx={{ textAlign: "left" }}
									>
										{item.mapNm}
									</CardContentItemData>
									<CardContentItemDivs>
										<CardContentItemButtons
											sx={{ py: "5.5px", px: "2px" }}
										>
											<GroupButton
												fullWidth={true}
												items={item.inputs}
											/>
										</CardContentItemButtons>
									</CardContentItemDivs>
								</CardContentItemDivs>
							</CardContentItems>
						);
					})}
				</CardContents>
			</Card>
		</Grid>
	);
}

export default DashboardSetting;
