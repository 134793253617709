import React from "react";
import { useDispatch } from "react-redux";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Card } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
import { Snackbar } from "../../Components/Snackbar";
import { FileForm } from "../../Components/FileForm";
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";
/** Splitter */
import { Splitter, SplitterPanel } from "primereact/splitter";

/** 컨트롤러 등록화면 */

function BM10020(props) {
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [mapToggle, setMapToggle] = React.useState(false);
	const [valToggle, setValToggle] = React.useState(false);
	const gridRef = React.useRef();
	const gridRef1 = React.useRef();
	const gridRef2 = React.useRef();
	const ColumnDefs = [
		{
			field: "itemCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_item_code"
					)[0].lanName
					: "", // 품목코드
			minWidth: 160,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		// {
		// 	field: "itemIdx",
		// 	headerName: "품목순번",
		// 	minWidth: 120,
		// 	editable: true,
		// },
		{
			field: "itemNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_item_nm"
					)[0].lanName
					: "", // 품목명
			minWidth: 180,
			editable: true,
		},
		{
			field: "itemType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_item_type"
					)[0].lanName
					: "", // 품목타입
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "ITEM_TYPE")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			minWidth: 160,
			editable: true,
		},
		// {
		// 	field: "lCatego",
		// 	headerName: "대분류",
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: props.codeList
		// 			.filter((item) => item.groupCd === "L_CATEGO")
		// 			.map((item) => item.name),
		// 	},
		// 	minWidth: 120,
		// 	editable: true,
		// },
		// {
		// 	field: "mCatego",
		// 	headerName: "중분류",
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: props.codeList
		// 			.filter((item) => item.groupCd === "M_CATEGO")
		// 			.map((item) => item.name),
		// 	},
		// 	minWidth: 120,
		// 	editable: true,
		// },
		// {
		// 	field: "itemUnit",
		// 	headerName: "품목단위",
		// 	minWidth: 260,
		// 	editable: true,
		// },
		// {
		// 	field: "itemStd1",
		// 	headerName: "규격1",
		// 	minWidth: 120,
		// 	editable: true,
		// },
		// {
		// 	field: "itemStd2",
		// 	headerName: "규격2",
		// 	minWidth: 120,
		// 	editable: true,
		// },
		{
			field: "rcsa",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_rcsa"
					)[0].lanName
					: "", // RCSA
			minWidth: 120,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0, // 숫자 최솟값
				precision: 0, // 소수점 이후 허용되는 자릿수
			},
			editable: true,
			cellDataType: "number",
		},
		{
			field: "rhra",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_rhra"
					)[0].lanName
					: "", // RHRA
			minWidth: 120,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0,
				precision: 0,
			},
			editable: true,
			cellDataType: "number",
		},
		{
			field: "rira",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_rira"
					)[0].lanName
					: "", // RIRA
			minWidth: 120,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0,
				precision: 0,
			},
			editable: true,
			cellDataType: "number",
		},
		{
			field: "wca",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_wca"
					)[0].lanName
					: "", // WCA
			minWidth: 120,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0,
				precision: 0,
			},
			editable: true,
			cellDataType: "number",
		},
		{
			field: "whra",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_whra"
					)[0].lanName
					: "", // WHRA
			minWidth: 120,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0,
				precision: 0,
			},
			editable: true,
			cellDataType: "number",
		},
		// {
		// 	field: "itemVatYn",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "bm10020_col_item_vat_yn"
		// 			)[0].lanName
		// 			: "", // 부가세포함여부
		// 	minWidth: 160,
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: props.codeList
		// 			.filter((item) => item.groupCd === "USE_YN")
		// 			.map((item) =>
		// 				props.userLanCode === "en" ? item.ref3 : item.name
		// 			),
		// 	},
		// 	editable: true,
		// },
		{
			field: "tmpYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_tmp_yn"
					)[0].lanName
					: "", // 온도사용유무
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "tmpPointYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_tmp_point_yn"
					)[0].lanName
					: "", // 온도소수점 사용유무
			minWidth: 230,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "hmdYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_hmd_yn"
					)[0].lanName
					: "", // 습도사용유무
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "hmdPointYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_hmd_point_yn"
					)[0].lanName
					: "", // 습도소수점 사용유무
			minWidth: 230,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "illYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_ill_yn"
					)[0].lanName
					: "", // 조도사용유무
			minWidth: 150,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "co2Yn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_co2_yn"
					)[0].lanName
					: "", // CO2사용유무
			minWidth: 150,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "itemQcInspYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_qc_inspect_yn"
					)[0].lanName
					: "", // 품질검사유무
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "runTimeCheckYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_runTimeCheck_yn"
					)[0].lanName
					: "", // 누적가동시간알림
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		// {
		// 	field: "itemGroup",
		// 	headerName: "품목그룹",
		// 	minWidth: 160,
		// 	editable: true,
		// },
		{
			field: "conForm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_con_form"
					)[0].lanName
					: "", // 전문형식
			minWidth: 120,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "CON_FORM")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "conType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_con_type"
					)[0].lanName
					: "", // 전문타입
			minWidth: 120,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "CON_TYPE")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		// {
		// 	field: "itemQty",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "bm10020_col_qty"
		// 			)[0].lanName
		// 			: "", // 수량
		// 	minWidth: 120,
		// 	cellEditor: "agNumberCellEditor",
		// 	cellEditorParams: {
		// 		min: 0,
		// 		precision: 0,
		// 	},
		// 	cellDataType: "number",
		// 	editable: true,
		// },
		// {
		// 	field: "itemStdAmt",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "bm10020_col_std_amt"
		// 			)[0].lanName
		// 			: "", // 기준단가
		// 	minWidth: 120,
		// 	cellEditor: "agNumberCellEditor",
		// 	cellEditorParams: {
		// 		min: 0,
		// 		precision: 0,
		// 	},
		// 	cellDataType: "number",
		// 	editable: true,
		// },
		// {
		// 	field: "itemSvsAmt",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "bm10020_col_svc_amt"
		// 			)[0].lanName
		// 			: "", // 서비스단가
		// 	minWidth: 120,
		// 	cellEditor: "agNumberCellEditor",
		// 	cellEditorParams: {
		// 		min: 0,
		// 		precision: 0,
		// 	},
		// 	cellDataType: "number",
		// 	editable: true,
		// },
		// {
		// 	field: "discontinuDt",
		// 	headerName: "단종일자",
		// 	minWidth: 120,
		// 	editable: true,
		// },
		// {
		// 	field: "disontinuId",
		// 	headerName: "단종처리자",
		// 	minWidth: 120,
		// 	editable: true,
		// },
		{
			field: "tmpTrmVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_tmp_trm_val"
					)[0].lanName
					: "", // 온도떨림값
			minWidth: 160,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0,
				precision: 0,
			},
			cellDataType: "number",
			editable: true,
		},
		{
			field: "hmdTrmVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_hmd_trm_val"
					)[0].lanName
					: "", // 습도떨림값
			minWidth: 160,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0,
				precision: 0,
			},
			cellDataType: "number",
			editable: true,
		},
		{
			field: "co2TrmVal",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_co2_trm_val"
					)[0].lanName
					: "", // CO2떨림값
			minWidth: 160,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0,
				precision: 0,
			},
			cellDataType: "number",
			editable: true,
		},
		{
			field: "remarks",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_remark"
					)[0].lanName
					: "", // 비고
			minWidth: 200,
			editable: true,
		},
		// {
		//   field: "createId",
		//   headerName: "최종등록자",
		//   minWidth: 180,
		//   editable: false,
		// },
		// {
		//   field: "createDate",
		//   headerName: "최초등록일",
		//   minWidth: 160,
		//   editable: false,
		// },
		{
			field: "updateId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_update_id"
					)[0].lanName
					: "", // 수정자
			minWidth: 180,
			editable: false,
		},
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_update_date"
					)[0].lanName
					: "", // 수정일
			minWidth: 170,
			editable: false,
		},
	];
	const ColumnDefs1 = [
		// {
		// 	field: "itemIdx",
		// 	headerName: "품목인덱스",
		// 	minWidth: 170,
		// 	editable: true,
		// },
		{
			field: "mapAdd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_map_address"
					)[0].lanName
					: "", // MAP 주소
			minWidth: 200,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "mapType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_map_type"
					)[0].lanName
					: "", // MAP 타입
			minWidth: 170,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "MAP_TYPE")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "mapNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_map_type_nm"
					)[0].lanName
					: "", // MAP 타입 명
			minWidth: 250,
			editable: true,
		},
		{
			field: "mapNmSh",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_map_type_short_nm"
					)[0].lanName
					: "", // MAP 타입 약어
			minWidth: 250,
			editable: true,
		},
		{
			field: "mapNmShEng",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId === "bm10020_col_map_type_short_nm_eng"
					)[0].lanName
					: "", // MAP 타입 약어
			minWidth: 400,
			editable: true,
		},
		// {
		// 	field: "value",
		// 	headerName: "값",
		// 	minWidth: 160,
		// 	editable: true,
		// },
		{
			field: "inputType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_input_type"
					)[0].lanName
					: "", // 입력 타입
			minWidth: 170,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "INPUT_TYPE")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "mapComment",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_comment"
					)[0].lanName
					: "", // Comment
			minWidth: 250,
			editable: true,
		},
		{
			field: "mapCommentEng",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_comment_eng"
					)[0].lanName
					: "", // English Comment
			minWidth: 250,
			editable: true,
		},
		{
			field: "mapMin",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_minimum_value"
					)[0].lanName
					: "", // 최소값
			minWidth: 160,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				precision: 1, // 소수점 이후 허용되는 자릿수
			},
			cellDataType: "number",
			editable: true,
		},
		{
			field: "mapMin1",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_minimum_value_sub"
					)[0].lanName
					: "", // 보조 최소값
			minWidth: 160,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				precision: 1, // 소수점 이후 허용되는 자릿수
			},
			cellDataType: "number",
			editable: true,
		},
		{
			field: "mapMax",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_maximum_value"
					)[0].lanName
					: "", // 최대값
			minWidth: 170,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				precision: 1, // 소수점 이후 허용되는 자릿수
			},
			cellDataType: "number",
			editable: true,
		},
		{
			field: "mapMax1",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_maximum_value_sub"
					)[0].lanName
					: "", // 보조 최대값
			minWidth: 160,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				precision: 1, // 소수점 이후 허용되는 자릿수
			},
			cellDataType: "number",
			editable: true,
		},
		{
			field: "copyType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_copy_yn"
					)[0].lanName
					: "", // 복사 여무
			minWidth: 160,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "pointChg",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_decimal_point"
					)[0].lanName
					: "", // 소수점 변환
			minWidth: 170,
			cellEditor: "agNumberCellEditor",
			cellEditorParams: {
				min: 0, // 숫자 최솟값
				precision: 0, // 소수점 이후 허용되는 자릿수
			},
			cellDataType: "number",
			editable: true,
		},
		{
			field: "mapUnit",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_unit"
					)[0].lanName
					: "", // 단위
			minWidth: 160,
			editable: true,
		},
		{
			field: "arDataType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_alarm_data_type"
					)[0].lanName
					: "", // 알람 데이터 타입
			minWidth: 170,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "AR_DATA_TYPE")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "arComment",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_alarm_comment"
					)[0].lanName
					: "", // 알람 코멘트
			minWidth: 200,
			editable: true,
		},
		{
			field: "mapAddImgCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_image_code"
					)[0].lanName
					: "", // 이미지 코드
			minWidth: 200,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "MAP_IMAGE_CODE")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		{
			field: "moDpYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_dashboard_yn"
					)[0].lanName
					: "", // 대시보드 표시 유무
			minWidth: 200,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) =>
						props.userLanCode === "en" ? item.ref3 : item.name
					),
			},
			editable: true,
		},
		// {
		// 	field: "useYn",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "bm10020_col_use_yn"
		// 			)[0].lanName
		// 			: "", // 사용 여부
		// 	minWidth: 120,
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: props.codeList
		// 			.filter((item) => item.groupCd === "USE_YN")
		// 			.map((item) =>
		// 				props.userLanCode === "en" ? item.ref3 : item.name
		// 			),
		// 	},
		// 	editable: true,
		// },
		// {
		// 	field: "remark",
		// 	headerName: "비고",
		// 	minWidth: 160,
		// 	editable: true,
		// },
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_update_date"
					)[0].lanName
					: "", // 수정일
			minWidth: 180,
			editable: false,
		},
	];
	const ColumnDefs2 = [
		// {
		// 	field: "itemIdx",
		// 	headerName: "품목인덱스",
		// 	minWidth: 170,
		// 	editable: true,
		// },
		{
			field: "mapAdd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_map_address"
					)[0].lanName
					: "", // 레지스터 주소
			minWidth: 200,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "val",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_value"
					)[0].lanName
					: "", // 값
			minWidth: 170,
			editable: false,
		},
		{
			field: "valDesc",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_value_comment"
					)[0].lanName
					: "", // 값 설명
			minWidth: 160,
			editable: true,
		},
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) => f.lanId === "bm10020_col_update_date"
					)[0].lanName
					: "", // 수정일
			minWidth: 160,
			editable: false,
		},
	];
	const [checkingToggle, setCheckingToggle] = React.useState({
		open: false,
		location: "",
	});
	const [confirmToggle, setConfirmToggle] = React.useState({
		open: false,
		title: "",
		message: "",
		type: "",
	});
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});

	return (
		<>
			<Splitter>
				<SplitterPanel size={30}>
					<Card
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							m: 0.3,
							p: 0.5,
						}}
					>
						<AuthGroupButton
							pageName="BM10020"
							target="G01"
							addFunc={() => {
								// 전체 장치 등록 대수가 200개를 넘으면 안 됨.
								// 조치 관리대장 343, 345 참조
								if (props.rowData.length >= 200) {
									props.setSnackDialog({
										...props.snackDialog,
										open: true,
										title: props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_error_title"
											)[0].lanName
											: "", // 에러
										message: props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_not_add_device"
											)[0].lanName
											: "", // 200개 이상 장치를 추가할 수 없습니다.
									});
								} else {
									setCheckingToggle({
										...checkingToggle,
										open: true,
										location: "grid",
									});
								}
							}}
							saveFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10020_pop_save_title"
											)[0].lanName
											: "", // 저장
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10020_pop_save_message"
											)[0].lanName
											: "", // 저장하시겠습니까?
									type: "save",
								});
							}}
							refreshNone="none"
							deleteFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10020_pop_delete_title"
											)[0].lanName
											: "", // 삭제
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10020_pop_delete_message"
											)[0].lanName
											: "", // 삭제하시겠습니까?
									type: "delete",
								});
							}}
						/>
					</Card>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.65
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animationRows={true}
								columnDefs={ColumnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: true,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="single"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() => {
									GridFunctions.onSelectionChanged(
										gridRef,
										(_selected) => {
											props.setSelectedRowData(_selected);
											props.setSelectedRowData1([]);
											props.GetDtlList(_selected);
											props.setRowData1([]);
											props.setRowData2([]);
										}
									);
								}}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen());
									GridFunctions.onCellValueChanged(
										e,
										gridRef,
										props.setChangedRowData
									);
								}}
								getRowStyle={(e) =>
									GridFunctions.getRowStyle(e)
								}
							/>
						</div>
					</Card>
				</SplitterPanel>
				<SplitterPanel size={70}>
					<Splitter layout="vertical">
						<SplitterPanel size={60}>
							<Card style={{ height: "100%" }}>
								<Card
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										m: 0.3,
										p: 0.5,
									}}
								>
									<AuthGroupButton
										pageName="BM10020"
										target="G02"
										addFunc={async () => {
											if (
												props.selectedRowData.length ===
												0
											) {
												setSnacks({
													open: true,
													type: "error",
													message:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"snack_select_controller_address"
															)[0].lanName
															: "", // 컨트롤러 주소를 선택해 주세요.
												});
												return false;
											} else if (
												!props.selectedRowData[0]
													.updateId
											) {
												return false;
											} else {
												props.setCheckings1({
													...props.checkings1,
													itemIdx:
														props.selectedRowData[0]
															.itemIdx,
												});

												return setCheckingToggle({
													...checkingToggle,
													open: true,
													location: "grid1",
												});
											}
										}}
										saveFunc={() => {
											if (
												!props.selectedRowData[0] ||
												!props.selectedRowData[0]
													.updateId
											) {
												return false;
											} else {
												setConfirmToggle({
													...confirmToggle,
													open: true,
													title:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"bm10020_pop_save_title"
															)[0].lanName
															: "", // 저장
													message:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"bm10020_pop_save_message"
															)[0].lanName
															: "", // 저장하시겠습니까?
													type: "save1",
												});
											}
										}}
										refreshNone="none"
										deleteFunc={() => {
											if (
												!props.selectedRowData[0] ||
												!props.selectedRowData[0]
													.updateId
											) {
												return false;
											} else {
												setConfirmToggle({
													...confirmToggle,
													open: true,
													title:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"bm10020_pop_delete"
															)[0].lanName
															: "", // 삭제
													message:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"bm10020_pop_delete_message"
															)[0].lanName
															: "", // 삭제하시겠습니까?
													type: "delete1",
												});
											}
										}}
										excelFunc={() => {
											if (
												props.selectedRowData.length ===
												0
											) {
												setSnacks({
													open: true,
													type: "error",
													message:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"snack_select_controller_address"
															)[0].lanName
															: "", // 컨트롤러 주소를 선택해 주세요.
												});
												return false;
											} else if (
												!props.selectedRowData[0]
													.updateId
											) {
												return false;
											} else {
												setMapToggle(true);
											}
										}}
									/>
								</Card>
								<Card style={{ height: "85%" }}>
									<div
										className="ag-theme-alpine-dark"
										style={{
											width: "100%",
											// height:
											// 	Commons.Dimentions().height < 800
											// 		? Commons.Dimentions().height * 0.58 * 0.5
											// 		: Commons.Dimentions().height * 0.74 * 0.5,
											// height: Commons.Dimentions().height < 800 ? "50%" : "60%",
											height: "100%",
										}}
									>
										<AgGridReact
											ref={gridRef1}
											animationRows={true}
											columnDefs={ColumnDefs1} // 컬럼 헤더
											defaultColDef={{
												flex: 1,
												minWidth: 100,
												editable: true,
												resizable: true,
												sortable: true,
												filter: true,
												floatingFilter: true,
											}}
											rowData={props.rowData1}
											rowSelection="multiple"
											singleClickEdit={
												Commons.IsMobile()
													? true
													: false
											}
											onSelectionChanged={() =>
												GridFunctions.onSelectionChanged(
													gridRef1,
													(_selected) => {
														props.setSelectedRowData1(
															_selected
														);
														props.GetValList(
															_selected
														);
													}
												)
											}
											onCellValueChanged={(e) => {
												dispatch(
													DrawerActions.setNotOpen()
												);
												GridFunctions.onCellValueChanged(
													e,
													gridRef1,
													props.setChangedRowData1
												);
											}}
											getRowStyle={(e) => {
												if (
													e.data.useYn === "미사용" &&
													e.data.newYn !== "Y"
												) {
													return { color: "#5f5f5f" };
												} else {
													return GridFunctions.getRowStyle(
														e
													);
												}
											}}
										/>
									</div>
								</Card>
							</Card>
						</SplitterPanel>
						<SplitterPanel size={40}>
							<Card style={{ height: "100%" }}>
								<Card
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										m: 0.3,
										p: 0.5,
									}}
								>
									<AuthGroupButton
										pageName="BM10020"
										target="G03"
										addFunc={async () => {
											if (
												props.selectedRowData1
													.length === 0
											) {
												setSnacks({
													open: true,
													type: "error",
													message:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"snack_select_controller_address"
															)[0].lanName
															: "", // 컨트롤러 주소를 선택해 주세요.
												});
												return false;
											} else if (
												!props.selectedRowData1[0]
													.updateDate
											) {
												return false;
											} else {
												props.setCheckings2({
													...props.checkings2,
													itemIdx:
														props
															.selectedRowData1[0]
															.itemIdx,
													mapAdd: props
														.selectedRowData1[0]
														.mapAdd,
												});

												return setCheckingToggle({
													...checkingToggle,
													open: true,
													location: "grid2",
												});
											}
										}}
										saveFunc={() => {
											if (
												!props.selectedRowData1[0] ||
												!props.selectedRowData1[0]
													.updateDate
											) {
												return false;
											} else {
												setConfirmToggle({
													...confirmToggle,
													open: true,
													title:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"bm10020_pop_save_title"
															)[0].lanName
															: "", // 저장
													message:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"bm10020_pop_save_message"
															)[0].lanName
															: "", // 저장하시겠습니까?
													type: "save2",
												});
											}
										}}
										refreshNone="none"
										deleteFunc={() => {
											if (
												!props.selectedRowData1[0] ||
												!props.selectedRowData1[0]
													.updateDate
											) {
												return false;
											} else {
												setConfirmToggle({
													...confirmToggle,
													open: true,
													title:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"bm10020_pop_delete_title"
															)[0].lanName
															: "", // 삭제
													message:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"bm10020_pop_delete_message"
															)[0].lanName
															: "", // 삭제하시겠습니까?
													type: "delete2",
												});
											}
										}}
										excelFunc={() => {
											// console.log("props.selectedRowData1 : ", props.selectedRowData1);
											if (
												props.selectedRowData1
													.length === 0
											) {
												setSnacks({
													open: true,
													type: "error",
													message:
														props.lanList.length > 0
															? props.lanList.filter(
																(f) =>
																	f.lanId ===
																	"snack_select_controller_address"
															)[0].lanName
															: "", // 컨트롤러 주소를 선택해 주세요.
												});
												return false;
											} else if (
												!props.selectedRowData1[0]
													.updateDate
											) {
												return false;
											} else {
												setValToggle(true);
											}
										}}
									/>
								</Card>
								<Card style={{ height: "100%" }}>
									<div
										className="ag-theme-alpine-dark"
										style={{
											width: "100%",
											// height:
											// 	Commons.Dimentions().height < 800
											// 		? Commons.Dimentions().height * 0.58 * 0.5 * 0.875
											// 		: Commons.Dimentions().height * 0.74 * 0.5 * 0.875,
											// height: Commons.Dimentions().height < 800 ? "50%" : "40%",
											height: "100%",
										}}
									>
										<AgGridReact
											ref={gridRef2}
											animationRows={true}
											columnDefs={ColumnDefs2} // 컬럼 헤더
											defaultColDef={{
												flex: 1,
												minWidth: 100,
												editable: true,
												resizable: true,
												sortable: true,
												filter: true,
												floatingFilter: true,
											}}
											rowData={props.rowData2}
											rowSelection="multiple"
											singleClickEdit={
												Commons.IsMobile()
													? true
													: false
											}
											onSelectionChanged={() =>
												GridFunctions.onSelectionChanged(
													gridRef2,
													props.setSelectedRowData2
												)
											}
											onCellValueChanged={(e) => {
												dispatch(
													DrawerActions.setNotOpen()
												);
												GridFunctions.onCellValueChanged(
													e,
													gridRef2,
													props.setChangedRowData2
												);
											}}
											getRowStyle={(e) =>
												GridFunctions.getRowStyle(e)
											}
										/>
									</div>
								</Card>
							</Card>
						</SplitterPanel>
					</Splitter>
				</SplitterPanel>
			</Splitter>
			<Dialog
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_key_check"
						)[0].lanName
						: "" // 키 체크
				}
				open={checkingToggle.open}
				onClose={() => {
					if (checkingToggle.location === "grid") {
						props.setCheckings({
							...props.checkings,
							itemCd: "",
						});
					} else if (checkingToggle.location === "grid1") {
						props.setCheckings1({
							...props.checkings1,
							mapAdd: "",
						});
					} else if (checkingToggle.location === "grid2") {
						props.setCheckings2({
							...props.checkings2,
							val: "",
						});
					}
					setCheckingToggle({
						...checkingToggle,
						open: false,
						location: "",
					});
				}}
				bodySx={{ display: "flex", flexDirection: "column" }}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_check"
						)[0].lanName
						: "" // 체크
				}
				onSave={() => {
					switch (checkingToggle.location) {
						case "grid": {
							props.CheckingLogic(gridRef);
							break;
						}
						case "grid1": {
							if (props.checkings1.mapAdd.length !== 5) {
								setSnacks({
									open: true,
									type: "error",
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_enter_10000_unit"
											)[0].lanName
											: "", // 10000 단위의 주소를 입력해 주세요
								});
							} else if (
								(props.checkings1.mapAdd.slice(-2) * 1) %
								((props.checkings1.mapAdd.substring(0, 1) +
									"0000") *
									1) >
								32
							) {
								setSnacks({
									open: true,
									type: "error",
									message:
										props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"snack_enter_32_sequentially"
											)[0].lanName
											: "", // 주소는 순차적으로 32까지 입력이 가능합니다.
								});
							} else {
								props.CheckingLogic1(gridRef1);
							}
							break;
						}
						case "grid2": {
							props.CheckingLogic2(gridRef2);
							break;
						}
					}
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				{checkingToggle.location === "grid" ? (
					<>
						<Input
							sx={{ my: 1 }}
							type="outlined"
							value={props.checkings.itemCd}
							onChange={(e) => {
								props.setCheckings({
									...props.checkings,
									itemCd: e,
								});
							}}
							name={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10020_pop_placeholder_item_code"
									)[0].lanName
									: "" // 품목코드
							}
						/>
					</>
				) : checkingToggle.location === "grid1" ? (
					<>
						<Input
							sx={{ my: 1 }}
							type="outlined"
							value={props.checkings1.mapAdd}
							onChange={(e) => {
								if (e.indexOf("-") > 0 || e.indexOf(".") > 0) {
									return null;
								} else if (e === "") {
									props.setCheckings1({
										...props.checkings1,
										mapAdd: e,
									});
								} else if (Number(e) >= 0) {
									props.setCheckings1({
										...props.checkings1,
										mapAdd: e + "",
									});
								}
							}}
							name={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10020_pop_placeholder_map_address"
									)[0].lanName
									: "" // 주소
							}
						/>
					</>
				) : (
					<>
						<Input
							sx={{ my: 1 }}
							type="outlined"
							value={props.checkings2.val}
							onChange={(e) => {
								if (e.indexOf("-") > 0 || e.indexOf(".") > 0) {
									return null;
								} else if (e === "") {
									props.setCheckings2({
										...props.checkings2,
										val: e,
									});
								} else if (
									Number(e) >= 0 &&
									Number(e) < 10 &&
									e.length < 2
								) {
									props.setCheckings2({
										...props.checkings2,
										val: e + "",
									});
								}
							}}
							name={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10020_pop_placeholder_value"
									)[0].lanName
									: "" // 값
							}
						/>
					</>
				)}
			</Dialog>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
				onSave={() => {
					if (confirmToggle.type === "save") {
						props.CreateLogic();
					} else if (confirmToggle.type === "save1") {
						props.CreateLogic1();
					} else if (confirmToggle.type === "save2") {
						props.CreateLogic2();
					} else if (confirmToggle.type === "delete") {
						props.DeleteLogic();
					} else if (confirmToggle.type === "delete1") {
						props.DeleteLogic1();
					} else if (confirmToggle.type === "delete2") {
						props.DeleteLogic2();
					}
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				{confirmToggle.message}
			</Dialog>
			<Dialog
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_excel_upload"
						)[0].lanName
						: "" // 엑셀 업로드
				}
				open={mapToggle}
				onClose={() => {
					setMapToggle(false);
					props.setInputs({
						...props.inputs,
						mapFiles: "",
						mapFileName: "",
						valFiles: "",
						valFileName: "",
					});
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_upload"
						)[0].lanName
						: "" // 업로드
				}
				onSave={() => {
					props.MapUpload();
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				<FileForm
					datas={[
						{
							type: "excel",
							name: "", //파일
							value: props.inputs.mapFile,
							onChange: (e) => {
								let file = e.target.files[0];
								if (e.target.files.length === 0) {
									return;
								} else {
									let reader = new FileReader();
									reader.readAsDataURL(file);
									reader.onload = (f) => {
										if (reader) {
											props.setInputs({
												...props.inputs,
												mapFiles: file,
												mapFileName: file.name,
											});
										}
									};
								}
							},
							buttonName:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10020_pop_file_upload"
									)[0].lanName
									: "", // 파일 업로드
						},
						{
							name:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10020_pop_placeholder_file_nm"
									)[0].lanName
									: "", // 파일명
							value: props.inputs.mapFileName,
							disabled: true,
						},
					]}
				/>
			</Dialog>
			<Dialog
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_excel_upload"
						)[0].lanName
						: "" // 엑셀 업로드
				}
				open={valToggle}
				onClose={() => {
					setValToggle(false);
					props.setInputs({
						...props.inputs,
						mapFiles: "",
						mapFileName: "",
						valFiles: "",
						valFileName: "",
					});
				}}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_upload"
						)[0].lanName
						: "" // 업로드
				}
				onSave={() => {
					props.ValUpload();
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "bm10020_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			>
				<FileForm
					datas={[
						{
							type: "excel",
							name: "", //파일
							value: props.inputs.valFile,
							onChange: (e) => {
								let file = e.target.files[0];
								if (e.target.files.length === 0) {
									return;
								} else {
									let reader = new FileReader();
									reader.readAsDataURL(file);
									reader.onload = (f) => {
										if (reader) {
											props.setInputs({
												...props.inputs,
												valFiles: file,
												valFileName: file.name,
											});
										}
									};
								}
							},
							buttonName:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10020_pop_file_upload"
									)[0].lanName
									: "", // 파일 업로드
						},
						{
							name:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"bm10020_pop_placeholder_file_nm"
									)[0].lanName
									: "", // 파일명
							value: props.inputs.valFileName,
							disabled: true,
						},
					]}
				/>
			</Dialog>
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
		</>
	);
}

export default BM10020;
