import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components Import */
import LM10040View from "../../Views/LogManagements/LM10040";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";

function LM10040() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [codeList, setCodeList] = React.useState([]); // 기초코드
	const [rowData, setRowData] = React.useState([]);
	const [fromDate, setFromDate] = React.useState(new Date());
	const [toDate, setToDate] = React.useState(new Date());
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		BetweenDate();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetCodeAll().then((result) => {
			setCodeList(result);
		});
	}, []);

	const BetweenDate = async () => {
		setProgress(true);
		let tempArr = [];
		let userInfo = null;
		let tempCode = [];
		let tempBkType2 = [];
		let tempBkType1 = [];
		let tempBkType3 = [];
		let tempArrList = [];
		let lans = [];
		let search = true;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					setLanList(result);
					result.forEach((item) => lans.push(item));
				}
			});

			await IndexedDb.GetCodeAll().then((result) => {
				tempCode = result;
			});


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "LM10040").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10040.GetBetweenRegister,
				{
					userId: userInfo.userId,
					fromDate: Commons.DateFormating(fromDate, 6),
					toDate: Commons.DateFormating(toDate, 6),
				}
			).then((result) => {
				let data = result.data;
				// // console.log("Data : ", data);

				if (data.type === "Error") {
					throw new Error(result.data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					// throw new Error(
					// 	(lans.length > 0
					// 		? lans.filter(
					// 			(f) => f.lanId === "snack_unavailable_data"
					// 		)[0].lanName
					// 		: "")
					// );
				} else if (data) {
					// setRowData(data);
					data.reverse().forEach((item, index) => {
						// // console.log("item : ", item);
						if (item.BkType === "2") {
							tempBkType2.push({
								...item,
								AftValue: item.ValDesc ? item.ValDesc : item.AftValue.toString(),
								ShowMapNm: userInfo.lanCode === "kr" ? item.MapNmSh : item.MapNmShEng,
								BkDate:
									item.BkDate.slice(0, 4) +
									"-" +
									item.BkDate.slice(4, 6) +
									"-" +
									item.BkDate.slice(6, 8) +
									" ",
								AppDate:
									item.AppDate.slice(0, 4) +
									"-" +
									item.AppDate.slice(4, 6) +
									"-" +
									item.AppDate.slice(6, 8) +
									" " +
									item.AppDate.slice(8, 10) +
									":" +
									item.AppDate.slice(10, 12) +
									":" +
									item.AppDate.slice(12, 14),
								BkType: tempCode.filter(
									(_item) =>
										_item.groupCd === "BK_TYPE" &&
										_item.code === item.BkType
								)[0].name,
								UpdateDate:
									item.UpdateDate.slice(0, 4) +
									"-" +
									item.UpdateDate.slice(4, 6) +
									"-" +
									item.UpdateDate.slice(6, 8) +
									" " +
									item.UpdateDate.slice(8, 10) +
									":" +
									item.UpdateDate.slice(10, 12) +
									":" +
									item.UpdateDate.slice(12, 14),
								CreateDate:
									item.CreateDate.slice(0, 4) +
									"-" +
									item.CreateDate.slice(4, 6) +
									"-" +
									item.CreateDate.slice(6, 8) +
									" " +
									item.CreateDate.slice(8, 10) +
									":" +
									item.CreateDate.slice(10, 12) +
									":" +
									item.CreateDate.slice(12, 14),
							});
						} else if (item.BkType === "1") {
							tempBkType1.push({
								...item,
								// num: index + 1,
								AftValue: item.ValDesc ? item.ValDesc : item.AftValue.toString(),
								ShowMapNm: userInfo.lanCode === "kr" ? item.MapNmSh : item.MapNmShEng,
								BkDate:
									item.BkDate.slice(0, 4) +
									"-" +
									item.BkDate.slice(4, 6) +
									"-" +
									item.BkDate.slice(6, 8) +
									" ",
								AppDate:
									item.AppDate.slice(0, 4) +
									"-" +
									item.AppDate.slice(4, 6) +
									"-" +
									item.AppDate.slice(6, 8) +
									" " +
									item.AppDate.slice(8, 10) +
									":" +
									item.AppDate.slice(10, 12) +
									":" +
									item.AppDate.slice(12, 14),
								BkType: tempCode.filter(
									(_item) =>
										_item.groupCd === "BK_TYPE" &&
										_item.code === item.BkType
								)[0].name,
								UpdateDate:
									item.UpdateDate.slice(0, 4) +
									"-" +
									item.UpdateDate.slice(4, 6) +
									"-" +
									item.UpdateDate.slice(6, 8) +
									" " +
									item.UpdateDate.slice(8, 10) +
									":" +
									item.UpdateDate.slice(10, 12) +
									":" +
									item.UpdateDate.slice(12, 14),
								CreateDate:
									item.CreateDate.slice(0, 4) +
									"-" +
									item.CreateDate.slice(4, 6) +
									"-" +
									item.CreateDate.slice(6, 8) +
									" " +
									item.CreateDate.slice(8, 10) +
									":" +
									item.CreateDate.slice(10, 12) +
									":" +
									item.CreateDate.slice(12, 14),
							});
						} else if (item.BkType === "3") {
							tempBkType3.push({
								...item,
								// num: index + 1,
								AftValue: item.ValDesc ? item.ValDesc : item.AftValue.toString(),
								ShowMapNm: userInfo.lanCode === "kr" ? item.MapNmSh : item.MapNmShEng,
								BkDate:
									item.BkDate.slice(0, 4) +
									"-" +
									item.BkDate.slice(4, 6) +
									"-" +
									item.BkDate.slice(6, 8) +
									" ",
								AppDate:
									item.AppDate.slice(0, 4) +
									"-" +
									item.AppDate.slice(4, 6) +
									"-" +
									item.AppDate.slice(6, 8) +
									" " +
									item.AppDate.slice(8, 10) +
									":" +
									item.AppDate.slice(10, 12) +
									":" +
									item.AppDate.slice(12, 14),
								BkType: tempCode.filter(
									(_item) =>
										_item.groupCd === "BK_TYPE" &&
										_item.code === item.BkType
								)[0].name,
								UpdateDate:
									item.UpdateDate.slice(0, 4) +
									"-" +
									item.UpdateDate.slice(4, 6) +
									"-" +
									item.UpdateDate.slice(6, 8) +
									" " +
									item.UpdateDate.slice(8, 10) +
									":" +
									item.UpdateDate.slice(10, 12) +
									":" +
									item.UpdateDate.slice(12, 14),
								CreateDate:
									item.CreateDate.slice(0, 4) +
									"-" +
									item.CreateDate.slice(4, 6) +
									"-" +
									item.CreateDate.slice(6, 8) +
									" " +
									item.CreateDate.slice(8, 10) +
									":" +
									item.CreateDate.slice(10, 12) +
									":" +
									item.CreateDate.slice(12, 14),
							});
						}
					});

					// // console.log("data[0].BkType * -1 : ", -0 + 0);
					// // console.log(data.sort((a, b) => ((a.BkType * -1) % 2) - ((b.BkType * -1) % 2)));

					tempArr.push(
						...tempBkType2,
						...tempBkType1,
						...tempBkType3
					);
					// // console.log("tempArr : ", tempArr);

					tempArr.forEach((item, index) => {
						tempArrList.push({
							...item,
							num: index + 1,
						});
					});
					// // console.log("tempArrList : ", tempArrList);

					setRowData(tempArrList);
				} else {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_view_failed"
							)[0].lanName
							: "") // 조회 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""}
								{/* 로그인 정보가 만료되었습니다. */}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_login_again"
									)[0].lanName
									: ""}
								{/* 다시 로그인 해 주세요. */}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	return (
		<div>
			<LM10040View
				setSnacks={setSnacks}
				rowData={rowData}
				setRowData={setRowData}
				fromDate={fromDate}
				setFromDate={setFromDate}
				toDate={toDate}
				setToDate={setToDate}
				lanList={lanList}
				// function
				BetweenDate={BetweenDate}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					(lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "") // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default LM10040;
