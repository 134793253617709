import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components Import */
import LM10060View from "../../Views/LogManagements/LM10060";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";

function LM10060() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [rowData, setRowData] = React.useState([]);		// 전송 로그
	const [rowData1, setRowData1] = React.useState([]);		// 전송 에러 로그
	const [rowData2, setRowData2] = React.useState([]);		// 카카오 로그
	const [fromDate, setFromDate] = React.useState(new Date());
	const [toDate, setToDate] = React.useState(new Date());
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		BetweenDate();
	}, []);

	const BetweenDate = async () => {
		setProgress(true);
		let userInfo = null;
		let codes = [];
		let pushlists = [];
		let pushErrlists = [];
		let kakaolists = [];
		let lans = [];
		let search = true;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					setLanList(result);
					result.forEach((item) => lans.push(item));
				}
			});

			await IndexedDb.GetCodeAll().then((result) => {
				codes = result;
			});


			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "LM10060").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.LogManagement.LM10060.Get,
				{
					userId: userInfo.userId,
					fromDate: Commons.DateFormating(fromDate, 6),
					toDate: Commons.DateFormating(toDate, 6),
				}
			).then((result) => {
				let data = result.data;
				// // console.log("data : ", data);

				if (data.type === "Error") {
					setRowData([]);
					throw new Error(result.data.message);
				} else {
					[...data.appPushLists].reverse().forEach((item, index) => {
						pushlists.push({
							num: index + 1,
							TrdDate: Commons.DateFormating(
								Commons.StringtoDate(item.TrdDate),
								2
							),
							UserId: item.UserId,
							SndDate: Commons.DateFormating(
								Commons.StringtoDate(item.SndDate, 3)
							),
							SndType: codes.filter(
								(f) =>
									f.groupCd === "SND_TYPE" &&
									f.code === item.SndType
							)[0].name,
							PsTitle: item.PsTitle,
							ItemNm: item.PsComment.split(", ")[0],
							PsComment: item.PsComment.split(", ")[1],
						});
					});
					[...data.appPushErrLists].reverse().forEach((item, index) => {
						pushErrlists.push({
							num: index + 1,
							TrdDate: Commons.DateFormating(
								Commons.StringtoDate(item.TrdDate),
								2
							),
							UserId: item.UserId,
							SndDate: Commons.DateFormating(
								Commons.StringtoDate(item.SndDate, 3)
							),
							SndType: codes.filter(
								(f) =>
									f.groupCd === "SND_TYPE" &&
									f.code === item.SndType
							)[0].name,
							PsTitle: item.PsTitle,
							ItemNm: item.PsComment.split(", ")[0],
							PsComment: item.PsComment.split(", ")[1],
						});
					});
					[...data.kakaoLists].reverse().forEach((item, index) => {
						kakaolists.push({
							num: index + 1,
							TrdDate: Commons.DateFormating(
								Commons.StringtoDate(item.TrdDate),
								2
							),
							UserId: item.UserId,
							CreateDate: item.CreateDate !== null ? Commons.DateFormating(
								Commons.StringtoDate(item.CreateDate, 3)
							) : null,
							SndDate: item.SndDate !== null ? Commons.DateFormating(
								Commons.StringtoDate(item.SndDate, 3)
							) : Commons.DateFormating(
								Commons.StringtoDate(item.CreateDate, 3)
							),
							SndType: codes.filter(
								(f) =>
									f.groupCd === "SND_KAKAO_TYPE" &&
									f.code === item.SndType
							)[0].name,
							ErrMsg: item.ErrMsg,
							PsTitle: item.PsTitle,
							ItemNm: item.PsComment.split(", ")[0],
							PsComment: item.PsComment.split(", ")[1],
						});
					});

					setRowData(pushlists);
					setRowData1(pushErrlists);
					setRowData2(kakaolists);
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""}
								{/* 로그인 정보가 만료되었습니다. */}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId === "snack_login_again"
									)[0].lanName
									: ""}
								{/* 다시 로그인 해 주세요. */}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	return (
		<div>
			<LM10060View
				setSnacks={setSnacks}
				rowData={rowData}
				setRowData={setRowData}
				rowData1={rowData1}
				setRowData1={setRowData1}
				rowData2={rowData2}
				setRowData2={setRowData2}
				fromDate={fromDate}
				setFromDate={setFromDate}
				toDate={toDate}
				setToDate={setToDate}
				lanList={lanList}
				// function
				BetweenDate={BetweenDate}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					(lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_confirm"
						)[0].lanName
						: "") // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default LM10060;
