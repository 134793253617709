import React from "react";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import { Button } from "@mui/material";
import { Input } from "../Inputs";
/** Icons Import */
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

function Checking({
	datas = [],
}) {
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			{datas.map((item, index) => {
				const ReturnInputs = () => {
					switch (item.type) {
						case "file":
							return (
								<div>
									<Button
										variant="contained"
										component="label"
										startIcon={<CloudUploadIcon />}
										color="secondary"
									>
										{item.buttonName ? item.buttonName : "파일 업로드"}
										<VisuallyHiddenInput
											type="file"
											accept="image/*"
											onChange={(e) => item.onChange(e)}
										/>
									</Button>
								</div>
							);
						case "image":
							return (
								<img
									id={item.id}
									src={item.src}
									style={{
										width: "100%",
										height: "100%",
									}}
								/>
							);
						case "excel":
							return (
								<div>
									<Button
										variant="contained"
										component="label"
										startIcon={<CloudUploadIcon />}
										color="secondary"
									>
										{item.buttonName ? item.buttonName : "파일 업로드"}
										<VisuallyHiddenInput
											type="file"
											accept=".xls, .xlsx, .cell"
											onChange={(e) => item.onChange(e)}
										/>
									</Button>
								</div>
							);
						default:
							return (
								<Input
									sx={{
										mx: 2,
									}}
									disabled={item.disabled}
									id={item.id}
									name={item.name}
									value={item.value}
									onChange={(e) => item.onChange(e)}
								/>
							);
					}
				};
				return <div key={index}>{ReturnInputs()}</div>;
			})}
		</div>
	);
}

Checking.defaultProps = {};

export default Checking;
