import React from "react";
import { useDispatch } from "react-redux";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
import { Combo } from "../../Components/Combos";
import { FileForm } from "../../Components/FileForm";
/** Reducers Import */
/** Icons Import */
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";

/** 언어관리 화면 */

function BM10080(props) {
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const gridRef = React.useRef();
	const columnDefs = [
		{
			field: "lanId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_col_representative_name"
					)[0].lanName
					: "", // 대표명칭
			minWidth: 140,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "lanCode",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_col_lan_code"
					)[0].lanName
					: "", // 언어타입
			minWidth: 160,
			editable: false,
		},
		{
			field: "lanName",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_col_lan_name"
					)[0].lanName
					: "", // 명칭
			minWidth: 150,
			editable: true,
		},
		{
			field: "lanType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_col_lan_type"
					)[0].lanName
					: "", // 타입
			minWidth: 230,
			cellEditor: "agRichSelectCellEditor",
			cellEditorParams: {
				values: props.lanTypeList.map((item) => item.name),
			},
			editable: true,
		},
	];
	const [checkingToggle, setCheckingToggle] = React.useState(false);
	const [checkingToggle1, setCheckingToggle1] = React.useState(false);
	const [confirmToggle, setConfirmToggle] = React.useState({
		open: false,
		title: "",
		message: "",
		type: "",
	});

	return (
		<>
			<Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid xs={12} md={12}>
					<Card sx={{ display: "flex", justifyContent: "flex-end" }}>
						<AuthGroupButton
							pageName="BM10080"
							target="G01"
							addFunc={() => {
								setCheckingToggle(true);
							}}
							saveFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10080_pop_save_title"
										)[0].lanName
										: ""),	// 저장
									message: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10080_pop_save_message"
										)[0].lanName
										: ""),	// 저장하시겠습니까?
									type: "create",
								});
							}}
							deleteFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10080_pop_delete_title"
										)[0].lanName
										: ""),	// 삭제
									message: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10080_pop_delete_message"
										)[0].lanName
										: ""),	// 삭제하시겠습니까?
									type: "delete",
								});
							}}
							excelFunc={() => {
								setCheckingToggle1(true);
							}}
						/>
					</Card>
				</Grid>
				<Grid xs={12} md={12}>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animateRows={true}
								columnDefs={columnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: true,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="multiple"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() =>
									GridFunctions.onSelectionChanged(
										gridRef,
										props.setSelectedRowData
									)
								}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen());
									GridFunctions.onCellValueChanged(
										e,
										gridRef,
										props.setChangedRowData
									);
								}}
								getRowStyle={(e) => {
									if (e.data.useYn === "미사용" && e.data.newYn !== "Y") {
										return { color: "#5f5f5f" };
									} else {
										return GridFunctions.getRowStyle(e);
									}
								}}
							/>
						</div>
					</Card>
				</Grid>
			</Grid>
			<Dialog
				title={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_pop_key_check"
					)[0].lanName
					: ""	// 키 체크
				}
				open={checkingToggle}
				onClose={() => {
					props.setAddData({
						...props.addData,
						lanId: "",
						lanCode: "",
					});
					setCheckingToggle(false);
				}}
				bodySx={{ display: "flex", flexDirection: "column" }}
				fullWidth
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_pop_check"
					)[0].lanName
					: ""	// 체크
				}
				onSave={() => {
					props.CheckCodeMst(gridRef);
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
			>
				<>
					<Input
						sx={{ my: 1 }}
						type="outlined"
						name={props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId ===
									"bm10080_pop_representative_name"
							)[0].lanName
							: ""	// 대표명칭
						}
						value={props.addData.lanId}
						onChange={(e) => {
							props.setAddData({
								...props.addData,
								lanId: e,
							});
						}}
					/>
					<Combo
						sx={{ my: 1 }}
						name={props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId ===
									"bm10080_pop_lan_code"
							)[0].lanName
							: ""	// 언어타입
						}
						value={props.addData.lanCode}
						items={props.lanCodeList}
						onChange={(e) => {
							props.setAddData({
								...props.addData,
								lanCode: e,
							});
						}}
					/>
				</>
			</Dialog>
			<Dialog
				title={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_pop_excel_upload"
					)[0].lanName
					: ""	// 엑셀 업로드
				}
				open={checkingToggle1}
				onClose={() => {
					setCheckingToggle1(false);
					props.setInputs({
						files: "",
						fileName: "",
					});
				}}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_pop_upload"
					)[0].lanName
					: ""	// 업로드
				}
				onSave={() => {
					props.ExcelUpload();
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
			>
				<FileForm
					datas={[
						{
							type: "excel",
							name: "", //파일
							value: props.inputs.fileName,
							onChange: (e) => {
								var file = e.target.files[0];
								if (e.target.files.length === 0) {
									return;
								} else {
									let reader = new FileReader();
									reader.readAsDataURL(file);
									reader.onload = (f) => {
										if (reader) {
											props.setInputs({
												...props.inputs,
												files: file,
												fileName: file.name,
											});
										}
									};
								}
							},
							buttonName: (props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"bm10080_pop_file_upload"
								)[0].lanName
								: ""),	// 파일 업로드
						},
						{
							name: (props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"bm10080_pop_file_nm"
								)[0].lanName
								: ""),	// 파일명
							value: props.inputs.fileName,
							disabled: true,
						},
					]}
				/>
			</Dialog>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10080_pop_confirm"
					)[0].lanName
					: ""	// 확인
				}
				onSave={() => {
					if (confirmToggle.type === "create") {
						props.CreateLogic();
					} else if (confirmToggle.type === "delete") {
						props.DeleteLogic();
					}
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
			>
				{confirmToggle.message}
			</Dialog>
		</>
	);
}

export default BM10080;
