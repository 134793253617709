import React from "react";
/** Components Import */
import { Button } from "@mui/material";

function BasicButton({
	type = "contained", // "contained", "outlined", "text"
	style = {}, // react inline-style object
	sx = {}, // mui inline-style object
	color = "secondary", // "first", "secondary", "success", "info", "warning", "error"
	name = "Button",
	size = "medium", // "small", "medium", "large"
	fullWidth = false, // true, false
	onClick = (e) => { }, // button click event function
	startIcon = null, // import {} from "@mui/icons-material"의 icon component
	endIcon = null, // import {} from "@mui/icons-material"의 icon component
	disabled = false, // true, false
}) {
	const ButtonClicked = (e) => {
		onClick(e);
	};

	return (
		<Button
			variant={type}
			style={style}
			sx={sx}
			color={color}
			size={size}
			fullWidth={fullWidth}
			onClick={ButtonClicked}
			startIcon={startIcon}
			endIcon={endIcon}
			disabled={disabled}
		>
			{name}
		</Button>
	);
}

export default BasicButton;
