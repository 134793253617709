import React from "react";
import { useDispatch } from "react-redux";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
/** Ag-grid Import */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";

/** 기초코드관리 화면 */

function BM10040(props) {
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const gridRef = React.useRef();
	const columnDefs = [
		{
			field: "groupCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_group_code"
					)[0].lanName
					: "", // 그룹코드
			minWidth: 300,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "code",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_code"
					)[0].lanName
					: "", // 코드
			minWidth: 120,
			editable: false,
		},
		{
			field: "name",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_code_nm"
					)[0].lanName
					: "", // 코드명
			minWidth: 130,
			editable: true,
		},
		{
			field: "sortSeq",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_sort"
					)[0].lanName
					: "", // 정렬순서
			minWidth: 150,
			editable: true,
		},
		{
			field: "val1",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_val1"
					)[0].lanName
					: "", // 값1
			minWidth: 150,
		},
		{
			field: "val2",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_val2"
					)[0].lanName
					: "", // 값2
			minWidth: 150,
		},
		{
			field: "ref1",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_ref1"
					)[0].lanName
					: "", // 참조1
			minWidth: 150,
		},
		{
			field: "ref2",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_ref2"
					)[0].lanName
					: "", // 참조2
			minWidth: 150,
		},
		{
			field: "ref3",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_ref3_lan"
					)[0].lanName
					: "", // 영문 코드명
			minWidth: 210,
		},
		// {
		// 	field: "createDate",
		// 	headerName: "생성일",
		// 	minWidth: 200,
		// 	editable: false,
		// },
		// {
		// 	field: "createId",
		// 	headerName: "생성자",
		// 	editable: false,
		// },
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_update_date"
					)[0].lanName
					: "", // 수정일
			minWidth: 200,
			editable: false,
		},
		{
			field: "updateId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_col_update_id"
					)[0].lanName
					: "", // 수정자
			editable: false,
		},
	];
	const [checkingToggle, setCheckingToggle] = React.useState(false);
	const [confirmToggle, setConfirmToggle] = React.useState({
		open: false,
		title: "",
		message: "",
		type: "",
	});

	return (
		<>
			<Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
				<Grid xs={12} md={12}>
					<Card sx={{ display: "flex", justifyContent: "flex-end" }}>
						<AuthGroupButton
							pageName="BM10040"
							target="G01"
							addFunc={() => {
								setCheckingToggle(true);
							}}
							saveFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10040_pop_save_title"
										)[0].lanName
										: ""),	// 저장
									message: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10040_pop_save_message"
										)[0].lanName
										: ""),	// 저장하시겠습니까?
									type: "create",
								});
							}}
							deleteFunc={() => {
								setConfirmToggle({
									...confirmToggle,
									open: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10040_pop_delete_title"
										)[0].lanName
										: ""),	// 삭제
									message: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10040_pop_delete_message"
										)[0].lanName
										: ""),	// 삭제하시겠습니까?
									type: "delete",
								});
							}}
						/>
					</Card>
				</Grid>
				<Grid xs={12} md={12}>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animateRows={true}
								columnDefs={columnDefs}
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: true,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="multiple"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() =>
									GridFunctions.onSelectionChanged(
										gridRef,
										props.setSelectedRowData
									)
								}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen()); // 열림 방지
									GridFunctions.onCellValueChanged(
										e,
										gridRef,
										props.setChangedRowData
									);
								}} // 칼럼의 어떤 값이 바뀔때마다 호출되는 함수
								getRowStyle={(e) =>
									GridFunctions.getRowStyle(e)
								}
							/>
						</div>
					</Card>
				</Grid>
			</Grid>
			<Dialog
				title={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_pop_key_check"
					)[0].lanName
					: ""	// 키체크
				}
				open={checkingToggle}
				onClose={() => {
					props.setCheckings({
						...props.checkings,
						groupCode: "",
						code: "",
					});
					setCheckingToggle(false);
				}}
				bodySx={{ display: "flex", flexDirection: "column" }}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_pop_check"
					)[0].lanName
					: ""	// 체크
				}
				onSave={() => props.CheckingLogic(gridRef)}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
			>
				<Input
					sx={{ my: 1 }}
					type="outlined"
					value={props.checkings.groupCode}
					onChange={(e) => {
						props.setCheckings({
							...props.checkings,
							groupCode: e,
						});
					}}
					name={props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId ===
								"bm10040_pop_placeholder_gorup_code"
						)[0].lanName
						: ""	// 그룹코드
					}
				/>
				<Input
					sx={{ my: 1 }}
					type="outlined"
					value={props.checkings.code}
					onChange={(e) => {
						props.setCheckings({
							...props.checkings,
							code: e,
						});
					}}
					name={props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId ===
								"bm10040_pop_placeholder_code"
						)[0].lanName
						: ""	// 코드
					}
				/>
			</Dialog>
			<Dialog
				title={confirmToggle.title}
				open={confirmToggle.open}
				onClose={() => {
					setConfirmToggle({
						...confirmToggle,
						open: false,
						title: "",
						message: "",
					});
				}}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_pop_confirm"
					)[0].lanName
					: ""	// 확인
				}
				onSave={() => {
					if (confirmToggle.type === "create") {
						props.CreateLogic();
					} else if (confirmToggle.type === "delete") {
						props.DeleteLogic();
					}
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10040_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
			>
				{confirmToggle.message}
			</Dialog>
		</>
	);
}

export default BM10040;
