import React from "react";
/** Components Import */
import {
	Autocomplete,
	TextField,
	Checkbox,
} from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
/** Icons Import */
import {
	CheckBoxOutlineBlank as CheckBoxIcon,
	CheckBox as CheckIcon,
	Clear as ClearIcon,
} from "@mui/icons-material";

function AutoCombo({
	options = [],
	getOptionLabel = (e) => { return e.name !== null ? e.name : ""; },
	label = "Checkbox Label",
	renderInput = (params) => { },
	renderOption = (props, option, { selected }) => { },
	isOptionEqualToValue = (option, value) => { },
	defaultValue = [],
	onChange = (e, v) => { },
	size = "small",
	style = {},
	sx = {},
	clearIcon = true,
	autoComplete = false,
	autoHighlight = false,
	autoSelect = false,
	multiple = false,
	disableCloseOnSelect = false,
}) {
	const inputRef = React.useRef();

	const [value, setValue] = React.useState(defaultValue);

	const RenderInput = (params) => {
		return (
			<TextField
				{...params}
				label={label}
				sx={{
					// resize: "none",
					// width: "900px",
					width: "100%",
					maxHeight: 10,
					marginTop: 1,
					maxLines: 1,
				}}
				// size={"medium"}
				multiline={true}
				rows={1}
				inputProps={{
					...params.inputProps,
					inputMode: "none"
				}}
			/>
		);

		// renderInput(params);
	};

	const RenderOption = (props, option, { selected }) => {
		return (
			<li {...props}>
				<Checkbox
					icon={<CheckBoxIcon fontSize="small" />}
					checkedIcon={<CheckIcon fontSize="small" />}
					style={{
						marginRight: 8,
						resize: "none"
					}}
					checked={option.all ? !!(value.length === options.length) : selected}
				/>
				{option.name}
			</li>
		);

		// renderOption(props, option, { selected });
	};

	const IsOptionEqualToValue = (option, value) => option.value === value.value;


	const OnChange = (e, v) => {
		onChange(e, v);
	};

	return (
		<Autocomplete
			options={options}
			value={value}
			filterOptions={(options, params) => { // <<<--- inject the Select All option
				const filter = createFilterOptions();
				const filtered = filter(options, params);
				return [{ name: '모두 선택', all: true }, ...filtered];
			}}
			getOptionLabel={getOptionLabel}
			renderInput={RenderInput}
			renderOption={RenderOption}
			isOptionEqualToValue={IsOptionEqualToValue}
			// defaultValue={initValue}
			onChange={(event, newValue) => {
				if (newValue.find(option => option.all)) {
					OnChange(event, value.length === options.length ? [] : options);
					return setValue(value.length === options.length ? [] : options);
				}

				setValue(newValue);
				OnChange(event, newValue);
			}}
			size={size}
			style={{
				...style,
				resize: "none",
				minWidth: "80%",
				width: "100%",
				// height: "200px"
			}}
			sx={{
				...sx,
				resize: "none",
			}}
			clearIcon={clearIcon && <ClearIcon fontSize="small" />}
			autoComplete={autoComplete}
			autoHighlight={autoHighlight}
			autoSelect={autoSelect}
			multiple={multiple}
			disableCloseOnSelect={disableCloseOnSelect}
		/>
	);
}

export default AutoCombo;