import React from "react";
/** Components Import */
import { FormGroup, FormControlLabel, RadioGroup, Radio } from "@mui/material";

function GroupRadio({
	sx = {}, // mui inline-style object
	row = true, // true, false
	value = "", // group radio value
	onChange = (e) => { }, // radio click event function
	items = [], // radio element object array ex) [{ name: "Button1", value: "" }, {...} ]
}) {
	const RadioChanged = (e) => {
		onChange(e.target.value);
	};

	return (
		<RadioGroup sx={sx} row={row} value={value} onChange={RadioChanged}>
			{items.map((item, index) => {
				return (
					<FormControlLabel
						key={index}
						sx={item.sx}
						control={
							<Radio
								color={item.color ? item.color : "secondary"}
								size={item.size}
								value={item.value}
							/>
						}
						label={item.name}
						required={item.required}
						disabled={item.disabled}
					/>
				);
			})}
		</RadioGroup>
	);
}

export default GroupRadio;
