import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardHeader,
	CardContent,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Avatar,
	IconButton,
	Typography,
	Divider,
} from "@mui/material";
import { Dialog } from "../../Components/Dialog";
import ChangePw from "../../Factories/Account/ChangePw";
import { Input } from "../../Components/Inputs";
import { Accordion } from "../../Components/Conotec";
/** Icons Import */
import {
	VpnKey as PasswordIcon,
	Settings as SettingIcon,
	EditCalendar as WriteIcon,
	KeyboardArrowRight as RightArrowIcon,
	NotificationsActive as AlarmIcon,
	AddCard as PaymentIcon,
	Logout as LogoutIcon,
	ArrowDropDown,
	FormatListBulleted as FormatList,
} from "@mui/icons-material";

function Settings(props) {
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	}); // 비밀번호 변경
	const [dialog1, setDialog1] = React.useState(false); // 이름 변경
	const [dialog2, setDialog2] = React.useState(false); // 약관 확인
	const [logoutToggle, setLogoutToggle] = React.useState(false);
	const [tel, setTel] = React.useState(null);

	// 입력한 전화번호를 전화번호 형식으로 변경
	React.useEffect(() => {
		let test = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
		// console.log("tel : ", tel);
		// console.log("tel : ", test.test(tel));
		if (test.test(tel)) {
			if (tel.length === 11) {
				props.setInputs({
					...props.inputs,
					changedTel: tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
				});
			} else if (tel.length === 13) {
				props.setInputs({
					...props.inputs,
					changedTel: tel,
				});
			}
		} else if (tel === "") {
			props.setInputs({
				...props.inputs,
				changedTel: tel,
			});
		}
	}, [tel]);

	return (
		<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
			<Grid xs={4} md={12}>
				<Card sx={{ width: "100%" }}>
					<CardHeader
						title={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) => f.lanId === "settings"
								)[0].lanName
								: ""
						}
						titleTypographyProps={{
							variant: Commons.IsMobile() ? "body1" : "h5",
							fontWeight: "bold",
							textAlign: "center",
						}}
					/>
					<CardHeader
						sx={{ py: 0 }}
						avatar={<Avatar />}
						title={props.inputs.userName}
						titleTypographyProps={{
							variant: Commons.IsMobile() ? "subtitle1" : "h6",
							fontWeight: "bold",
						}}
						subheader={props.inputs.userId}
						subheaderTypographyProps={{
							variant: "subtitle2",
						}}
						action={
							<IconButton onClick={() => setDialog1(true)}>
								<WriteIcon />
							</IconButton>
						}
					/>
					<CardContent>
						<Typography
							variant={
								props.inputs.amountYn === "Y"
									? "subtitle1"
									: "subtitle2"
							}
						>
							{props.inputs.amountYn === "Y"
								? (props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"settings_gen_amount_message_1"
									)[0].lanName
									: "") +
								props.inputs.authDate +
								(props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"settings_gen_amount_message_2"
									)[0].lanName
									: "")
								: props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"settings_gen_no_amount_message"
									)[0].lanName
									: ""}
							{/* // 유료기간 사용은 ~ 까지입니다. or 유료가입을 하면 더 많은 서비스를 제공합니다. */}
						</Typography>
					</CardContent>
				</Card>
				<List>
					<ListItem>
						<ListItemButton
							onClick={() => {
								setDialog({
									...dialog,
									open: true,
								});
							}}
						>
							<ListItemIcon>
								<PasswordIcon />
							</ListItemIcon>
							<ListItemText
								primary={
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"settings_gen_change_password"
										)[0].lanName
										: ""
								}
							/>
							{/* 비밀번호 변경 */}
							<RightArrowIcon />
						</ListItemButton>
					</ListItem>
					<Divider variant="middle" />
					<ListItem>
						<ListItemButton
							onClick={() => props.MovePage("SystemSetting")}
						>
							<ListItemIcon>
								<SettingIcon />
							</ListItemIcon>
							<ListItemText
								primary={
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"settings_gen_change_settings"
										)[0].lanName
										: ""
								}
							/>
							{/* 설정변경 */}
							<RightArrowIcon />
						</ListItemButton>
					</ListItem>
					<Divider variant="middle" />
					{/* <ListItem>
						<ListItemButton
							onClick={() => props.MovePage("AlarmSetting")}
						>
							<ListItemIcon>
								<AlarmIcon />
							</ListItemIcon>
							<ListItemText primary="알림 설정" />
							<RightArrowIcon />
						</ListItemButton>
					</ListItem>
					<Divider variant="middle" /> */}
					{/* <ListItem>
						<ListItemButton
							onClick={() => props.MovePage("Approval")}
						>
							<ListItemIcon>
								<PaymentIcon />
							</ListItemIcon>
							<ListItemText
								primary={
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"settings_gen_membership"
										)[0].lanName
										: ""
								}
							/>
							{
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"settings_gen_membership"
									)[0].lanName
									: ""	// 유료 회원 가입
							}
							<RightArrowIcon />
						</ListItemButton>
					</ListItem> */}
					<Divider variant="middle" /><ListItem>
						<ListItemButton
							onClick={() => setDialog2(true)}
						>
							<ListItemIcon>
								<FormatList />
							</ListItemIcon>
							<ListItemText
								primary={

									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"snack_check_term"
										)[0].lanName
										: ""	// 약관 확인
								}
							/>
							<RightArrowIcon />
						</ListItemButton>
					</ListItem>
					<Divider variant="middle" />
					<ListItem>
						<ListItemButton onClick={() => setLogoutToggle(true)}>
							<ListItemIcon>
								<LogoutIcon />
							</ListItemIcon>
							<ListItemText
								primary={
									props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"settings_gen_logout"
										)[0].lanName
										: ""
								}
							/>
							{/* 로그아웃 */}
							<RightArrowIcon />
						</ListItemButton>
					</ListItem>
				</List>
			</Grid>
			{/** 비밀번호 변경 */}
			<Dialog
				open={dialog.open}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId === "settings_gen_change_password"
						)[0].lanName
						: ""
				} // 비밀번호 변경
				children={
					<ChangePw
						dialog={dialog}
						setDialog={setDialog}
						snacks={props.snacks}
						setSnacks={props.setSnacks}
					/>
				}
				onClose={() => {
					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				closeButtonName=""
				saveButtonName=""
			/>
			{/** 사용자 이름, 전화번호 변경 */}
			<Dialog
				open={dialog1}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "settings_pop_change_title"
						)[0].lanName
						: "사용자 정보 변경"
				} // 사용자 정보 변경
				children={
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Input
							name={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) => f.lanId === "settings_gen_name"
									)[0].lanName
									: "이름"
							} // 이름
							value={props.inputs.changedName}
							onChange={(e) => {
								props.setInputs({
									...props.inputs,
									changedName: e,
								});
							}}
							sx={{
								margin: "15px 0"
							}}
						/>
						<Input
							name={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) => f.lanId === "settings_gen_tel"
									)[0].lanName
									: "전화번호"
							} // 전화번호
							value={tel !== null ? tel : props.inputs.userTel}
							onChange={(e) => {
								setTel(e);
							}}
							sx={{
								margin: "15px 0"
							}}
						/>
					</div>
				}
				onClose={() => {
					setDialog1(false);
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "settings_pop_close"
						)[0].lanName
						: ""
				} // 닫기
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "settings_pop_save"
						)[0].lanName
						: ""
				} // 저장
				onSave={() => {
					let telReg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
					if (props.inputs.changedName === "") {
						// 사용자 이름 공백으로 저장 에러
						setDialog1(false);
						props.setSnackDialog({
							open: true,
							title:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"snack_error_title"
									)[0].lanName
									: "",	// 에러!
							message:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"snack_space_saved"
									)[0].lanName
									: "",	// 공백은 저장할 수 없습니다.,
						});
						props.setInputs({
							...props.inputs,
							changedName: props.inputs.userName,
						});
					} else if (tel !== "" && tel !== null && !telReg.test(tel)) {
						setDialog1(false);
						props.setSnackDialog({
							open: true,
							title:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"snack_error_title"
									)[0].lanName
									: "",	// 에러!
							message:
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"snack_tel_format"
									)[0].lanName
									: "",	// 전화번호 형식을 바르게 입력해 주세요
						});
						setTel(null);
					} else {
						props.ChangeName();
						setTel(null);
					}
				}}
			/>
			{/** 약관 확인 */}
			<Dialog
				fullWidth
				maxWidth={"md"}
				open={dialog2}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "snack_check_term"
						)[0].lanName
						: ""
				} // 약관 확인
				children={
					<Accordion
						lanList={props.lanList}
						showCheckbox={false}
					/>
				}
				onClose={() => {
					setDialog2(false);
				}}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "settings_pop_close"
						)[0].lanName
						: ""
				} // 닫기
				useSaveButton={false}
			/>
			{/** 로그아웃 */}
			<Dialog
				open={logoutToggle}
				onClose={() => setLogoutToggle(false)}
				title={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "drawer_pop_warning_title"
						)[0].lanName
						: "경고" // 경고
				}
				saveButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "drawer_pop_confirm"
						)[0].lanName
						: "확인" // 확인
				}
				closeButtonName={
					props.lanList.length > 0
						? props.lanList.filter(
							(f) => f.lanId === "drawer_pop_close"
						)[0].lanName
						: "닫기" // 닫기
				}
				onSave={() => {
					props.LogoutLogic();
				}}
			>
				<div>
					{
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId ===
									"snack_no_more_push"
							)[0].lanName
							: "더 이상 푸시 메시지가 전송되지 않습니다." // 더 이상 푸시 메시지가 전송되지 않습니다.
					}
				</div>
				<div>
					{
						props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId ===
									"snack_wanna_logout"
							)[0].lanName
							: "로그아웃 하시겠습니까?" // 로그아웃 하시겠습니까?
					}
				</div>
			</Dialog>
		</Grid>
	);
}

export default Settings;
