import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Services Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components Import */
import ApprovalView from "../../Views/Home/Approval";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import {
	SnackDialog,
	SnackDialog as ErrorDialog
} from "../../Components/Dialog";

function Approval() {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [errorDialog, setErrorDialog] = React.useState({		// 에러 메시지 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [payments, setPayments] = React.useState([]); // 결제수단 콤보 리스트
	const [approvalPeriod, setApprovalPeriod] = React.useState([]); // 가입기간 콤보 리스트
	const [inputs, setInputs] = React.useState({
		userId: "",
		userNm: "",
		periodValue: "",
		discount: 0,
		calAmt: 0,
		payment: "",
		checkboxValue: false,
		/** --- SMARTRO PAY 변수 --- */
		Paymethod: "", // 결제수단
		GoodsCnt: 1, // 수량
		GoodsName: "", // 상품명
		Amt: 0, // 금액
		MoId: "", // 주문번호
		MId: process.env.REACT_APP_SMARTROPAY_MID, // KEY
		ReturnUrl: Global.base.useUrl + Global.urls.Approval.CallMobileApproval,
		StopUrl: Global.base.useUrl + Global.urls.Approval.MobileStopApproval,
		// RetryUrl: Global.base.useUrl + Global.urls.Approval.RetryApproval,
		BuyerName: "", // 구매자
		BuyerTel: "0", // 구매자
		BuyerEmail: "", // 구매자 이메일
		VbankExpDate: "", // 입금만료일(yyyyMMdd)
		EncryptData: "", // KEY
		GoodsCl: "0", // 휴대폰 결제 타입(0: 컨텐츠, 1: 실물)
		EdiDate: "", // 전문생성일시(yyyyMMddhhmmss)
		TaxAmt: "", // 과세
		TaxFreeAmt: "", // 비과세
		VatAmt: "", // 부가세
		VersionType: "", // 복합결제 전문 버전
		/** ------ */
	});
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		Init();
	}, []);

	const Init = async () => {
		setProgress(true);
		let userInfo = null;
		let paymentsList = [];
		let period = [];
		let amt = 0;
		let discount = 0;
		let lans = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((f) => lans.push(f));
					setLanList(result);
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Approval.Approval
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else {
					data.forEach((item, index) => {
						if (item.GroupCd === "APPROVAL_PERIOD") {
							period.push({
								name: item.Name,
								value: item.Val1,
								ref: item.Ref1,
								goods: item.Val2,
							});
						} else if (
							item.GroupCd === "APPROVAL_AMT" &&
							item.Ref1 === "AMT"
						) {
							amt = item.Val1 * 1;
						} else if (
							item.GroupCd === "APPROVAL_AMT" &&
							item.Ref1 === "DISCOUNT"
						) {
							discount = item.Val1 * 1;
						} else if (
							item.GroupCd === "APPROVAL_PAYMENT" &&
							item.Val2 === "Y"
						) {
							paymentsList.push({
								name: item.Name,
								value: item.Code,
								val1: item.Val1,
							});
						}
					});
				}
			});

			setPayments(paymentsList);
			setApprovalPeriod(period);
			setInputs({
				...inputs,
				userId: userInfo.userId,
				userNm: userInfo.userNm,
				calAmt: amt,
				discount: discount,
				BuyerName: userInfo.userNm,
				BuyerEmail: userInfo.userId,
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 선택된 가입기간에 따른 결제 금액 변경
	const ChangeAmount = (e) => {
		let now = Commons.DateFormating(new Date(), 5);
		let selectItem = approvalPeriod.filter((item) => item.value === e)[0];
		let result = 0;
		let temp = 0;
		let taxAmt = 0;
		let vatAmt = 0;

		if (selectItem.ref === "AMT") {
			result = inputs.calAmt * 1 * selectItem.value;
		} else if (selectItem.ref === "DISCOUNT") {
			result =
				inputs.calAmt * 1 * selectItem.value -
				(inputs.calAmt * 1 * selectItem.value * (inputs.discount * 1)) /
				100;
		}

		temp = result / 1.1;
		vatAmt = Math.floor(result - temp);
		taxAmt = result - vatAmt;

		setInputs({
			...inputs,
			periodValue: e,
			Amt: result,
			// Amt: "0",
			GoodsName: selectItem.goods,
			MoId: inputs.userNm + now,
			VbankExpDate: Commons.DateToString(Commons.DateCalcular(7, "D")),
			EncryptData: Commons.Sha256Encrypt(
				now +
				inputs.MId +
				result +
				// "0" +
				process.env.REACT_APP_SMARTROPAY_MERCHANTKEY
			),
			EdiDate: now,
			TaxAmt: taxAmt,
			// TaxAmt: "",
			TaxFreeAmt: "0",
			VatAmt: vatAmt,
			// VatAmt: "",
			VersionType: "",
		});
	};

	const ChangeCheckbox = (e) => {
		setInputs({
			...inputs,
			checkboxValue: e,
		});
	};

	// 결제 전 확인
	const ApprovalValidation = () => {
		// console.log("inputs : ", inputs);
		if (!inputs.payment) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		(lanList.length > 0
			// 			? lanList.filter(
			// 				(f) => f.lanId === "snack_select_payment_method"
			// 			)[0].lanName
			// 			: ""), // 결제수단을 선택하세요.
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message:
					(lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_select_payment_method"
						)[0].lanName
						: ""), // 결제수단을 선택하세요.
			});
			return false;
		} else if (!inputs.periodValue) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		(lanList.length > 0
			// 			? lanList.filter(
			// 				(f) =>
			// 					f.lanId ===
			// 					"snack_select_subscription_period"
			// 			)[0].lanName
			// 			: ""), // 가입기간을 선택하세요.
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message:
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_select_subscription_period"
						)[0].lanName
						: ""), // 가입기간을 선택하세요.
			});
			return false;
		} else if (!inputs.checkboxValue) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		(lanList.length > 0
			// 			? lanList.filter(
			// 				(f) => f.lanId === "snack_agree_regulation"
			// 			)[0].lanName
			// 			: ""), // 유료회원/환불안내 규정을 동의해야합니다.
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message:
					(lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_agree_regulation"
						)[0].lanName
						: ""), // 유료회원/환불안내 규정을 동의해야합니다.
			});
			return false;
		} else {
			return true;
		}
	};

	const CallApproval = async (tId = "", trAuthKey = "") => {
		setProgress(true);
		let userInfo = null;
		let endDate = null;
		let calDate = null;

		try {
			if (!tId || !trAuthKey) {
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_authentication_failed"
						)[0].lanName
						: "") // 인증에 실패했습니다.
				);
			}
			// console.log("tId : ", tId);
			// console.log("trAuthKey : ", trAuthKey);
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Approval.CallApproval,
				{
					url: process.env.REACT_APP_SMARTROPAY_APPROVAL_URL,
					tId: tId,
					trAuthKey: trAuthKey,
				}
			).then(async (result) => {
				let data = result.data;
				// console.log("CallApproval: ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (
					data.ResultCode !== "3001" &&
					data.ResultCode !== "4000" &&
					data.ResultCode !== "2211" &&
					data.ResultCode !== "4100" &&
					data.ResultCode !== "4110" &&
					data.ResultCode !== "A000" &&
					data.ResultCode !== "A100" &&
					data.ResultCode !== "KP00" &&
					data.ResultCode !== "NP00" &&
					data.ResultCode !== "PP00" &&
					data.ResultCode !== "LP00" &&
					data.ResultCode !== "PI00" &&
					data.ResultCode !== "0000" &&
					data.ResultCode !== "2001" &&
					data.ResultCode !== "7001" &&
					data.ResultCode !== "4200" &&
					data.ResultCode !== "4300" &&
					data.ResultCode !== "4301" &&
					data.ResultCode !== "4311" &&
					data.ResultCode !== "4314" &&
					data.ResultCode !== "4315" &&
					data.ResultCode !== "PA00" &&
					data.ResultCode !== "PS00" &&
					data.ResultCode !== "PS40"
				) {
					// 성공 코드 아니면 에러
					// 다이얼로그 박스에서 닫기 누르면 세팅창으로 가게끔 수정
					setErrorDialog({
						...errorDialog,
						open: true,
						title: "Error",
						message:
							(
								<div>
									{"Error Code : " + data.ResultCode}
									<br />
									{"Message : " + data.ResultMsg}
								</div>
							),
					});
				} else {
					// 결제 성공. 종료기간 설정 및 저장
					await IndexedDb.GetLoginInfo().then((result) => {
						// console.log("result : ", result);
						endDate =
							result.useEdate.substring(0, 4) +
							"-" +
							result.useEdate.substring(4, 6) +
							"-" +
							result.useEdate.substring(6);
					});

					if (Commons.IsFutureDate(new Date(), new Date(endDate))) {
						calDate = Commons.DateFormating(
							Commons.AddMonthsToDate(
								new Date(endDate),
								inputs.periodValue * 1
							),
							6
						);
					} else {
						calDate = Commons.DateFormating(
							Commons.AddMonthsToDate(
								new Date(),
								inputs.periodValue * 1
							),
							6
						);
					}

					await Commons.Axios(
						"post",
						Global.base.useUrl +
						Global.urls.Approval.UpdateUseEDate,
						{
							userId: userInfo.userId,
							useEDate: calDate,
							amt: inputs.Amt,
						}
					).then(async (result1) => {
						let data1 = result1;
						// console.log("Data1 : ", data1);

						if (data1.type === "Error") {
							throw new Error(data1.message);
						} else if (data1 === "데이터를 확인하세요.") {
							throw new Error(
								(lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId === "snack_check_data"
									)[0].lanName
									: "")
							);
						} else {
							await IndexedDb.UpdateDb(
								"LoginInfo",
								userInfo.token,
								{
									...userInfo,
									useSdate: Commons.DateFormating(
										new Date(),
										6
									),
									useEdate: calDate,
									amountYn: "Y",
								}
							);
							Commons.Storages("set", "userInfo", {
								...Commons.Storages("get", "userInfo"),
								useEndDate:
									calDate.substring(0, 4) +
									"-" +
									calDate.substring(4, 6) +
									"-" +
									calDate.substring(6),
								amountYn: "Y",
							});

							// setSnacks({
							// 	...snacks,
							// 	open: true,
							// 	type: "success",
							// 	message:
							// 		(lanList.length > 0
							// 			? lanList.filter(
							// 				(f) =>
							// 					f.lanId ===
							// 					"snack_finish_payment"
							// 			)[0].lanName
							// 			: ""), // 결제되었습니다.
							// });
							setSnackDialog({
								open: true,
								title:
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"snack_success_title"
										)[0].lanName
										: "",	// 성공
								message:
									(lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"snack_finish_payment"
										)[0].lanName
										: ""), // 결제되었습니다.
							});
							navigate("/Settings");
						}
					});
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.message === "인증에 실패했습니다.") {
				// console.log(e);
			} else {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			}
		} finally {
			setProgress(false);
		}
	};

	return (
		<div>
			<ApprovalView
				payments={payments}
				approvalPeriod={approvalPeriod}
				inputs={inputs}
				lanList={lanList}
				setInputs={setInputs}
				ChangeAmount={ChangeAmount}
				ChangeCheckbox={ChangeCheckbox}
				ApprovalValidation={ApprovalValidation}
				CallApproval={CallApproval}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "approval_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
			<ErrorDialog
				open={errorDialog.open}
				title={errorDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() => {
					setErrorDialog({
						...errorDialog,
						open: false,
						title: "",
						message: ""
					});

					return navigate("/Settings");
				}}
			>
				{errorDialog.message}
			</ErrorDialog>
		</div>
	);
}

export default Approval;
