import React from "react";
import { useDispatch } from "react-redux";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card, Tabs, Tab } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Icons Import */
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";
/** Splitter */
import { Splitter, SplitterPanel } from "primereact/splitter";

/** 권한관리 화면 */

function BM10030(props) {
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const gridRef = React.useRef();
	const gridRef1 = React.useRef();
	const gridRef2 = React.useRef();
	const menuListRef = React.useRef();
	const columnDefs = [
		// 좌측 권한그룹 코드
		{
			field: "authGroupCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_auth_group_code"
					)[0].lanName
					: "", // 권한그룹코드
			minWidth: 120,
		},
		{
			field: "authGroupNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_auth_group_name"
					)[0].lanName
					: "", // 권한그룹명
			minWidth: 120,
			editable: true,
		},
		{
			field: "authGroupNameEng",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_auth_group_name_eng"
					)[0].lanName
					: "", // 권한그룹명
			minWidth: 120,
			editable: true,
		},
		{
			field: "updateId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_update_id"
					)[0].lanName
					: "", // 수정자
			minWidth: 120,
		},
	];
	const columnDefs1 = [
		// 권한관리 탭의 우측 그리드
		{
			field: "authGroupCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_auth_group_code"
					)[0].lanName
					: "", // 권한그룹코드
			minWidth: 180,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "lcategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_large_type"
					)[0].lanName
					: "", // 대분류
			minWidth: 200,
			editable: false,
		},
		{
			field: "mcategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_medium_type"
					)[0].lanName
					: "", // 중분류
			minWidth: 150,
			editable: false,
		},
		{
			field: "mcategoName",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_medium_type_nm"
					)[0].lanName
					: "", // 중분류 명
			minWidth: 170,
			editable: false,
		},
		{
			field: "detailCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_detail_auth"
					)[0].lanName
					: "", // 상세 권한
			minWidth: 150,
			editable: false,
		},
		{
			field: "createYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_add_auth"
					)[0].lanName
					: "", // 추가 권한
			minWidth: 150,
			cellEditor: "agSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) => item.name),
			},
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
			editable: (e) => {
				if (e.data.createYn === "X") {
					return false;
				} else {
					return true;
				}
			},
			editable: false,
		},
		{
			field: "readYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_search_auth"
					)[0].lanName
					: "", // 조회 권한
			minWidth: 150,
			cellEditor: "agSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) => item.name),
			},
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
			editable: (e) => {
				if (e.data.readYn === "X") {
					return false;
				} else {
					return true;
				}
			},
			editable: false,
		},
		{
			field: "updateYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_save_auth"
					)[0].lanName
					: "", // 저장 권한
			minWidth: 150,
			cellEditor: "agSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) => item.name),
			},
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
			editable: (e) => {
				if (e.data.updateYn === "X") {
					return false;
				} else {
					return true;
				}
			},
			editable: false,
		},
		{
			field: "deleteYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_delete_auth"
					)[0].lanName
					: "", // 삭제 권한
			minWidth: 150,
			cellEditor: "agSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) => item.name),
			},
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
			editable: (e) => {
				if (e.data.deleteYn === "X") {
					return false;
				} else {
					return true;
				}
			},
			editable: false,
		},
		{
			field: "excelYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_excel_auth"
					)[0].lanName
					: "", // Excel 권한
			minWidth: 150,
			cellEditor: "agSelectCellEditor",
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "USE_YN")
					.map((item) => item.name),
			},
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
			editable: (e) => {
				if (e.data.excelYn === "X") {
					return false;
				} else {
					return true;
				}
			},
			editable: false,
		},
	];
	const columnDefs2 = [
		// 메뉴관리 탭의 우측 그리드
		{
			field: "authGroupCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_auth_group_code"
					)[0].lanName
					: "", // 권한그룹코드
			minWidth: 180,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "lcategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_large_type"
					)[0].lanName
					: "", // 대분류
			minWidth: 200,
			editable: false,
		},
		{
			field: "mcategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_medium_type"
					)[0].lanName
					: "", // 중분류
			minWidth: 150,
			editable: false,
		},
		{
			field: "mcategoName",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_medium_type_nm"
					)[0].lanName
					: "", // 중분류 명
			minWidth: 170,
			editable: false,
		},
		{
			field: "scategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_small_type"
					)[0].lanName
					: "", // 소분류
			minWidth: 150,
			editable: false,
		},
		{
			field: "screenType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_screen_type"
					)[0].lanName
					: "", // 화면 분류
			minWidth: 150,
			cellEditorParams: {
				values: props.codeList
					.filter((item) => item.groupCd === "SCREEN_TYPE")
					.map((item) => item.name),
			},
			editable: false,
		},
	];
	const menuListColumnDefs = [
		// 권한관리 팝업 그리드
		{
			field: "lcategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_large_type"
					)[0].lanName
					: "", // 대분류
			headerCheckboxSelection: true,
			checkboxSelection: true,
			minWidth: 200,
		},
		{
			field: "mcategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_medium_type"
					)[0].lanName
					: "", // 중분류
		},
		{
			field: "mcategoName",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_medium_type_nm"
					)[0].lanName
					: "", // 중분류 명
			minWidth: 170,
		},
		{
			field: "scategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_small_type"
					)[0].lanName
					: "", // 소분류
		},
		{
			field: "detailCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_detail_auth"
					)[0].lanName
					: "", // 상세권한
			minWidth: 130
		},
		{
			field: "createYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_add_auth"
					)[0].lanName
					: "", // 추가권한
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
		},
		{
			field: "readYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_search_auth"
					)[0].lanName
					: "", // 조회권한
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
		},
		{
			field: "updateYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_save_auth"
					)[0].lanName
					: "", // 저장권한
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
		},
		{
			field: "deleteYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_delete_auth"
					)[0].lanName
					: "", // 삭제권한
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
		},
		{
			field: "excelYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_excel_auth"
					)[0].lanName
					: "", // Excel 권한
			cellRenderer: (e) => {
				if (e.value === "X") {
					return null;
				} else {
					return e.value;
				}
			},
		},
	];
	const menuListColumnDefs1 = [
		// 메뉴관리 팝업 그리드
		{
			field: "lcategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_large_type"
					)[0].lanName
					: "", // 대분류
			headerCheckboxSelection: true,
			checkboxSelection: true,
			minWidth: 200,
		},
		{
			field: "mcategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_medium_type"
					)[0].lanName
					: "", // 중분류
		},
		{
			field: "mcategoName",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_medium_type_nm"
					)[0].lanName
					: "", // 중분류 명
			minWidth: 170,
		},
		{
			field: "scategoCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_small_type"
					)[0].lanName
					: "", // 소분류
		},
		{
			field: "screenType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_col_screen_type"
					)[0].lanName
					: "", // 화면분류
		},
	];
	const [checkingDialog, setCheckingDialog] = React.useState(false);
	const [menuDialog, setMenuDialog] = React.useState({
		open: false,
		nowTab: 0,
	});
	const [confirms, setConfirms] = React.useState({
		toggle: false,
		title: "",
		content: "",
		onSave: () => { },
	});

	const TabChanged = (event, newValue) => {
		props.setTabToggle(newValue);
		props.Init();
		props.setRowData1([]);
		props.setRowData2([]);
	};

	return (
		<>
			<Tabs value={props.tabToggle} onChange={TabChanged}>
				<Tab
					value={0}
					label={props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId ===
								"bm10030_tab_menu_management"
						)[0].lanName
						: ""	// 메뉴관리
					}
				/>
				<Tab
					value={1}
					label={props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId ===
								"bm10030_tab_auth_management"
						)[0].lanName
						: ""	// 권한관리
					}
				/>
			</Tabs>
			<Splitter>
				<SplitterPanel size={40}>
					<Card sx={{ display: "flex", justifyContent: "flex-end" }}>
						<AuthGroupButton
							pageName="BM10030"
							target="G01"
							refreshNone="none"
							addFunc={() => {
								setCheckingDialog(true);
							}}
							saveFunc={() => {
								setConfirms({
									...confirms,
									toggle: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10030_pop_save_title"
										)[0].lanName
										: ""),	// 저장
									content: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10030_pop_save_messege"
										)[0].lanName
										: ""),	// 저장하시겠습니까?
									onSave: () => props.CreateGroup(),
								});
							}}
							deleteFunc={() => {
								setConfirms({
									...confirms,
									toggle: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10030_pop_delete_title"
										)[0].lanName
										: ""),	// 삭제
									content: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10030_pop_delete_messege"
										)[0].lanName
										: ""),	// 삭제하시겠습니까?
									onSave: () => props.DeleteGroup(),
								});
							}}
						/>
					</Card>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animationRows={true}
								columnDefs={columnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: false,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="single"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() => {
									GridFunctions.onSelectionChanged(
										gridRef,
										(_selected) => {
											if (_selected[0].authGroupCd) {
												props.setSelectedRowData(
													_selected
												);
												if (props.tabToggle === 0) {
													props.GetMenuGroup(
														_selected[0].authGroupCd
													);
												} else {
													props.GetAuthGroup(
														_selected[0].authGroupCd
													);
												}
											} else {
												props.setRowData1([]);
												props.setRowData2([]);
											}
										}
									);
								}}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen()); // 열림 방지
									GridFunctions.onCellValueChanged(
										e,
										gridRef,
										props.setChangedRowData
									);
								}}
							// getRowStyle={(e) =>
							// 	GridFunctions.getRowStyle(e)
							// }
							/>
						</div>
					</Card>
				</SplitterPanel>
				<SplitterPanel size={60}>
					{props.tabToggle === 0 && (
						<div>
							<Card
								sx={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<AuthGroupButton
									pageName="BM10030"
									target="G02"
									refreshNone="none"
									addFunc={async () => {
										if (
											!props.selectedRowData[0] ||
											!props.selectedRowData[0].updateId
										) {
											return;
										} else {
											props.GetMenuList();
											setMenuDialog({
												open: true,
												nowTab: 0,
											});
										}
									}}
									saveFunc={() => {
										if (
											!props.selectedRowData[0] ||
											!props.selectedRowData[0].updateId
										) {
											return false;
										} else {
											setConfirms({
												...confirms,
												toggle: true,
												title: (props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"bm10030_pop_save_title"
													)[0].lanName
													: ""),	// 저장
												content: (props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"bm10030_pop_save_messege"
													)[0].lanName
													: ""),	// 저장하시겠습니까?
												onSave: () =>
													props.CreateMenuGroup(),
											});
										}
									}}
									deleteFunc={() => {
										if (
											!props.selectedRowData[0] ||
											!props.selectedRowData[0].updateId
										) {
											return false;
										} else {
											setConfirms({
												...confirms,
												toggle: true,
												title: (props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"bm10030_pop_delete_title"
													)[0].lanName
													: ""),	// 삭제
												content: (props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"bm10030_pop_delete_messege"
													)[0].lanName
													: ""),	// 삭제하시겠습니까?
												onSave: () =>
													props.DeleteMenuGroup(),
											});
										}
									}}
								/>
							</Card>
							<Card>
								<div
									className="ag-theme-alpine-dark"
									style={{
										width: "100%",
										height:
											Commons.Dimentions().height < 800
												? Commons.Dimentions().height *
												0.58
												: Commons.Dimentions().height *
												0.74,
									}}
								>
									<AgGridReact
										ref={gridRef2}
										animationRows={true}
										columnDefs={columnDefs2} // 컬럼 헤더
										defaultColDef={{
											flex: 1,
											minWidth: 100,
											editable: true,
											resizable: true,
											sortable: true,
											filter: true,
											floatingFilter: true,
										}}
										rowData={props.rowData2}
										rowSelection="multiple"
										singleClickEdit={
											Commons.IsMobile() ? true : false
										}
										onSelectionChanged={() =>
											GridFunctions.onSelectionChanged(
												gridRef2,
												props.setSelectedRowData2
											)
										}
										onCellValueChanged={(e) => {
											dispatch(
												DrawerActions.setNotOpen()
											); // 열림 방지
											GridFunctions.onCellValueChanged(
												e,
												gridRef2,
												props.setChangedRowData2
											);
										}}
									// getRowStyle={(e) =>
									// 	GridFunctions.getRowStyle(e)
									// }
									/>
								</div>
							</Card>
						</div>
					)}
					{props.tabToggle === 1 && (
						<div>
							<Card
								sx={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<AuthGroupButton
									pageName="BM10030"
									target="G03"
									refreshNone="none"
									addFunc={async () => {
										if (
											!props.selectedRowData[0] ||
											!props.selectedRowData[0].updateId
										) {
											return;
										} else {
											props.GetMenu();
											setMenuDialog({
												open: true,
												nowTab: 1,
											});
										}
									}}
									saveFunc={() => {
										if (
											!props.selectedRowData[0] ||
											!props.selectedRowData[0].updateId
										) {
											return;
										} else {
											setConfirms({
												...confirms,
												toggle: true,
												title: (props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"bm10030_pop_save_title"
													)[0].lanName
													: ""),	// 저장
												content: (props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"bm10030_pop_save_messege"
													)[0].lanName
													: ""),	// 저장하시겠습니까?
												onSave: () =>
													props.CreateAuthGroup(),
											});
										}
									}}
									deleteFunc={() => {
										if (
											!props.selectedRowData[0] ||
											!props.selectedRowData[0].updateId
										) {
											return;
										} else {
											setConfirms({
												...confirms,
												toggle: true,
												title: (props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"bm10030_pop_delete_title"
													)[0].lanName
													: ""),	// 삭제
												content: (props.lanList.length > 0
													? props.lanList.filter(
														(f) =>
															f.lanId ===
															"bm10030_pop_delete_messege"
													)[0].lanName
													: ""),	// 삭제하시겠습니까?
												onSave: () =>
													props.DeleteAuthGroup(),
											});
										}
									}}
								/>
							</Card>
							<Card>
								<div
									className="ag-theme-alpine-dark"
									style={{
										width: "100%",
										height:
											Commons.Dimentions().height < 800
												? Commons.Dimentions().height *
												0.58
												: Commons.Dimentions().height *
												0.74,
									}}
								>
									<AgGridReact
										ref={gridRef1}
										animationRows={true}
										columnDefs={columnDefs1} // 컬럼 헤더
										defaultColDef={{
											flex: 1,
											minWidth: 100,
											editable: true,
											resizable: true,
											sortable: true,
											filter: true,
											floatingFilter: true,
										}}
										rowData={props.rowData1}
										rowSelection="multiple"
										singleClickEdit={
											Commons.IsMobile() ? true : false
										}
										onSelectionChanged={() =>
											GridFunctions.onSelectionChanged(
												gridRef1,
												props.setSelectedRowData1
											)
										}
										onCellValueChanged={(e) => {
											dispatch(
												DrawerActions.setNotOpen()
											); // 열림 방지
											GridFunctions.onCellValueChanged(
												e,
												gridRef1,
												props.setChangedRowData1
											);
										}}
									// getRowStyle={(e) =>
									// 	GridFunctions.getRowStyle(e)
									// }
									/>
								</div>
							</Card>
						</div>
					)}
				</SplitterPanel>
			</Splitter>
			<Dialog
				title={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_pop_key_check"
					)[0].lanName
					: ""	// 키 체크
				}
				open={checkingDialog}
				onClose={() => setCheckingDialog(false)}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
				bodySx={{ display: "flex", flexDirection: "column" }}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_pop_check"
					)[0].lanName
					: ""	// 체크
				}
				onSave={() => {
					props.CheckAuthGroupCd(gridRef);
					props.setInputs({
						...props.inputs,
						keyCheckedValue: "",
					});
				}}
			>
				<Input
					sx={{ my: 1 }}
					type="outlined"
					value={props.inputs.keyCheckedValue}
					onChange={(e) => {
						props.setInputs({
							...props.inputs,
							keyCheckedValue: e,
						});
					}}
					name={props.lanList.length > 0
						? props.lanList.filter(
							(f) =>
								f.lanId ===
								"bm10030_pop_key_check_placeholder"
						)[0].lanName
						: ""	// 권한그룹코드
					}
				/>
			</Dialog>
			<Dialog
				fullWidth={true}
				maxWidth="xl"
				title={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_pop_menu_list"
					)[0].lanName
					: ""	// 메뉴 목록
				}
				open={menuDialog.open}
				onClose={() => setMenuDialog({ ...menuDialog, open: false })}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_pop_add"
					)[0].lanName
					: ""	// 추가
				}
				onSave={() => {
					if (menuDialog.nowTab === 0) {
						let pushData = [];

						props.selectedMenuRowData1.forEach((item) => {
							pushData.push({
								authGroupCd:
									props.selectedRowData[0].authGroupCd,
								...item,
								newYn: "Y",
							});
						});
						gridRef2.current.api.setRowData([
							...pushData,
							...props.rowData2,
						]);
						props.setChangedRowData2((current) => [
							...pushData,
							...current,
						]);
					} else {
						let pushData = [];

						props.selectedMenuRowData.forEach((item) => {
							pushData.push({
								authGroupCd:
									props.selectedRowData[0].authGroupCd,
								...item,
								screenType: "M",
								newYn: "Y",
							});
						});
						gridRef1.current.api.setRowData([
							...pushData,
							...props.rowData1,
						]);
						props.setChangedRowData1((current) => [
							...pushData,
							...current,
						]);
					}

					return dispatch(DrawerActions.setNotOpen()); // Drawer 열림 방지
				}}
			>
				<Grid container spacing={0} columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid xs={12} md={12}>
						<Card>
							<div
								className="ag-theme-alpine-dark"
								style={{ width: "100%", height: "600px" }}
							>
								<AgGridReact
									ref={menuListRef}
									animateRows={true}
									columnDefs={
										menuDialog.nowTab === 0
											? menuListColumnDefs1
											: menuListColumnDefs
									}
									defaultColDef={{
										flex: 1,
										minWidth: 100,
										editable: false,
										resizable: true,
										sortable: true,
										filter: true,
										floatingFilter: true,
									}}
									rowData={
										menuDialog.nowTab === 0
											? props.menuRowData1
											: props.menuRowData
									}
									rowSelection="multiple"
									onSelectionChanged={() =>
										menuDialog.nowTab === 0
											? GridFunctions.onSelectionChanged(
												menuListRef,
												props.setSelectedMenuRowData1
											)
											: GridFunctions.onSelectionChanged(
												menuListRef,
												props.setSelectedMenuRowData
											)
									}
								/>
							</div>
						</Card>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				title={confirms.title}
				open={confirms.toggle}
				onClose={() => {
					setConfirms({
						...confirms,
						toggle: false,
					});
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
				bodySx={{ display: "flex", flexDirection: "column" }}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10030_pop_confirm"
					)[0].lanName
					: ""	// 확인
				}
				onSave={() => {
					confirms.onSave();
				}}
			>
				{confirms.content}
			</Dialog>
		</>
	);
}

export default BM10030;
