import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardHeader,
	CardContent as MuiCardContent,
	IconButton,
	Typography,
} from "@mui/material";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
/** Images Import */
import TempuratureIcon from "../../Assets/Images/Design/drawable-hdpi/tem_ic.png";
import TempuratureBtnIcon from "../../Assets/Images/Design/drawable-hdpi/tem_btn.png";

const CardContents = styled((props) => (
	<MuiCardContent style={{ paddingBottom: "5px" }} {...props} />
))(({ theme }) => ({
	padding: "0px",
	margin: "0px",
}));
const CardContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
}));
const CardContentItem = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
}));
const CardContentItemTitle = styled((props) => <Typography {...props} />)(
	({ theme }) => ({
		width: "100%",
		textAlign: "center",
		padding: "4px 0px",
		borderWidth: "1px",
		borderBottomWidth: "0px",
		borderColor: "#4A5263",
		borderStyle: "solid",
		color: "#FFFFFF",
		backgroundColor: "#000000",
	})
);
const CardContentItemData = styled((props) => <Typography {...props} />)(
	({ theme }) => ({
		width: "100%",
		textAlign: "center",
		padding: "4px 0px",
		borderWidth: "1px",
		borderTopWidth: "0px",
		borderColor: "#4A5263",
		borderStyle: "solid",
		color: "#FFFFFF",
	})
);

const ContentWrap = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
}));

const ContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	margin: "8px 0px",
}));

const ContentItemTitle = styled((props) => <div {...props} />)(({ theme }) => ({
	width: "30%",
}));

function DashboardSchedule({
	items = [], // DashboardSchedule's item list
	itemsVal = [], // DashboardSchedule's item value
	lanList = [],
	lanCode = "",	// 사용자의 현재 언어 설정
	controllerData = {},
	onClick = () => { },
	onDelete = () => { },
}) {
	const [detailToggle, setDetailToggle] = React.useState(false);
	const [deleteToggle, setDeleteToggle] = React.useState(false);
	const [selectedItem, setSelectedItem] = React.useState({});

	const DeleteBooking = (bkDate, rcnt) => {
		onDelete(bkDate, rcnt);
	};

	return (
		<Grid xs={4} sm={8} md={controllerData.amountYn === "Y" ? 6 : 12}>
			<Card
				className="no-scroll"
				sx={{
					height: items.length > 5 ? "300px" : "100%",
					overflowY: "scroll",
				}}
			>
				<CardHeader
					sx={{ py: 1, bgcolor: "#141820" }}
					avatar={
						<img
							style={{ width: "20px", height: "20px" }}
							src={TempuratureIcon}
						/>
					}
					title={
						lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"dashboard_gen_schedule_list"
							)[0].lanName
							: "" // 스케줄 목록
					}
					titleTypographyProps={{
						variant: "subtitle2",
						fontWeight: "bold",
					}}
					action={
						<IconButton
							onClick={() => {
								if (controllerData.amountYn === "Y") {
									onClick();
								} else {
									return false;
								}
							}}
						>
							<img
								style={{ width: "24px", height: "24px" }}
								src={TempuratureBtnIcon}
							/>
						</IconButton>
					}
				/>
				<CardContents>
					<CardContentItems>
						<CardContentItem>
							<CardContentItemTitle>
								{lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_schedule_list_date"
									)[0].lanName
									: ""}
								{/* 예약일자 */}
							</CardContentItemTitle>
							<CardContentItemTitle>
								{lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_schedule_list_subscript"
									)[0].lanName
									: ""}
								{/* 변경 내용 */}
							</CardContentItemTitle>
							<CardContentItemTitle>
								{lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_schedule_list_ch_value"
									)[0].lanName
									: ""}
								{/* 변경 값 */}
							</CardContentItemTitle>
						</CardContentItem>
						{items.map((item, index) => {
							let tempItem =
								itemsVal.filter(
									(_item) =>
										_item.inputType === "2" &&
										_item.mapAdd === item.MapAdd
								).length !== 0
									? itemsVal.filter(
										(_item) =>
											_item.inputType === "2" &&
											_item.mapAdd === item.MapAdd
									)[0].inputs[item.AftValue].name === undefined
										? itemsVal.filter(
											(_item) =>
												_item.inputType === "2" &&
												_item.mapAdd === item.MapAdd
										)[0].inputs[item.AftValue]
										: itemsVal.filter(
											(_item) =>
												_item.inputType === "2" &&
												_item.mapAdd === item.MapAdd
										)[0].inputs[item.AftValue].name
									: item.AftValue;
							return (
								<CardContentItem
									key={index}
									className="schedule-list"
									style={{ cursor: "pointer" }}
									onClick={() => {
										if (item.AppDate) {
											setDetailToggle(true);
											setSelectedItem({ ...item });
										} else {
											return false;
										}
									}}
								>
									{item.AppDate ? (
										<>
											<CardContentItemData>
												{
													item.AppDate.toString().substring(
														0,
														4
													) +
													"-" +
													item.AppDate.toString().substring(
														4,
														6
													) +
													"-" +
													item.AppDate.toString().substring(
														6,
														8
													)
												}
											</CardContentItemData>
											<CardContentItemData>
												{lanCode === "en" ? item.MapNmShEng : item.MapNmSh}
											</CardContentItemData>
											<CardContentItemData
												sx={{ fontWeight: "bold" }}
											>
												{tempItem}
											</CardContentItemData>
										</>
									) : (
										<>
											<CardContentItemData>
												{item.data}
											</CardContentItemData>
										</>
									)}
								</CardContentItem>
							);
						})}
					</CardContentItems>
				</CardContents>
			</Card>
			<Dialog
				title={
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"dashboard_pop_schedule_detail_title"
						)[0].lanName
						: "" // 예약 상세
				}
				open={detailToggle}
				onClose={() => {
					setDetailToggle(false);
					setSelectedItem({});
				}}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "dashboard_pop_delete"
						)[0].lanName
						: "" // 삭제
				}
				onSave={() => {
					setDeleteToggle(true);
				}}
				useSaveAfterClose={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "dashboard_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				children={
					<ContentWrap>
						<ContentItems>
							<ContentItemTitle
								style={{
									width: Commons.IsMobile() ? "40%" : "30%",
								}}
							>
								{lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_pop_schedule_detail_enroll_date"
									)[0].lanName
									: ""}
								{/* 등록 일자 */}
							</ContentItemTitle>
							<Input
								sx={{ pl: 1 }}
								value={
									selectedItem.BkDate
										? selectedItem.BkDate.toString().substring(
											0,
											4
										) +
										"-" +
										selectedItem.BkDate.toString().substring(
											4,
											6
										) +
										"-" +
										selectedItem.BkDate.toString().substring(
											6,
											8
										)
										: ""
								}
								disabled={true}
							/>
						</ContentItems>
						<ContentItems>
							<ContentItemTitle
								style={{
									width: Commons.IsMobile() ? "40%" : "30%",
								}}
							>
								{lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_schedule_list_date"
									)[0].lanName
									: ""}
								{/* 예약 일자 */}
							</ContentItemTitle>
							<Input
								sx={{ pl: 1 }}
								value={
									selectedItem.AppDate
										? selectedItem.AppDate.toString().substring(
											0,
											4
										) +
										"-" +
										selectedItem.AppDate.toString().substring(
											4,
											6
										) +
										"-" +
										selectedItem.AppDate.toString().substring(
											6,
											8
										) +
										" " +
										selectedItem.AppDate.toString().substring(
											8,
											10
										) +
										":" +
										selectedItem.AppDate.toString().substring(
											10,
											12
										)
										: ""
								}
								disabled={true}
							/>
						</ContentItems>
						<ContentItems>
							<ContentItemTitle
								style={{
									width: Commons.IsMobile() ? "40%" : "30%",
								}}
							>
								{lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_gen_schedule_list_subscript"
									)[0].lanName
									: ""}
								{/* 변경 내용 */}
							</ContentItemTitle>
							<Input
								sx={{ pl: 1 }}
								value={`${selectedItem.MapNmSh}`}
								disabled={true}
							/>
						</ContentItems>
						<ContentItems>
							<ContentItemTitle
								style={{
									width: Commons.IsMobile() ? "40%" : "30%",
								}}
							>
								{lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"dashboard_pop_schedule_change_value"
									)[0].lanName
									: ""}
								{/* 변경 값<br /> */}
								{/* (상세) */}
							</ContentItemTitle>
							<Input
								sx={{ pl: 1 }}
								value={`${selectedItem.AftValue} ( ${selectedItem.MapComment} )`}
								disabled={true}
							/>
						</ContentItems>
					</ContentWrap>
				}
			/>
			<Dialog
				title={
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"dashboard_pop_schedule_delete_confirm_title"
						)[0].lanName
						: "" // 삭제 확인
				}
				open={deleteToggle}
				onClose={() => {
					setDeleteToggle(false);
				}}
				saveButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "dashboard_pop_delete"
						)[0].lanName
						: "" // 삭제
				}
				onSave={() => {
					DeleteBooking(selectedItem.BkDate, selectedItem.Rcnt);
					setDeleteToggle(false);
					setDetailToggle(false);
					setSelectedItem({});
				}}
				useSaveAfterClose={false}
				children={
					<ContentItemTitle style={{ width: "100%" }}>
						{lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"dashboard_pop_delete_confirm_message"
							)[0].lanName
							: ""}
						{/* 현재 변경 내용을 삭제 하시겠습니까? */}
					</ContentItemTitle>
				}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "dashboard_pop_close"
						)[0].lanName
						: "" // 닫기
				}
			/>
		</Grid>
	);
}

export default DashboardSchedule;
