import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components */
import DM10030View from "../../Views/DashboardManagements/DM10030";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";

function DM10030(props) {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const location = useLocation(); // 화면간 데이터를 전달받음.
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
		logout: false,
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const intervalTime = Commons.Storages("get", "settings").reloadTime;
	const [determinateLabel, setDeterminateLabel] = React.useState(
		Commons.Storages("get", "settings").reloadTime / 1000
	); // 재조회 시간
	const [allController, setAllController] = React.useState([]); // 사용자의 모든 컨트롤러 데이터
	const [writeValue, setWriteValue] = React.useState(""); // 장치 이름 변경 변수
	const [controllerData, setControllerData] = React.useState([]);
	const [controllerDetail, setControllerDetail] = React.useState([]); // 조회된 컨트롤러의 정리된 정보
	const [chartData, setChartData] = React.useState({
		categories: [],
		data: [],
		yaxis: [
			{
				seriesName: "온도",
				axisTicks: {
					show: true
				},
				axisBorder: {
					show: true
				},
				labels: {
					style: {
						colors: ["#4ecdc4"]
					}
				},
			},
		],
	});
	const [curTmp, setCurTmp] = React.useState([]);
	const [curHdm, setCurHdm] = React.useState([]);
	const [curIll, setCurIll] = React.useState([]);
	const [curCo2, setCurCo2] = React.useState([]);
	const [itemTypeValue, setItemTypeValue] = React.useState(""); // Combo에서 선택된 값
	const [itemTypeItems, setItemTypeItems] = React.useState([]); // Combo에서 보여주는 목록
	const [lanList, setLanList] = React.useState([]); // language list

	const reloadCount = React.useRef(0); // 일정 시간 후 새로고침을 하기 위한 변수

	// 재조회 시간 카운트다운
	React.useEffect(() => {
		// Init();

		const LabelProgress = setInterval(() => {
			setDeterminateLabel((prevProgress) =>
				prevProgress <= 0 ? intervalTime / 1000 : prevProgress - 1
			);
			reloadCount.current = reloadCount.current + 1;
		}, 1000);

		return () => {
			clearInterval(LabelProgress);
		};
	}, []);

	// 재조회
	React.useEffect(() => {
		if (reloadCount.current >= 900) {
			window.location.reload(true);
		} else if (determinateLabel === 0) {
			Init(false);
			setDeterminateLabel(
				Commons.Storages("get", "settings").reloadTime / 1000
			);
		}
	}, [determinateLabel]);

	// 컨트롤러 데이터 수신
	React.useEffect(() => {
		let temp = [];
		let lans = [];

		// 언어팩
		const lanListData = async () => {
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});
		};
		lanListData();

		if (location.state === null || location.state[0] === undefined || location.state[1].length === 0) {
			setDialog({
				open: true,
				title: "No Controller",
				children: (
					<>
						<p>
							No controller has been selected.
							{/* 컨트롤러가 선택되지 않았습니다. */}
						</p>
						<p>
							Return to the home screen.
							{/* 홈 화면으로 돌아갑니다. */}
						</p>
					</>
				),
				logout: false,
			});
		} else {
			temp.push({
				...location.state[0],
			});

			setControllerData(temp);
			setControllerDetail(location.state[1]);
			Init(false);
			// GetChartData(
			// 	location.state[0].itemNo,
			// 	location.state[0].itemType,
			// 	// location.state[0].machNm
			// );
		}
	}, [location]);

	const Init = async (type = true) => {
		if (type) {
			setProgress(true);
		}

		let userInfo = null;
		let codeList = [];
		let errorMsgCodes = [];
		let mesureUnitList = [];
		let mesureSubUnitList = [];
		let temp = [];
		let tempDetail = [];
		let lans = [];
		let lanCode = "";

		try {
			// 사용자 정보 조회
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			// 코드 리스트 조회
			await IndexedDb.GetCodeAll().then((result) => {
				codeList = result;
				errorMsgCodes = result.filter(
					(item, index) => item.groupCd === "SENSSOR_ERROR"
				);
				mesureUnitList = result.filter(
					(item, index) => item.groupCd === "MESURE_TYPE"
				);
			});

			// 사용자 언어코드 확인
			await IndexedDb.GetLoginInfo().then((result) => {
				lanCode = result.lanCode;
			});

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.Home,
				{
					userId: userInfo.userId,
					type: Commons.Storages("get", "settings").server,
				}
			)
				.then((result) => {
					let data = result.data;

					if (
						data.message ===
						"Index was outside the bounds of the array."
					) {
						throw new Error(
							lans.length > 0
								? lans.filter(
									(f) =>
										f.lanId ===
										"snack_no_conntected_devices"
								)[0].lanName
								: ""
							// 연결된 장치가 없습니다.
						);
					} else if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						setControllerData([]);
						throw new Error(
							lans.length > 0
								? lans.filter(
									(f) =>
										f.lanId === "snack_unavailable_data"
								)[0].lanName
								: "" // 데이터가 없습니다.
						);
					} else if (data === "등록된 장치가 없습니다.") {
						setControllerData([]);
						throw new Error(
							lans.length > 0
								? lans.filter(
									(f) =>
										f.lanId === "snack_no_device_registered"
								)[0].lanName
								: "" // 등록된 장치가 없습니다.
						);
					} else if (data === false) {
						throw new Error(data);
					} else {
						// 사용자의 전체 데이터 조회 후 대시보드에 맞게 데이터 재가공
						if (data.machNames.length > 0) {
							setAllController(data.machNames);
							codeList.forEach((item, index) => {
								mesureUnitList.forEach((item1, index1) => {
									if (item.groupCd === item1.code) {
										mesureSubUnitList.push({ ...item });
									}
								});
							});

							data.machNames.map((item, index) => {
								let pushData = {
									...item,
									amountYn: userInfo.amountYn,
								};

								if (data.settingValues) {
									data.settingValues.forEach(
										(item1, index1) => {
											if (item1.itemNo === item.itemNo) {
												pushData = {
													...pushData,
													setting_temp: item1.temp,
													setting_damp: item1.damp,
													setting_illum: item1.illum,
													setting_co2: item1.co2,
												};
											}
										}
									);
								}
								if (data.currentValues) {
									data.currentValues.forEach(
										(item1, index1) => {
											if (item1.itemNo === item.itemNo) {
												pushData = {
													...pushData,
													current_temp: item1.temp,
													current_damp: item1.damp,
													current_illum: item1.illum,
													current_co2: item1.co2,
												};
											}
										}
									);
								}
								if (data.operations) {
									data.operations.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												operation: item1.operation,
											};
										}
									});
								}
								if (data.tempUnits) {
									data.tempUnits.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												tempUnit: item1.tempUnit
													? item1.tempUnit
													: "0",
											};
										}
									});
								}
								if (data.alarms) {
									data.alarms.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												errorYn: "Y",
												// alarmMsg:
												// 	errorMsgCodes.filter(
												// 		(f) =>
												// 			f.code ===
												// 			item1.alarmMsg
												// 	).length > 0
												// 		? errorMsgCodes.filter(
												// 			(f) =>
												// 				f.code ===
												// 				item1.alarmMsg
												// 		)[0].val1
												// 		: "",
												alarmMsg:
													item1.alarmMsg === "미접속"
														? "N-S"
														: item1.alarmMsg,
											};
										}
									});
								}
								temp.push(pushData);
							});

							temp.filter(
								(f) => f.itemNo === location.state[0].itemNo
							).map((item, index) => {
								if (
									item.itemType === "1" &&
									item.tmpYn === "Y"
								) {
									tempDetail.push({
										type: "temp",
										maxVal: item.tmpMaxVal,
										minVal: item.tmpMinVal,
										current: item.current_temp,
										setting: item.setting_temp,
										unit:
											mesureSubUnitList.filter(
												(f) =>
													f.groupCd === "TMP" &&
													f.code == item.tempUnit
											).length > 0
												? mesureSubUnitList.filter(
													(f) =>
														f.groupCd ===
														"TMP" &&
														f.code ==
														item.tempUnit
												)[0].val1
												: "",
										errorYn:
											item.errorYn === "Y" ? "Y" : "N",
										alarmMsg: item.alarmMsg,
									});
								} else if (
									item.itemType === "2" &&
									item.hmdYn === "Y"
								) {
									tempDetail.push({
										type: "damp",
										maxVal: item.hmdMaxVal,
										minVal: item.hmdMinVal,
										current: item.current_damp,
										setting: item.setting_damp,
										unit:
											mesureSubUnitList.filter(
												(f) =>
													f.groupCd === "DAMP" &&
													f.code == "0"
											).length > 0
												? mesureSubUnitList.filter(
													(f) =>
														f.groupCd ===
														"DAMP" &&
														f.code == "0"
												)[0].val1
												: "",
										errorYn:
											item.errorYn === "Y" ? "Y" : "N",
										alarmMsg: item.alarmMsg,
									});
								} else if (
									item.itemType === "3" &&
									(item.tmpYn === "Y" || item.hmdYn === "Y")
								) {
									tempDetail = [
										{
											type: "temp",
											maxVal: item.tmpMaxVal,
											minVal: item.tmpMinVal,
											current: item.current_temp,
											setting: item.setting_temp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "TMP" &&
														f.code == item.tempUnit
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"TMP" &&
															f.code ==
															item.tempUnit
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "damp",
											maxVal: item.hmdMaxVal,
											minVal: item.hmdMinVal,
											current: item.current_damp,
											setting: item.setting_damp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "DAMP" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"DAMP" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
									];
								} else if (
									item.itemType === "4" &&
									(item.tmpYn === "Y" ||
										item.hmdYn === "Y" ||
										item.co2Yn === "Y")
								) {
									tempDetail = [
										{
											type: "temp",
											maxVal: item.tmpMaxVal,
											minVal: item.tmpMinVal,
											current: item.current_temp,
											setting: item.setting_temp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "TMP" &&
														f.code == item.tempUnit
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"TMP" &&
															f.code ==
															item.tempUnit
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "damp",
											maxVal: item.hmdMaxVal,
											minVal: item.hmdMinVal,
											current: item.current_damp,
											setting: item.setting_damp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "DAMP" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"DAMP" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "co2",
											maxVal: item.co2MaxVal,
											minVal: item.co2MinVal,
											current: item.current_co2,
											setting: item.setting_co2,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "CO2" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"CO2" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
									];
								} else if (
									item.itemType === "5" &&
									(item.tmpYn === "Y" ||
										item.hmdYn === "Y" ||
										item.illYn === "Y" ||
										item.co2Yn === "Y")
								) {
									tempDetail = [
										{
											type: "temp",
											maxVal: item.tmpMaxVal,
											minVal: item.tmpMinVal,
											current: item.current_temp,
											setting: item.setting_temp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "TMP" &&
														f.code == item.tempUnit
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"TMP" &&
															f.code ==
															item.tempUnit
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "damp",
											maxVal: item.hmdMaxVal,
											minVal: item.hmdMinVal,
											current: item.current_damp,
											setting: item.setting_damp,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "DAMP" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"DAMP" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "inllum",
											maxVal: item.illMaxVal,
											minVal: item.illMinVal,
											current: item.current_illum,
											setting: item.setting_illum,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "ILLUM" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"ILLUM" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
										{
											type: "co2",
											maxVal: item.co2MaxVal,
											minVal: item.co2MinVal,
											current: item.current_co2,
											setting: item.setting_co2,
											unit:
												mesureSubUnitList.filter(
													(f) =>
														f.groupCd === "CO2" &&
														f.code == "0"
												).length > 0
													? mesureSubUnitList.filter(
														(f) =>
															f.groupCd ===
															"CO2" &&
															f.code == "0"
													)[0].val1
													: "",
											errorYn:
												item.errorYn === "Y"
													? "Y"
													: "N",
											alarmMsg: item.alarmMsg,
										},
									];
								} else if (item.itemType === "6") {
									// console.log("이상신호기");
								} else if (item.itemType === "7") {
									// console.log("중계기");
								}
							});
						}
						// console.log("init temp : ", temp.filter(
						// (f) => f.itemNo == location.state[0].itemNo
						// ));
						setControllerData(
							temp.filter(
								(f) => f.itemNo == location.state[0].itemNo
							)
						);
						setControllerDetail(tempDetail);
						GetChartData(
							temp.filter(
								(f) => f.itemNo == location.state[0].itemNo
							)[0].itemNo,
							temp.filter(
								(f) => f.itemNo == location.state[0].itemNo
							)[0].itemType
						);
					}
				});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 온도변화 차트 데이터 조회
	const GetChartData = async (id, itemType) => {
		setProgress(true);
		let userInfo = null;
		let tempCategories = [];
		let tempCurTmp = [];
		let tempCurHdm = [];
		let tempCurIll = [];
		let tempCurCo2 = [];
		let tempList = [];
		let tempItem = "1";
		let lans = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.LogManagement.LM10010.GetDeviceGraphData,
				{
					years: new Date().getFullYear(),
					userId: userInfo.userId,
					itemNo: id,
				}
			).then((result) => {
				let data = result.data;
				// console.log("chart data : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else if (data) {
					// Combo Item 제작
					switch (itemType) {
						case "1": {
							tempList.push({
								value: "1",
								name: "온도",
							});
							break;
						}
						case "2": {
							tempList.push({
								value: "2",
								name: "습도",
							});
							tempItem = "2";
							break;
						}
						case "3": {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								}
							);
							break;
						}
						case "4": {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								},
								{
									value: "3",
									name: "CO2",
								}
							);
							break;
						}
						case "5": {
							tempList.push(
								{
									value: "1",
									name: "온도",
								},
								{
									value: "2",
									name: "습도",
								},
								{
									value: "3",
									name: "CO2",
								},
								{
									value: "4",
									name: "조도",
								}
							);
							break;
						}
					}

					setItemTypeValue(tempItem);
					setItemTypeItems(tempList);

					// 중복을 제거한 CreateDate 목록 생성
					const dateList = Array.from(
						new Set(
							data.map((item) => item.CreateDate.slice(8, 12))
						)
					).sort((a, b) => a.localeCompare(b));

					// 각 타입별 결과를 저장할 배열 초기화
					dateList.map((date) => {
						const matchingItems = data.filter(
							(item) => item.CreateDate.slice(8, 12) === date
						)[0];

						switch (itemType) {
							case "1": {
								tempCurTmp.push(
									matchingItems.CurTmp === 999 || matchingItems.CurTmp === 9999 || matchingItems.CurTmp === 998
										? null
										: matchingItems.CurTmp
								);
								break;
							}
							case "2": {
								tempCurHdm.push(
									matchingItems.CurHdm === 999 || matchingItems.CurHdm === 9999 || matchingItems.CurHdm === 998
										? null
										: matchingItems.CurHdm
								);
								tempItem = "2";
								break;
							}
							case "3": {
								tempCurTmp.push(
									matchingItems.CurTmp === 999 || matchingItems.CurTmp === 9999 || matchingItems.CurTmp === 998
										? null
										: matchingItems.CurTmp
								);
								tempCurHdm.push(
									matchingItems.CurHdm === 999 || matchingItems.CurHdm === 9999 || matchingItems.CurHdm === 998
										? null
										: matchingItems.CurHdm
								);
								break;
							}
							case "4": {
								tempCurTmp.push(
									matchingItems.CurTmp === 999 || matchingItems.CurTmp === 9999 || matchingItems.CurTmp === 998
										? null
										: matchingItems.CurTmp
								);
								tempCurHdm.push(
									matchingItems.CurHdm === 999 || matchingItems.CurHdm === 9999 || matchingItems.CurHdm === 998
										? null
										: matchingItems.CurHdm
								);
								tempCurIll.push(
									matchingItems.CurIll === 999 || matchingItems.CurIll === 9999 || matchingItems.CurIll === 998
										? null
										: matchingItems.CurIll
								);
								break;
							}
							case "5": {
								tempCurTmp.push(
									matchingItems.CurTmp === 999 || matchingItems.CurTmp === 9999 || matchingItems.CurTmp === 998
										? null
										: matchingItems.CurTmp
								);
								tempCurHdm.push(
									matchingItems.CurHdm === 999 || matchingItems.CurHdm === 9999 || matchingItems.CurHdm === 998
										? null
										: matchingItems.CurHdm
								);
								tempCurIll.push(
									matchingItems.CurIll === 999 || matchingItems.CurIll === 9999 || matchingItems.CurIll === 998
										? null
										: matchingItems.CurIll
								);
								tempCurCo2.push(
									matchingItems.CurCo2 === 999 || matchingItems.CurCo2 === 9999 || matchingItems.CurCo2 === 998
										? null
										: matchingItems.CurCo2
								);
								break;
							}
						}
					});

					setCurTmp(tempCurTmp);
					setCurHdm(tempCurHdm);
					setCurIll(tempCurIll);
					setCurCo2(tempCurCo2);

					dateList.forEach((item) => {
						tempCategories.push(
							item.slice(0, 2) + ":" + item.slice(2, 4)
						);
					});

					switch (itemType) {
						case "1": {
							setChartData({
								categories: tempCategories,
								data: [
									{
										name: "온도",
										data: tempCurTmp,
									},
								],
								yaxis: [
									{
										seriesName: "온도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#4ecdc4"],

											}
										},
									},
								]
							});
							break;
						}
						case "2": {
							setChartData({
								categories: tempCategories,
								data: [
									{
										name: "습도",
										data: tempCurHdm,
									},
								],
								yaxis: [
									{
										seriesName: "습도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#4ecdc4"]
											}
										},
									},
								]
							});
							break;
						}
						case "3": {
							setChartData({
								categories: tempCategories,
								data: [
									{
										name: "온도",
										data: tempCurTmp,
									},
									{
										name: "습도",
										data: tempCurHdm,
									},
								],
								yaxis: [
									{
										seriesName: "온도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#4ecdc4"]
											}
										},
									},
									{
										seriesName: "습도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#c7f464"]
											}
										},
									},
								]
							});
							break;
						}
						case "4": {
							setChartData({
								categories: tempCategories,
								data: [
									{
										name: "온도",
										data: tempCurTmp,
									},
									{
										name: "습도",
										data: tempCurHdm,
									},
									{
										name: "조도",
										data: tempCurIll,
									},
								],
								yaxis: [
									{
										seriesName: "온도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#4ecdc4"]
											}
										},
									},
									{
										seriesName: "습도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#c7f464"]
											}
										},
									},
									{
										seriesName: "조도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#81d4fa"]
											}
										},
									},
								]
							});
							break;
						}
						case "5": {
							setChartData({
								categories: tempCategories,
								data: [
									{
										name: "온도",
										data: tempCurTmp,
									},
									{
										name: "습도",
										data: tempCurHdm,
									},
									{
										name: "조도",
										data: tempCurIll,
									},
									{
										name: "CO2",
										data: tempCurCo2,
									},
								],
								yaxis: [
									{
										seriesName: "온도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#4ecdc4"]
											}
										},
									},
									{
										seriesName: "습도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#c7f464"]
											}
										},
									},
									{
										seriesName: "조도",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#81d4fa"]
											}
										},
									},
									{
										seriesName: "CO2",
										axisTicks: {
											show: true
										},
										axisBorder: {
											show: true
										},
										labels: {
											style: {
												colors: ["#fd6a6a"]
											}
										},
									},
								]
							});
							break;
						}
					}
				} else {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_chart_view_failed"
							)[0].lanName
							: "")	// 차트 조회 실패
					);
				}
			});
		} catch (e) {
			// console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title:
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"dashboard_pop_alert_title"
							)[0].lanName
							: ""),	// Alert
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 장치 이름 변경
	const ChangeItemName = async () => {
		setProgress(true);
		let userInfo = null;

		try {
			if (!writeValue) {
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_enter_new_nm"
					)[0].lanName
					: "")	// 변경할 장치명을 입력하세요.
				);
			}

			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.ChangeMachName,
				{
					itemNo: location.state[0].itemNo,
					userId: userInfo.userId,
					machNm: writeValue,
				}
			).then((result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data !== "Ok") {
					throw new Error((lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_save_failed"
						)[0].lanName
						: ""),	// 저장 실패!
					);
				} else {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		(lanList.length > 0
					// 			? lanList.filter(
					// 				(f) =>
					// 					f.lanId ===
					// 					"snack_saved"
					// 			)[0].lanName
					// 			: ""),	// 저장 되었습니다!
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_saved"
								)[0].lanName
								: ""),	// 저장 되었습니다!
					});

					let temp = [...controllerData];
					temp[0].name = writeValue;
					setControllerData(temp);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 컨트롤러 상태 재조회
	const ReloadPage = () => {
		Init(false);
		// 재조회 버튼 클릭 시 시간 초기화
		setDeterminateLabel(
			Commons.Storages("get", "settings").reloadTime / 1000
		);
	};

	return (
		<div className="wrap">
			<DM10030View
				// 언어팩
				lanList={lanList}
				// 장치 이름 변경
				writeValue={writeValue}
				setWriteValue={setWriteValue}
				// 재조회 시간
				determinateLabel={determinateLabel}
				// 컨트롤러 데이터
				controllerData={controllerData}
				controllerDetail={controllerDetail}
				// 차트 데이터
				chartData={chartData}
				setChartData={setChartData}
				// 차트 콤보박스
				itemTypeValue={itemTypeValue}
				setItemTypeValue={setItemTypeValue}
				itemTypeItems={itemTypeItems}
				setItemTypeItems={setItemTypeItems}
				// itemType별 데이터
				curTmp={curTmp}
				curHdm={curHdm}
				curIll={curIll}
				curCo2={curCo2}
				// 함수
				GetChartData={GetChartData}
				ChangeItemName={ChangeItemName}
				ReloadPage={ReloadPage}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					dialog.logout && Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"dashboard_pop_confirm"
					)[0].lanName
					: ""	// 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default DM10030;

// let data = [
// 	{
// 		CreateDate: '20240223105000',
// 		ItemNo: 33,
// 		CurTmp: 19.6,
// 		CurHdm: 5.5,
// 		CurIll: 12.3,
// 		CurCo2: 55.3
// 	},
// 	{
// 		CreateDate: '20240223110000',
// 		ItemNo: 33,
// 		CurTmp: 20.0,
// 		CurHdm: 4.5,
// 		CurIll: 12,
// 		CurCo2: 56
// 	},
// 	{
// 		CreateDate: '20240223111000',
// 		ItemNo: 33,
// 		CurTmp: 25.4,
// 		CurHdm: 6.5,
// 		CurIll: 11.7,
// 		CurCo2: 57
// 	},
// 	{
// 		CreateDate: '20240223112000',
// 		ItemNo: 33,
// 		CurTmp: 24.7,
// 		CurHdm: 3.5,
// 		CurIll: 11.4,
// 		CurCo2: 58
// 	},
// 	{
// 		CreateDate: '20240223113000',
// 		ItemNo: 33,
// 		CurTmp: 12.5,
// 		CurHdm: 7.5,
// 		CurIll: 11.1,
// 		CurCo2: 59
// 	},
// 	{
// 		CreateDate: '20240223114000',
// 		ItemNo: 33,
// 		CurTmp: 20.6,
// 		CurHdm: 2.5,
// 		CurIll: 10.8,
// 		CurCo2: 60
// 	},
// 	{
// 		CreateDate: '20240223115000',
// 		ItemNo: 33,
// 		CurTmp: 25.5,
// 		CurHdm: 8.5,
// 		CurIll: 10.5,
// 		CurCo2: 61
// 	},
// 	{
// 		CreateDate: '20240223120000',
// 		ItemNo: 33,
// 		CurTmp: 27.2,
// 		CurHdm: 1.5,
// 		CurIll: 10.2,
// 		CurCo2: 62
// 	},
// 	{
// 		CreateDate: '20240223121000',
// 		ItemNo: 33,
// 		CurTmp: 30.9,
// 		CurHdm: 9.5,
// 		CurIll: 9.9,
// 		CurCo2: 63
// 	},
// 	{
// 		CreateDate: '20240223122000',
// 		ItemNo: 33,
// 		CurTmp: 20.1,
// 		CurHdm: 0.5,
// 		CurIll: 9.6,
// 		CurCo2: 64
// 	},
// 	{
// 		CreateDate: '20240223123000',
// 		ItemNo: 33,
// 		CurTmp: 15.4,
// 		CurHdm: 10.5,
// 		CurIll: 9.3,
// 		CurCo2: 65
// 	},
// ];
