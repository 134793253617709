import React from "react";
import { useNavigate } from "react-router-dom";
/** Service Import */
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components Import */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardHeader,
	CardContent,
	CardActionArea,
	IconButton,
	Typography,
	Divider,
} from "@mui/material";
/** Icons Import */
import {
	WifiTethering as LinkOnIcon,
	WifiTetheringError as LinkErrorIcon,
	PortableWifiOff as LinkOffIcon,
	ConstructionOutlined,
	Feed,
} from "@mui/icons-material";
/** Images Import */
import SettingIcon from "../../Assets/Images/Design/drawable-hdpi/main_setting_btn.png";
import StrengthNs from "../../Assets/Images/Strength/N_S.png";
import Strength0 from "../../Assets/Images/Strength/Strength0.png";
import Strength1 from "../../Assets/Images/Strength/Strength1.png";
import Strength2 from "../../Assets/Images/Strength/Strength2.png";
import Strength3 from "../../Assets/Images/Strength/Strength3.png";

const CardContentItems = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
}));
const CardContentItem = styled((props) => <div {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "50%",
}));
const CardContentItemUnit = styled((props) => <div {...props} />)(
	({ theme }) => ({
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	})
);

function HomeLists({
	items = [], // HomeLists's item list
	mesureUnits = [],
	setAddController = () => { },
}) {
	const navigate = useNavigate();

	const ReturnData = items.filter((f) => f.screenYn === "Y").map((item, index) => {
		// 테두리 색 표시하는 함수
		const ReturnBgColor = () => {
			if (item.itemType === "7") {
				return "normal-box normal-in";
			} else if (item.errorYn === "Y") {
				return "error-box error-in";
			} else {
				return "normal-box normal-in";
			}
		};

		// 가동 유무(시스템 상태(00007)) 표시하는 함수
		const ReturnIcons = () => {
			// 안테나 표시
			switch (item.operation) {
				case "1":
					if (item.errorYn === "Y" && item.alarmMsg === "미접속") {
						return (
							// <LinkErrorIcon fontSize="large" />
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={StrengthNs}
							/>
						);
					} else if (item.strength === "0") {
						return (
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={Strength0}
							/>
						);
					} else if (item.strength === "1") {
						return (
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={Strength1}
							/>
						);
					} else if (item.strength === "2") {
						return (
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={Strength2}
							/>
						);
					} else {
						return (
							// <LinkOnIcon
							// 	sx={{ color: "#474747" }}
							// 	fontSize="large"
							// />
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={Strength3}
							/>
						);
					}
				default:
					if (item.errorYn === "Y" && item.alarmMsg === "미접속") {
						return (
							// <LinkErrorIcon fontSize="large" />
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={StrengthNs}
							/>
						);
					} else if (item.strength === "0") {
						return (
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={Strength0}
							/>
						);
					} else if (item.strength === "1") {
						return (
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={Strength1}
							/>
						);
					} else if (item.strength === "2") {
						return (
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={Strength2}
							/>
						);
					} else {
						return (
							// <LinkOnIcon
							// 	sx={{ color: "green" }}
							// 	fontSize="large"
							// />
							<img
								style={{ width: "35px", heigth: "35px" }}
								src={Strength3}
							/>
						);
					}
			}
		};

		// 현재값 및 단위 표시하는 함수
		/** 
		 * 홈 화면에서 계측값이나 에러 메시지가 뜨지 않는다면, 
		 * 컨트롤러 등록에서 품목 타입에 맞는 사용유무를 'Y'로 설정했는지 확인할 것! 
		*/
		const ReturnUnit = () => {
			let alarm = "";
			let temp = [];
			let storeTemp = [];
			let sortingTemp = [];

			if (item.tmpYn === "Y") {
				temp.push({
					value: item.current_temp,
					units: mesureUnits.filter((f) => f.groupCd === "TMP"),
					currentUnit: item.tempUnit,
				});
			}
			if (item.illYn === "Y") {
				temp.push({
					value: item.current_illum,
					units: mesureUnits.filter((f) => f.groupCd === "ILLUM"),
					currentUnit: "0",
				});
			}
			if (item.hmdYn === "Y") {
				temp.push({
					value: item.current_damp,
					units: mesureUnits.filter((f) => f.groupCd === "DAMP"),
					currentUnit: "0",
				});
			}
			if (item.co2Yn === "Y") {
				temp.push({
					value: item.current_co2,
					units: mesureUnits.filter((f) => f.groupCd === "CO2"),
					currentUnit: "0",
				});
			}
			if (item.itemType === "7") {
				temp.push({
					value: "중계기",
					units: "",
					currentUnit: "0",
				});
			}

			temp.forEach((tempItem, tempIndex) => {
				if (
					(tempIndex + 1) % 2 === 0 ||
					tempIndex + 1 === temp.length
				) {
					storeTemp.push(tempItem);
					sortingTemp.push(storeTemp);
					storeTemp = [];
				} else {
					storeTemp.push(tempItem);
				}
			});

			return sortingTemp.map((sortingUnitItem, sortingUnitIndex) => {
				return (
					<CardContentItems
						key={sortingUnitIndex}
						style={{
							margin: sortingTemp.length > 2 ? "8px 0px" : "",
						}}
					>
						{item.errorYn === "Y" ? (
							<CardContentItem>
								<Typography
									fontWeight="bold"
									fontFamily="digital-num"
									variant="h3"
									color="red"
								>
									{/* {item.alarmMsg !== "" && item.alarmMsg !== "미접속" ? item.alarmMsg : "N-S"} */}
									{item.itemType === "7"
										? "중계기"
										: item.alarmMsg !== "" &&
											item.alarmMsg !== "미접속"
											? item.alarmMsg
											: "N-S"}
									{/* N-S */}
								</Typography>
							</CardContentItem>
						) : (
							sortingUnitItem.map((unitItem, unitIndex) => {
								return (
									<CardContentItem key={unitIndex}>
										<Typography
											fontWeight="bold"
											fontFamily="digital-num"
											variant="h3"
										// color={item.operation === "1" ? "red" : null}
										>
											{item.operation === "1" ? "OFF" : unitItem.value}
										</Typography>
										{item.operation === "1" ? null : (<CardContentItemUnit>
											{unitItem.units.length > 0 &&
												unitItem.units.map(
													(lastItem, lastIndex) => {
														return (
															<Typography
																key={lastIndex}
																sx={{
																	color:
																		unitItem.currentUnit ==
																			lastIndex
																			? "#FFFFFF"
																			: "#474747",
																}}
																fontFamily="digital-num"
															>
																{lastItem.val1}
															</Typography>
														);
													}
												)}
										</CardContentItemUnit>)}
									</CardContentItem>
								);
							})
						)}
					</CardContentItems>
				);
			});
		};

		return (
			<Grid key={index} xs={12} md={4}>
				<Card className={ReturnBgColor()}>
					<CardHeader
						sx={{ py: 1 }}
						title={item.machNm}
						titleTypographyProps={{
							variant: "h6",
							fontWeight: "bold",
						}}
						action={
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								{ReturnIcons()}
								<IconButton
									sx={{ ml: 1 }}
									onClick={() => {
										setAddController({
											open: true,
											disabled: true, // 맥 주소, 장치 설정, 연결 중계기 수정 가능 여부
											disabledNoShow: false, // 화면표시 미사용 콤보박스 사용 X
											itemIdx: item.itemIdx, // 장치 설정
											itemNo: item.itemNo, // 장치 번호
											machNm: item.machNm, // 장치명
											macAdd: item.macAdd, // 맥 주소
											useMac: true, // 맥 주소 사용 가능 여부
											machId: item.machId, // 국번
											useMachId: true,	// 국번 수정 가능 여부 (false면 수정 불가능, true면 가능)
											joinRtNo:
												item.joinRtNo === null
													? ""
													: item.joinRtNo, // 연결 중계기 번호
											screenYn: item.screenYn, // 화면 표시 유무
											address1: item.address1, // 주소
										});
									}}
								>
									<Feed
										style={{
											width: "28px",
											height: "28px",
										}}
									/>
								</IconButton>
								{item.amountYn === "Y" ? (
									<IconButton
										sx={{ ml: 1 }}
										onClick={() => {
											if (
												item.itemType === "7" ||
												(item.errorYn === "Y" &&
													item.alarmMsg === "미접속")
											) {
												return false;
											} else {
												navigate(
													"/SettingManagement/SM10000",
													{
														state: { ...item },
													}
												);
											}
										}}
									>
										<img
											style={{
												width: "28px",
												height: "28px",
											}}
											src={SettingIcon}
										/>
									</IconButton>
								) : null}
							</div>
						}
					/>
					<CardActionArea
						onClick={() => {
							if (
								item.itemType === "7" ||
								(item.errorYn === "Y" &&
									item.alarmMsg === "미접속")
							) {
								return false;
							} else {
								navigate("/DashBoardManagement/DM10000", {
									state: { ...item },
								});
							}
						}}
					>
						<CardContent
							sx={{
								border: "1px solid #388DFC",
								bgcolor: "#000000",
							}}
						>
							{ReturnUnit()}
						</CardContent>
					</CardActionArea>
				</Card>
			</Grid>
		);
	});

	return ReturnData;
}

export default HomeLists;
