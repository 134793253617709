import React from "react";
/** Service Import */
import Commons from "../../Services/Common/Common";
/** Styles Import */
import { styled } from "@mui/material/styles";
/** Components */
import {
	Unstable_Grid2 as Grid,
	Card,
	CardHeader,
	CardContent as MuiCardContent,
	List,
	ListItem as MuiListItem,
	ListItemText,
	Avatar,
	Typography,
} from "@mui/material";
import { Combo } from "../../Components/Combos";
/** Icons Import */

const CardContent = styled((props) => <MuiCardContent {...props} />)(
	({ theme }) => ({
		// display: "flex",
		// justifyContent: "space-between",
		// alignItems: "center",
	})
);

const ListItem = styled((props) => <MuiListItem {...props} />)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
}));

function SystemSetting(props) {
	return (
		<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
			<Grid xs={4} md={12}>
				<Card sx={{ width: "100%" }}>
					<CardHeader
						title={
							props.lanList.length > 0
								? props.lanList.filter(
									(f) => f.lanId === "systemsetting"
								)[0].lanName
								: ""
						} // 시스템 설정
						titleTypographyProps={{
							variant: Commons.IsMobile() ? "body1" : "h5",
							fontWeight: "bold",
							textAlign: "center",
						}}
					/>
					<CardHeader
						sx={{ py: 0 }}
						avatar={<Avatar />}
						title={props.inputs.userName}
						titleTypographyProps={{
							variant: Commons.IsMobile() ? "subtitle1" : "h6",
							fontWeight: "bold",
						}}
						subheader={props.inputs.userId}
						subheaderTypographyProps={{
							variant: "subtitle2",
						}}
					/>
					<CardContent>
						<Typography
							variant={
								props.inputs.amountYn === "Y"
									? "subtitle1"
									: "subtitle2"
							}
						>
							{props.inputs.amountYn === "Y"
								? (props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"settings_gen_amount_message_1"
									)[0].lanName
									: "") +
								props.inputs.authDate +
								(props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"settings_gen_amount_message_2"
									)[0].lanName
									: "")
								: props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"settings_gen_no_amount_message"
									)[0].lanName
									: ""}
							{/* // 유료기간 사용은 ~ 까지입니다. or 유료가입을 하면 더 많은 서비스를 제공합니다. */}
						</Typography>
					</CardContent>
				</Card>
				<List>
					<ListItem>
						<ListItemText
							sx={{ width: "55%" }}
							primary={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"systemsetting_gen_reload_time"
									)[0].lanName
									: ""
							} // 재조회 시간
						/>
						<Combo
							sx={{ width: "200px" }}
							name=""
							items={props.comboList}
							value={props.inputs.reloadTime}
							onChange={(e) => {
								props.setInputs({
									...props.inputs,
									reloadTime: e,
								});
								props.SaveReloadTime(e);
							}}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							sx={{ width: "55%" }}
							primary={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"systemsetting_gen_log_time"
									)[0].lanName
									: ""
							} // 로그 저장 시간
						/>
						{props.inputs.logTime !== 0 ?
							(
								<Combo
									sx={{ width: "200px" }}
									name=""
									items={props.comboList1}
									value={props.inputs.logTime}
									onChange={(e) => {
										props.setInputs({
											...props.inputs,
											logTime: e,
										});
										props.SaveLogTime(e);
									}}
								/>
							) : (
								<Typography
									variant={"subtitle1"}
								>
									{props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"systemsetting_gen_reload_time_null"
										)[0].lanName
										: ""}
								</Typography>
							)
						}
					</ListItem>
					<ListItem>
						<ListItemText
							sx={{ width: "55%" }}
							primary={
								props.lanList.length > 0
									? props.lanList.filter(
										(f) =>
											f.lanId ===
											"systemsetting_gen_lan_change"
									)[0].lanName
									: ""
							} // 언어 설정
						/>
						<Combo
							sx={{ width: "200px" }}
							name=""
							items={props.comboList2}
							value={props.inputs.lanCode}
							onChange={(e) => {
								props.setInputs({
									...props.inputs,
									lanCode: e,
								});
								props.ChangeLanguage(e);
							}}
						/>
					</ListItem>
				</List>
			</Grid>
		</Grid>
	);
}

export default SystemSetting;
