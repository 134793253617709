import Slick from 'react-slick'; // react-slick 사용을 위해 import
import { FileForm } from '../FileForm';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function BasicSlick({
	items = [],
}) {
	const settings = {
		// dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};

	return (
		<Slick {...settings}>
			{items.map((item, index) => {
				return (
					<img
						key={index}
						src={item}
						style={{
							width: "100%",
							height: "100%"
						}}
					/>
				);
			})}
		</Slick>
	);
}

export default BasicSlick;