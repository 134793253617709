import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Styles Import */
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components Import */
import BM10010View from "../../Views/BaseManagements/BM10010";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";
/** Images Import */
/** Icons Import */

/** 사용자 등록 */
function BM10010(props) {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [rowData, setRowData] = React.useState([]); // 그리드에 보여주는 데이터
	const [selectedRowData, setSelectedRowData] = React.useState([]); // 선택된 행을 저장함
	const [changedRowData, setChangedRowData] = React.useState([]); // 수정된 행을 저장함
	const [checkings, setCheckings] = React.useState({
		// 데이터의 중복을 체크하는 변수(해당 테이블의 )
		userId: "",
	});
	const [authGroupData, setAuthGroupData] = React.useState([]); // 사용자 권한그룹 데이터
	const [codeList, setCodeList] = React.useState([]); // Code list
	const [useYnList, setUseYnList] = React.useState([]); // UseYn code list
	const [lanCodeList, setLanCodeList] = React.useState([]); // language code list (사용 언어 선택 콤보박스)
	const [lanList, setLanList] = React.useState([]); // language list
	const [userLanCode, SetUserLanCode] = React.useState("kr");	// 사용자 설정 언어코드. 기본 한국어

	React.useEffect(() => {
		GetAuthGroup();
	}, []);

	React.useMemo(() => {
		IndexedDb.GetLoginInfo().then((result) => {
			SetUserLanCode(result.lanCode);
		});
	}, []);

	// 권한 그룹 데이터 조회
	const GetAuthGroup = async () => {
		setProgress(true);
		let codes = [];
		let useYns = [];
		let lanCodes = [];
		let lans = [];
		let search = true;

		try {
			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			await IndexedDb.GetCodeAll().then((result) => {
				if (result.length > 0) {
					setCodeList(result);
					result.forEach((item, index) => {
						if (item.groupCd === "USE_YN") {
							useYns.push(item.name);
						}
						if (item.groupCd === "LAN_CODE") {
							lanCodes.push(item.name);
						}
						codes.push(item);
					});
				}
			});

			// 해당 페이지의 권한 확인
			await IndexedDb.GetAllDb("AuthList").then((result) => {
				search = result.filter((f) => f.mcategoCd === "BM10010").length > 0;
			});
			search && await Commons.Axios(
				"get",
				Global.base.useUrl +
				Global.urls.BaseManagement.BM10010.GetAuthGroup
			).then(async (result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setAuthGroupData([]);
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else if (data.length > 0) {
					setAuthGroupData(data);
					setUseYnList(useYns);
					setLanCodeList(lanCodes);
					await Init(data, codes, lans);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				setSnacks({
					...snacks,
					open: true,
					type: "error",
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	//그리드 데이터 조회
	const Init = async (authData, codes, lans = lanList) => {
		setProgress(true);
		let temp = [];
		let lanCode = "";

		try {
			// 사용자 언어코드 확인
			await IndexedDb.GetLoginInfo().then((result) => {
				lanCode = result.lanCode;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10010.GetCode
			).then((result) => {
				let data = result.data;
				// console.log("data : ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setRowData([]);
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else if (data.length > 0) {
					data.forEach((item, index) => {
						temp.push({
							userId: item.userId,
							userNm: item.userNm,
							postNo: item.postNo,
							address1: item.address1,
							address2: item.address2,
							countryCode:
								(codes.filter(
									(f) =>
										f.groupCd === "COUNTRY_CODE" &&
										f.code === item.countryCode
								)[0].name),
							tel: item.tel === null ? "" : item.tel,
							useYn:
								lanCode === "en" ?
									(codes.filter(
										(f) =>
											f.groupCd === "USE_YN" &&
											f.code === item.useYn
									)[0].ref3) :
									(codes.filter(
										(f) =>
											f.groupCd === "USE_YN" &&
											f.code === item.useYn
									)[0].name),
							privacyYn: item.privacyYn === null || item.privacyYn === "" ?
								(lanCode === "en" ?
									(codes.filter(
										(f) =>
											f.groupCd === "CONSENT_YN" &&
											f.code === "N"
									)[0].ref3) :
									(codes.filter(
										(f) =>
											f.groupCd === "CONSENT_YN" &&
											f.code === "N"
									)[0].name)) :
								(lanCode === "en" ?
									(codes.filter(
										(f) =>
											f.groupCd === "CONSENT_YN" &&
											f.code === item.privacyYn
									)[0].ref3) :
									(codes.filter(
										(f) =>
											f.groupCd === "CONSENT_YN" &&
											f.code === item.privacyYn
									)[0].name)),
							useSdate: Commons.StringtoDate(item.useSdate),
							useEdate: Commons.StringtoDate(item.useEdate),
							useAmt: item.useAmt,
							authGroupCd: lanCode === "en" ?
								(authData.filter(
									(f) =>
										f.AuthGroupCd === item.authGroupCd
								)[0].AuthGroupNameEng) :
								authData.filter(
									(f) =>
										f.AuthGroupCd === item.authGroupCd
								)[0].AuthGroupNm,
							lanCode: lanCode === "en" ?
								(codes.filter(
									(f) =>
										f.groupCd === "LAN_CODE" &&
										f.code === item.lanCode
								)[0].ref3) :
								(codes.filter(
									(f) =>
										f.groupCd === "LAN_CODE" &&
										f.code === item.lanCode
								)[0].name),
							createId: item.createId,
							createDate: Commons.DateFormating(
								Commons.StringtoDate(item.createDate, 3)
							),
							updateId: item.updateId,
							updateDate: Commons.DateFormating(
								Commons.StringtoDate(item.updateDate, 3)
							),
						});
					});
					setRowData(temp);
				}
			});
		} catch (e) {
			console.log(e);
			if (e.response === undefined) {
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: e.message,
				// });
				setSnackDialog({
					open: true,
					title:
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_error_title"
							)[0].lanName
							: "",	// 에러!
					message: e.message,
				});
			} else if (e.response.status === 401) {
				setDialog({
					...dialog,
					open: true,
					title: "Alert",
					children: (
						<>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_information_expired"
									)[0].lanName
									: ""	// 로그인 정보가 만료되었습니다.
								}
							</p>
							<p>
								{lans.length > 0
									? lans.filter(
										(f) =>
											f.lanId ===
											"snack_login_again"
									)[0].lanName
									: ""	// 다시 로그인 해 주세요.
								}
							</p>
						</>
					),
				});
			}
		} finally {
			setProgress(false);
		}
	};

	// 중복 데이터 검사
	const CheckingLogic = async (_gridref, id) => {
		setProgress(true);
		try {
			if (!checkings.userId) {
				// 1. 아이디가 없음
				// setSnacks({
				// 	...snacks,
				// 	open: true,
				// 	type: "error",
				// 	message: (lanList.length > 0
				// 		? lanList.filter(
				// 			(f) =>
				// 				f.lanId ===
				// 				"snack_enter_id"
				// 		)[0].lanName
				// 		: ""),	// 아이디를 입력하세요.
				// });
				// return false;
				throw new Error(
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_enter_id"
						)[0].lanName
						: "",	// 아이디를 입력하세요.
				);
			} else {
				await Commons.Axios(
					"get",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10010.CheckCode,
					{
						userId: id,
					}
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "데이터가 없습니다.") {
						throw new Error(
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_unavailable_data"
								)[0].lanName
								: "")	// 데이터가 없습니다.
						);
					} else if (data) {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_available_id"
						// 		)[0].lanName
						// 		: ""),	// 사용 가능한 아이디입니다
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_available_id"
								)[0].lanName
								: ""),	// 사용 가능한 아이디입니다
						});

						// console.log("test : ", authGroupData.filter(
						// 	(item) => item.AuthGroupCd === "USER"
						// )[0].AuthGroupNm);

						let currentData = [
							{
								useSdate: new Date(),
								userId: id,
								useEdate: new Date(),
								useAmt: 0,
								authGroupCd: userLanCode === "en" ?
									(authGroupData.filter(
										(item) => item.AuthGroupCd === "USER"
									)[0].AuthGroupNameEng) :
									(authGroupData.filter(
										(item) => item.AuthGroupCd === "USER"
									)[0].AuthGroupNm),
								lanCode: userLanCode === "en" ?
									(codeList.filter((item) =>
										item.groupCd === "LAN_CODE" &&
										item.code === "kr"
									)[0].ref3) :
									(codeList.filter((item) =>
										item.groupCd === "LAN_CODE" &&
										item.code === "kr"
									)[0].name),
								privacyYn: userLanCode === "en" ?
									(codeList.filter((item) =>
										item.groupCd === "CONSENT_YN" &&
										item.code === "N"
									)[0].ref3) :
									(codeList.filter((item) =>
										item.groupCd === "CONSENT_YN" &&
										item.code === "N"
									)[0].name),
								useYn: userLanCode === "en" ?
									(codeList.filter((item) =>
										item.groupCd === "USE_YN" &&
										item.code === "Y"
									)[0].ref3) :
									(codeList.filter((item) =>
										item.groupCd === "USE_YN" &&
										item.code === "Y"
									)[0].name),
								newYn: "Y",
								createDate: Commons.DateFormating(new Date()),
								updateDate: Commons.DateFormating(new Date()),
							},
							...rowData,
						];
						_gridref.current.api.setRowData(currentData);
						setRowData(currentData);
						setChangedRowData([
							...changedRowData,
							{
								useSdate: new Date(),
								userId: id,
								useEdate: new Date(),
								useAmt: 0,
								authGroupCd: userLanCode === "en" ?
									(authGroupData.filter(
										(item) => item.AuthGroupCd === "USER"
									)[0].AuthGroupNameEng) :
									(authGroupData.filter(
										(item) => item.AuthGroupCd === "USER"
									)[0].AuthGroupNm),
								lanCode: userLanCode === "en" ?
									(codeList.filter((item) =>
										item.groupCd === "LAN_CODE" &&
										item.code === "kr"
									)[0].ref3) :
									(codeList.filter((item) =>
										item.groupCd === "LAN_CODE" &&
										item.code === "kr"
									)[0].name),
								privacyYn: userLanCode === "en" ?
									(codeList.filter((item) =>
										item.groupCd === "CONSENT_YN" &&
										item.code === "N"
									)[0].ref3) :
									(codeList.filter((item) =>
										item.groupCd === "CONSENT_YN" &&
										item.code === "N"
									)[0].name),
								useYn: userLanCode === "en" ?
									(codeList.filter((item) =>
										item.groupCd === "USE_YN" &&
										item.code === "Y"
									)[0].ref3) :
									(codeList.filter((item) =>
										item.groupCd === "USE_YN" &&
										item.code === "Y"
									)[0].name),
								newYn: "Y",
								createDate: Commons.DateFormating(new Date()),
								updateDate: Commons.DateFormating(new Date()),
							},
						]);
						setCheckings({
							...props.checkings,
							userId: "",
						});
						dispatch(DrawerActions.setNotOpen()); // 열림 방지
						return true;
					} else {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_exists_id"
							)[0].lanName
							: ""),	// 이미 존재하는 아이디입니다.
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// 키 체크의 사용자 입력값 초기화
			setCheckings({
				userId: "",
			});

			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 그리드 데이터 저장
	const CreateLogic = async () => {
		setProgress(true);
		let userInfo = null;
		let changedArr = [];
		let temp = [];

		// 전화번호 형식 체크
		let regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
		let telCheck = [...changedRowData]
			.reverse()
			.reduce((prev, now) => {
				if (!prev.some((obj) => obj.userId === now.userId)) {
					prev.push(now);
				}
				return prev;
			}, [])
			.filter((f) => {
				if (f.tel === undefined || f.tel === "" || !regex.test(f.tel))
					return f;
			});

		// 국가 전화번호 코드 선택
		let countryCodeCheck = [...changedRowData]
			.reverse()
			.reduce((prev, now) => {
				if (!prev.some((obj) => obj.userId === now.userId)) {
					prev.push(now);
				}
				return prev;
			}, [])
			.filter((f) => {
				if (f.countryCode === undefined || f.countryCode === "")
					return f;
			});

		try {
			if (changedRowData.length === 0) {
				// 변경된 행이 없으면 에러
			} else if (telCheck.length > 0) {
				// 전화번호 형식이 틀리면 에러

				// throw new Error(
				// 	(lanList.length > 0
				// 		? lanList.filter(
				// 			(f) =>
				// 				f.lanId ===
				// 				"snack_enter_user_nm_1"
				// 		)[0].lanName
				// 		: "") +	// '
				// 	item.userId +
				// 	(lanList.length > 0
				// 		? lanList.filter(
				// 			(f) =>
				// 				f.lanId ===
				// 				"snack_enter_user_nm_2"
				// 		)[0].lanName
				// 		: "")	// 의 사용자 이름을 입력해 주세요
				// );

				// console.log("telCheck : ", telCheck);
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_tel_format"
						)[0].lanName
						: "")	// 전화번호 형식을 바르게 입력해 주세요
				);
			} else if (countryCodeCheck.length > 0) {
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_country_code"
						)[0].lanName
						: "")	// 국가 전화번호 코드를 선택해 주세요.
				);
			} else {
				await IndexedDb.GetLoginInfo().then((result) => {
					userInfo = result;
				});

				// changedRowData 중복 제거
				temp = [...changedRowData].reverse().reduce((prev, now) => {
					if (!prev.some((obj) => obj.userId === now.userId)) {
						prev.push(now);
					}
					return prev;
				}, []);

				// 사용자 이름 있는지 확인
				temp.forEach((item) => {
					if (
						item.userNm === "" ||
						item.userNm === null ||
						item.userNm === undefined
					) {
						// console.log("error : ", item);
						throw new Error(
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_enter_user_nm_1"
								)[0].lanName
								: "") +	// '
							item.userId +
							(lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_enter_user_nm_2"
								)[0].lanName
								: "")	// 의 사용자 이름을 입력해 주세요
						);
					}
				});

				temp.forEach((item, index) => {
					changedArr.push({
						member: {
							userId: item.userId,
							userNm: item.userNm,
							password: "",
							eMail: item.userId,
							postNo: item.postNo,
							address1: item.address1,
							address2: item.address2,
							countryCode:
								(codeList.filter(
									(f) =>
										f.groupCd === "COUNTRY_CODE" &&
										(f.name === item.countryCode ||
											f.ref3 === item.countryCode)
								)[0].code),
							tel: (item.tel === null || item.tel === "") ?
								""
								: item.tel.length === 11 ?
									item.tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
									: item.tel.length === 13 ?
										item.tel
										: "",
							useYn: codeList.filter(
								(f) =>
									f.groupCd === "USE_YN" &&
									(f.name === item.useYn ||
										f.ref3 === item.useYn)
							)[0].code,
							privacyYn: codeList.filter(
								(f) =>
									f.groupCd === "CONSENT_YN" &&
									(f.name === item.privacyYn ||
										f.ref3 === item.privacyYn)
							)[0].code,
							lanCode: codeList.filter(
								(f) =>
									f.groupCd === "LAN_CODE" &&
									(f.name === item.lanCode ||
										f.ref3 === item.lanCode)
							)[0].code,
							createDate: Commons.DateFormating(new Date(), 5),
							createId: userInfo.userId,
							updateDate: Commons.DateFormating(new Date(), 5),
							updateId: userInfo.userId,
						},
						memberHis: {
							userId: item.userId,
							rcnt: 0,
							useSdate:
								item.useSdate === undefined ||
									item.useSdate === ""
									? Commons.DateToString(new Date())
									: Commons.DateToString(item.useSdate),
							useEdate:
								item.useEdate === undefined ||
									item.useEdate === ""
									? Commons.DateToString(new Date())
									: Commons.DateToString(item.useEdate),
							useAmt: item.useAmt,
							createDate: Commons.DateFormating(new Date(), 5),
							createId: userInfo.userId,
							updateDate: Commons.DateFormating(new Date(), 5),
							updateId: userInfo.userId,
						},
						groupMember: {
							userId: item.userId,
							authGroupCd: authGroupData.filter(
								(f) => f.AuthGroupNameEng === item.authGroupCd ||
									f.AuthGroupNm === item.authGroupCd
							)[0].AuthGroupCd,
							createDate: Commons.DateFormating(new Date(), 5),
							createId: userInfo.userId,
							updateDate: Commons.DateFormating(new Date(), 5),
							updateId: userInfo.userId,
						},
					});
				});

				await Commons.Axios(
					"post1",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10010.Create,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_saved"
						// 		)[0].lanName
						// 		: ""),	// 저장 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_saved"
								)[0].lanName
								: ""),	// 저장 되었습니다!
						});
						setSelectedRowData([]);
						setChangedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init(authGroupData, codeList);
					} else {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_user_registration_failed"
							)[0].lanName
							: ""),	// 사용자 등록 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 그리드 데이터 삭제
	const DeleteLogic = async () => {
		setProgress(true);
		let changedArr = [];
		let userInfo = null;
		let tempNewYn = selectedRowData.filter(
			(item) => item.newYn !== undefined && item.newYn === "Y"
		); // 저장되지 않은 데이터 (newYn) 확인

		try {
			await IndexedDb.GetLoginInfo().then(
				(result) => (userInfo = result)
			);

			if (selectedRowData.length === 0) {
				// 선택된 행이 없으면 에러
				// throw new Error("선택된 데이터가 없습니다.");
			} else if (
				selectedRowData.filter(
					(item) => item.userId === userInfo.userId
				).length > 0
			) {
				// 자신의 계졍 삭제시 에러
				throw new Error((lanList.length > 0
					? lanList.filter(
						(f) =>
							f.lanId ===
							"snack_cannot_unused"
					)[0].lanName
					: ""),	// 자신의 계정은 미사용 처리 할 수 없습니다.
				);
			} else if (tempNewYn.length !== 0) {
				// 저장되지 않은 데이터 삭제시 에러
				throw new Error(
					(lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_cannot_unstored"
						)[0].lanName
						: ""),	// 저장되지 않은 데이터는 미사용 처리 할 수 없습니다.
				);
			} else {
				selectedRowData.forEach((item, index) => {
					changedArr.push({
						authGroupCd: authGroupData.filter(
							(f) => f.AuthGroupNameEng === item.authGroupCd ||
								f.AuthGroupNm === item.authGroupCd
						)[0].AuthGroupCd,
						userId: item.userId,
						userNm: item.userNm,
						password: "",
						eMail: item.userId,
						postNo: item.postNo,
						address1: item.address1,
						address2: item.address2,
						countryCode:
							(codeList.filter(
								(f) =>
									f.groupCd === "COUNTRY_CODE" &&
									(f.name === item.countryCode ||
										f.ref3 === item.countryCode)
							)[0].code),
						useYn: codeList.filter(
							(f) =>
								f.groupCd === "USE_YN" &&
								(f.name === item.useYn ||
									f.ref3 === item.useYn)
						)[0].code,
						privacyYn: codeList.filter(
							(f) =>
								f.groupCd === "CONSENT_YN" &&
								(f.name === item.privacyYn ||
									f.ref3 === item.privacyYn)
						)[0].code,
						lanCode: codeList.filter(
							(f) =>
								f.groupCd === "LAN_CODE" &&
								(f.name === item.lanCode ||
									f.ref3 === item.lanCode)
						)[0].code,
						createDate: Commons.DateFormating(new Date(), 5),
						createId: item.createId,
						updateDate: Commons.DateFormating(new Date(), 5),
						updateId: item.updateId,
					});
				});
				await Commons.Axios(
					"post",
					Global.base.useUrl +
					Global.urls.BaseManagement.BM10010.Delete,
					changedArr
				).then((result) => {
					let data = result.data;

					if (data.type === "Error") {
						throw new Error(data.message);
					} else if (data === "Ok") {
						// setSnacks({
						// 	open: true,
						// 	type: "success",
						// 	message: (lanList.length > 0
						// 		? lanList.filter(
						// 			(f) =>
						// 				f.lanId ===
						// 				"snack_unused"
						// 		)[0].lanName
						// 		: ""),	// 미사용 처리 되었습니다!
						// });
						setSnackDialog({
							open: true,
							title:
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_success_title"
									)[0].lanName
									: "",	// 성공
							message: (lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_unused"
								)[0].lanName
								: ""),	// 미사용 처리 되었습니다!
						});
						setSelectedRowData([]);
						setChangedRowData([]);
						dispatch(DrawerActions.setUseOpen()); // 열림 허용
						Init(authGroupData, codeList);
					} else {
						throw new Error((lanList.length > 0
							? lanList.filter(
								(f) =>
									f.lanId ===
									"snack_unused_failed"
							)[0].lanName
							: "")	// 미사용 처리 실패!
						);
					}
				});
			}
		} catch (e) {
			console.log(e);
			// setSnacks({
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<>
			<BM10010View
				// 언어팩
				lanList={lanList}
				userLanCode={userLanCode}
				// 코드 리스트
				codeList={codeList}
				// 그리드 데이터
				rowData={rowData}
				setRowData={setRowData}
				selectedRowData={selectedRowData}
				setSelectedRowData={setSelectedRowData}
				changedRowData={changedRowData}
				setChangedRowData={setChangedRowData}
				checkings={checkings}
				setCheckings={setCheckings}
				authGroupData={authGroupData}
				useYnList={useYnList}
				lanCodeList={lanCodeList}
				// function
				setSnacks={setSnacks}
				CheckingLogic={CheckingLogic}
				CreateLogic={CreateLogic}
				DeleteLogic={DeleteLogic}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "bm10010_pop_confirm"
						)[0].lanName
						: "" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</>
	);
}

export default BM10010;
