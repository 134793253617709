import React from "react";
/** Components Import */
import { Backdrop as MuiBackDrop, CircularProgress } from "@mui/material";

function Loading({
	sx = { color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }, // mui inline-style object
	value = false, // back-drop value
	onClick = () => {}, // back-drop click event function
	invisible = false, // true, false
	children = <CircularProgress color="inherit" />, // your component in back-drop
}) {
	const LoadDropClicked = () => {
		onClick();
	};

	return (
		<MuiBackDrop
			sx={sx}
			open={value}
			onClick={LoadDropClicked}
			invisible={invisible}
		>
			{children}
		</MuiBackDrop>
	);
}

export default Loading;
