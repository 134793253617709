import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
import dayjs from "dayjs";
/** Reducers Import */
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Components */
import SM10010View from "../../Views/SettingManagements/SM10010";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { Dialog } from "../../Components/Dialog";
import { SnackDialog } from "../../Components/Dialog";

/** 설정 선택 */

function SM10010(props) {
	const navigate = useNavigate(); // 화면 이동을 위한 Navigate 저장
	const location = useLocation(); // 화면간 데이터를 전달받음.
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [dialog, setDialog] = React.useState({
		open: false,
		title: "",
		children: "",
		logout: false,
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [listData, setListData] = React.useState([]); // 컨트롤러들의 현재 값 데이터들
	const [controllerData, setControllerData] = React.useState([]); // 선택된 컨트롤러 데이터
	const [mapList, setMapList] = React.useState([]); // 선택된 컨트롤러의 TB_REGISTER_MAP 데이터
	const [settingList, setSettingList] = React.useState([]); // 선택된 컨트롤러의 현재 Register 데이터
	const [alarmList, setAlarmList] = React.useState([]); // 선택된 컨트롤러의 알림 리스트 데이터
	const [bitsList, setBitsList] = React.useState([]); // 선택된 컨트롤러의 입출력값 리스트
	const [bitsSwitchList, setBitsSwitchList] = React.useState([]); // 선택된 컨트롤러의 입출력의 Switch 값 리스트
	const [wrapAlarms, setWrapAlarms] = React.useState(true); // 전체 알림 유무
	const [alarmInputs, setAlarmInputs] = React.useState({
		// 온도
		tmpArYn: false,
		tmpMinVal: "0",
		tmpMaxVal: "0",
		defHgArYn: false, // 제상 시 고온알림
		tmpCheckTime: "0", // 온도 변화가 일정시간(값 - default: 0) 없을 시 알림
		// 습도
		hmdArYn: false,
		hmdMinVal: "0",
		hmdMaxVal: "0",
		// 조도
		illArYn: false,
		illMinVal: "0",
		illMaxVal: "0",
		// CO2
		co2ArYn: false,
		co2MinVal: "0",
		co2MaxVal: "0",
		//
		// 측정값 이외 알림
		inOutArr: [], // 입출력(콤프, 제상, 펜 등) 알림
		retryYn: false, // 10분마다 반복알림(RETRY_PUSH_ALARM_YN)
		checkTotalTime: "0", // 가동시간(값 - default: 0)이 초과 했을 경우 알림
		runTimeCheckYn: "N",
	}); // 알림 유무 모음
	const [lanList, setLanList] = React.useState([]); // language list
	const [userLanCode, setUserLanCode] = React.useState("kr"); // 사용자 설정 언어코드. 기본 한국어

	React.useEffect(() => {
		let temp = [];

		if (!location.state) {
			setDialog({
				open: true,
				title: "No Controller",
				children: (
					<>
						<p>
							No controller has been selected.
							{/* 컨트롤러가 선택되지 않았습니다. */}
						</p>
						<p>
							Return to the home screen.
							{/* 홈 화면으로 돌아갑니다. */}
						</p>
					</>
				),
				logout: false,
			});
		} else {
			// // console.log("location: ", location.state);
			temp.push({ ...location.state });
			setControllerData(temp);
			setAlarmInputs({
				...alarmInputs,
				defHgArYn: location.state.defHgArYn === "Y" ? true : false,
			});
			Init();
		}
		dispatch(BreadCrumbsActions.setMediumTitle("Change Settings"));
	}, [location]);

	const Init = async () => {
		setProgress(true);
		let userInfo = null;
		let codeList = [];
		let mesureUnitList = [];
		let mesureSubUnitList = [];
		let mapDatas = []; // 레지스터 맵
		let settingDatas = []; // 컨트롤러의 현재값
		let temp = []; // 설정값 변경시 사용할 데이터 리스트
		let resultSettingList = []; // 설정값 리스트 결과
		let alarms = []; // 알림 리스트
		let bitsData = []; // 알림 리스트
		let bitsSwitch = []; // 현재 알림값
		let lans = []; // 언어팩
		let lanCode = ""; // 현재 사용자 언어
		let tempListData = [];
		let search = true;

		try {
			// 사용자 데이터 조회
			await IndexedDb.GetLoginInfo().then(
				(result) => (userInfo = result)
			);
			await IndexedDb.GetCodeAll().then((result) => (codeList = result));

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					setLanList(result);
					result.forEach((item) => lans.push(item));
				}
			});

			// 사용자 언어코드 확인
			await IndexedDb.GetLoginInfo().then((result) => {
				lanCode = result.lanCode;
				setUserLanCode(result.lanCode);
			});

			// console.log("location.state : ", location.state);

			// 해당 페이지의 권한 확인
			// await IndexedDb.GetAllDb("AuthList").then((result) => {
			// 	search = result.filter((f) => f.mcategoCd === "SM10000").length > 0;
			// });

			// home에서의 컨트롤러 조회 로직 => 해당 데이터로 설정값 리스트 데이터를 변경
			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Home.Home,
				{
					userId: userInfo.userId,
					type: Commons.Storages("get", "settings").server,
				}
			).then(async (result) => {
				let data = result.data;
				// console.log("data : ", data);

				if (
					data.message !== undefined &&
					data.message ===
					"Index was outside the bounds of the array."
				) {
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_no_conntected_devices"
							)[0].lanName
							: "" // 연결된 장치가 없습니다.
					);
				} else if (data.type !== undefined && data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					setListData([]);
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) => f.lanId === "snack_unavailable_data"
							)[0].lanName
							: "" // 데이터가 없습니다.
					);
				} else if (data === "등록된 장치가 없습니다.") {
					throw new Error(
						lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId === "snack_no_device_registered"
							)[0].lanName
							: "" // 등록된 장치가 없습니다.
					);
				} else if (data === false) {
					throw new Error(data);
				} else {
					if (data.machNames.length > 0) {
						codeList.forEach((item, index) => {
							mesureUnitList.forEach((item1, index1) => {
								if (item.groupCd === item1.code) {
									mesureSubUnitList.push({ ...item });
								}
							});
						});

						data.machNames
							.filter(
								(f) => f.itemType !== "7" && f.screenYn === "Y"
							)
							.map((item, index) => {
								let pushData = {
									...item,
									amountYn: userInfo.amountYn,
								};

								if (data.settingValues) {
									data.settingValues.forEach(
										(item1, index1) => {
											if (item1.itemNo === item.itemNo) {
												pushData = {
													...pushData,
													setting_temp: item1.temp,
													setting_damp: item1.damp,
													setting_illum: item1.illum,
													setting_co2: item1.co2,
												};
											}
										}
									);
								}
								if (data.currentValues) {
									data.currentValues.forEach(
										(item1, index1) => {
											if (item1.itemNo === item.itemNo) {
												pushData = {
													...pushData,
													current_temp: item1.temp,
													current_damp: item1.damp,
													current_illum: item1.illum,
													current_co2: item1.co2,
												};
											}
										}
									);
								}
								if (data.operations) {
									data.operations.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												operation: item1.operation,
											};
										}
									});
								}
								if (data.tempUnits) {
									data.tempUnits.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												tempUnit: item1.tempUnit
													? item1.tempUnit
													: "0",
											};
										}
									});
								}
								if (data.alarms) {
									data.alarms.forEach((item1, index1) => {
										if (item1.itemNo === item.itemNo) {
											pushData = {
												...pushData,
												errorYn: "Y",
												// alarmMsg:
												// 	errorMsgCodes.filter(
												// 		(f) =>
												// 			f.code ===
												// 			item1.alarmMsg
												// 	).length > 0
												// 		? errorMsgCodes.filter(
												// 			(f) =>
												// 				f.code ===
												// 				item1.alarmMsg
												// 		)[0].val1
												// 		: "",
												alarmMsg: item1.alarmMsg,
											};
										}
									});
								}
								temp.push(pushData);
							});
					}
					tempListData = temp.filter(
						(f) => f.itemNo === location.state.itemNo
					)[0];
					setListData(
						temp.filter((f) => f.itemNo === location.state.itemNo)
					);

					if (
						tempListData.itemType === "1" &&
						tempListData.tmpYn === "Y"
					) {
						alarms.push({
							type: "temp",
							name: "온도",
							engName: "Temperature",
						});
						setAlarmInputs({
							...alarmInputs,
							tmpArYn:
								tempListData.tmpArYn === "Y" ? true : false,
							defHgArYn:
								tempListData.defHgArYn === "Y" ? true : false,
							tmpMaxVal: tempListData.tmpMaxVal * 1,
							tmpMinVal: tempListData.tmpMinVal * 1,
							retryYn:
								tempListData.retryPushAlarmYn === "Y"
									? true
									: false,
							runTimeCheckYn: tempListData.runTimeCheckYn,
							tmpCheckTime: tempListData.tmpCheckTime,
						});
					} else if (
						tempListData.itemType === "2" &&
						tempListData.hmdYn === "Y"
					) {
						alarms.push({
							type: "damp",
							name: "습도",
							engName: "Humidity",
						});
						setAlarmInputs({
							...alarmInputs,
							hmdArYn:
								tempListData.hmdArYn === "Y" ? true : false,
							hmdMaxVal: tempListData.hmdMaxVal * 1,
							hmdMinVal: tempListData.hmdMinVal * 1,
							retryYn:
								tempListData.retryPushAlarmYn === "Y"
									? true
									: false,
							runTimeCheckYn: tempListData.runTimeCheckYn,
							tmpCheckTime: tempListData.tmpCheckTime,
						});
					} else if (
						tempListData.itemType === "3" &&
						(tempListData.tmpYn === "Y" ||
							tempListData.hmdYn === "Y")
					) {
						alarms = [
							{
								type: "temp",
								name: "온도",
								engName: "Temperature",
							},
							{ type: "damp", name: "습도", engName: "Humidity" },
						];
						setAlarmInputs({
							...alarmInputs,
							tmpArYn:
								tempListData.tmpArYn === "Y" ? true : false,
							defHgArYn:
								tempListData.defHgArYn === "Y" ? true : false,
							tmpMaxVal: tempListData.tmpMaxVal * 1,
							tmpMinVal: tempListData.tmpMinVal * 1,
							hmdArYn:
								tempListData.hmdArYn === "Y" ? true : false,
							hmdMaxVal: tempListData.hmdMaxVal * 1,
							hmdMinVal: tempListData.hmdMinVal * 1,
							retryYn:
								tempListData.retryPushAlarmYn === "Y"
									? true
									: false,
							runTimeCheckYn: tempListData.runTimeCheckYn,
							tmpCheckTime: tempListData.tmpCheckTime,
						});
					} else if (
						tempListData.itemType === "4" &&
						(tempListData.tmpYn === "Y" ||
							tempListData.hmdYn === "Y" ||
							tempListData.co2Yn === "Y")
					) {
						alarms = [
							{
								type: "temp",
								name: "온도",
								engName: "Temperature",
							},
							{ type: "damp", name: "습도", engName: "Humidity" },
							{ type: "co2", name: "Co2", engName: "Co2" },
						];
						setAlarmInputs({
							...alarmInputs,
							tmpArYn:
								tempListData.tmpArYn === "Y" ? true : false,
							defHgArYn:
								tempListData.defHgArYn === "Y" ? true : false,
							tmpMaxVal: tempListData.tmpMaxVal * 1,
							tmpMinVal: tempListData.tmpMinVal * 1,
							hmdArYn:
								tempListData.hmdArYn === "Y" ? true : false,
							hmdMaxVal: tempListData.hmdMaxVal * 1,
							hmdMinVal: tempListData.hmdMinVal * 1,
							co2ArYn:
								tempListData.co2ArYn === "Y" ? true : false,
							co2MaxVal: tempListData.co2MaxVal * 1,
							co2MinVal: tempListData.co2MinVal * 1,
							retryYn:
								tempListData.retryPushAlarmYn === "Y"
									? true
									: false,
							runTimeCheckYn: tempListData.runTimeCheckYn,
							tmpCheckTime: tempListData.tmpCheckTime,
						});
					} else if (
						tempListData.itemType === "5" &&
						(tempListData.tmpYn === "Y" ||
							tempListData.hmdYn === "Y" ||
							tempListData.illYn === "Y" ||
							tempListData.co2Yn === "Y")
					) {
						alarms = [
							{
								type: "temp",
								name: "온도",
								engName: "Temperature",
							},
							{ type: "damp", name: "습도", engName: "Humidity" },
							{ type: "co2", name: "Co2", engName: "Co2" },
							{
								type: "ill",
								name: "조도",
								engName: "illuminance",
							},
						];
						setAlarmInputs({
							...alarmInputs,
							tmpArYn:
								tempListData.tmpArYn === "Y" ? true : false,
							defHgArYn:
								tempListData.defHgArYn === "Y" ? true : false,
							tmpMaxVal: tempListData.tmpMaxVal * 1,
							tmpMinVal: tempListData.tmpMinVal * 1,
							hmdArYn:
								tempListData.hmdArYn === "Y" ? true : false,
							hmdMaxVal: tempListData.hmdMaxVal * 1,
							hmdMinVal: tempListData.hmdMinVal * 1,
							illArYn:
								tempListData.illArYn === "Y" ? true : false,
							illMaxVal: tempListData.illMaxVal * 1,
							illMinVal: tempListData.illMinVal * 1,
							co2ArYn:
								tempListData.co2ArYn === "Y" ? true : false,
							co2MaxVal: tempListData.co2MaxVal * 1,
							co2MinVal: tempListData.co2MinVal * 1,
							retryYn:
								tempListData.retryPushAlarmYn === "Y"
									? true
									: false,
							runTimeCheckYn: tempListData.runTimeCheckYn,
							tmpCheckTime: tempListData.tmpCheckTime,
						});
					}
					setAlarmList(alarms);

					// 레지스터 맵 조회
					await Commons.Axios(
						"get",
						Global.base.useUrl +
						Global.urls.BaseManagement.BM10070.GetRegisterMaps,
						{
							itemNo: location.state.itemNo,
						}
					)
						.then((result) => {
							let data = result.data;

							if (data.type === "Error") {
								throw new Error(data.message);
							} else if (data === "데이터가 없습니다.") {
								throw new Error(
									lans.length > 0
										? lans.filter(
											(f) =>
												f.lanId ===
												"snack_unavailable_data"
										)[0].lanName
										: ""
								);
							} else {
								// setMapList(
								// 	data.filter((f) => f.moDpYn !== "Y")
								// );
								// mapDatas = data.filter((f) => f.moDpYn !== "Y");

								// 대시보드의 장치설정 데이터도 리스트에 나오게 수정
								setMapList(
									data
								);
								mapDatas = data;
							}
						})
						.then(async () => {
							// 현재 컨트롤러의 값 조회
							await Commons.Axios(
								"get",
								Global.base.useUrl +
								Global.urls.Home.GetSetting,
								{
									userId: userInfo.userId,
									itemNo: location.state.itemNo,
									type: Commons.Storages("get", "settings")
										.server,
									lan: lanCode === "en" ? 2 : 1,
								}
							).then(async (result) => {
								let data = result.data;

								if (data.type === "Error") {
									throw new Error(data.message);
								} else if (data === "데이터가 없습니다.") {
									throw new Error(
										lans.length > 0
											? lans.filter(
												(f) =>
													f.lanId ===
													"snack_unavailable_data"
											)[0].lanName
											: "" // 데이터가 없습니다.
									);
								} else {
									// settingDatas = data.baseTypes.filter(
									// 	(f) => f.moDpYn !== "Y"
									// );
									// 대시보드 장치설정 데이터도 리스트에 나오게 수정
									settingDatas = data.baseTypes;

									// mapDatas.forEach((item, index) => {
									// 	settingDatas.forEach((item1, index1) => {
									// 		if (item.MapAdd === item1.mapAdd) {
									// 			temp.push({
									// 				...item1,
									// 				mapUnit: item.MapUnit,
									// 			});
									// 		}
									// 	});
									// });
									// // console.log("temp : ", temp);

									// 설정값 리스트 제작
									// temp.forEach((item, index) => {
									// 	let stepType = null;

									// 	if (
									// 		(!item.inputType ||
									// 			item.inputType === "1") &&
									// 		item.value.toString().includes(".")
									// 	) {
									// 		stepType = "float";
									// 	} else if (
									// 		(!item.inputType ||
									// 			item.inputType === "1") &&
									// 		Commons.TypeOfNumber(item.value) ===
									// 		"int" &&
									// 		item.value * 1 >= 0
									// 	) {
									// 		stepType = "int";
									// 	} else {
									// 		stepType = false;
									// 	}

									// 	if (
									// 		(item.mapUnit === "분" &&
									// 			temp[index + 1].mapUnit === "초") ||
									// 		(item.mapUnit === "Min" &&
									// 			temp[index + 1].mapUnit === "Sec") ||
									// 		(item.mapUnit === "MIN" &&
									// 			temp[index + 1].mapUnit === "SEC")
									// 	) {
									// 		item.value =
									// 			item.value.toString().padStart(2, "0") +
									// 			":" +
									// 			temp[index + 1].value
									// 				.toString()
									// 				.padStart(2, "0");
									// 		item.value1 = item.value;
									// 		item.value2 = temp[index + 1].value;
									// 		item.mapUnit = "분초";
									// 		// item.mapNm =
									// 		// 	item.mapNm.substring(
									// 		// 		0,
									// 		// 		item.mapNm.length - 3
									// 		// 	) + "(분초)";
									// 	} else if (
									// 		item.mapUnit === null || item.mapUnit === ""
									// 	) {
									// 		item.mapUnit = "";
									// 	}

									// 	if (item.mapUnit !== "초") {
									// 		resultSettingList.push({
									// 			...item,
									// 			inputType:
									// 				item.inputType === null
									// 					? "1"
									// 					: item.inputType,
									// 			stepType: stepType,
									// 		});
									// 	}
									// });

									// // console.log("lanCode : ", lanCode);
									// // console.log("mapDatas : ", mapDatas);
									// // console.log("settingDatas : ", settingDatas);
									// // console.log("locaion.sate : ", location.state);

									// 설정값 리스트 제작
									mapDatas.forEach((mapItem, mapIndex) => {
										settingDatas.forEach(
											(setItem, setIndex) => {
												if (
													mapItem.MapAdd ===
													setItem.mapAdd
												) {
													// 레지스터 맵과 설정값이 같을 때 설정 리스트 저장
													if (
														mapItem.InputType ===
														"1"
													) {
														// 1. 아이템의 입력값이 '숫자'일 때
														if (
															(mapItem.MapUnit ===
																"분" &&
																mapDatas[
																	mapIndex + 1
																].MapUnit ===
																"초") ||
															(mapItem.MapUnit ===
																"Min" &&
																mapDatas[
																	mapIndex + 1
																].MapUnit ===
																"Sec")
														) {
															// 1-1. 분 + 초로 timepicker를 사용해야 함.
															resultSettingList.push(
																{
																	mapNm:
																		lanCode ===
																			"en"
																			? mapItem.MapNmShEng.split(
																				"("
																			)[0]
																			: mapItem.MapNmSh.split(
																				"("
																			)[0],
																	message:
																		lanCode ===
																			"en"
																			? mapItem.MapCommentEng
																			: mapItem.MapComment,
																	inputType:
																		"1",
																	inputs: [],
																	min: Number(
																		mapItem.MapMin
																	),
																	max: Number(
																		mapItem.MapMax
																	),
																	min1: null,
																	max1: null,
																	beforeValue:
																		setItem.value,
																	mapAdd: mapItem.MapAdd,
																	mapUnit:
																		"분초",
																	showValue:
																		setItem.value.padStart(
																			2,
																			"0"
																		) +
																		":" +
																		settingDatas[
																			setIndex +
																			1
																		].value.padStart(
																			2,
																			"0"
																		),
																	value:
																		setItem.value.padStart(
																			2,
																			"0"
																		) +
																		":" +
																		settingDatas[
																			setIndex +
																			1
																		].value.padStart(
																			2,
																			"0"
																		),
																	value1: setItem.value.padStart(
																		2,
																		"0"
																	),
																	value2: settingDatas[
																		setIndex +
																		1
																	].value.padStart(
																		2,
																		"0"
																	),
																}
															);
														} else if (
															(mapItem.MapUnit ===
																"분" &&
																mapDatas[
																	mapIndex + 1
																].MapUnit !==
																"초") ||
															(mapItem.MapUnit ===
																"Min" &&
																mapDatas[
																	mapIndex + 1
																].MapUnit !==
																"Sec")
														) {
															// 1-2. '분'만 입력하는 경우 => 일반 input. 온도 단위에 따른 Min/Max 구분 없음
															resultSettingList.push(
																{
																	mapNm:
																		lanCode ===
																			"en"
																			? mapItem.MapNmShEng
																			: mapItem.MapNmSh,
																	message:
																		lanCode ===
																			"en"
																			? mapItem.MapCommentEng
																			: mapItem.MapComment,
																	inputType:
																		"1",
																	inputs: [],
																	min: Number(
																		mapItem.MapMin
																	),
																	max: Number(
																		mapItem.MapMax
																	),
																	min1: Number(
																		mapItem.MapMin
																	),
																	max1: Number(
																		mapItem.MapMax
																	),
																	beforeValue:
																		setItem.value,
																	mapAdd: mapItem.MapAdd,
																	mapUnit:
																		"분",
																	showValue:
																		setItem.value,
																	value: setItem.value,
																	value1: "",
																	value2: "",
																}
															);
														} else if (
															mapItem.MapUnit !==
															"초" &&
															mapItem.MapUnit !==
															"Sec"
														) {
															// 1-3. 일반 input. 온도 단위에 따른 Min/Max 구분 필요
															resultSettingList.push(
																{
																	mapNm:
																		lanCode ===
																			"en"
																			? mapItem.MapNmShEng
																			: mapItem.MapNmSh,
																	message:
																		lanCode ===
																			"en"
																			? mapItem.MapCommentEng
																			: mapItem.MapComment,
																	inputType:
																		"1",
																	inputs: [],
																	min: Number(
																		mapItem.MapMin
																	),
																	max: Number(
																		mapItem.MapMax
																	),
																	min1: Number(
																		mapItem.MapMin1
																	),
																	max1: Number(
																		mapItem.MapMax1
																	),
																	beforeValue:
																		setItem.value,
																	mapAdd: mapItem.MapAdd,
																	mapUnit: "",
																	showValue:
																		setItem.value,
																	value: setItem.value,
																	value1: "",
																	value2: "",
																}
															);
														}
													} else {
														// 2. 아이템의 입력값이 '코드'일 때
														let tempInputs = [];
														setItem.inputs.forEach(
															(item, index) => {
																tempInputs.push(
																	{
																		value: index,
																		name: item,
																	}
																);
															}
														);

														resultSettingList.push({
															mapNm:
																lanCode === "en"
																	? mapItem.MapNmShEng
																	: mapItem.MapNmSh,
															message:
																lanCode === "en"
																	? mapItem.MapCommentEng
																	: mapItem.MapComment,
															inputType: "2",
															inputs: tempInputs,
															min: null,
															max: null,
															min1: null,
															max1: null,
															beforeValue:
																tempInputs.filter(
																	(f) =>
																		f.name ===
																		setItem.value
																).length > 0
																	? tempInputs.filter(
																		(
																			f
																		) =>
																			f.name ===
																			setItem.value
																	)[0].value
																	: "",
															mapAdd: mapItem.MapAdd,
															mapUnit: "",
															showValue:
																setItem.value,
															value:
																tempInputs.filter(
																	(f) =>
																		f.name ===
																		setItem.value
																).length > 0
																	? setItem.value
																	: "",
															value:
																tempInputs.filter(
																	(f) =>
																		f.name ===
																		setItem.value
																).length > 0
																	? tempInputs.filter(
																		(
																			f
																		) =>
																			f.name ===
																			setItem.value
																	)[0].value
																	: "",
															value1: "",
															value2: "",
														});
													}
												}
											}
										);
									});

									// 알림 리스트 제작
									data.bitStatus.forEach((item, index) => {
										bitsData.push(
											mapDatas.filter((f) =>
												Commons.Storages(
													"get",
													"userInfo"
												).lanCode === "kr"
													? f.MapNmSh ===
													item.statusNm
													: f.MapNmShEng ===
													item.statusNm
											)[0]
										);
									});
									// mapDatas.forEach((item2, index2) => {
									// 	if (item2.ArDataType === "1") {
									// 		bitsData.push(item2);
									// 	}
									// });

									// 알림 세팅값 조회
									await Commons.Axios(
										"get",
										Global.base.useUrl +
										Global.urls.SettingManagement
											.SM10010.GetInOutAlarm,
										{
											itemNo: location.state.itemNo,
										}
									).then((result) => {
										let inOutData = result.data;

										if (inOutData.type === "Error") {
											throw new Error(inOutData.message);
										} else if (
											inOutData === "데이터가 없습니다."
										) {
											bitsData.forEach((bits, index) => {
												bitsSwitch.push(false);
											});
										} else {
											let count = 0;
											bitsData.forEach((item, index) => {
												inOutData.forEach(
													(item1, index1) => {
														if (
															item.MapAdd ===
															item1.MapAdd
														) {
															count++;
														}
														// else {
														// 	bitsSwitch.push(false);
														// }
													}
												);
												if (count > 0) {
													bitsSwitch.push(true);
													count = 0;
												} else {
													bitsSwitch.push(false);
												}
											});
										}
									});

									// // console.log(
									// 	"resultSettingList: ",
									// 	resultSettingList
									// );
									setSettingList(resultSettingList); // 설정값 리스트
									setBitsList(bitsData); // 알림 리스트
									setBitsSwitchList(bitsSwitch); // 알림 현재값 리스트
								}
							});
						});
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 저장 전 Min/Max 확인
	const CheckTempUnit = async (mapAdd, value) => {
		// // console.log("mapAdd, value : ", mapAdd, value);
		let tempMap = settingList.filter((f) => f.mapAdd === mapAdd)[0];
		// // console.log("tempMap: ", tempMap);
		// InputType이 '숫자'인 경우 (분+초(timepicker) 제외)
		if (
			tempMap.inputType === "1"
			// && (tempMap.MapUnit === "℃/℉" ||
			// 	tempMap.MapUnit === "%" ||
			// 	tempMap.MapUnit === "ppm" ||
			// 	tempMap.MapUnit === "LUX")
		) {
			if (location.state.tempUnit === "0") {
				// 현재 설정 단위가 섭씨인 경우 => 소수점 입력, Max/Min 사용
				if (isNaN(value)) {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "", // 설정 범위를 벗어난 값입니다.
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "", // 설정 범위를 벗어난 값입니다.
					});
				} else if (Number(value) > tempMap.max) {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		// (lanList.length > 0
					// 		// 	? lanList.filter(
					// 		// 		(f) => f.lanId === "snack_over_limit_1"
					// 		// 	)[0].lanName
					// 		// 	: "") + tempMap.MapNm +
					// 		// (lanList.length > 0
					// 		// 	? lanList.filter(
					// 		// 		(f) => f.lanId === "snack_over_limit_2"
					// 		// 	)[0].lanName
					// 		// 	: ""), // '의 설정값이 상한보다 높습니다.
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "",
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "",
					});
				} else if (Number(value) < tempMap.min) {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		// (lanList.length > 0
					// 		// 	? lanList.filter(
					// 		// 		(f) => f.lanId === "snack_under_limit_1"
					// 		// 	)[0].lanName
					// 		// 	: "") + tempMap.MapNm +
					// 		// (lanList.length > 0
					// 		// 	? lanList.filter(
					// 		// 		(f) => f.lanId === "snack_under_limit_2"
					// 		// 	)[0].lanName
					// 		// 	: ""), // '의 설정값이 하한보다 낮습니다.
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "",
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "",
					});
				} else {
					SaveValue(mapAdd, value);
				}
			} else if (location.state.tempUnit === "1") {
				// 현재 설정 단위가 화씨인 경우 => 정수 입력, Max1/Min1 사용
				if (isNaN(value)) {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "", // 설정 범위를 벗어난 값입니다.
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "", // 설정 범위를 벗어난 값입니다.
					});
				} else if (Number(value) > tempMap.max1) {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		// (lanList.length > 0
					// 		// 	? lanList.filter(
					// 		// 		(f) => f.lanId === "snack_over_limit_1"
					// 		// 	)[0].lanName
					// 		// 	: "") + tempMap.MapNm +
					// 		// (lanList.length > 0
					// 		// 	? lanList.filter(
					// 		// 		(f) => f.lanId === "snack_over_limit_2"
					// 		// 	)[0].lanName
					// 		// 	: ""), // '의 설정값이 상한보다 높습니다.
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "",
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "",
					});
				} else if (Number(value) < tempMap.min1) {
					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "error",
					// 	message:
					// 		// (lanList.length > 0
					// 		// 	? lanList.filter(
					// 		// 		(f) => f.lanId === "snack_under_limit_1"
					// 		// 	)[0].lanName
					// 		// 	: "") + tempMap.MapNm +
					// 		// (lanList.length > 0
					// 		// 	? lanList.filter(
					// 		// 		(f) => f.lanId === "snack_under_limit_2"
					// 		// 	)[0].lanName
					// 		// 	: ""), // '의 설정값이 하한보다 낮습니다.
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_out_of_range"
					// 			)[0].lanName
					// 			: "",
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_error_title"
								)[0].lanName
								: "",	// 에러!
						message:
							lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_out_of_range"
								)[0].lanName
								: "",
					});
				} else {
					SaveValue(mapAdd, value);
				}
			}
		} else {
			SaveValue(mapAdd, value);
		}
	};

	// 설정값 저장
	const SaveValue = async (mapAdd, value) => {
		setProgress(true);
		let userInfo = null;
		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl +
				Global.urls.SettingManagement.SM10010.Change,
				{
					itemNo: location.state.itemNo,
					mapAdd: mapAdd,
					value: value + "",
					userId: userInfo.userId,
					type: Commons.Storages("get", "settings").server,
				}
			).then((result) => {
				let saveResult = result.data.response;

				if (saveResult.indexOf("N") > -1) {
					Init(location.state.itemNo);
					throw new Error(result.data.errorMessage);
				} else if (saveResult.indexOf("미접속") > -1) {
					Init(location.state.itemNo);
					throw new Error(saveResult.split("!")[0]);
				} else if (saveResult.indexOf("Y") != -1) {
					// setSnacks({
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		lanList.length > 0
					// 			? lanList.filter(
					// 				(f) =>
					// 					f.lanId ===
					// 					"snack_save_after_refresh"
					// 			)[0].lanName
					// 			: "", // 저장되었습니다. 30초 후 새로고침하세요.
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"sm10000"
								)[0].lanName
								: "",	// 설정값 변경
						message: (
							<div>
								<p>
									{lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"snack_setting_submit"
										)[0].lanName
										: ""	// 설정값을 전달하였습니다.
									}
								</p>
								<p>
									{lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"snack_verify_changed"
										)[0].lanName
										: ""	// 설정값이 올바르게 변경되었는지 확인하세요
									}
								</p>
							</div>
						)
					});
					Init(location.state.itemNo);
				} else {
					throw new Error(
						lanList.length > 0
							? lanList.filter(
								(f) => f.lanId === "snack_save_failed"
							)[0].lanName
							: "" // 저장 실패!
					);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 저장 전 알림값 확인
	const ChangeAlarmValidation = () => {
		if (alarmInputs.tmpMaxVal * 1 < alarmInputs.tmpMinVal * 1) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		lanList.length > 0
			// 			? lanList.filter(
			// 				(f) => f.lanId === "snack_tmp_noti_under_limit"
			// 			)[0].lanName
			// 			: "", // "온도 알림 최댓값"이 "온도 알림 최솟값" 보다 작습니다.'
			// });
			// return false;
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_tmp_noti_under_limit"
						)[0].lanName
						: "", // "온도 알림 최댓값"이 "온도 알림 최솟값" 보다 작습니다.'
			});
		} else if (alarmInputs.hmdMaxVal * 1 < alarmInputs.hmdMinVal * 1) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		lanList.length > 0
			// 			? lanList.filter(
			// 				(f) => f.lanId === "snack_hdm_noti_under_limit"
			// 			)[0].lanName
			// 			: "", // "습도 알림 최댓값"이 "습도 알림 최솟값" 보다 작습니다.
			// });
			// return false;
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_hdm_noti_under_limit"
						)[0].lanName
						: "", // "습도 알림 최댓값"이 "습도 알림 최솟값" 보다 작습니다.
			});
		} else if (alarmInputs.illMaxVal * 1 < alarmInputs.illMinVal * 1) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		lanList.length > 0
			// 			? lanList.filter(
			// 				(f) => f.lanId === "snack_ill_noti_under_limit"
			// 			)[0].lanName
			// 			: "", // "조도 알림 최댓값"이 "조도 알림 최솟값" 보다 작습니다.
			// });
			// return false;
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_ill_noti_under_limit"
						)[0].lanName
						: "", // "조도 알림 최댓값"이 "조도 알림 최솟값" 보다 작습니다.
			});
		} else if (alarmInputs.co2MaxVal * 1 < alarmInputs.co2MinVal * 1) {
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message:
			// 		lanList.length > 0
			// 			? lanList.filter(
			// 				(f) => f.lanId === "snack_co2_noti_under_limit"
			// 			)[0].lanName
			// 			: "", // "Co2 알림 최댓값"이 "Co2 알림 최솟값" 보다 작습니다.
			// });
			// return false;
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message:
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "snack_co2_noti_under_limit"
						)[0].lanName
						: "", // "Co2 알림 최댓값"이 "Co2 알림 최솟값" 보다 작습니다.
			});
		} else {
			ChangeAlarm();
		}
	};

	// 알림값 저장
	const ChangeAlarm = async () => {
		setProgress(true);
		let userInfo = null;
		let inOuts = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			// // console.log("bitsList: ", bitsList);
			// // console.log("bitsSwitchList: ", bitsSwitchList);
			bitsSwitchList.forEach((item, index) => {
				inOuts.push({
					itemNo: location.state.itemNo * 1,
					mapAdd: bitsList[index].MapAdd + "",
					userId: userInfo.userId,
					type: item ? "add" : "delete",
				});
			});
			// // console.log("inOuts: ", inOuts);

			await Commons.Axios(
				"post",
				Global.base.useUrl +
				Global.urls.SettingManagement.SM10010.ChangeInOutAlarm,
				inOuts
			).then(async (result) => {
				let data = result.data;
				// // console.log("ChangeInOut: ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else {
					await Commons.Axios(
						"post",
						Global.base.useUrl +
						Global.urls.SettingManagement.SM10010.ChangeAlarm,
						{
							userId: userInfo.userId,
							itemNo: location.state.itemNo,
							itemIdx: 0,
							itemGrp: "string",
							machNm: "string",
							macAdd: "string",
							machId: "string",
							fwVer: "string",
							useYn: "string",
							itemLan: 0,
							itemLong: 0,
							postNo: "string",
							address1: "string",
							address2: "string",
							tmpSpec: 0,
							tmpArYn: alarmInputs.tmpArYn ? "Y" : "N",
							tmpMinVal: alarmInputs.tmpMinVal,
							tmpMaxVal: alarmInputs.tmpMaxVal,
							hmdArYn: alarmInputs.hmdArYn ? "Y" : "N",
							hmdMinVal: alarmInputs.hmdMinVal,
							hmdMaxVal: alarmInputs.hmdMaxVal,
							illArYn: alarmInputs.illArYn ? "Y" : "N",
							illMinVal: alarmInputs.illMinVal,
							illMaxVal: alarmInputs.illMaxVal,
							co2ArYn: alarmInputs.co2ArYn ? "Y" : "N",
							co2MinVal: alarmInputs.co2MinVal,
							co2MaxVal: alarmInputs.co2MaxVal,
							defHgArYn: alarmInputs.defHgArYn ? "Y" : "N",
							joinRtNo: 0,
							itemOrder: 0,
							tmpSaveTime: 0,
							tmpCheckTime: alarmInputs.tmpCheckTime,
							retryPushAlarmYn: alarmInputs.retryYn ? "Y" : "N",
							checkTotalTime: alarmInputs.checkTotalTime,
							updateDate: "string",
							updateId: "string",
						}
					).then((result) => {
						let data = result.data;

						if (data.type === "Error") {
							throw new Error(data.message);
						} else if (data === "데이터가 없습니다.") {
							throw new Error(
								lanList.length > 0
									? lanList.filter(
										(f) =>
											f.lanId ===
											"snack_unavailable_data"
									)[0].lanName
									: ""
							);
						} else if (data === false) {
							throw new Error(data);
						} else {
							// setSnacks({
							// 	...snacks,
							// 	open: true,
							// 	type: "success",
							// 	message:
							// 		lanList.length > 0
							// 			? lanList.filter(
							// 				(f) => f.lanId === "snack_saved"
							// 			)[0].lanName
							// 			: "", // 저장되었습니다.
							// });
							setSnackDialog({
								open: true,
								title:
									lanList.length > 0
										? lanList.filter(
											(f) =>
												f.lanId ===
												"snack_success_title"
										)[0].lanName
										: "",	// 성공
								message:
									lanList.length > 0
										? lanList.filter(
											(f) => f.lanId === "snack_saved"
										)[0].lanName
										: "", // 저장되었습니다.
							});
							Init();
						}
					});
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	return (
		<div className="wrap">
			<SM10010View
				// 언어팩
				lanList={lanList}
				userLanCode={userLanCode}
				// 데이터
				controllerData={controllerData}
				settingList={settingList}
				alarmList={alarmList}
				setAlarmList={setAlarmList}
				bitsList={bitsList}
				bitsSwitchList={bitsSwitchList}
				setBitsSwitchList={setBitsSwitchList}
				wrapAlarms={wrapAlarms}
				setWrapAlarms={setWrapAlarms}
				alarmInputs={alarmInputs}
				// 함수
				setAlarmInputs={setAlarmInputs}
				CheckTempUnit={CheckTempUnit}
				SaveValue={SaveValue}
				ChangeAlarm={ChangeAlarmValidation}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<Dialog
				open={dialog.open}
				title={dialog.title}
				children={dialog.children}
				onClose={() => {
					// 로그아웃
					dialog.logout && Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					navigate("/");

					setDialog({
						...dialog,
						open: false,
						title: "",
						children: "",
					});
				}}
				useSaveButton={false}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "dashboard_pop_confirm"
						)[0].lanName
						: "confirm" // 확인
				}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "닫기" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default SM10010;
