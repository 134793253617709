import React from "react";
/** Components Import */
import { Alert, AlertTitle } from "@mui/material";

function DesCriptionAlert({
	sx = {}, // mui inline-style object
	color = "", // "first", "secondary", "success", "info", "warning", "error"
	type = "standard", // "filled", "outlined", "standard"
	noteType = "info", // "success", "info", "warning", "error"
	onClose = () => {}, // alert closed event function
	title = "", // alert title
	name = "", // alert name
}) {
	const AlertClosed = () => {
		onClose();
	};

	return (
		<Alert
			sx={sx}
			color={color}
			variant={type}
			severity={noteType}
			onClose={AlertClosed}
		>
			<AlertTitle>{title}</AlertTitle>
			{name}
		</Alert>
	);
}

export default DesCriptionAlert;
