import React from "react";
/** Components Import */
import { Alert } from "@mui/material";

function BasicAlert({
	sx = {}, 					// mui inline-style object
	color = "", 				// "first", "secondary", "success", "info", "warning", "error"
	type = "standard", 			// "filled", "outlined", "standard"
	noteType = "info", 			// "success", "info", "warning", "error"
	onClose = () => { }, 		// alert closed event function
	name = "", 					// alert article
}) {
	const AlertClosed = () => {
		onClose();
	};

	return (
		<Alert
			sx={sx}
			color={color}
			variant={type}
			severity={noteType}
			onClose={AlertClosed}
		>
			{name}
		</Alert>
	);
}

export default BasicAlert;
