import React from "react";
import { useDispatch } from "react-redux";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
/** Services Import */
import Commons from "../../Services/Common/Common";
/** Components Import */
import { Unstable_Grid2 as Grid, Card } from "@mui/material";
import { AuthGroupButton } from "../../Components/Buttons";
import { Dialog } from "../../Components/Dialog";
import { Input } from "../../Components/Inputs";
import { Alert } from "../../Components/Alert";
import { Combo } from "../../Components/Combos";
import NumgberCellEditor from "../../Components/Conotec/NumberCellEditor";
/** Reducers Import */
/** Icons Import */
/** Ag-grid Import */
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GridFunctions from "../../Services/agGrid/ag-Grid-functions";
/** Splitter */
import { Splitter, SplitterPanel } from "primereact/splitter";

/** 마스터 복사 관리 화면 */

function BM10070(props) {
	const dispatch = useDispatch(); // Dispatch 사용을 위한 저장
	const gridRef = React.useRef();
	const gridRef1 = React.useRef();
	const gridRef2 = React.useRef();
	const columnDefs = [
		{
			field: "itemNo",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_machine_number"
					)[0].lanName
					: "", // 장치번호
			minWidth: 140,
			headerCheckboxSelection: true,
			checkboxSelection: true,
			editable: false,
		},
		{
			field: "machNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_machine_name"
					)[0].lanName
					: "", // 장치명
			minWidth: 160,
			editable: false,
		},
		{
			field: "rmCd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_master_code"
					)[0].lanName
					: "", // 마스터 코드
			minWidth: 150,
			editable: false,
		},
		{
			field: "rmNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_master_code_name"
					)[0].lanName
					: "", // 마스터 코드명
			minWidth: 230,
			editable: true,
		},
		{
			field: "rmComment",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_description"
					)[0].lanName
					: "", // 설명
			minWidth: 300,
			editable: true,
		},
		// {
		// 	field: "createDate",
		// 	headerName: "등록일시",
		// 	minWidth: 180,
		// 	editable: false,
		// },
		// {
		// 	field: "createId",
		// 	headerName: "등록자",
		// 	minWidth: 120,
		// 	editable: false,
		// },
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_update_date"
					)[0].lanName
					: "", // 수정일시
			minWidth: 180,
			editable: false,
		},
		{
			field: "updateId",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_update_id"
					)[0].lanName
					: "", // 수정자
			minWidth: 120,
			editable: false,
		},
	];
	const columnDefs1 = [
		{
			field: "mapAdd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_register_add"
					)[0].lanName
					: "", // 레지스터 주소
			minWidth: 160,
			editable: false,
		},
		{
			field: "mapNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_register_name"
					)[0].lanName
					: "", // 레지스터 명
			minWidth: 260,
			editable: false,
		},
		{
			field: "mapNmSh",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_register_short_name"
					)[0].lanName
					: "", // 레지스터 약어
			minWidth: 260,
			editable: false,
		},
		{
			field: "val",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_register_value"
					)[0].lanName
					: "", // 레지스터 값
			minWidth: 150,
			cellEditorSelector: (e) => {
				let row = e.api.getSelectedRows()[0];

				if (e.data.type === "number") {
					return {
						component: NumgberCellEditor, // 자세한건 NumgberCellEditor 침조
					};
				}
				if (e.data.type === "select") {
					return {
						component: "agRichSelectCellEditor",
						params: {
							values: row.values,
						},
						popup: true,
					};
				}
				return undefined;
			},
			editable: true,
		},
		{
			field: "updateDate",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_update_date"
					)[0].lanName
					: "", // 수정일시
			minWidth: 150,
			editable: false,
		},
	];
	const ColumnDefs2 = [
		{
			field: "MapAdd",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_register_add"
					)[0].lanName
					: "", // 주소
			headerCheckboxSelection: true,
			checkboxSelection: true,
			minWidth: 180,
		},
		// {
		// 	field: "CopyType",
		// 	headerName:
		// 		props.lanList.length > 0
		// 			? props.lanList.filter(
		// 				(f) => f.lanId === "bm10020_col_copy_yn"
		// 			)[0].lanName
		// 			: "", // 복사 여무
		// 	minWidth: 160,
		// 	cellEditor: "agRichSelectCellEditor",
		// 	cellEditorParams: {
		// 		values: props.codeList
		// 			.filter((item) => item.groupCd === "USE_YN")
		// 			.map((item) =>
		// 				props.userLanCode === "en" ? item.ref3 : item.name
		// 			),
		// 	},
		// },
		{
			field: "MapNm",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_register_name"
					)[0].lanName
					: "", // 타입 명
			minWidth: 230,
		},
		{
			field: "MapNmSh",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_register_short_name"
					)[0].lanName
					: "", // 타입 약어
			minWidth: 230,
		},
		{
			field: "InputType",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_input_type"
					)[0].lanName
					: "", // 입력 타입
			minWidth: 120,
		},
		{
			field: "MapMin",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_min_value"
					)[0].lanName
					: "", // 최소값
			minWidth: 120,
		},
		{
			field: "MapMax",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_max_value"
					)[0].lanName
					: "", // 최대값
			minWidth: 120,
			cellDataType: "number",
		},
		{
			field: "MapUnit",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_unit"
					)[0].lanName
					: "", // 단위
			minWidth: 120,
		},
		{
			field: "MapComment",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_comment"
					)[0].lanName
					: "", // Comment
			minWidth: 230,
		},
		{
			field: "UseYn",
			headerName:
				props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_col_use_yn"
					)[0].lanName
					: "", // 사용여부
			minWidth: 120,
		},
	];
	const [checkingDialog, setCheckingDialog] = React.useState({
		open: false,
		location: "",
	});
	const [confirms, setConfirms] = React.useState({
		toggle: false,
		title: "",
		content: "",
		onSave: () => { },
	});
	const [message, setMessage] = React.useState({
		toggle: false,
		message: "",
		type: "info",
	});

	return (
		<>
			<Splitter>
				<SplitterPanel size={40}>
					<Card sx={{ display: "flex", justifyContent: "flex-end" }}>
						<AuthGroupButton
							pageName="BM10070"
							target="G01"
							refreshNone="none"
							addFunc={() => {
								setCheckingDialog({
									open: true,
									location: "grid",
								});
								props.setAddData({
									...props.addData,
									userId: "",
								});
							}}
							saveFunc={() => {
								setConfirms({
									...confirms,
									toggle: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10070_pop_save_title"
										)[0].lanName
										: ""),	// 저장
									content: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10070_pop_save_message"
										)[0].lanName
										: ""),	// 저장하시겠습니까?
									onSave: () => props.CreateMst(),
								});
							}}
							deleteFunc={() => {
								setConfirms({
									...confirms,
									toggle: true,
									title: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10070_pop_delete_title"
										)[0].lanName
										: ""),	// 삭제
									content: (props.lanList.length > 0
										? props.lanList.filter(
											(f) =>
												f.lanId ===
												"bm10070_pop_delete_message"
										)[0].lanName
										: ""),	// 삭제하시겠습니까?
									onSave: () => props.DeleteMst(),
								});
							}}
						/>
					</Card>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef}
								animationRows={true}
								columnDefs={columnDefs} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: false,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData}
								rowSelection="multiple"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() => {
									GridFunctions.onSelectionChanged(
										gridRef,
										async (_selected) => {
											if (
												_selected.length !== 0 &&
												_selected[0].itemNo
											) {
												props.setSelectedRowData(
													_selected
												);
												props.GetRegisterMaps(
													_selected[0].itemNo,
													_selected[0].rmCd
												);
												props.setRowData1([]);
												props.setChangedRowData1([]);
												props.setSelectedRowData1([]);
											} else {
												props.setRowData1([]);
											}
										}
									);
								}}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen()); // 열림 방지
									GridFunctions.onCellValueChanged(
										e,
										gridRef,
										props.setChangedRowData
									);
								}}
								getRowStyle={(e) =>
									GridFunctions.getRowStyle(e)
								}
							/>
						</div>
					</Card>
				</SplitterPanel>
				<SplitterPanel size={60}>
					<Card sx={{ display: "flex", justifyContent: "flex-end" }}>
						<AuthGroupButton
							pageName="BM10070"
							target="G02"
							refreshNone="none"
							addFunc={async () => {
								if (!props.selectedRowData[0]) {
									return;
								} else if (!props.selectedRowData[0].updateId) {
									return false;
								} else {
									props.MakeGridData();
									setCheckingDialog({
										open: true,
										location: "grid1",
									});
								}
							}}
							saveFunc={() => {
								if (
									!props.selectedRowData[0] ||
									!props.selectedRowData[0].updateId
								) {
									return false;
								} else {
									setConfirms({
										...confirms,
										toggle: true,
										title: (props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10070_pop_save_title"
											)[0].lanName
											: ""),	// 저장
										content: (props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10070_pop_save_message"
											)[0].lanName
											: ""),	// 저장하시겠습니까?
										onSave: () => {
											props.CheckGridData();
										},
									});
								}
							}}
							deleteFunc={() => {
								if (
									!props.selectedRowData[0] ||
									!props.selectedRowData[0].updateId
								) {
									return false;
								} else {
									setConfirms({
										...confirms,
										toggle: true,
										title: (props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10070_pop_delete_title"
											)[0].lanName
											: ""),	// 삭제
										content: (props.lanList.length > 0
											? props.lanList.filter(
												(f) =>
													f.lanId ===
													"bm10070_pop_delete_message"
											)[0].lanName
											: ""),	// 삭제하시겠습니까?
										onSave: () => props.DeleteSub(),
									});
								}
							}}
						/>
					</Card>
					<Card>
						<div
							className="ag-theme-alpine-dark"
							style={{
								width: "100%",
								height:
									Commons.Dimentions().height < 800
										? Commons.Dimentions().height * 0.58
										: Commons.Dimentions().height * 0.74,
							}}
						>
							<AgGridReact
								ref={gridRef1}
								animationRows={true}
								columnDefs={columnDefs1} // 컬럼 헤더
								defaultColDef={{
									flex: 1,
									minWidth: 100,
									editable: true,
									resizable: true,
									sortable: true,
									filter: true,
									floatingFilter: true,
								}}
								rowData={props.rowData1}
								rowSelection="multiple"
								singleClickEdit={
									Commons.IsMobile() ? true : false
								}
								onSelectionChanged={() =>
									GridFunctions.onSelectionChanged(
										gridRef1,
										props.setSelectedRowData1
									)
								}
								onCellValueChanged={(e) => {
									dispatch(DrawerActions.setNotOpen()); // 열림 방지
									GridFunctions.onCellValueChanged(
										e,
										gridRef1,
										props.setChangedRowData1
									);
								}}
							// getRowStyle={(e) =>
							// 	GridFunctions.getRowStyle(e)
							// }
							/>
						</div>
					</Card>
				</SplitterPanel>
			</Splitter>
			<Dialog
				fullWidth
				title={
					checkingDialog.location === "grid"
						? (props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId ===
									"bm10070_pop_key_check_title"
							)[0].lanName
							: "")	// 키 체크
						: (props.lanList.length > 0
							? props.lanList.filter(
								(f) =>
									f.lanId ===
									"bm10070_pop_select_register_title"
							)[0].lanName
							: "")	// 레지스터 선택
				}
				open={checkingDialog.open}
				onClose={() => {
					setCheckingDialog({
						...checkingDialog,
						open: false,
					});
					props.setAddData({
						...props.addData,
						userId: "",
						itemNo: "",
						rmCd: "",
					});
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
				bodySx={{ display: "flex", flexDirection: "column" }}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_pop_check"
					)[0].lanName
					: ""	// 체크
				}
				onSave={() => {
					if (checkingDialog.location === "grid") {
						props.CheckCodeMst(gridRef);
					} else if (checkingDialog.location === "grid1") {
						props.CheckCodeSub(gridRef1);
					}
				}}
			>
				{checkingDialog.location === "grid" ? (
					<>
						<Combo
							sx={{ my: 1 }}
							name={props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"bm10070_pop_select_user_id"
								)[0].lanName
								: ""	// 사용자 아이디 선택
							}
							value={props.addData.userId}
							items={props.addData.userCombo}
							onChange={(e) => {
								props.setAddData({
									...props.addData,
									userId: e,
								});
								props.CheckUserId(e);
							}}
						/>
						<Combo
							sx={{ my: 1 }}
							name={props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"bm10070_pop_select_item"
								)[0].lanName
								: ""	// 아이템 선택
							}
							value={props.addData.itemNo}
							items={props.addData.itemCombo}
							onChange={(e) => {
								props.setAddData({
									...props.addData,
									itemNo: e,
								});
							}}
						/>
						<Input
							sx={{ my: 1 }}
							type="outlined"
							name={props.lanList.length > 0
								? props.lanList.filter(
									(f) =>
										f.lanId ===
										"bm10070_pop_master_code"
								)[0].lanName
								: ""	// 마스터 코드
							}
							value={props.addData.rmCd}
							onChange={(e) => {
								if (e.length > 10) {
									props.setSnacks({
										...props.snacks,
										open: true,
										type: "error",
										message:
											(props.lanList.length > 0
												? props.lanList.filter(
													(f) =>
														f.lanId ===
														"snack_master_code_limit"
												)[0].lanName
												: "")	// 마스터 코드는 10자를 넘을 수 없습니다.",
									});
								} else {
									props.setAddData({
										...props.addData,
										rmCd: e,
									});
								}
							}}
						/>
					</>
				) : checkingDialog.location === "grid1" ? (
					<Grid
						container
						spacing={0}
						columns={{ xs: 4, sm: 8, md: 12 }}
					>
						<Grid xs={12} md={12}>
							<Card>
								<div
									className="ag-theme-alpine-dark"
									style={{ width: "100%", height: "600px" }}
								>
									<AgGridReact
										ref={gridRef2}
										animationRows={true}
										columnDefs={ColumnDefs2} // 컬럼 헤더
										defaultColDef={{
											flex: 1,
											minWidth: 100,
											editable: false,
											resizable: true,
											sortable: true,
											filter: true,
											floatingFilter: true,
										}}
										rowData={props.rowData2}
										rowSelection="multiple"
										singleClickEdit={false}
										onSelectionChanged={() => {
											GridFunctions.onSelectionChanged(
												gridRef2,
												(_selected) => {
													props.setSelectedRowData2(
														_selected
													);
												}
											);
										}}
										getRowStyle={(e) =>
											GridFunctions.getRowStyle(e)
										}
										onRowDoubleClicked={() => {
											props.CheckCodeSub(gridRef1);
											setCheckingDialog({ open: false });
										}}
									/>
								</div>
							</Card>
						</Grid>
					</Grid>
				) : null}
				{message.toggle && (
					<Alert
						sx={{ mt: 3, fontSize: 18 }}
						noteType={message.type}
						name={message.message}
						onClose={() => {
							setMessage({
								...message,
								toggle: false,
							});
						}}
					/>
				)}
			</Dialog>
			<Dialog
				title={confirms.title}
				open={confirms.toggle}
				onClose={() => {
					setConfirms({
						...confirms,
						toggle: false,
					});
				}}
				closeButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_pop_close"
					)[0].lanName
					: ""	// 닫기
				}
				bodySx={{ display: "flex", flexDirection: "column" }}
				saveButtonName={props.lanList.length > 0
					? props.lanList.filter(
						(f) =>
							f.lanId ===
							"bm10070_pop_confirm"
					)[0].lanName
					: ""	// 확인
				}
				onSave={() => {
					confirms.onSave();
				}}
			>
				{confirms.content}
			</Dialog>
		</>
	);
}

export default BM10070;
