import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/** Service Import */
import Global from "../../Services/Common/Global";
import Commons from "../../Services/Common/Common";
import IndexedDb from "../../Services/IndexedDb/IndexedDb";
/** Reducers Import */
import { DrawerActions } from "../../Reducers/DrawerReducer";
import { BreadCrumbsActions } from "../../Reducers/BreadCrumbsReducer";
/** Components */
import SettingsView from "../../Views/Home/Settings";
import { Snackbar } from "../../Components/Snackbar";
import { Loading } from "../../Components/Progresses";
import { SnackDialog } from "../../Components/Dialog";

function Settings() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [progress, setProgress] = React.useState(false);
	const [snacks, setSnacks] = React.useState({
		open: false,
		type: "info",
		message: "",
	});
	const [snackDialog, setSnackDialog] = React.useState({		// 스낵바 다이얼로그
		open: false,
		title: "",
		message: "",
	});
	const [inputs, setInputs] = React.useState({
		userId: Commons.Storages("get", "userInfo").userId,
		userName: Commons.Storages("get", "userInfo").userNm,
		userTel: "",
		authDate: Commons.Storages("get", "userInfo").useEndDate,
		amountYn: Commons.Storages("get", "userInfo").amountYn,
		changedName: Commons.Storages("get", "userInfo").userNm,
		changedTel: "",
		// userId: "",
		// userName: "",
		// userTel: "",
		// authDate: "",
		// amountYn: "",
		// changedName: "",
		// changedTel: "",
	});
	const [lanList, setLanList] = React.useState([]); // language list

	React.useEffect(() => {
		UpdateUserInfo();
	}, []);

	// memberHis 테이블에서 필요한 정보만 조회
	const UpdateUserInfo = async () => {
		setProgress(true);
		let userInfo = [];
		let lans = [];

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfo = result;
			});

			// 언어팩
			await IndexedDb.GetAllDb("LanguageList").then((result) => {
				if (result.length > 0) {
					result.forEach((item) => lans.push(item));
					setLanList(result);
				}
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.BaseManagement.BM10010.GetMemberHisInfo,
				{
					userId: userInfo.userId
				}
			).then(async (result) => {
				let data = result.data;

				if (data.type === "Error") {
					throw new Error(data.message);
				} else if (data === "데이터가 없습니다.") {
					throw new Error(
						(lans.length > 0
							? lans.filter(
								(f) =>
									f.lanId ===
									"snack_unavailable_data"
							)[0].lanName
							: "")	// 데이터가 없습니다.
					);
				} else {
					await IndexedDb.UpdateDb(
						"LoginInfo",
						userInfo.token,
						{
							...userInfo,
							useEdate: data.useEdate,
							amountYn: data.amountYn,
						}
					);
					Commons.Storages("set", "userInfo", {
						...Commons.Storages("get", "userInfo"),
						useEndDate:
							data.useEdate.substring(0, 4) +
							"-" +
							data.useEdate.substring(4, 6) +
							"-" +
							data.useEdate.substring(6),
						amountYn: data.amountYn,
					});
					// setInputs({
					// 	...inputs,
					// 	userTel: data.userTel,
					// 	changedTel: data.userTel,
					// });

					Init(lans, data);
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 기타 사용자 정보 조회
	const Init = async (lans = lanList, hisData) => {
		setProgress(true);
		let loginInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				loginInfo = result;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Settings.GetUserInfo,
				{
					userId: loginInfo.userId,
				}
			).then((result) => {
				let data = result.data;
				// console.log("Init: ", data);

				if (data.type === "Error") {
					throw new Error(data.message);
				} else {
					setInputs({
						...inputs,
						userTel: hisData.userTel,
						authDate:
							data.UseEdate.substring(0, 4) +
							"-" +
							data.UseEdate.substring(4, 6) +
							"-" +
							data.UseEdate.substring(6),
					});
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lans.length > 0
						? lans.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	// 이름 변경
	const ChangeName = async () => {
		setProgress(true);
		let loginInfo = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				loginInfo = result;
			});

			await Commons.Axios(
				"post",
				Global.base.useUrl + Global.urls.Settings.ChangeName,
				{
					userId: inputs.userId,
					name: inputs.changedName,
					tel: inputs.changedTel === "" ? "00" : inputs.changedTel,
				}
			).then(async (result) => {
				let data = result.data;

				if (data.type === "Error") {
					setInputs({
						...inputs,
						userName: loginInfo.userNm,
					});
					throw new Error(data.message);
				} else {
					await Commons.Storages("set", "userInfo", {
						...Commons.Storages("get", "userInfo"),
						userNm: inputs.changedName,
					});

					await IndexedDb.UpdateDb("LoginInfo", loginInfo.token, {
						...loginInfo,
						userNm: inputs.changedName,
					});

					setInputs({
						...inputs,
						userName: inputs.changedName,
						userTel: inputs.changedTel,
					});

					// setSnacks({
					// 	...snacks,
					// 	open: true,
					// 	type: "success",
					// 	message:
					// 		(lanList.length > 0
					// 			? lanList.filter(
					// 				(f) => f.lanId === "snack_changed"
					// 			)[0].lanName
					// 			: ""), // 변경되었습니다.
					// });
					setSnackDialog({
						open: true,
						title:
							lanList.length > 0
								? lanList.filter(
									(f) =>
										f.lanId ===
										"snack_success_title"
								)[0].lanName
								: "",	// 성공
						message:
							(lanList.length > 0
								? lanList.filter(
									(f) => f.lanId === "snack_changed"
								)[0].lanName
								: ""), // 변경되었습니다.
					});
				}
			});
		} catch (e) {
			// console.log(e);
			// setSnacks({
			// 	...snacks,
			// 	open: true,
			// 	type: "error",
			// 	message: e.message,
			// });
			setSnackDialog({
				open: true,
				title:
					lanList.length > 0
						? lanList.filter(
							(f) =>
								f.lanId ===
								"snack_error_title"
						)[0].lanName
						: "",	// 에러!
				message: e.message,
			});
		} finally {
			setProgress(false);
		}
	};

	const MovePage = (_path = "Home") => {
		navigate("/" + _path);
	};

	const LogoutLogic = async () => {
		let userInfoId = null;

		try {
			await IndexedDb.GetLoginInfo().then((result) => {
				userInfoId = result.userId;
			});

			await Commons.Axios(
				"get",
				Global.base.useUrl + Global.urls.Account.Logout,
				{
					userId: userInfoId,
					type: Commons.IsMobile() ? "mobile" : "web",
				}
			).then((result) => {
				let data = result.data;

				if (data === "Ok") {
					Commons.Axios("logout");
					dispatch(BreadCrumbsActions.setLargeTitle("Home"));
					dispatch(BreadCrumbsActions.setMediumTitle("Home"));
					dispatch(DrawerActions.CloseDrawer());
					navigate("/");
					// return window.location.reload();
				}
			});
		} catch (e) {
			// console.log(e);
		} finally {
		}
	};

	return (
		<div className="wrap">
			<SettingsView
				inputs={inputs}
				snacks={snacks}
				lanList={lanList}
				setSnacks={setSnacks}
				setInputs={setInputs}
				ChangeName={ChangeName}
				LogoutLogic={LogoutLogic}
				MovePage={MovePage}
				snackDialog={snackDialog}
				setSnackDialog={setSnackDialog}
			/>
			<Loading value={progress} />
			<Snackbar
				type={snacks.type}
				open={snacks.open}
				onClose={() => {
					setSnacks({
						...snacks,
						open: false,
					});
				}}
				message={snacks.message}
			/>
			<SnackDialog
				open={snackDialog.open}
				title={snackDialog.title}
				closeButtonName={
					lanList.length > 0
						? lanList.filter(
							(f) => f.lanId === "cm10000_pop_close"
						)[0].lanName
						: "" // 닫기
				}
				onClose={() =>
					setSnackDialog({
						...snackDialog,
						open: false,
						title: "",
						message: ""
					})
				}
			>
				{snackDialog.message}
			</SnackDialog>
		</div>
	);
}

export default Settings;
